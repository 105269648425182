<input
  type="checkbox"
  [id]="_id"
  class="c-checkbox"
  [checked]="_isChecked"
  [indeterminate]="indeterminate"
  [value]="_isChecked"
  [disabled]="disabled"
  (change)="handleChange($event)"
  cy-checkbox
/>
<label [for]="_id"></label>
