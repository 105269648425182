<button
  mat-icon-button
  [disableRipple]="true"
  color="primary"
  [matTooltip]="'SHOW_HINT' | translate"
  matTooltipPosition="above"
  (click)="showTooltip()"
  (blur)="hideTooltip()"
>
  <mat-icon
    #customTooltip="matTooltip"
    [matTooltip]="message | translate"
    matTooltipPosition="right"
    [matTooltipHideDelay]="hideDelay"
    [matTooltipDisabled]="tooltipDisabled"
    matTooltipClass="custom-tooltip"
    >info_outline</mat-icon
  >
</button>
