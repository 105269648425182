import { AbstractControl } from '@angular/forms';
import { phoneRegexp } from '..';

export const phoneValidator = (AC: AbstractControl) => {
  if (AC?.value && !phoneRegexp.test(AC.value)) {
    return { invalid: true };
  }
  return null;
};

export const positiveWholeNumberValidator = (AC: AbstractControl) => {
  if (AC?.value && !/^\d+$/.test(AC.value.toString().trim())) {
    return { invalid: true };
  }
  return null;
};

export const tabletNameValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value || '';

    if (!/^[A-Fa-f0-9]*$/.test(value)) {
      return { prohibitedCharacters: true };
    }

    if (value.length > 16) {
      return { maxLength: true };
    }

    return null;
}
