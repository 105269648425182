import { Pipe, PipeTransform } from '@angular/core';
import { phoneRegexp } from '@varistar-apps/shared/utilities';
import * as _ from 'lodash';

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transformFunc = _.flow([
    _.partialRight(_.split, ''),
    _.partialRight(_.filter, (value) => {
      return value !== ' ';
    }),
    _.reverse,
    _.partialRight(_.chunk, 3),
    _.partialRight(_.map, (chunk, index) => {
      if (index < 3) {
        chunk.push(' ');
      }

      return chunk;
    }),
    _.flattenDeep,
    _.reverse,
    _.partialRight(_.join, ''),
    _.partialRight(_.thru, (value) => {
      return value.trim();
    }),
  ]);

  transform(val: string) {
    if (phoneRegexp.test(val)) {
      return this.transformFunc(val);
    }

    return val;
  }

  constructor() {}
}
