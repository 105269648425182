import * as moment from 'moment';
import { MapLayerType } from './map-layer.model';
import { TLpisSrPole } from './entities/TLpisSrPole';
import { TLpisFirmy } from './entities/TLpisFirmy';
// import { t_LPIS_firmy } from '@prisma/client';

export enum SatelliteType {
  LANDSAT = 'LANDSAT',
  SENTINEL = 'SENTINEL',
}

export enum VisualizationType {
  // uzivatelsky vyber
  NDVI = 'NDVI',
  LAI = 'LAI',
  EVI = 'EVI',
  IRECI = 'IRECI',
  NDRE = 'NDRE',
  RGBVIS = 'RGBVIS',
  //
  OUTLINE = 'OUTLINE',
  LPIS = 'LPIS',
  // nejsou v listboxu, pouze interni pouziti
  SMART_SCOUTING = 'SMART_SCOUTING',
  ASSET = 'ASSET',
  RVP = 'RVP',
  DEM = 'DEM',
}

export enum DownloadFormat {
  GEOJSON = 'GEOJSON',
  KML = 'KML',
  KMZ = 'KMZ',
}

export enum AssetType {
  CLIP = 'CLIP',
  RVP = 'RVP',
  LPIS = 'LPIS', // ???
}

export enum ClipType {
  BOUNDS = 'BOUNDS', // bez výběru, podle vybraného okna
  RESOURCE = 'RESOURCE', // hony zakaznika, resource asset
  LPIS = 'LPIS', // hony dle LPIS
  // // Asi nebude potreba
  // LPIS_CR_BOUNDS = 'LPIS_CR_BOUNDS', // hony dle LPIS ČR
  // LPIS_CR_FILTER_SQUARE = 'LPIS_CR_FILTER_SQUARE', // hony dle LPIS ČR vybraného dle čtverce (CTVEREC)
  // LPIS_CR_FILTER_CODE = 'LPIS_CR_FILTER_CODE', // hony dle LPIS ČR vybraného dle zkáceného kódu (ZKOD_DPB)
  // LPIS_CR_FILTER_COMPANY = 'LPIS_CR_FILTER_COMPANY', // hony dle LPIS ČR vybraného dle podniku (PODNIK, ID_UZ)
  // LPIS_SK_BOUNDS = 'LPIS_SK_BOUNDS', // hony dle LPIS SK
  // LPIS_SK_FILTER_CODE = 'LPIS_SK_FILTER_CODE', // hony dle LPIS SK vybraného dle kódu dílu (KODKD)
  // LPIS_SK_FILTER_LOCALITY = 'LPIS_SK_FILTER_LOCALITY', // hony dle LPIS SK vybraného dle lokality (LOKALITA)
}

export enum LpisFilter {
  LPIS_CR_BOUNDS = 'LPIS_CR_BOUNDS', // hony dle LPIS ČR
  LPIS_CR_FILTER_CODE = 'LPIS_CR_FILTER_CODE', // hony dle LPIS ČR vybraného dle čtverce a zkráceného kódu (CTVEREC, ZKOD_DPB)
  LPIS_CR_FILTER_COMPANY = 'LPIS_CR_FILTER_COMPANY', // hony dle LPIS ČR vybraného dle podniku (PODNIK, ID_UZ)
  LPIS_SK_BOUNDS = 'LPIS_SK_BOUNDS', // hony dle LPIS SK
  LPIS_SK_FILTER_CODE = 'LPIS_SK_FILTER_CODE', // hony dle LPIS SK vybraného dle kódu dílu (KODKD)
  LPIS_SK_FILTER_LOCALITY = 'LPIS_SK_FILTER_LOCALITY', // hony dle LPIS SK vybraného dle lokality (LOKALITA)
}

export enum LpisAsset {
  LPIS_CR = 'users/varistar_sat/LPIS_CR', // hony dle LPIS ČR
  LPIS_SR = 'users/varistar_sat/LPIS_SR', // hony dle LPIS SR
}

export const ImageCollections = {
  LANDSAT: 'LANDSAT/LC08/C01/T1_RT_TOA',
  // LANDSAT_RGBVIS: 'LANDSAT/LC08/C01/T1_TOA',
  LANDSAT_RGBVIS: 'LANDSAT/LC08/C01/T1_RT_TOA',
  SENTINEL: 'COPERNICUS/S2_SR',
  // SENTINEL: 'COPERNICUS/S2',
  SENTINEL_RGBVIS: 'COPERNICUS/S2_SR',
  // SENTINEL_RGBVIS: 'COPERNICUS/S2',
};

export const MapPalette = {
  default: [
    'FFFFFF',
    'CE7E45',
    'DF923D',
    'F1B555',
    'FCD163',
    '99B718',
    '74A901',
    '66A000',
    '529400',
    '3E8601',
    '207401',
    '056201',
    '004C00',
    '023B01',
    '012E01',
    '011D01',
    '011301',
  ],
  'colorbrewer.Spectral': [
    '9e0142',
    'd53e4f',
    'f46d43',
    'fdae61',
    'fee08b',
    'ffffbf',
    'e6f598',
    'abdda4',
    '66c2a5',
    '3288bd',
    '5e4fa2',
  ],
  'colorbrewer.RdYlGn': [
    'a50026',
    'd73027',
    'f46d43',
    'fdae61',
    'fee08b',
    'ffffbf',
    'd9ef8b',
    'a6d96a',
    '66bd63',
    '1a9850',
    '006837',
  ],
  'colorbrewer.BrBG': [
    '543005',
    '8c510a',
    'bf812d',
    'dfc27d',
    'f6e8c3',
    'f5f5f5',
    'c7eae5',
    '80cdc1',
    '35978f',
    '01665e',
    '003c30',
  ],
  'colorbrewer.BuGn': [
    'f7fcfd',
    'e5f5f9',
    'ccece6',
    '99d8c9',
    '66c2a4',
    '41ae76',
    '238b45',
    '006d2c',
    '00441b',
  ],
  'colorbrewer.Paired': [
    'a6cee3',
    '1f78b4',
    'b2df8a',
    '33a02c',
    'fb9a99',
    'e31a1c',
    'fdbf6f',
    'ff7f00',
    'cab2d6',
    '6a3d9a',
    'ffff99',
  ],
  'colorbrewer.Accent': [
    '7fc97f',
    'beaed4',
    'fdc086',
    'ffff99',
    '386cb0',
    'f0027f',
    'bf5b17',
    '666666',
  ],
  'cmocean.Speed': ['fffdcd', 'e1cd73', 'aaac20', '5f920c', '187328', '144b2a', '172313'],
  'colorbrewer.RYP': [
    '000',
    '3b0119',
    '4d001f',
    '621a37',
    '9e0142',
    'f46d43',
    'fde50a',
    'a7f591',
    '40b95c',
    '0e7bbe',
    '08517e',
    '4e27ee',
  ],
};

export const GraphPalette = [
  '#2f4f4f',
  '#2e8b57',
  '#808000',
  '#b22222',
  '#7f007f',
  '#ff0000',
  '#ffa500',
  '#0000cd',
  '#7cfc00',
  '#00fa9a',
  '#4169e1',
  '#e9967a',
  '#00cccc',
  '#00bfff',
  '#ff00ff',
  '#ff1493',
  '#ee82ee',
];

export const MapParams = {
  NDVI: {
    min: 0,
    max: 1,
    palette: MapPalette['default'],
  },
  LAI: {
    min: 0,
    max: 7,
    palette: MapPalette['default'],
  },
  EVI: {
    min: 0,
    max: 1,
    palette: MapPalette['default'],
  },
  IRECI: {
    min: 0,
    max: 3,
    palette: MapPalette['default'],
  },
  NDRE: {
    min: 0,
    max: 1,
    palette: MapPalette['default'],
  },
  OUTLINE: {
    max: 20,
    palette: ['FF0000'],
  },
  SMART_SCOUTING: {
    min: 0,
    max: 3,
    palette: ['000000', 'FF0000', 'FFFF00', '0000FF'],
  },
  RVP: {
    min: 0, // TODO: musi se nastavit dle YP
    max: 100, // TODO: musi se nastavit dle YP
    palette: MapPalette['colorbrewer.Spectral'],
  },
  RGBVIS: {
    min: 0.0,
    // max: 0.2500,
    max: 0.3,
    // gamma: 1.4,
    bands: ['B4', 'B3', 'B2'],
  },
  // RGBVIS: {
  //   min: 0.0,
  //   max: 0.3,
  //   bands: ['B4', 'B3', 'B2'],
  // },
};

export type MapBoundsArray = [[number, number], [number, number]];

export interface MapBoundsSwNe {
  //google.maps.LatLng
  sw: [number, number]; // South West - lat, lng
  ne: [number, number]; // North East - lat, lng
}

export interface MapParams {
  min?: number;
  max?: number;
  palette?: string[];
  // pouziti pouze pri navratu z GEE a pouzivaji se pouze pri bestVegetation
  median?: number;
  stdDev?: number;
}

export interface ResponseGeeMapLayer {
  visualizationType: VisualizationType; // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
  type: MapLayerType; // MAP_IMAGE_URL / GEO_JSON
  url?: string; // map tiles / download url
  content?: object; // GeoJson object
  downloadFormat?: DownloadFormat; // TODO: jak budou formatovany data pri downloadu
  minMaxColumn?: object;
  palette?: string[];
  point?: [number, number]; // lon, lat
  value?: number; // hodnota v poloze dle point
}

export interface MapClip {
  type?: ClipType;
  // resourceAsset?: string, // RESOURCE, asset name podle type
  // lpisAsset?: string, // LPIS asset name podle type
  asset?: string; // RESOURCE nebo LPIS asset name podle type
  bounds?: MapBoundsArray; // souradnice SW NE okraje mapy
  params?: any; // parametry pro filtraci z assetu
  screen?: boolean; // orizne dle aktualne zobrazene mapy
}

export interface ApiVaristarGeeMapRequest {
  bounds: MapBoundsArray; // souradnice SW NE okraje mapy
  dateTo: string;
  daysBack: number;
  cloudyPixelPercentage: number; // 0-100
  satellite: SatelliteType;
  image?: string; // vybrany obrazek ze satelity, nehleda se cela kolekce
  visualization: VisualizationType;
  cloudDetection: boolean; // detekce oblacnosti
  clipImage: boolean; // oriznout image podle assetu?
  resourceId: string; // Resource id ktere se na serveru namapuje na Asset name pro CLIP (orez)
  imageId?: string; // id konkretniho snimku ze satelitu, nehleda se cela kolekce
  mapParams?: MapParams; // manualni vyber min a max pro palety a palety
  bestVegetation?: boolean; // min = median - stdDev
  autoMinMax?: boolean; // zjisti optimalni min max MapParam parametry
  point?: [number, number]; // lon, lat
  clip?: MapClip;
}
// class pro DTO
export class ApiVaristarGeeMapRequest implements ApiVaristarGeeMapRequest {
  static getName(request: ApiVaristarGeeMapRequest) {
    const end = moment(request.dateTo).format('YYYY-MM-DD');
    const start = moment(request.dateTo).subtract(request.daysBack, 'day').format('YYYY-MM-DD');
    let name;
    switch (request.visualization) {
      case VisualizationType.RVP:
        name = `API_VARISTAR.VISUALIZATION_TYPE.${request.visualization}-${request}`;
        break;
      case VisualizationType.OUTLINE:
      case VisualizationType.LPIS:
        name = `API_VARISTAR.VISUALIZATION_TYPE.${request.visualization}`;
        break;
      default:
        name = `${request.visualization}_${SatelliteType[request.satellite][0]}${
          request.clipImage ? 'X' : ''
        }_P${request.cloudyPixelPercentage}${request.cloudDetection ? '_M' : ''}${
          request.bestVegetation ? '_V' : ''
        }_${request.imageId ? request.imageId : `${end}_${request.daysBack}`}${
          request.point ? `_${JSON.stringify(request.point)}` : ''
        }`;
    }
    return name;
  }
  static getId(request: ApiVaristarGeeMapRequest) {
    // return `${this.getName(request)}-${request.resourceId}-${JSON.stringify(request.mapParams)}-${request.bestVegetation}`;
    return `${this.getName(request)}-${request.resourceId}-${JSON.stringify(request.mapParams)}`;
  }
}

export interface ApiVaristarGeeMapResponse {
  name: string; // e.g. NDVI_2020-03-01_2020-03-15
  bounds: [[number, number], [number, number]]; // souradnice SW NE okraje vracene mapy (lat, lng)
  centroid?: [number, number]; // souradnice stredu vracene mapy
  clipAsset: string; // podle jakeho CLIP assetu
  rvpAsset?: string; // podle jakeho RVP aasetu
  layers?: ResponseGeeMapLayer[]; // pole vracenych map layers
  layer?: ResponseGeeMapLayer; // map layer
  mapParams?: MapParams;
}

export interface ApiVaristarGeeImagesResponse {
  images: string[]; // seznam id snimku ze satelitu
  bounds: [[number, number], [number, number]]; // souradnice SW NE okraje vracene mapy (lat, lng)
  clipAsset: string; // podle jakeho CLIP assetu
}

export interface ApiVaristarGeeAssetMapRequest {
  resourceId: string; // Resource id ktere se na serveru namapuje na Asset name pro CLIP (orez) nebo RVP (vynosovy potencial)
  assetType: AssetType;
  minMaxColumn?: string; // nazev property atributu na kterem se ma zjistit min max
  clip?: MapClip;
}
// class pro DTO
export class ApiVaristarGeeAssetMapRequest implements ApiVaristarGeeAssetMapRequest {
  static getName(request: ApiVaristarGeeAssetMapRequest) {
    return `API_VARISTAR.VISUALIZATION_TYPE.${request.assetType}`;
  }
  static getId(request: ApiVaristarGeeAssetMapRequest) {
    // return `${request.assetType}-${request.resourceId}-${request.minMaxColumn || 'N/A'}`;
    // nove rozlisuji dle vsech parametru
    return `${JSON.stringify(request)}`;
  }
}

export interface ApiVaristarLpisCompaniesRequest {
  lpisId: string; // LPIS id dokumentu ve Firestore, pod ktery se do companies ulozi nazvy podniku
}
// class pro DTO
export class ApiVaristarLpisCompaniesRequest implements ApiVaristarLpisCompaniesRequest {}

export interface ApiVaristarLpisCompaniesResponse {
  companies: string[]; // LPIS id dokumentu ve Firestore pod ktery se do companies ulozi nazvy podniku
}

export interface ApiVaristarVaristarIsCompaniesResponse {
  // companies: t_LPIS_firmy[]; // LPIS id dokumentu ve Firestore pod ktery se do companies ulozi nazvy podniku
  // companies: { ID_LPIS: number, Obchodni_jmeno: string }[]; // LPIS id dokumentu ve Firestore pod ktery se do companies ulozi nazvy podniku
  companies: TLpisFirmy[];
  name: string;
  skip: number;
  limit: number;
}

export interface ApiVaristarVaristarIsLocalitiesResponse {
  // localities: { id: number, name: string }[];
  localities: TLpisSrPole[];
  name: string;
  skip: number;
  limit: number;
}

export interface ApiVaristarVaristarIsCrOwnersResponse {
  obchodniJmeno: string;
  idUz: number;
}

export const ApiVaristar = {
  MapPalette: MapPalette,

  getSatelliteTypes: () => {
    return Object.keys(SatelliteType).map((key) => ({
      name: `API_VARISTAR.SATELLITE_TYPE.${key.toUpperCase()}`,
      value: SatelliteType[key],
    }));
  },
  getVisualizationTypes: (satellite: SatelliteType = SatelliteType.SENTINEL) => {
    // return Object.keys(VisualizationType).map(key => ({ name: `API_VARISTAR.VISUALIZATION_TYPE.${key.toUpperCase()}`, value: VisualizationType[key] }));
    const types = [];
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.NDVI}`,
      value: VisualizationType.NDVI,
    });
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.LAI}`,
      value: VisualizationType.LAI,
    });
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.EVI}`,
      value: VisualizationType.EVI,
    });
    if (satellite === SatelliteType.SENTINEL) {
      // neni na LANDSAT
      types.push({
        name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.IRECI}`,
        value: VisualizationType.IRECI,
      });
      types.push({
        name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.NDRE}`,
        value: VisualizationType.NDRE,
      });
    }
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.RGBVIS}`,
      value: VisualizationType.RGBVIS,
    });
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.OUTLINE}`,
      value: VisualizationType.OUTLINE,
    });
    types.push({
      name: `API_VARISTAR.VISUALIZATION_TYPE.${VisualizationType.LPIS}`,
      value: VisualizationType.LPIS,
    });
    return types;
  },
  getClipTypes: () => {
    return Object.keys(ClipType).map((key) => ({
      name: `API_VARISTAR.CLIP_TYPE.${key.toUpperCase()}`,
      value: ClipType[key],
    }));
  },
  getPalettes: () => {
    // return Object.keys(MapPalette).map(key => ({ name: `API_VARISTAR.PALETTE.${key.toUpperCase()}`, value: MapPalette[key] }));
    return Object.keys(MapPalette).map((key) => ({
      name: `API_VARISTAR.PALETTE.${key}`,
      value: MapPalette[key],
    }));
  },
  // getMapParams: (visualization: VisualizationType, palette: string[] = MapPalette.default) => {
  getMapParams: (visualization: VisualizationType): MapParams => {
    // return { ...MapParams[visualization], palette };
    return { ...MapParams[visualization] };
  },
  getImageCollection(satellite: SatelliteType, visualization: VisualizationType) {
    switch (satellite) {
      case SatelliteType.LANDSAT:
        switch (visualization) {
          case VisualizationType.RGBVIS:
            return ImageCollections.LANDSAT_RGBVIS;
          default:
            return ImageCollections.LANDSAT;
        }
      case SatelliteType.SENTINEL:
        switch (visualization) {
          case VisualizationType.RGBVIS:
            return ImageCollections.SENTINEL_RGBVIS;
          default:
            return ImageCollections.SENTINEL;
        }
    }
  },
  getLpisFilter: () => {
    return Object.keys(LpisFilter).map((key) => ({
      name: `API_VARISTAR.LPIS_FILTER.${key.toUpperCase()}`,
      value: LpisFilter[key],
    }));
  },
  // getLpisFilterParams: () => {
  //   return Object.keys(LpisFilter).map(key => ({ name: `API_VARISTAR.LPIS_FILTER.${key.toUpperCase()}`, value: LpisFilter[key] }));
  // },
};
