export interface MapDocument {
  cisloZakazky: string;
  cisloTerminaluList: string[];
}

export const MapDocument = {
  selectId: (a: MapDocument) => {
    return a.cisloZakazky;
  },
};
