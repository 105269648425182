import { AuthUser } from './auth-user.model';
import { AccountRole } from './account.model';

export enum AccessState {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

// FIXIT: Jeste je u AccountRole
export enum AccessRole {
  USER = 'USER', // vidi jen svoji organizaci a jeji data a muze menit jen svoje data
  // ADMIN = 'ADMIN', // muze vse
  MANAGER = 'MANAGER', // muze spravovat jen svoji organizaci
}

export interface Access {
  id?: string;
  user?: AuthUser; // email
  state?: AccessState;
  created?: string; // ISO datetime
  accepted?: string; // ISO datetime
  rejected?: string; // ISO datetime
  role?: AccessRole | AccountRole;
}

export const Access = {
  selectId: (a: Access): string => {
    return a.id;
  },
  sortByCreated: (a: Access, b: Access): number => {
    return a.created && a.created.localeCompare(b.created);
  },
  getAccessStates: () => {
    return Object.keys(AccessState).map((key) => ({
      name: `ACCESS.STATE.${key.toUpperCase()}`,
      value: AccessState[key],
    }));
  },
  getAccessRoles: () => {
    return Object.keys(AccessRole).map((key) => ({
      name: `ACCESS.ROLE.${key.toUpperCase()}`,
      value: AccessRole[key],
    }));
  },
};
