import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConfigurationFeature, ConfigurationPayload } from './configuration.actions';
import {
  CONFIGURATION_FEATURE_KEY,
  configurationAdapters,
  ConfigurationFeatureState,
  ConfigurationState,
} from './configuration.reducer';

const getConfigurationState = createFeatureSelector<ConfigurationState>(CONFIGURATION_FEATURE_KEY);

export const getMapSelector = (feature: ConfigurationFeature) => {
  return createSelector(getConfigurationState, (state: ConfigurationState) => state[feature]);
};
export const getMapSelectors = (feature: ConfigurationFeature) => {
  return configurationAdapters[feature].getSelectors(getMapSelector(feature));
};

export const getLoadedSelector = (feature: ConfigurationFeature) => {
  return createSelector(
    getMapSelector(feature),
    (state: ConfigurationFeatureState<ConfigurationPayload>) => state.loaded, // loaded, error
  );
};
export const getSelectedIdSelector = (feature: ConfigurationFeature) => {
  return createSelector(
    getMapSelector(feature),
    (state: ConfigurationFeatureState<ConfigurationPayload>) => state.selectedId,
  );
};

export const getSelectedSelector = (feature: ConfigurationFeature) => {
  return createSelector(
    getMapSelectors(feature).selectEntities,
    getSelectedIdSelector(feature),
    (entities, id) => {
      return entities[id];
    },
  );
};

export const getConfigurationQuery = (feature: ConfigurationFeature) => {
  return {
    ...getMapSelectors(feature),
    loaded: getLoadedSelector(feature),
    selectedId: getSelectedIdSelector(feature),
    selected: getSelectedSelector(feature),
  };
};
