import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EntityCollectionService } from '@ngrx/data';
import {
  FeatureConfiguration,
  FeatureConfigurationService,
} from '@varistar-apps/frontend/ui-feature';
import { Order } from '@varistar-apps/shared/data';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { auditTime, distinctUntilKeyChanged, filter, switchMap } from 'rxjs/operators';

import { WizardStep, WizardStepsService } from './wizard-steps.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss'],
})
export class WizardStepsComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription();
  lastStep: number;
  @Input() name = '';
  @Input('lastStep$') set setLastStep(lastStep$: Observable<number>) {
    this.subscription.add(
      lastStep$.subscribe((lastStep) => {
        this.lastStep = lastStep;
      }),
    );
  }
  wizardSteps$: Observable<WizardStep[]>;
  selectedStepIndex$: Observable<number>;
  // this.wizardStepsService.actualStep$//.pipe(auditTime(500))

  @ViewChild('stepper', { static: true })
  stepper: MatStepper;

  steps: WizardStep[] = [];
  steps$ = new BehaviorSubject<WizardStep[]>([]);
  actualStep: WizardStep;
  selectedIndex = 0;
  params;
  inProgress = false;

  constructor(
    public wizardStepsService: WizardStepsService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.wizardSteps$ = this.wizardStepsService.getWizard(this.name)?.wizardSteps$;
    this.selectedStepIndex$ = this.wizardStepsService.getWizard(this.name)?.selectedStepIndex$;

    this.subscription.add(
      this.wizardSteps$.subscribe((wizardSteps) => {
        this.wizardStepsService.wizardSteps$.next(wizardSteps);
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      combineLatest([this.wizardSteps$, this.selectedStepIndex$]).subscribe(
        ([wizardSteps, selectedStepIndex]) => {
          this.steps = wizardSteps;
          if (this.stepper.selectedIndex !== selectedStepIndex) {
            this.selectedIndex = selectedStepIndex;
            setTimeout(() => {
              this.stepper.selectedIndex = this.selectedIndex;
            }, 0);
          }
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.wizardStepsService.wizardSteps$.next([]);
    this.wizardStepsService.actualStep$.next(undefined);
    this.subscription.unsubscribe();
  }

  onSelectionChange(event: any) {
    this.wizardStepsService.actualStep$.next(event.selectedIndex);

    if (event.selectedIndex === this.selectedIndex) return;
    this.selectedIndex = event.selectedIndex;
    // podle konfigurace step zjsitim celou pripadnou url, ale kontroluji zda mam vsechny parametry
    const step = this.steps[event.selectedIndex];
    let pathSegments = step.path.split('/');

    if (this.lastStep || this.lastStep === 0) {
      if (event.selectedIndex <= this.lastStep) {
        this.wizardStepsService.selectWizardStep(this.name, step.stepKey);
        this.router.navigate(pathSegments, {
          relativeTo: this.route,
          queryParams: step.query || {},
          queryParamsHandling: 'merge',
        });
      } else {
        this.stepper.selectedIndex = event.previouslySelectedIndex;
        return;
      }
    }

    let params = {};
    let route = this.router.routerState.snapshot.root;
    do {
      params = { ...params, ...route.params };
      route = route.firstChild;
    } while (route);
    let isUndefined = false;
    pathSegments = pathSegments.map((segment, i) => {
      if (segment[0] === ':') segment = params[segment.substr(1)];
      if (segment === undefined) {
        if (i !== pathSegments.length - 1) isUndefined = true;
        else segment = ''; // posledni segment muze byt i undefined
      }
      return segment;
    });
    const queryParams = {};
    _.keys(step.query).forEach((key, value) => {
      if (value[0] === ':')
        queryParams[key] = this.router.routerState.snapshot.root.queryParams[key.substr(1)];
      return value;
    });
    // TODO: nepustit dale pokud neni splnea podminka parametru v ceste, treba z new na order bez idOrder
    if (isUndefined) {
      this.stepper.selectedIndex = event.previouslySelectedIndex;
      return;
    }
    this.wizardStepsService.selectWizardStep(this.name, step.stepKey); //

    this.router.navigate(pathSegments, {
      relativeTo: this.route,
      queryParams: step.query || {},
      queryParamsHandling: 'merge',
    });
  }

  isCompleted(stepIndex) {
    return stepIndex <= this.selectedIndex;
  }
  isEditable(stepIndex) {
    return stepIndex <= this.selectedIndex + 2;
  }

  /**
   * Z query params podle step zachova pouze vyjmenovane parametry ostatni zahodi
   */
  private getQueryParams(step) {
    const stepParams = _.get(step, 'params', []);
    return _.pickBy(this.route.snapshot.queryParams, (value, key) => {
      return _.includes(stepParams, key);
    });
  }
}
