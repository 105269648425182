import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type ImageViewerData = {
  image_urls: Array<string>;
  index: number;
  onChangeIndex: (index: number) => void;
};

@Component({
  selector: 'ui-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  public selected_url: string = null;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerData,
  ) {}

  ngOnInit(): void {
    const { image_urls, index } = this.data;

    try {
      this.selected_url = image_urls[index];
    } catch (error) {
      console.log('ImageViewerComponent ngOnInit [image_urls, index]', image_urls, index);
    }
  }

  movePrev(move: boolean = undefined): boolean {
    let index = this.data.image_urls.indexOf(this.selected_url);
    if (move && index > 0) {
      index -= 1;
      this.selected_url = this.data.image_urls[index];
      this.data.onChangeIndex(index);
    }
    return index > 0;
  }

  moveNext(move: boolean = undefined): boolean {
    let index = this.data.image_urls.indexOf(this.selected_url);
    if (move && index < this.data.image_urls.length - 1) {
      index += 1;
      this.selected_url = this.data.image_urls[index];
      this.data.onChangeIndex(index);
    }
    return index < this.data.image_urls.length - 1;
  }
}
