import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { TBaseFertil } from './TBaseFertil';
import { TDodatky } from './TDodatky';
import { TOsoby } from './TOsoby';
import { TZakazkyFields } from './TZakazkyFields';
import { TZakazkyStav } from './TZakazkyStav';
import { TZakazkyTerminal } from './TZakazkyTerminal';
import { TZakazkyTyp } from './TZakazkyTyp';
import { TVyfakturuj } from './TVyfakturuj';
import { TPlodinyEagri } from './TPlodinyEagri';

@Index('id_dodatek_idx', ['idDodatek'], {})
@Index('id_zakazky', ['idZakazky'], {})
@Index('app_type_index', ['appType'], {})
@Index('id_stav_zakazky_idx', ['orderStatus'], {})
@Index('short_name', ['shortName'], {})
@Index('short_name_customer', ['shortNameCustomer'], {})
@Index('datum_zadani', ['datumZadani'], {})
@Index('datum_vytvoreni', ['createDate'], {})
@Index('cislo_zakazky', ['cisloZakazky'], {})
@Index('popis', ['popis'], {})
@Index('app_type_zakazka', ['appType'], {})
@Index('created_user', ['createdUser'], {})
@Index('order_status', ['orderStatus'], {})
@Index('plodina', ['plodinaApp'], {})
@Index('vyfakturuj_zak', ['idVyfakturuj'], {})
@Index('t_zakazky_FK', ['idPlodiny'], {})
@Entity('t_zakazky', { schema: 'VaristarIS' })
export class TZakazky {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'id_zakazky' })
  idZakazky: string;

  @Column('int', { name: 'id_dodatek', nullable: true })
  idDodatek: number | null;

  @Column('datetime', {
    name: 'datum_zadani',
    nullable: true,
    comment: 'Datum prvního vytvoření zakázky - zakázka může být vytvořena, ale neodeslána.',
    default: () => 'CURRENT_TIMESTAMP',
  })
  datumZadani: Date | null;

  @Column('decimal', {
    name: 'vymera',
    nullable: true,
    comment: 'Celková výměra zakázky - součet plochy všech honů v zakázce',
    precision: 6,
    scale: 2,
  })
  vymera: string | null;

  @Column('varchar', {
    name: 'cislo_zakazky',
    nullable: true,
    comment: 'Přiřadí se ve chvíli odeslání - číslo dle VaristarIS',
    length: 45,
  })
  cisloZakazky: string | null;

  @Column('varchar', {
    name: 'cislo_faktury',
    nullable: true,
    comment: 'Číslo faktury z fakturačního systému Vyfakturuj',
    length: 45,
  })
  cisloFaktury: string | null;

  @Column('decimal', {
    name: 'fakturovano_bez_DPH',
    nullable: true,
    precision: 10,
    scale: 2,
  })
  fakturovanoBezDph: string | null;

  @Column('date', { name: 'datum_faktury', nullable: true })
  datumFaktury: string | null;

  @Column('int', {
    name: 'id_vyfakturuj',
    nullable: true,
    comment: 'ID ze systému vyfakturuj',
  })
  idVyfakturuj: number | null;

  @Column('varchar', { name: 'faktura_mena', nullable: true, length: 10 })
  fakturaMena: string | null;

  @Column('decimal', {
    name: 'faktura_kurz',
    nullable: true,
    precision: 10,
    scale: 2,
  })
  fakturaKurz: string | null;

  @Column('date', {
    name: 'uhrazeno',
    nullable: true,
    comment: 'Datum úhrady faktury',
  })
  uhrazeno: string | null;

  @Column('varchar', {
    name: 'popis',
    nullable: true,
    comment: 'Popis, který si může zákazník zadat pro přesnější identifikaci zakázky',
    length: 999,
  })
  popis: string | null;

  @Column('varchar', {
    name: 'short_name',
    nullable: true,
    comment:
      'Zkrácený název pro tvorbu souboru ZIP či SHP (odstraněna diakritika a nevhodné znaky pro soubory)',
    length: 255,
  })
  shortName: string | null;

  @Column('varchar', {
    name: 'short_name_customer',
    nullable: true,
    comment: 'Zkrácený název zadaný klientem',
    length: 255,
  })
  shortNameCustomer: string | null;

  @Column('datetime', {
    name: 'create_date',
    nullable: true,
    comment: 'Datum odeslání do terminálu či poslední úpravy',
  })
  createDate: Date | null;

  @Column('int', {
    name: 'app_type',
    nullable: true,
    comment:
      'Typ aplikace - bude řešeno separátní tabulkou s popisem - Variabilní hnojení dle mapy RVP, Variabilní setí dle mapy RVP, Pesticidy dle mapy RVP, Základní hnojení (P,K) dle mapy RVP a rozborů půdy, Variabilní hnojení dle mapy RVP + aktuální stav, Pesticidy – dle aktuálního stavu',
  })
  appType: number | null;

  @Column('int', {
    name: 'created_user',
    nullable: true,
    comment: 'Párovací klíč do tabulky t_osoby - id_osoby.',
  })
  createdUser: number | null;

  @Column('int', {
    name: 'admin_order',
    nullable: true,
    comment:
      'V případě, že je zakázka vygenerovaná administrátorem za nějakého uživatele - ukládá se ID administrátora, aby bylo jasné kdo zakázku vygeneroval.',
  })
  adminOrder: number | null;

  @Column('bigint', {
    name: 'id_shp',
    nullable: true,
    comment: 'Pole pro párování vytvořené SHP aplikační mapy v datbázi PostGre-PostGIS',
  })
  idShp: string | null;

  @Column('int', {
    name: 'order_status',
    nullable: true,
    comment:
      'V jakém stadiu se zakázka nachází. (Vytvořená - rozpracovaná, Odeslaná - rozpracovaná, Odeslaná - ukončená, Smazaná, starý systém - Excel tabulky, admin_order - vytvořená administrátorem - nevstupuje do billingu.)',
    default: () => "'1'",
  })
  orderStatus: number | null;

  @Column('varchar', {
    name: 'plodina_app',
    nullable: true,
    comment: 'Plodina na, kterou byla aplikace aplikována.',
    length: 255,
  })
  plodinaApp: string | null;

  @Column('decimal', {
    name: 'map_koef',
    nullable: true,
    comment: 'Koeficient, kterým může klient vynásobit RATE - dávku celé již vytvořené mapy.',
    precision: 5,
    scale: 2,
    default: () => "'1.00'",
  })
  mapKoef: string | null;

  @Column('decimal', {
    name: 'base_rate',
    nullable: true,
    comment:
      'Základní dávka zadaná pro tuto zakázku (u pesticidů se sem ukládá Základní dávka vody)',
    precision: 12,
    scale: 2,
  })
  baseRate: string | null;

  @Column('decimal', {
    name: 'min_rate',
    nullable: true,
    comment: 'Minimální dávka zadaná pro tuto zakázku',
    precision: 12,
    scale: 2,
  })
  minRate: string | null;

  @Column('decimal', {
    name: 'max_rate',
    nullable: true,
    comment: 'Maximální dávka zadaná pro tuto zakázku',
    precision: 12,
    scale: 2,
  })
  maxRate: string | null;

  @Column('decimal', {
    name: 'var_rate',
    nullable: true,
    comment:
      'Nastavení variability celé mapy v % na 10% relativního výnosového potenciálu - kladná přidává do zón s vyšším výnosovým potenciálem, záporná přidává do zón s nižším výnosovým potenciálem. Příklad: Je-li základní dávka 200 a var_rate 15% v zóně 110% bude aplikační dávka 230 (přidá na 10% výnosového potenciálu 15% z celkové dávky)',
    precision: 12,
    scale: 2,
  })
  varRate: string | null;

  @Column('decimal', {
    name: 'var_rate_actual',
    nullable: true,
    comment:
      'Nastavení variability celé mapy v % na 10% aktuálního stavu - kladná přidává do zón s lepším pororstem, záporná přidává do zón s horším pororstem.',
    precision: 12,
    scale: 2,
  })
  varRateActual: string | null;

  @Column('decimal', {
    name: 'klicivost',
    nullable: true,
    comment: 'Klíčivost osiva celé mapy',
    precision: 8,
    scale: 2,
  })
  klicivost: string | null;

  @Column('decimal', {
    name: 'app_1',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_1: string | null;

  @Column('decimal', {
    name: 'app_2',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_2: string | null;

  @Column('decimal', {
    name: 'app_3',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_3: string | null;

  @Column('decimal', {
    name: 'app_4',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_4: string | null;

  @Column('decimal', {
    name: 'app_5',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_5: string | null;

  @Column('decimal', {
    name: 'app_6',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_6: string | null;

  @Column('decimal', {
    name: 'app_1_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_1_net: string | null;

  @Column('decimal', {
    name: 'app_2_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_2_net: string | null;

  @Column('decimal', {
    name: 'app_3_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_3_net: string | null;

  @Column('decimal', {
    name: 'app_4_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_4_net: string | null;

  @Column('decimal', {
    name: 'app_5_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_5_net: string | null;

  @Column('decimal', {
    name: 'app_6_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_6_net: string | null;

  @Column('int', {
    name: 'app_product_id',
    nullable: true,
    comment: 'ID produktu, který je v dané zakázce aplikován jako hlavní.',
  })
  appProductId: number | null;

  @Column('int', {
    name: 'app_custom_product_id',
    nullable: true,
    comment: 'ID produktu, který je v dané zakázce aplikován jako hlavní.',
  })
  appCustomProductId: number | null;

  @Column('varchar', {
    name: 'app_product',
    nullable: true,
    comment: 'Název uživatelem vytvořeného produktu.',
    length: 255,
  })
  appProduct: string | null;

  @Column('decimal', {
    name: 'percent_volume',
    nullable: true,
    comment: 'Objem účinné látky v produktu v % nebo klíčivost u osiva v %.',
    precision: 5,
    scale: 2,
  })
  percentVolume: string | null;

  @Column('tinyint', {
    name: 'liquid',
    nullable: true,
    comment: 'Označení jestli jde o tekutý či pevný přípravek (0 = pevný, 1 = kapalný)',
    width: 1,
  })
  liquid: boolean | null;

  @Column('tinyint', {
    name: 'is_for_record',
    nullable: true,
    comment: 'priznak zakazky pro evidenci',
    width: 1,
  })
  isForRecord: boolean | null;

  @Column('tinyint', {
    name: 'is_without_bonitation',
    nullable: true,
    comment: 'priznak pro zakazku, ve ktere se nepouziva bonitace poli',
    width: 1,
  })
  isWithoutBonitation: boolean | null;

  @Column('int', {
    name: 'seed_units',
    nullable: true,
    comment:
      'Definuje typ řízení zadávání výsevku a stroje: 0: kg/---; 1: jedinci/kg; 2: jedinci/jedinci',
  })
  seedUnits: number | null;

  @Column('int', {
    name: 'last_step',
    nullable: true,
    comment: 'Posledni krok v pruvodci tvorby zakazky',
  })
  lastStep: number | null;

  @Column('varchar', {
    name: 'fert_type',
    nullable: true,
    comment:
      'Řídící prvek u hnojiva, kterým je řízena aplikace např. N, P či K. U pesticidů se sem uvádí typ pesticidu u uživatelsky definovaných pesticidů.',
    length: 255,
  })
  fertType: string | null;

  @Column('varchar', {
    name: 'index_type',
    nullable: true,
    comment:
      'Typ vegetačního indexu, podle kterého je tvořena aplikační mapa v případě mapy dle aktuálního stavu: EVI (deafult) LAI a NDVI',
    length: 15,
  })
  indexType: string | null;

  @Column('decimal', { name: 'htz', nullable: true, precision: 12, scale: 2 })
  htz: string | null;

  @Column('timestamp', {
    name: 'created',
    nullable: true,
    default: () => 'CURRENT_TIMESTAMP',
  })
  created: Date | null;

  @Column('int', {
    name: 'id_plodiny',
    nullable: true,
    comment: 'Cizí klíč do tabulky t_plodiny_EAGRI - identifikuje plodinu zakázky',
  })
  idPlodiny: number | null;

  @Column('float', {
    name: 'org_koef_current_season',
    nullable: true,
    comment: 'koeficient vyuzitelnosti organickeho hnojiva v teto sezone - urcuje si zakaznik',
    precision: 12,
    default: () => "'1'",
  })
  orgKoefCurrentSeason: number | null;

  @Column('float', {
    name: 'org_koef_next_season',
    nullable: true,
    comment:
      'koeficient vyuzitelnosti organickeho hnojiva v nasledujici sezone - urcuje si zakaznik',
    precision: 12,
    default: () => "'0'",
  })
  orgKoefNextSeason: number | null;

  @Column('int', {
    name: 'season_id',
    nullable: true,
    comment: 'Id sezony, pro kterou byla zakazka vytvorena. id 21 = sezona 2021/22',
  })
  seasonId: number | null;

  @Column('int', {
    name: 'pesticides_units',
    nullable: true,
    comment:
      'Definuje jednotky pro řízení dávky u pesticidů: 0:l/ha, 1: ml/l (při využití injektorů)',
    default: () => "'0'",
  })
  pesticidesUnits: number | null;

  @Column('float', {
    name: 'spatial_decline_value',
    nullable: true,
    comment: 'Represents percentage value for decrease the batch in valley zones',
    precision: 12,
    default: () => "'0'",
  })
  spatialDeclineValue: number | null;

  @ManyToOne('TPlodinyEagri', 'tZakazkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_plodiny', referencedColumnName: 'idtPlodinyEagri' }])
  idPlodiny2: TPlodinyEagri;

  // @OneToMany(() => TBaseFertil, (tBaseFertil) => tBaseFertil.idZakazky2)
  @OneToMany('TBaseFertil', 'idZakazky2')
  tBaseFertils: TBaseFertil[];

  // @ManyToOne(() => TOsoby, (tOsoby) => tOsoby.tZakazkies, {
  @ManyToOne('TOsoby', 'tZakazkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'created_user', referencedColumnName: 'idOsoby' }])
  createdUser2: TOsoby;

  @OneToOne('TVyfakturuj', 'idVyfakturuj', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_vyfakturuj', referencedColumnName: 'idVyfakturuj' }])
  vyfakturuj2: TVyfakturuj;

  // @ManyToOne(() => TDodatky, (tDodatky) => tDodatky.tZakazkies, {
  @ManyToOne('TDodatky', 'tZakazkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_dodatek', referencedColumnName: 'idDodatek' }])
  idDodatek2: TDodatky;

  // @ManyToOne(() => TZakazkyStav, (tZakazkyStav) => tZakazkyStav.tZakazkies, {
  @ManyToOne('TZakazkyStav', 'tZakazkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'order_status', referencedColumnName: 'idtZakazkyStav' }])
  orderStatus2: TZakazkyStav;

  // @ManyToOne(() => TZakazkyTyp, (tZakazkyTyp) => tZakazkyTyp.tZakazkies, {
  @ManyToOne('TZakazkyTyp', 'tZakazkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'app_type', referencedColumnName: 'idtZakazkyTyp' }])
  appType2: TZakazkyTyp;

  // @OneToMany(
  //   () => TZakazkyFields,
  //   (tZakazkyFields) => tZakazkyFields.idZakazky2
  // )
  @OneToMany('TZakazkyFields', 'idZakazky2')
  tZakazkyFields: TZakazkyFields[];

  // @OneToMany(
  //   () => TZakazkyTerminal,
  //   (tZakazkyTerminal) => tZakazkyTerminal.idZakazka2
  // )
  @OneToMany('TZakazkyTerminal', 'idZakazka2')
  tZakazkyTerminals: TZakazkyTerminal[];
}
