import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TFields } from './TFields';
import { TSmlouvy } from './TSmlouvy';

@Index('t_strediska_FK', ['idtSmlouvy'], {})
@Entity('t_strediska', { schema: 'VaristarIS' })
export class TStrediska {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_strediska' })
  idStrediska: number;

  @Column('varchar', { name: 'nazev', nullable: true, length: 100 })
  nazev: string | null;

  @Column('int', { name: 'idt_smlouvy', nullable: true })
  idtSmlouvy: number | null;

  @Column('varchar', {
    name: 'argoRiskCity',
    nullable: true,
    comment:
      'Město, podle kterého se vyhledává informace z Agrorisk, v případě, že neexistuje detekce podle počasí.',
    length: 255,
  })
  argoRiskCity: string | null;

  @OneToMany('TFields', 'idtStrediska2')
  tFields: TFields[];

  @ManyToOne('TSmlouvy', 'tStrediskas', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idtSmlouvy2: TSmlouvy;
}
