import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RegistrationFeature, RegistrationPayload } from './registration.actions';
import {
  REGISTRATION_FEATURE_KEY,
  registrationAdapters,
  RegistrationFeatureState,
  RegistrationState,
} from './registration.reducer';

const getRegistrationState = createFeatureSelector<RegistrationState>(REGISTRATION_FEATURE_KEY);

export const getRegistrationFeatureSelector = (feature: RegistrationFeature) => {
  return createSelector(getRegistrationState, (state: RegistrationState) => state[feature]);
};
export const getRegistrationFeatureSelectors = (feature: RegistrationFeature) => {
  return registrationAdapters[feature].getSelectors(getRegistrationFeatureSelector(feature));
};

export const getRegistrationLoadedSelector = (feature: RegistrationFeature) => {
  return createSelector(
    getRegistrationFeatureSelector(feature),
    (state: RegistrationFeatureState<RegistrationPayload>) => state.loaded, // loaded, error
  );
};
export const getRegistrationSelectedIdSelector = (feature: RegistrationFeature) => {
  return createSelector(
    getRegistrationFeatureSelector(feature),
    (state: RegistrationFeatureState<RegistrationPayload>) => state.selectedId,
  );
};

export const getRegistrationSelectedSelector = (feature: RegistrationFeature) => {
  return createSelector(
    getRegistrationFeatureSelectors(feature).selectEntities,
    getRegistrationSelectedIdSelector(feature),
    (entities, id) => {
      return entities[id];
    },
  );
};

export const getRegistrationQuery = (feature: RegistrationFeature) => {
  return {
    ...getRegistrationFeatureSelectors(feature),
    loaded: getRegistrationLoadedSelector(feature),
    selectedId: getRegistrationSelectedIdSelector(feature),
    selected: getRegistrationSelectedSelector(feature),
  };
};
