import { Column, Entity, Index, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TTerminalySoil } from './TTerminalySoil';
import { TTerminaly } from './TTerminaly';

@Index('IMSI_UNIQUE', ['imsi'], { unique: true })
@Index('t_sim_UN', ['actualTerminal'], { unique: true })
@Index('t_sim_UN1', ['actualTerminalSoil'], { unique: true })
@Index('phone_number', ['phoneNumber'], {})
@Index('imsi', ['imsi'], {})
@Index('act_date', ['activationDate'], {})
@Index('t_terminaly_idx', ['actualTerminal'], {})
@Entity('t_sim', { schema: 'VaristarIS' })
export class TSim {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_SIM' })
  idtSim: number;

  @Column('varchar', {
    name: 'phone_number',
    nullable: true,
    comment: 'Telefonní číslo SIM karty',
    length: 45,
  })
  phoneNumber: string | null;

  @Column('varchar', {
    name: 'IMSI',
    unique: true,
    comment: 'IMSI číslo SIM karty',
    length: 45,
  })
  imsi: string;

  @Column('date', {
    name: 'activation_date',
    nullable: true,
    comment: 'Datum aktivace SIM karty - jejich splatnost je 1 rok.',
  })
  activationDate: string | null;

  @Column('int', {
    name: 'actual_terminal',
    nullable: true,
    unique: true,
    comment: 'Id terminálu, ve kterém je aktuálně SIM karta',
  })
  actualTerminal: number | null;

  @Column('varchar', {
    name: 'last_terminal',
    nullable: true,
    comment: 'Číslo terminálu v kterém byla SIM naposledy použita',
    length: 100,
  })
  lastTerminal: string | null;

  @Column('int', {
    name: 'actual_terminal_soil',
    nullable: true,
    unique: true,
    comment: 'Id terminálu Varistar Soil, ve kterém je aktuálně SIM karta',
  })
  actualTerminalSoil: number | null;
}
