import { TPlanetApiKey } from './entities/TPlanetApiKey';

// tslint:disable-next-line: no-empty-interface
export interface PlanetApiKey extends TPlanetApiKey {}

export const PlanetApiKey = {
  selectId: (x: TPlanetApiKey) => {
    return x.idPlanetApiKey;
  },
};
