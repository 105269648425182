// https://www.worldatlas.com/aatlas/ctycodes.htm
export enum AddressCountry {
  CZ = 'CZ',
  SK = 'SK',
  DE = 'DE',
  AT = 'AT',
  PL = 'PL',
}

// https://wiki.openstreetmap.org/wiki/Cs:WikiProjekt_%C4%8Cesko/Syst%C3%A9m_adres
// https://wiki.openstreetmap.org/wiki/RUIAN#Czech_address_import

export interface Address {
  conscriptionnumber?: string; // Číslo popisné
  provisionalnumber?: string; // Číslo evidenční
  streetnumber?: string; // Číslo orientační
  housenumber?: string; // 1367, 1367/67, 2238/1a, ev.21, ev.21/1
  street?: string;
  place?: string; // Lhotka, Libív, Vysočany.
  suburb?: string; // Praha 9 or Plzeň 2-Slovany
  city?: string; // Brno, Jince, Brdy, Lhota.
  postcode?: string; // 19000
  country?: AddressCountry; // CZ
  ruian?: string;
}

export const Address = {
  getCountries: () => {
    return Object.keys(AddressCountry).map((key) => ({
      name: `COUNTRY.${key.toUpperCase()}`,
      value: AddressCountry[key],
    }));
  },
};
