import { Column, Entity, OneToMany } from 'typeorm';

// import { TPlodinyEagri } from './TPlodinyEagri';

@Entity('t_plodiny_EAGRIskupina_plodin', { schema: 'VaristarIS' })
export class TPlodinyEagrIskupinaPlodin {
  @Column('int', { primary: true, name: 'idt_skupina_plodin' })
  idtSkupinaPlodin: number;

  @Column('varchar', {
    name: 'NAZEV',
    nullable: true,
    comment: 'Název skupiny plodin.',
    length: 65,
  })
  nazev: string | null;

  // @OneToMany(
  //   () => TPlodinyEagri,
  //   (tPlodinyEagri) => tPlodinyEagri.idskupplodin2
  // )
  // @OneToMany(
  //   'TPlodinyEagri', 'idskupplodin2'
  // )
  // tPlodinyEagris: TPlodinyEagri[];
}
