import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { THoldingOsoby } from './THoldingOsoby';
import { TRole } from './TRole';

@Index('t_holding_osoby_role_FK', ['idtHoldingOsoby'], {})
@Index('t_holding_osoby_role_FK_1', ['idtRole'], {})
@Entity('t_holding_osoby_role', { schema: 'VaristarIS' })
export class THoldingOsobyRole {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_holding_osoby_role' })
  idtHoldingOsobyRole: number;

  @Column('int', { name: 'idt_holding_osoby' })
  idtHoldingOsoby: number;

  @Column('int', { name: 'idt_role' })
  idtRole: number;

  @ManyToOne('THoldingOsoby', 'tHoldingOsobyRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_holding_osoby', referencedColumnName: 'idtHoldingOsoby' }])
  idtHoldingOsoby2: THoldingOsoby;

  @ManyToOne('TRole', 'tHoldingOsobyRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_role', referencedColumnName: 'idRole' }])
  idtRole2: TRole;
}
