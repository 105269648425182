import * as _ from 'lodash';
import * as moment from 'moment';
import { removeDiacritics } from './shared-utilities';

export const getCollatorCompare = () => {
  return new Intl.Collator(window['applicationLanguage'] || 'cs').compare;
};

export const compare = (a, b, isAsc = true, isRemoveDiacritics = false) => {
  if (
    typeof a === 'object' ||
    typeof a === 'undefined' ||
    a === '' ||
    (typeof a === 'number' && isNaN(a))
  ) {
    return 1;
  } else if (
    typeof b === 'object' ||
    typeof b === 'undefined' ||
    b === '' ||
    (typeof b === 'number' && isNaN(b))
  ) {
    return -1;
  }

  const collatorCompare = getCollatorCompare();

  if (moment.isMoment(a) || (/\d+[-/\\_.]{1}\d+[-/\\_.]{1}\d+/.test(a) && moment(a).isValid())) {
    return (moment(a).isBefore(moment(b), 'day') ? -1 : 1) * (isAsc ? 1 : -1);
  }

  if (isNaN(+a)) a = (isRemoveDiacritics ? removeDiacritics(a) : a).toLocaleUpperCase().trim();
  if (isNaN(+b)) b = (isRemoveDiacritics ? removeDiacritics(b) : b).toLocaleUpperCase().trim();
  if (isNaN(+a) && isNaN(+b)) return collatorCompare(a, b) * (isAsc ? 1 : -1);
  else return (+a < +b ? -1 : 1) * (isAsc ? 1 : -1);
};

//TODO this is better version I think. But I dont have time to test that is work across whole application

// if (moment(a).isValid() && moment(b).isValid()) {
//   console.log('date', a, b);
//   return (moment(a).isBefore(moment(b), 'day') ? 1 : -1) * (isAsc ? 1 : -1);
// }

// if (!isNaN(+a) && !isNaN(+b)) {
//   return (+a < +b ? -1 : 1) * (isAsc ? 1 : -1);
// }

// //just for sure
// a = a.toString();
// b = b.toString();

// a = (isRemoveDiacritics ? removeDiacritics(a) : a).toLocaleUpperCase().trim();
// b = (isRemoveDiacritics ? removeDiacritics(b) : b).toLocaleUpperCase().trim();

// return collatorCompare(a, b) * (isAsc ? 1 : -1);
