import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcInputButtons<T> extends DynamicFormControlBase<T> {
  controlType = 'input-buttons';
  type: string;
  buttons: any[];
  // inputs: number; // pocet vstupu
  inputs: any[]; // parametry vstupu
  delimiter: string;
  // header: string;
  description: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.buttons = options['buttons'] || [];
    // this.inputs = options['inputs'] || 1;
    this.inputs = options['inputs'] || [{}];
    this.delimiter = options['delimiter'] || null;
    // this.header = options['header'];
    this.description = options['description'];
  }
}
