import { ApiVaristarGeeMapResponse } from './api-varistar.model';

export enum OldMapLayerType {
  MAP_IMAGE_TILES_URL = 'MAP_IMAGE_TILES_URL', // map image tiles url
  MAP_IMAGE_DOWNLOAD_URL = 'MAP_IMAGE_DOWNLOAD_URL', // map image download url
  GEOJSON_CONTENT = 'GEOJSON_CONTENT', // feature collection content in GeoJson format
  GEOJSON_DOWNLOAD_URL = 'GEOJSON_DOWNLOAD_URL', // download url to feature collection in GeoJson format
}

// export interface ResponseGeeMapLayer {
//   visualizationType: VisualizationType // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
//   type: LayerType, // MAP_IMAGE_URL / GEO_JSON
//   url?: string, // map tiles / download url
//   content?: object, // GeoJson object
//   downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu
// }
export interface OldMapLayer {
  id: string;
  response: ApiVaristarGeeMapResponse;
  //   visualizationType: VisualizationType // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
  // type: MapLayerType, // MAP_IMAGE_URL / GEO_JSON
  //   url?: string, // map tiles / download url
  //   content?: object, // GeoJson object
  //   downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu
}

// export interface MapLayer {
//   type: MapLayerType, // MAP_IMAGE_URL / GEO_JSON
//   id?: string; // NOTE: doplni se pri nacteni kvuli reduceru ale je stejne jako id
//   // webmap_id?: string, // NOTE: primarni id
//   // client_id?: string,
//   // source_layer_id?: string,
//   // // asset?: string, // GEE Asset
//   // name?: string // GROSPOL STAŘÍČ, s.r.o.
//   // contract?: string; // 2019010
//   // asset?: string; // CLIP GEE asset name
//   // rvp?: string; // RVP GEE asset name
//   // // nasledujici atributy s eulozili i kdyz nebyly v DTO definici
//   // readonly client_details?: any; // display_name 2019010 AGROSPOL STAŘÍČ, s.r.o.
//   // readonly client_resource_group?: any;

// }

export const OldMapLayer = {
  selectId: (a: OldMapLayer): string => {
    //In this case this would be optional since primary key is id
    return a.id;
  },

  // parseClientDetails: (resource: MapLayer) => {
  //   const display_name = resource && resource.client_resource_group && resource.client_resource_group.display_name;
  //   if (display_name) {
  //     const contract = display_name.substr(0, display_name.indexOf(' '));
  //     const name = display_name.substr(display_name.indexOf(' ') + 1);
  //     return { contract, name };
  //   } else {
  //     return {};
  //   }
  // }
  // sortByLastName: (a: Person, b: Person): number => {
  //   return a.lastName && a.lastName.localeCompare(b.lastName);
  // },

  // fullName: (p: Person) => {
  //   return `${p.lastName} ${p.firstName}`;
  // },
  // getSalutations: () => {
  //   return Object.keys(PersonSalutation).map(key => ({ name: `SALUTATION.${key.toUpperCase()}`, value: PersonSalutation[key] }));
  // }
};
