<div fxFlexFill>
  <mat-vertical-stepper
    #stepper
    (selectionChange)="onSelectionChange($event)"
    [linear]="true"
    class="wizard-steps"
  >
    <ng-container *ngIf="lastStep || lastStep === 0; else normal">
      <mat-step
        *ngFor="let step of wizardSteps$ | async; let i = index"
        [label]="step.title | translate"
        [completed]="i < lastStep"
        [editable]="true"
      >
        <small>{{ step.subtitle | translate }}</small>
      </mat-step>
    </ng-container>
    <ng-template #normal>
      <mat-step
        *ngFor="let step of wizardSteps$ | async; let i = index"
        [label]="step.title | translate"
        [completed]="step.completed"
        [editable]="step.editable"
      >
        <small>{{ step.subtitle | translate }}</small>
      </mat-step>
    </ng-template>
  </mat-vertical-stepper>
</div>
