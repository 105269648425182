import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

import { AuthCredentials } from '../+state/auth.reducer';
// import { ConfigurationFeature } from '@varistar-apps/feature';
import { AngularFirestore } from '@angular/fire/firestore';
import { take, map } from 'rxjs/operators';
import {
  AuthUser,
  Account,
  AccountRole,
  Organization,
  Firestore,
} from '@varistar-apps/shared/data';
// import { ConfigurationFeature } from '@varistar-apps/feature';
// import { ApiVaristarService } from '@varistar-apps/frontend/api-varistar';

@Injectable()
export class AuthService {
  constructor(
    // public apiVaristarService: ApiVaristarService,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    // public db: AngularFirestore,
    // public gplus: GooglePlus,
    // public facebook: Facebook
  ) {}

  // loadAll() {
  //   this.store.dispatch(new LoadAuth());
  // }

  getAuthState() {
    return this.afAuth.authState;
  }

  signUpByEmail(credentials: AuthCredentials) {
    return this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password);
  }

  loginByEmail(credentials: AuthCredentials) {
    return this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password);
  }

  loginByGoogle() {
    return this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  loginByFacebook() {
    return this.afAuth.auth.signInWithPopup(new auth.FacebookAuthProvider());
  }

  loginByMicrosoft() {
    return this.afAuth.auth.signInWithPopup(new auth.OAuthProvider('microsoft.com'));
  }

  logout() {
    return this.afAuth.auth.signOut();
  }

  // async getAccountRoles(user: AuthUser, organization: Organization, ) {
  //   const rolePath = Firestore.getPath('Account', { user, organization });
  //   const roles = await this.afs.doc(rolePath).get().toPromise();
  //   return roles;
  // }

  // async getAccountOrganizations(user: AuthUser) {
  //   const organizationPath = Firestore.getPath('Organization', { user });
  //   const roles = await this.afs.doc(organizationPath).get().toPromise();
  //   return roles;
  // }

  async updateAccount(user: AuthUser) {
    // TODO: ziskat z backendu do varistarProfile$ informaci dle t_user o uzivateli z t_osoby a o jeho roli u zakaznika z  t_zakaznik_osoby
    // !!! FIXIT: circular dependency
    // const x = await this.apiVaristarService.getVaristarProfile();

    return user;
  }

  // async updateAccount(user: AuthUser) {
  //   // const accountUrl = `${ConfigurationFeature.Account}/${user.uid}`;
  //   const accountUrl = `Account/${user.uid}`;
  //   // const accountUrl = 'Account';
  //   // const account = await this.afs.collection(accountUrl).doc(user.uid);
  //   const account = await this.afs.doc(accountUrl).get().toPromise();
  //   let accountData: Partial<Account> = { ...account.data(), id: account.id };
  //   if (account.exists && accountData.user && accountData.user.email === user.email) {
  //     const accessPath = Firestore.getPath('OrganizationAccess', accountData)
  //     const accountAccess = await this.afs.collection(accessPath).doc(user.uid).get().toPromise();
  //     // Account/user.uid jse stejný jako vrácené data

  //     // FIXIT na prasaka pridam accountAccess do access atributu i kdyz neni definovan v Account
  //     return {
  //       ...accountData, access: accountAccess.data()
  //     };
  //   } else {
  //     // aktualizuji Account/user.uid/{email, displayName}
  //     accountData = {
  //       ...accountData,
  //       user: {
  //         // TODO: mozna by bylo dobre si pamatovat vice, ale
  //         displayName: user.displayName,
  //         email: user.email,
  //       },
  //       role: AccountRole.USER,
  //       // admin: false,
  //       // organization: null,
  //     };
  //     return account.ref.set(accountData).then(() => accountData);
  //   }

  //   // ConfigurationFeature

  //   // return from(collection.get())
  //   //   .pipe(
  //   //     mergeMap(value => value.docChanges()),

  //   //     map(documentChange => {
  //   //       const id = documentChange.doc.id;
  //   //       const data = {
  //   //         ...documentChange.doc.data(),
  //   //         id,
  //   //       }
  //   // const this.afs.collection().add(data).then(newData => {

  //   // const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   // await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  //   //   ...user,
  //   //   username: newUsername
  //   // });
  // }

  // // sendPasswordResetEmail(credentials: IAuthCredentials): Promise<void> {
  // //   return this.afAuth.auth.sendPasswordResetEmail(credentials.email);
  // // }

  // // parseUserCredential(userCredintials: auth.UserCredential): AuthData {
  // //   const authUser: AuthData = {
  // //     id: userCredintials.user.uid,
  // //     email: userCredintials.user.email,
  // //     displayName: userCredintials.user.displayName || userCredintials.user.email,
  // //     authProfile: {
  // //       refreshToken: userCredintials.user.refreshToken,
  // //     },
  // //     preferences: {
  // //       // language: // TODO
  // //     }
  // //   };
  // //   return authUser;
  // // }

  // // async updateUsername(user: IAuthUser, newUsername: string) {
  // //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  // //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  // //     ...user,
  // //     username: newUsername
  // //   });
  // // }

  // // async updateSex(user: IAuthUser, newSex: string) {
  // //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  // //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({
  // //     ...user,
  // //     personalData: {
  // //       ...user.personalData,
  // //       sex: newSex
  // //     }
  // //   });
  // // }

  // // async updateLanguage(user: IAuthUser, newLanguage: string) {
  // //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  // //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({
  // //     ...user,
  // //     personalData: {
  // //       ...user.personalData,
  // //       language: newLanguage
  // //     }
  // //   });
  // // }

  // // async updateAvatar(user: IAuthUser, path: string) {
  // //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  // //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  // //     ...user,
  // //     profile: {
  // //       ...user.profile,
  // //       avatar: path
  // //     }
  // //   });
  // // }

  // // /* */
  // // async updateProfile(user: IAuthUser): Promise<IProgress> {
  // //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  // //   const defaultUserData: IAuthUser = {
  // //     id: fbUser.uid,
  // //     username: fbUser.displayName ? fbUser.displayName : fbUser.email,
  // //     email: fbUser.email,
  // //     preferences: {
  // //       allowDownloadOnDataConnection: true
  // //     },
  // //     profile: {
  // //       avatar: fbUser.photoURL
  // //     },
  // //     createdAt: fb.firestore.FieldValue.serverTimestamp(),
  // //     updatedAt: fb.firestore.FieldValue.serverTimestamp()
  // //   };
  // //   const dbUserPayload: DocumentSnapshot<IAuthUser> = (await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).snapshotChanges().pipe(
  // //     take(1)
  // //   ).toPromise()).payload;
  // //   if (!dbUserPayload.exists) {
  // //     this.store$.dispatch(new ProgressUpdateAction({ id: fbUser.uid }));
  // //     await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({ ...defaultUserData });
  // //   }
  // //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  // //     ...user,
  // //     updatedAt: fb.firestore.FieldValue.serverTimestamp()
  // //   });
  // //   return this.goToLastPage();
  // // }

  // // async goToLastPage(): Promise<IProgress> {
  // //   const progress: Promise<IProgress> = this.store$.select(selectActiveProgress).pipe(
  // //     take(1)
  // //   ).toPromise();

  // //   this.store$.dispatch(
  // //     new RouterGoAction({
  // //       path: [_.get(await progress, 'lastUrl', '')]
  // //     })
  // //   );
  // //   return progress;
  // // }
}
