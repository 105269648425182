import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { ProgressBarService } from '../..';

@Injectable()
export class LoadingService {
  //this service helps to handle parallel requests loading

  private counter$ = new BehaviorSubject(0);
  public isLoading$ = this.counter$.pipe(map((counterValue) => !!counterValue));

  constructor(private progressBarService: ProgressBarService) {
    this.isLoading$.pipe(skip(1)).subscribe((isLoading) => {
      if (!isLoading) {
        this.progressBarService.stop();
      }
    });
  }

  private changeCounter(difference: number) {
    this.counter$.next(Math.max(this.counter$.value + difference, 0));
  }

  public start(message = 'PROGRESS.FETCH_DATA') {
    this.changeCounter(+1);
    this.progressBarService.start(message);
  }

  public stop() {
    this.changeCounter(-1);
  }

  public withLoading = (value: Observable<any> | Promise<any>) => {
    this.start();
    if (value instanceof Observable) {
      value = value.toPromise();
    }

    value.finally(() => {
      this.stop();
    });

    return value;
  };
}
