export class FrontendApiVaristarConfig {
  // endpointUrl: string;                          // 'https://trading-dot-ocs-io-demo.appspot.com',
  // apiUri: string;                               // '/internal/v1',
  // headers: {
  //   request: { [headerName: string]: string },  // { 'Content-Type': 'application/json', ... }
  //   authToken: string,                          // 'X-Huwwi-Auth-Token'
  // };
  // path: { [featureName: string]: string };      // {  resourcs: '/resources', ... }

  apiUrl: string; // 'http://localhost'
  apiPath: { [featureName: string]: string[] }; // {  GEOJSON: ['api', 'resource', 'resource.id', 'geojson'] }
  apiTimeout: number;
  // optional
  headers?: {
    request: { [headerName: string]: string }; // { 'Content-Type': 'application/json', ... }
    authToken: string; // 'X-Huwwi-Auth-Token'
  };

  onboardingEmailTokenSecret: string;
  dags: {
    [taskName: string]: string;
  };
  varistarJohnDeereLogoutUrl: string;
}
