<ui-date-picker
  [selectedDate$]="_startDate$"
  (dateChange)="startDateChange.emit($event)"
  [name]="nameStart"
  [max$]="_endDate$"
  [min$]="min$"
></ui-date-picker>
<ui-date-picker
  [selectedDate$]="_endDate$"
  (dateChange)="endDateChange.emit($event)"
  [name]="nameEnd"
  [min$]="_startDate$"
  [max$]="max$"
></ui-date-picker>
