import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-confirm-dialog',
  templateUrl: './select-confirm-dialog.component.html',
  styleUrls: ['./select-confirm-dialog.component.scss'],
})
export class SelectConfirmDialogComponent {
  title: string;
  question: string;

  constructor(
    public dialogRef: MatDialogRef<SelectConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      question: string;
      buttons: { text: string; primary: boolean; returnValue: number }[];
    },
  ) {
    // Update view with given values
    this.title = data.title;
    this.question = data.question;
  }

  onConfirm(value: number): void {
    this.dialogRef.close(value);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
