import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TFields } from './TFields';

@Index('t_field_group_FK', ['idSmlouvy'], {})
@Entity('t_field_group', { schema: 'VaristarIS' })
export class TFieldGroup {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_field_group' })
  idFieldGroup: number;

  @Column('varchar', { name: 'color', length: 7 })
  color: string;

  @Column('varchar', { name: 'name', length: 100 })
  name: string;

  @Column('int', { name: 'id_smlouvy' })
  idSmlouvy: number;

  @OneToMany('TFields', 'tFields')
  tFields: TFields[];

  // @ManyToOne('TSmlouvy', 'tSmlouvy.tFieldGroups', {
  //   onDelete: "CASCADE",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_smlouvy", referencedColumnName: "idSmlouvy" }])
  // idSmlouvy2: TSmlouvy;
}
