import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { SettingsFacade } from './+state/settings.facade';
import { initialState, settingsReducer, SETTINGS_FEATURE_KEY } from './+state/settings.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(SETTINGS_FEATURE_KEY, settingsReducer, {
      initialState: initialState,
    }),
  ],

  providers: [SettingsFacade],
})
export class FrontendSettingsModule {}
