import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

// import { THnojivaOrg } from "./THnojivaOrg";

@Index('DRUH', ['druh'], {})
@Index('KAT_N', ['katN'], {})
@Index('NAZ', ['naz'], {})
@Index('ORG', ['org'], {})
@Index('TYP_MJ', ['typMj'], {})
@Index('VYKALY', ['vykaly'], {})
@Index('VYT', ['vyt'], {})
@Entity('t_hnojiva', { schema: 'VaristarIS' })
export class THnojiva {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_hnojiva' })
  idtHnojiva: string;

  @Column('varchar', {
    name: 'REC',
    nullable: true,
    comment: 'Registrační číslo hnojiva dle ÚKZÚZ. Pouze u reg. hnojiv.',
    length: 45,
  })
  rec: string | null;

  @Column('varchar', {
    name: 'EVC',
    nullable: true,
    comment:
      'Evidenční číslo hnojiva, není vyplněno u typu MD = HS - statková hnojiva a HM-hlavní hnojiva.',
    length: 45,
  })
  evc: string | null;

  @Column('varchar', {
    name: 'NAZ',
    nullable: true,
    comment: 'Název hnojiva.',
    length: 255,
  })
  naz: string | null;

  @Column('varchar', {
    name: 'ZAD',
    nullable: true,
    comment: 'Obchodní jméno žadatele',
    length: 255,
  })
  zad: string | null;

  @Column('varchar', {
    name: 'VYR',
    nullable: true,
    comment: 'Obchodní jméno výrobce',
    length: 255,
  })
  vyr: string | null;

  @Column('int', {
    name: 'VYKALY',
    nullable: true,
    comment:
      'Může nabývat hodnoty 1/0 : 1 - Hnojivo = výkaly, 0 - nejde o výkaly. 1 může nabývat jen u hnojiv původem z číselníku statkových hnojiv (HS).',
  })
  vykaly: number | null;

  @Column('varchar', {
    name: 'POP',
    nullable: true,
    comment: 'Charakteristika - popis',
    length: 1000,
  })
  pop: string | null;

  @Column('varchar', {
    name: 'CH_N',
    nullable: true,
    comment: 'Obsah N v %.',
    length: 11,
  })
  chN: string | null;

  @Column('varchar', {
    name: 'CH_P',
    nullable: true,
    comment: 'Obsah P2O5 v %.',
    length: 11,
  })
  chP: string | null;

  @Column('varchar', {
    name: 'CH_K',
    nullable: true,
    comment: 'Obsah K2O v %.',
    length: 11,
  })
  chK: string | null;

  @Column('varchar', {
    name: 'CH_CA',
    nullable: true,
    comment: 'Obsah CaO v %.',
    length: 11,
  })
  chCa: string | null;

  @Column('varchar', {
    name: 'CH_MG',
    nullable: true,
    comment: 'Obsah MgO v %.',
    length: 11,
  })
  chMg: string | null;

  @Column('varchar', {
    name: 'CH_NA',
    nullable: true,
    comment: 'Obsah Na2O v %.',
    length: 11,
  })
  chNa: string | null;

  @Column('varchar', {
    name: 'CH_S',
    nullable: true,
    comment: 'Obsah S v %.',
    length: 11,
  })
  chS: string | null;

  @Column('varchar', {
    name: 'CH_CL',
    nullable: true,
    comment: 'Obsah chloridů v %.',
    length: 11,
  })
  chCl: string | null;

  @Column('varchar', {
    name: 'CH_SL',
    nullable: true,
    comment: 'Obsah spalitelných látek v %.',
    length: 11,
  })
  chSl: string | null;

  @Column('varchar', {
    name: 'CH_ZN',
    nullable: true,
    comment: 'Obsah Zn v %.',
    length: 11,
  })
  chZn: string | null;

  @Column('varchar', {
    name: 'CH_CU',
    nullable: true,
    comment: 'Obsah Cu v %.',
    length: 11,
  })
  chCu: string | null;

  @Column('varchar', {
    name: 'CH_FE',
    nullable: true,
    comment: 'Obsah Fe v %.',
    length: 11,
  })
  chFe: string | null;

  @Column('varchar', {
    name: 'CH_B',
    nullable: true,
    comment: 'Obsah B v %.',
    length: 11,
  })
  chB: string | null;

  @Column('varchar', {
    name: 'CH_MN',
    nullable: true,
    comment: 'Obsah Mn v %.',
    length: 11,
  })
  chMn: string | null;

  @Column('varchar', {
    name: 'CH_MO',
    nullable: true,
    comment: 'Obsah Mo v %.',
    length: 11,
  })
  chMo: string | null;

  @Column('varchar', {
    name: 'CH_SE',
    nullable: true,
    comment: 'Obsah Se v %.',
    length: 11,
  })
  chSe: string | null;

  @Column('varchar', {
    name: 'CH_PH_OD',
    nullable: true,
    comment: 'pH od',
    length: 11,
  })
  chPhOd: string | null;

  @Column('varchar', {
    name: 'CH_PH_DO',
    nullable: true,
    comment: 'pH do',
    length: 11,
  })
  chPhDo: string | null;

  @Column('varchar', {
    name: 'CH_EL_V',
    nullable: true,
    comment: 'Elektrická vodivost',
    length: 11,
  })
  chElV: string | null;

  @Column('int', {
    name: 'KAT_N',
    nullable: true,
    comment:
      'Kategorie hnojiva z pohledu obsahu dusíku - možné hodnoty:  1- Nedusíkaté hnojivo 2- Minerální hnojivo 3- Hnojivo s rychle uvol. Dusíkem 4- Hnojivo s pomalu uvolnitelným dusíkem 5- Upravený kal 6- Rostlinný zbytek',
  })
  katN: number | null;

  @Column('int', {
    name: 'NS2011',
    nullable: true,
    comment: 'Přepočítací koeficient N v % pro nitrátovou směrnici platnou do 07/2012',
  })
  ns2011: number | null;

  @Column('int', {
    name: 'NS2012',
    nullable: true,
    comment: 'Přepočítací koeficient N v % pro nitrátovou směrnici platnou od 08/2012',
  })
  ns2012: number | null;

  @Column('int', {
    name: 'ORG',
    nullable: true,
    comment: 'Příznak, zda jde o organické hnojivo - 1/0.',
  })
  org: number | null;

  @Column('int', {
    name: 'VYT',
    nullable: true,
    comment:
      'Slouží k vytřídění dat, n1- hnojivo volně ložené, n2- hnojivo malospotřebitelské balení, n3- pomocná látka volně ložená, n4- pomocná látka malospotřebitelské balení',
  })
  vyt: number | null;

  @Column('int', { name: 'PLA', nullable: true, comment: 'Platnost - 1/0.' })
  pla: number | null;

  @Column('varchar', {
    name: 'KOEFPREP',
    nullable: true,
    comment:
      'Koeficient přepočtu mezi hmotnostními a objemovými jednotkami (při použití objemových jednotek je nutné číselníkový obsah živin pronásobit tímto koeficientem).',
    length: 11,
  })
  koefprep: string | null;

  @Column('varchar', {
    name: 'MD',
    nullable: true,
    comment:
      'Odkazuje na tabulku t_hnojiva_md kde je typ číselníku - HS - hnojiva statková, HD - registrovaná hnojiva, HM - hlavní hnojiva, HO - ohlášená hnojiva, HV - vzájemně uznaná, HE - hnojiva ES.',
    length: 11,
  })
  md: string | null;

  @Column('varchar', {
    name: 'TYP_MJ',
    nullable: true,
    comment: 'Typ obvyklé měrné jednotky použití. H-hmostnostní O-objemová',
    length: 11,
  })
  typMj: string | null;

  @Column('varchar', {
    name: 'CENA',
    nullable: true,
    comment: 'Obvyklá cena dle VÚZT',
    length: 45,
  })
  cena: string | null;

  @Column('varchar', {
    name: 'TYP',
    nullable: true,
    comment: 'Typ hnojiva - název',
    length: 55,
  })
  typ: string | null;

  @Column('int', {
    name: 'DRUH',
    nullable: true,
    comment:
      'ID druhu hnojiva. 1\tMinerální - jednosložkové, 2 Minerální - vícesložkové, 3 Minerální - se stopovými prvky, 4 Minerální - vápenaté a vápenatohořečnaté, 5 Organické, 6 Organominerální, 7 Pomocné látky - pomocné půdní látky, 8 Pomocné látky - pomocné rostlinné přípravky, 9 Pomocné látky - substráty, 10 Minerální - s druhotnými živinami, 11 Popele ze samostatného spalování biomasy, 12\tKompost, 13 Digestát, 14 Inhibitory dusíku, 15 Minerální s inhibitorem',
  })
  druh: number | null;

  @Column('tinyint', {
    name: 'EZ',
    nullable: true,
    comment: 'Příznak zda jde o ekologické zemědělství',
  })
  ez: number | null;

  // @OneToMany(() => THnojivaOrg, (tHnojivaOrg) => tHnojivaOrg.pZnak2)
  // tHnojivaOrgs: THnojivaOrg[];
}
