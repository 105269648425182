import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { resetState } from './reset.meta-reducer';
import { filterEmptyNrgxAction } from './filter-empty-ngrx-action.meta-reducer';

export interface State {}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [resetState, filterEmptyNrgxAction]
  : [resetState, filterEmptyNrgxAction];
