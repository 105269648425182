import { TFields } from './entities/TFields';
import { TZakazkyFields } from './entities/TZakazkyFields';

// tslint:disable-next-line: no-empty-interface
export interface OrderField extends TZakazkyFields {}

export function getOrderFieldUnitMap(orderField: TZakazkyFields) {
  const liquidUnitMap = {
    1: ' (l/ha)',
    1000: ' (m3)',
  };

  const solidUnitMap = {
    1: ' (kg/ha)',
    1000: ' (t)',
  };

  if (!orderField) {
    return {};
  }

  return orderField.liquid ? liquidUnitMap : solidUnitMap;
}

export const OrderField = {
  selectId: (a: TZakazkyFields) => {
    //In this case this would be optional since primary key is id
    return String(a.idtZakazkyFields);
  },
};
