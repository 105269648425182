export interface ProductApplication {
  name: string;
  elementN: number;
  // area: number,
  areaPerFid: number;
  areaPerPlant: number;
  // consumptionPerArea: number,
  // averageRate: number,
  volume: number; // Spotreba hnojiva celkem
  avgVolumePerHa: number; // Prumerna spotreba hnojiva (na jeden hektar)
  savings: number;
}

export const ProductApplication = {
  // selectId: (a: TZakazkyFields) => {
  //   //In this case this would be optional since primary key is id
  //   return String(a.idtZakazkyFields);
  // },
};
