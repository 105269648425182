import { Column, Entity, OneToMany } from 'typeorm';
import { TTerminaly } from './TTerminaly';

@Entity('t_dropbox_direct', { schema: 'VaristarIS' })
export class TDropboxDirect {
  @Column('int', {
    primary: true,
    name: 'idt_dropbox_direct',
    comment: 'primární klíč - číslo skupiny Directů',
  })
  idtDropboxDirect: number;

  @Column('varchar', {
    name: 'refresh_token',
    nullable: true,
    comment: 'Dropbox refresh token pro přístup do Dropboxu',
    length: 255,
  })
  refreshToken: string | null;

  @OneToMany('TTerminaly', 'directRefreshToken2')
  tTerminalies: TTerminaly[];
}
