import { TFieldGroup } from './entities/TFieldGroup';

// tslint:disable-next-line: no-empty-interface
export interface FieldGroup extends TFieldGroup {}

export const FieldGroup = {
  selectId: (x: TFieldGroup) => {
    return x.idFieldGroup;
  },
};
