import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  NG_VALIDATORS,
  Validator,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { isDefined } from '@angular/compiler/src/util';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { KeyValue } from '../dfc-keys';
import { DfcValue } from '../dfc-value';
import { Subject } from 'rxjs';
import { isString, isNumber, isArray } from 'util';
import { ValueTypes } from '../dynamic-form-control-value/dynamic-form-control-value.component';
import { DfcInput } from '../dfc-input';

// export interface Keys {
//   key: string,
//   value: any
// };
// export interface ConversionConstants {
//   // data z databaze Conversion[] s pridanym atributem constants (conversionData[]) z preformatovaneho atributy data (conversionDataObject)
//   constants: ConversionData[];
// }
// export interface ConversionData {
//   deviceType: string;
//   deviceId: string;
//   indexOrName: string;
//   key: string;
//   value: number;
// }
// export interface ConversionDataObject {
//   [deviceType: string]: {
//     [deviceId: string]: {
//       [indexOrName: string]: {
//         [key: string]: number; // value
//       }
//     }
//   };
// }

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-control-keys',
  templateUrl: './dynamic-form-control-keys.component.html',
  styleUrls: ['./dynamic-form-control-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicFormControlKeysComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DynamicFormControlKeysComponent,
      multi: true,
    },
  ],
})
export class DynamicFormControlKeysComponent implements ControlValueAccessor, Validator {
  @Input() form?: FormGroup;
  // @Input() validFrom?: Date;
  // protoze je autosave tak se nezobrazi save a cance u kazde polozky ale jen souhrnne
  @Input() enable = {
    save: false,
    cancel: false,
    delete: false,
  };

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() event = new EventEmitter();

  value: any;
  //Conversion[]; // pole z Conversion objektu jak je ulozen v DB
  // conversions: ConversionConstants[]; // pole objektu s atributem constants obsahujicim prevedeny ConversionDataObject objekt na ConversionData pole
  isInEdit = []; // je dany index contant v editacnim rezimu

  isDisabledValue = false;
  controls = [];

  keyFilter = '';
  keyvalue: KeyValue;
  data;
  isArray = false;
  isObject = false;
  isInvalid = false;
  exception: string;

  valueType: ValueTypes;
  get valueTypes() {
    return ValueTypes;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  onChangeValue = (value: any): void => {};
  onValidatorChange = (value?: any): void => {};
  isDisabled = (i) => this.isDisabledValue && !this.isInEdit[i];

  writeValue(value: any) {
    try {
      this.value = value;
      const v = _.isString(this.value) ? JSON.parse(this.value) : this.value;
      // this.data = _.reduce(this.value, (acc, v, k) => {
      //   acc[k.toString()] = { key: k, value: v };
      //   return acc;
      // }, {});
      this.data = _.reduce(
        this.value,
        (acc, v, k) => {
          acc.push({ key: k, value: v });
          return acc;
        },
        [],
      );
      this.isArray = _.isArray(v);
      this.isObject = !this.isArray && _.isObject(v);
      if (this.isArray) this.valueType = ValueTypes.ARRAY;
      if (this.isObject) this.valueType = ValueTypes.OBJECT;
    } catch (ex) {
      this.isInvalid = true;
      this.exception = ex.toString();
    }

    this.controls = [
      new DfcInput<string>({
        key: 'key',
        // value: k,
        placeholder: _.isArray
          ? 'PLACEHOLDER.CONFIGURATION.SETTINGS.INDEX'
          : 'PLACEHOLDER.CONFIGURATION.SETTINGS.KEY',
        order: 1.1,
        required: true,
        disabled: this.isArray,
        validator: Validators.compose([Validators.required]),
        validatorMessages: {
          // required: 'VALIDATION.LOCALITY.NAME'
        },
        flex: this.isArray ? '10' : '20',
        // enable: {
        //   save: false,
        //   cancel: false
        // }
      }),
      new DfcValue<any>({
        key: 'value',
        placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE',
        order: 1.2,
        required: true,
        validator: Validators.compose([Validators.required]),
        validatorMessages: {
          // required: 'VALIDATION.LOCALITY.NAME'
        },
        flex: '100',
        enable: {
          // delete: true,
          save: false,
          cancel: false,
        },
      }),
    ];
    // switch (this.valueType) {

    //   case ValueTypes.ARRAY:
    //   case ValueTypes.OBJECT:
    //     this.controls = _.reduce(this.data, (acc, v, k) => {
    //       acc.push(
    //         new DfcInput<string>({
    //           key: `${k}.key`,
    //           // value: k,
    //           placeholder: isArray ? 'PLACEHOLDER.CONFIGURATION.SETTINGS.INDEX' : 'PLACEHOLDER.CONFIGURATION.SETTINGS.KEY',
    //           order: acc.length + 0.1,
    //           required: true,
    //           disabled: this.isArray,
    //           validator: Validators.compose([Validators.required]),
    //           validatorMessages: {
    //             // required: 'VALIDATION.LOCALITY.NAME'
    //           },
    //           flex: this.isArray ? '10': '20',
    //           // enable: {
    //           //   save: false,
    //           //   cancel: false
    //           // }
    //         }),
    //         new DfcValue<any>({
    //           key: `${k}.value`,
    //           placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE',
    //           order: acc.length + 0.2,
    //           required: true,
    //           validator: Validators.compose([Validators.required]),
    //           validatorMessages: {
    //             // required: 'VALIDATION.LOCALITY.NAME'
    //           },
    //           flex: '100',
    //           enable: {
    //             delete: true,
    //             save: false,
    //             cancel: false
    //           }
    //         })
    //       );
    //       return acc;
    //     }, []);
    //     break;
    // }

    // this.controls = _.map(this.value, (keyvalue, i) => {
    //   return [
    //     new DfcValue<KeyValue>({
    //       key: 'value',
    //       placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE',
    //       value: value,
    //       order: 1,
    //       flex: '100',
    //       hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE',
    //       validatorMessages: {
    //         // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
    //       },
    //       enable: {
    //         add: true,
    //         copy: true,
    //         delete: true,
    //       }
    //     }),
    //   ];
    // });
    // this.change.emit(this.value);
    this.cdr.markForCheck();
  }

  onChange() {
    this.onChangeValue(this.value);
    this.onValidatorChange(this.value);
  }

  registerOnChange(fn) {
    this.onChangeValue = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabledValue = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isValid = true;
    // const isValid = _.reduce(control.value, (isValid, value, id) => {
    //   return _.reduce(value.data, (isValid, value, deviceType) => {
    //     return deviceType !== '' && _.reduce(value, (isValid, value, deviceId) => {
    //       return deviceId !== '' && _.reduce(value, (isValid, value, indexOrName) => {
    //         return indexOrName !== '' && _.reduce(value, (isValid, value, key) => {
    //           return key !== '' && !isNaN(value);
    //         }, isValid);
    //       }, isValid);
    //     }, isValid);
    //   }, isValid);
    // }, true);

    // // this.onValidatorChange();
    return isValid ? null : { custom: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  // changeValidFrom(event, i, validFromOld) {
  //   const validFromNew = event.value;
  //   let x = _.cloneDeep(this.value);
  //   x[i].validFrom = moment(validFromNew).toDate();
  //   this.writeValue(x);
  //   return this.onChange();
  // }

  onSelect(keyvalue: KeyValue) {
    this.keyvalue = keyvalue;
  }

  onSave(event, i) {
    if (this.isArray) {
      this.value[i] = event.value;
    }
    if (this.isObject) {
      this.value[event.key] = event.value;
    }
    // let x = _.cloneDeep(this.value);
    // x[i]= event.value;
    // let data = event;
    // if (this.isArray) {
    //   // prevedu to na array
    //   data = _.reduce(event, (acc, v) => {
    //     acc.push(v.value);
    //     return acc;
    //   }, []);

    // }
    // if (this.isObject) {
    //   // prevedu to na array
    //   data = _.reduce(event, (acc, v) => {
    //     acc[v.key.toString()] = v.value;
    //     return acc;
    //   }, {});

    // }
    // this.writeValue(x);
    this.writeValue(this.value);
    return this.onChange();
  }

  onModify(event, i) {
    // let x = _.cloneDeep(this.value);
    // x[i] = { ...x[i], ...event };
    // this.onChangeValue(event);
  }

  // onCopy(i) {
  //   const today = moment().startOf('day');
  //   let x = _.cloneDeep(this.value);
  //   let xx = _.cloneDeep(this.value[i]);
  //   xx.id = 'NEW';
  //   xx.validFrom = today.toDate();
  //   x.push(xx)
  //   this.writeValue(x);
  //   return this.onChange();
  // }

  onDelete(event, i, value) {
    if (this.isArray) {
      this.value.splice(i, 1);
    }
    if (this.isObject) {
      delete this.value[event.key];
    }
    // let x = _.cloneDeep(this.value);
    // if (this.isArray)
    //   x.splice(event.key, 1);
    // if (this.isObject)
    //   delete x[event.key];
    // this.writeValue(x);
    // this.onChange();
    // this.value.push(null);
    // this.value = _.cloneDeep(this.value);
    this.writeValue(this.value);
    // this.delete.emit(this.value);
    // this.writeValue(x);
    this.onChange();
    // this.onChangeValue(this.value);
  }

  onAdd(event) {
    if (this.isArray) {
      this.value.push(null);
    }
    if (this.isObject) {
      this.value['NEW'] = null;
    }
    this.writeValue(this.value);
    return this.onChange();
  }

  // onCopyToLocality(i) {
  //   let x = _.cloneDeep(this.value);

  //   this.event.emit(this.value[i]);
  //   // x.splice(i, 1)
  //   // this.writeValue(x);
  //   // this.onChange();
  // }

  applyFilter(filterValue: string) {
    this.keyFilter = filterValue.trim().toLowerCase();
  }
}
