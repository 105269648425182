import { Column, Entity, OneToMany } from 'typeorm';

import { TTabletMachines } from './TTabletMachines';
import { TTerminaly } from './TTerminaly';

@Entity('t_tablet_machines_type', { schema: 'VaristarIS' })
export class TTabletMachinesType {
  @Column('int', { primary: true, name: 'idt_tablet_machines_type' })
  idtTabletMachinesType: number;

  @Column('varchar', {
    name: 'description',
    nullable: true,
    comment: 'Popis typu stroje ve formě textového kódu',
    length: 45,
  })
  description: string | null;

  // @OneToMany(() => TTabletMachines, (tTabletMachines) => tTabletMachines.mType2)
  @OneToMany('TTabletMachines', 'mType2')
  tTabletMachines: TTabletMachines[];

  // @OneToMany(() => TTerminaly, (tTerminaly) => tTerminaly.machineType2)
  @OneToMany('TTerminaly', 'machineType2')
  tTerminalies: TTerminaly[];
}
