import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent implements AfterViewInit, OnInit, OnDestroy {
  subscription = new Subscription();
  public _isChecked;
  public _id = Math.random().toString();

  @Input() set checked(isChecked: boolean) {
    this._isChecked = isChecked;
  }
  @Input() checked$: Observable<boolean>;
  @Input() indeterminate: boolean;
  @Input() disabled: boolean;
  @Input() label: HTMLElement;

  @Output() change = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.checked$) {
      this.subscription.add(
        this.checked$.subscribe((checked) => {
          this._isChecked = checked;
        }),
      );
    }
  }

  ngAfterViewInit() {
    if (this.label) {
      this.label.style.cursor = 'pointer';
      this.label.addEventListener('click', (event) => {
        this.handleChange(event);
      });
    }
  }

  ngOnDestroy() {
    if (this.label) {
      this.label.removeEventListener('click', (event) => {
        this.handleChange(event);
      });
    }

    this.subscription.unsubscribe();
  }

  handleChange = (event) => {
    event.stopPropagation();
    this._isChecked = !this._isChecked;
    this.change.emit({ checked: this._isChecked });
  };
}
