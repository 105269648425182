import { TKultura } from './entities/TKultura';

// tslint:disable-next-line: no-empty-interface
export interface Kultura extends TKultura {}

export const Kultura = {
  selectId: (x: TKultura) => {
    return x.idDruhpudy;
  },
};
