import { AuthAction, AuthActionTypes } from './auth.actions';
import {
  Account,
  AuthUser,
  AccountRole,
  Organization,
  User,
  Holding,
  Customer,
  Contract,
  VaristarProfile,
  PermissionSettings,
} from '@varistar-apps/shared/data';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthCredentials {
  email: string;
  password?: string;
}

// // Normalizovany format informaci o uzivateli z Firebase UserCredential a pripadne z profilu ve Firestore
// export interface AuthData {
//   id?: string; // User UID z Firebase Authentication
//   displayName?: string; // UserCredential.user.displayName nebo UserCredential.user.email
//   email?: string; // UserCredential.user.email
//   preferences?: {
//     language?: string; // TBD: Firestore user profile
//   };
//   authProfile?: {
//     refreshToken?: string // UserCredential.user.refreshToken
//   }
//   // profile?: Object; email
//   // createdAt?;
//   // updatedAt?;
// }

const defaultUser = new AuthUser(null, null, null);

export interface AuthState {
  loading: boolean; // loading
  authenticated: boolean; // user is succesfully authenticated (can be done without user action via login popup)
  authorized: boolean; // user is succesfully authorized against Varistar TUser (can be done without user action via login popup)
  admin: boolean; // user account have role ADMIN
  // user: User;

  // isAuthenticating: boolean; // authentication in progress
  // isAuthenticated: boolean; // user is already authenticated (can be done without user action via login popup)
  // isLoggedIn: boolean; // login successfully finished
  // displayName: string; // displayName or email
  // authData: AuthData; // selected attributtes form firebase UserCredential object
  //
  //
  error?: any; // last none error (if any)

  account?: Account;
  varistar?: VaristarProfile;

  // role?: AccountRole;
  organization?: Organization;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  loading: false,
  authenticated: false,
  authorized: false,
  admin: false,
  // user: {
  //   ...defaultUser,
  // },
  account: {
    id: null,
    user: {
      ...defaultUser,
    },
    //...
  },
  varistar: {
    // idtUser: null,
    // holdingId: null,
    // customerId: null,
    // contractId: null,
    user: null,
    holding: null,
    customer: null,
    contract: null,
    permissions: null,
  },
  // role: null,
  // organization: null,
  // isAuthenticating: false,
  // isAuthenticated: false,
  // isLoggedIn: false,
  // displayName: '',
  // authData: null,
  error: null,
};

export function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.GetUser:
      return {
        ...state,
        loading: true,
      };

    case AuthActionTypes.GetVaristarProfile:
      return {
        ...state,
        loading: true,
      };

    case AuthActionTypes.Authenticated:
      return {
        ...state,
        // user: {
        //   ...action.payload
        // },
        loading: true, // jeste pockam do AccountUpdated
        authenticated: true,
        account: {
          ...state.account,
          // id: action.payload.uid,
          user: {
            ...state.account?.user,
            ...action.payload,
          },
        },
        varistar: {
          ...state.varistar,
        },
        error: null,
      };

    case AuthActionTypes.NotAuthenticated:
      return {
        ...state,
        ...initialState,
        loading: false,
        authenticated: false,
        authorized: false,
        account: {
          id: null,
          user: {
            ...defaultUser,
          },
          //...
        },
        varistar: {
          user: null,
          holding: null,
          customer: null,
          contract: null,
        },
        // role: null,
        error: null,
      };

    case AuthActionTypes.AuthError:
      return {
        ...state,
        ...action.payload,
        loading: false,
        authenticated: false,
        authorized: false,
        user: null,
        account: null,
        varistar: null,
        // role: null,
        error: action.payload,
      };

    case AuthActionTypes.Authorized:
      return {
        ...state,
        loading: true, // jeste pockam do AccountUpdated
        authorized: true,
        account: {
          ...state.account,
        },
        varistar: {
          ...state.varistar,
          user: {
            // ...state.varistar.user,
            ...(action.payload as User),
          },
        },
        error: null,
      };

    case AuthActionTypes.NotAuthorized:
      return {
        ...state,
        ...initialState,
        loading: false,
        authenticated: false,
        account: {
          id: null,
          user: {
            ...defaultUser,
          },
          //...
        },
        varistar: {
          user: null,
          holding: null,
          customer: null,
          contract: null,
          permissions: null,
        },
        // role: null,
        error: null,
      };

    case AuthActionTypes.SignUpByEmail:
    case AuthActionTypes.LoginByEmail:
    case AuthActionTypes.LoginByGoogle:
    case AuthActionTypes.LoginByFacebook:
    case AuthActionTypes.LoginByMicrosoft:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case AuthActionTypes.Logout:
      return {
        ...state,
        loading: true,
      };

    case AuthActionTypes.AccountUpdated:
      return {
        ...state,
        loading: false,
        account: {
          ...state.account,
          ...action.payload,
        },
        // admin: (action.payload as Account).role === AccountRole.ADMIN,
        // // admin: action.payload.admin,
        // // role: action.payload.role
        // // role: action.payload
        // // displayName: action.payload.displayName
      };

    case AuthActionTypes.VaristarProfileUpdated:
      return {
        ...state,
        // loading: false,
        // authorized: true,
        varistar: {
          ...state.varistar,
          // ...action.payload,
          user: {
            ...state.varistar?.user,
            ...action.payload?.user,
          },
          person: {
            ...state.varistar?.person,
            ...action.payload?.person,
          },
          holding: {
            ...state.varistar?.holding,
            ...action.payload?.holding,
          },
          customer: {
            ...state.varistar?.customer,
            ...action.payload?.customer,
          },
          contract: {
            ...state.varistar?.contract,
            ...action.payload?.contract,
          },
          annex: {
            ...state.varistar?.annex,
            ...action.payload?.annex,
          },
          department: {
            ...state.varistar?.department,
            ...action.payload?.department,
          },
          permissions: {
            ...state.varistar.permissions,
            ...action.payload?.permissions,
          },
        },
      };

    // case AuthActionTypes.AuthSuccess:
    //   return {
    //     ...state,
    //     isAuthenticating: false,
    //     isLoggedIn: true,
    //     error: null
    //   };

    // case AuthActionTypes.UpdateSuccess:
    //   return {
    //     ...state,
    //     displayName: action.payload.displayName
    //   };

    // case AuthActionTypes.AuthStateChanged:
    //   return {
    //     ...state,
    //     isLoggedIn: action.payload !== null,
    //     isAuthenticated: action.payload !== null,
    //     displayName: action.payload ? action.payload.displayName : '',
    //     authData: action.payload ? action.payload : null
    //   };

    // case AuthActionTypes.AuthFailure:
    //   return {
    //     ...state,
    //     isAuthenticating: false,
    //     error: action.payload
    //   };

    // case AuthActionTypes.Logout:
    //   return {
    //     ...state,
    //     authData: null,
    //     isAuthenticated: false,
    //     isLoggedIn: false,
    //     isAuthenticating: false,
    //     displayName: ''
    //   };
  }
  return state;
}
