// // export interface IUser {
// //   uid?: string; // User UID z Firebase Authentication
// //   email?: string; // UserCredential.user.email
// //   displayName?: string; // UserCredential.user.displayName nebo UserCredential.user.email
// // }

import { Annex } from './annex.model';
import { Contract } from './contract.model';
import { Customer } from './customer.model';
import { THolding } from './entities/THolding';
import { Holding } from './holding.model';
import { Person } from './person.model';
import { Department } from './department.model';
import { User } from './user.model';

// // export class User implements IUser {
// //   constructor(
// //     public uid?: string,
// //     public email?: string,
// //     public displayName?: string,
// //   ) { }
// // }

// import { PropsFilterFnFactory } from '@ngrx/data';
// import { TUser } from './entities/TUser';

export interface VaristarProfile {
  user?: Partial<User>; // zalogovany uzivatel podle t_user
  person?: Partial<Person>; // zalogovany uzivatel podle t_user
  holding?: Partial<Holding>; // vybrany holding
  customer?: Partial<Customer>; // vybrany holding
  department?: Partial<Department>;
  annex?: Annex; //posledni platny dodatek
  contract?: Partial<Contract>; // vybrany holding
  permissions?: any;
  // idtUser?: string, // id v t_user podle uid loginu
  // idtUser?: string, // vybrany uzivatel v t_user
  // holdingId?: string, // vybrany holding
  // customerId?: string, // vybrany zakaznik
  // contractId?: string, // vybrana smlouva
  // role?: string;
}

export const VaristarProfile = {
  selectId: (a: VaristarProfile) => {
    return a.user?.uid;
    // return a.user?.idtUser;
    // return a.idtUser;
  },
  // sortByName: (a: User, b: User): number => {
  //   return a.name && a.name.localeCompare(b.name);
  // },
  // filterFn: PropsFilterFnFactory(['idtUser', 'country'])
};
