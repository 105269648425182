<h1 mat-dialog-title></h1>
<mat-dialog-content class="mat-typography">
  <p>
    <span translate [translate]="message"></span>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    [mat-dialog-close]="false"
    cdkFocusInitial
    class="c-rounded-button c-rounded-button-outlined c-rounded-button--small"
  >
    <span translate>BUTTON.CLOSE</span>
  </button>
</mat-dialog-actions>
