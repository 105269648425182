import { TTabletWifiSculFw } from "./entities/TTabletWifiSculFw";

// tslint:disable-next-line: no-empty-interface
export interface TabletWifiSculFw extends TTabletWifiSculFw {
}

export const TabletWifiSculFw = {
  selectId: (x: TTabletWifiSculFw) => {
    return x.id;
  },
}
