import { Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FrontendUiModuleConfig } from '../ui.interfaces';

export interface RemovedSteps {
  [step: string]: boolean;
}

export interface ModifiedSteps {
  [step: string]: WizardStep;
}

export interface WizardStep {
  stepKey: string;
  path: string;
  query?: any;
  title: string;
  subtitle?: string;
  // params: string[];
  prev?: string[];
  next?: string[];
  quit?: string[];

  completed?: boolean;
  editable?: boolean;
}

export class Wizard {
  private steps$ = new BehaviorSubject<WizardStep[]>([]);

  selectedStep: WizardStep;
  selectedStepIndex$ = new BehaviorSubject<number>(0);

  wizardSteps$ = combineLatest([this.steps$, this.selectedStepIndex$]).pipe(
    map(([steps, selectedStepIndex]) =>
      steps.map((step, i) => {
        return {
          ...step,
          completed: i <= selectedStepIndex,
          editable: i <= selectedStepIndex + 2,
        };
      }),
    ),
  ); // filtered and modified steps

  private wizardName: string = '';
  private steps: WizardStep[] = [];
  private activeStepKey: string = null;
  private removedSteps: RemovedSteps = {};
  private modifiedSteps: ModifiedSteps = {};

  constructor(wizardName: string, steps: WizardStep[], activeStepKey?: string) {
    this.wizardName = wizardName;
    this.steps = steps;
    this.activeStepKey = activeStepKey;
    this.filterAndMapSteps();
  }

  hideStep(stepKey: string) {
    this.removedSteps[stepKey] = true;
    return this.filterAndMapSteps();
  }

  showStep(stepKey: string) {
    this.removedSteps[stepKey] = false;
    return this.filterAndMapSteps();
  }

  modifyStep(step: WizardStep) {
    this.modifiedSteps[step.stepKey] = step;
    return this.filterAndMapSteps();
  }

  private filterAndMapSteps() {
    const filtered = this.steps.filter((wizardStep) => !this.removedSteps[wizardStep.stepKey]);
    const mapped = filtered.map((wizardStep) => ({
      ...wizardStep,
      ...this.modifiedSteps[wizardStep.stepKey],
    }));
    this.steps$.next(mapped);
    if (!this.activeStepKey) this.activeStepKey = this.steps[0]?.stepKey;
    return this.steps;
  }

  selectStep(stepKey: string) {
    const selectedStepIndex = this.steps$.value.findIndex(
      (wizardStep) => wizardStep.stepKey === stepKey,
    );
    if (selectedStepIndex >= 0) this.selectedStepIndex$.next(selectedStepIndex);
    return this.getSelectStep();
  }

  getSelectStep() {
    if (this.selectedStepIndex$.value >= 0) return this.steps$.value[this.selectedStepIndex$.value];
  }

  updateSteps(steps: WizardStep[], activeStepKey?: string) {
    this.steps = steps || [];
    if (activeStepKey) this.activeStepKey = activeStepKey;
    this.filterAndMapSteps();
    return this.selectStep(this.activeStepKey);
  }
}

@Injectable({
  providedIn: 'root',
})
export class WizardStepsService {
  private wizards: { [name: string]: Wizard } = {};

  actualStep: WizardStep = null;
  readonly actualStep$ = new BehaviorSubject<WizardStep>(this.actualStep);
  // readonly actualStep$ = new BehaviorSubject<WizardStep>(null);

  public wizardSteps: WizardStep[] = [];
  readonly wizardSteps$ = new BehaviorSubject<Array<WizardStep>>([]);

  constructor() {}

  createWizard(wizardName: string, steps: WizardStep[], activeStep: string) {
    this.wizards[wizardName] = new Wizard(wizardName, steps, activeStep);
  }

  updateWizardSteps(wizardName: string, steps: WizardStep[], activeStep: string) {
    if (this.wizards[wizardName]) return this.wizards[wizardName].updateSteps(steps, activeStep);
  }

  getWizard(wizardName: string) {
    return this.wizards[wizardName];
  }

  selectWizardStep(wizardName: string, stepKey: string): WizardStep {
    return this.wizards[wizardName]?.selectStep(stepKey);
  }

  getSelectedWizardStep(wizardName: string) {
    return this.wizards[wizardName]?.getSelectStep();
  }
}
