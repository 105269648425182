import { Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { TTerminaly } from './TTerminaly';

@Index('idt_terminaly_typ_UNIQUE', ['idtTerminalyTyp'], { unique: true })
@Index('name', ['nazevTerminalu'], {})
@Entity('t_terminaly_typ', { schema: 'VaristarIS' })
export class TTerminalyTyp {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_terminaly_typ' })
  idtTerminalyTyp: number;

  @Column('varchar', {
    name: 'nazev_terminalu',
    nullable: true,
    comment:
      "'Název typu terminálu, aby bylo jasné o co jde např Raven, Či Müller 800/1200 Varistar Direct'",
    length: 150,
  })
  nazevTerminalu: string | null;

  @Column('varchar', {
    name: 'uloziste',
    nullable: true,
    comment: "'Kam jsou data ukládána (DropBox, OneDrive, JD Link atd.)'",
    length: 255,
  })
  uloziste: string | null;

  @Column('varchar', {
    name: 'folder',
    nullable: true,
    comment: "'Cesta kam jsou mapy na ulozisti ukladany'",
    length: 255,
  })
  folder: string | null;

  @Column('varchar', {
    name: 'alt_folder',
    nullable: true,
    comment:
      "'Alternativní cesta kam jsou mapy na ulozisti ukladany - Napr Raven ma vice moznosti - root nebo adresarova struktura s identifikaci farmy'",
    length: 255,
  })
  altFolder: string | null;

  @Column('varchar', {
    name: 'must_folder',
    nullable: true,
    comment:
      "'Cesta, kterou musí ulozicte obsahovat, ale neukladaji se do ni aplikacni mapy - napr. Müller 1200 musí pro správnou funkci mít v rootu folder TASKDATA, ale mapy jsou ve folderu SHP'",
    length: 255,
  })
  mustFolder: string | null;

  @Column('varchar', {
    name: 'attr_name',
    nullable: true,
    comment: "'Název řídícího atributu aplikační mapy'",
    length: 45,
  })
  attrName: string | null;

  @Column('varchar', {
    name: 'Authentication',
    nullable: true,
    comment: "'Typ autentizace - např. name+passwrod, Api key, pro přístup do úložiště'",
    length: 45,
  })
  authentication: string | null;

  @Column('tinyint', {
    name: 'Split',
    nullable: true,
    comment: 'Rozdělit velký shape na jednotlivá pole',
    width: 1,
    default: () => "'0'",
  })
  split: boolean | null;

  @Column('tinyint', {
    name: 'download',
    nullable: true,
    comment: 'Je-li true umožní uživateli stáhnout data, aby se je přenesl na flash do stroje.',
    width: 1,
    default: () => "'0'",
  })
  download: boolean | null;

  @Column('tinyint', {
    name: 'zip',
    nullable: true,
    comment: 'V případě, že je 1 musí být výstupní mapa zazipovaná',
    width: 1,
    default: () => "'0'",
  })
  zip: boolean | null;

  @Column('tinyint', {
    name: 'short',
    nullable: true,
    comment:
      'Vytváří zkrácený název ZIP i vlastní aplikační mapy. Název se vytváří ve formátu: YYYYMMDD+camelCase(ShortName[:10]))+camelCase(Jmeno_honu)',
    width: 1,
    default: () => "'0'",
  })
  short: boolean | null;

  // @OneToMany(() => TTerminaly, (tTerminaly) => tTerminaly.terminalType2)
  @OneToMany('TTerminaly', 'terminalType2')
  tTerminalies: TTerminaly[];
}
