import { Column, Entity, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { TNewFeature } from './TNewFeature';

@Entity('t_new_feature_category', { schema: 'VaristarIS' })
export class TNewFeatureCategory {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_new_feature_category' })
  idNewFeatureCategory: number;

  @Column('varchar', { name: 'name_cz', nullable: true, length: 128 })
  nameCz: string | null;

  @Column('varchar', { name: 'name_sk', nullable: true, length: 128 })
  nameSk: string | null;

  @Column('varchar', { name: 'name_en', nullable: true, length: 128 })
  nameEn: string | null;

  @Column('varchar', { name: 'icon', length: 128, default: () => "'info'" })
  icon: string;

  @OneToMany('TNewFeature', 'idNewFeatureCategory2')
  tNewFeatures: TNewFeature[];
}
