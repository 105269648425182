<button
  mat-icon-button
  [disableRipple]="true"
  class="add-button"
  [matTooltip]="tooltip | translate"
  matTooltipPosition="above"
  [disabled]="disabled"
>
  <mat-icon>playlist_add</mat-icon>
</button>
