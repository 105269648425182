import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AuthAction } from '@varistar-apps/frontend/auth';
import { Access, Account, Organization } from '@varistar-apps/shared/data';

import {
  RegistrationAction,
  RegistrationActionTypes,
  RegistrationFeature,
  RegistrationPayload,
} from './registration.actions';

export const REGISTRATION_FEATURE_KEY = 'registration';

// Entity adapters
export const registrationAdapters: {
  [key in RegistrationFeature]: EntityAdapter<RegistrationPayload>;
} = {
  [RegistrationFeature.Account]: createEntityAdapter<Account>({
    selectId: Account.selectId,
    // sortComparer: Trader.sortByName
  }),
  [RegistrationFeature.Organization]: createEntityAdapter<Organization>({
    selectId: Organization.selectId,
    // sortComparer: Trader.sortByName
  }),
  [RegistrationFeature.OrganizationAccess]: createEntityAdapter<Access>({
    selectId: Access.selectId,
    // sortComparer: Trader.sortByName
  }),

  // [RegistrationFeature.Person]: createEntityAdapter<Person>({
  //   // NOTE: Modifikace chování entity adapteru pro Person data objekt
  //   selectId: Person.selectId,
  //   sortComparer: Person.sortByLastName
  // }),
  // [RegistrationFeature.Deal]: createEntityAdapter<Deal>({
  //   // NOTE: Modifikace chování entity adapteru pro Deal data objekt
  //   // selectId: Person.selectId,
  //   // sortComparer: Person.sortByLastName
  // }),
  // [RegistrationFeature.TradingPlatform]: createEntityAdapter<TradingPlatform>({
  //   selectId: TradingPlatform.selectId,
  //   sortComparer: TradingPlatform.sortByName
  // }),
  // [RegistrationFeature.TradePoint]: createEntityAdapter<TradePoint>({
  //   selectId: TradePoint.selectId,
  //   sortComparer: TradePoint.sortByName
  // }),
  // [RegistrationFeature.Company]: createEntityAdapter<Company>({
  //   selectId: Company.selectId,
  //   sortComparer: Company.sortByName
  // }),
  // [RegistrationFeature.Trader]: createEntityAdapter<Trader>({
  //   selectId: Trader.selectId,
  //   // sortComparer: Trader.sortByName
  // }),
  // [RegistrationFeature.Customer]: createEntityAdapter<Customer>({
  //   selectId: Customer.selectId,
  //   // sortComparer: Trader.sortByName
  // }),
  // // [RegistrationFeature.Role]: createEntityAdapter<Role>({
  // //   selectId: Role.selectId,
  // //   // sortComparer: Trader.sortByName
  // // }),
};

// State
export interface RegistrationFeatureState<T> extends EntityState<T> {
  selectedId?: string | number; // which registration record has been selected
  loaded: boolean; // has the registration list been loaded
  error?: any; // last none error (if any)
}

export interface RegistrationState {
  [RegistrationFeature.Account]: RegistrationFeatureState<Account>;
  [RegistrationFeature.Organization]: RegistrationFeatureState<Organization>;
  [RegistrationFeature.OrganizationAccess]: RegistrationFeatureState<Access>;

  // [RegistrationFeature.Person]: RegistrationFeatureState<Person>;
  // [RegistrationFeature.Deal]: RegistrationFeatureState<Deal>;
  // [RegistrationFeature.TradingPlatform]: RegistrationFeatureState<TradingPlatform>;
  // [RegistrationFeature.TradePoint]: RegistrationFeatureState<TradePoint>;
  // [RegistrationFeature.Company]: RegistrationFeatureState<Company>;
  // [RegistrationFeature.Trader]: RegistrationFeatureState<Trader>;
  // [RegistrationFeature.Customer]: RegistrationFeatureState<Customer>;
}

export interface RegistrationPartialState {
  readonly [REGISTRATION_FEATURE_KEY]: RegistrationState;
}

// export const initialRegistrationState: RegistrationState = {
//   [RegistrationFeature.Person]: registrationAdapters[RegistrationFeature.Person].getInitialState({
//     selectedId: null,
//     loaded: false,
//     error: null,
//   })
// };

export const getInitialRegistrationState = (feature: RegistrationFeature) => {
  return registrationAdapters[feature].getInitialState({
    selectedId: null,
    loaded: false,
    error: null,
  });
};

export const registrationInitialState = Object.keys(RegistrationFeature).reduce((state, key) => {
  state[key] = getInitialRegistrationState(RegistrationFeature[key]);
  return state;
}, {});

export function registrationReducer(
  state: any, //initialRegistrationState,
  action: RegistrationAction | AuthAction,
): RegistrationState {
  if (!state) state = registrationInitialState;

  switch (action.type) {
    case RegistrationActionTypes.Select:
      return {
        ...state,
        [action.feature]: { ...state[action.feature], selectedId: action.id },
      };

    case RegistrationActionTypes.Added:
      return {
        ...state,
        [action.feature]: registrationAdapters[action.feature].addOne(
          action.payload,
          state[action.feature],
        ),
      };

    case RegistrationActionTypes.Modified:
      return {
        ...state,
        [action.feature]: registrationAdapters[action.feature].updateOne(
          {
            id: action.payload.id,
            changes: action.payload,
          },
          state[action.feature],
        ),
      };

    case RegistrationActionTypes.Removed:
      return {
        ...state,
        [action.feature]: registrationAdapters[action.feature].removeOne(
          action.id,
          state[action.feature],
        ),
      };

    // return registrationAdapter[action.feature].addOne(action.payload, state[RegistrationFeature.Person]);

    // case RegistrationActionTypes.Modified:
    //   return registrationAdapter.updateOne({
    //     id: action.payload.refId,
    //     changes: action.payload
    //   }, state);

    // case RegistrationActionTypes.Removed:
    //   return registrationAdapter.removeOne(action.payload.refId, state);

    // case RegistrationActionTypes.FirebaseError:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // // FIXIT: prasarna odchyceni uspesneho Logout
    // case AuthActionTypes.NotAuthenticated:
    //   return registrationAdapter.removeAll(state);
  }
  return state;
}
