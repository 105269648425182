export interface FieldYieldYear {
  idtFieldsYieldYear: string;
  ctverec: string;
  zkod: string;
  nkod: string;
  fid: string;
  jmeno: string;
  vymeram: string;
  vymeramTotal: string;
  crop?: string;
  comment?: string;
  harvestDate?: string;
  year?: number;
  yield?: string;
  idCrop?: number;
  cropName?: string;
  cropOptionList: { name: string; value: number }[];
}

export const FieldYieldYear = {
  selectId: (f: FieldYieldYear): string => {
    return f.idtFieldsYieldYear;
  },
};
