import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

@Index('t_lpis_sk_select_info_id_LPIS_select_IDX', ['idLpisSelect'], {})
@Index('t_lpis_sk_select_info_ppa_id_IDX', ['idUz'], {})
@Index('t_lpis_sk_select_info_UN', ['idUz', 'idLpisSelect'], { unique: true })
@Entity('t_lpis_sk_select_info', { schema: 'VaristarIS' })
export class TLpisSkSelectInfo {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_t_lpis_sk_select' })
  idTLpisSkSelect: number;

  @Column('decimal', {
    name: 'id_uz',
    comment: 'Id uživatele ve Slovsnkém LPIS - tam se to nazává ppa_id.',
    precision: 8,
    scale: 0,
  })
  idUz: string;

  @Column('varchar', { name: 'customer_name', length: 128 })
  customerName: string;

  @Column('varchar', {
    name: 'id_LPIS_select',
    nullable: true,
    comment:
      'Unikátní ID, které je generované systémem VaristarIS - toto ID je součástí hyperlinku, který dostane klient Varistar a jednoznačně identifikuje pole, z kterých má vybírat hony pro smlouvu.',
    length: 45,
  })
  idLpisSelect: string | null;
}
