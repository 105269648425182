import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_pesticid_custom', { schema: 'VaristarIS' })
export class TPesticidCustom {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_pesticid_custom' })
  idPesticidCustom: number;

  @Column('varchar', { name: 'name', length: 255 })
  name: string;

  @Column('varchar', { name: 'type', length: 255 })
  type: string;

  @Column('int', {
    name: 'created_user',
    comment: 'Párovací klíč do tabulky t_osoby - id_osoby.',
  })
  createdUser: number;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    comment: 'Indikuje, informace o hnojivu byla editovana',
    width: 1,
  })
  deactivated: boolean | null;
}
