import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
export class DynamicFormControlBase<T> {
  key: string; // podle tohoto se data načítají,
  apiKey: string; // toto se použije v jako atribut při update či delete
  group: DynamicFormControlBase<T>[];
  value: T;
  // label: string;
  placeholder: string;
  hint: string;
  hintPopup: string;
  link: string;
  required: boolean;
  disabled: any; //boolean;
  disabled$: Observable<boolean>;
  order: number;
  controlType: string;
  validator?: ValidatorFn | ValidatorFn[];
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[];
  validatorMessages: any;
  flex: string;
  enable: any; // nepovinne predani flagu co se ma v komponente povolit k zobrazeni napr {add: true, copy: true}
  floatLabel?: string;
  overflowLabel?: boolean;
  // set: (value: any, values?: any) => any;
  // get: (value: any, values?: any) => any;
  set: (value: any, form?: any, key?: string) => any;
  get: (value: any, form?: any, key?: string, previousValue?: any) => any;
  change: (value: any, control?: any, form?: any) => any;
  action: (value: any, control?: any, form?: any) => any;
  presskey: (value: any, control?: any, form?: any) => any; // pri stisku libovolne klavesy
  enter: (value: any, control?: any, form?: any) => any; // pri stisku klavesy ENTER
  esc: (value: any, control?: any, form?: any) => any; // pri stisku klavesy ESC
  width: string;
  text_align?: TextAlign;
  // // link
  // url: string;
  // icon: string;
  // tooltip: string;
  type?: string;
  header?: string;
  description?: string;
  divider?: boolean;
  url?: string;
  icon?: string;
  tooltip?: string;
  minRows?: number;
  maxRows?: number;
  display?: (any) => any | null;
  items$?: any;
  filteredItems$?: any;
  filter?: any;
  filterValue?: string;
  placeholderfilter?: string;
  applyFilter?: (any) => any | null;
  clearFilter?: (any) => any | null;
  dataSource?: any;
  displayedColumns?: any;
  buttons?: any[];
  // inputs?: number; // pro input-buttons, pocet input controlu
  inputs?: any[]; // pro input-buttons, parametry input controlu
  delimiter?: string;
  // isLoading?: boolean;
  isLoading$?: BehaviorSubject<boolean>;
  ignoreValueChanges?: boolean; // pro autocomplete aby pri autosave neukladal nevybrane hodnoty
  prefix?: string; // pro input
  suffix?: string; // pro input
  suffixIcon?: string; // pro input
  focus?: boolean; // ziska focus po zbrazeni formulare, ale vzdy jen posledni takto definovany control
  select?: boolean; // vybere text controlu po ziskani focusu, defaultne je true
  autocompleteListOnEmpty?: boolean; // otevre nabidku autocomplete i pokud neni zadana zadna vstuptni fitracni hodnota (pouze u defautniho neprepsaneho remote)
  min?: number;
  max?: number;

  constructor(
    options: {
      key?: string;
      apiKey?: string;
      group?: DynamicFormControlBase<T>[];
      value?: T;
      // label?: string,
      placeholder?: string;
      hint?: string;
      hintPopup?: string;
      link?: string;
      required?: boolean;
      disabled?: any; //boolean,
      disabled$?: Observable<boolean>;
      order?: number;
      controlType?: string;
      validator?: ValidatorFn | ValidatorFn[];
      asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[];
      validatorMessages?: any;
      flex?: string;
      enable?: any;
      // set?: (value: any, values?: any)  => any,
      // get?: (value: any, values?: any) => any,
      set?: (value: any, form?: any, key?: string) => any;
      get?: (value: any, form?: any, key?: string, previousValue?: any) => any;
      change?: (value: any, control?: any, form?: any) => any;
      action?: (value: any, control?: any, form?: any) => any;
      presskey?: (value: any, control?: any, form?: any) => any;
      enter?: (value: any, control?: any, form?: any) => any;
      esc?: (value: any, control?: any, form?: any) => any;
      width?: string;
      text_align?: TextAlign;
      ignoreValueChanges?: boolean;
      // url?: string,
      // icon?: string,
      // tooltip?: string,
      focus?: boolean;
      select?: boolean;
      autocompleteListOnEmpty?: boolean;
    } = {},
  ) {
    this.key = options.key || '';
    this.apiKey = options.apiKey || '';
    this.group = options.group || [];
    this.value = options.value;
    // this.label = options.label || '';
    this.placeholder = options.placeholder || '';
    this.hint = options.hint || '';
    this.hintPopup = options.hintPopup || '';
    this.link = options.link || '';
    this.required = !!options.required;
    this.disabled = !!options.disabled;
    this.disabled$ = options.disabled$;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.validator = options.validator || [];
    this.asyncValidator = options.asyncValidator || [];
    this.validatorMessages = options.validatorMessages || {};
    this.flex = options.flex || '0';
    this.enable = options.enable || {};
    this.floatLabel = options['floatLabel'] || 'auto';
    this.overflowLabel = options['overflowLabel'] || false;
    this.min = options['min'] !== null ? options['min'] : null;
    this.max = options['max'] !== null ? options['max'] : null;
    this.set = options.set || ((value) => value);
    this.get = options.get || ((value) => value);
    this.change = options.change || null;
    this.action = options.action || null;
    this.presskey = options.presskey || null;
    this.enter = options.enter || null;
    this.esc = options.esc || null;
    this.width = options.width;
    this.text_align = options.text_align || 'initial';
    this.ignoreValueChanges = options.ignoreValueChanges || false;
    // this.url = this.url || '';
    // this.icon = this.icon || '';
    // this.tooltip = this.tooltip || '';
    this.focus = !!options.focus;
    this.select = options.select === undefined ? true : !!options.select;
    this.autocompleteListOnEmpty = !!options.autocompleteListOnEmpty;
  }
}
