import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FrontendAuthModule } from '@varistar-apps/frontend/auth';
import { FrontendSettingsModule } from '@varistar-apps/frontend/settings';
import { FrontendUiFlexLayoutModule } from '@varistar-apps/frontend/ui-flex-layout';
import { FrontendUiMaterialModule } from '@varistar-apps/frontend/ui-material';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { FrontendUiFormModule } from '@varistar-apps/frontend/ui-form';
import { AddButtonComponent } from './add-button/add-button.component';
import { AsyncFocusDirective } from './async-focus/async-focus.directive';
import { AuthDialogLoginComponent } from './auth-dialog-login/auth-dialog-login.component';
import { AuthDialogSignupComponent } from './auth-dialog-signup/auth-dialog-signup.component';
import { ButtonOneClickDirective } from './button-one-click/button-one-click.directive';
import { CheckFocusDirective } from './check-focus/check-focus.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CropSelectComponent } from './crop-select/crop-select.component';
import { CustomCheckboxComponent } from './custom-components/custom-checkbox/custom-checkbox.component';
import {
  DatePickerComponent,
  DatePickerMonthSelectHeader,
} from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { DropdownSelectDialogComponent } from './dropdown-select-dialog/dropdown-select-dialog.component';
import { FieldsEditComponent } from './fields-edit-component/fields-edit.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { LoadingService } from './loading-service/loading.service';
import { LocationChangeService } from './location-change-service/location-change.service';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { PipeDecimalCustomPipe } from './pipe-decimal-custom/pipe-decimal-custom';
import { PipeFilterAttributesPipe } from './pipe-filter-attributes/pipe-filter-attributes.pipe';
import { PipeOrderByObjectPipe } from './pipe-order-by-object/pipe-order-by-object.pipe';
import { PhoneNumberPipe } from './pipe-phone-number/pipe-phone-number';
import { PlodinaPipe } from './plodina-pipe/plodina-pipe';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RoundSeedPipe } from './round-seed-pipe/round-seed-pipe';
import { ScrollToViewDirective } from './scroll-to-view/scroll-to-view.directive';
import { OffsetTopDirective } from './scroll-to/offset-top.directive';
import { ScrollableDirective } from './scroll-to/scrollable.directive';
import { SeasonPickerComponent } from './season-picker/season-picker.component';
import { SelectAutofocusDirective } from './select-auto-focus/select-auto-focus.directive';
import { SideNavService } from './side-nav/side-nav.service';
import { SimpleSelectComponent } from './simple-select/simple-select.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { StorageBrowser } from './storage-browser/storage.browser';
import { TextFilterComponent } from './text-filter/text-filter.component';
import { TitleBarService } from './title-bar/title-bar.service';
import { FrontendUiModuleConfig } from './ui.interfaces';
import { WizardStepsComponent } from './wizard-steps/wizard-steps.component';
import { WizardStepsService } from './wizard-steps/wizard-steps.service';
import { ClickOutsideDirective } from './click-outside-directive/click-outside.directive';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { FallbackSrcDirective } from './fallback-src/click-outside.directive';
import { KeysPipe } from './keysPipe/keys-pipe';
import { NumberFormatByPesticicesUnitsPipe } from './pipe-number-format-by-pesticides-units/pipe-number-format-by-pesticides-units';
import { PipeFloorPipe } from './pipe-floor/pipe-floor';
import { VisibilityService } from './visibility-service/visibility.service';
import { VisibleWithDirective } from './visibility-directive/visibility.directive';
import { ToKmPerHPipe } from './to-km-per-h-pipe/to-km-per-h-pipe';
import { InlineProgressBarComponent } from './inline-progress-bar/inline-progress-bar.component';
import { FieldVymeramPipe } from './field-vymeram/pipe-field-vymeram';
import { SelectConfirmDialogComponent } from './select-confirm-dialog/select-confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FrontendUiMaterialModule,
    FrontendUiFlexLayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FrontendAuthModule,
    FrontendSettingsModule,
    FontAwesomeModule,
    FrontendUiFormModule,
    MatNativeDateModule,
  ],
  exports: [
    AlertDialogComponent,
    AuthDialogLoginComponent,
    AuthDialogSignupComponent,
    SnackBarComponent,
    PipeFilterAttributesPipe,
    PipeDecimalCustomPipe,
    FieldVymeramPipe,
    NumberFormatByPesticicesUnitsPipe,
    PipeOrderByObjectPipe,
    RoundSeedPipe,
    PlodinaPipe,
    PhoneNumberPipe,
    PipeFloorPipe,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    SelectConfirmDialogComponent,
    ScrollableDirective,
    ButtonOneClickDirective,
    OffsetTopDirective,
    WizardStepsComponent,
    ProgressBarComponent,
    FilterSelectComponent,
    AsyncFocusDirective,
    CheckFocusDirective,
    ScrollToViewDirective,
    SelectAutofocusDirective,
    ImageViewerComponent,
    CustomCheckboxComponent,
    CropSelectComponent,
    FieldsEditComponent,
    MultiSelectComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    SeasonPickerComponent,
    SimpleSelectComponent,
    TextFilterComponent,
    AddButtonComponent,
    DropdownSelectDialogComponent,
    ClickOutsideDirective,
    DatePickerMonthSelectHeader,
    FallbackSrcDirective,
    KeysPipe,
    VisibleWithDirective,
    ToKmPerHPipe,
    InlineProgressBarComponent,
  ],
  declarations: [
    AlertDialogComponent,
    AuthDialogLoginComponent,
    AuthDialogSignupComponent,
    SnackBarComponent,
    PipeFilterAttributesPipe,
    PipeDecimalCustomPipe,
    FieldVymeramPipe,
    NumberFormatByPesticicesUnitsPipe,
    PipeOrderByObjectPipe,
    RoundSeedPipe,
    PlodinaPipe,
    PhoneNumberPipe,
    PipeFloorPipe,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    SelectConfirmDialogComponent,
    ScrollableDirective,
    ButtonOneClickDirective,
    OffsetTopDirective,
    WizardStepsComponent,
    ProgressBarComponent,
    FilterSelectComponent,
    AsyncFocusDirective,
    CheckFocusDirective,
    ScrollToViewDirective,
    SelectAutofocusDirective,
    ImageViewerComponent,
    CustomCheckboxComponent,
    CropSelectComponent,
    FieldsEditComponent,
    MultiSelectComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    SeasonPickerComponent,
    SimpleSelectComponent,
    TextFilterComponent,
    AddButtonComponent,
    DropdownSelectDialogComponent,
    ClickOutsideDirective,
    DatePickerMonthSelectHeader,
    FallbackSrcDirective,
    KeysPipe,
    VisibleWithDirective,
    ToKmPerHPipe,
    InlineProgressBarComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'cs' },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    // TitleBarService,
    // { provide: MAT_DATE_LOCALE, useValue: 'cs-CZ' },
    // { provide: MAT_SNACK_BAR_DATA, useValue: {} }, //
    // { provide: MatSnackBarRef, useValue: {} },
    // SnackBarComponent,
    // PipeFilterAttributesPipe,
    // PipeOrderByObjectPipe,
    // SideNavService,
    // DecimalPipe
  ],
  entryComponents: [
    AuthDialogLoginComponent,
    AuthDialogSignupComponent,
    SnackBarComponent,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    SelectConfirmDialogComponent,
  ],
})
export class FrontendUiModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FrontendUiModule,
    private dateAdapter: DateAdapter<any>,
    private translateService: TranslateService,
  ) {
    this.translateService.onLangChange.asObservable().subscribe(({ lang }) => {
      // for compare function (shared-utilities)
      window['applicationLanguage'] = lang;

      //change language in datepickers
      this.dateAdapter.setLocale(lang);
    });
  }

  static forRoot(config: FrontendUiModuleConfig): ModuleWithProviders<FrontendUiModule> {
    return {
      ngModule: FrontendUiModule,
      providers: [
        { provide: FrontendUiModuleConfig, useValue: config },
        // !!! kvuli tomu aby WizardStepsService zustal singleton
        TitleBarService,
        { provide: MAT_SNACK_BAR_DATA, useValue: {} }, //
        { provide: MatSnackBarRef, useValue: {} },
        SnackBarComponent,
        PipeFilterAttributesPipe,
        PipeOrderByObjectPipe,
        SideNavService,
        WizardStepsService,
        StorageBrowser,
        NgxChartsModule,
        DecimalPipe,
        PlodinaPipe,
        PhoneNumberPipe,
        RoundSeedPipe,
        ProgressBarComponent,
        LoadingService,
        LocationChangeService,
        VisibilityService,
      ],
    };
  }
}
