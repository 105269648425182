import { TTankmix } from './entities/TTankmix';

// tslint:disable-next-line: no-empty-interface
export interface Tankmix extends TTankmix {}

export const Tankmix = {
  selectId: (a: TTankmix) => {
    return a.idTankmix;
  },
};
