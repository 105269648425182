import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();
  @Input() clickOutsideActive = true;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement): void {
    if (this.clickOutsideActive) {
      const clickedInside = this.elementRef.nativeElement.contains(target);

      if (!clickedInside) {
        this.clickOutside.emit();
      }
    }
  }
}
