import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FrontendUiFormModule } from '@varistar-apps/frontend/ui-form';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ConfigurationFormComponent } from '../configuration-form/configuration-form.component';
import { ConfigurationPersonFormComponent } from '../configuration-person-form/configuration-person-form.component';
import { ConfigurationEffects } from './+state/configuration.effects';
import { ConfigurationFacade } from './+state/configuration.facade';
import {
  CONFIGURATION_FEATURE_KEY,
  configurationReducer,
  configurationInitialState,
} from './+state/configuration.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CONFIGURATION_FEATURE_KEY, configurationReducer, {
      initialState: configurationInitialState,
    }),
    EffectsModule.forFeature([ConfigurationEffects]),
    AngularFirestoreModule, // Firebase Firestore
    AngularFireAuthModule, // Firebase Authentication,
    FrontendUiFormModule,
    // ApiOcsModule,
  ],
  providers: [ConfigurationFacade],
  declarations: [ConfigurationPersonFormComponent, ConfigurationFormComponent],
  exports: [ConfigurationPersonFormComponent, ConfigurationFormComponent],
})
export class ConfigurationModule {}
