export interface Owner {
  idUz: string;
  obchodniJmeno?: string;
}

export const Owner = {
  selectId: (a: Owner): string => {
    //In this case this would be optional since primary key is id
    return a.idUz;
  },

  // sortByName: (a: Owner, b: Owner): number => {
  //   return a.obchodniJmeno && a.obchodniJmeno.localeCompare(b.obchodniJmeno);
  // },
};
