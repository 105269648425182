export enum ContactType {
  EMAIL = 'EMAIL',
  WWW = 'WWW',
  MOBILE = 'MOBILE',
  PHONE = 'PHONE',
}

export interface Contact {
  type?: ContactType; // Typ kontaktu
  web?: string;
  email?: string;
  mobile?: string;
  phone?: string;
}

export const Contact = {
  getContacTypes: () => {
    return Object.keys(ContactType).map((key) => ({
      name: `PLACEHOLDER.CONTACT.${key.toUpperCase()}`,
      value: ContactType[key],
    }));
  },
};
