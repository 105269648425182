import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TFields } from './TFields';

@Index('datum_last', ['validObservationDate'], {})
@Index('id_fields', ['idtFields'], {})
@Index('id_prim', ['idtFieldsObservations'], {})
@Entity('t_fields_observations', { schema: 'VaristarIS' })
export class TFieldsObservations {
  @PrimaryGeneratedColumn({
    type: 'bigint',
    name: 'idt_fields_observations',
    comment: 'Primární klíč',
  })
  idtFieldsObservations: string;

  @Column('bigint', {
    name: 'idt_fields',
    nullable: true,
    comment: 'Cizí klíč do tabulky t_fields',
  })
  idtFields: string | null;

  @Column('date', {
    name: 'valid_observation_date',
    nullable: true,
    comment: 'Datum kdy pro daný hon jsou k dispozici bezmračná data',
  })
  validObservationDate: string | null;

  @Column('decimal', {
    name: 'cloud_cover_percent',
    nullable: true,
    comment: 'Procento oblačnosti na daném snímku.',
    precision: 5,
    scale: 4,
  })
  cloudCoverPercent: string | null;

  @ManyToOne('TFields', 'tFieldsObservations', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_fields', referencedColumnName: 'idtFields' }])
  idtFields2: TFields;
}
