import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TFields } from './TFields';
import { TZakazky } from './TZakazky';

@Index('fileds_app_fields_idx', ['idtFields'], {})
@Index('zakazky_idx', ['idZakazky'], {})
@Entity('t_zakazky_fields', { schema: 'VaristarIS' })
export class TZakazkyFields {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_zakazky_fields' })
  idtZakazkyFields: string;

  @Column('bigint', { name: 'id_zakazky', nullable: true })
  idZakazky: string | null;

  @Column('bigint', { name: 'idt_fields', nullable: true })
  idtFields: string | null;

  @Column('decimal', {
    name: 'base',
    nullable: true,
    comment:
      'Základní dávka zadaná pro tuto zakázku (u pesticidů se sem ukládá Základní dávka vody)',
    precision: 12,
    scale: 2,
  })
  base: string | null;

  @Column('decimal', {
    name: 'var_rate',
    nullable: true,
    comment:
      'Nastavení variability v % na 10% relativního výnosového potenciálu - kladná přidává do zón s vyšším výnosovým potenciálem, záporná přidává do zón s nižším výnosovým potenciálem. Příklad: Je-li základní dávka 200 a var_rate 15% v zóně 110% bude aplikační dávka 230 (přidá na 10% výnosového potenciálu 15% z celkové dávky)',
    precision: 12,
    scale: 2,
  })
  varRate: string | null;

  @Column('decimal', {
    name: 'var_rate_actual',
    nullable: true,
    comment:
      'Nastavení variability v % na 10% aktuálního stavu - kladná přidává do zón s lepším pororstem, záporná přidává do zón s horším pororstem.',
    precision: 12,
    scale: 2,
  })
  varRateActual: string | null;

  @Column('decimal', {
    name: 'min',
    nullable: true,
    comment:
      'Minimální dávka dodávaného formulovaného hnojiva v L nebo kg na ha, pod kterou se systém při variabilním dávkování nedostane.',
    precision: 12,
    scale: 2,
  })
  min: string | null;

  @Column('decimal', {
    name: 'max',
    nullable: true,
    comment:
      'Maximální dávka dodávaného dusíku (N) v L nebo kg na ha, nad kterou se systém při variabilním dávkování nedostane.',
    precision: 12,
    scale: 2,
  })
  max: string | null;

  @Column('varchar', {
    name: 'app_product',
    nullable: true,
    comment: 'Komerční název aplikovaného produktu.',
    length: 254,
  })
  appProduct: string | null;

  @Column('decimal', {
    name: 'percent_volume',
    nullable: true,
    comment: 'Objem účinné látky v produktu v % nebo klíčivost u osiva v %.',
    precision: 5,
    scale: 2,
  })
  percentVolume: string | null;

  @Column('varchar', {
    name: 'fert_type',
    nullable: true,
    comment:
      'Řídící prvek u hnojiva, kterým je řízena aplikace např. N, P či K. U pesticidů se sem uvádí typ pesticidu u uživatelsky definovaných pesticidů.',
    length: 255,
  })
  fertType: string | null;

  @Column('tinyint', {
    name: 'liquid',
    nullable: true,
    comment: 'Označení jestli jde o tekutý či pevný přípravek (0 = pevný, 1 = kapalný)',
    width: 1,
  })
  liquid: boolean | null;

  @Column('decimal', {
    name: 'htz',
    nullable: true,
    comment:
      'HTZ - Hmotnost tisíce zrn či HTS - hmnotnost tisíce semen pro zadávání a výpočet spotřeby u osiv a variabilního setí',
    precision: 12,
    scale: 2,
  })
  htz: string | null;

  @Column('decimal', {
    name: 'volume_total',
    nullable: true,
    comment: 'Celková spotřeba produktu na honu.',
    precision: 12,
    scale: 2,
  })
  volumeTotal: string | null;

  @Column('decimal', {
    name: 'app_1',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_1: string | null;

  @Column('decimal', {
    name: 'app_2',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_2: string | null;

  @Column('decimal', {
    name: 'app_3',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_3: string | null;

  @Column('decimal', {
    name: 'app_4',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_4: string | null;

  @Column('decimal', {
    name: 'app_5',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_5: string | null;

  @Column('decimal', {
    name: 'app_6',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_6: string | null;

  @Column('decimal', {
    name: 'app_1_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_1_net: string | null;

  @Column('decimal', {
    name: 'app_2_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_2_net: string | null;

  @Column('decimal', {
    name: 'app_3_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_3_net: string | null;

  @Column('decimal', {
    name: 'app_4_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_4_net: string | null;

  @Column('decimal', {
    name: 'app_5_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_5_net: string | null;

  @Column('decimal', {
    name: 'app_6_net',
    nullable: true,
    comment:
      'Dávka l/ha, v celé mapě pro aplikaci v příslušné zóně. Čím nižší číslo zóny, tím nižší výnosový potenciál. A potenciálně horší porost. U zásobního hnojení znamená index v příslušné zóně (1 nejnižší zásobenost 6 nejvyšší).',
    precision: 12,
    scale: 2,
  })
  app_6_net: string | null;

  @Column('decimal', {
    name: 'bon_koef',
    nullable: true,
    comment:
      'Musí se uložit bonitační koeficient platný na daném poli v době aplikace. Aby zpětně bylo možné vyvolat výpočtová data i když se změní bonitační skupina či koeficient daného pole.',
    precision: 5,
    scale: 2,
  })
  bonKoef: string | null;

  @Column('decimal', {
    name: 'n_percent',
    nullable: true,
    comment: 'Obsah dusíku v hnojivu',
    precision: 5,
    scale: 2,
  })
  nPercent: string | null;

  @Column('decimal', {
    name: 'p_percent',
    nullable: true,
    comment: 'obsah fosforu v hnojivu',
    precision: 5,
    scale: 2,
  })
  pPercent: string | null;

  @Column('decimal', {
    name: 'k_percent',
    nullable: true,
    comment: 'Obsah draslíku v hnojivu.',
    precision: 5,
    scale: 2,
  })
  kPercent: string | null;

  @Column('decimal', {
    name: 'ca_percent',
    nullable: true,
    comment: 'Obsah vápníku v hnojivu.',
    precision: 5,
    scale: 2,
  })
  caPercent: string | null;

  @Column('decimal', {
    name: 'mg_percent',
    nullable: true,
    comment: 'Obsah mg v hnojivu.',
    precision: 5,
    scale: 2,
  })
  mgPercent: string | null;

  @Column('decimal', {
    name: 'net_mg',
    nullable: true,
    precision: 12,
    scale: 2,
  })
  netMg: string | null;

  @Column('float', {
    name: 'spatial_decline_value',
    nullable: true,
    comment: 'Represents percentage value for decrease the batch in valley zones',
    precision: 12,
    default: () => "'0'",
  })
  spatialDeclineValue: number | null;

  @Column('int', {
    name: 'app_product_id',
    nullable: true,
    comment: 'ID produktu, který je na daném honu aplikován.',
  })
  appProductId: number | null;

  @Column('float', {
    name: 'org_koef_current_season',
    nullable: true,
    comment: 'koeficient vyuzitelnosti organickeho hnojiva v teto sezone - urcuje si zakaznik',
    precision: 12,
    default: () => "'1'",
  })
  orgKoefCurrentSeason: number | null;

  @Column('float', {
    name: 'org_koef_next_season',
    nullable: true,
    comment:
      'koeficient vyuzitelnosti organickeho hnojiva v nasledujici sezone - urcuje si zakaznik',
    precision: 12,
    default: () => "'0'",
  })
  orgKoefNextSeason: number | null;

  @Column('int', {
    name: 'app_custom_product_id',
    nullable: true,
    comment: 'ID produktu, který je v dané zakázce aplikován jako hlavní.',
  })
  appCustomProductId: number | null;

  @Column('decimal', { name: 'net_p', nullable: true, precision: 12, scale: 2 })
  netP: string | null;

  @Column('decimal', { name: 'net_n', nullable: true, precision: 12, scale: 2 })
  netN: string | null;

  @Column('decimal', { name: 'net_k', nullable: true, precision: 12, scale: 2 })
  netK: string | null;

  @Column('decimal', {
    name: 'net_ca',
    nullable: true,
    precision: 12,
    scale: 2,
  })
  netCa: string | null;

  @Column('decimal', {
    name: 'volume_average',
    nullable: true,
    precision: 12,
    scale: 2,
  })
  volumeAverage: string | null;

  @Column('date', {
    name: 'soil_analyse_date',
    nullable: true,
    comment: 'Datum analyzy pudnich rozboru, ktere se pouzije pri tvorbe mapy. optional',
  })
  soilAnalyseDate: string | null;

  // @ManyToOne(() => TFields, (tFields) => tFields.tZakazkyFields, {
  @ManyToOne('TFields', 'tZakazkyFields', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_fields', referencedColumnName: 'idtFields' }])
  idtFields2: TFields;

  // @ManyToOne(() => TZakazky, (tZakazky) => tZakazky.tZakazkyFields, {
  @ManyToOne('TZakazky', 'tZakazkyFields', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_zakazky', referencedColumnName: 'idZakazky' }])
  idZakazky2: TZakazky;
}
