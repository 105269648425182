import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Person } from '@varistar-apps/shared/data';
import { DeleteDialogComponent } from '@varistar-apps/frontend/ui';
import { DfcInput, DfcSelect } from '@varistar-apps/frontend/ui-form';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

// import { PERSONS_FEATURE_KEY } from '../+state/persons.reducer';
import { ConfigurationFacade } from '../configuration/+state/configuration.facade';
import { ConfigurationFeature } from '../configuration/+state/configuration.actions';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-configuration-person-form',
  templateUrl: './configuration-person-form.component.html',
  styleUrls: ['./configuration-person-form.component.scss'],
})
export class ConfigurationPersonFormComponent implements OnInit, OnDestroy {
  @Input() person$: Observable<Person>;

  @Input() data$: Observable<{ person: Person }>;
  @Input() resetWhenChanged = 'person.id'; // refId?

  person: Person;
  data: { person: Person };
  controls = [];

  enable = {
    delete: true,
  };

  salutations = [{ name: 'Pan', value: 'sErIgPRAzpP4U1' }];
  private dataSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private configurationFacade: ConfigurationFacade,
  ) {}

  ngOnInit() {
    this.data$ = this.person$.pipe(
      tap((person) => {
        this.person = person;
      }),
      map((person) => ({ person })),
      tap((data) => {
        this.data = data;
      }),
    );

    this.dataSubscription = this.data$.pipe(filter((data) => !!data)).subscribe((data) => {
      this.controls = [
        // refId?
        new DfcInput<string>({
          key: 'person.id',
          placeholder: 'PLACEHOLDER.PERSON.ID',
          order: 1.1,
          disabled: true, //data.person && !!data.person.id,
          required: true,
          validator: Validators.compose([Validators.required]),
          validatorMessages: {
            // required: 'VALIDATION.PERSON.REFID.REQUIRED'
          },
        }),
        new DfcSelect<number>({
          key: 'person.salutationId',
          // apiKey: 'person.salutationRefId',
          placeholder: 'PLACEHOLDER.PERSON.SALUTATION',
          order: 2.0,
          required: false,
          items: this.salutations,
          validator: Validators.compose([]),
          validatorMessages: {},
        }),
        new DfcInput<string>({
          key: 'person.firstName',
          placeholder: 'PLACEHOLDER.PERSON.FIRSTNAME',
          order: 2.1,
          required: true,
          validator: Validators.compose([Validators.required]),
          validatorMessages: {
            // required: 'VALIDATION.FIRSTNAME'
          },
          flex: '100',
        }),
        new DfcInput<string>({
          key: 'person.lastName',
          placeholder: 'PLACEHOLDER.PERSON.LASTNAME',
          order: 2.2,
          required: true,
          validator: Validators.compose([Validators.required]),
          validatorMessages: {
            // required: 'VALIDATION.PERSON.LASTNAME'
          },
          flex: '100',
        }),
        // new DfcCheckbox<boolean>({
        //   key: 'person.enabled',
        //   placeholder: 'PLACEHOLDER.PERSON.PERSON.ENABLED',
        //   value: false,
        //   order: 1.3,
        //   flex: '100',
        //   hint: 'HINT.PERSON.ENABLE'
        // }),
      ];
    });
    //     new DfcSelect<number>({
    //       key: 'configuration.localityId',
    //       placeholder: 'PLACEHOLDER.PERSON.ID',
    //       order: 4,
    //       hint: 'HINT.CONFIGURATION.GASSUPPLYPOINT.PERSON',
    //       required: true,
    //       items: this.localities,
    //       validator: Validators.compose([Validators.required, this.validateLoclityNumber]),
    //       validatorMessages: {
    //         required: 'VALIDATION.PERSON.ID.REQUIRED',

    //       },
    //     }),
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

  async onSave(values: any) {
    if (this.person && this.person.id) {
      const modifiedPerson = {
        ...this.person,
        ...values.person,
      };

      // this.apiOcsService.put(PERSONS_FEATURE_KEY, modifiedPerson, modifiedPerson.refId).subscribe((person: Person) => {

      // });
    } else {
      // this.apiOcsService.post(PERSONS_FEATURE_KEY, values.person).subscribe((person: Person) => {
      //   this.personsFacade.select(person.refId);
      //   // this.router.
      // });
    }
  }

  onDelete(values: any) {
    if (this.person && this.person.id) {
      this.openPersonDeleteDialog();
    }
  }

  openPersonDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        // title: String(PERSONS_FEATURE_KEY).toLocaleUpperCase(),
        count: 1,
      },
    });

    dialogRef.afterClosed().subscribe((isYes) => {
      if (isYes) {
        // this.apiOcsService.delete(PERSONS_FEATURE_KEY, this.person.refId).subscribe((person: Person) => {
        //   this.personsFacade.select(null);
        // });
      }
    });
  }
}
