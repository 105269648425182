<h1 mat-dialog-title>
  <span>{{
    data.question || 'QUESTION.REMOVE'
      | translate: { count: data.count, postfix: data.count === 1 ? 'u' : 'y' }
  }}</span>
</h1>
<mat-dialog-content class="mat-typography">
  <span *ngIf="data.title">{{ data.title }}</span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="accent" [mat-dialog-close]="false" cdkFocusInitial>
    <span translate>BUTTON.NO</span>
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    <span translate>BUTTON.YES</span>
  </button>
</mat-dialog-actions>
