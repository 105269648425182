import { TZakaznikOsobyStrediska } from './entities/TZakaznikOsobyStrediska';

// tslint:disable-next-line: no-empty-interface
export interface CustomerPersonDepartment extends TZakaznikOsobyStrediska {}

export const CustomerPersonDepartment = {
  selectId: (a: CustomerPersonDepartment) => {
    return a.idZakaznikOsobyStrediska;
  },
};
