import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TPorUcinnaLatka } from './TPorUcinnaLatka';

@Index('idt_por_ucinna_latka', ['idUl'], {})
@Entity('t_por_skupina_ul', { schema: 'VaristarIS' })
export class TPorSkupinaUl {
  @Column('bigint', { name: 'id_ul' })
  idUl: string;

  @Column('varchar', {
    name: 'KOD',
    nullable: true,
    comment: 'Kód skupiny účinné látky',
    length: 45,
  })
  kod: string | null;

  @Column('varchar', {
    name: 'NAZEV_EN',
    nullable: true,
    comment: 'Anglický název skupiny látky',
    length: 255,
  })
  nazevEn: string | null;

  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_skupina_ul' })
  idtPorSkupinaUl: string;

  // @ManyToOne(
  //   () => TPorUcinnaLatka,
  //   (tPorUcinnaLatka) => tPorUcinnaLatka.tPorSkupinaUls,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  @ManyToOne('TPorUcinnaLatka', 'tPorSkupinaUls', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_ul', referencedColumnName: 'idtPorUcinnaLatka' }])
  idUl2: TPorUcinnaLatka;
}
