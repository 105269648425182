import { Action, ActionReducer } from '@ngrx/store';

const RESET_TYPE_CUSTOMER = 'RESET_STATE_CUSTOMER';
const RESET_TYPE_DEPARTMENT = 'RESET_STATE_DEPARTMENT';

const adminPortalProtectedEntityList = [
  'User',
  'Person',
  'Customer',
  'PersonRole',
  'Role',
  'CustomerPerson',
  'CustomerPersonRole',
  'Contract',
  'Sim',
  'PlanetApiKey',
  'NewFeature',
  'NewFeatureCategory',
  'DropboxDirect',
];

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    let protectedEntityList = [];
    if (action.type === RESET_TYPE_CUSTOMER) {
      protectedEntityList = [
        'User',
        'Holding',
        'Customer',
        'Department',
        ...adminPortalProtectedEntityList,
      ];
    } else if (action.type === RESET_TYPE_DEPARTMENT) {
      protectedEntityList = [
        'User',
        'Holding',
        'Customer',
        'Department',
        'Field',
        'FieldArchive',
        'Order',
        'BonityGroup',
        ...adminPortalProtectedEntityList,
      ];
    }

    if (action.type === RESET_TYPE_CUSTOMER || action.type === RESET_TYPE_DEPARTMENT) {
      state = {
        ...state,
        entityCache: Object.keys(state.entityCache).reduce((acc, key) => {
          if (protectedEntityList.includes(key)) {
            acc[key] = state.entityCache[key];
          } else {
            acc[key] = {
              changeState: {},
              entities: {},
              entityName: key,
              filter: '',
              ids: [],
              loaded: false,
              loading: true,
              selectedId: null,
            };
          }

          return acc;
        }, {}),
      };
    }

    return reducer(state, action);
  };
}

export class ResetStateOnCustomerIdChange implements Action {
  readonly type = RESET_TYPE_CUSTOMER;
  constructor() {}
}

export class ResetStateOnDepartmentIdChange implements Action {
  readonly type = RESET_TYPE_DEPARTMENT;
  constructor() {}
}
