import { Person } from './person.model';
import { Company } from './company.model';
import { AuthUser } from './auth-user.model';
import { Organization } from './organization.model';
import { Access, AccessRole } from './access.model';
import { VaristarProfile } from './varistar-profile.model';

export enum AccountRole {
  USER = 'USER', // vidi jen svoji organizaci a jeji data a muze menit jen svoje data
  ADMIN = 'ADMIN', // muze vse
  // MANAGER = 'MANAGER', // muze spravovat jen svoji organizaci
}

export interface Account {
  id?: string;
  user?: AuthUser;
  // !!! Nova Varistar implementace dle t_user, t_osoby, t_zakaznik_osoby
  varistar?: VaristarProfile;

  // !!! Puvodni implementace pro Firestore autorizaci, prideleni k organizaci a role uzivatele
  // company?: Company; // Foreign Key to Company
  // person?: Person;  // Foreign Key to Person
  // role?: AccountRole | AccessRole;
  // // admin?: boolean;
  // organization?: Organization; // vybrana organizace do ktere se hlasim
  // access?: Access; // dotazeny stav pristupu k organizaci
  // // personId?: string;  // Foreign Key to Person
  // // validFrom?: Date; // Date/Time of the validity from
  // // validTo?: Date; // Date/Time of the validity To
  // // note?: string;
  // // //
  // // refId?: string,
  // // createdByRefId?: string;
  // // companyRefId?: string;  // Foreign Key to Company
  // // personRefId?: string; // Foreign Key to Person
  // // messages?: string[];
  // // userRefId?: string; // Foreign Key to AuthUser in case Account is user of the platform
}

export const Account = {
  selectId: (a: Account): string => {
    return a.id;
  },
  // sortByPeriods: (a: Account, b: Account): number => {
  //   return false;
  // return a.periodFrom && a.periodFrom.localeCompare(b.periodFrom);
  // return a.periodFrom <&& a.periodFrom < b.periodFrom);
  // }
  getRoles: () => {
    return Object.keys(AccountRole).map((key) => ({
      name: `ACCOUNT.ROLE.${key.toUpperCase()}`,
      value: AccountRole[key],
    }));
  },
};
