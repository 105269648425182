import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TPorOchrannaPasma } from './TPorOchrannaPasma';

@Index('idt_por_ochranna_pasma', ['opId'], {})
@Entity('t_por_op_plodiny', { schema: 'VaristarIS' })
export class TPorOpPlodiny {
  @Column('bigint', { name: 'op_id' })
  opId: string;

  @Column('int', { name: 'KOD', comment: 'Kód plodiny nebo skupiny z EPH' })
  kod: number;

  @Column('varchar', {
    name: 'TYP',
    comment: 'Typ kódu - PL- plodina z EPH; SK- skupina plodin z EPH',
    length: 45,
  })
  typ: string;

  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_op_plodiny' })
  idtPorOpPlodiny: string;

  // @ManyToOne(
  //   () => TPorOchrannaPasma,
  //   (tPorOchrannaPasma) => tPorOchrannaPasma.tPorOpPlodinies,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  @ManyToOne('TPorOchrannaPasma', 'tPorOpPlodinies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'op_id', referencedColumnName: 'idtPorOchrannaPasma' }])
  op: TPorOchrannaPasma;
}
