import { TFieldsObservationsNew } from './entities/TFieldsObservationsNew';

// tslint:disable-next-line: no-empty-interface
export interface FieldObservationNew extends TFieldsObservationsNew {
  hasMap?: boolean;
}

export const FieldObservationNew = {
  selectId: (a: TFieldsObservationsNew) => {
    //In this case this would be optional since primary key is id
    return String(a.idtFieldsObservationNew);
  },
};
