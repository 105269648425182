import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { elementAt, first, take, takeUntil, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[checkFocus]',
})
export class CheckFocusDirective implements OnInit {
  @Input() checkFocus;
  element;

  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    if (document.activeElement?.getAttribute('checkFocusId') === this.checkFocus) {
      timer(0, 100)
        .pipe(take(5))
        .subscribe(() => {
          if (document.activeElement !== this.element) {
            this.element.select();
          }
        });
    }
  }
}
