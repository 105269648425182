import { Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { TPorRozhodnuti } from './TPorRozhodnuti';

@Index('ojp', ['ojp'], {})
@Index('pouzitekny_do', ['dPouzitelnyDo'], {})
@Entity('t_por', { schema: 'VaristarIS' })
export class TPor {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por' })
  idtPor: string;

  @Column('varchar', {
    name: 'OJP',
    nullable: true,
    comment: 'Název přípravku',
    length: 1024,
  })
  ojp: string | null;

  @Column('tinyint', { name: 'STAV', nullable: true })
  stav: number | null;

  @Column('datetime', {
    name: 'D_POUZITELNY_DO',
    nullable: true,
    comment: 'Datum platnosti + dva roky skladování',
  })
  dPouzitelnyDo: Date | null;

  @Column('bit', {
    name: 'deactivated',
    nullable: true,
    comment: 'Informuje, ci produkt v ciselniku EAGRI CZ existuje [0] nebo uz neexistuje [1]',
    default: () => "'b'0''",
  })
  deactivated: boolean | null;

  @OneToMany('TPorRozhodnuti', 'por')
  tPorRozhodnutis: TPorRozhodnuti[];
}
