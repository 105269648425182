import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  inProgress$ = new BehaviorSubject<boolean>(false);
  isUnknown$ = new BehaviorSubject<boolean>(false);
  isPercent$ = new BehaviorSubject<number>(0);
  status$ = new BehaviorSubject<string>(null);

  /**
   * Spuštění progress baru
   * @param status text popisujici co se dele behem progressu
   * @param percent pokud se nezada je -1 pak je Indeterminate a6 do stop
   */
  start(status: string = null, percent: number = -1) {
    this.inProgress$.next(true);
    if (percent < 0 || percent > 100) {
      this.isUnknown$.next(true);
      this.isPercent$.next(0);
    } else {
      this.isUnknown$.next(false);
      this.isPercent$.next(percent);
    }
    this.status$.next(status);
  }
  update(status: string, percent: number = -1) {
    if (percent < 0 || percent > 100) {
      this.isUnknown$.next(true);
      this.isPercent$.next(0);
    } else {
      this.isUnknown$.next(false);
      this.isPercent$.next(percent);
    }
    this.status$.next(status);
  }

  /**
   * Ukončení progresu a případné zobrazení posledního statusu po dobu timeout
   * @param lastStatus závěrečná zpráva
   * @param timeout [ms]
   */
  stop(lastStatus: string = null, timeout: number = 1000) {
    this.inProgress$.next(false);
    if (!!lastStatus && !!timeout) {
      this.status$.next(lastStatus);
      this.isPercent$.next(100);
      setTimeout(() => {
        this.isUnknown$.next(false);
        this.isPercent$.next(0);
      }, timeout);
    } else {
      this.isUnknown$.next(false);
      this.isPercent$.next(0);
    }
  }

  setLoading(loading: boolean = false) {
    if (loading) {
      this.status$.next(null);
      this.isPercent$.next(0);
    }
    this.isUnknown$.next(loading);
    this.inProgress$.next(loading);
  }
}
