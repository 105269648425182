import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-inline-progress-bar',
  templateUrl: './inline-progress-bar.component.html',
  styleUrls: ['./inline-progress-bar.component.css'],
})
export class InlineProgressBarComponent implements OnInit {
  @Input() progress: number = 0;
  @Input() showPercent = true;

  constructor() {}

  ngOnInit(): void {}
}
