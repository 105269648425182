import { TPlodinyEagri } from './entities/TPlodinyEagri';

export function getPlantName(language: string, plodina: TPlodinyEagri) {
  if (!plodina) {
    return '';
  }

  const keyUpperCase = 'NAZEV' + language.toUpperCase();
  return (
    plodina[
      Object.keys(plodina).find((plodina) => plodina.toUpperCase() === keyUpperCase) || 'nazev'
    ] || ''
  );
}

// tslint:disable-next-line: no-empty-interface
export interface Plant extends TPlodinyEagri {}

export const Plant = {
  selectId: (a: Plant) => {
    //In this case this would be optional since primary key is id
    return a.idtPlodinyEagri; // TBD: refId ?
  },

  getItems: (data: Plant[] = []) => {
    return data.map((plant) => ({
      name: plant.nazev,
      value: plant.idtPlodinyEagri,
    }));
  },
  // sortByDescription: (a: Field, b: Field): number => {
  //   return a.popis && a.popis.localeCompare(b.popis);
  // },

  // // localityNameFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string) => {
  // //   return PropsFilterFnFactory(['name'])(localities, pattern);
  // // },

  // localityEnabledFilterFn: (
  //   localities: Locality[],
  //   pattern: string) => {
  //   return PropsFilterFnFactory(['enabled'])(localities, !!pattern);
  // },

  // // localityIdFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string | number) => {
  // //   return localities.find(a => {
  // //     return a.id === pattern
  // //   });
  // //   //   // return PropsFilterFnFactory(['id'])(localities, pattern);
  // // // },

  // fullName: (p: Application) => {
  //   return p.order && `${p.order.popis} - ${p.order.popis}`;
  // },
};
