import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

@Index('Lokalita', ['mapvlst5'], {})
@Index('kod', ['nkod'], {})
@Index('idkd', ['fid'], {})
@Index('zkod', ['zkod'], {})
@Entity('t_LPIS_SK_pole', { schema: 'VaristarIS' })
export class TLpisSkPole {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'id_LPIS_SK_pole' })
  idLpisSkPole: string;

  @Column('int', {
    name: 'rok',
    nullable: true,
    comment: 'Rok pro který byl SHP sloubor vytvořen.',
  })
  rok: number | null;

  @Column('int', { name: 'fid', nullable: true, comment: 'ID honu.' })
  fid: number | null;

  @Column('varchar', {
    name: 'zkod',
    nullable: true,
    comment: 'Zkrácený kód v rámci katastrálního území.',
    length: 254,
  })
  zkod: string | null;

  @Column('double', {
    name: 'vymeram',
    nullable: true,
    comment: 'Výměra půdního bloku v hektarech.',
    precision: 7,
    scale: 2,
  })
  vymeram: number | null;

  @Column('varchar', {
    name: 'nkod',
    nullable: true,
    comment: 'Celostátní unikátní kód půdního bloku.',
    length: 45,
  })
  nkod: string | null;

  @Column('varchar', {
    name: 'mapvlst5',
    nullable: true,
    comment: 'Lokalita (katastrální)',
    length: 254,
  })
  mapvlst5: string | null;

  @Column('varchar', {
    name: 'prisl_opzpl',
    nullable: true,
    comment: 'Kód katastrálního území',
    length: 45,
  })
  prislOpzpl: string | null;

  @Column('tinyint', { name: 'valid', nullable: true, width: 1 })
  valid: boolean | null;

  @Column('varchar', {
    name: 'kulturanaz',
    nullable: true,
    comment: 'Typ využívání půdního bloku Orná půda, Vinice, Trvalý travní porost atd.',
    length: 254,
  })
  kulturanaz: string | null;
}
