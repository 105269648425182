import { Column, Entity, PrimaryGeneratedColumn } from "typeorm";

@Entity("t_tablet_wifi_scul_fw", { schema: "VaristarIS" })
export class TTabletWifiSculFw {
  @PrimaryGeneratedColumn({ type: "int", name: "id" })
  id: number;

  @Column("varchar", {
    name: "filename",
    comment: "Firmware filename",
    length: 20,
  })
  filename: string;

  @Column("varchar", {
    name: "description",
    nullable: true,
    comment: "Preview of fixed issues, new features,...",
    length: 400,
  })
  description: string | null;

  @Column("tinyint", {
    name: "is_active",
    comment: "Indicate if the firmware is active [1] or non-activate [0]",
    width: 1,
    default: () => "'1'",
  })
  isActive: boolean;
}
