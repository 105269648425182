import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AuthActionTypes, Authenticated } from '@varistar-apps/frontend/auth';
import { Firestore } from '@varistar-apps/shared/data';
import { defer, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  LoadRegistration,
  QueryRegistration,
  RegistrationActionTypes,
  RegistrationAdded,
  RegistrationFirebaseError,
  RegistrationModified,
  RegistrationRemoved,
} from './registration.actions';

// import { Registration } from '@energy-management/data';
@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    // private dataPersistence: DataPersistence<RegistrationPartialState>
  ) {}

  @Effect({ dispatch: true })
  // load$: Observable<Action> = this.actions$.pipe(
  load$ = this.actions$.pipe(
    ofType<LoadRegistration>(RegistrationActionTypes.Load),
    // switchMap(action => {
    mergeMap((action) => {
      // NOTE: Load akce se posila paralelne na vice zdroju, tak nelze cekat ze predchozi jiz skoncila proto melze pouzit switchap na zruseni predchoziho nedokonceneho requestu
      const feature = action.feature;
      // const dataUrl = `ORGANIZATION/1/${feature}`;
      const dataUrl = Firestore.getPath(feature);
      const collection = this.afs.collection(
        dataUrl,
        // ref => {
        //   return ref.where('tenantId', '==', '1')
        // }
      );
      return from(collection.get()).pipe(
        mergeMap((value) => value.docChanges()),

        map((documentChange) => {
          const id = documentChange.doc.id;
          const data = {
            ...documentChange.doc.data(),
            id,
          };
          switch (documentChange.type as DocumentChangeType) {
            case 'added':
              return new RegistrationAdded(feature, data);
            case 'modified':
              return new RegistrationModified(feature, data);
            case 'removed':
              return new RegistrationRemoved(feature, id);
          }
        }),

        // map(value => {
        //   return new RegistrationLoaded(feature, value.docs.map(doc => ({
        //     ...doc.data(),
        //     id: doc.id,
        //   })));
        // }),

        // takeUntil(this.afAuth.authState.pipe(filter(u => !u))), // NOTE: docasne vypnuto aby se overilole ze neprihlaseny uzivatel nema prava
        // mergeMap(actions => actions),
        // map(documentChange => {
        //   const data = {
        //     ...documentChange.payload.doc.data(),
        //     id: documentChange.payload.doc.id,
        //   }
        //   switch (documentChange.type as DocumentChangeType) {
        //     case 'added':
        //       return new RegistrationAdded(data);
        //     case 'modified':
        //       return new RegistrationModified(data);
        //     case 'removed':
        //       return new RegistrationRemoved(data);
        //   }
        // }),
        catchError((error) => of(new RegistrationFirebaseError({ ...error, dataUrl }))),
      );
    }),
  );

  // @Effect({ dispatch: true })
  // query$: Observable<Action> = this.actions$.pipe(
  //   ofType<QueryRegistration>(RegistrationActionTypes.Query),
  //   switchMap(action => {
  //     const dataUrl = 'ORGANIZATION/1/Registration';
  //     const collection = this.afs.collection<Registration>(
  //       dataUrl,
  //       ref => {
  //         return ref.where('tenantId', '==', '1')
  //       }
  //     );
  //     return from(collection.stateChanges())
  //       .pipe(
  //         takeUntil(this.afAuth.authState.pipe(filter(u => !u))), // NOTE: docasne vypnuto aby se overilole ze neprihlaseny uzivatel nema prava
  //         mergeMap(actions => actions),
  //         map(documentChange => {
  //           const data = {
  //             ...documentChange.payload.doc.data(),
  //             id: documentChange.payload.doc.id,
  //           }
  //           switch (documentChange.type as DocumentChangeType) {
  //             case 'added':
  //               return new RegistrationAdded(data);
  //             case 'modified':
  //               return new RegistrationModified(data);
  //             case 'removed':
  //               return new RegistrationRemoved(data);
  //           }
  //         }),
  //         catchError(error => of(new RegistrationFirebaseError({ ...error, dataUrl }))),
  //       )
  //   }),
  // );

  // @Effect({ dispatch: true })
  // update$: Observable<Action> = this.actions$.pipe(
  //   ofType<UpdateRegistration>(RegistrationActionTypes.Update),
  //   // map((action: RegistrationAction.Update) => action),
  //   switchMap(data => {
  //     const dataUrl = `ORGANIZATION/1/Registration${data.id}`;
  //     const ref = this.afs.doc<Registration>(dataUrl);
  //     return from(ref.update(data.changes));
  //   }),
  //   // TODO: dodelat kontrolu uspesnosti
  //   map(() => new UpdateRegistrationSuccess())
  // );

  // Odchyceni Login na aktivaci Query
  @Effect({ dispatch: true })
  login$: Observable<Action> = this.actions$.pipe(
    ofType<Authenticated>(AuthActionTypes.Authenticated),
    map((action) => {
      return new QueryRegistration();
    }),
  );
  // NOTE: Odchyceni Logout na odmazanani nactenych dat je jen v reduceru

  // Zachytne Init Action ?
  // Dle MIGRATION.md / @ngrx/effects / Init Action : https://github.com/ngrx/platform/blob/81afd0d71c0aca6d051a88954e2fa4edbf9a9cf2/MIGRATION.md
  @Effect()
  init$: Observable<Action> = defer(() => {
    // TODO: Doplnit nahrání dat pro všechny tabulky co jsou číselníky pro ostatní
    // return of(new QueryRegistration());
    // return of(new LoadRegistration(RegistrationFeature.Person));
    // return of(new LoadRegistration(RegistrationFeature.TradingPlatform));
  });
}
