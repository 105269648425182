import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TPorSkupinaUl } from './TPorSkupinaUl';
import { TPorRozhodnuti } from './TPorRozhodnuti';

@Index('idt_por_rozhodnuti_idx', ['rozhodnutiId'], {})
@Entity('t_por_ucinna_latka', { schema: 'VaristarIS' })
export class TPorUcinnaLatka {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_ucinna_latka' })
  idtPorUcinnaLatka: string;

  @Column('bigint', { primary: true, name: 'rozhodnuti_id' })
  rozhodnutiId: string;

  @Column('varchar', {
    name: 'NAZEV',
    nullable: true,
    comment: 'Název účinné látky',
    length: 200,
  })
  nazev: string | null;

  @Column('varchar', {
    name: 'NAZEV_EN',
    nullable: true,
    comment: 'Anglický název účinné látky',
    length: 1024,
  })
  nazevEn: string | null;

  @Column('varchar', {
    name: 'MNOZSTVI',
    nullable: true,
    comment: 'Množství účinné látky v přípravku',
    length: 200,
  })
  mnozstvi: string | null;

  @Column('varchar', {
    name: 'MJ',
    nullable: true,
    comment: 'Měrná jednotka množství účinné látky',
    length: 200,
  })
  mj: string | null;

  @Column('tinyint', {
    name: 'IP_VINICE',
    nullable: true,
    comment: 'Integrovaná produkce pro révu vinnou',
  })
  ipVinice: number | null;

  @Column('tinyint', {
    name: 'IP_SADY',
    nullable: true,
    comment: 'Integrovaná produkce pro ovocné stromy a keře',
  })
  ipSady: number | null;

  // @Column("tinyint", {
  //   name: "IP_ZELENINA",
  //   nullable: true,
  //   comment: "Integrovaná produkce pro zeleninu",
  // })
  // ipZelenina: number | null;

  // @OneToMany('TPorSkupinaUl', 'idUl2')
  // tPorSkupinaUls: TPorSkupinaUl[];

  @ManyToOne('TPorRozhodnuti', 'tPorUcinnaLatkas', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'rozhodnuti_id', referencedColumnName: 'idtPorRozhodnuti' }])
  rozhodnuti: TPorRozhodnuti;
}
