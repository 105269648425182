import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DynamicFormControlBase } from '../dynamic-form-control-base';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormBuilderService {
  private valueChangesSubscriptions: { [key: string]: Subscription } = {};

  constructor() {}

  /**
   * Vytvoří ze seznamu komponent form group
   * FIXIT: se se stejnym zanorenim, coz ovlivnuje strukturu vraceneho objektu
   * FIXIT: to zanoreni by ale mohlo byt jine nez je pak podle control id [pouzivane pro plneni data ze streamu  data$
   * NOTE: lepsi je vytvorit vracenou datovou strukturu podle control id
   * @param controls
   */
  toFormGroup(controls: DynamicFormControlBase<any>[], group: any = new FormGroup({})) {
    // FIXIT: zanoreni proste nejde
    // varianta pro flat form strukturu, vraceny data object se podle control key zrekonstruuje
    controls?.forEach((control) => {
      // // je to zanorena skupina
      // if (control.group && control.group.length) {
      //   group[control.key] = this.toFormGroup(control.group);
      // }
      // group.addControl(control.key, new FormControl({ value: control.value || null, disabled: control.disabled }, control.validator, control.asyncValidator));
      const formControl = new FormControl(
        {
          value: control.value || null,
          disabled: control.disabled,
        },
        {
          updateOn: 'change', // 'change' | 'blur' | 'submit'	 The event name for control to update upon. NOTE: nic jineho nez change moc nefunguje,
          validators: control.validator,
          asyncValidators: control.asyncValidator,
        },
      );
      // !!! NOTE: doplnena moznost prijimat prime notifikace o zmene hodnoty controlu
      if (control.change) {
        if (this.valueChangesSubscriptions[control.key])
          this.valueChangesSubscriptions[control.key].unsubscribe();
        this.valueChangesSubscriptions[control.key] = formControl.valueChanges.subscribe(
          (value) => {
            if (!control.ignoreValueChanges) control.change(value, control, group);
          },
        );
      }
      group.addControl(control.key, formControl);
    });

    // // varianta pro form group strukturu, ale to pak se nedaji jednoduse dohledat form control ze zanorenych struktur id
    // controls.forEach(control => {
    //   const path = control.key.split('.');

    //   path.reduce((controlGroup: FormGroup, name, index) => {
    //     if (!(controlGroup && controlGroup instanceof FormGroup)) {
    //       controlGroup = new FormGroup({})
    //     }
    //     const isLastIndex = (index + 1) >= path.length;
    //     if (isLastIndex) {
    //       controlGroup.addControl(control.key, new FormControl({ value: control.value || '', disabled: control.disabled }, control.validator, control.asyncValidator));
    //     } else {
    //       if (!controlGroup.get(name))
    //         controlGroup.addControl(name, new FormGroup({}));
    //     }
    //     return controlGroup.get(name);
    //   }, group);
    // });

    return group;
  }
}
