import { AuthAction } from '@varistar-apps/frontend/auth';
import {
  Access,
  Account,
  Company,
  Organization,
  Person,
  Resource,
  Lpis,
  OldMapLayer,
  MapLayer,
  Profile,
  // Deal,
  // TradingPlatform,
  // TradePoint,
  // Trader,
  // Customer,
  // Player,
  // Season,
} from '@varistar-apps/shared/data';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  ConfigurationAction,
  ConfigurationActionTypes,
  ConfigurationFeature,
  ConfigurationPayload,
} from './configuration.actions';

export const CONFIGURATION_FEATURE_KEY = 'configuration';

// Entity adapters
export const configurationAdapters: {
  [key in ConfigurationFeature]: EntityAdapter<ConfigurationPayload>;
} = {
  [ConfigurationFeature.Access]: createEntityAdapter<Access>({
    selectId: Access.selectId,
    // sortComparer: Trader.sortByName
  }),
  [ConfigurationFeature.Account]: createEntityAdapter<Account>({
    selectId: Account.selectId,
    // sortComparer: Trader.sortByName
  }),
  [ConfigurationFeature.Company]: createEntityAdapter<Company>({
    selectId: Company.selectId,
    sortComparer: Company.sortByName,
  }),
  [ConfigurationFeature.Organization]: createEntityAdapter<Organization>({
    selectId: Organization.selectId,
    // sortComparer: Trader.sortByName
  }),
  [ConfigurationFeature.Person]: createEntityAdapter<Person>({
    // NOTE: Modifikace chování entity adapteru pro Person data objekt
    selectId: Person.selectId,
    // sortComparer: Person.sortByLastName
  }),
  [ConfigurationFeature.Resource]: createEntityAdapter<Resource>({
    // NOTE: Modifikace chování entity adapteru pro Person data objekt
    selectId: Resource.selectId,
  }),
  [ConfigurationFeature.Profile]: createEntityAdapter<Profile>({
    // NOTE: Modifikace chování entity adapteru pro Person data objekt
    selectId: Profile.selectId,
  }),
  [ConfigurationFeature.Lpis]: createEntityAdapter<Lpis>({
    // NOTE: Modifikace chování entity adapteru pro Person data objekt
    selectId: Lpis.selectId,
  }),
  [ConfigurationFeature.MapLayer]: createEntityAdapter<MapLayer>({
    // NOTE: Modifikace chování entity adapteru pro Person data objekt
    selectId: MapLayer.selectId,
  }),
  // [ConfigurationFeature.Season]: createEntityAdapter<Season>({
  //   // NOTE: Modifikace chování entity adapteru pro Season data objekt
  //   selectId: Season.selectId,
  //   sortComparer: Season.sortByName
  // }),
  // [ConfigurationFeature.Player]: createEntityAdapter<Player>({
  //   // NOTE: Modifikace chování entity adapteru pro Person data objekt
  //   selectId: Person.selectId
  // }),
  // [ConfigurationFeature.Deal]: createEntityAdapter<Deal>({
  //   // NOTE: Modifikace chování entity adapteru pro Deal data objekt
  //   // selectId: Person.selectId,
  //   // sortComparer: Person.sortByLastName
  // }),
  // [ConfigurationFeature.TradingPlatform]: createEntityAdapter<TradingPlatform>({
  //   selectId: TradingPlatform.selectId,
  //   sortComparer: TradingPlatform.sortByName
  // }),
  // [ConfigurationFeature.TradePoint]: createEntityAdapter<TradePoint>({
  //   selectId: TradePoint.selectId,
  //   sortComparer: TradePoint.sortByName
  // }),
  // [ConfigurationFeature.Trader]: createEntityAdapter<Trader>({
  //   selectId: Trader.selectId
  //   // sortComparer: Trader.sortByName
  // }),
  // [ConfigurationFeature.Customer]: createEntityAdapter<Customer>({
  //   selectId: Customer.selectId
  //   // sortComparer: Trader.sortByName
  // })
  // [ConfigurationFeature.Role]: createEntityAdapter<Role>({
  //   selectId: Role.selectId,
  //   // sortComparer: Trader.sortByName
  // }),
};

// State
export interface ConfigurationFeatureState<T> extends EntityState<T> {
  selectedId?: string | number; // which configuration record has been selected
  loaded: boolean; // has the configuration list been loaded
  error?: any; // last none error (if any)
}

export interface ConfigurationState {
  [ConfigurationFeature.Access]: ConfigurationFeatureState<Access>;
  [ConfigurationFeature.Account]: ConfigurationFeatureState<Account>;
  [ConfigurationFeature.Company]: ConfigurationFeatureState<Company>;
  [ConfigurationFeature.Organization]: ConfigurationFeatureState<Organization>;
  [ConfigurationFeature.Person]: ConfigurationFeatureState<Person>;
  [ConfigurationFeature.Resource]: ConfigurationFeatureState<Resource>;
  [ConfigurationFeature.MapLayer]: ConfigurationFeatureState<OldMapLayer>;
  // [ConfigurationFeature.Season]: ConfigurationFeatureState<Season>;
  // [ConfigurationFeature.Deal]: ConfigurationFeatureState<Deal>;
  // [ConfigurationFeature.TradingPlatform]: ConfigurationFeatureState<
  //   TradingPlatform
  // >;
  // [ConfigurationFeature.TradePoint]: ConfigurationFeatureState<TradePoint>;
  // [ConfigurationFeature.Trader]: ConfigurationFeatureState<Trader>;
  // [ConfigurationFeature.Customer]: ConfigurationFeatureState<Customer>;
}

export interface ConfigurationPartialState {
  readonly [CONFIGURATION_FEATURE_KEY]: ConfigurationState;
}

// export const initialConfigurationState: ConfigurationState = {
//   [ConfigurationFeature.Person]: configurationAdapters[ConfigurationFeature.Person].getInitialState({
//     selectedId: null,
//     loaded: false,
//     error: null,
//   })
// };

export const getInitialConfigurationState = (feature: ConfigurationFeature) => {
  return configurationAdapters[feature].getInitialState({
    selectedId: null,
    loaded: false,
    error: null,
  });
};

export const configurationInitialState = Object.keys(ConfigurationFeature).reduce((state, key) => {
  state[key] = getInitialConfigurationState(ConfigurationFeature[key]);
  return state;
}, {});

export function configurationReducer(
  state: any, //initialConfigurationState,
  action: ConfigurationAction | AuthAction,
): ConfigurationState {
  if (!state) state = configurationInitialState;

  switch (action.type) {
    // pred Query se odmažou předchozí data
    case ConfigurationActionTypes.Query:
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].removeAll(state[action.feature]),
      };

    case ConfigurationActionTypes.Clear:
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].removeAll(state[action.feature]),
      };

    case ConfigurationActionTypes.Select:
      return {
        ...state,
        [action.feature]: { ...state[action.feature], selectedId: action.id },
      };

    case ConfigurationActionTypes.Added:
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].addOne(
          action.payload,
          state[action.feature],
        ),
      };

    case ConfigurationActionTypes.Modified:
      // // kvuli Resource ouziji zjisteni id pres selectId, ale musim pretipovat na number
      // const id = (configurationAdapters[action.feature]).selectId(action.payload);
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].updateOne(
          {
            // id: id,
            id: action.payload.id,
            changes: action.payload,
          },
          state[action.feature],
        ),
      };

    case ConfigurationActionTypes.Set:
      // // kvuli Resource ouziji zjisteni id pres selectId, ale musim pretipovat na number
      // const id = (configurationAdapters[action.feature]).selectId(action.payload);
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].setOne(
          action.payload,
          state[action.feature],
        ),
      };

    case ConfigurationActionTypes.Removed:
      return {
        ...state,
        [action.feature]: configurationAdapters[action.feature].removeOne(
          action.id,
          state[action.feature],
        ),
      };

    // return configurationAdapter[action.feature].addOne(action.payload, state[ConfigurationFeature.Person]);

    // case ConfigurationActionTypes.Modified:
    //   return configurationAdapter.updateOne({
    //     id: action.payload.refId,
    //     changes: action.payload
    //   }, state);

    // case ConfigurationActionTypes.Removed:
    //   return configurationAdapter.removeOne(action.payload.refId, state);

    // case ConfigurationActionTypes.FirebaseError:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    // // FIXIT: prasarna odchyceni uspesneho Logout
    // case AuthActionTypes.NotAuthenticated:
    //   return configurationAdapter.removeAll(state);
  }
  return state;
}
