import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormTranslateService {
  constructor(private translate: TranslateService) {}

  /**
   * Přeloží validační zprávy pro prvky formuláře
   * @param validationMessages objekt klíčů definujícími validační zprávy pro prvky formuláře
   */
  translateValidationMessages(validationMessages: any) {
    // TODO: prevest na univerzalni sluzbu
    // async translate
    _.forEach(validationMessages, (fields, name) => {
      const tkName = name.toUpperCase();
      _.forEach(fields, (validations, field) => {
        const tkField = field.toUpperCase();
        _.forEach(validations, (message, validation) => {
          const tkValidation = validation.toUpperCase();
          const tk = ['VALIDATION', tkName, tkField, tkValidation].join('.');
          this.translate.get(tk, this).subscribe((t) => {
            _.set(validationMessages, [name, field, validation], t); //  text validace se nahradi vzdy
            if (t !== tk) {
              // // nahrazeni prekladem se udele jen pokud se klic v prekladu nasel
              // this.validationMessages[name][field][validation] = t;
            } else {
              console.warn('Missing traslation key', tk);
            }
          });
        });
      });
    });
  }
}
