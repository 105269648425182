import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexLayoutFxFlexDirective } from './flex-layout-fx-flex.directive';
import { FlexLayoutFxLayoutDirective } from './flex-layout-fx-layout.directive';
import { FlexLayoutBreakpointsProvider } from './flex-layout-breakpoints';

@NgModule({
  imports: [CommonModule, FlexLayoutModule.withConfig({ disableDefaultBps: false })],
  declarations: [FlexLayoutFxFlexDirective, FlexLayoutFxLayoutDirective],
  exports: [FlexLayoutModule, FlexLayoutFxFlexDirective, FlexLayoutFxLayoutDirective],
  providers: [FlexLayoutBreakpointsProvider],
})
export class FrontendUiFlexLayoutModule {}
