import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

// import { TDodatky } from "./TDodatky";

@Index('id_cislo', ['cisloZfaktury'], {})
@Index('id_datum_fakt', ['datumZfaktury'], {})
@Index('id_dod', ['idDodatek'], {})
@Index('id_vyfakturuj.cz', ['idVyfakturuj'], {})
@Index('id_zf', ['idFakZalohove'], {})
@Index('is_uhraz', ['zalUhrazeno'], {})
@Entity('t_fak_zalohove', { schema: 'VaristarIS' })
export class TFakZalohove {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_fak_zalohove' })
  idFakZalohove: number;

  @Column('int', { name: 'id_dodatek', nullable: true })
  idDodatek: number | null;

  @Column('int', {
    name: 'id_vyfakturuj',
    nullable: true,
    comment: 'Slouží k připárování k tabulce t_vyfakturuj resp. ke službě Vyfakturuj. ',
  })
  idVyfakturuj: number | null;

  @Column('varchar', {
    name: 'zfaktura_mena',
    nullable: true,
    comment: 'Udává v jaké měně je faktura. ',
    length: 10,
  })
  zfakturaMena: string | null;

  @Column('decimal', {
    name: 'zfaktura_kurz',
    nullable: true,
    comment: 'Udává kurz pro přepočet vyfakurované částy na hodnotu v Kč. ',
    precision: 10,
    scale: 2,
  })
  zfakturaKurz: string | null;

  @Column('varchar', {
    name: 'cislo_zfaktury',
    nullable: true,
    comment: 'Číslo zálohové faktury. ',
    length: 45,
  })
  cisloZfaktury: string | null;

  @Column('decimal', {
    name: 'fakturovano_zal_bez_DPH',
    nullable: true,
    comment: 'Výše zálohové faktury bez DPH. ',
    precision: 10,
    scale: 2,
  })
  fakturovanoZalBezDph: string | null;

  @Column('date', { name: 'datum_zfaktury', nullable: true })
  datumZfaktury: string | null;

  @Column('date', {
    name: 'zal_uhrazeno',
    nullable: true,
    comment: 'Datum kdy byla provedena úhrada zálohové fakturuy. ',
  })
  zalUhrazeno: string | null;

  // @ManyToOne(() => TDodatky, (tDodatky) => tDodatky.tFakZalohoves, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_dodatek", referencedColumnName: "idDodatek" }])
  // idDodatek2: TDodatky;
}
