import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TZakaznikOsoby } from './TZakaznikOsoby';

@Index('t_zakaznik_osoby_role_FK', ['idtZakaznikOsoby'], {})
@Index('t_zakaznik_osoby_role_FK_1', ['idtRole'], {})
@Entity('t_zakaznik_osoby_role', { schema: 'VaristarIS' })
export class TZakaznikOsobyRole {
  @PrimaryGeneratedColumn({
    type: 'int',
    name: 'idt_zakaznik_osoby_role',
    comment: 'ID - primární klíč',
  })
  idtZakaznikOsobyRole: number;

  @Column('int', { name: 'idt_zakaznik_osoby', nullable: true })
  idtZakaznikOsoby: number | null;

  @Column('int', { name: 'idt_role', nullable: true })
  idtRole: number | null;

  @ManyToOne('TZakaznikOsoby', 'tZakaznikOsobyRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_zakaznik_osoby', referencedColumnName: 'idtZakaznikUser' }])
  idtZakaznikOsoby2: TZakaznikOsoby;
}
