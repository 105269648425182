import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { VisibilityService } from '../visibility-service/visibility.service';
import { filter, take } from 'rxjs/operators';

@Directive({ selector: '[visibleWith]' })
export class VisibleWithDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private visibilityService: VisibilityService,
  ) {}

  @Input()
  set visibleWith(element) {
    this.visibilityService
      .elementInSight(new ElementRef(element))
      .pipe(
        filter((visible) => visible),
        take(1),
      )
      .subscribe(() => {
        this.viewContainer.createEmbeddedView(this.templateRef);
      });
  }
}
