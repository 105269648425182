import { TabletWifiSculFw } from '@varistar-apps/shared/data'; 
import { DropboxDirect, Kultura, NewFeatureCategory } from '@varistar-apps/shared/data';
import { LpisSkSelectInfo } from '@varistar-apps/shared/data';
import { HoldingPerson } from '@varistar-apps/shared/data';
import { HoldingPersonRole } from '@varistar-apps/shared/data';
import { PersonRole } from '@varistar-apps/shared/data';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  EntityCollectionReducerFactory,
  EntityCollectionReducerMethodsFactory,
  EntityCollectionReducerRegistry,
  EntityDataService,
  EntityDefinitionService,
  EntityMetadataMap,
  PLURAL_NAMES_TOKEN,
} from '@ngrx/data';
import { FrontendUiFormModule } from '@varistar-apps/frontend/ui-form';
import {
  Annex,
  Application,
  BonityGroup,
  CiselnikDruh,
  Component,
  ConsumptionStandard,
  Contract,
  ContractTerminal,
  Customer,
  CustomerPerson,
  CustomerPersonDepartment,
  CustomerPersonDepartmentRole,
  CustomerPersonRole,
  Department,
  Fertilizer,
  FertilizerCustom,
  Field,
  FieldGroup,
  FieldObservationNew,
  FieldSelect,
  FieldYieldYear,
  Holding,
  LpisPole,
  LpisSkPole,
  LpisSkSelect,
  MapDocument,
  NewFeature,
  OnboardingPerson,
  OpenBalenaFleet,
  Order,
  OrderField,
  OrderFieldApplication,
  OrderStatus,
  OrderTerminal,
  OrderType,
  Owner,
  Permission,
  Person,
  PesticidCustom,
  Pesticides,
  PesticidType,
  PlanetApiKey,
  Plant,
  PlantType,
  ProductPrice,
  Resources,
  Role,
  Seed,
  SeedCustom,
  Sim,
  TabletMachine,
  TabletMachineType,
  TabletMachineUnit,
  TabletProtocol,
  TabletProtocolSpeed,
  TabletSettings,
  Tankmix,
  Terminal,
  TerminalSoil,
  TerminalType,
  User,
  VaristarProfile,
  Vyfakturuj,
  YieldPrice,
} from '@varistar-apps/shared/data';

import { FeatureConfigurationFormComponent } from '../feature-configuration-form/feature-configuration-form.component';
import { FeatureConfigurationEntityCollectionReducerMethodsFactory } from './feature-configuration.reducer';
import { FeatureConfigurationService } from './feature-configuration.service';
import { FieldArchive } from '../../../../../shared/data/src/lib/field-archive.model';

// import { FeatureConfigurationPersistenceResultHandler } from './feature-configuration.persistent';
// import { FeatureConfigurationEntityCollectionReducerMethodsFactory } from './feature-configuration.reducer';

export const entityMetadata: EntityMetadataMap = {
  TabletWifiSculFw: {
    selectId: TabletWifiSculFw.selectId,
  },
  Kultura: {
    selectId: Kultura.selectId,
  },
  LpisSkSelectInfo: {
    selectId: LpisSkSelectInfo.selectId,
  },
  HoldingPerson: {
    selectId: HoldingPerson.selectId,
  },
  HoldingPersonRole: {
    selectId: HoldingPersonRole.selectId,
  },
  PersonRole: {
    selectId: PersonRole.selectId,
  },
  PlanetApiKey: {
    selectId: PlanetApiKey.selectId,
  },
  NewFeature: {
    selectId: NewFeature.selectId,
  },
  NewFeatureCategory: {
    selectId: NewFeatureCategory.selectId,
  },
  FieldGroup: {
    selectId: FieldGroup.selectId,
  },
  LpisPole: {
    selectId: LpisPole.selectId,
  },
  CiselnikDruh: {
    selectId: CiselnikDruh.selectId,
  },
  Seed: {
    selectId: Seed.selectId,
    filterFn: Seed.filterFn,
  },
  YieldPrice: {
    selectId: YieldPrice.selectId,
  },
  PesticidCustom: {
    selectId: PesticidCustom.selectId,
    filterFn: PesticidCustom.filterFn,
  },
  SeedCustom: {
    selectId: SeedCustom.selectId,
    filterFn: SeedCustom.filterFn,
  },
  User: {
    selectId: User.selectId,
  },
  ContractTerminal: {
    selectId: ContractTerminal.selectId,
  },
  VaristarProfile: {
    selectId: VaristarProfile.selectId,
  },
  Application: {
    selectId: Application.selectId,
    sortComparer: Application.sortByDescription,
    // filterFn: Locality.localityNameFilterFn,
    // filterFn: Locality.localityIdFilterFn,
    // filterFn: Application.localityEnabledFilterFn,
    additionalCollectionState: {
      selectedId: null,
    },
  },
  Order: {
    selectId: Order.selectId,
    sortComparer: Order.sortByDescription,
    filterFn: Order.filterFn,
    additionalCollectionState: {
      selectedId: null,
    },
  },
  OrderType: {
    selectId: OrderType.selectId,
  },
  ConsumptionStandard: {
    selectId: ConsumptionStandard.selectId,
  },
  OrderStatus: {
    selectId: OrderStatus.selectId,
  },
  OrderTerminal: {
    selectId: OrderTerminal.selectId,
  },
  Field: {
    selectId: Field.selectId,
    filterFn: Field.filterFn,
  },
  FieldArchive: {
    selectId: FieldArchive.selectId,
    filterFn: FieldArchive.filterFn,
  },
  FieldObservationNew: {
    selectId: FieldObservationNew.selectId,
  },
  OrderField: {
    selectId: OrderField.selectId,
  },
  OrderFieldApplication: {
    selectId: OrderFieldApplication.selectId,
  },
  ProductPrice: {
    selectId: ProductPrice.selectId,
  },
  LpisSkSelect: {
    selectId: LpisSkSelect.selectId,
  },
  LpisSkPole: {
    selectId: LpisSkPole.selectId,
    filterFn: LpisSkPole.filterFn,
  },
  Terminal: {
    selectId: Terminal.selectId,
    filterFn: Terminal.filterFn,
  },
  TerminalType: {
    selectId: TerminalType.selectId,
  },
  TerminalSoil: {
    selectId: TerminalSoil.selectId,
  },
  TabletMachine: {
    selectId: TabletMachine.selectId,
    filterFn: TabletMachine.filterFn,
  },
  TabletMachineType: {
    selectId: TabletMachineType.selectId,
  },
  Annex: {
    selectId: Annex.selectId,
  },
  Department: {
    selectId: Department.selectId,
  },
  Vyfakturuj: {
    selectId: Vyfakturuj.selectId,
  },
  Person: {
    selectId: Person.selectId,
  },
  Role: {
    selectId: Role.selectId,
  },
  Permission: {
    selectId: Permission.selectId,
  },
  CustomerPersonDepartment: {
    selectId: CustomerPersonDepartment.selectId,
  },
  CustomerPersonDepartmentRole: {
    selectId: CustomerPersonDepartmentRole.selectId,
  },
  Fertilizer: {
    selectId: Fertilizer.selectId,
    additionalCollectionState: {
      page: null,
      pageCount: null,
      total: null,
      count: null,
    },
  },
  FertilizerCustom: {
    selectId: FertilizerCustom.selectId,
    filterFn: FertilizerCustom.filterFn,
  },
  Owner: {
    selectId: Owner.selectId,
    // sortComparer: Order.sortByDescription,
    // additionalCollectionState: {
    //   selectedId: null,
    // },
  },
  FieldSelect: {
    selectId: FieldSelect.selectId,
    // sortComparer: Order.sortByDescription,
    // additionalCollectionState: {
    //   selectedId: null,
    // },
  },
  FieldEdit: {
    selectId: FieldSelect.selectId,
    // sortComparer: Order.sortByDescription,
    // additionalCollectionState: {
    //   selectedId: null,
    // },
  },
  FieldYieldYear: {
    selectId: FieldYieldYear.selectId,
  },
  Plant: {
    selectId: Plant.selectId,
    // sortComparer: Order.sortByDescription,
    // additionalCollectionState: {
    //   selectedId: null,
    // },
  },
  PlantType: {
    selectId: PlantType.selectId,
  },
  BonityGroup: {
    selectId: BonityGroup.selectId,
  },
  Holding: {
    selectId: Holding.selectId,
    // sortComparer: Order.sortByDescription,
    additionalCollectionState: {
      selectedId: null,
    },
    filterFn: Holding.filterFn,
  },
  Customer: {
    selectId: Customer.selectId,
    // sortComparer: Order.sortByDescription,
    additionalCollectionState: {
      selectedId: null,
    },
    filterFn: Customer.filterFn,
  },
  Contract: {
    selectId: Contract.selectId,
  },
  Pesticides: {
    selectId: Pesticides.selectId,
  },
  PesticidType: {
    selectId: PesticidType.selectId,
  },
  Resource: {
    selectId: Resources.selectId,
  },
  MapDocument: {
    selectId: MapDocument.selectId,
  },
  CustomerPerson: {
    selectId: CustomerPerson.selectId,
  },
  CustomerPersonRole: {
    selectId: CustomerPersonRole.selectId,
  },
  Component: {
    selectId: Component.selectId,
  },
  OnboardingPerson: {
    selectId: OnboardingPerson.selectId,
  },
  Sim: {
    selectId: Sim.selectId,
  },
  Tankmix: {
    selectId: Tankmix.selectId,
  },

  TabletProtocol: {
    selectId: TabletProtocol.selectId,
  },
  TabletProtocolSpeed: {
    selectId: TabletProtocolSpeed.selectId,
  },
  TabletMachineUnit: {
    selectId: TabletMachineUnit.selectId,
  },
  TabletSettings: {
    selectId: TabletSettings.selectId,
  },
  OpenBalenaFleet: {
    selectId: OpenBalenaFleet.selectId,
  },
  DropboxDirect: {
    selectId: DropboxDirect.selectId,
  },
};

// because the plural of "locality" is not "localitys"
// TODO: ale protoze LB3 model pouziva jen plural, pro testy reseno pomoci proxy configurace, v deploy potreba upravit
export const pluralNames = {
  TabletWifiSculFw: 'tablet-wifi-scul-fw',
  Kultura: 'kultura',
  LpisSkSelectInfo: 'lpis-sk-select-info',
  HoldingPerson: 'holding-person',
  HoldingPersonRole: 'holding-person-role',
  PersonRole: 'person-role',
  PlanetApiKey: 'planet-api-key',
  NewFeature: 'new-feature',
  FieldGroup: 'field-group',
  LpisPole: 'lpis-pole',
  CiselnikDruh: 'ciselnik-druh',
  Seed: 'seed',
  YieldPrice: 'yield-price',
  PesticidCustom: 'pesticid-custom',
  SeedCustom: 'seed-custom',
  User: 'users',
  // VaristarUserProfile: 'VaristarUserProfile', // !!! nema backedn reprezentaci, je jen pro frontend
  Application: 'application',
  Order: 'orders',
  OrderType: 'order-types',
  OrderStatus: 'order-status', // !!! no plural
  OrderTerminal: 'order-terminals',
  OrderField: 'order-fields',
  OrderFieldApplication: 'order-fields-application', // mix order-fields a ze shapefile napocitane statistiky spotreby aplikaci pres zony v poli
  Field: 'fields',
  FieldObservationNew: 'fields-observations-new',
  FieldYieldYear: 'fields-yield-year',
  Terminal: 'terminals',
  TerminalType: 'terminal-types',
  TerminalSoil: 'terminal-soil',
  TabletMachine: 'tablet-machines',
  TabletMachineType: 'tablet-machine-types',
  Annex: 'annexes',
  Vyfakturuj: 'vyfakturuj',
  Fertilizer: 'fertilizers',
  FertilizerCustom: 'fertilizers-custom',
  Owner: 'owners',
  FieldSelect: 'fields-select',
  FieldEdit: 'fields-select',
  Person: 'persons',
  Plant: 'plants',
  PlantType: 'plant-types',
  Holding: 'holdings',
  Customers: 'customers',
  Pesticides: 'pesticides',
  PesticidType: 'pesticid-type',
  BonityGroup: 'bonity-groups',
  Resource: 'resources',
  MapDocument: 'map-document',
  CustomerPerson: 'customer-person',
  CustomerPersonRole: 'customer-person-roles',
  CustomerPersonDepartment: 'customer-person-department',
  CustomerPersonDepartmentRole: 'customer-person-department-roles',
  Component: 'component',
  OnboardingPerson: 'onboarding-persons',
  Department: 'departments',
  Role: 'roles',
  Permission: 'permissions',
  Sim: 'sim',
  LpisSkSelect: 'lpis-sk-select',
  LpisSkPole: 'lpis-sk-pole',
  Tankmix: 'tankmix',
  TabletProtocol: 'tablet-protocol',
  TabletProtocolSpeed: 'tablet-protocol-speed',
  TabletMachineUnit: 'tablet-machine-unit',
  TabletSettings: 'tablet-settings',
  ContractTerminal: 'contract-terminal',
  ProductPrice: 'product-price',
  ConsumptionStandard: 'consumption-standard',
  DropboxDirect: 'dropbox-directs',
  OpenBalenaFleet: 'open-balena/fleet',
};

// const defaultDataServiceConfig: DefaultDataServiceConfig = {
//   root: 'api/v1',
//   timeout: 3000, // request timeout
// }

@NgModule({
  imports: [CommonModule, FrontendUiFormModule],
  providers: [
    FeatureConfigurationService,
    { provide: PLURAL_NAMES_TOKEN, multi: true, useValue: pluralNames },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: FeatureConfigurationEntityCollectionReducerMethodsFactory,
    },
    // { provide: PersistenceResultHandler, useClass: FeatureConfigurationPersistenceResultHandler }
    // { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }, // NOTE: tady to nefunguje, presunuto do app.module
    // { provide: DefaultDataServiceFactory,  useClass: ExtendedDataServiceFactory },
    // ExtendedDataService,
  ],
  declarations: [FeatureConfigurationFormComponent],
  exports: [FeatureConfigurationFormComponent],
})
export class FeatureConfigurationModule {
  constructor(
    eds: EntityDefinitionService,
    entityDataService: EntityDataService,
    entityCollectionReducerRegistry: EntityCollectionReducerRegistry,
    entityCollectionReducerFactory: EntityCollectionReducerFactory,
    entityCollectionReducerMethodsFactory: EntityCollectionReducerMethodsFactory,
    // extendedDataservice: ExtendedDataService<Fertilizer>,
  ) {
    eds.registerMetadataMap(entityMetadata);
    // entityDataService.registerService('Fertilizer', extendedDataservice); // <-- register it
    // const reducerMap: EntityCollectionReducers= {'':}
    // entityCollectionReducerRegistry.registerReducersreducerMap);
    // entityCollectionReducerRegistry.registerReducer('Fertilizer', entityCollectionReducerFactory.create('Fertilizer'))
    // entityCollectionReducerMethodsFactory.create('Fertilizer');
  }
}
