import { TFields } from './entities/TFields';
import { TZakazkyFields } from './entities/TZakazkyFields';
import { OrderField } from './order-field.model';

// tslint:disable-next-line: no-empty-interface
export interface ApplicationMap extends OrderField {
  clipUrl: string; // url na stažení ořezového GeoJSON s okrajem pole
  geoJsonUrl: string; // url na stažení GeoJson s jednotlivými area ve poli (orderField)
  consumptionPerArea: number; // napočítané spotřeba produktu (hnojiva) přes včechny area v jednom poli dle FID_1 (orderField.idtFields)
  avgRatePerField: number; // napočítané spotřeba produktu (hnojiva) přes včechny area v poli dle FID_1 (orderField.idtFields)
}

export const ApplicationMap = {
  selectId: (a: OrderField) => {
    //In this case this would be optional since primary key is id
    return String(a.idtZakazkyFields);
  },
};
