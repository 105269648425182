import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { TTabletApplied } from './TTabletApplied';
import { TTabletMachines } from './TTabletMachines';
import { TTerminaly } from './TTerminaly';
import { TZakazky } from './TZakazky';
import { TTabletMachinesUnits } from './TTabletMachinesUnits';

@Index('t_id_tablet_machines', ['idTabletMachine'], {})
@Index('t_id_terminal', ['idTerminal'], {})
@Index('t_terminaly_idx', ['idTerminal'], {})
@Index('t_zakazky_idx', ['idZakazka'], {})
@Index('t_zakazky_terminal_machine_units', ['units'], {})
@Entity('t_zakazky_terminal', { schema: 'VaristarIS' })
export class TZakazkyTerminal {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_zakazka_terminal' })
  idtZakazkaTerminal: string;

  @Column('bigint', {
    name: 'id_zakazka',
    nullable: true,
    comment: 'Cizí klíč do tabulky t_zakazky',
  })
  idZakazka: string | null;

  @Column('int', {
    name: 'id_terminal',
    nullable: true,
    comment: 'Cizí klíč do tabulky t_terminaly',
  })
  idTerminal: number | null;

  @Column('int', {
    name: 'id_tablet_machine',
    nullable: true,
    comment:
      'Zadává ID stroje u terminálu Varistar Tablet - určuje, který profil nadefinovaného stroje má být použit pro danou aplikační mapu - ID stroje se přenáší rovněž v XML souboru do tabletu a tablet podle něj pozná, kterému profilu stroje má mapu přiřadit.',
  })
  idTabletMachine: number | null;

  @Column('datetime', {
    name: 'date_sent',
    nullable: true,
    default: () => 'CURRENT_TIMESTAMP',
  })
  dateSent: Date | null;

  @Column('tinyint', {
    name: 'map_deactivated',
    nullable: true,
    comment:
      'Logická hodnota jestli je mapa aktivní pro aplikaci v terminálu. Je-li 1 znamená to, že byla z terminálu/úložiště smazána. Pro Varistar tablet bude až teprve ve chvíli kdy bude vymazána z tabletu - nezaměňovat s automatickou deaktivací (je neaktivní ale ne smazaná - obsluha ji ještě může ručně aktivovat). Tento stav nebude v portálu zachycen.',
    width: 1,
    default: () => "'0'",
  })
  mapDeactivated: boolean | null;

  @Column('datetime', {
    name: 'last_applied',
    nullable: true,
    comment: 'Poslední datum aplikace daným strojem v dané zakázce',
  })
  lastApplied: Date | null;

  @Column('double', {
    name: 'total_applied',
    nullable: true,
    comment: 'Celková aplikovaná výměra v (ha)  v dané zakázce daným strojem',
    precision: 22,
  })
  totalApplied: number | null;

  @Column('int', {
    name: 'units',
    nullable: true,
    comment: 'Jednotky v jakých stroj dávkuje',
  })
  units: number | null;

  // units3 name is required (unique error on order guide step 7), do not rename!
  @ManyToOne('TTabletMachinesUnits', 'tZakazkyTerminals', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'units', referencedColumnName: 'idtTabletMachinesUnits' }])
  units3: TTabletMachinesUnits;

  // @OneToMany(
  //   () => TTabletApplied,
  //   (tTabletApplied) => tTabletApplied.idZakazkyTerminal2
  // )
  @OneToMany('TTabletApplied', 'idZakazkyTerminal2', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_zakazka_terminal', referencedColumnName: 'idZakazkyTerminal' }])
  tTabletApplieds: TTabletApplied[];

  // @ManyToOne(
  //   () => TTabletMachines,
  //   (tTabletMachines) => tTabletMachines.tZakazkyTerminals,
  //   { onDelete: "NO ACTION", onUpdate: "NO ACTION" }
  // )
  @ManyToOne('TTabletMachines', 'tZakazkyTerminals', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_tablet_machine', referencedColumnName: 'idtTabletMachines' }])
  idTabletMachine2: TTabletMachines;

  // @ManyToOne(() => TTerminaly, (tTerminaly) => tTerminaly.tZakazkyTerminals, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  @ManyToOne('TTerminaly', 'tZakazkyTerminals', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_terminal', referencedColumnName: 'idTerminaly' }])
  idTerminal2: TTerminaly;

  // @ManyToOne(() => TZakazky, (tZakazky) => tZakazky.tZakazkyTerminals, {
  //   onDelete: "CASCADE",
  //   onUpdate: "CASCADE",
  // })
  @ManyToOne('TZakazky', 'tZakazkyTerminals', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_zakazka', referencedColumnName: 'idZakazky' }])
  idZakazka2: TZakazky;
}
