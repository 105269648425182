import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ui-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements OnInit, AfterViewInit {
  @Output() filterChange = new EventEmitter();
  @Input() defaultValue: string;

  id = Math.random().toString(36).slice(0, 10);

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.defaultValue) {
      (<HTMLInputElement>document.querySelector(`[id="${this.id}"]`)).value = this.defaultValue;
    }
  }
}
