<dynamic-form
  [header]="control.header"
  [description]="control.description"
  [controls]="controls"
  [data]="data"
  (save)="onSave($event)"
  (delete)="onDelete($event)"
  (modify)="onModify($event)"
  resetWhenChanged="value"
  layout="simple"
  fxFlex
  [enable]="control.enable"
  [autosave]="true"
>
</dynamic-form>

<div fxLayout="row" fxLayoutAlign="end center">
  <button
    *ngFor="let button of control.buttons"
    mat-icon-button
    [disableRipple]="true"
    [matTooltip]="button.tooltip | translate"
    matTooltipPosition="above"
    (click)="button.click(this.form, this.control)"
    [disabled]="isDisabled"
  >
    <mat-icon>{{ button.icon }}</mat-icon>
  </button>
</div>

<!-- <div fxLayout="row">
  <input matInput [type]="control.type" [formControlName]="control.key" [id]="control.key" [required]="control.required" [disableControl]="control.disabled" (change)="onChange(form)" [placeholder]="control.placeholder | translate" [autocomplete]="control.key" cdkFocusInitial>
</div> -->
<!-- {{control | json}} -->
