import { Action, ActionReducer } from '@ngrx/store';

// if backend returns empty list as a response (for example, when obchodnik does not have rights to access certain data)
// ngrx store will break (payload.data is null). This should filter out empty responses
export function filterEmptyNrgxAction(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type.includes("@ngrx/data") && (action as any).payload?.data === null) {
      return state;
    }

    return reducer(state, action);
  };
}
