import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TPorPouziti } from './TPorPouziti';

@Index('idt_por_pouziti_soppp', ['pouzitiId'], {})
@Entity('t_por_pouziti_so_ppp', { schema: 'VaristarIS' })
export class TPorPouzitiSoPpp {
  @Column('bigint', { name: 'pouziti_id' })
  pouzitiId: string;

  @Column('int', { name: 'KOD_SO', nullable: true, comment: 'Kód ŠO z PPP' })
  kodSo: number | null;

  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_pouziti_so_ppp' })
  idtPorPouzitiSoPpp: string;

  // @ManyToOne(
  //   () => TPorPouziti,
  //   (tPorPouziti) => tPorPouziti.tPorPouzitiSoPpps,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  @ManyToOne('TPorPouziti', 'tPorPouzitiSoPpps', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'pouziti_id', referencedColumnName: 'idtPorPouziti' }])
  pouziti: TPorPouziti;
}
