import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultDataService,
  DefaultDataServiceConfig,
  HttpUrlGenerator,
  QueryParams,
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FrontendUiFeatureExtendedDataService<T> extends DefaultDataService<T> {
  constructor(
    entityName: string,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    config?: DefaultDataServiceConfig,
  ) {
    super(entityName, http, httpUrlGenerator, config);
  }

  getWithQuery(params: string | QueryParams): Observable<T[]> {
    return super.getWithQuery(params).pipe(
      // TODO: načte data i pokud přijdou v paginate nestjsx/crud formatu, cili zanoreny pod atribut data
      // map((res: any) => res.data || res)
      // !!! pokus osto resit to v reduceru a odchytit i pagination atributy a ulozit je, a data z atributu data mapovat tam
      map((res: any) => res),
    );
  }
}
