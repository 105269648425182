import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TPlodinyEagri } from './TPlodinyEagri';

@Index('ctverec', ['ctverec'], {})
@Index('t_fields_yield_year_FK', ['idCrop'], {})
@Index('t_fields_yield_year_nkod_IDX', ['nkod'], {})
@Index('t_fields_yield_year_year_IDX', ['year'], {})
@Index('year', ['year'], {})
@Index('zkod', ['zkod'], {})
@Entity('t_fields_yield_year', { schema: 'VaristarIS' })
export class TFieldsYieldYear {
  @PrimaryGeneratedColumn({
    type: 'bigint',
    name: 'idt_fields_yield_year',
    comment:
      'Historické výnosy po jednotlivých letech pro jednotlivé hony. Základním identifikátorem je CTVEREC + ZKOD - ten se mění výrazně méně než FID.',
  })
  idtFieldsYieldYear: string;

  @Column('varchar', {
    name: 'ctverec',
    nullable: true,
    comment:
      'Kód mapového čtverce - slouží pro vyhledání daného honu v LPIS. - BERE SE Z T-FIELDS, TADY ZBYTECNY',
    length: 45,
  })
  ctverec: string | null;

  @Column('varchar', {
    name: 'zkod',
    nullable: true,
    comment: 'Zkrácený kód - označní z databáze LPIS. - BERE SE Z T-FIELDS, TADY ZBYTECNY',
    length: 45,
  })
  zkod: string | null;

  @Column('int', {
    name: 'year',
    nullable: true,
    comment: 'Rok žní (year = seasonId + 1)',
  })
  year: number | null;

  @Column('varchar', {
    name: 'crop',
    nullable: true,
    comment: 'Plodina v daném roce - ZREJME ZBYTECNE - STACI ID-CROP',
    length: 255,
  })
  crop: string | null;

  @Column('varchar', {
    name: 'harvest_date',
    nullable: true,
    comment: 'Datum žní',
    length: 45,
  })
  harvestDate: string | null;

  @Column('decimal', {
    name: 'yield',
    nullable: true,
    comment: 'Výnos v tunách.',
    precision: 8,
    scale: 2,
  })
  yield: string | null;

  @Column('varchar', {
    name: 'comment',
    nullable: true,
    comment: 'Poznámka např. různé poškození porostu apod.',
    length: 1024,
  })
  comment: string | null;

  @Column('int', { name: 'id_crop', nullable: true })
  idCrop: number | null;

  @Column('varchar', { name: 'nkod', nullable: true, length: 45 })
  nkod: string | null;

  @Column('decimal', {
    name: 'vymeram',
    nullable: true,
    precision: 6,
    scale: 2,
  })
  vymeram: string | null;

  @ManyToOne('TPlodinyEagri', 'tFieldsYieldYears', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_crop', referencedColumnName: 'idtPlodinyEagri' }])
  idCrop2: TPlodinyEagri;
}
