import { PropsFilterFnFactory } from '@ngrx/data';
import { TZakazkyStav } from './entities/TZakazkyStav';

// tslint:disable-next-line: no-empty-interface
export interface OrderStatus extends TZakazkyStav {}

export const OrderStatus = {
  selectId: (a: OrderStatus) => {
    //In this case this would be optional since primary key is id
    return a.idtZakazkyStav;
  },
  getItems: (data: OrderStatus[] = []) => {
    return data.map((orderStatus) => ({
      name: `ORDER_STATUS.${orderStatus.description.toUpperCase()}`,
      value: orderStatus.idtZakazkyStav,
    }));
  },
};
