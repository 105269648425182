import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { TLpisSelect } from './TLpisSelect';
import { TFields } from './TFields';
import { TPlodinyEagrIskupinaPlodin } from './TPlodinyEagrIskupinaPlodin';
import { TZakazky } from './TZakazky';

@Index('IDSKUPPLODIN', ['idskupplodin'], {})
@Index('nazev', ['nazev'], {})
@Entity('t_plodiny_EAGRI', { schema: 'VaristarIS' })
export class TPlodinyEagri {
  @Column('int', { primary: true, name: 'idt_plodiny_EAGRI' })
  idtPlodinyEagri: number;

  @Column('varchar', { name: 'NAZEV', nullable: true, length: 255 })
  nazev: string | null;

  @Column('varchar', { name: 'NAZEV_SK', nullable: true, length: 255 })
  nazevSk: string | null;

  @Column('varchar', {
    name: 'EPPOKOD',
    nullable: true,
    comment: 'EPPO kód plodiny.',
    length: 255,
  })
  eppokod: string | null;

  @Column('int', {
    name: 'LIMITN',
    nullable: true,
    comment: 'Limit přísunu přepočteného dusíku v rámci nitrátové směrnice.',
  })
  limitn: number | null;

  @Column('int', {
    name: 'LIMITN2012',
    nullable: true,
    comment: 'Limit přísunu přepočteného dusíku v rámci nové nitrátové směrnice 2012.',
  })
  limitn2012: number | null;

  @Column('int', {
    name: 'IDSKUPPLODIN',
    nullable: true,
    comment:
      'Udává ID skupiny, do které je plodina zařazena. Odkazuje na t_plodiny_EAGRIskupina_plodin',
  })
  idskupplodin: number | null;

  @Column('varchar', {
    name: 'color_id',
    nullable: true,
    comment: 'RGB color of cultivated plant to colorized the FID in CLIP layer',
    length: 50,
  })
  colorId: string | null;

  @ManyToOne('TPlodinyEagrIskupinaPlodin', 'tPlodinyEagris', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'IDSKUPPLODIN', referencedColumnName: 'idtSkupinaPlodin' }])
  idskupplodin2: TPlodinyEagrIskupinaPlodin;

  @OneToMany('TZakazky', 'idPlodiny2')
  tZakazkies: TZakazky[];

  @OneToMany('TFields', 'idPlodiny2')
  tFields: TFields[];

  @OneToMany('TLpisSelect', 'idPlodiny2')
  tLpisSelect: TLpisSelect[];
}
