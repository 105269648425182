<h1 mat-dialog-title>{{ name | translate }}</h1>
<mat-dialog-content class="content">
  <ui-filter-select
    class="userSelect"
    [optionList$]="data.optionList$"
    [selectedId$]="selectedId$"
    (optionSelect)="handleSelectOption($event)"
    [label]="name"
    [width]="width"
  ></ui-filter-select>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="c-rounded-button c-rounded-button-outlined c-rounded-button--small"
    (click)="handleCloseDialog()"
    cdkFocusInitial
  >
    <span translate>BUTTON.CANCEL</span>
  </button>
  <button
    cy-button-save
    class="c-rounded-button c-rounded-button-primary c-rounded-button--small"
    (click)="handleSave()"
    [disabled]="!(selectedId$ | async)"
    #saveButton
  >
    <span translate>BUTTON.SAVE</span>
  </button>
</mat-dialog-actions>
