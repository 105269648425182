import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'ui-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnDestroy, OnChanges {
  subscription = new Subscription();

  _startDate$ = new BehaviorSubject(null);
  _endDate$ = new BehaviorSubject(null);

  @Input() startDate$: Observable<moment.Moment>;
  @Input() endDate$: Observable<moment.Moment>;

  @Input() max$ = of(moment().add('100', 'year'));
  @Input() min$ = of(moment().subtract('100', 'year'));

  @Input() startDate: string;
  @Input() endDate: string;

  @Input() nameStart = 'PLACEHOLDER.CHOOSE_START_DATE';
  @Input() nameEnd = 'PLACEHOLDER.CHOOSE_END_DATE';

  @Output() startDateChange = new EventEmitter();
  @Output() endDateChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.startDate$) {
      this.subscription.add(
        this.startDate$.subscribe((startDate) => {
          this._startDate$.next(startDate);
        }),
      );
    }

    if (this.endDate$) {
      this.subscription.add(
        this.endDate$.subscribe((endDate) => {
          this._endDate$.next(endDate);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate) {
      this._startDate$.next(moment(changes.startDate.currentValue, 'YYYY-MM-DD'));
    }

    if (changes.endDate) {
      this._endDate$.next(moment(changes.endDate.currentValue, 'YYYY-MM-DD'));
    }
  }
}
