import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthFacade } from '@varistar-apps/frontend/auth';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { DynamicFormTranslateService } from '@varistar-apps/frontend/ui-form';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-auth-dialog-login',
  templateUrl: './auth-dialog-login.component.html',
  styleUrls: ['./auth-dialog-login.component.scss'],
})
export class AuthDialogLoginComponent implements OnInit {
  minPasswordLength = 1;
  rememberMe = true;

  loginForm = this.fb.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: [
      '',
      Validators.compose([Validators.required /*, Validators.minLength(this.minPasswordLength)*/]),
    ],
    rememberMe: [false],
  });

  // NOTE: inspirace https://angular-templates.io/tutorials/about/angular-forms-and-validations
  // Ale upraveno z array na object a keyvalue pipe aby se daly prelozit
  // v prekladu assets/i18n/cs.json jsou vsechny klice v objektu prevedeny na velke pismena
  // obsah se nahradi prelozenym textem pokud exisuje, jako parametr se prilozi public atributy tridy, zde napr. maxContentLength
  validationMessages = {
    auth: {
      email: {
        email: '',
        required: '',
      },
      password: {
        required: '',
      },
    },
  };

  constructor(
    public dialogRef: MatDialogRef<AuthDialogLoginComponent>,
    public dialog: MatDialog,
    private translateFormService: DynamicFormTranslateService,
    private fb: FormBuilder,
    public authFacade: AuthFacade,
    library: FaIconLibrary,
    // public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    library.addIconPacks(fas, far, fab);
  }

  ngOnInit() {
    // inicializace form
    this.loginForm.setValue({
      email: this.data.email || '',
      password: this.data.password || '',
      rememberMe: !!this.data.rememberMe || true,
    });
    this.translateFormService.translateValidationMessages(this.validationMessages);
  }

  onLogin() {
    this.authFacade.loginByEmail(
      {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      },
      // this.loginForm.value.rememberMe
    );
  }

  onLoginByGoogle() {
    this.authFacade.loginByGoogle();
  }

  onLoginByFacebook() {
    this.authFacade.loginByFacebook();
  }

  onLoginByMicrosoft() {
    this.authFacade.loginByMicrosoft();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
