<ng-template #contentButtons>
  <ng-content select="button"></ng-content>
</ng-template>
<!-- <ng-template #contentSelect>
  <ng-content select="mat-form-field"></ng-content>
</ng-template> -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<ng-template #formButtons>
  <button
    mat-icon-button
    [disableRipple]="true"
    color
    *ngIf="enable?.delete"
    (click)="onDelete($event)"
    [matTooltip]="'BUTTON.DELETE' | translate"
    matTooltipPosition="above"
  >
    <mat-icon>delete</mat-icon>
  </button>
  <button
    mat-icon-button
    [disableRipple]="true"
    color="warn"
    *ngIf="enable?.save"
    [disabled]="form.invalid || !form.dirty"
    (click)="onSave()"
    [matTooltip]="'BUTTON.SAVE' | translate"
    matTooltipPosition="above"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-icon-button
    [disableRipple]="true"
    color="accent"
    *ngIf="enable?.cancel"
    [disabled]="!form.dirty"
    (click)="onCancel()"
    [matTooltip]="'BUTTON.RESET_CHANGES' | translate"
    matTooltipPosition="above"
  >
    <mat-icon>cancel</mat-icon>
  </button>
</ng-template>

<!-- {{data$ | async | json}} -->
<!-- <form [formGroup]="form" (ngSubmit)="onSubmit($event)"> -->
<form
  [formGroup]="form"
  [ngSwitch]="layout"
  (keydown.enter)="$event.preventDefault()"
  fxFlexFill
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <ng-container *ngSwitchCase="'simple'">
    <div fxFlex fxLayout="column">
      <!-- <mat-card-title *ngIf="enable?.header" translate>{{header}}</mat-card-title> -->
      <mat-card-subtitle *ngIf="enable?.header" translate>
        {{ header }}
      </mat-card-subtitle>
      <mat-card-subtitle style="font-size: 75%; margin: 0" *ngIf="enable?.description" translate>
        {{ description }}
      </mat-card-subtitle>
      <!-- <ng-container *ngTemplateOutlet="contentSelect"></ng-container> -->
      <div *ngFor="let controls of controlRows">
        <div *ngIf="controls?.length" fxLayoutGap="16px" fxLayout="row" class="rowControls">
          <ng-container *ngFor="let control of controls">
            <!-- {{control | json}} -->
            <dynamic-form-control
              *ngIf="control"
              [control]="control"
              [form]="form"
              [fxFlex]="control.flex"
              (event)="onEvent($event)"
              (change)="onChange($event)"
              (mouseout)="onMouseOut(control)"
              (focus)="onFocus(form)"
              (blur)="onBlur(form)"
              (action)="onAction($event)"
              (presskey)="onPressKey($event)"
              (enter)="onEnter($event)"
              (esc)="onEsc($event)"
            >
              <ng-container *ngTemplateOutlet="content"></ng-container>
            </dynamic-form-control>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="contentButtons"></ng-container>
    <ng-container *ngTemplateOutlet="formButtons"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'card'">
    <mat-card fxFlex fxLayout="column">
      <mat-card-header *ngIf="enable?.header">
        <mat-card-title translate>{{ header }}</mat-card-title>
        <div fxFlex></div>
        <div fxFlex fxLayout="row" fxLayoutAlign="end center">
          <ng-container *ngTemplateOutlet="contentButtons"></ng-container>
          <ng-container *ngTemplateOutlet="formButtons"></ng-container>
        </div>
        <mat-card-subtitle translate>{{ description }}</mat-card-subtitle>
      </mat-card-header>
      <!-- <mat-card-content fxFlex style="overflow: auto"> -->
      <mat-card-content fxFlex style="overflow: visible">
        <div fxLayoutGap="8px" fxLayout="column" fxFlex>
          <div *ngFor="let controls of controlRows">
            <div *ngIf="controls?.length" fxLayoutGap="16px" fxLayout="row">
              <ng-container *ngFor="let control of controls">
                <label
                  *ngIf="control?.overflowLabel && control?.placeholder"
                  class="overflowLabel"
                  >{{ control.placeholder }}</label
                >
                <dynamic-form-control
                  *ngIf="control"
                  [control]="control"
                  [form]="form"
                  [fxFlex]="control.flex"
                  (event)="onEvent($event)"
                  (change)="onChange($event)"
                  (mouseout)="onMouseOut(control)"
                  (blur)="onBlur(control)"
                  (action)="onAction($event)"
                  (presskey)="onPressKey($event)"
                  ((enter)="onEnter($event)"
                  (esc)="onEsc($event)"
                >
                </dynamic-form-control>
              </ng-container>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</form>
