import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthFacade } from '@varistar-apps/frontend/auth';
import { DataService } from '@varistar-apps/frontend/ui-feature';
import {
  Annex,
  ApplicationMapStats,
  Contract,
  Field,
  FieldSelect,
  FieldYieldYear,
  getPlantName,
  LpisSkSelect,
  Order,
  OrderField,
  Plant,
  ProductApplication,
  ProductTypeEnum,
  TabletMachineTypes,
  TPlodinyEagri,
  TZakazkyFields,
} from '@varistar-apps/shared/data';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as qs from 'querystringify';
import { combineLatest, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, skip } from 'rxjs/operators';
import * as urljoin from 'url-join';
import * as XLSX from 'xlsx';
import { compare } from './compare';
import { MatTooltip } from '@angular/material/tooltip';
import { QueryList } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface seasonOption {
  value: number;
  name: string;
}

/**
 * Vytvoreni URL z podle paramatru a nahrazovanych atibutu a jejich hodnot
 * @param parts pole casti url, cast muze obsahovat cestu atributu v ids jehoz hodnotou se nahradi
 * @param ids objekt s atributy jejich cesta v parts se pripadne nahradi hodnotou
 */
export const getUrl = (parts: string[], ids: any = {}, query: any = {}): string => {
  const pathParams = parts.map((param) => String(_.get(ids, param, param)));
  // const url = pathParams.join('/');
  const url = `${urljoin(pathParams)}${qs.stringify(query, '?')}`;

  return url;
};

export const phoneRegexp = new RegExp(
  /^\s*(([\+]?[0-9]{3}|00[0-9]{3})[' ']?)?([0-9]{3}[' ']?){3}\s*$/,
);

/*
   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
*/
const defaultDiacriticsRemovalMap = [
  {
    base: 'A',
    letters:
      '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F',
  },
  { base: 'AA', letters: '\uA732' },
  { base: 'AE', letters: '\u00C6\u01FC\u01E2' },
  { base: 'AO', letters: '\uA734' },
  { base: 'AU', letters: '\uA736' },
  { base: 'AV', letters: '\uA738\uA73A' },
  { base: 'AY', letters: '\uA73C' },
  {
    base: 'B',
    letters: '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181',
  },
  {
    base: 'C',
    letters: '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E',
  },
  {
    base: 'D',
    letters:
      '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0',
  },
  { base: 'DZ', letters: '\u01F1\u01C4' },
  { base: 'Dz', letters: '\u01F2\u01C5' },
  {
    base: 'E',
    letters:
      '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E',
  },
  { base: 'F', letters: '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B' },
  {
    base: 'G',
    letters:
      '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E',
  },
  {
    base: 'H',
    letters: '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D',
  },
  {
    base: 'I',
    letters:
      '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197',
  },
  { base: 'J', letters: '\u004A\u24BF\uFF2A\u0134\u0248' },
  {
    base: 'K',
    letters: '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2',
  },
  {
    base: 'L',
    letters:
      '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780',
  },
  { base: 'LJ', letters: '\u01C7' },
  { base: 'Lj', letters: '\u01C8' },
  { base: 'M', letters: '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C' },
  {
    base: 'N',
    letters:
      '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4',
  },
  { base: 'NJ', letters: '\u01CA' },
  { base: 'Nj', letters: '\u01CB' },
  {
    base: 'O',
    letters:
      '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C',
  },
  { base: 'OI', letters: '\u01A2' },
  { base: 'OO', letters: '\uA74E' },
  { base: 'OU', letters: '\u0222' },
  { base: 'OE', letters: '\u008C\u0152' },
  { base: 'oe', letters: '\u009C\u0153' },
  {
    base: 'P',
    letters: '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754',
  },
  { base: 'Q', letters: '\u0051\u24C6\uFF31\uA756\uA758\u024A' },
  {
    base: 'R',
    letters:
      '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782',
  },
  {
    base: 'S',
    letters:
      '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784',
  },
  {
    base: 'T',
    letters:
      '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786',
  },
  { base: 'TZ', letters: '\uA728' },
  {
    base: 'U',
    letters:
      '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244',
  },
  { base: 'V', letters: '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245' },
  { base: 'VY', letters: '\uA760' },
  {
    base: 'W',
    letters: '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72',
  },
  { base: 'X', letters: '\u0058\u24CD\uFF38\u1E8A\u1E8C' },
  {
    base: 'Y',
    letters:
      '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE',
  },
  {
    base: 'Z',
    letters: '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762',
  },
  {
    base: 'a',
    letters:
      '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250',
  },
  { base: 'aa', letters: '\uA733' },
  { base: 'ae', letters: '\u00E6\u01FD\u01E3' },
  { base: 'ao', letters: '\uA735' },
  { base: 'au', letters: '\uA737' },
  { base: 'av', letters: '\uA739\uA73B' },
  { base: 'ay', letters: '\uA73D' },
  {
    base: 'b',
    letters: '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253',
  },
  {
    base: 'c',
    letters: '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184',
  },
  {
    base: 'd',
    letters: '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A',
  },
  { base: 'dz', letters: '\u01F3\u01C6' },
  {
    base: 'e',
    letters:
      '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD',
  },
  { base: 'f', letters: '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C' },
  {
    base: 'g',
    letters:
      '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F',
  },
  {
    base: 'h',
    letters:
      '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265',
  },
  { base: 'hv', letters: '\u0195' },
  {
    base: 'i',
    letters:
      '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131',
  },
  { base: 'j', letters: '\u006A\u24D9\uFF4A\u0135\u01F0\u0249' },
  {
    base: 'k',
    letters: '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3',
  },
  {
    base: 'l',
    letters:
      '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747',
  },
  { base: 'lj', letters: '\u01C9' },
  { base: 'm', letters: '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F' },
  {
    base: 'n',
    letters:
      '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5',
  },
  { base: 'nj', letters: '\u01CC' },
  {
    base: 'o',
    letters:
      '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275',
  },
  { base: 'oi', letters: '\u01A3' },
  { base: 'ou', letters: '\u0223' },
  { base: 'oo', letters: '\uA74F' },
  {
    base: 'p',
    letters: '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755',
  },
  { base: 'q', letters: '\u0071\u24E0\uFF51\u024B\uA757\uA759' },
  {
    base: 'r',
    letters:
      '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783',
  },
  {
    base: 's',
    letters:
      '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B',
  },
  {
    base: 't',
    letters:
      '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787',
  },
  { base: 'tz', letters: '\uA729' },
  {
    base: 'u',
    letters:
      '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289',
  },
  { base: 'v', letters: '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C' },
  { base: 'vy', letters: '\uA761' },
  {
    base: 'w',
    letters: '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73',
  },
  { base: 'x', letters: '\u0078\u24E7\uFF58\u1E8B\u1E8D' },
  {
    base: 'y',
    letters:
      '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF',
  },
  {
    base: 'z',
    letters: '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763',
  },
];

const diacriticsMap = {};
for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
  const letters = defaultDiacriticsRemovalMap[i].letters;
  for (let j = 0; j < letters.length; j++) {
    diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap[i].base;
  }
}

// "what?" version ... http://jsperf.com/diacritics/12
export const removeDiacritics = (str) => {
  if (!str) {
    return '';
  }

  return str.replace(/[^\u0000-\u007E]/g, function (a) {
    return diacriticsMap[a] || a;
  });
};

export const convertShortName = (str) => {
  let text = String(removeDiacritics(str));
  // import unicodedata
  let final = '';
  for (const char of text) {
    if (!String('()<>:"|?*').includes(char)) if (char.charCodeAt(0) > 31) final += char;
  }
  final = final.normalize('NFKD');
  let output = final;
  // for c in final:
  //   if not unicodedata.combining(c):
  // output += c

  output = output.replace(/\//g, '-');
  output = output.replace(/ /g, '_');
  output = output.replace(/[^\w-_]/g, '');
  return output;
};

export const getIsOrderLocked$ = (givenThis) => {
  console.log('getIsLocked$', givenThis);
  if (!givenThis.order$ || !givenThis.authFacade) {
    return of(false);
  }

  return combineLatest([
    givenThis.order$,
    givenThis.authFacade
      .isAccessAllowed({
        section: permissionSectionMap.ORDER,
        requiredPermission: permissionMap.WRITE,
      })
      .pipe(first()),
  ]).pipe(
    map(([order, isWriteAllowed]: [Order, boolean]) => {
      if (isWriteAllowed && order) {
        return isOrderLocked(order);
      } else {
        return true;
      }
    }),
  );
};

export const getIsOrderLocked = (givenThis) => {
  if (!givenThis.order$ || !givenThis.authFacade) {
    return;
  }

  givenThis.subscription.add(
    combineLatest([
      givenThis.order$,
      givenThis.authFacade
        .isAccessAllowed({
          section: permissionSectionMap.ORDER,
          requiredPermission: permissionMap.WRITE,
        })
        .pipe(first()),
    ]).subscribe(([order, isWriteAllowed]: [Order, boolean]) => {
      if (isWriteAllowed && order) {
        givenThis.isOrderLocked = isOrderLocked(order);
      } else {
        givenThis.isOrderLocked = true;
      }
    }),
  );
};

export const getIsOrderClosed = (givenThis) => {
  if (!givenThis.order$ || !givenThis.authFacade) {
    return;
  }

  givenThis.subscription.add(
    combineLatest([
      givenThis.order$,
      givenThis.authFacade
        .isAccessAllowed({
          section: permissionSectionMap.ORDER,
          requiredPermission: permissionMap.WRITE,
        })
        .pipe(first()),
    ]).subscribe(([order, isWriteAllowed]: [Order, boolean]) => {
      if (isWriteAllowed && order) {
        givenThis.isOrderClosed = isOrderClosed(order);
      } else {
        givenThis.isOrderClosed = true;
      }
    }),
  );
};

export const firstLetterUpperCase = (text: string) => {
  return text[0].toLocaleUpperCase() + text.slice(1);
};

export const isOrderLocked = (order: Order) => {
  return order?.orderStatus !== 1 && order?.orderStatus !== 6;
};

export const isOrderClosed = (order: Order) => {
  return order?.orderStatus > 2 && order?.orderStatus !== 6 && order?.orderStatus !== 7;
};

export const isOrderOpen = (order: Order) => {
  return order?.orderStatus === 1 || order.orderStatus === 6;
};

export const formatNumberWithSpaces = (value: number | string) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const setMinMax = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

export interface OrderFieldApplication extends OrderField {
  volume: number;
  avgVolumePerHa: number;
  waterSum: number;
  waterAverageConsumption: number;
}
export const getOrderFieldApplications = (
  orderFields$: Observable<TZakazkyFields[]>,
  mapStats$: Observable<ApplicationMapStats>,
  order$: Observable<Order>,
): Observable<OrderFieldApplication[]> => {
  return combineLatest([orderFields$, mapStats$, order$]).pipe(
    map(([orderFields, mapStats, order]) => {
      if (
        +order.appType === 3 ||
        +order.appType === 6 ||
        +order.appType === 10 ||
        +order.appType === 12
      ) {
        return orderFields.map((orderField: OrderFieldApplication) => {
          const areaPerFid =
            mapStats?.AREA_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)];
          const volumePerFid =
            mapStats?.VOLUME_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)];
          const volumeNetPerFid =
            mapStats?.VOLUME_NET_PER_FID?.[
              Field.getStatisticsFidNumber(orderField.idtFields2.fid)
            ] || 0;
          const volume = mapStats ? +volumeNetPerFid * 1000 : null;
          const avgVolumePerHa = mapStats ? (volumeNetPerFid / areaPerFid) * 1000 : null;

          let waterSum: number;
          let waterAverageConsumption: number;
          if (+order.pesticidesUnits === 1) {
            waterSum = (+order.baseRate * areaPerFid) / 1000;
            waterAverageConsumption = +order.baseRate;
          } else {
            waterSum = volumePerFid;
            waterAverageConsumption = (volumePerFid / areaPerFid) * 1000;
          }

          return {
            ...orderField,
            volume,
            avgVolumePerHa,
            waterSum,
            waterAverageConsumption,
          };
        });
      } else {
        return orderFields.map((orderField: OrderFieldApplication) => {
          const areaPerFid =
            mapStats?.AREA_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)]; // napoctena vymera celeho pole z jednotlivych polygonu
          const areaPerPlant = +orderField.idtFields2.vymeram; // vymera pole alokovana pro plodinu
          const areaCoef = areaPerFid ? areaPerPlant / areaPerFid : 1; // pomer alokovane ke skutecne vymere pole
          const volumePerFid =
            mapStats?.VOLUME_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)];
          const volume = mapStats ? +volumePerFid * areaCoef : null;
          const avgVolumePerHa = mapStats ? (volume / areaPerPlant) * 1000 : null;
          return {
            ...orderField,
            volume,
            avgVolumePerHa,
            waterSum: volumePerFid,
            waterAverageConsumption: volumePerFid / areaPerFid,
          };
        });
      }
    }),
  );
};

export const isPesticid = (appType: string | number) => {
  return appType.toString() === '3' || appType.toString() === '6' || appType.toString() === '10';
};

export const isSeeding = (appType: string | number) => {
  return appType.toString() === '2' || appType.toString() === '11';
};

export const isFertilization = (appType: string | number) => {
  return (
    appType.toString() === '1' ||
    appType.toString() === '5' ||
    appType.toString() === '7' ||
    appType.toString() === '8'
  );
};

export const isOrderProductFertilizer = (value: string | number | Order) => {
  return [1, 4, 5, 7, 8, 12].includes(+(value['appType'] || value));
};

export const isOrderProductPesticid = (value: string | number | Order) => {
  return [3, 6, 10, 12].includes(+(value['appType'] || value));
};

export const isOrderProductSeed = (value: string | number | Order) => {
  return [2, 11].includes(+(value['appType'] || value));
};

export enum OrderProductCategoryEnum {
  SEED = 'SEED',
  FERTILIZER = 'FERTILIZER',
  PESTICID = 'PESTICID',
}

export const getOrderProductCategory = (value: string | number | Order) => {
  if (isOrderProductFertilizer(value)) {
    return OrderProductCategoryEnum.FERTILIZER;
  } else if (isOrderProductPesticid(value)) {
    return OrderProductCategoryEnum.PESTICID;
  } else if (isOrderProductSeed(value)) {
    return OrderProductCategoryEnum.SEED;
  } else {
    return null;
  }
};

export const getOrderUnit = (order: Order, type: 'sum' | 'average') => {
  if (isOrderProductFertilizer(order)) {
    if (order.liquid && type === 'sum') {
      return 'm3';
    } else if (order.liquid && type === 'average') {
      return 'l/ha';
    } else if (!order.liquid && type === 'sum') {
      return 't';
    } else if (!order.liquid && type === 'average') {
      return 'kg/ha';
    }
  } else if (isOrderProductPesticid(order)) {
    if (type === 'sum') {
      return 'l';
    } else if (type === 'average') {
      return 'l/ha';
    }
  } else if (isOrderProductSeed(order)) {
    if (type === 'sum') {
      return 't';
    } else if (type === 'average') {
      return 'kg/ha';
    }
  } else {
    return null;
  }
};

export enum TankmixProductType {
  P = 'pesticides',
  F = 'fertilizers',
}

export type TankmixProduct = {
  productName: string;
  consumption: number;
};

export const getProductApplicationsTankmix = (
  tankmixProductList$: Observable<TankmixProduct[]>,
  mapStats$: Observable<ApplicationMapStats>,
) => {
  return combineLatest([tankmixProductList$, mapStats$]).pipe(
    map(([productList, mapStats]) => {
      return productList.map(({ productName }) => {
        const areaPerProduct = mapStats?.AREA_PER_PRODUCT[productName];
        const volumePerProduct = mapStats?.VOLUME_PER_PRODUCT[productName];
        const volumeNetPerProduct = (mapStats?.VOLUME_NET_PER_PRODUCT?.[productName] || 0) * 1000;

        const result: any = {};
        result.name = productName;
        result.areaPerProduct = areaPerProduct;
        result.volume = volumeNetPerProduct;
        result.waterSum = volumePerProduct;
        result.averageWaterConsumption = (volumePerProduct / areaPerProduct) * 1000;
        result.avgVolumePerHa = volumeNetPerProduct / areaPerProduct;

        return result;
      });
    }),
  );
};

export const getProductApplications = (
  orderFields$: Observable<TZakazkyFields[]>,
  mapStats$: Observable<ApplicationMapStats>,
  order$: Observable<Order>,
) => {
  return combineLatest([orderFields$, mapStats$, order$]).pipe(
    map(([orderFields, mapStats, order]) => {
      if (+order.appType === 3 || +order.appType === 6 || +order.appType === 10) {
        const productList = orderFields.reduce((resultList, orderField) => {
          if (!resultList.includes(orderField.appProduct)) {
            resultList.push(orderField.appProduct);
          }

          return resultList;
        }, []);

        return productList.map((productName) => {
          const areaPerProduct = mapStats?.AREA_PER_PRODUCT[productName];
          const volumePerProduct = mapStats?.VOLUME_PER_PRODUCT[productName];
          const volumeNetPerProduct = (mapStats?.VOLUME_NET_PER_PRODUCT?.[productName] || 0) * 1000;
          const savingsPerProduct = (mapStats?.SAVINGS_PER_PRODUCT?.[productName] || 50) * 1000;

          const result: any = {};
          result.name = productName;
          result.areaPerProduct = areaPerProduct;
          result.volume = volumeNetPerProduct;
          result.savings = savingsPerProduct;
          if (+order.pesticidesUnits === 1) {
            result.waterSum = (+order.baseRate * areaPerProduct) / 1000;
            result.averageWaterConsumption = +order.baseRate;
          } else {
            result.waterSum = volumePerProduct;
            result.averageWaterConsumption = (volumePerProduct / areaPerProduct) * 1000;
          }

          result.avgVolumePerHa = volumeNetPerProduct / areaPerProduct;

          return result;
        });
      } else {
        const productApplicationsByProductMap = orderFields.reduce(
          (
            productApplicationsByProduct: {
              [appProduct: string]: ProductApplication;
            },
            orderField,
          ) => {
            const areaPerFid =
              mapStats?.AREA_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)]; // napoctena vymera celeho pole z jednotlivych polygonu
            const areaPerPlant = +orderField.idtFields2.vymeram; // vymera pole alokovana pro plodinu
            const areaCoef = areaPerFid ? areaPerPlant / areaPerFid : 1; // pomer alokovane ke skutecne vymere pole
            const volumePerFid =
              mapStats?.VOLUME_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)];
            const volume = mapStats ? +volumePerFid * areaCoef : 0;
            const volumePerProduct = mapStats?.VOLUME_PER_PRODUCT[orderField.appProduct] || 0;
            const areaPerProduct = mapStats?.AREA_PER_PRODUCT[orderField.appProduct] || 0;
            const savingsPerFid =
              mapStats?.SAVINGS_PER_FID[Field.getStatisticsFidNumber(orderField.idtFields2.fid)];

            if (!productApplicationsByProduct[orderField.appProduct]) {
              productApplicationsByProduct[orderField.appProduct] = {
                name: orderField.appProduct,
                elementN: +orderField.percentVolume,
                areaPerFid: 0,
                areaPerPlant: 0,
                volume: 0,
                avgVolumePerHa: 0,
                savings: 0,
              };
            }
            productApplicationsByProduct[orderField.appProduct].areaPerFid += areaPerFid;
            productApplicationsByProduct[orderField.appProduct].areaPerPlant += areaPerPlant;
            productApplicationsByProduct[orderField.appProduct].volume += volume;
            productApplicationsByProduct[orderField.appProduct].avgVolumePerHa =
              (productApplicationsByProduct[orderField.appProduct].volume /
                productApplicationsByProduct[orderField.appProduct].areaPerPlant) *
              1000;
            productApplicationsByProduct[orderField.appProduct].savings += savingsPerFid;

            return productApplicationsByProduct;
          },
          {},
        );

        return Object.keys(productApplicationsByProductMap).map(
          (name) => productApplicationsByProductMap[name],
        );
      }
    }),
  );
};

export const getOrderZoneCount = (order): number => (order.app_5 === null ? 3 : 5);

export const stripUndefined = (value: any) => (!value && value !== 0 ? '' : value);

export const getLimingZoneNameMap = (translateService, sanitizer) => {
  const zoneNameList = [
    'EXTREMNE_KYSELA',
    'SILNE_KYSELA',
    'KYSELA',
    'SLABE_KYSELA',
    'NEUTRALNI',
    'ALKALICKA',
  ];

  return zoneNameList.reduce((acc, zoneName, index) => {
    acc[index + 1] = translateService.get('PLACEHOLDER.FERTILIZATION.' + zoneName).pipe(
      map((name: string) => {
        const splittedName = name.split('');
        const spaceIndexList = splittedName.reduce((acc, value, index) => {
          if (value === ' ') {
            acc.push(index);
          }

          return acc;
        }, []);

        const replaceIndex = spaceIndexList[1] || spaceIndexList[0];
        splittedName.splice(replaceIndex, 1, ` <span style="white-space: nowrap;">`);
        splittedName.push('</span>');
        splittedName.push(' <span>(kg/ha)</span>');
        return sanitizer.bypassSecurityTrustHtml(splittedName.join(''));
      }),
    );

    return acc;
  }, {});
};

export const getSeasonId = (year: number): number => {
  return +year.toString().slice(-2);
};

export const getSeasonOptionList = (
  lastSeasonId: number | string,
  currentSeasonId: string | number,
): seasonOption[] => {
  const currentSeason = '20' + currentSeasonId;
  const seasonOptionList: seasonOption[] = [];

  const seasonCount = +currentSeasonId - +lastSeasonId;
  for (let x = 0; x <= seasonCount; x++) {
    seasonOptionList.push({
      value: +currentSeasonId - x,
      name: `${+currentSeason - x}/${+currentSeason + 1 - x}`,
    });
  }

  return seasonOptionList;
};

export const getMomentSeasonId = (date: moment.Moment, seasonStartMoment: moment.Moment) => {
  seasonStartMoment.set('year', date.year());

  if (date.isBefore(seasonStartMoment, 'day')) {
    return +(date.year() - 1).toString().slice(-2);
  }

  return +date.year().toString().slice(-2);
};

export const removeNaNFromFloatNumberInput = (rawValue) => {
  if (!rawValue) {
    return rawValue;
  }

  const value = rawValue?.toString().trim().replace(/\s/g, '');

  if (value === '-') {
    return 0;
  }

  const parsed = value.match(/^(?<sign>-?)(?<whole>\d+)[,\.](?<fractional>\d*)/);

  if (parsed) {
    const { sign, whole, fractional } = parsed.groups;

    return +`${sign}${whole}.${fractional || 0}`;
  }

  return value;
};

export const getCurrentSeasonIdBySeasonStart = (seasonStartMoment: moment.Moment): number => {
  if (
    moment()
      .set('year', moment().year())
      .isBefore(seasonStartMoment.set('year', moment().year()), 'day')
  ) {
    return +(moment().year() - 1).toString().slice(-2);
  }

  return +moment().year().toString().slice(-2);
};

export const getFieldColor = (field: Field, ds: DataService) => {
  return combineLatest([ds.FieldGroup.entityMap$, ds.Field.entityMap$]).pipe(
    map(([fieldGroupMap, fieldMap]) => {
      return fieldGroupMap[fieldMap[field.idtFields]?.groupId]?.color;
    }),
  );
};


export const getSeasonBoundaries = async (
  seasonStartSource: AuthFacade | Promise<Contract> | string,
  seasonId: string | number,
): Promise<{ seasonStart: string; seasonEnd: string }> => {
  let seasonStartDate$;

  if (typeof seasonStartSource === 'string') {
    seasonStartDate$ = of(seasonStartSource);
  } else if (seasonStartSource instanceof AuthFacade) {
    seasonStartDate$ = seasonStartSource.varistarProfile$.pipe(
      map((varistarProfile) => {
        return varistarProfile?.contract?.seasonStart;
      }),
    );
  } else {
    seasonStartDate$ = from(seasonStartSource).pipe(
      map((contract) => {
        return contract.seasonStart;
      }),
    );
  }

  return seasonStartDate$
    .pipe(
      first((x) => !!x),
      map((seasonStartDate) => {
        const seasonMoment = moment(seasonStartDate).set('year', +`20${seasonId}`);

        const seasonStart = seasonMoment.format('YYYY-MM-DD');
        const seasonEnd = seasonMoment.add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');

        return { seasonStart, seasonEnd };
      }),
    )
    .toPromise();
};

export const getSeasonBoundaries$ = (
  seasonStartSource: AuthFacade | Promise<Contract> | string,
  seasonId: string | number,
): Observable<{ seasonStart: string; seasonEnd: string }> => {
  let seasonStartDate$;

  if (typeof seasonStartSource === 'string') {
    seasonStartDate$ = of(seasonStartSource);
  } else if (seasonStartSource instanceof AuthFacade) {
    seasonStartDate$ = seasonStartSource.varistarProfile$.pipe(
      map((varistarProfile) => {
        return varistarProfile?.contract?.seasonStart;
      }),
    );
  } else {
    seasonStartDate$ = from(seasonStartSource).pipe(
      map((contract) => {
        return contract.seasonStart;
      }),
    );
  }

  return seasonStartDate$.pipe(
    first((x) => !!x),
    map((seasonStartDate) => {
      const seasonMoment = moment(seasonStartDate).set('year', +`20${seasonId}`);

      const seasonStart = seasonMoment.format('YYYY-MM-DD');
      const seasonEnd = seasonMoment.add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');

      return { seasonStart, seasonEnd };
    }),
  );
};

export function getPrePlantingFertilizationZoneNameMap(givenThis, name = 'zoneNameMap') {
  givenThis.translateService
    .get('PLACEHOLDER')
    .pipe(first())
    .subscribe((value) => {
      givenThis[name] = {
        0:
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.VELMI_NIZKY') +
          ' 0-30 (mg/kg)',
        1:
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.NIZKY') +
          ' 30-50 (mg/kg)',
        2:
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.VYHOVUJICI') +
          ' 51-80 (mg/kg)',
        3:
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.DOBRY') +
          ' 80-115 (mg/kg)',
        4:
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.VYSOKY') +
          ' 116-185 (mg/kg)',
        5:
          givenThis.translateService.instant(
            'PLACEHOLDER.PRE_PLANTING_FERTILIZATION.VELMI_VYSOKY',
          ) +
          ' ' +
          givenThis.translateService.instant('PLACEHOLDER.PRE_PLANTING_FERTILIZATION.NAD') +
          ' 185 (mg/kg)',
      };
    });
}

export function getPlantOptionList(
  plants$: Observable<Plant[]>,
  fields$: Observable<Field[] | FieldSelect[] | LpisSkSelect[] | FieldYieldYear[] | any[]>,
  translateService: TranslateService,
  cb: Function,
) {
  return combineLatest([
    fields$,
    plants$.pipe(
      first((plantList: any[]) => !!plantList?.length),
      map((plantList) => {
        return plantList
          .sort((a, b) => {
            return compare(a.nazev, b.nazev, true);
          })
          .map((plant: TPlodinyEagri) => ({
            name: getPlantName(translateService.currentLang, plant),
            value: plant?.idtPlodinyEagri,
          }));
      }),
    ),
  ]).subscribe(([fields, plants]) => {
    const usedPlantCounterMap = {};
    const usedPlantMap = {};
    const fieldsLength = fields.length;
    for (let index = 0; index < fieldsLength; index++) {
      let plantOption;

      if (fields[index].idtFieldsYieldYear) {
        plantOption = {
          name: fields[index].cropName,
          value: fields[index].idCrop,
        };
      } else if (fields[index].idPlodiny2) {
        const plant = fields[index].idPlodiny2;
        plantOption = {
          name: getPlantName(translateService.currentLang, plant),
          value: plant?.idtPlodinyEagri,
        };
      } else {
        //field can be without plant
        continue;
      }

      if (Object.keys(plantOption || {}).length) {
        if (!usedPlantCounterMap[plantOption.value]) {
          usedPlantCounterMap[plantOption.value] = 1;
        } else {
          usedPlantCounterMap[plantOption.value] += 1;
        }

        usedPlantMap[plantOption.name] = plantOption;
      }
    }

    const usedPlantList = Object.entries(usedPlantMap)
      .sort(([nazevA, a], [nazevB, b]) => {
        const difference = <number>b - <number>a;

        if (difference !== 0) {
          return difference;
        }

        return compare(nazevA, nazevB, true);
      })
      .map(([plant]) => usedPlantMap[plant]);

    cb(
      usedPlantList.concat(
        plants.filter(
          (plant) =>
            !usedPlantList.find((usedPlant) => {
              return usedPlant.value === plant.value;
            }),
        ),
      ),
    );
  });
}

export const filterNestedObject = (data, filter) => {
  const filterValue = filter.trim().toLocaleLowerCase();
  if (!filterValue) {
    return true;
  }

  return Object.values(data)
    .filter((value) => !!value)
    .map((value) => {
      if (typeof value === 'object') {
        return Object.values(value);
      }

      return value;
    })
    .some((value) => {
      return value.toString().toLocaleLowerCase().includes(filterValue);
    });
};

export const filterNestedObjectListOperator = (valueList) => {
  // do not rely on variable order
  let filter, objectList;
  if (Array.isArray(valueList[0])) {
    objectList = valueList[0];
    filter = valueList[1];
  } else {
    objectList = valueList[1];
    filter = valueList[0];
  }

  if (!filter) {
    return objectList;
  }

  return objectList.filter((o) => {
    return filterNestedObject(o, filter);
  });
};

export const exportTableToExcel = (fileName: string, sheetMap: object, mergeList = null) => {
  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();

  Object.entries(sheetMap).forEach(([sheetName, sheetDataList]) => {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetDataList);
    if (mergeList?.length) {
      ws['!merges'] = mergeList;
    }

    //sheet name cannot be longer than 31 chars
    XLSX.utils.book_append_sheet(wb, ws, sheetName.slice(0, 31));
  });

  /* save to file */
  XLSX.writeFile(wb, fileName + '.xlsx');
};

export const handlePrint = (pdfDefinition: any) => {
  if (window['printTimer']) {
    return;
  }

  window['printTimer'] = setTimeout(() => {
    window['printTimer'] = null;
  }, 1000);

  pdfMake.createPdf(pdfDefinition).getDataUrl(
    (pdfString: string) => {
      document.getElementById('printIframe').setAttribute('src', pdfString);
    },
    { autoPrint: true },
  );
};

export const permissionSectionMap = {
  ALL: 'ALL',
  ORDER: 'ORDER',
  USER: 'USER',
  ADMIN: 'ADMIN',
  MAP: 'MAP',
  VARISTAR_SOIL: 'VARISTAR_SOIL',
};

export const permissionSectionTabMap = {
  [permissionSectionMap.USER]: {
    ALL: 'ALL',
    INVOICE: 'INVOICE',
    HISTORICAL_YIELD: 'HISTORICAL_YIELD',
    HISTORICAL_YIELD_ANALYSE: 'HISTORICAL_YIELD_ANALYSE',
    ORDER_SUMMARY: 'ORDER_SUMMARY',
    FERTILIZATION_PLANNING: 'FERTILIZATION_PLANNING',
    PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
    REVENUE: 'REVENUE',
  },
  [permissionSectionMap.ADMIN]: {
    ALL: 'ALL',
    HOLDING_MANAGEMENT: 'HOLDING_MANAGEMENT',
    CUSTOMER_MANAGEMENT: 'CUSTOMER_MANAGEMENT',
    DEPARTMENT_MANAGEMENT: 'DEPARTMENT_MANAGEMENT',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
    ONBOARDING: 'ONBOARDING',
    PLANET_API_KEY_MANAGEMENT: 'PLANET_API_KEY_MANAGEMENT',
    TERMINAL: 'TERMINAL',
    TERMINAL_SOIL: 'TERMINAL_SOIL',
    WIFI_SCUL_FW: 'WIFI_SCUL_FW',
    VARISTAR_DIRECT: 'VARISTAR_DIRECT',
    SIM_CARD: 'SIM_CARD',
    POSTGIS_GATEWAY: 'POSTGIS_GATEWAY',
    NEW_FEATURE: 'NEW_FEATURE',
    NEW_FEATURE_CATEGORY: 'NEW_FEATURE_CATEGORY',
    RVP: 'RVP',
  },
};

export const permissionMap = {
  READ: 'READ',
  WRITE: 'WRITE',
};

export const permissionLevelMap = {
  READ: 0,
  WRITE: 1,
};

export const permissionOptionMap = {
  [permissionSectionMap.ALL]: [permissionMap.READ, permissionMap.WRITE],
  [permissionSectionMap.ORDER]: [permissionMap.READ, permissionMap.WRITE],
  [permissionSectionMap.MAP]: [permissionMap.READ, permissionMap.WRITE],
  [permissionSectionMap.VARISTAR_SOIL]: [permissionMap.READ, permissionMap.WRITE],
  [permissionSectionMap.USER]: {
    [permissionSectionTabMap.USER.ALL]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.USER.INVOICE]: [permissionMap.READ],
    [permissionSectionTabMap.USER.HISTORICAL_YIELD]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.USER.HISTORICAL_YIELD_ANALYSE]: [permissionMap.READ],
    [permissionSectionTabMap.USER.ORDER_SUMMARY]: [permissionMap.READ],
    [permissionSectionTabMap.USER.FERTILIZATION_PLANNING]: [
      permissionMap.READ,
      permissionMap.WRITE,
    ],
    [permissionSectionTabMap.USER.PRODUCT_MANAGEMENT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.USER.REVENUE]: [permissionMap.READ, permissionMap.WRITE],
  },
  [permissionSectionMap.ADMIN]: {
    [permissionSectionTabMap.ADMIN.ALL]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.HOLDING_MANAGEMENT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.CUSTOMER_MANAGEMENT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.DEPARTMENT_MANAGEMENT]: [
      permissionMap.READ,
      permissionMap.WRITE,
    ],
    [permissionSectionTabMap.ADMIN.USER_MANAGEMENT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.ROLE_MANAGEMENT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.ONBOARDING]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.PLANET_API_KEY_MANAGEMENT]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.TERMINAL]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.WIFI_SCUL_FW]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.TERMINAL_SOIL]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.VARISTAR_DIRECT]: [permissionMap.READ, permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.SIM_CARD]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.POSTGIS_GATEWAY]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.NEW_FEATURE]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.NEW_FEATURE_CATEGORY]: [permissionMap.WRITE],
    [permissionSectionTabMap.ADMIN.RVP]: [permissionMap.READ, permissionMap.WRITE],
  },
};

export const permissionTranslationMap = {
  ALL: 'PLACEHOLDER.ALL',
  ORDER: 'TITLE.ORDERS_LIST',
  USER: 'TITLE.USER_PORTAL',
  ADMIN: 'TITLE.ADMIN_PORTAL',
  MAP: 'TITLE.MAP_PORTAL',
  VARISTAR_SOIL: 'TITLE.SOIL_PORTAL',
  INVOICE: 'SUBTITLE.INVOICES',
  HISTORICAL_YIELD: 'TITLE.HISTORICAL_YIELDS',
  HISTORICAL_YIELD_ANALYSE: 'TITLE.HISTORICAL_YIELDS_ANALYSE',
  ORDER_SUMMARY: 'PLACEHOLDER.ORDERS_SUMMARY',
  FERTILIZATION_PLANNING: 'PLACEHOLDER.FERTILIZATION_PLANNING.NAME',
  PRODUCT_MANAGEMENT: 'PLACEHOLDER.PRODUCT_MANAGEMENT',
  ROLE_MANAGEMENT: 'TITLE.ROLE_MANAGEMENT',
  PLANET_API_KEY_MANAGEMENT: 'TITLE.PLANET_API_KEY_MANAGEMENT',
  REVENUE: 'PLACEHOLDER.REVENUES',
  HOLDING_MANAGEMENT: 'TITLE.HOLDING_MANAGEMENT',
  CUSTOMER_MANAGEMENT: 'TITLE.CUSTOMER_MANAGEMENT',
  DEPARTMENT_MANAGEMENT: 'TITLE.DEPARTMENT_MANAGEMENT',
  USER_MANAGEMENT: 'TITLE.USER_MANAGEMENT',
  ONBOARDING: 'TITLE.ONBOARDING.PREFIX',
  TERMINAL: 'TITLE.TERMINALS',
  WIFI_SCUL_FW: 'TITLE.WIFI_SCUL_FW',
  TERMINAL_SOIL: 'TITLE.TERMINAL_SOIL',
  VARISTAR_DIRECT: 'TITLE.TERMINAL_DIRECT',
  SIM_CARD: 'TITLE.SIM_CARDS',
  POSTGIS_GATEWAY: 'TITLE.POSTGIS_GATEWAY',
  NEW_FEATURE: 'PLACEHOLDER.NEW_FEATURES',
  NEW_FEATURE_CATEGORY: 'PLACEHOLDER.NEW_FEATURE_CATEGORIES',
  RVP: 'PLACEHOLDER.RVP.RVP',
};

export const getAllowedTabRouteMap = async (
  allTabMap,
  canAccessTab: (tab: string) => Promise<boolean>,
) => {
  return Promise.all(
    Object.keys(allTabMap).map((tab) => {
      return canAccessTab(tab).then((canAccessResult: boolean) => {
        return canAccessResult ? tab : null;
      });
    }),
  ).then((resultList) => {
    return resultList
      .filter((tab) => !!tab)
      .reduce((acc, tab) => {
        acc[tab] = allTabMap[tab];
        return acc;
      }, {});
  });
};

export const setupTabRouting = (givenThis, tabRouteMap) => {
  const getFeatureFromPath = (path: string) => {
    return path.match(/\/[^\/]*\/[^\/]*\/[^\/]*\/[^\/]*\/([^\/\?]*)/)?.[1] || '';
  };

  const routeMap = Object.values(tabRouteMap).reduce((acc, route: string, index) => {
    acc[route] = index;
    acc[index] = route;
    return acc;
  }, {});

  const currentFeature = getFeatureFromPath(givenThis.location.path());

  givenThis.basePath = currentFeature
    ? givenThis.location.path().slice(0, givenThis.location.path().indexOf(currentFeature))
    : givenThis.location.path();

  if (currentFeature) {
    givenThis.tabIndex$.next(routeMap[currentFeature] || routeMap[0]);
  } else {
    givenThis.router.navigateByUrl(givenThis.basePath + '/' + routeMap[0]);
  }

  givenThis.subscription.add(
    givenThis.tabIndex$.pipe(skip(1), distinctUntilChanged()).subscribe((tabIndex) => {
      if (routeMap[tabIndex] !== getFeatureFromPath(givenThis.location.path())) {
        const lastChar = givenThis.basePath.substr(givenThis.basePath.length - 1);
        givenThis.router.navigateByUrl(
          givenThis.basePath + (lastChar === '/' ? '' : '/') + routeMap[tabIndex],
        );
      }
    }),
  );

  givenThis.subscription.add(
    givenThis.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          return getFeatureFromPath(givenThis.location.path());
        }),
      )
      .subscribe((feature) => {
        if (feature) {
          givenThis.tabIndex$.next(routeMap[feature]);
        }
      }),
  );
};

export const isAllowed = ({
  permissionMap,
  isSuperAdmin,
  customerId,
  departmentId,
  section,
  tab,
  requiredPermission,
}: {
  permissionMap: object;
  isSuperAdmin: boolean;
  customerId: string | number;
  departmentId: string | number;
  section: string;
  tab: string;
  requiredPermission: string;
}) => {
  // NOTE: overide permissions by isSuperAdmin (t_user.super_admin)
  if (isSuperAdmin) {
    return true;
  }

  let permissionList = permissionMap['ALL']
    .concat(permissionMap[customerId]?.['ALL'] || [])
    .concat(permissionMap[customerId]?.[departmentId] || []);

  return permissionList.some((permission) => {
    return (
      section === permission.section &&
      (!tab || permission.tab === 'ALL' || tab === permission.tab) &&
      permissionLevelMap[requiredPermission] <= permissionLevelMap[permission.permission]
    );
  });
};
export enum CountryCodeEnum {
  SK = 'SK',
  EN = 'EN',
  CZ = 'CZ',
}

export const isTerminalTypeVaristarOne = (terminalyType) => {
  return +terminalyType === 2;
};


export const tabletAdapterMap = {
  'A-Female': 'A-Female',
  'A-Male': 'A-Male',
  L: 'L',
  V: 'V',
  A: 'A',
  SCUL: 'SCUL',
  WIFI: 'WIFI-připojení',
};

export const terminalTypeMap = {
  CLASSIC: 'CLASSIC',
  VARISTAR_ONE: 'VARISTAR_ONE',
};

export const ACTIVATION_MAX_DAYS = 90;

export const getOrderProductType = (order: Order) => {
  if (isOrderProductFertilizer(order)) {
    return order.appCustomProductId ? ProductTypeEnum.CF : ProductTypeEnum.BF;
  }

  if (isOrderProductPesticid(order)) {
    return order.appCustomProductId ? ProductTypeEnum.CP : ProductTypeEnum.BP;
  }

  return order.appCustomProductId ? ProductTypeEnum.CS : ProductTypeEnum.BS;
};

export const getProductUid = (idProduct: string | number, productType: ProductTypeEnum) => {
  return productType + '_' + idProduct;
};

export const isoDateFormat = 'YYYY-MM-DD';

export const orderProductCategoryTranslationMap = {
  [OrderProductCategoryEnum.FERTILIZER]: 'PLACEHOLDER.FERTILIZER_PLURAL',
  [OrderProductCategoryEnum.PESTICID]: 'PLACEHOLDER.PESTICID_PLURAL',
  [OrderProductCategoryEnum.SEED]: 'PLACEHOLDER.SEED_PLURAL',
};

export const sortBySearch = (listToSort: any[], sortColumn: string, searchValue: string) => {
  return listToSort
    .sort((a, b) => compare(a[sortColumn], b[sortColumn], true))
    .sort((a, b) => {
      const positionA = a[sortColumn].toLowerCase().indexOf(searchValue);
      const positionB = b[sortColumn].toLowerCase().indexOf(searchValue);

      if (positionA === positionB) {
        return 0;
      } else if (positionA < 0 && positionB >= 0) {
        return 1;
      } else if (positionB < 0 && positionA >= 0) {
        return -1;
      }

      return positionA - positionB;
    });
};

export const roundSeeding = (value: string | number) => {
  return _.round(+value, +value <= 30 ? 1 : 0);
};

export enum FertilizationControlElementEnum {
  P = 'P',
  K = 'K',
}

export const schemaTranslateCountMap = {
  CA: 5,
  DP: 3,
  MG: 5,
  HP: 4,
  S: 6,
  OH: 0,
  SAMPLSTAT: 0,
  K: 5,
  P: 6,
  PH: 7,
  SAMPLPOINTS: 0,
};

export interface SeedType {
  name: string;
  idType: number;
}

export const FIELD_YIELD_YEAR_DB_NAME = (idSmlouvy: number) => 'FieldYieldYear' + '_' + idSmlouvy;
export const FIELD_YIELD_YEAR_ENTITIES_STORE_NAME = 'entities';

export const showOrderListTooltip = (
  order: Order,
  tooltips: QueryList<MatTooltip>,
  hide = false,
) => {
  if (!tooltips?.length) return;

  // hide other tooltips before show specific one
  tooltips.forEach((matTooltip: MatTooltip) => {
    if (matTooltip._isTooltipVisible()) matTooltip.hide();
  });

  const selectedTooltip: MatTooltip = tooltips.find(
    (tooltip: MatTooltip) => order.idZakazky.toString() === tooltip.tooltipClass,
  );
  if (hide) {
    selectedTooltip.hide();
  } else {
    selectedTooltip.show();
  }
};

// Define a polyfill for flatMap for TypeScript versions < 3.9
export const flatMap = <T, U>(
  array: T[],
  callback: (value: T, index: number, array: T[]) => U[],
): U[] => {
  if (array == null) {
    throw new TypeError('flatMap called on null or undefined');
  }

  const arr = Object(array);
  // tslint:disable-next-line:no-bitwise
  const len = arr.length >>> 0;
  if (typeof callback !== 'function') {
    throw new TypeError(callback + ' is not a function');
  }

  const flattenedArray: U[] = [];
  for (let i = 0; i < len; i++) {
    const mappedValue = callback.call(null, arr[i], i, arr);
    if (Array.isArray(mappedValue)) {
      flattenedArray.push(...mappedValue);
    } else {
      flattenedArray.push(mappedValue);
    }
  }

  return flattenedArray;
};

/**
 * Recalculate jedinci/ha to kg/ha and vice versa
 * @param htz
 * @param rate
 */
export function recalculateUnits(htz, rate, countToKg = true): number {
  if (countToKg) {
    return +((htz / 1000) * (rate / 1000)).toFixed(2);
  }
  return +((rate * 1000 * 1000) / htz).toFixed(2);
}

export function calculateValueByBonitation(
  base,
  bonKoef,
  volume,
  rate,
  mode: 'KG' | 'UNITS' = 'UNITS',
) {
  let result = 0;
  if (+rate < 0) {
    result = (+base * (1 - (+bonKoef - 1))) / volume;
  } else {
    result = (+base * +bonKoef) / volume;
  }
  if (mode === 'KG') {
    result = result / 1000000;
  }

  return result;
}
