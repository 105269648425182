import { TRole } from './entities/TRole';

export interface PermissionSettings {
  section: string;
  tab: string | null;
  permission: 'READ' | 'WRITE';
}
export interface Role extends TRole {}

export const Role = {
  selectId: (a: Role) => {
    return a.idRole;
  },
};
