<mat-form-field class="container">
  <mat-select
    [value]="selectedId$ | async"
    (valueChange)="change.emit($event)"
    [placeholder]="name | translate"
    [attr.cy-select]="cyAttribute"
  >
    <mat-option
      *ngFor="let option of optionList$ | async"
      [value]="option.value"
      [attr.cy-select-option]="cyAttribute"
    >
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
