import { TSmlouvy } from './entities/TSmlouvy';
import { AssetType, MapClip } from './api-varistar.model';

// tslint:disable-next-line: no-empty-interface
export interface Contract extends TSmlouvy {
  mena: string;
}

export const Contract = {
  selectId: (a: Contract) => {
    return a.idSmlouvy;
  },
  // getItems: (data: Contract[] = []) => {
  //   return data.map(annex => ({ name: `${moment(annex.platnyOd).format('D.M.YYYY')} - ${moment(annex.platnyOd).add(annex.delkaTrvani, 'year').format('D.M.YYYY')} #${annex.cisloDodatku} (${annex.idSmlouvy})`, value: annex.idSmlouvy }));
  //   // return data.map(annex => ({ name: `#${annex.cisloDodatku} : ${moment(annex.platnyOd).format('D.M.YYYY')} - ${moment(annex.platnyOd).add(annex.delkaTrvani, 'year').format('D.M.YYYY')} (${annex.idSmlouvy} : ${annex.idSmlouvy})`, value: annex.idSmlouvy }));
  // },
};

export interface ContractSetRypMapSensitivityRequest {
  contractId: number;
  fids: string[];
  rypMapSensitivity: number;
}

export interface SetRypMapSensitivityToFidRequest {
  fid: string;
  rypMapSensitivity: number;
}

export interface SetRypBufferToFidRequest {
  fid: string;
  rypBuffer: number;
}

export interface SetRypBufferByAnnexRequest {
  annexId: number;
  rypBuffer: number;
}
