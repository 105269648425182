import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

// import { TTabletMachines } from "./TTabletMachines";
// import { TTabletProtocol } from "./TTabletProtocol";

@Index('protocol', ['idTTabletProtocol'], {})
@Entity('t_tablet_protocol_speed', { schema: 'VaristarIS' })
export class TTabletProtocolSpeed {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_tablet_protocol_speed' })
  idTabletProtocolSpeed: number;

  @Column('int', { name: 'id_t_tablet_protocol', nullable: true })
  idTTabletProtocol: number | null;

  @Column('int', { name: 'baud_rate', nullable: true })
  baudRate: number | null;

  // @OneToMany(
  //   () => TTabletMachines,
  //   (tTabletMachines) => tTabletMachines.mProtocolSpeed2
  // )
  // tTabletMachines: TTabletMachines[];

  // @OneToOne(
  //   () => TTabletProtocol,
  //   (tTabletProtocol) => tTabletProtocol.idTabletProtokol2
  // )
  // tTabletProtocol: TTabletProtocol;
}
