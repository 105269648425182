export interface Pesticides {
  idPesticid: number;
  name: string;
  type: string;
  substance: string;
  substanceContent: string; //value + unit
  substanceGroup: string;
  substanceList?: {
    substance: string;
    substanceContent: string; //value + unit
    substanceGroup: string;
  }[];
  documentUrlList?: string[];
  amountList: { plantName: string; amount: string }[];
  deactivated?: number;
}

export const Pesticides = {
  selectId: (p: Pesticides): number => {
    return p?.idPesticid;
  },
};
