import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// CDK
import { LayoutModule } from '@angular/cdk/layout';
import { CdkTableModule } from '@angular/cdk/table';
import { PlatformModule } from '@angular/cdk/platform'; // ?
import { ObserversModule } from '@angular/cdk/observers'; // ?
// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  // Material 6.x
  MatBadgeModule,
} from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomDateAdapter } from './custom-date-adapter/custom-date-adapter';
import { TranslatedPaginator } from './translated-paginator/translated-paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { SpacedInputDirective } from './spaced-input/spaced-input.directive';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [SpacedInputDirective],
  imports: [
    CommonModule,
    // BrowserAnimationsModule, // Musi se includovat uz v app.module.ts protoze lazy load modules to nepovoli znovupouzit pri include tohoto modulu
  ],
  exports: [
    //  BrowserAnimationsModule,

    LayoutModule,
    CdkTableModule,
    ObserversModule,
    PlatformModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTreeModule,
    DragDropModule,
    // Material 6.x
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    ScrollingModule,
    SpacedInputDirective,
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useClass: TranslatedPaginator },
  ],
})
export class FrontendUiMaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    // this.dateAdapter.setLocale('cs-CZ');

    const svgIcons = [
      'dashboard-black-24-dp',
      'fact-check-black-24-dp',
      'map-black-24-dp',
      'oldportal-black-24-dp',
      'place-black-24-dp',
      'settings-black-24-dp',
      'travel-explore-black-24-dp',

      'hnojeni_mapa_satelit',
      'hnojeni_satelit',
      'hnojeni_mapa',
      'home_plny',
      'mapa_plna',
      'obili_klasy',
      'ochrana_mapa',
      'ochrana_satelit',
      'satelit_plny',
      'seti_mapa',
      'vapneni_mapa',
      'rozmetadlo_granule',
      'postrikovac_kapky',
      'secka_semena',
      'john_deere_final',
      'monitor_white_24dp',
      'expand_more_black_24dp',
      'mapa_s_polohou',
      'zasobni_mapa',
      'hnojeni_uniformni',
      'seti_uniformni',
      'ochrana_uniformni',
      'tankmix',
      'tractor',

      'risk_low',
      'risk_middle',
      'risk_high',
      'risk_extreme',

      'drop',
      'moon',
      'rain',
      'wind_orientation',

      //new weather icons
      'broken_clouds',
      'clear_sky_day',
      'clear_sky_night',
      'few_clouds_day',
      'few_clouds_night',
      'mist',
      'rain_day',
      'rain_night',
      'scattered_clouds',
      'shower_rain',
      'snow',
      'precipitation',
      'temperature_max',
      'temperature_min',
      'temperature_night',
      'temperature_feeling',
      'thunderstorm',
      'pressure',
      'wind',
      'humidity',
    ];
    svgIcons.forEach((svgIcon) => {
      this.matIconRegistry.addSvgIcon(
        svgIcon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${svgIcon}.svg`),
      );
    });
  }
}
