import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '@varistar-apps/shared/data';
import { compare } from '@varistar-apps/shared/utilities';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';

export interface SelectOption {
  name: string;
  value: string | number;
  notActive?: boolean;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() optionList$: Observable<SelectOption[]>;
  @Input('selectedId$') set setSelectedOption$(selectedOption$: Observable<string>) {
    this.subscription.add(
      combineLatest([this.optionList$, selectedOption$.pipe(distinctUntilChanged())]).subscribe(
        ([optionList, selectedOption]) => {
          const foundOption = optionList.find(
            (option) => option.value?.toString() === selectedOption?.toString(),
          );
          if (foundOption) {
            const name = foundOption.name;
            if (name) {
              this.filterValue$.next(
                this.translateService.instant(name) +
                  (foundOption.notActive
                    ? ' (' + this.translateService.instant('PLACEHOLDER.NOT_ACTIVE') + ')'
                    : ''),
              );
            } else {
              this.filterValue$.next('');
            }
          } else {
            this.filterValue$.next('');
          }
        },
      ),
    );
  }
  @Input('defaultValue') set setDefaultValue(defaultValue: string) {
    if (!this.filterValue$.value && defaultValue) {
      this.filterValue$.next(defaultValue);
    }
  }
  @Input() label: string;
  @Input() width: number;
  @Input('disabled') isDisabled: boolean;
  @Input() required: boolean;
  @Output() filterChange = new EventEmitter();
  @Output() optionSelect = new EventEmitter();

  private subscription = new Subscription();
  filterValue$ = new BehaviorSubject<string>('');
  optionListFiltered$: Observable<SelectOption[]>;
  selectForm = new FormGroup({
    selectFormControl: new FormControl(null, { updateOn: 'change' }),
  });
  optionList = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (this.required) {
      this.selectForm.get('selectFormControl').setValidators(Validators.required);
    }

    this.selectForm.get('selectFormControl').markAsTouched();
    this.selectForm.markAsDirty();

    this.optionListFiltered$ = combineLatest([this.optionList$, this.filterValue$]).pipe(
      map(([optionList, filterValue]) => {
        if (!filterValue) {
          return optionList.slice().sort((a, b) => compare(a.name?.trim(), b.name?.trim(), true));
        }

        return optionList
          .filter((option) => option.name?.trim().toLowerCase().includes(filterValue.toLowerCase()))
          .sort((a, b) => compare(a.name?.trim(), b.name?.trim(), true));
      }),
    );

    this.subscription.add(
      this.optionList$.subscribe((optionList) => {
        this.optionList = optionList;
      }),
    );

    this.subscription.add(
      this.filterValue$.subscribe((filterValue) => {
        this.selectForm.get('selectFormControl').setValue(filterValue);
        this.filterChange.emit(filterValue);
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.isDisabled?.previousValue !== !!changes.isDisabled?.currentValue) {
      this.selectForm
        .get('selectFormControl')
        [changes.isDisabled.currentValue ? 'disable' : 'enable']();
    }
  }

  handleFilterChange = (event) => {
    const newFilterValue = event.target.value;

    if (!newFilterValue) {
      this.optionSelect.emit(null);
      this.filterValue$.next('');
    } else {
      this.filterValue$.next(event.target.value);
    }
  };

  handleOptionSelect = (event) => {
    const { value } = event.option;
    this.filterValue$.next(
      this.optionList.find((option) => option.value?.toString() === value?.toString()).name,
    );

    this.optionSelect.emit(value);
  };

  handleClearFilter = (event) => {
    event.stopPropagation();
    this.optionSelect.emit(null);
    this.filterValue$.next('');
  };
}
