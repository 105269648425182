import { Column, Entity, Index, JoinColumn, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { THoldingOsoby } from './THoldingOsoby';
import { TUser } from './TUser';
import { TVerze } from './TVerze';
import { TZakazky } from './TZakazky';
import { TZakaznikOsoby } from './TZakaznikOsoby';

@Index('id_osoby_UNIQUE', ['idOsoby'], { unique: true })
@Index('jmeno', ['jmeno'], {})
@Index('prijmeni', ['prijmeni'], {})
@Index('firma', ['firma'], {})
@Index('email', ['emailOsoby'], {})
@Entity('t_osoby', { schema: 'VaristarIS' })
export class TOsoby {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_osoby' })
  idOsoby: number;

  @Column('varchar', { name: 'titul_pred', nullable: true, length: 45 })
  titulPred: string | null;

  @Column('varchar', { name: 'jmeno', nullable: true, length: 100 })
  jmeno: string | null;

  @Column('varchar', { name: 'prijmeni', nullable: true, length: 100 })
  prijmeni: string | null;

  @Column('varchar', { name: 'titul_za', nullable: true, length: 45 })
  titulZa: string | null;

  @Column('varchar', { name: 'firma', nullable: true, length: 255 })
  firma: string | null;

  @Column('varchar', { name: 'funkce', nullable: true, length: 255 })
  funkce: string | null;

  @Column('varchar', { name: 'email_osoby', nullable: true, length: 100 })
  emailOsoby: string | null;

  @Column('varchar', { name: 'mobilni_osoby', nullable: true, length: 20 })
  mobilniOsoby: string | null;

  @Column('varchar', { name: 'telefon_osoby', nullable: true, length: 20 })
  telefonOsoby: string | null;

  @Column('varchar', { name: 'ulice_osoby', nullable: true, length: 255 })
  uliceOsoby: string | null;

  @Column('varchar', { name: 'mesto_osoby', nullable: true, length: 255 })
  mestoOsoby: string | null;

  @Column('varchar', { name: 'psc_osoby', nullable: true, length: 10 })
  pscOsoby: string | null;

  @Column('double', {
    name: 'hs_osoba_id',
    nullable: true,
    comment: 'Slouží k propojení osoby s CRM HubSpot',
    precision: 22,
  })
  hsOsobaId: number | null;

  @Column('datetime', {
    name: 'last_update_osoba',
    nullable: true,
    comment: 'Datum poslední změny záznamu osoby',
  })
  lastUpdateOsoba: Date | null;

  @Column('varchar', {
    name: 'osloveni',
    nullable: true,
    comment: 'Oslovení zákazníka do automaticky generovaných textů.',
    length: 105,
  })
  osloveni: string | null;

  @Column('varchar', { name: 'zeme_osoby', nullable: true, length: 45 })
  zemeOsoby: string | null;

  @OneToMany('THoldingOsoby', 'idtOsoby2')
  tHoldingOsobies: THoldingOsoby[];

  @OneToMany('TUser', 'idtOsoby2')
  @JoinColumn([{ name: 'id_osoby', referencedColumnName: 'idtOsoby' }])
  tUsers: TUser[];

  @OneToMany('TVerze', 'idKontaktNabidka2')
  tVerzes: TVerze[];

  @OneToMany('TZakazky', 'createdUser2')
  tZakazkies: TZakazky[];

  @OneToMany('TZakaznikOsoby', 'idtOsoby2', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_osoby', referencedColumnName: 'idtOsoby' }])
  tZakaznikOsobies: TZakaznikOsoby[];
}
