import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '@varistar-apps/frontend/ui-feature';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-dropdown-select-dialog',
  templateUrl: './dropdown-select-dialog.component.html',
  styleUrls: ['./dropdown-select-dialog.component.scss'],
})
export class DropdownSelectDialogComponent {
  selectedId$ = new BehaviorSubject(null);
  name: string;
  width: number;

  constructor(
    public ds: DataService,
    public dialogRef: MatDialogRef<DropdownSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      optionList$: Observable<{ name: string; value: number }[]>;
      name: string;
      width: number;
    },
  ) {
    this.name = data.name;
    this.width = data.width;
  }

  handleSelectOption = (newValue) => {
    this.selectedId$.next(newValue);
  };

  handleSave = async () => {
    this.dialogRef.close(this.selectedId$.value);
  };

  handleCloseDialog = () => {
    this.dialogRef.close();
  };
}
