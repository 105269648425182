import { Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { TNabidky } from './TNabidky';

import { TZakaznikOsoby } from './TZakaznikOsoby';

// import { TNabidky } from "./TNabidky";
// import { THolding } from "./THolding";
@Index('holding', ['idTHolding'], {})
@Index('id_zakaznik_UNIQUE', ['idZakaznik'], { unique: true })
@Index('name', ['nazev'], {})
@Entity('t_zakaznik', { schema: 'VaristarIS' })
export class TZakaznik {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_zakaznik' })
  idZakaznik: number;

  @Column('varchar', {
    name: 'nazev',
    nullable: true,
    comment: 'Název farmy, jakožto zákazníka. ',
    length: 255,
  })
  nazev: string | null;

  @Column('varchar', {
    name: 'ic',
    nullable: true,
    comment: 'IČO zákazníka',
    length: 14,
  })
  ic: string | null;

  @Column('varchar', {
    name: 'dic',
    nullable: true,
    comment: 'DIČ zákazníka',
    length: 16,
  })
  dic: string | null;

  @Column('varchar', {
    name: 'or_ulice',
    nullable: true,
    comment: 'Ulice uvedená u subjektu v obchodním rejstříku. ',
    length: 255,
  })
  orUlice: string | null;

  @Column('varchar', {
    name: 'or_mesto',
    nullable: true,
    comment: 'Město uvedené u subjektu v obchodním rejstříku. ',
    length: 255,
  })
  orMesto: string | null;

  @Column('varchar', {
    name: 'or_psc',
    nullable: true,
    comment: 'PSČ uvedená u subjektu v obchodním rejstříku. ',
    length: 10,
  })
  orPsc: string | null;

  @Column('varchar', {
    name: 'or_zapis',
    nullable: true,
    comment: 'Kde byl proveden zápis subjektu do obchodního rejstříku. ',
    length: 255,
  })
  orZapis: string | null;

  @Column('varchar', {
    name: 'koresp_ulice',
    nullable: true,
    comment: 'Korespondeční adresa -  ulice.',
    length: 255,
  })
  korespUlice: string | null;

  @Column('varchar', {
    name: 'koresp_mesto',
    nullable: true,
    comment: 'Korespondeční adresa -  město.',
    length: 255,
  })
  korespMesto: string | null;

  @Column('varchar', {
    name: 'koresp_psc',
    nullable: true,
    comment: 'Korespondeční adresa -  psč',
    length: 10,
  })
  korespPsc: string | null;

  @Column('double', {
    name: 'hs_firma_id',
    nullable: true,
    comment: 'ID firmy pro využiní v CRM systému HubSpot.',
    precision: 22,
  })
  hsFirmaId: number | null;

  @Column('datetime', {
    name: 'last_update_firma',
    nullable: true,
    comment: 'Datum poslední změny zákazníka.',
  })
  lastUpdateFirma: Date | null;

  @Column('varchar', { name: 'zeme', nullable: true, length: 45 })
  zeme: string | null;

  @Column('varchar', { name: 'koresp_zeme', nullable: true, length: 45 })
  korespZeme: string | null;

  @Column('int', {
    name: 'id_t_holding',
    nullable: true,
    comment: 'Cizí klíč pro tabulku t_holding - přidává zákazníka do holdingu. ',
  })
  idTHolding: number | null;

  // @OneToMany(() => TNabidky, (tNabidky) => tNabidky.idZakaznik2)
  @OneToMany('TNabidky', 'idZakaznik2')
  tNabidkies: TNabidky[];

  // @ManyToOne(() => THolding, (tHolding) => tHolding.tZakazniks, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_t_holding", referencedColumnName: "idtHolding" }])
  // idTHolding2: THolding;

  // @OneToMany(
  //   () => TZakaznikOsoby,
  //   (tZakaznikOsoby) => tZakaznikOsoby.idtZakaznik2
  // )
  @OneToMany('TZakaznikOsoby', 'idtZakaznik2')
  tZakaznikOsobies: TZakaznikOsoby[];
}
