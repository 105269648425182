import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_seed_custom', { schema: 'VaristarIS' })
export class TSeedCustom {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_seed_custom' })
  idSeedCustom: number;

  @Column('varchar', { name: 'name', length: 255 })
  name: string;

  @Column('int', { name: 'id_type' })
  idType: number;

  @Column('int', {
    name: 'created_user',
    comment: 'Párovací klíč do tabulky t_osoby - id_osoby.',
  })
  createdUser: number;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    comment: 'Indikuje, informace o hnojivu byla editovana',
    width: 1,
    default: () => "'0'",
  })
  deactivated: boolean | null;
}
