import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeFilterAttributes',
})
export class PipeFilterAttributesPipe implements PipeTransform {
  transform(values: any[], filter?: [string[], string]): any {
    const attribites = filter[0] || [];
    const value = filter[1] || '';
    return !values || !values.filter
      ? []
      : values.filter((v) => {
          // TODO: pres vsechny attributes
          return attribites.reduce((result, attr) => {
            if (
              v &&
              v[attr] &&
              String(v[attr]).toLowerCase().includes(String(value).toLowerCase())
            ) {
              return true;
            } else {
              return result;
            }
          }, false);
          return true; //(v.key && v.key.toLowerCase().includes(filter)) || (v.value && v.value.toString().includes(filter));
        });
  }
}
