import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcTextarea<T> extends DynamicFormControlBase<T> {
  controlType = 'textarea';
  minRows: number;
  maxRows: number;

  constructor(options: {} = {}) {
    super(options);
    this.minRows = +(options['minRows'] || 3);
    this.maxRows = +(options['maxRows'] || 5);
  }
}
