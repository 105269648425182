import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { TranslateService } from '@ngx-translate/core';
import { getPlantName, TPlodinyEagri } from '@varistar-apps/shared/data';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  ignoreElements,
  map,
  tap,
} from 'rxjs/operators';

interface PlantOption {
  name: string;
  value: any;
}

@Component({
  selector: 'ui-crop-select',
  templateUrl: './crop-select.component.html',
  styleUrls: ['./crop-select.component.scss'],
})
export class CropSelectComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  private subscription = new Subscription();
  isSelectActive: boolean;
  plantListFiltered$: Observable<PlantOption[]>;
  plantFormControl = new FormControl('', Validators.required);
  plantFilterValue$ = new BehaviorSubject('');
  selectedPlantName: string;
  closeTimeout = null;

  @ViewChild('clearButton') clearButton: MatButton;
  @Input('isSelectActive') set setIsSelectActive(isActive) {
    this.isSelectActive = isActive;
  }
  @Input() focus = true;
  @Input() plantList$: Observable<PlantOption[]>;
  @Input() set crop(crop: TPlodinyEagri | { name: string; value: number } | any) {
    if (!crop) return;
    if (crop.hasOwnProperty('idtPlodinyEagri')) {
      const cropName = getPlantName(this.translateService.currentLang, crop);
      this.selectedPlantName = cropName;
      this.plantFormControl.setValue(cropName);
      this.plantFilterValue$.next(cropName);
    } else {
      this.selectedPlantName = crop.name;
      this.plantFormControl.setValue(crop.name);
      this.plantFilterValue$.next(crop.name);
    }
  }
  @Input() plantSelectCb: Function;
  @Input() optionListDelay: number | undefined;

  plantListCurrent: PlantOption[];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.plantFormControl.markAsTouched();
    this.plantFormControl.markAsDirty();

    this.plantListFiltered$ = combineLatest([
      this.plantList$.pipe(first((p: PlantOption[]) => !!p?.length)),
      this.plantFilterValue$.pipe(
        map((value) => (value && value?.toLowerCase ? value?.toLowerCase() : '')),
      ),
    ]).pipe(
      map(([plantList, filterValue]) => {
        return plantList.filter((plant) => plant.name?.toLowerCase().includes(filterValue));
      }),
      map((plantOptions) => plantOptions.filter((plant) => plant?.value !== undefined)), // v seznamu plodin se nezobrazuje prazdna polozka, ale jen Bez plodiny
      distinctUntilKeyChanged('length'),
    );

    this.subscription.add(
      this.plantFormControl.valueChanges
        .pipe(map((value) => (value?.name || value?.value ? value?.value : value)))
        .subscribe((value) => {
          this.plantFilterValue$.next(value);
        }),
    );

    this.subscription.add(
      combineLatest([
        this.plantListFiltered$.pipe(filter((plantList) => !plantList.length)),
        this.plantFilterValue$.pipe(
          filter((filterValue) => !!filterValue),
          distinctUntilChanged(),
        ),
      ]).subscribe(() => {
        this.plantFormControl.setErrors({ notExists: true });
      }),
    );

    this.subscription.add(
      this.plantList$.subscribe((plantList: PlantOption[]) => {
        this.plantListCurrent = plantList;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleInputClick = () => {
    this.plantFormControl.setValue(this.plantFormControl.value);
    this.autocomplete.openPanel();
  };

  handleBlur = () => {
    this.closeTimeout = setTimeout(() => {
      this.autocomplete?.closePanel();
    }, 200);
  };

  handleOptionSelect = (event) => {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }
    const { value: selectedOption } = event.option;

    this.plantFormControl.setValue(selectedOption.name);
    this.plantSelectCb(selectedOption);
  };

  handleClearPlantFilter = () => {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
    }

    if (document.activeElement === this.clearButton._elementRef.nativeElement) {
      this.plantFormControl.setValue('');
    }

    //Set selected plant to 'Bez plodiny' / 'No crop'
    const cropNoCrop = this.plantListCurrent.find((plant) => plant.value === 0);
    this.selectedPlantName = cropNoCrop.name ?? 'Bez plodiny';
    this.plantSelectCb(0); // Crop with id 0 is 'Bez plodiny' / 'No crop'
  };
}
