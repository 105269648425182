import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

interface SelectOption {
  name: string;
  value: string;
}

@Component({
  selector: 'ui-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
})
export class SimpleSelectComponent implements OnInit, OnDestroy {
  @Input() optionList$: Observable<SelectOption[]>;
  @Input() selectedId$: Observable<SelectOption>;
  @Input() name: string;
  @Input() cyAttribute: string;
  @Output() change = new EventEmitter();

  subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
