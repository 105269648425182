<form *ngIf="isSelectActive; else plant">
  <mat-form-field class="plantSelectFormField" fxFlex>
    <mat-label>
      {{ 'PLACEHOLDER.PLANT.TYPE_NAME' | translate }}
    </mat-label>
    <input
      #plantSelect
      class="plantSelect"
      type="text"
      [placeholder]="'PLACEHOLDER.PLANT.TYPE_SEARCH' | translate"
      matInput
      [formControl]="plantFormControl"
      (blur)="handleBlur()"
      [matAutocomplete]="auto"
      select-autofocus
      [focus]="focus"
      [focusDelay]="optionListDelay"
    />
    <mat-autocomplete
      (optionSelected)="handleOptionSelect($event)"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <cdk-virtual-scroll-viewport
        itemSize="48"
        minBufferPx="400"
        maxBufferPx="800"
        class="virtualOptionList"
        [style.height]="(plantListFiltered$ | async)?.length * 48 + 'px'"
      >
        <mat-option
          *cdkVirtualFor="let plant of plantListFiltered$ | async; trackBy: plant.value"
          [value]="plant"
        >
          {{ plant.name }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <button
      #clearButton
      *ngIf="plantSelect.value"
      matSuffix
      mat-icon-button
      [disableRipple]="true"
      aria-label="Clear"
      (click)="$event.stopPropagation(); handleClearPlantFilter(); plantSelect.focus()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error
      *ngIf="plantFormControl.hasError('notExists') || plantFormControl.hasError('required')"
    >
      {{ 'PLACEHOLDER.PLANT.TYPE_ERROR' | translate }}
    </mat-error>
  </mat-form-field>
</form>
<ng-template #plant>{{ selectedPlantName }}</ng-template>
