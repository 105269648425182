<form [formGroup]="loginForm">
  <div mat-dialog-title fxLayout="column" fxLayoutAlign="space-between center">
    <div>
      <img alt="TITLE.APP_NAME" height="80" src="/assets/logo.png" title="TITLE.APP_NAME" />
    </div>
    <div>
      <span translate>TITLE.LOGIN</span>
    </div>
    <!-- <button mat-icon-button
[disableRipple]="true" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>
  <mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="column" fxLayoutAlign="space-between stretch">
      <button mat-raised-button color="accent" (click)="onLoginByGoogle()" fxFlex>
        <fa-icon [icon]="['fab', 'google']" size="lg"> </fa-icon>
        &nbsp;
        <span translate>BUTTON.AUTH.GOOGLE</span>
      </button>
      <!-- <button
        mat-raised-button
        color="accent"
        (click)="onLoginByFacebook()"
        fxFlex
        disabled
      >
        <fa-icon
          [icon]="['fab', 'facebook-square']"
          size="lg"
        >
        </fa-icon>
        &nbsp;
        <span translate>BUTTON.AUTH.FACEBOOK</span>
      </button> -->
      <button mat-raised-button color="accent" (click)="onLoginByMicrosoft()" fxFlex disabled>
        <fa-icon [icon]="['fab', 'windows']" size="lg"> </fa-icon>
        &nbsp;
        <span translate>BUTTON.AUTH.MICROSOFT</span>
      </button>
    </div>
    <div class="form-container">
      <mat-form-field>
        <input
          cy-input-email
          type="email"
          matInput
          [placeholder]="'PLACEHOLDER.AUTH.LOGIN_EMAIL' | translate"
          formControlName="email"
          required
          autocomplete="email"
          cdkFocusInitial
        />
        <mat-error *ngFor="let validation of validationMessages?.auth?.email | keyvalue">
          <mat-error class="error-message" *ngIf="loginForm.get('email').hasError(validation.key)">
            {{ validation.value }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          cy-input-password
          type="password"
          matInput
          [placeholder]="'PLACEHOLDER.AUTH.LOGIN_PASSWORD' | translate"
          formControlName="password"
          required
          autocomplete="current-password"
        />
        <mat-error *ngFor="let validation of validationMessages?.auth?.password | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="loginForm.get('password').hasError(validation.key)"
          >
            {{ validation.value }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <!-- <br />
       <mat-checkbox labelPosition="after" formControlName="rememberMe" name="rememberMe">
        {{'PLACEHOLDER.AUTH.LOGIN_REMEMBERME' | translate}}
      </mat-checkbox> -->
    </div>
    <div fxLayout="column">
      <button
        cy-button-login
        mat-raised-button
        mat-dialog-close
        color="accent"
        [disabled]="loginForm.invalid || !loginForm.dirty"
        (click)="onLogin()"
        fxFlex
      >
        <span translate>BUTTON.AUTH.LOGIN</span>
      </button>
      <button
        mat-flat-button
        mat-dialog-close="AUTH_CREATE_ACCOUNT"
        fxFlex
        [disableRipple]="true"
        fxFlex
      >
        <span translate>BUTTON.AUTH.CREATE_ACCOUNT</span>
      </button>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="center" fxLayout="column" fxLayoutGap="16px">

    <button mat-raised-button mat-dialog-close color="accent" [disabled]="(loginForm.invalid || !loginForm.dirty)"
      (click)="onLogin()" fxFlex>
      <span translate>BUTTON.AUTH.LOGIN</span>
    </button>
    <button mat-flat-button mat-dialog-close="AUTH_UNKNOWN_PASSWORD" *ngIf="data.enable?.AUTH_UNKNOWN_PASSWORD" >
      <span translate>BUTTON.AUTH.UNKNOWN_PASSWORD</span>X
    </button>
    <button mat-flat-button mat-dialog-close="AUTH_CREATE_ACCOUNT" fxFlex [disableRipple]="true">
      <span translate>BUTTON.AUTH.CREATE_ACCOUNT</span>
    </button>
  </mat-dialog-actions> -->
</form>
