import { Column, Entity } from 'typeorm';

// import { TZakazky } from "./TZakazky";

@Entity('t_zakazky_stav', { schema: 'VaristarIS' })
export class TZakazkyStav {
  @Column('int', {
    primary: true,
    name: 'idt_zakazky_stav',
    comment: 'ID stavu zakázky',
  })
  idtZakazkyStav: number;

  @Column('varchar', {
    name: 'description',
    comment: 'Popis stavu zakázky ve formě textového kódu',
    length: 100,
  })
  description: string;

  // @OneToMany(() => TZakazky, (tZakazky) => tZakazky.orderStatus2)
  // tZakazkies: TZakazky[];
}
