import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
// import { TRole } from "./TRole";
import { TOsoby } from './TOsoby';
import { TZakaznik } from './TZakaznik';

@Index('t_osoby_idx', ['idtOsoby'], {})
@Index('t_user_FK', ['selectedCustomerId'], {})
@Entity('t_user', { schema: 'VaristarIS' })
export class TUser {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_user' })
  idtUser: number;

  @Column('int', {
    name: 'idt_osoby',
    nullable: true,
    comment: 'Párovací klíč do tabulky t_osoby.',
  })
  idtOsoby: number | null;

  @Column('varchar', { name: 'uid', nullable: true, length: 128 })
  uid: string | null;

  @Column('tinyint', {
    name: 'enabled',
    nullable: true,
    comment:
      'Logická hodnota 1/0 - jestli je uživatel schválen nebo ne - má-li přístup do systému. (vytvoří se při prvním přihlášení s 0 a administrátor ho aktivuje = 1) - při zákázání přístupu se změní na 0.',
    width: 1,
  })
  enabled: boolean | null;

  @Column('tinyint', {
    name: 'super_admin',
    nullable: true,
    comment:
      'Role superadmina umožňuje veškeré úpravy ve frontendu systému na všech zákaznících - typicky zaměstnanci Varistar.',
    width: 1,
    default: () => "'0'",
  })
  superAdmin: boolean | null;

  @Column('varchar', {
    name: 'ui_language',
    nullable: true,
    comment: 'Jazyk uživatelského rozhraní portálu.',
    length: 3,
    default: () => "'cs'",
  })
  uiLanguage: string | null;

  @Column('json', { name: 'dashboard_config', nullable: true })
  dashboardConfig: object | null;

  @Column('varchar', { name: 'email', nullable: true, length: 100 })
  email: string | null;

  @Column('int', { name: 'selected_customer_id', nullable: true })
  selectedCustomerId: number | null;

  // @OneToMany(() => TRole, (tRole) => tRole.createdUser2)
  // tRoles: TRole[];

  @ManyToOne('TOsoby', 'tUsers', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_osoby', referencedColumnName: 'idOsoby' }])
  idtOsoby2: TOsoby;

  @ManyToOne('TZakaznik', 'tUsers', {
    onDelete: 'SET NULL',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'selected_customer_id', referencedColumnName: 'idZakaznik' }])
  selectedCustomer: TZakaznik;
}
