import { Column, Entity, OneToMany } from 'typeorm';

import { TTabletMachines } from './TTabletMachines';

@Entity('t_tablet_machines_units', { schema: 'VaristarIS' })
export class TTabletMachinesUnits {
  @Column('int', { primary: true, name: 'idt_tablet_machines_units' })
  idtTabletMachinesUnits: number;

  @Column('varchar', {
    name: 'description',
    nullable: true,
    comment: 'Popis jednotky ve formě textového kódu',
    length: 45,
  })
  description: string | null;

  @Column('float', {
    name: 'unit_transformation',
    nullable: true,
    comment: 'Koeficient pro prepocet rate v aplikacni mape pro seti',
    precision: 12,
  })
  unitTransformation: number | null;

  // @OneToMany(
  //   () => TTabletMachines,
  //   (tTabletMachines) => tTabletMachines.mUnits2
  // )
  @OneToMany('TTabletMachines', 'mUnits2')
  tTabletMachines: TTabletMachines[];
}
