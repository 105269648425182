import { TZakaznikOsobyStrediskaRole } from './entities/TZakaznikOsobyStrediskaRole';

// tslint:disable-next-line: no-empty-interface
export interface CustomerPersonDepartmentRole extends TZakaznikOsobyStrediskaRole {}

export const CustomerPersonDepartmentRole = {
  selectId: (a: CustomerPersonDepartmentRole) => {
    return a.idZakaznikOsobyStrediskaRole;
  },
};
