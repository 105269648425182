import {
  Application,
  Device,
  DeviceVariable,
  DeviceWithServiceDetails,
  ServiceInstance,
} from 'balena-sdk';

// tslint:disable-next-line: no-empty-interface
export interface OpenBalenaDeviceDetails extends DeviceWithServiceDetails {
  env?: DeviceVariable[]; // pridaji se jen ciste envVars ne cele OpenBalenaVariable (hlavne kvuli TERMINAL_NUMBER)
  isInLocalMode?: boolean; // device in local mode, service defined in release are replaced by test local version, status and log is not working
}

export const OpenBalenaDeviceDetails = {
  selectId: (a: OpenBalenaDeviceDetails) => {
    return a.id;
  },
};
