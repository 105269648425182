import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TZakazky } from './TZakazky';

@Index('id_zakazky_bf_idx', ['idZakazky'], {})
@Entity('t_base_fertil', { schema: 'VaristarIS' })
export class TBaseFertil {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_base_fertil' })
  idtBaseFertil: number;

  @Column('bigint', {
    name: 'id_zakazky',
    nullable: true,
    comment: 'Odkaz na zakázku dle ID. ',
  })
  idZakazky: string | null;

  @Column('varchar', {
    name: 'product',
    nullable: true,
    comment: 'Aplikovaná látka - P-fosfor, K-draslík',
    length: 45,
  })
  product: string | null;

  @Column('decimal', {
    name: 'zone_0',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 0. ',
    precision: 4,
    scale: 2,
  })
  zone_0: string | null;

  @Column('decimal', {
    name: 'zone_1',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 1. ',
    precision: 4,
    scale: 2,
  })
  zone_1: string | null;

  @Column('decimal', {
    name: 'zone_2',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 2. ',
    precision: 4,
    scale: 2,
  })
  zone_2: string | null;

  @Column('decimal', {
    name: 'zone_3',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 3. ',
    precision: 4,
    scale: 2,
  })
  zone_3: string | null;

  @Column('decimal', {
    name: 'zone_4',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 4. ',
    precision: 4,
    scale: 2,
  })
  zone_4: string | null;

  @Column('decimal', {
    name: 'zone_5',
    nullable: true,
    comment: 'Udává hodnotu koeficientu, jenž se má použít při zásobním hnojení pro zónu 5. ',
    precision: 4,
    scale: 2,
  })
  zone_5: string | null;

  // @ManyToOne(() => TZakazky, (tZakazky) => tZakazky.tBaseFertils, {
  @ManyToOne('TZakazky', 'tBaseFertils', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_zakazky', referencedColumnName: 'idZakazky' }])
  idZakazky2: TZakazky;
}
