<div fxLayout="column" class="progress">
  <div fxLayout="row" fxLayoutAlign="end center">
    <div class="progress-status">
      {{ (progress.inProgress$ | async) ? (progress.status$ | async | translate) : '' }}
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="progress.isUnknown$ | async"></mat-progress-bar>
  <mat-progress-bar
    mode="determinate"
    [value]="percent"
    *ngIf="progress.isPercent$ | async as percent"
  ></mat-progress-bar>
</div>
