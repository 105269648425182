import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[oneClick]',
})
export class ButtonOneClickDirective {
  @HostBinding('attr.disabled') disabled: boolean;
  @HostListener('click') onButtonClick() {
    this.disabled = true;
  }

  constructor() {}
}
