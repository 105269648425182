//structure is customized; see seed.controller.ts for customization logic
//goal is to have better structure in application logic
//this table is read-only, so it is not a big deal

// tslint:disable-next-line: no-empty-interface
export interface Seed {
  idSeed: number;
  name: string;
  type: string;
}

//!!! seed id can be '0'
export const Seed = {
  selectId: (x: Seed) => {
    return x.idSeed;
  },
  filterFn: (seedList) => {
    return seedList.filter((seed: Seed) => {
      //filter out id "0", because it makes bugs and is not needed at all
      return !!+seed.idSeed;
    });
  },
};
