import { Pipe, PipeTransform } from '@angular/core';
import { Field } from '@varistar-apps/shared/data';

@Pipe({
  name: 'fieldVymeram',
})
export class FieldVymeramPipe implements PipeTransform {
  transform(field: Field): Number {
    if (field?.splitted) {
      return +field.vymeram;
    }
    return +field.vymeramTotal;
  }
}
