import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcInput<T> extends DynamicFormControlBase<T> {
  controlType = 'input';
  type: string;
  step;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.prefix = options['prefix'] || '';
    this.suffix = options['suffix'] || '';
    this.suffixIcon = options['suffixIcon'] || '';
    this.step = options['step'] || 1;
    this.hintPopup = options['hintPopup'] || '';
  }
}
