import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { AuthFacade } from '@varistar-apps/frontend/auth';
import { SnackBarComponent } from '@varistar-apps/frontend/ui';
import {
  Access,
  AccessRole,
  AccessState,
  Firestore,
  Organization,
} from '@varistar-apps/shared/data';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  LoadRegistration,
  RegistrationAdded,
  RegistrationFeature,
  RegistrationModified,
  RegistrationPayload,
  RegistrationRemoved,
  SelectRegistration,
} from './registration.actions';
import { RegistrationState } from './registration.reducer';
import { getRegistrationQuery } from './registration.selectors';

@Injectable()
export class RegistrationFacade {
  // organizationRole;

  constructor(
    private store: Store<RegistrationState>,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBarComponent: SnackBarComponent,
    public authFacade: AuthFacade,
    // private dataPersistence: DataPersistence<RegistrationState>,
    // private apiOcsConfig: ApiOcsService,
  ) {}

  /**
   * Vr cestu na data
   * @param feature
   */
  getDataPath(feature: RegistrationFeature) {
    // const dataUrl = `ORGANIZATION/1/${feature}`;
    const dataUrl = `${feature}`;
  }

  getAllOrganizations(): Observable<Organization[]> {
    // load actual data
    // TODO: and register for updates
    this.store.dispatch(new LoadRegistration(RegistrationFeature.Organization));
    // merge data together
    return combineLatest(this.getAll(RegistrationFeature.Organization)).pipe(
      map(([organizations]) =>
        organizations.map((organization: Organization) => {
          return {
            ...organization,
            // company: companies.find((v) => customer.company && v.id === customer.company.id),
          };
        }),
      ),
    );
  }

  // getAccount(): Observable<Organization[]> {
  //   // load actual data
  //   // TODO: and register for updates
  //   this.store.dispatch(new LoadRegistration(RegistrationFeature.Account));
  //   // merge data together
  //   return combineLatest(
  //     this.getAll(RegistrationFeature.Account),
  //   ).pipe(
  //     map(([organizations]) => organizations.map((organization: Organization) => {
  //       return {
  //         ...organization,
  //         // company: companies.find((v) => customer.company && v.id === customer.company.id),
  //       }
  //     })),
  //   );
  // }

  async createOrganization(organization: Organization) {
    const uid = this.afAuth.auth.currentUser.uid;
    // vytvorit organizaci pokud ma na to uzivate pravo (kontroluje se na urovni Firestore rules)
    const organizationCollectionPath = Firestore.getPath(RegistrationFeature.Organization);
    await this.afs.collection(organizationCollectionPath).doc(organization.id).set(organization);
    // aktualizovat seznam organizaci
    this.store.dispatch(new LoadRegistration(RegistrationFeature.Organization));
    // ZKontrolovat zda ma uzivate pristup k organizaci
    const organizationAccessCollectionPath = Firestore.getPath(
      RegistrationFeature.OrganizationAccess,
      {
        'organization.id': organization.id,
      },
    );
    const access: Access = {
      id: uid,
      created: moment().toISOString(),
      state: AccessState.REQUESTED,
      user: {
        email: this.afAuth.auth.currentUser.email,
        displayName: this.afAuth.auth.currentUser.displayName,
      },
      role: AccessRole.USER,
    };
    const organizationAccess = await this.afs
      .collection(organizationAccessCollectionPath)
      .doc(uid)
      .set(access);
    const accountCollectionPath = Firestore.getPath(RegistrationFeature.Account);
    await this.afs.collection(accountCollectionPath).doc(uid).update({ organization });
    // To je blbost.... potrebuji aktualizovat account v auth state
    // // aktualizaovat account
    // this.store.dispatch(new LoadRegistration(RegistrationFeature.Account));
    this.authFacade.updateAccount({
      /*organization*/
    }); // !!! nahradit cele po novu
    this.snackBarComponent.snackbarError('ERROR.ACCESS.NOT_YET_ACCEPTED', 'warning', 'close', 5000);
  }
  async organizationAccess(organization: Organization) {
    const uid = this.afAuth.auth.currentUser.uid;
    // FIXI: nemelo by se stat ze neni vytvorena
    // vytvorit organizaci pokud ma na to uzivate pravo (kontroluje se na urovni Firestore rules)
    const organizationCollectionPath = Firestore.getPath(RegistrationFeature.Organization);
    await this.afs.collection(organizationCollectionPath).doc(organization.id).set(organization);
    // ZKontrolovat zda ma uzivate pristup k organizaci
    const organizationAccessCollectionPath = Firestore.getPath(
      RegistrationFeature.OrganizationAccess,
      {
        'organization.id': organization.id,
      },
    );
    const access = await this.afs
      .collection(organizationAccessCollectionPath)
      .doc(uid)
      .get()
      .toPromise();

    if (access.exists) {
      // jiz tu je zaznam o Accountu, zkotroluji stav
      const accountAccess: Access = access.data();
      switch (accountAccess.state) {
        case AccessState.ACCEPTED:
          // Ulozim organizaci do Account
          const accountCollectionPath = Firestore.getPath(RegistrationFeature.Account);
          await this.afs.collection(accountCollectionPath).doc(uid).update({ organization });
          // To je blbost.... potrebuji aktualizovat account v auth state
          // // aktualizaovat account
          // this.store.dispatch(new LoadRegistration(RegistrationFeature.Account));
          this.authFacade.updateAccount({
            /*organization*/
          }); // !!! nahradit cele po novu
          this.snackBarComponent.snackbarSuccess('MESSAGE.ACCESS.ACCEPTED', 'info', 'close', 2000);
          return true;
        case AccessState.REQUESTED:
          this.snackBarComponent.snackbarError(
            'ERROR.ACCESS.NOT_YET_ACCEPTED',
            'warning',
            'close',
            5000,
          );
          break;
        case AccessState.REJECTED:
          this.snackBarComponent.snackbarError('ERROR.ACCESS.REJECTED', 'warning', 'close', 5000);
          break;
      }
    } else {
      // uzivatel jeste neni v seznamu uzivatelu organizace
      const newAccess: Access = {
        id: uid,
        created: moment().toISOString(),
        state: AccessState.REQUESTED,
        user: {
          email: this.afAuth.auth.currentUser.email,
          displayName: this.afAuth.auth.currentUser.displayName,
        },
        role: AccessRole.USER,
      };
      const organizationAccess = await this.afs
        .collection(organizationAccessCollectionPath)
        .doc(uid)
        .set(newAccess);
      this.snackBarComponent.snackbarError('ERROR.ACCESS.REQUESTED', 'warning', 'close', 5000);
    }
    return false;
  }

  async getOrganizationAccess(organization: Organization) {
    const uid = this.afAuth.auth.currentUser.uid;
    // ZKontrolovat zda ma uzivatel pristup k organizaci
    const organizationAccessCollectionPath = Firestore.getPath(
      RegistrationFeature.OrganizationAccess,
      {
        'organization.id': organization.id,
      },
    );
    const access = await this.afs
      .collection(organizationAccessCollectionPath)
      .doc(uid)
      .get()
      .toPromise();
    return access.data();
  }

  load(feature: RegistrationFeature) {
    this.store.dispatch(new LoadRegistration(feature));
  }

  select(feature: RegistrationFeature, id: string) {
    this.store.dispatch(new SelectRegistration(feature, id));
  }
  add(feature: RegistrationFeature, data: RegistrationPayload, ids: any = {}) {
    const collectionPath = Firestore.getPath(feature, { ...data, ...ids });
    return this.afs
      .collection(collectionPath)
      .add(data)
      .then((newData) => {
        const featureData = { id: newData.id, ...data };
        this.store.dispatch(new RegistrationAdded(feature, featureData));
        this.select(feature, newData.id);
        return featureData;
      });
  }

  modify(
    feature: RegistrationFeature,
    data: Partial<RegistrationPayload>,
    id: string,
    ids: any = {},
  ) {
    const collectionPath = Firestore.getPath(feature, { ...data, ...ids });
    // // ne chci ukladat id, ktere se pridalo pri nacteni dokumentu
    // const dataWithoutId = { ...data };
    // delete dataWithoutId.id;
    // return this.afs.collection(collectionPath).doc(id).update(dataWithoutId).then(() => {
    return this.afs
      .collection(collectionPath)
      .doc(id)
      .update(data)
      .then(() => {
        this.store.dispatch(new RegistrationModified(feature, data as RegistrationPayload));
        return data;
      });
  }

  remove(feature: RegistrationFeature, id: string, ids: any = {}) {
    const collectionPath = Firestore.getPath(feature, { ...ids });
    return this.afs
      .collection(collectionPath)
      .doc(id)
      .delete()
      .then(() => {
        this.store.dispatch(new RegistrationRemoved(feature, id));
        this.select(feature, null);
      });
  }

  getAll(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selectAll));
  }
  getTotal(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selectTotal));
  }
  getIds(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selectIds));
  }
  getEntities(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selectEntities));
  }

  getLoaded(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).loaded));
  }

  getSelectedId(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selectedId));
  }
  getSelected(feature: RegistrationFeature) {
    return this.store.pipe(select(getRegistrationQuery(feature).selected));
  }
}
