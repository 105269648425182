<div *ngFor="let item of data; let i = index">
  <dynamic-form
    header="SUBTITLE.CONTACTS"
    description="DESCRIPTION.CONTACTS"
    [controls]="controls"
    [data]="data[i]"
    layout="simple"
    [enable]="enable"
    (save)="onSave($event, i)"
    (delete)="onDelete($event, i, value)"
    [autosave]="true"
    (modify)="onModify($event)"
  >
    <!-- Prida se pred tlacitka Save a Cancel -->
    <!-- <button mat-icon-button
[disableRipple]="true" color="" (click)="onDelete(i)" [matTooltip]="'BUTTON.ADD' | translate"
              matTooltipPosition="above">
              <mat-icon>minus_box</mat-icon>
            </button> -->
  </dynamic-form>
</div>
