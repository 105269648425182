import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { compare } from '@varistar-apps/shared/utilities';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, first, map, skip } from 'rxjs/operators';

type SelectOption = {
  name: string;
  value: any;
};

@Component({
  selector: 'ui-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('select') inputAutocomplete;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  selectedValueList$ = new BehaviorSubject([]);
  filterValue$ = new BehaviorSubject<string>('');
  optionListFiltered$: Observable<SelectOption[]>;
  subscription = new Subscription();

  //{ value: name }
  optionMap = {};
  activeOption;

  @Input() optionList$: Observable<SelectOption[]>;
  @Input() actions: boolean = false;
  @Input('selectedValueList$') set setSelectedValueList$(selectedValueList$: Observable<any[]>) {
    this.subscription.add(
      selectedValueList$.subscribe((selectedValueList) => {
        if (
          Array.isArray(selectedValueList) &&
          JSON.stringify(selectedValueList) !== JSON.stringify(this.selectedValueList$.value)
        ) {
          this.selectedValueList$.next(selectedValueList);
        }
      }),
    );
  }
  @Input('defaultSelectedValueList') set setDefaultSelectedValueList(
    defaultSelectedValueList: any[],
  ) {
    if (!this.selectedValueList$.value.length) {
      this.selectedValueList$.next(defaultSelectedValueList);
    }
  }
  @Input() label: string;
  @Input() placeholder: string;
  @Input() width: string;
  @Input('disabled') isDisabled: boolean;
  @Input() hideAutocompleteOnInitialize = false;
  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.optionListFiltered$ = combineLatest([
      combineLatest([this.optionList$, this.selectedValueList$]).pipe(
        map(([optionList, selectedValueList]) => {
          return optionList.filter((option) => !selectedValueList.includes(option.value));
        }),
      ),
      this.filterValue$,
    ]).pipe(
      map(([optionList, filterValue]) => {
        if (!filterValue) {
          return optionList.sort((a, b) => compare(a.name, b.name, true));
        }

        const filterLowerCase = filterValue.toLocaleLowerCase();

        return optionList
          .filter((option) => option.name.toLowerCase().includes(filterLowerCase))
          .sort((a, b) => {
            const aIndex = a.name.toLocaleLowerCase().indexOf(filterLowerCase);
            const bIndex = b.name.toLocaleLowerCase().indexOf(filterLowerCase);

            if (aIndex > bIndex) {
              return 1;
            } else if (bIndex > aIndex) {
              return -1;
            }

            return compare(a.name, b.name, true);
          });
      }),
    );

    this.subscription.add(
      this.optionListFiltered$.subscribe((optionList) => {
        this.activeOption = optionList[0]?.value;
      }),
    );

    this.subscription.add(
      this.selectedValueList$
        .pipe(skip(1), distinctUntilKeyChanged('length'))
        .subscribe((selectedValueList) => {
          this.valueChange.emit(selectedValueList);
          if (!this.hideAutocompleteOnInitialize) {
            this.autocompleteTrigger?.openPanel();
          } else {
            this.hideAutocompleteOnInitialize = false;
          }
        }),
    );

    this.subscription.add(
      this.optionList$.subscribe((optionList) => {
        optionList.forEach(({ name, value }) => {
          this.optionMap[value] = name;
        });
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeValue(value) {
    this.selectedValueList$.next(this.selectedValueList$.value.filter((v) => v !== value));
  }

  handleFilterChange = (event) => {
    this.filterValue$.next(event.target.value);
  };

  handleClearFilter = (event) => {
    event.stopPropagation();
    this.filterValue$.next('');
  };

  handleOptionSelect = (value = this.activeOption) => {
    this.filterValue$.next('');
    this.addValue(value);
  };

  addValue = (value) => {
    this.optionList$.pipe(first()).subscribe((optionList) => {
      if (optionList.find((option) => option.value === value)) {
        this.selectedValueList$.next(this.selectedValueList$.value.concat(value));
      }
    });
  };

  handleAddAll = () => {
    this.filterValue$.next('');
    this.optionList$.pipe(first()).subscribe((optionList) => {
      this.selectedValueList$.next(optionList.map((option) => option.value));
    });
  };

  handleRemoveAll = () => {
    this.filterValue$.next('');
    this.selectedValueList$.next([]);
  };
}
