import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TOpravneni } from './TOpravneni';
import { TOsoby } from './TOsoby';
import { TZakaznik } from './TZakaznik';
import { TZakaznikOsobyRole } from './TZakaznikOsobyRole';
import { TZakaznikOsobyStrediska } from './TZakaznikOsobyStrediska';

@Index('id_user', ['idtOsoby'], {})
@Index('id_zakaznik', ['idtZakaznik'], {})
@Index('t_zakaznik_osoby_UN', ['idtZakaznikUser'], { unique: true })
@Entity('t_zakaznik_osoby', { schema: 'VaristarIS' })
export class TZakaznikOsoby {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_zakaznik_user' })
  idtZakaznikUser: number;

  @Column('int', {
    name: 'idt_zakaznik',
    nullable: true,
    comment: 'Párovací ID z tabulky zákazník - id farmy',
  })
  idtZakaznik: number | null;

  @Column('int', {
    name: 'idt_osoby',
    nullable: true,
    comment: 'Párovací ID uživatele z tabulky t_osoby',
  })
  idtOsoby: number | null;

  @Column('int', {
    name: 'role',
    nullable: true,
    comment: 'Sloupec, který je nepoužívaný z původní implementace práv.',
  })
  role: number | null;

  @OneToMany('TOpravneni', 'idtZakaznikOsoby2')
  tOpravnenis: TOpravneni[];

  @ManyToOne('TOsoby', 'tZakaznikOsobies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_osoby', referencedColumnName: 'idOsoby' }])
  idtOsoby2: TOsoby;

  @ManyToOne('TZakaznik', 'tZakaznikOsobies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_zakaznik', referencedColumnName: 'idZakaznik' }])
  idtZakaznik2: TZakaznik;

  @OneToMany('TZakaznikOsobyRole', 'idtZakaznikOsoby2')
  tZakaznikOsobyRoles: TZakaznikOsobyRole[];

  @OneToMany('TZakaznikOsobyStrediska', 'idtZakaznikOsoby2')
  tZakaznikOsobyStrediskas: TZakaznikOsobyStrediska[];
}
