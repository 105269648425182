import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getPlantName, Order } from '@varistar-apps/shared/data';

@Pipe({
  name: 'plodina',
})
export class PlodinaPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(transformObject: any) {
    return getPlantName(
      this.translateService.currentLang,
      transformObject?.idPlodiny2 || transformObject?.idCrop2 || transformObject,
    );
  }
}
