import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Contact } from '@varistar-apps/shared/data';
import * as _ from 'lodash';
import { DfcInput } from '../dfc-input';
import { DfcSelect } from '../dfc-select';
import { of, Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-control-contacts',
  templateUrl: './dynamic-form-control-contacts.component.html',
  styleUrls: ['./dynamic-form-control-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicFormControlContactsComponent,
      multi: true,
    },
  ],
})
export class DynamicFormControlContactsComponent implements ControlValueAccessor {
  @Input() form?: FormGroup;
  @Input() enable = {
    save: false,
    cancel: false,
    delete: false,
  };

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() event = new EventEmitter();

  value: Contact[]; // pole kontaktů
  controls = [];
  data: { contact: Contact };

  contactTypes = Contact.getContacTypes();

  onChangeValue = (value: any): void => {};
  onValidatorChange = (value?: any): void => {};

  constructor(private cdr: ChangeDetectorRef) {}

  writeValue(value: Contact[]) {
    this.value = value;
    // this.data = { contact: value }; // NOTE: data musí být zanořeny jinak se po updatu nezobrazí
    this.controls = _.map(this.value, (contact, i) => {
      return [
        new DfcSelect<string>({
          key: 'contact.type',
          placeholder: 'PLACEHOLDER.CONTACT.TYPE',
          order: 1.1,
          required: true,
          items: this.contactTypes,
          value: null,
        }),
        new DfcInput<string>({
          key: 'contact.value',
          placeholder: 'PLACEHOLDER.CONTACT.VALUE',
          order: 1.2,
          flex: '100',
        }),
      ];
    });

    this.change.emit(this.value);
    this.cdr.markForCheck();
  }

  onChange() {
    this.onChangeValue(this.value);
    this.onValidatorChange(this.value);
  }

  registerOnChange(fn) {
    this.onChangeValue = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean) {
    isDisabled = isDisabled;
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   // const isValid = _.reduce(control.value, (isValid, value, id) => {
  //   //   return _.reduce(value.data, (isValid, value, deviceType) => {
  //   //     return deviceType !== '' && _.reduce(value, (isValid, value, deviceId) => {
  //   //       return deviceId !== '' && _.reduce(value, (isValid, value, indexOrName) => {
  //   //         return indexOrName !== '' && _.reduce(value, (isValid, value, key) => {
  //   //           return key !== '' && !isNaN(value);
  //   //         }, isValid);
  //   //       }, isValid);
  //   //     }, isValid);
  //   //   }, isValid);
  //   // }, true);

  //   // // this.onValidatorChange();
  //   // return isValid ? null : { 'custom': true };
  // }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onSave(data) {
    const value = data.contact;
    // let x = _.cloneDeep(this.value);
    //   x[i].ip = event.ip;
    // this.writeValue({ ...this.value, ...value});
    this.writeValue(value);
    return this.onChange();
  }

  onModify(data) {
    const value = data.contact;
    // let x = _.cloneDeep(this.value);
    // x[i] = { ...x[i], ...event };
    // this.onChangeValue(value);
    if (this.value) {
      // NOTE: po pridani selectionChange v dynamic-form-control u select to bez kontroly na null nastavilo zmenu pri zobrazeni prazdneho form kde je select
      this.onChangeValue(value);
      // this.onChangeValue({ ...this.value, ...value });
    }
  }

  onDelete(event) {
    // let x = _.cloneDeep(this.value);
    // x.splice(i, 1)
    // this.writeValue(x);
    // this.onChange();
  }
}
