import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TPlodinyEagri } from './TPlodinyEagri';

@Index('fid', ['fid'], {})
@Index('id_select', ['idLpisSkSelect'], {})
@Index('id_select_id', ['idLpisSelect'], {})
@Index('jmeno', ['jmeno'], {})
@Index('select_end', ['selectEnd'], {})
@Index('t_LPIS_select_FK', ['idPlodiny'], {})
@Entity('t_LPIS_SK_select', { schema: 'VaristarIS' })
export class TLpisSkSelect {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'id_LPIS_SK_select' })
  idLpisSkSelect: string;

  @Column('bigint', {
    name: 'fid',
    nullable: true,
    comment: 'Jedineční identifákor v databázi LPIS.',
  })
  fid: string | null;

  @Column('int', {
    name: 'select_end',
    nullable: true,
    comment: 'Informace jestli byl výběr honů dokončen či nikoliv. ',
    default: () => "'0'",
  })
  selectEnd: number | null;

  @Column('varchar', {
    name: 'id_LPIS_select',
    nullable: true,
    comment:
      'Unikátní ID, které je generované systémem VaristarIS - toto ID je součástí hyperlinku, který dostane klient Varistar a jednoznačně identifikuje pole, z kterých má vybírat hony pro smlouvu.',
    length: 45,
  })
  idLpisSelect: string | null;

  @Column('varchar', {
    name: 'jmeno',
    nullable: true,
    comment: 'Zákaznické pojmenování honu. ',
    length: 150,
  })
  jmeno: string | null;

  @Column('double', {
    name: 'vymeram_vyber',
    nullable: true,
    comment: 'Celková výměra vybraných honů. ',
    precision: 7,
    scale: 2,
  })
  vymeramVyber: number | null;

  @Column('int', {
    name: 'id_plodiny',
    nullable: true,
    comment: 'Id plodiny z tabulky t_plodiny_EAGRI',
    default: () => "'0'",
  })
  idPlodiny: number | null;

  @Column('varchar', { name: 'nkod', nullable: true, length: 45 })
  nkod: string | null;

  @Column('double', { name: 'vymeram', nullable: true, precision: 7, scale: 2 })
  vymeram: number | null;

  @Column('varchar', {
    name: 'zkod',
    nullable: true,
    comment: 'Zkrácený kód půdního bloku v katastrálním území',
    length: 15,
  })
  zkod: string | null;

  @Column('varchar', {
    name: 'mapvlst5',
    nullable: true,
    comment: 'Lokalita umístění honu',
    length: 255,
  })
  mapvlst5: string | null;

  @Column('varchar', {
    name: 'prisl_opzpl',
    nullable: true,
    comment: 'Čsílo katastrálního území',
    length: 45,
  })
  prislOpzpl: string | null;

  @Column('int', {
    name: 'pcuv',
    nullable: true,
    comment: 'ID zemědělské parcely v rámci ZKOD',
  })
  pcuv: number | null;

  @Column('decimal', {
    name: 'kultura',
    nullable: true,
    comment: 'kód kultury dle slovenského LPIS',
    precision: 4,
    scale: 0,
  })
  kultura: string | null;

  @Column('tinyint', {
    name: 'splitted',
    nullable: true,
    comment:
      'Ukazuje jestli jde o geometricky oddělení půdní subblok (FID takového půdního bloku má hodnotu YYYYYYYYXXX Y - původní FID, X pořadové číslo odděleného bloku na daném FID např. 12199611003)',
    width: 1,
    default: () => "'0'",
  })
  splitted: boolean | null;

  @Column('decimal', {
    name: 'id_uz',
    nullable: true,
    comment:
      'ID uživatele půdního bloku dle slovenského LPIS - ve slovenském LPIS se nazává ppa_id',
    precision: 8,
    scale: 0,
  })
  idUz: string | null;

  @Column('tinyint', {
    name: 'vyber',
    nullable: true,
    comment: 'Informace o tom, zda byl hon (1) vybrán nebo ne (0).',
    width: 1,
    default: () => "'0'",
  })
  vyber: boolean | null;

  @Column('varchar', {
    name: 'anc_kategoria',
    nullable: true,
    comment: 'GSAA identifikator ANC_KATEGORIA',
    length: 10,
  })
  ancKategoria: string | null;

  @Column('varchar', {
    name: 'parcela',
    nullable: true,
    comment: 'GSAA identifikator PARCELA',
    length: 3,
  })
  parcela: string | null;

  @ManyToOne('TPlodinyEagri', 'tLpisSkSelects', {
    onDelete: 'RESTRICT',
    onUpdate: 'RESTRICT',
  })
  @JoinColumn([{ name: 'id_plodiny', referencedColumnName: 'idtPlodinyEagri' }])
  idPlodiny2: TPlodinyEagri;
}
