import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[appOffsetTop]' })
export class OffsetTopDirective {
  constructor(private _el: ElementRef) {}
  get offsetTop(): number {
    const x = this._el.nativeElement.offsetTop;

    return x;
  }
}
