import { NgControl } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disableControl]',
})
export class DynamicDisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    if (this.ngControl && this.ngControl.control) this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {}
}
