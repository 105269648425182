import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ElementRef,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isFunction } from 'util';

import { DynamicFormControlBase } from '../dynamic-form-control-base';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { DOCUMENT } from '@angular/common';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormControlComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() control: DynamicFormControlBase<any>;
  @Input() form: FormGroup;
  @Output() change = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() event = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() presskey = new EventEmitter();
  @Output() enter = new EventEmitter();
  @Output() esc = new EventEmitter();

  value;
  disabledObservable: Subscription;

  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private document,
  ) {}

  ngOnInit() {
    if (this.control.disabled$)
      this.disabledObservable = this.control.disabled$.subscribe((isDisabled) => {
        this.control.disabled = isDisabled;
      });
  }

  ngOnDestroy(): void {
    if (this.control.disabled$) this.disabledObservable.unsubscribe();
  }

  ngAfterViewInit(): void {
    const controlElement = this.document.getElementById(this.control.key);
    // if (controlElement) {
    //   this.control.focus && controlElement.focus();
    // }
  }

  get isValid() {
    return this.form.controls[this.control.key].valid;
  }

  isDisabled() {
    const disabled = isFunction(this.control.disabled)
      ? this.control.disabled(this)
      : this.control.disabled;
    return disabled; //this.form.controls[this.control.key].disabled;
  }

  onChange(event) {
    this.value = event;
    this.change.emit(event);
  }

  onChangeVoid(event) {
    event.stopPropagation();
  }

  onChangeOptions(event) {
    this.value = _.get(event, 'option.value.value');
    this.change.emit(this.value);
  }

  onClear(event) {
    this.form.controls[this.control.key].setValue(null);
    this.value = null;
    this.change.emit({ value: null });
  }

  onDelete(event) {
    this.delete.emit(this.control);
  }

  onEvent(data) {
    this.event.emit(data);
  }

  private timeoutOnFocus = null;
  private timeoutOnEnter = null;

  onFocus(event) {
    event.preventDefault();
    this.focus.emit(event);
    if (this.control.select) {
      if (this.timeoutOnFocus) clearTimeout(this.timeoutOnFocus);
      this.timeoutOnFocus = setTimeout(() => {
        const controlElement = this.document.getElementById(this.control.key);
        if (controlElement) {
          // this.control.focus && controlElement.focus();
          this.control.select && controlElement.select();
        }
        console.log('onFocus');
      }, 300); // FIXIT: pri 100 to zlobilo ve formulari u application-order-rvp-dialog
    }
  }

  onBlur(data) {
    this.blur.emit(data);
    if (this.timeoutOnFocus) clearTimeout(this.timeoutOnFocus);
    if (this.timeoutOnEnter) clearTimeout(this.timeoutOnEnter);
  }

  onMouseOut(data) {}

  onPressKey(event) {
    if (this.presskey) {
      console.log('presskey', event, event.target.type);
      // event.preventDefault();
      // this.change.emit(event);
      this.presskey.emit(event);
    }
  }

  onEnter(event) {
    event.preventDefault();
    this.change.emit(event);
    this.enter.emit(event);
    if (this.control.select) {
      if (this.timeoutOnEnter) clearTimeout(this.timeoutOnEnter);
      this.timeoutOnEnter = setTimeout(() => {
        const controlElement = this.document.getElementById(this.control.key);
        if (controlElement) {
          this.control.select && controlElement.select();
        }
      }, 100);
    }
  }

  onEsc(event) {
    event.preventDefault();
    this.esc.emit(event);
  }

  onAction(action: string, control: any) {
    this.action.emit({ action, control });
  }
}
