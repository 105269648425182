import { TPesticidCustom } from './entities/TPesticidCustom';

// tslint:disable-next-line: no-empty-interface
export interface PesticidCustom extends TPesticidCustom {}

export const PesticidCustom = {
  selectId: (x: TPesticidCustom) => {
    return x.idPesticidCustom;
  },
  filterFn: (pesticidList: PesticidCustom[]) => {
    return pesticidList.filter((p: PesticidCustom) => {
      return !p.deactivated;
    });
  },
};
