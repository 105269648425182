import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcDate<T> extends DynamicFormControlBase<T> {
  controlType = 'date';
  // type: string;

  constructor(options: {} = {}) {
    super(options);
    // this.type = options['type'] || '';
  }
}
