import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcContact<T> extends DynamicFormControlBase<T> {
  controlType = 'contact';
  // type: string;

  constructor(options: {} = {}) {
    super(options);
    // this.type = options['type'] || '';
  }
}
