import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { TPorUdaje } from './TPorUdaje';
import { TPorOpPlodiny } from './TPorOpPlodiny';

@Index('idt_por_udaje_idx', ['udajeId'], {})
@Entity('t_por_ochranna_pasma', { schema: 'VaristarIS' })
export class TPorOchrannaPasma {
  @Column('bigint', { primary: true, name: 'idt_por_ochranna_pasma' })
  idtPorOchrannaPasma: string;

  @Column('bigint', {
    name: 'udaje_id',
    nullable: true,
    comment: 'Odkazuje na tabulku t_por_udaje',
  })
  udajeId: string | null;

  @Column('varchar', {
    name: 'BEZ_REDUKCE',
    nullable: true,
    comment: 'Ochranná vzdálenost bez redukce [m]\\n',
    length: 50,
  })
  bezRedukce: string | null;

  @Column('varchar', {
    name: 'TRYSKA_50',
    nullable: true,
    comment: 'Ochranná vzdálenost při použití trysky s redukcí 50% [m]',
    length: 50,
  })
  tryska_50: string | null;

  @Column('varchar', {
    name: 'TRYSKA_75',
    nullable: true,
    comment: 'Ochranná vzdálenost při použití trysky s redukcí 75% [m]',
    length: 50,
  })
  tryska_75: string | null;

  @Column('varchar', {
    name: 'TRYSKA_90',
    nullable: true,
    comment: 'Ochranná vzdálenost při použití trysky s redukcí 90% [m]',
    length: 50,
  })
  tryska_90: string | null;

  @Column('varchar', {
    name: 'PLODINY_TEXT',
    nullable: true,
    comment: 'Plodiny pro ochranné pásmo textem',
    length: 2000,
  })
  plodinyText: string | null;

  @Column('varchar', {
    name: 'OP_SVAH',
    nullable: true,
    comment: 'Ochranná vzdálenost na svažitém pozemku',
    length: 50,
  })
  opSvah: string | null;

  @Column('varchar', {
    name: 'VEG_PAS_SVAH',
    nullable: true,
    comment: 'Vegetační pás',
    length: 50,
  })
  vegPasSvah: string | null;

  @ManyToOne('TPorUdaje', 'tPorOchrannaPasmas', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'udaje_id', referencedColumnName: 'idtPorUdaje' }])
  udaje: TPorUdaje;

  // @OneToMany('TPorOpPlodiny', 'op')
  // tPorOpPlodinies: TPorOpPlodiny[];
}
