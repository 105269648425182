import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApiVaristarService } from '@varistar-apps/frontend/api-varistar';
import { AuthFacade } from '@varistar-apps/frontend/auth';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';

const excludedErrorList = [
  //prevent infinite error sending loop !!!
  'send-error-to-support',

  'leaflet',
  'maintenance-page',
  'align="start',
  'ExpressionChangedAfterItHasBeenCheckedError',
];

const excludedErrorListDevelopment = [
  'ExpressionChangedAfterItHasBeenCheckedError',
  'A hint was already declared for',
];

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private apiVaristarService: ApiVaristarService,
    private injector: Injector,
  ) {}

  handleError(error) {
    const errorMessage = error.toString().toLowerCase();

    if (errorMessage.includes('chunk')) {
      //may happer if new version comes out while in the browser is still loaded the old one; needs reload;
      window.location.reload();
      return;
    }

    if (environment.production) {
      if (
        excludedErrorList.every((excludedValue) => {
          return !errorMessage.includes(excludedValue.toLowerCase());
        })
      ) {
        //set support email first!
        // this.sendErrorToSupport(error);
      }
    } else {
      if (
        excludedErrorListDevelopment.every((excludedValue) => {
          return !errorMessage.includes(excludedValue.toLowerCase());
        })
      ) {
        console.error(error);
      }
    }
  }

  private sendErrorToSupport = async (error) => {
    //must use injector because of dependency cycling
    const varistarProfile = await this.injector
      .get(AuthFacade)
      .varistarProfile$.pipe(first())
      .toPromise();

    this.apiVaristarService.sendErrorToSupport(
      `
      URL: ${window.location.href}
      STACK:${error.stack}
      VARISTAR_PROFILE: ${JSON.stringify(varistarProfile)}`,
      error.toString(),
    );
  };
}
