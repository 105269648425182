import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as _ from 'lodash';
import { isArray, isNumber, isString } from 'util';

import { DfcInput } from '../dfc-input';
import { DfcKeys, KeyValue } from '../dfc-keys';

// export interface Keys {
//   key: string,
//   value: any
// };
// export interface ConversionConstants {
//   // data z databaze Conversion[] s pridanym atributem constants (conversionData[]) z preformatovaneho atributy data (conversionDataObject)
//   constants: ConversionData[];
// }
// export interface ConversionData {
//   deviceType: string;
//   deviceId: string;
//   indexOrName: string;
//   key: string;
//   value: number;
// }
// export interface ConversionDataObject {
//   [deviceType: string]: {
//     [deviceId: string]: {
//       [indexOrName: string]: {
//         [key: string]: number; // value
//       }
//     }
//   };
// }
export enum ValueTypes {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  ARRAY = 'ARRAY',
  OBJECT = 'OBJECT',
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-control-value',
  templateUrl: './dynamic-form-control-value.component.html',
  styleUrls: ['./dynamic-form-control-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicFormControlValueComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DynamicFormControlValueComponent,
      multi: true,
    },
  ],
})
export class DynamicFormControlValueComponent implements ControlValueAccessor, Validator {
  @Input() form?: FormGroup;
  // // @Input() validFrom?: Date;
  // // protoze je autosave tak se nezobrazi save a cance u kazde polozky ale jen souhrnne
  @Input() enable = {
    save: false,
    cancel: false,
    delete: false,
  };

  @Output() event = new EventEmitter();

  value: any;
  data: any;

  isInEdit = []; // je dany index contant v editacnim rezimu

  isDisabledValue = false;

  controls = [];

  keyFilter = '';
  isString = false;
  isNumber = false;
  isArray = false;
  isObject = false;
  isInvalid = false;
  exception: string;

  valueType: ValueTypes;
  get valueTypes() {
    return ValueTypes;
  }

  isArrayOrObject = false;
  // enable = {
  //   save: false,
  //   cancel: false,
  //   delete: false,
  // };

  constructor(private cdr: ChangeDetectorRef) {}

  onChangeValue = (value: any): void => {};
  onValidatorChange = (value?: any): void => {};
  isDisabled = (i) => this.isDisabledValue && !this.isInEdit[i];

  writeValue(value: any) {
    try {
      this.value = value;
      const v = this.value; //isString(this.value) ? JSON.parse(this.value) : this.value;
      this.data = v.value ? v : { value: v };
      this.isString = _.isString(v);
      this.isNumber = _.isNumber(v);
      this.isArray = _.isArray(v);
      this.isObject = !this.isArray && _.isObject(v);
      if (this.isString) this.valueType = ValueTypes.STRING;
      if (this.isNumber) this.valueType = ValueTypes.NUMBER;
      if (this.isArray) this.valueType = ValueTypes.ARRAY;
      if (this.isObject) this.valueType = ValueTypes.OBJECT;
    } catch (ex) {
      this.isInvalid = true;
      this.exception = ex.toString();
    }
    this.isArrayOrObject = this.isArray || this.isObject;

    this.controls = [];
    switch (this.valueType) {
      case ValueTypes.STRING:
        this.controls = [
          new DfcInput<string>({
            key: 'value',
            type: 'string',
            // get: (v) => (v.toString()),
            placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE.STRING',
            // value: this.value,
            order: 1,
            flex: '100',
            // hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE.STRING',
            validatorMessages: {
              // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
            },
            enable: {
              // add: true,
              // copy: true,
              // delete: true,
              save: false,
              cancel: false,
            },
          }),
        ];
        break;

      case ValueTypes.NUMBER:
        this.controls = [
          new DfcInput<number>({
            key: 'value',
            get: (v) => +v,
            type: 'number',
            placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE.NUMBER',
            // value: this.value,
            order: 1,
            flex: '100',
            // hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE.NUMBER',
            validatorMessages: {
              // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
            },
            enable: {
              // add: true,
              // copy: true,
              // delete: true,
              save: false,
              cancel: false,
            },
          }),
        ];
        break;

      case ValueTypes.ARRAY:
        this.controls = [
          new DfcKeys<any>({
            key: 'value',
            placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE.ARRAY',
            // value: this.value,
            order: 1,
            flex: '100',
            // hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE.ARRAY',
            validatorMessages: {
              // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
            },
            enable: {
              // add: true,
              // copy: true,
              delete: true,
              save: false,
              cancel: false,
            },
          }),
        ];
        break;
      case ValueTypes.OBJECT:
        this.controls = [
          new DfcKeys<any>({
            key: 'value',
            placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE.OBJECT',
            // value: this.value,
            order: 1,
            flex: '100',
            // hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE.OBJECT',
            validatorMessages: {
              // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
            },
            enable: {
              // add: true,
              // copy: true,
              delete: true,
              save: false,
              cancel: false,
            },
          }),
        ];
        break;
    }
    // this.change.emit(this.value);
    // this.controls = _.map(this.value, (keyvalue, i) => {
    //   return [
    //     new DfcValue<KeyValue>({
    //       key: 'value',
    //       placeholder: 'PLACEHOLDER.CONFIGURATION.SETTINGS.VALUE',
    //       value: value,
    //       order: 1,
    //       flex: '100',
    //       hint: 'HINT.LOCALITY.CONFIGURATION.SETTINGS.VALUE',
    //       validatorMessages: {
    //         // custom: 'VALIDATION.LOCALITY.PARAMS.CONVERSION.FILL_ALL'
    //       },
    //       enable: {
    //         add: true,
    //         copy: true,
    //         delete: true,
    //       }
    //     }),
    //   ];
    // });
    this.event.emit(this.value);
    this.cdr.markForCheck();
  }

  onChange(x?) {
    this.onChangeValue(this.value);
    this.onValidatorChange(this.value);
  }

  registerOnChange(fn) {
    this.onChangeValue = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabledValue = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isValid = true;
    // const isValid = _.reduce(control.value, (isValid, value, id) => {
    //   return _.reduce(value.data, (isValid, value, deviceType) => {
    //     return deviceType !== '' && _.reduce(value, (isValid, value, deviceId) => {
    //       return deviceId !== '' && _.reduce(value, (isValid, value, indexOrName) => {
    //         return indexOrName !== '' && _.reduce(value, (isValid, value, key) => {
    //           return key !== '' && !isNaN(value);
    //         }, isValid);
    //       }, isValid);
    //     }, isValid);
    //   }, isValid);
    // }, true);

    // // this.onValidatorChange();
    return isValid ? null : { custom: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  // changeValidFrom(event, i, validFromOld) {
  //   const validFromNew = event.value;
  //   let x = _.cloneDeep(this.value);
  //   x[i].validFrom = moment(validFromNew).toDate();
  //   this.writeValue(x);
  //   return this.onChange();
  // }

  onSelect(keyvalue: KeyValue) {}

  onSave(event) {
    // let x;
    // if (this.isString) {
    //   x = JSON.stringify(event.value);
    // }
    // if (this.isNumber) {
    //   x = event.value;
    // }
    // // let x = _.cloneDeep(this.value);
    // // x.value = event.value;
    this.writeValue(event.value);
    return this.onChange();
  }

  onModify(event) {
    // let x = _.cloneDeep(this.value);
    // x[i] = { ...x[i], ...event };
    // this.onChangeValue(x);
  }

  // onCopy(i) {
  //   const today = moment().startOf('day');
  //   let x = _.cloneDeep(this.value);
  //   let xx = _.cloneDeep(this.value[i]);
  //   xx.id = 'NEW';
  //   xx.validFrom = today.toDate();
  //   x.push(xx)
  //   this.writeValue(x);
  //   return this.onChange();
  // }

  onDelete(event) {}

  // onCopyToLocality(i) {
  //   let x = _.cloneDeep(this.value);

  //   this.event.emit(this.value[i]);
  //   // x.splice(i, 1)
  //   // this.writeValue(x);
  //   // this.onChange();
  // }

  applyFilter(filterValue: string) {
    this.keyFilter = filterValue.trim().toLowerCase();
  }

  onEvent(event) {}

  onChangeValueType(event) {
    let value;
    switch (this.valueType) {
      case ValueTypes.STRING:
        value = this.value ? this.value.toString() : '';
        break;
      case ValueTypes.NUMBER:
        value = parseInt(this.value, 10);
        break;
      case ValueTypes.ARRAY:
        value = [this.value];
        break;
      case ValueTypes.OBJECT:
        value = { NEW: this.value };
        break;
    }
    this.writeValue(value);
    this.onChange();
  }

  onAdd(event) {
    if (this.isArray) {
      this.value.push(null);
    }
    if (this.isObject) {
      this.value['NEW'] = null;
    }
    this.writeValue(this.value);
    return this.onChange();
  }
}
