// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCp0wzUmfT_I6QJ_yGZPeNpsCKPmX2Wx0s',
    authDomain: 'varistar-management.firebaseapp.com',
    databaseURL: 'https://varistar-management.firebaseio.com',
    projectId: 'varistar-management',
    storageBucket: 'varistar-management.appspot.com',
    messagingSenderId: '822004123111',
    appId: '1:822004123111:web:f646f31bc5815a1d202942',
    measurementId: 'G-SZ0DEE9HPT',
  },
  feature: {
    /**
     * root path of the web api.  may also include protocol, domain, and port
     * for remote api, e.g.: `'https://api-domain.com:8000/api/v1'` (default: 'api')
     */
    root: 'api',
    /**
     * Known entity HttpResourceUrls.
     * HttpUrlGenerator will create these URLs for entity types not listed here.
     */
    // entityHttpResourceUrls?: EntityHttpResourceUrls;
    /** Is a DELETE 404 really OK? (default: true) */
    // delete404OK?: boolean;
    /** Simulate GET latency in a demo (default: 0) */
    // getDelay?: number;
    /** Simulate save method (PUT/POST/DELETE) latency in a demo (default: 0) */
    // saveDelay?: number;
    /** request timeout in MS (default: 0)*/
    timeout: 0,
  },
  varistar: {
    apiUrl: '/',
    // apiUrl: 'http://localhost:3333',
    // apiUrl: 'http://localhost:5000/varistar-management/us-central1',
    apiPath: {
      // onboarding
      ONBOARDING_UPDATE_HUBSPOT_EMAIL: ['api', 'onboarding', 'update-hubspot-email'],
      ONBOARDING_PREPARE_PERSON_LOGIN: ['api', 'onboarding', 'prepare-person-login'],
      ONBOARDING_ACTIVATE_PERSON_LOGIN: ['api', 'onboarding', 'activate-person-login'],
      ONBOARDING_RESET_PASSWORD: ['api', 'onboarding', 'reset-password'],
      // authorization
      AUTHORIZATION_GET_VARISTAR_PROFILE: ['api', 'authorization', 'varistar-profile'],
      AUTHORIZATION_TO_JOHN_DEERE_PORTAL_AND_GET_TERMINALS: ['api', 'authorization', 'john-deere'],
      // utility
      UTILITY_SEND_EMAIL: ['api', 'utility', 'send-email'],
      MAP_DOCUMENT_GET_MAP_DOCUMENT: ['api', 'map-document', 'download-map-document'],
      UTILITY_SEND_MAP_DOCUMENT_TO_EMAIL: ['api', 'map-document', 'send-map-document-to-email'],

      // airflow
      AIRFLOW_GET_DAG_RUN_STATUS: ['api', 'airflow', 'dag-run-status'],
      AIRFLOW_AWAIT_DAG_RUN: ['api', 'airflow', 'await-dag-run'],
      AIRFLOW_ACTIVATE_MAP: ['api', 'airflow', 'dag-activate-map'],
      AIRFLOW_DEACTIVATE_MAP: ['api', 'airflow', 'dag-deactivate-map'],
      AIRFLOW_DEACTIVATE_MAP_BY_ORDER: ['api', 'airflow', 'dag-deactivate-map-by-order'],

      // users
      USERS_DASHBOARD: ['api', 'users', 'dashboard'],
      HOLDING_USERS: ['api', 'holdings', 'holding-users', 'holdingId'],
      USER_CUSTOMERS: ['api', 'customers', 'user-customers', 'idOsoby'],
      ALL_CUSTOMERS: ['api', 'customers', 'all-customers'],
      CUSTOMER_USERS_FOR_PROTOCOL: ['api', 'customers', 'customer-users-for-protocol', 'idZakaznik'],
      // ONBOARDING_PERSONS: ['api', 'customers', 'onboarding-persons', 'idOsoby'],
      CUSTOMER_USERS: ['api', 'customers', 'customer-users', 'idZakaznik'],
      ACTIVE_CUSTOMERS: ['api', 'customers', 'active-customers'],

      // dashboard
      DASHBOARD_DATA: ['api', 'dashboard', 'data'],

      //
      GET_TERMINAL_OWNER_NAME: ['api', 'terminals', 'terminal-owner-name'],
      ORDER_CREATE: ['api', 'application', 'order'],
      ORDER_GET: ['api', 'application', 'order', 'idOrder'],
      ORDERS_GET: ['api', 'application', 'open-orders', 'idAppendix'],

      // CR
      LPIS_COMPANY: ['api', 'lpis', 'company', 'name'], // Vyhledá ve Varistar IS MySQL databázi LPIS ČR podnik podle jména
      LPIS_COMPANIES: ['api', 'lpis', 'lpisId', 'companies'], // TODO
      LPIS_CR_OWNERS: ['api', 'lpis', 'owners', 'idLpis'], // Vyhledá majitele ve Varistar IS MySQL databázi LPIS select podle 40 mistneho id
      LPIS_CR_FIELDS: ['api', 'lpis', 'fields', 'idLpis', 'idOwner'], // Vyhledá pole jednoho majitele ve Varistar IS MySQL databázi LPIS select podle 40 mistneho id

      // SK
      LPIS_SK_LOCALITY: ['api', 'lpis', 'locality', 'name'], // Vyhledá ve Varistar IS MySQL databázi LPIS SK lokalitu podle jména

      SATELLITE_IMAGES: ['api', 'composite', 'images'],
      COMPOSITE_MAP: ['api', 'composite', 'map'],
      COMPOSITE_POINT: ['api', 'composite', 'point'],
      COMPOSITE_DOWNLOAD: ['api', 'composite', 'download'],
      SMART_SCOUTING_MAP: ['api', 'smart-scouting', 'map'],
      SMART_SCOUTING_DOWNLOAD: ['api', 'smart-scouting', 'download'],
      ASSET_MAP: ['api', 'asset', 'map'],
      ASSET_RVP: ['api', 'asset', 'rvp'],
      // COMPOSITE_BUCKET_TASK: ['api', 'composite', 'bucket'],

      COMPOSITE: ['api', 'composite'],
      RESOURCE: ['api', 'resource', 'resource.id'],
      GEOJSON: ['api', 'resource', 'resource.id', 'geojson'],
      MAP: ['api', 'resource', 'resource.id', 'map'],
      APPLIED_MAP: ['api', 'tablet-applied', 'geojson'],
      IS_APPLIED_MAP: ['api', 'tablet-applied', 'is-tablet-applied'],
      LAST_ANNEX_FIELD_LIST: ['api', 'fields', 'last-annex-field-list'],
      PLANT_ID: ['api', 'plants', 'plant-id'],
      MAP_PORTAL_FIELD_LIST: ['api', 'fields', 'map-portal-field-list'],
      SEASON_OPTION_LIST: ['api', 'utility', 'season-option-list'],
      MAP_LAYERS: ['api', 'utility', 'map-layers'],
      DEM_LAYERS: ['api', 'utility', 'dem-layer'],
      MAP_PORTAL_SCHEMA_OPTION_MAP: ['api', 'utility', 'map-portal-schema-option-map'],
      MAP_PORTAL_APP_MAPS_OPTION_MAP: ['api', 'utility', 'map-portal-app-maps-option-map'],
      BULK_EXISTS_APPLIED_MAP: ['api', 'utility', 'bulk-exists-applied-map'],
      BULK_GET_PLANET_API_KEY_AOI: ['api', 'annexes', 'bulk-get-planet-api-key-aoi'],
      EXISTS_APPLIED_MAP: ['api', 'utility', 'exists-applied-map'],
      MAP_PORTAL_SOIL_COLLECTED_POINTS: ['api', 'utility', 'map-portal-soil-collected-points'],
      MAP_PORTAL_SOIL_PLANNED_POINTS: ['api', 'utility', 'map-portal-soil-planned-points'],
      PUDNI_ROZBORY_OPTION_LIST: ['api', 'utility', 'pudni-rozbory-option-list'],
      FIELDS_WITH_MAP: ['api', 'fields', 'fields-with-map'],
      FIELDS_FOR_RVP: ['api', 'fields', 'fields-for-rvp'],
      IS_GRAIN: ['api', 'orders', 'is-grain'],
      NEW_CISLO_TERMINALU: ['api', 'terminals', 'new-cislo-terminalu'],
      PESTICIDE_TYPE: ['api', 'pesticides', 'pesticide-type'],
      LAST_ANNEX: ['api', 'annexes', 'last-annex'],
      ANNEXES_FOR_CUSTOMER: ['api', 'annexes', 'annexes-by-customer'],
      REPLACE_DEPARTMENT: ['api', 'departments', 'replace-department'],
      CONTRACT_BY_CUSTOMER_ID: ['api', 'contracts', 'contract-by-customer-id', 'customerId'],
      CONTRACT_SET_RYP_MAP_SENSITIVITY: ['api', 'contracts', 'set-ryp-map-sensitivity'],
      SET_RYP_BUFFER_BY_ANNEX: ['api', 'fields', 'set-ryp-buffer-by-annex'],
      SET_RYP_MAP_SENSITIVITY_TO_FID: ['api', 'contracts', 'set-ryp-map-sensitivity-for-fid'],
      SET_RYP_BUFFER_TO_FID: ['api', 'contracts', 'set-ryp-buffer-for-fid'],
      ORDERS_BY_CUSTOMER_ID: ['api', 'orders'],
      MODIFY_LPIS_FIELDS_END: ['api', 'lpis', 'modify-fields-end'],
      MODIFY_LPIS_FIELDS_END_SK: ['api', 'lpis-sk-select', 'modify-fields-end'],
      CUSTOMER_TERMINALS: ['api', 'customers', 'customer-terminals'],
      ASSET: ['api', 'resource', 'resource.id', 'asset'],
      ANNEXES_WITH_FIELDS_FOR_SEASON: ['api', 'annexes', 'annexes-with-fields-for-season'],
      ANNEXES_WITH_FIELDS: ['api', 'annexes', 'annexes-with-fields'],
      ANNEXES_WITH_FIELDS_ARCHIVE: ['api', 'annexes', 'annexes-with-fields-archive'],
      SOIL_PROTOCOL_DATA: ['api', 'contracts', 'protocol-data'],
      LPIS_SK_NAME_OPTION_LIST: ['api', 'lpis-sk-pole', 'name-option-list'],
      LPIS_SK_KULTURA_OPTION_LIST: ['api', 'lpis-sk-pole', 'kultura-option-list'],
      LPIS_SK_SAVE: ['api', 'lpis-sk-pole', 'save'],
      LPIS_SK_CUSTOMER_NAME: ['api', 'lpis-sk-select', 'customer-name'],
      LPIS_END_SEND_EMAIL_TO_DEALER: ['api', 'fields-select', 'lpis-end-send-email-to-dealer'],
      LPIS_SK_END_SEND_EMAIL_TO_DEALER: [
        'api',
        'fields-select',
        'lpis-sk-end-send-email-to-dealer',
      ],
      SEND_TEST_MAP_TO_TERMINAL: ['api', 'utility', 'send-test-map-to-terminal'],
      SEND_TEST_MAP_TO_TABLET: ['api', 'utility', 'send-test-map-to-tablet'],
      UPDATE_TERMINALS_IN_DATABASE: ['api', 'utility', 'update-terminals'],
      SEND_ZIP_MAP_TO_TERMINAL: ['api', 'utility', 'send-zip-map-to-terminal'],
      MAINTENANCE_PAGE: ['api', 'utility', 'maintenance-page'],
      UPDATE_FERTILIZER_USABILITY_GLOBALLY: [
        'api',
        'fertilizers',
        'update-fertilizer-usability-globally',
      ],
      SEND_ERROR_TO_SUPPORT: ['api', 'public', 'send-error-to-support'],
      UPDATE_AVERAGE_YIELD: ['api', 'fields', 'update-average-yield'],
      SET_ORDER_NUMBER: ['api', 'orders', 'set-order-number', 'orderId'],
      REGISTER_UNIFORM_FIELDS: ['api', 'utility', 'register-uniform-fields'],
      LPIS_FIELD_MAP: ['api', 'public', 'lpis-field-map'],
      FIELD_SEASON_OPTION_LIST: ['api', 'fields', 'field-season-option-list'],
      HISTORICAL_YIELD_LAST_UPDATE: ['api', 'contracts', 'historical-yield-last-update'],
      CREATE_FIELDS_LPIS_SK: ['api', 'public', 'create-fields-lpis-sk'],
      DELETE_FIELDS_LPIS_SK: ['api', 'public', 'delete-fields-lpis-sk'],
      BULK_CALCULATE_AOI: ['api', 'fields', 'bulk-calculate-aoi'],
      GENERATE_ORDER_GRANT_REPORT: ['api', 'utility', 'generate-order-grant-report'],
      FIND_RYP_EVI: ['api', 'utility', 'find-ryp-evi'],
      FIND_RYP_CLIP: ['api', 'utility', 'find-ryp-clip'],
      FIND_TABLES: ['api', 'utility', 'find-tables'],
      READ_AS_PNG: ['api', 'utility', 'read-as-png'],
      READ_RYP_CLIP: ['api', 'utility', 'read-ryp-clip'],
      SAVE_EDITED_MAP: ['api', 'utility', 'save-edited-map'],

      RYP_MAPS_PROCESSED_STATE: ['api', 'utility', 'ryp-maps-processed-state'],
      COMBINE_DEM_RYP_MAPS: ['api', 'utility', 'combine-dem-ryp-maps'],

      BULK_GET_PLANET_API_KEY_CUMULATIVE_QUOTE_AREA: [
        'api',
        'planet-api-key',
        'bulk-get-planet-api-key-cumulative-quote-area',
      ],

      //permissions
      CAN_HAVE_RIGHTS_TO_ADMIN_SECTION: ['api', 'permissions', 'can-have-rights-to-admin-section'],

      // varistar-open-balena
      OPENBALENA_FLEETS: ['api', 'open-balena', 'fleet'],
      OPENBALENA_FLEET: ['api', 'open-balena', 'fleet', 'fleetName'],
      OPENBALENA_FLEET_DEVICES: ['api', 'open-balena', 'fleet', 'fleetName', 'devices'],
      OPENBALENA_FLEET_DEVICES_DETAILS: [
        'api',
        'open-balena',
        'fleet',
        'fleetName',
        'devices-details',
      ],
      OPENBALENA_DEVICE_DETAILS: ['api', 'open-balena', 'device', 'uuid', 'details'],
      OPENBALENA_DEVICE_LOGS: ['api', 'open-balena', 'device', 'uuid', 'logs'],
      OPENBALENA_DEVICE_SYSTEM_LOG: ['api', 'open-balena', 'device', 'uuid', 'system-log'],
      OPENBALENA_DEVICE_RENAME: ['api', 'open-balena', 'device', 'uuid', 'rename'],
      OPENBALENA_DEVICE_VARS: ['api', 'open-balena', 'device', 'uuid', 'vars'],
      OPENBALENA_DEVICE_ENV_VAR: ['api', 'open-balena', 'device', 'uuid', 'env-var', 'key'],
      OPENBALENA_DEVICE_TAGS: ['api', 'open-balena', 'device', 'uuid', 'tags'],
      OPENBALENA_DEVICE_LOCAL_MODE: ['api', 'open-balena', 'device', 'uuid', 'local-mode'],
      OPENBALENA_DEVICE_MANAGE_SERVICE: [
        'api',
        'open-balena',
        'device',
        'uuid',
        'manage-service',
        'serviceName',
      ],
      OPENBALENA_DEVICE_ACTION: ['api', 'open-balena', 'device', 'uuid', 'action'],

      // tile server
      TILE_SERVER_GET_POINT: ['api', 'tile', 'point'],
      TILE_SERVER_GET_URL: ['api', 'tile', 'data'],

      // soil portal
      SOIL_PORTAL_GET_POINTS: ['api', 'utility', 'soil-portal-get-points'],
      SOIL_PORTAL_CREATE_MAP: ['api', 'utility', 'soil-portal-create-map'],
      SOIL_PORTAL_DELETE_MAP: ['api', 'utility', 'soil-portal-delete-map', 'mapName'],
      SOIL_PORTAL_UPDATE_MAP: ['api', 'utility', 'soil-portal-update-map', 'mapName'],

      // map operations
      ENABLE_PLANET_SUBSCRIPTION: ['api', 'utility', 'enable-planet-subscription'],
      UPDATE_PLANET_FIELD_SUBSCRIPTION: ['api', 'utility', 'update-planet-field-subscription'],
      ANNEXES_WITHOUT_PLANET_API_KEY: ['api', 'utility', 'annexes-without-planet-api-key'],
      PLANET_API_KEY_ANNEXES_ID_LIST: ['api', 'annexes', 'planet-api-key-annexes-id-list'],
      UPDATE_FIELDS_IN_ORDER: ['api', 'application', 'update-fields-in-order', 'orderId'],
      APPLICATION_CREATE_MAP: ['api', 'application', 'create-map', 'order.idZakazky'],
      APPLICATION_CREATE_APP_MAP: ['api', 'application', 'create-app-map', 'order.idZakazky'],
      APPLICATION_COUNT_RATE: ['api', 'application', 'count-rate', 'order.idZakazky'],
      APPLICATION_UPDATE_ORDER_MAP_COEFFICIENT: [
        'api',
        'application',
        'update-order-map-coefficient',
        'order.idZakazky',
      ],
      APPLICATION_UPDATE_RATE_IN_POLYGON: [
        'api',
        'application',
        'update-rate-in-polygon',
        'order.idZakazky',
      ],
      APPLICATION_UPDATE_RATE: ['api', 'application', 'update-rate', 'order.idZakazky'],
      APPLICATION_READ_MAP: ['api', 'application', 'read-map', 'order.idZakazky'],
      GET_APPLIED_MAP: ['api', 'application', 'get-applied-map'],
      APPLICATION_GET_MAP: ['api', 'application', 'get-map'],

      //varistar planet gateway api
      PLANET_GATEWAY_ORDER_STATUS: [
        'api',
        'varistar-planet-gateway-api',
        'get-ryp-maps-order-status',
      ],
      PLANET_GATEWAY_INIT_RYP_MAPS: ['api', 'varistar-planet-gateway-api', 'initialize-ryp-maps'],

      // simplification
      APPLICATION_SEND_MAP_TO_TERMINALS: [
        'api',
        'application',
        'send-map-to-terminals',
        'order.idZakazky',
      ],
      // map source
      APPLICATION_DOWNLOAD_MAP_STATS: [
        'api',
        'application',
        'download-map',
        'order.idZakazky',
        'stats',
      ],
      APPLICATION_DOWNLOAD_MAP_SHAPE_ZIP: [
        'api',
        'application',
        'download-map',
        'order.idZakazky',
        'shape.zip',
      ],
      APPLICATION_DOWNLOAD_MAP_SHAPE_FILES: [
        'api',
        'application',
        'download-map',
        'order.idZakazky',
        'shape',
      ],
      APPLICATION_DOWNLOAD_MAP_GEOJSON: [
        'api',
        'application',
        'download-map',
        'order.idZakazky',
        'geojson',
      ],
      UPLOAD_POSTGIS_MAP: ['api', 'application', 'upload-postgis-map'],

      REGEN_RYP_MAPS_FROM_POSTGIS: ['api', 'utility', 'regen-ryp-maps-from-postgis'],
      RYP_EVI_DROP_TABLE: ['api', 'utility', 'ryp-evi-drop-table'],
      DELETE_RIP_CLIP: ['api', 'utility', 'delete-ryp-clip'],
      COPY_RIP_CLIP_TABLE_FROM_POSTGIS: ['api', 'utility', 'copy-rip-clip-table-from-postgis'],
      POSTGIS_DEM: ['api', 'utility', 'postgis-dem'],
    },
    apiTimeout: 300 * 1000,
    headers: {
      request: {
        // { 'Content-Type': 'application/json', ... }
      },
      authToken: 'X-Varistar-Auth-Token',
    },
    onboardingEmailTokenSecret: 'VaRiStAr', // nutno stejne jako u backendu
    dags: {
      JD_LOGIN_TASK: 'TEST_call_jd_login_task',
    },
    varistarJohnDeereLogoutUrl:
      'https://persistent-header.deere.com/logout?target=https://connections.deere.com/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
