import { TTabletProtocol } from './entities/TTabletProtocol';

// tslint:disable-next-line: no-empty-interface
export interface TabletProtocol extends TTabletProtocol {}

export const TabletProtocol = {
  selectId: (a: TTabletProtocol) => {
    //In this case this would be optional since primary key is id
    return a.idTabletProtokol;
  },
};
