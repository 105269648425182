import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { getPlantName, Order } from '@varistar-apps/shared/data';

@Pipe({
  name: 'toKmPerH',
})
export class ToKmPerHPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(speedInMPerSec: number) {
    return (speedInMPerSec * 3.6).toFixed(1);
  }
}
