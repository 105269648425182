import { TZakaznikOsobyRole } from './entities/TZakaznikOsobyRole';

// tslint:disable-next-line: no-empty-interface
export interface CustomerPersonRole extends TZakaznikOsobyRole {}

export const CustomerPersonRole = {
  selectId: (a: TZakaznikOsobyRole) => {
    return a.idtZakaznikOsobyRole;
  },
};
