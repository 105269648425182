import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.history.push(this.router.url);
      }
    });
  }

  back(): void {
    const record = this.history.pop();

    // prevent redirect to same url
    if (this.router.url === record) {
      this.back();
      return;
    }

    // history exists - redirect back via angular router
    if (this.history.length > 0) {
      this.router.navigateByUrl(record);
    } else {
      // redirect back via window.history
      this.location.back();
    }
  }
}
