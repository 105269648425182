import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { TSmlouvy } from './TSmlouvy';
import { TZakazky } from './TZakazky';

// import { TBonitaceDodatek } from "./TBonitaceDodatek";
// import { TFakZalohove } from "./TFakZalohove";
// import { TFields } from "./TFields";
// import { TZalohaPouzita } from "./TZalohaPouzita";

@Index('cislo_dodatku', ['cisloDodatku'], {})
@Index('id_dodatek_UNIQUE', ['idDodatek'], { unique: true })
@Index('id_smlouvy_idx', ['idSmlouvy'], {})
@Index('platny_od', ['platnyOd'], {})
@Entity('t_dodatky', { schema: 'VaristarIS' })
export class TDodatky {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_dodatek' })
  idDodatek: number;

  @Column('int', {
    name: 'id_smlouvy',
    nullable: true,
    comment: 'Slouží k připárování dodatku ke smllouvě.',
  })
  idSmlouvy: number | null;

  @Column('date', {
    name: 'platny_od',
    nullable: true,
    comment: 'Datum od kterého je dodatek platný. ',
  })
  platnyOd: string | null;

  @Column('decimal', {
    name: 'delka_trvani',
    nullable: true,
    comment: 'Délka trvnání dodatku v letech. ',
    precision: 6,
    scale: 2,
  })
  delkaTrvani: string | null;

  @Column('int', {
    name: 'cislo_dodatku',
    nullable: true,
    comment: 'Označení o kolikátý jde dodatek v řadě. ',
  })
  cisloDodatku: number | null;

  @Column('decimal', {
    name: 'cena_1_aplikace',
    nullable: true,
    comment: 'Cena za 1. apliakce',
    precision: 9,
    scale: 2,
  })
  cena_1Aplikace: string | null;

  @Column('decimal', {
    name: 'cena_2_aplikace',
    nullable: true,
    comment: 'Cena za 2. aplikaci',
    precision: 9,
    scale: 2,
  })
  cena_2Aplikace: string | null;

  @Column('decimal', {
    name: 'cena_3_aplikace',
    nullable: true,
    comment: 'Cena za 3. aplikaci',
    precision: 9,
    scale: 2,
  })
  cena_3Aplikace: string | null;

  @Column('decimal', {
    name: 'vymera',
    nullable: true,
    comment: 'Výměra na které by měli být provedeni variabilní aplikace.',
    precision: 9,
    scale: 2,
    default: () => "'0.00'",
  })
  vymera: string | null;

  @Column('varchar', {
    name: 'id_LPIS_select',
    nullable: true,
    comment:
      'Unikátní ID, které je generované systémem VaristarIS - toto ID je součástí hyperlinku, který dostane klient Varistar a jednoznačně identifikuje pole, z kterých má vybírat hony pro smlouvu.',
    length: 45,
  })
  idLpisSelect: string | null;

  @Column('date', {
    name: 'date_LPIS_select',
    nullable: true,
    comment: 'Datup provení LPIS select. ',
  })
  dateLpisSelect: string | null;

  @Column('date', { name: '10', nullable: true, comment: 'Dodatek podepsán\n' })
  10: string | null;

  @Column('date', {
    name: '20',
    nullable: true,
    comment: 'Zřízen přístup do systému, zákazník požádán o vyplnění historických výnosů\n',
  })
  20: string | null;

  @Column('date', {
    name: '30',
    nullable: true,
    comment: 'Vydefinování nových honů a zaslání Vojtovi Lukasovi (OZ)\n',
  })
  30: string | null;

  @Column('date', {
    name: '40',
    nullable: true,
    comment: 'Vyplnění historických výnosů pro nová pole (OZ)\n',
  })
  40: string | null;

  @Column('date', {
    name: '50',
    nullable: true,
    comment: 'Validace map od Vojty Lukase (OZ)\n',
  })
  50: string | null;

  @Column('date', {
    name: '60',
    nullable: true,
    comment: 'Zpracování validací (OZ+VL)\n',
  })
  60: string | null;

  @Column('date', {
    name: '70',
    nullable: true,
    comment: 'Zavedení zákazníka do systému (MS+JŠ)\n',
  })
  70: string | null;

  @Column('date', { name: '75', nullable: true })
  75: string | null;

  @Column('date', { name: '80', nullable: true, comment: 'Vše dokončeno\n' })
  80: string | null;

  @Column('varchar', {
    name: 'poznamka',
    nullable: true,
    comment: 'poznámky k zavádění dodatku',
    length: 2000,
  })
  poznamka: string | null;

  @Column('tinyint', {
    name: 'flatRate',
    nullable: true,
    comment:
      'Určuje je-li dodatek jako flat rate - fixní cena za hektar a rok bez ohledu na počet aplikací.',
    default: () => "'0'",
  })
  flatRate: number | null;

  @Column('tinyint', {
    name: 'enable_planet',
    nullable: true,
    width: 1,
    default: () => "'0'",
  })
  enablePlanet: boolean | null;

  @Column('tinyint', {
    name: 'enable_planet_uniform',
    nullable: true,
    width: 1,
    default: () => "'0'",
  })
  enablePlanetUniform: boolean | null;

  @Column('tinyint', {
    name: 'enable_dynacrop',
    nullable: true,
    width: 1,
    default: () => "'0'",
  })
  enableDynacrop: boolean | null;

  @Column('tinyint', {
    name: 'enable_dynacrop_uniform',
    nullable: true,
    width: 1,
    default: () => "'0'",
  })
  enableDynacropUniform: boolean | null;

  @Column('int', {
    name: 'yp_maps_source',
    nullable: true,
    comment:
      'Zdroj z kterého jsou vytvářené mapy relativního výnosového potenciálu: 0 - nevytváří se, 1 - Dynacrop (Sentinel 2), 2 - Planet.com (PlanetScope)',
    default: () => "'0'",
  })
  ypMapsSource: number | null;

  @Column('tinyint', {
    name: 'is_activated',
    nullable: true,
    comment: 'Příznak, že dodatek je kompletní a zavedený a může jej systém/uživatel používat.',
    width: 1,
    default: () => "'0'",
  })
  isActivated: boolean | null;

  @Column('decimal', { name: 'aoi', nullable: true, precision: 12, scale: 2 })
  aoi: string | null;

  // @OneToMany(
  //   () => TBonitaceDodatek,
  //   (tBonitaceDodatek) => tBonitaceDodatek.idtDodatek2
  // )
  // tBonitaceDodateks: TBonitaceDodatek[];

  // @ManyToOne(() => TSmlouvy, (tSmlouvy) => tSmlouvy.tDodatkies, {
  //   onDelete: "CASCADE",
  //   onUpdate: "CASCADE",
  // })
  @ManyToOne('TSmlouvy', 'tDodatkies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idSmlouvy2: TSmlouvy;

  // @OneToMany(() => TFakZalohove, (tFakZalohove) => tFakZalohove.idDodatek2)
  // tFakZalohoves: TFakZalohove[];

  // @OneToMany(() => TFields, (tFields) => tFields.idtDodatek2)
  // tFields: TFields[];

  // @OneToMany(() => TZakazky, (tZakazky) => tZakazky.idDodatek2)
  @OneToMany('TZakazky', 'idDodatek2')
  tZakazkies: TZakazky[];

  // @OneToMany(
  //   () => TZalohaPouzita,
  //   (tZalohaPouzita) => tZalohaPouzita.idDodatek2
  // )
  // tZalohaPouzitas: TZalohaPouzita[];
}
