import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'lodash';

@Pipe({
  name: 'numberCustom',
})
export class PipeDecimalCustomPipe implements PipeTransform {
  transform(val: string | number, format: string, localization: string) {
    if (val === null || (typeof val === 'number' && isNaN(val)) || typeof val === 'undefined') {
      return null;
    } else if (!val || !Math.ceil(Math.abs(+val))) {
      return `${val}`.replace('.', ',');
    }

    const isFloat = format.slice(-1) !== '0';

    const transformed = this.decimalPipe.transform(+val, format, localization).replace('.', ',');

    if (!isFloat) {
      return transformed;
    }

    let padded = transformed;
    const startIndex = format.indexOf('.');
    let padCount =
      +format.slice(startIndex, startIndex + 1) -
      transformed.slice(transformed.indexOf(',') + 1).length;

    if (padCount && !padded.includes(',')) {
      padded += ',';
      padCount = +format.slice(-1);
    }

    for (let x = 0; x < padCount; x++) {
      padded += '0';
    }

    return padded;
  }

  constructor(private decimalPipe: DecimalPipe) {}
}
