import { Column, Entity } from 'typeorm';

@Entity('t_resources', { schema: 'VaristarIS' })
export class TResources {
  @Column('int', {
    primary: true,
    name: 'idt_resources',
    comment: 'Dle webmap_id',
  })
  idtResources: number;

  @Column('varchar', {
    name: 'contract',
    nullable: true,
    comment: 'Číslo kontraktu, vazba na zákazníka',
    length: 7,
  })
  contract: string | null;

  @Column('int', { name: 'webmap_id', nullable: true, comment: 'Id mapy' })
  webmapId: number | null;

  @Column('varchar', {
    name: 'asset',
    nullable: true,
    comment: 'Cesta na  resource v GEE s CLIP ořezem honu',
    length: 255,
  })
  asset: string | null;

  @Column('varchar', {
    name: 'rvp',
    nullable: true,
    comment: 'Cesta na  resource v GEE s RVP na honu',
    length: 255,
  })
  rvp: string | null;

  @Column('varchar', {
    name: 'name',
    nullable: true,
    comment: 'Jméno zákazníka',
    length: 255,
  })
  name: string | null;

  @Column('int', { name: 'client_id', nullable: true, comment: 'Id zákazníka' })
  clientId: number | null;
}
