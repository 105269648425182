import { TOpravneni } from './entities/TOpravneni';

// tslint:disable-next-line: no-empty-interface
export interface Permission extends TOpravneni {
  settings: {
    section: string;
    tab: string;
    permission: string;
  };
}

export const Permission = {
  selectId: (a: Permission) => {
    return a.idOpravneni;
  },
};
