import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ConfigurationState } from '@varistar-apps/frontend/feature';
import { DeleteDialogComponent } from '@varistar-apps/frontend/ui';
import { Observable, Subscription } from 'rxjs';

import {
  FeatureConfiguration,
  FeatureConfigurationService,
} from '../feature-configuration/feature-configuration.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-feature-configuration-form',
  templateUrl: './feature-configuration-form.component.html',
  styleUrls: ['./feature-configuration-form.component.scss'],
})
export class FeatureConfigurationFormComponent implements OnInit, OnDestroy {
  @Input() feature: FeatureConfiguration;
  @Input() data$: Observable<any>;
  @Input() controls = [];
  @Input() resetWhenChanged: string;
  @Input() id = 'id'; // jaky atribut nese id, casto stejne resetWhenChanged
  @Input() enable = {
    header: true,
    delete: true,
  };
  @Input() header = '';
  @Input() description = '';
  @Input() ids: any = {}; // objekt s kluci pouzivanymi pri Firestore.getPath
  @Input() title = '';

  @Output() delete = new EventEmitter();

  private data: any;
  private dataSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private store: Store<ConfigurationState>,
    private featureConfigurationService: FeatureConfigurationService<any>,
  ) {}

  ngOnInit() {
    if (!this.resetWhenChanged) this.resetWhenChanged = `${this.feature}.id`;
    this.dataSubscription = this.data$.pipe().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnDestroy() {
    if (this.dataSubscription && !this.dataSubscription.closed) this.dataSubscription.unsubscribe();
  }

  private getDataId() {
    const id = this.data && this.data[this.feature] && this.data[this.feature][this.id];
    return id;
  }

  async onSave(values: any) {
    const id = this.getDataId();
    if (id) {
      const modifiedData = {
        ...this.data[this.feature],
        ...values[this.feature],
      };
      this.featureConfigurationService.modify(this.feature, modifiedData, id, this.ids);
    } else {
      this.featureConfigurationService.add(this.feature, values[this.feature], this.ids);
    }
  }

  onDelete(values: any) {
    const id = this.getDataId();
    if (id) {
      this.openPersonDeleteDialog();
    }
  }

  openPersonDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        // title: String(this.feature).toLocaleUpperCase(),
        count: 1,
      },
    });

    dialogRef.afterClosed().subscribe((isYes) => {
      if (isYes) {
        const id = this.getDataId();
        this.featureConfigurationService.remove(this.feature, id, this.ids);
        this.delete.emit(id);
      }
    });
  }
}
