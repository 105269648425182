import { TStrediska } from './entities/TStrediska';

// tslint:disable-next-line: no-empty-interface
export interface Department extends TStrediska {}

export const Department = {
  selectId: (a: TStrediska) => {
    //In this case this would be optional since primary key is id
    return String(a.idStrediska);
  },
};
