import { Injectable } from '@angular/core';
import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class FrontendUiFeatureConfigurationPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);
    return (data: any) => {
      const action = actionHandler(data);

      if (action && data && data.selectedId) {
        (action as any).payload.selectedId = data.selectedId;
      }
      /// NOTE: uprava pro paginate nestjsx/crud formatu
      if (action && data && data.data && data.page && data.pageCount && data.total) {
        const actionData = actionHandler(data.data);
        (actionData as any).payload.page = data.page;
        (actionData as any).payload.pageCount = data.pageCount;
        (actionData as any).payload.total = data.total;
        (actionData as any).payload.count = data.count;
        return actionData;
      } else {
        return action;
      }
    };
  }
}

// @Injectable()
// export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
//   handleSuccess(originalAction: EntityAction): (data: any) => Action {
//     const actionHandler = super.handleSuccess(originalAction);
//     // return a factory to get a data handler to
//     // parse data from DataService and save to action.payload
//     return function (data: any) {
//       const action = actionHandler.call(this, data);
//       if (action && data && data.totalNumberOfEntities) {
//         // save the data.totalNumberOfEntities to action.payload.totalNumberOfEntities
//         (action as any).payload.totalNumberOfEntities =
//           data.totalNumberOfEntities;
//       }
//       if (action && data && data.entities) {
//         // save the data.entities to action.payload.data
//         (action as any).payload.data = data.entities;
//       }

//         `AdditionalPropertyPersistenceResultHandler::${JSON.stringify(action)}`
//       );
//       return action;
//     };
//   }
// }
// @Injectable()
// export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
//   handleSuccess(originalAction: EntityAction): (data: any) => Action {
//     const actionHandler = super.handleSuccess(originalAction);
//     // return a factory to get a data handler to
//     // parse data from DataService and save to action.payload
//     return function (data: any) {
//       const action = actionHandler.call(this, data);
//       if (action && data && data.pageCount) {
//         // save the data.pageCount to action.payload.pageCount
//         (action as any).payload.pageCount =
//           data.pageCount;
//       }
//       if (action && data && data.data) {
//         // save the data.entities to action.payload.data
//         (action as any).payload.data = data.data;
//       }

//       //   `AdditionalPropertyPersistenceResultHandler::${JSON.stringify(action)}`
//       // );
//       return action;
//     };
//   }
// }
