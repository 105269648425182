import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcCheckbox<T> extends DynamicFormControlBase<T> {
  controlType = 'checkbox';
  // type: string;

  constructor(options: {} = {}) {
    super(options);
    // this.type = options['type'] || '';
  }
}
