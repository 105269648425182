import { Directive, ElementRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[scroll-to-view]',
})
export class ScrollToViewDirective {
  @Input('scroll-to-view') set isScrollToView(isScrollToView) {
    if (isScrollToView) {
      this._el.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }

  constructor(private _el: ElementRef) {}
}
