import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TRole } from './TRole';
import { TZakaznikOsobyStrediska } from './TZakaznikOsobyStrediska';

@Index('t_zakaznik_osoby_role_UN', ['idZakaznikOsobyStrediskaRole'], {
  unique: true,
})
@Index('t_zakaznik_osoby_role_FK', ['idtZakaznikOsobyStrediska'], {})
@Index('t_zakaznik_osoby_role_FK_1', ['idtRole'], {})
@Entity('t_zakaznik_osoby_strediska_role', { schema: 'VaristarIS' })
export class TZakaznikOsobyStrediskaRole {
  @PrimaryGeneratedColumn({
    type: 'int',
    name: 'id_zakaznik_osoby_strediska_role',
    comment: 'ID - primární klíč',
  })
  idZakaznikOsobyStrediskaRole: number;

  @Column('int', { name: 'idt_zakaznik_osoby_strediska', nullable: true })
  idtZakaznikOsobyStrediska: number | null;

  @Column('int', { name: 'idt_role', nullable: true })
  idtRole: number | null;

  @ManyToOne('TRole', 'tZakaznikOsobyStrediskaRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_role', referencedColumnName: 'idRole' }])
  idtRole2: TRole;

  @ManyToOne('TZakaznikOsobyStrediska', 'tZakaznikOsobyStrediskaRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([
    {
      name: 'idt_zakaznik_osoby_strediska',
      referencedColumnName: 'idZakaznikOsobyStrediska',
    },
  ])
  idtZakaznikOsobyStrediska2: TZakaznikOsobyStrediska;
}
