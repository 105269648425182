import { ApplicationMapStats } from './application-map-stats.model';

export enum PostgisSchema {
  RVP = 'RVP',
  APP_MAPS = 'AppMaps',
}

export interface PostgisGatewayResponse {
  detail: string;
  geojson_data: any;
  statistics?: ApplicationMapStats;
}
