<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
  <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
  <span fxFlex translate [translateParams]="data">
    {{ data.message }}
  </span>
  <div class="mat-simple-snackbar-action" *ngIf="data.actionButtonList?.length">
    <button
      *ngFor="let action of data.actionButtonList"
      (click)="snackBarRef.dismiss(); action.cb()"
      mat-icon-button
      [disableRipple]="true"
      color="primary"
      [matTooltip]="action.tooltip || '' | translate"
      matTooltipPosition="above"
    >
      <mat-icon>{{ action.icon }}</mat-icon>
    </button>
  </div>
  <div class="mat-simple-snackbar-action" *ngIf="hasAction">
    <button mat-icon-button [disableRipple]="true" (click)="action()">
      <mat-icon>{{ data.action }}</mat-icon>
    </button>
  </div>
</div>
