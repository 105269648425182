import { DynamicFormControlBase } from './dynamic-form-control-base';
import { Observable, of } from 'rxjs';

export class DfcSelect<T> extends DynamicFormControlBase<T> {
  controlType = 'select';
  items: { name: string; value: any }[];
  items$: Observable<{ name: string; value: any }[]>;

  constructor(options: {} = {}) {
    super(options);
    this.items = options['items'] || [];
    const x = options['items$'];
    // this.items$ = options['items$'] ? options['items$'] : of(options['items']);
    this.items$ = options['items$'] || of(this.items);
  }
}
