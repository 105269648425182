import { Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { TVerze } from './TVerze';

@Index('id_obchodnik_UNIQUE', ['idObchodnik'], { unique: true })
@Entity('t_obchodnik', { schema: 'VaristarIS' })
export class TObchodnik {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_obchodnik' })
  idObchodnik: number;

  @Column('varchar', { name: 'jmeno_obchodnik', nullable: true, length: 100 })
  jmenoObchodnik: string | null;

  @Column('varchar', { name: 'telefon_obchodnik', nullable: true, length: 20 })
  telefonObchodnik: string | null;

  @Column('varchar', { name: 'email_obchodnik', nullable: true, length: 100 })
  emailObchodnik: string | null;

  @Column('int', {
    name: 'hs_obchodnik_id',
    nullable: true,
    comment: 'Slouží k propojení tabulky s CRM HubSpot.',
  })
  hsObchodnikId: number | null;

  @Column('varchar', {
    name: 'role',
    nullable: true,
    comment: 'Definuje oprávnění obchodníka, neboli uživatele, ve Varistar IS.',
    length: 5,
  })
  role: string | null;

  @Column('varchar', { name: 'zeme', nullable: true, length: 45 })
  zeme: string | null;

  @OneToMany('TVerze', 'idObchodnik2')
  tVerzes: TVerze[];
}
