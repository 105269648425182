import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TPlodinyEagri } from './TPlodinyEagri';

@Index('ctverec', ['ctverec'], {})
@Index('fid', ['fid'], {})
@Index('id_select', ['idtLpisSelect'], {})
@Index('id_select_id', ['idLpisSelect'], {})
@Index('jmeno', ['jmeno'], {})
@Index('katastr', ['prislOpzl'], {})
@Index('okres', ['mapvlst5'], {})
@Index('plodina', ['plodina'], {})
@Index('select_end', ['selectEnd'], {})
@Index('vyber', ['vyber'], {})
@Index('vymeram', ['vymeram'], {})
@Index('zkod_dpb', ['zkodDpb'], {})
@Index('t_LPIS_select_FK', ['idPlodiny'], {})
@Entity('t_LPIS_select', { schema: 'VaristarIS' })
export class TLpisSelect {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_LPIS_select' })
  idtLpisSelect: string;

  @Column('int', {
    name: 'ID_UZ',
    nullable: true,
    comment: 'Odkazuje na uživatele (zákazníka) ke který provádí výběr. ',
  })
  idUz: number | null;

  @Column('int', {
    name: 'FID',
    nullable: true,
    comment: 'Jedineční identifákor v databázi LPIS.',
  })
  fid: number | null;

  @Column('varchar', {
    name: 'CTVEREC',
    nullable: true,
    comment: 'Kód mapového čtverce - slouží pro vyhledání daného honu v LPIS.',
    length: 15,
  })
  ctverec: string | null;

  @Column('varchar', {
    name: 'ZKOD_DPB',
    nullable: true,
    comment: 'Zkrácený kód PB/DPB - slouží k vyhledání daného honu v LPIS.',
    length: 15,
  })
  zkodDpb: string | null;

  @Column('varchar', {
    name: 'PLATNYOD',
    nullable: true,
    comment: 'Datum začátku platnosti/účinnosti PB/DPB.',
    length: 12,
  })
  platnyod: string | null;

  @Column('decimal', {
    name: 'VYMERAM',
    nullable: true,
    comment: 'Výměra honu.',
    precision: 6,
    scale: 2,
  })
  vymeram: number | null;

  @Column('varchar', {
    name: 'PRISL_OPZL',
    nullable: true,
    comment: 'Příslušnost k pobočce SZIF.',
    length: 100,
  })
  prislOpzl: string | null;

  @Column('varchar', {
    name: 'MAPVLST5',
    nullable: true,
    comment: 'Kód pro mapový list klad 1:5000.',
    length: 100,
  })
  mapvlst5: string | null;

  @Column('int', {
    name: 'select_end',
    nullable: true,
    comment: 'Informace jestli byl výběr honů dokončen či nikoliv. ',
    default: () => "'0'",
  })
  selectEnd: number | null;

  @Column('varchar', {
    name: 'id_LPIS_select',
    nullable: true,
    comment:
      'Unikátní ID, které je generované systémem VaristarIS - toto ID je součástí hyperlinku, který dostane klient Varistar a jednoznačně identifikuje pole, z kterých má vybírat hony pro smlouvu.',
    length: 45,
  })
  idLpisSelect: string | null;

  @Column('varchar', {
    name: 'jmeno',
    nullable: true,
    comment: 'Zákaznické pojmenování honu. ',
    length: 150,
  })
  jmeno: string | null;

  @Column('varchar', {
    name: 'plodina',
    nullable: true,
    comment: 'Pěstovaná plodina na vybraném honu. ',
    length: 100,
  })
  plodina: string | null;

  @Column('int', {
    name: 'vyber',
    nullable: true,
    comment: 'Informace o tom, zda byl hon (1) vybrán nebo ne (0).',
    default: () => "'0'",
  })
  vyber: number | null;

  @Column('decimal', {
    name: 'VYMERAM_vyber',
    nullable: true,
    comment: 'Celková výměra vybraných honů. ',
    precision: 6,
    scale: 2,
  })
  vymeramVyber: number | null;

  @Column('varchar', {
    name: 'nkod',
    nullable: true,
    comment: 'Národní kód půdního bloku',
    length: 25,
  })
  nkod: string | null;

  @Column('decimal', {
    name: 'kultura',
    nullable: true,
    comment: 'Typ kultury dle LPIS (např. orná půda)',
    precision: 4,
    scale: 0,
    default: () => "'2'",
  })
  kultura: string | null;

  @Column('int', { name: 'id_plodiny', nullable: true })
  idPlodiny: number | null;

  @ManyToOne('TPlodinyEagri', 'tLpisSelects', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_plodiny', referencedColumnName: 'idtPlodinyEagri' }])
  idPlodiny2: TPlodinyEagri;
}
