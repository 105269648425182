<mat-form-field class="filter">
  <input
    #search
    matInput
    [id]="id"
    (input)="filterChange.emit($event.target.value.trim().toLowerCase())"
    [placeholder]="'PLACEHOLDER.FILTER' | translate"
    autocomplete="off"
  />
  <span matSuffix>
    <button
      mat-icon-button
      [disableRipple]="true"
      [matTooltip]="'BUTTON.CLEAR_SEARCH' | translate"
      matTooltipPosition="above"
      *ngIf="search.value?.length"
      (click)="search.value = ''; filterChange.emit('')"
    >
      <mat-icon>close</mat-icon>
    </button>
  </span>
</mat-form-field>
