import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TSim } from './TSim';
import { TSmlouvyTerminaly } from './TSmlouvyTerminaly';
import { TTabletMachines } from './TTabletMachines';
import { TTabletPosition } from './TTabletPosition';
import { TTabletSettings } from './TTabletSettings';
import { TDropboxDirect } from './TDropboxDirect';
import { TTabletMachinesType } from './TTabletMachinesType';
import { TTerminalyTyp } from './TTerminalyTyp';
import { TZakazkyTerminal } from './TZakazkyTerminal';
import { TTabletMachinesUnits } from './TTabletMachinesUnits';

@Index('cislo_terminalu', ['cisloTerminalu'], {})
@Index('id_terminalu1', ['idTerminaly'], {})
@Index('t_terminaly_typ_idx', ['terminalType'], {})
@Index('t_terminaly_machine_type_idx', ['machineType'], {})
@Index('jmeno', ['jmenoTerminalu'], {})
@Index('popis', ['popis'], {})
@Index('deactivated', ['deactivated'], {})
@Index('machine_type', ['machineType'], {})
@Index('t_terminaly_FK', ['directRefreshToken'], {})
@Index('t_terminaly_machine_units', ['units'], {})
@Entity('t_terminaly', { schema: 'VaristarIS' })
export class TTerminaly {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_terminaly' })
  idTerminaly: number;

  @Column('varchar', {
    name: 'cislo_terminalu',
    nullable: true,
    comment: 'Číselné označení terminálu. ',
    length: 45,
  })
  cisloTerminalu: string | null;

  @Column('varchar', {
    name: 'uzivatelske_jmeno',
    nullable: true,
    comment: 'Uživatelské jméno pro přihlášení k úložisti (viz pripojeni). ',
    length: 100,
  })
  uzivatelskeJmeno: string | null;

  @Column('varchar', {
    name: 'heslo',
    nullable: true,
    comment: 'Heslo pro přihlášení k úložisti (viz pripojeni). ',
    length: 20,
  })
  heslo: string | null;

  @Column('varchar', {
    name: 'API_key',
    nullable: true,
    comment:
      'Autentizační API key pro přístup k úložišti - např. u Dropbox a Varistar Direct, zároveň se sem ukládá SSAID Varistar One terminálu',
    length: 255,
  })
  apiKey: string | null;

  @Column('varchar', {
    name: 'jmeno_terminalu',
    nullable: true,
    comment:
      'Pojmenování terminálu v portálu přes který se přenáší data např. v DropBoxu jde o jméno a příjmení uživatele.',
    length: 100,
  })
  jmenoTerminalu: string | null;

  @Column('bigint', {
    name: 'teamviewer_id',
    nullable: true,
    comment: 'ID pro připojení přes teamviewer - tzv. partnerské ID.',
  })
  teamviewerId: string | null;

  @Column('varchar', {
    name: 'imei',
    nullable: true,
    comment: 'IMEI použitého tabletu, pouze pokud připojení řešeno přes tablet/ terminál iSoyl. ',
    length: 45,
  })
  imei: string | null;

  @Column('varchar', {
    name: 'jmeno_obsluhy',
    nullable: true,
    comment: 'Jméno obsluhy, jenž pracuje s teminálem.',
    length: 300,
  })
  jmenoObsluhy: string | null;

  @Column('varchar', {
    name: 'tel_obsluhy',
    nullable: true,
    comment: 'Telefonní spojení na obluhu pracující s terminálem. ',
    length: 300,
  })
  telObsluhy: string | null;

  @Column('datetime', {
    name: 'datum_predani',
    nullable: true,
    comment: 'Datum předání našeho teminálu zákazníkovi.',
  })
  datumPredani: Date | null;

  @Column('varchar', {
    name: 'cislo_plomby',
    nullable: true,
    comment: 'Číslo plomby našeho terminálu.',
    length: 255,
  })
  cisloPlomby: string | null;

  @Column('varchar', { name: 'poznamka', nullable: true, length: 1600 })
  poznamka: string | null;

  @Column('int', {
    name: 'terminal_type',
    nullable: true,
    comment: 'Odkazuje na typ terminálu - t_terminaly_typ',
  })
  terminalType: number | null;

  @Column('varchar', {
    name: 'popis',
    nullable: true,
    comment: 'Popis terminálu pro zákazníka - může jej měnit, aby poznal o jaký terminál jde.',
    length: 255,
  })
  popis: string | null;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    comment: 'Příznak pro neaktivní terminály',
    width: 1,
    default: () => "'0'",
  })
  deactivated: boolean | null;

  @Column('varchar', {
    name: 'refresh_token',
    nullable: true,
    comment: 'Refresh token pro OneDrive nebo Dropbox',
    length: 1024,
  })
  refreshToken: string | null;

  @Column('varchar', {
    name: 'storage_id',
    nullable: true,
    comment:
      'Starage ID pro přihlášení k OneDrive (User ID) - Ručně se získává z OneDrive a Dropboxu.',
    length: 255,
  })
  storageId: string | null;

  @Column('int', {
    name: 'machine_type',
    nullable: true,
    comment: 'Ozančuje typ stroje - Rozmetadlo, postřikovač, secí stroj, other.',
  })
  machineType: number | null;

  @Column('int', {
    name: 'direct_refreshToken',
    nullable: true,
    comment:
      'Cizí klíč do tabulky t_dropbox_direct - určuje jaký se má použít pro daný terminál Refrseh Token pro přístup do sdíleného dropboxu pro Varistar Directy',
    default: () => "'1'",
  })
  directRefreshToken: number | null;

  @Column('int', {
    name: 'units',
    nullable: true,
    comment:
      'Jednotky v jakých stroj dávkuje:.  1. kg/ha.  2. l/ha.  3. t/ha.  4. jednici/ha.  5. tis. jedinců/ha.  6. m3/ha.  7. jedniců/bm.  8. jedniců/m2.',
  })
  units: number | null;

  @OneToOne('TSim', 'tSim.actualTerminal2')
  tSim: TSim;

  @OneToMany('TSmlouvyTerminaly', 'idTerminaly2')
  tSmlouvyTerminalies: TSmlouvyTerminaly[];

  @OneToMany('TTabletMachines', 'idTerminal2')
  tTabletMachines: TTabletMachines[];

  @OneToMany('TTabletPosition', 'idTerminaly2')
  tTabletPositions: TTabletPosition[];

  @OneToOne('TTabletSettings', 'tTabletSettings.idTerminaly2')
  tTabletSettings: TTabletSettings;

  @ManyToOne('TDropboxDirect', 'tTerminalies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'direct_refreshToken', referencedColumnName: 'idtDropboxDirect' }])
  directRefreshToken2: TDropboxDirect;

  @ManyToOne('TTabletMachinesType', 'tTerminalies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'machine_type', referencedColumnName: 'idtTabletMachinesType' }])
  machineType2: TTabletMachinesType;

  @ManyToOne('TTerminalyTyp', 'tTerminalies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'terminal_type', referencedColumnName: 'idtTerminalyTyp' }])
  terminalType2: TTerminalyTyp;

  @OneToMany('TZakazkyTerminal', 'idTerminal2')
  tZakazkyTerminals: TZakazkyTerminal[];

  // todo corrupted saving terminals
  @ManyToOne('TTabletMachinesUnits', 'tTerminalies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'units', referencedColumnName: 'idtTabletMachinesUnits' }])
  units2: TTabletMachinesUnits;
}
