import { SettingsAction, SettingsActionTypes } from './settings.actions';

export const SETTINGS_FEATURE_KEY = 'settings';

export interface SettingsState {
  selectedSeasonId: number;
  seasonOptionList: { name: string, value: number }[];
}

export interface SettingsPartialState {
  readonly [SETTINGS_FEATURE_KEY]: SettingsState;
}

export const initialState: SettingsState = {
  selectedSeasonId: null,
  seasonOptionList: [],
};

export function settingsReducer(
  state: SettingsState = initialState,
  action: SettingsAction,
): SettingsState {
  switch (action.type) {
    case SettingsActionTypes.SelectSeasonId:

      return {
        ...state,
        selectedSeasonId: action.payload || state.selectedSeasonId,
      };

    case SettingsActionTypes.SetSeasonOptionList:
      return {
        ...state,
        seasonOptionList: action.payload || [],
      };

    default:
      return state;
  }
}
