import * as _ from 'lodash';
// import { keys } from 'ts-transformer-keys';

// import { Account, AccountRole } from './account.model';
// import { Customer } from './customer.model';

/**
 * Struktura dat ve Firestore
 */

// export interface Firestore {
// Account: {
//   [uid: string]: Account
// },
// Role: {
//   [uid: string]: {
//     [organizationId: string]: AccountRole[];
//   };
// }
// Organization: {
//   [organizationId: string]: {
//     Customer: {
//       [customerId: string]: Customer
//     }
//   }
// }
// id: string,
// company?: Company; // Foreign Key to Company
// person?: Person;  // Foreign Key to Person
// deals?: Deal[];
// personId?: string;  // Foreign Key to Person
// validFrom?: Date; // Date/Time of the validity from
// validTo?: Date; // Date/Time of the validity To
// note?: string;
// //
// refId?: string,
// createdByRefId?: string;
// companyRefId?: string;  // Foreign Key to Company
// personRefId?: string; // Foreign Key to Person
// messages?: string[];
// };
export enum FirestoreCollections {
  Account = 'Account',
  Organization = 'Organization',
  Access = 'Access',
  OrganizationAccess = 'OrganizationAccess',
  Resource = 'Resource',
  Lpis = 'Lpis',
  LpisCompany = 'LpisCompany',
  //
  Company = 'Company',
  Person = 'Person',
  //
  Profile = 'Profile',
}

export const firestoreCollectionHiearchy = {
  [FirestoreCollections.Account]: ['Account'],
  [FirestoreCollections.Organization]: ['Organization'],
  [FirestoreCollections.Access]: ['Organization', 'account.organization.id', 'Access'],
  [FirestoreCollections.OrganizationAccess]: ['Organization', 'account.organization.id', 'Access'], // TODO" zrisit a sloucit a Access
  [FirestoreCollections.Resource]: ['Organization', 'varistar', 'Resource'],
  [FirestoreCollections.Lpis]: ['Organization', 'varistar', 'Lpis'],
  [FirestoreCollections.LpisCompany]: ['Organization', 'varistar', 'Lpis', 'lpis.id', 'Company'],
  //
  [FirestoreCollections.Company]: ['Organization', 'account.organization.id', 'Company'],
  [FirestoreCollections.Person]: ['Organization', 'account.organization.id', 'Person'],
  //
  [FirestoreCollections.Profile]: ['Account', 'account.id', 'Profile'], // id je account.id protoze se predava objekt auth.account
};

export const Firestore = {
  FirestoreCollections: FirestoreCollections,
  /**
   * Seznam názvů všech collections
   */
  collectionNames: Object.keys(firestoreCollectionHiearchy),

  /**
   * @param {string} feature collection name
   * @param {[id: string]: string} ids objekt kde klicem je string definujici id
   */
  getPath: (feature: string, ids: any = {}): string => {
    // const firestoreHiearchy = {
    //   // collections
    //   Account: ['Account'],
    //   Organization: ['Organization'],
    //   Access: ['Organization', 'organization.id', 'Access'],
    //   OrganizationAccess: ['Organization', 'organization.id', 'Access'], // TODO" zrisit a sloucit a Access

    //   Company: ['Organization', 'organization.id', 'Company'],
    //   Person: ['Organization', 'organization.id', 'Person'],

    //   Resource: ['Organization', 'varistar', 'Resource'],
    //   Lpis: ['Organization', 'varistar', 'Lpis'],
    //   LpisCompany: ['Organization', 'varistar', 'Lpis', 'lpis.id', 'Company'],

    //   Season: ['Organization', 'organization.id', 'Season'],
    //   Player: ['Organization', 'organization.id', 'Player'],
    //   Trader: ['Organization', 'organization.id', 'Trader'],
    //   Customer: ['Organization', 'organization.id', 'Customer'],
    //   Deal: [
    //     'Organization',
    //     'organization.id',
    //     'Customer',
    //     'customer.id',
    //     'Deal'
    //   ],
    //   TradingPlatform: ['Organization', 'organization.id', 'TradingPlatform'],
    //   TradePoint: ['Organization', 'organization.id', 'TradePoint'],

    //   // Account = 'Account',
    //   // Organization = 'Organization',
    //   // // Role = 'Role',
    //   // Company = 'Company',
    //   // Person = 'Person',
    //   // Trader = 'Trader',
    //   // Customer = 'Customer',
    //   // Deal = 'Deal',
    //   // TradingPlatform = 'TradingPlatform',
    //   // TradePoint = 'TradePoint',

    //   // Role: ['Role'],

    //   Invitation: ['Invitation'],

    //   OrganizationInvitations: [
    //     'Organization',
    //     'organization.id',
    //     'Invitation'
    //   ],
    //   OrganizationAccounts: ['Organization', 'organization.id', 'Account'],
    //   Roles: ['Role'],
    //   RoleAccounts: ['Role', 'role.id', 'Account']
    //   // document
    //   // Account: ['Account', 'user.uid'],
    //   // Organization: ['Organization', 'organization.id'],
    //   // OrganizationInvitation: ['Organization', 'organization.id', 'Invitation'],
    //   // OrganizationAccess: ['Organization', 'organization.id', 'Access'],
    //   // OrganizationAccount: ['Organization', 'organization.id', 'Account'],
    //   // Role: ['Role','role.id', 'user.uid', 'organization.id'],
    //   // Customer: ['Organization', 'organization.id', 'Customer'],
    //   // Deal: ['Organization', 'organization.id', 'Customer', 'customer.id', 'Deal'],
    //   // Consumption: ['Organization', 'organization.id', 'Customer', 'customer.id', 'Consumption'],
    //   // Production: ['Organization', 'organization.id', 'Customer', 'customer.id', 'Production'],
    // };
    const pathParams = firestoreCollectionHiearchy[feature].map((param) =>
      _.get(ids, param, param),
    );
    return pathParams.join('/');
  },
};
