// export interface IUser {
//   uid?: string; // User UID z Firebase Authentication
//   email?: string; // UserCredential.user.email
//   displayName?: string; // UserCredential.user.displayName nebo UserCredential.user.email
// }

// export class User implements IUser {
//   constructor(
//     public uid?: string,
//     public email?: string,
//     public displayName?: string,
//   ) { }
// }

import { PropsFilterFnFactory } from '@ngrx/data';
import { TUser } from './entities/TUser';

export interface Widget {
  h: number;
  w: number;
  x: number;
  y: number;
  id: number;
  maxW: number;
  maxH?: number;
  minW?: number;
  minH?: number;
  content?: string;
}

// tslint:disable-next-line: no-empty-interface
export interface User extends TUser {
  // holdingId?: string,
  // customerId?: string,
  dashboardConfig: {
    gridItems: Widget[];
    graphType: string;
    lastObservationSchema: string;
  };
}

export const User = {
  selectId: (a: User) => {
    return a.idtUser;
  },
  // sortByName: (a: User, b: User): number => {
  //   return a.name && a.name.localeCompare(b.name);
  // },
  // filterFn: PropsFilterFnFactory(['idtUser', 'country'])
};
