import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';
import { timer } from 'rxjs';
import { elementAt, first, take, takeUntil, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[asyncFocus]',
})
export class AsyncFocusDirective {
  constructor(el: ElementRef) {
    timer(0, 100)
      .pipe(take(5))
      .subscribe(() => {
        if (document.activeElement !== el.nativeElement) {
          el.nativeElement.select();
        }
      });
  }
}
