import { TTerminalySoil } from './entities/TTerminalySoil';

// tslint:disable-next-line: no-empty-interface

export const SensorType = {
  'PR-3001': 0,
  'JXBS-4001': 1,
};
export interface TerminalSoil extends TTerminalySoil {}

export const TerminalSoil = {
  selectId: (a: TTerminalySoil) => {
    //In this case this would be optional since primary key is id
    return String(a.idTerminalySoil);
  },
};
