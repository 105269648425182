import { Column, Entity } from 'typeorm';

// import { TTabletProtocolSpeed } from "./TTabletProtocolSpeed";

@Entity('t_tablet_protocol', { schema: 'VaristarIS' })
export class TTabletProtocol {
  @Column('int', {
    primary: true,
    name: 'id_tablet_protokol',
    comment:
      'id protokolu používaného na tabletu - přes toto ID se přiřazuje protokol k profilu určitého stroje ve Varistar tabletu',
  })
  idTabletProtokol: number;

  @Column('varchar', {
    name: 'protocol_name',
    nullable: true,
    comment: 'Jméno protokolu, pro srozumitelne označení - překládá kód protokolu na string.',
    length: 45,
  })
  protocolName: string | null;

  // @OneToOne(
  //   () => TTabletProtocolSpeed,
  //   (tTabletProtocolSpeed) => tTabletProtocolSpeed.tTabletProtocol,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  // @JoinColumn([
  //   { name: "id_tablet_protokol", referencedColumnName: "idTTabletProtocol" },
  // ])
  // idTabletProtokol2: TTabletProtocolSpeed;
}
