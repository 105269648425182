import { TCiselnikDruh } from './entities/TCiselnikDruh';

// tslint:disable-next-line: no-empty-interface
export interface CiselnikDruh {
  nazev: string;
  kod: string;
}

export const CiselnikDruh = {
  selectId: (x: TCiselnikDruh) => {
    return x.kod;
  },
};
