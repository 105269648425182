import { Directive } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';

const selector = `
  [fxLayout.fhd], [fxLayout.gt-fhd],
  [fxLayout.qhd], [fxLayout.gt-qhd],
  [fxLayout.uhd], [fxLayout.gt-uhd],
  [fxLayout.fuhd],
`;
const inputs = [
  'fxLayout.fhd',
  'fxLayout.gt-fhd',
  'fxLayout.qhd',
  'fxLayout.gt-qhd',
  'fxLayout.uhd',
  'fxLayout.gt-uhd',
  'fxLayout.fuhd',
];

@Directive({ selector, inputs })
export class FlexLayoutFxLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
