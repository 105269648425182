import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_fields_observations_new', { schema: 'VaristarIS' })
export class TFieldsObservationsNew {
  @PrimaryGeneratedColumn({
    type: 'bigint',
    name: 'idt_fields_observation_new',
  })
  idtFieldsObservationNew: string;

  @Column('varchar', { name: 'fid', nullable: true, length: 45 })
  fid: string | null;

  @Column('datetime', { name: 'valid_observation_datetime', nullable: true })
  validObservationDatetime: Date | null;

  @Column('int', {
    name: 'source_type',
    nullable: true,
    comment: '1 = Dynacrop\n2 = Planet',
  })
  sourceType: number | null;

  @Column('decimal', {
    name: 'cloud_cover_percent',
    nullable: true,
    precision: 5,
    scale: 4,
  })
  cloudCoverPercent: string | null;

  @Column('datetime', {
    name: 'created_datetime',
    nullable: true,
    default: () => 'CURRENT_TIMESTAMP',
  })
  createdDatetime: Date | null;

  @Column('datetime', { name: 'ndvi3', nullable: true })
  ndvi3: Date | null;

  @Column('datetime', { name: 'ndvi5', nullable: true })
  ndvi5: Date | null;

  @Column('datetime', { name: 'ndvi_zones', nullable: true })
  ndviZones: Date | null;

  @Column('datetime', { name: 'evi3', nullable: true })
  evi3: Date | null;

  @Column('datetime', { name: 'evi5', nullable: true })
  evi5: Date | null;

  @Column('datetime', { name: 'evi_zones', nullable: true })
  eviZones: Date | null;

  @Column('datetime', { name: 'lai3', nullable: true })
  lai3: Date | null;

  @Column('datetime', { name: 'lai5', nullable: true })
  lai5: Date | null;

  @Column('datetime', { name: 'lai_zones', nullable: true })
  laiZones: Date | null;

  @Column('datetime', { name: 'ndvi', nullable: true })
  ndvi: Date | null;

  @Column('datetime', { name: 'evi', nullable: true })
  evi: Date | null;

  @Column('datetime', { name: 'lai', nullable: true })
  lai: Date | null;

  @Column('datetime', { name: 'ss', nullable: true })
  ss: Date | null;
}
