import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TPorOchrannaPasma } from './TPorOchrannaPasma';
import { TPorRozhodnuti } from './TPorRozhodnuti';

@Index('idt_por_rozhodnuti_idp', ['rozhodnutiId'], {})
@Index('idt_por_rozhodnuti_idx', ['rozhodnutiId'], {})
@Entity('t_por_udaje', { schema: 'VaristarIS' })
export class TPorUdaje {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_udaje' })
  idtPorUdaje: string;

  @Column('bigint', { name: 'rozhodnuti_id', nullable: true })
  rozhodnutiId: string | null;

  @OneToMany('TPorOchrannaPasma', 'udaje')
  tPorOchrannaPasmas: TPorOchrannaPasma[];

  @ManyToOne('TPorRozhodnuti', 'tPorUdajes', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'rozhodnuti_id', referencedColumnName: 'idtPorRozhodnuti' }])
  rozhodnuti: TPorRozhodnuti;
}
