import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class AppRedirectComponent implements OnInit, AfterViewInit {
  @HostBinding('class') componentCssClass;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    window.location.href = 'http://oldportal.varistar.cz/';
  }
}
