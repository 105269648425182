import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import {
  DefaultDataServiceConfig,
  EntityCollectionDataService,
  HttpUrlGenerator,
} from '@ngrx/data';

import { FrontendUiFeatureExtendedDataService } from './frontend-ui-feature-extended-data-service';

@Injectable()
export class FrontendUiFeatureExtendedDataServiceFactory {
  constructor(
    protected http: HttpClient,
    protected httpUrlGenerator: HttpUrlGenerator,
    @Optional() protected config?: DefaultDataServiceConfig,
  ) {
    config = config || {};
    httpUrlGenerator.registerHttpResourceUrls(config.entityHttpResourceUrls);
  }
  /**
   * Create a default {EntityCollectionDataService} for the given entity type
   * @param entityName Name of the entity type for this data service
   */
  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new FrontendUiFeatureExtendedDataService<T>(
      entityName,
      this.http,
      this.httpUrlGenerator,
      this.config,
    );
  }
}
