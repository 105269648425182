import { TTerminalyTyp } from './entities/TTerminalyTyp';

// tslint:disable-next-line: no-empty-interface
export interface TerminalType extends TTerminalyTyp {}

export const TerminalType = {
  selectId: (a: TTerminalyTyp) => {
    //In this case this would be optional since primary key is id
    return String(a.idtTerminalyTyp);
  },
  // getItems: () => {
  //   return Object.keys(TabletMachineTypes).map(key => ({ name: `TABLET_MACHINE_TYPE.${key.toUpperCase()}`, value: TabletMachineType[key] }));
  // },
  getItems: (data: TTerminalyTyp[] = []) => {
    return data.map((terminalType) => ({
      name: `${terminalType.nazevTerminalu}`,
      value: terminalType.idtTerminalyTyp,
    }));
  },
};
