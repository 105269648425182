import { TLpisPole } from './entities/TLpisPole';

// tslint:disable-next-line: no-empty-interface
export interface LpisPole extends TLpisPole {}

export const LpisPole = {
  selectId: (x: TLpisPole) => {
    return x.fid;
  },
};
