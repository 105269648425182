import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import {
  AUTH_FEATURE_KEY,
  authReducer,
  initialState as authInitialState,
} from './+state/auth.reducer';
import { AuthInterceptor } from './auth-interceptor/auth.interceptor';
import { AuthService } from './auth-service/auth.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer, {
      initialState: authInitialState,
    }),
    EffectsModule.forFeature([AuthEffects]),
    AngularFireAuthModule, // Firebase Authentication
    // AngularFirestoreModule, // Firebase Firestore // !!! uz neni puvodni zavislost na Firestore
  ],

  providers: [
    AuthFacade,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useFactory: function (router: Router) {
    //     return new AuthInterceptor(router);
    //   },
    //   multi: true,
    //   deps: [Router]
    // },
  ],
})
export class FrontendAuthModule {}
