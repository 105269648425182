import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { TNabidky } from './TNabidky';
import { TSmlouvy } from './TSmlouvy';

// import { TNabidky } from "./TNabidky";
// import { TObchodnik } from "./TObchodnik";
// import { TOsoby } from "./TOsoby";

@Index('id_nabidky_idx', ['idNabidky'], {})
@Index('id_obchodnik_idx', ['idObchodnik'], {})
@Index('id_osoby_idx', ['idKontaktNabidka'], {})
@Index('id_osoby_idx1', ['idKontaktJednajici'], {})
@Index('id_osoby_idx2', ['idKontaktAgronom'], {})
@Index('id_osoby_idx3', ['idKontaktFakturace'], {})
@Index('id_verze_UNIQUE', ['idVerze'], { unique: true })
@Index('vytvoeno', ['vytvoreno'], {})
@Entity('t_verze', { schema: 'VaristarIS' })
export class TVerze {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_verze' })
  idVerze: number;

  @Column('int', {
    name: 'id_nabidky',
    nullable: true,
    comment: 'Odkazuje na nabídku (t_nabidky) ke které je verze vázána.',
  })
  idNabidky: number | null;

  @Column('int', {
    name: 'id_obchodnik',
    nullable: true,
    comment: 'Označuje obchodníka, jenž vytvořil verzi nabídky.',
  })
  idObchodnik: number | null;

  @Column('int', { name: 'cislo_verze', nullable: true })
  cisloVerze: number | null;

  @Column('int', {
    name: 'id_kontakt_nabidka',
    nullable: true,
    comment: 'Odkazuje na kam je nabídka směřována (kontakt).',
  })
  idKontaktNabidka: number | null;

  @Column('int', {
    name: 'id_kontakt_jednajici',
    nullable: true,
    comment: 'Označuje jednající osobu zákazníka.',
  })
  idKontaktJednajici: number | null;

  @Column('int', {
    name: 'id_kontakt_agronom',
    nullable: true,
    comment: 'Odkazuje na kontakt agronoma u jednající strany.',
  })
  idKontaktAgronom: number | null;

  @Column('int', {
    name: 'id_kontakt_fakturace',
    nullable: true,
    comment: 'Odkazuje na kontakt k fakturaci u jednající strany.',
  })
  idKontaktFakturace: number | null;

  @Column('varchar', {
    name: 'misto_aplikace',
    nullable: true,
    comment: 'Místo kde by byla provedena variabilní aplikace.',
    length: 255,
  })
  mistoAplikace: string | null;

  @Column('date', { name: 'zacatek_smlouvy', nullable: true })
  zacatekSmlouvy: string | null;

  @Column('decimal', {
    name: 'delka_smlouvy',
    nullable: true,
    comment: 'Délka smlouvy v letech.',
    precision: 6,
    scale: 2,
  })
  delkaSmlouvy: string | null;

  @Column('int', {
    name: 'pocet_aplikaci',
    nullable: true,
    comment: 'Počet smluvně domluvených aplikací.',
  })
  pocetAplikaci: number | null;

  @Column('decimal', {
    name: 'vymera_celkem',
    nullable: true,
    comment: 'Celková výměra pro provedení aplikací.',
    precision: 9,
    scale: 2,
  })
  vymeraCelkem: string | null;

  @Column('decimal', {
    name: 'vymera_1_app',
    nullable: true,
    comment: 'Výměra pro 1. aplikaci.',
    precision: 9,
    scale: 2,
  })
  vymera_1App: string | null;

  @Column('decimal', {
    name: 'vymera_2_app',
    nullable: true,
    comment: 'Výměra pro 2. aplikaci.',
    precision: 9,
    scale: 2,
  })
  vymera_2App: string | null;

  @Column('decimal', {
    name: 'vymera_3_app',
    nullable: true,
    comment: 'Výměra pro 3. aplikaci.',
    precision: 9,
    scale: 2,
  })
  vymera_3App: string | null;

  @Column('decimal', {
    name: 'cena_1_app',
    nullable: true,
    comment: 'Cena za provednou 1. aplikaci. ',
    precision: 9,
    scale: 2,
  })
  cena_1App: string | null;

  @Column('decimal', {
    name: 'cena_2_app',
    nullable: true,
    comment: 'Cena za provednou 2. aplikaci. ',
    precision: 9,
    scale: 2,
  })
  cena_2App: string | null;

  @Column('decimal', {
    name: 'cena_3_app',
    nullable: true,
    comment: 'Cena za provednou 3. aplikaci. ',
    precision: 9,
    scale: 2,
  })
  cena_3App: string | null;

  @Column('decimal', {
    name: 'obrat',
    nullable: true,
    comment: 'Obrat subjektu s kterým je vedeno jednání. ',
    precision: 9,
    scale: 2,
  })
  obrat: string | null;

  @Column('datetime', {
    name: 'vytvoreno',
    nullable: true,
    comment: 'Datum vytvoření verze.',
  })
  vytvoreno: Date | null;

  @Column('tinyint', {
    name: 'flatRate',
    nullable: true,
    comment:
      'Určuje je-li nabídka vystavena jako flat rate - fixní cena za hektar a rok bez ohledu na počet aplikací.',
    default: () => "'0'",
  })
  flatRate: number | null;

  @Column('tinyint', {
    name: 'tryBuy',
    nullable: true,
    comment:
      'Příznak, že verze nabídky je na try & buy marketingovou akci a zvýhodněnou paušální cenu.',
    default: () => "'0'",
  })
  tryBuy: number | null;

  @Column('decimal', {
    name: 'tryBuyPrice',
    nullable: true,
    comment: 'Paušální cena za try & buy akci.',
    precision: 9,
    scale: 2,
  })
  tryBuyPrice: string | null;

  // @OneToMany(() => TSmlouvy, (tSmlouvy) => tSmlouvy.idVerze2)
  @OneToMany('TSmlouvy', 'idVerze2')
  tSmlouvies: TSmlouvy[];

  // @ManyToOne(() => TNabidky, (tNabidky) => tNabidky.tVerzes, {
  //   onDelete: "CASCADE",
  //   onUpdate: "CASCADE",
  // })
  @ManyToOne('TNabidky', 'Verzes', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_nabidky', referencedColumnName: 'idNabidky' }])
  idNabidky2: TNabidky;

  // @ManyToOne(() => TObchodnik, (tObchodnik) => tObchodnik.tVerzes, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_obchodnik", referencedColumnName: "idObchodnik" }])
  // idObchodnik2: TObchodnik;

  // @ManyToOne(() => TOsoby, (tOsoby) => tOsoby.tVerzes, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_kontakt_nabidka", referencedColumnName: "idOsoby" }])
  // idKontaktNabidka2: TOsoby;
}
