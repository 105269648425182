import { TLpisSkSelectInfo } from './entities/TLpisSkSelectInfo';

// tslint:disable-next-line: no-empty-interface
export interface LpisSkSelectInfo extends TLpisSkSelectInfo {}

export const LpisSkSelectInfo = {
  selectId: (x: TLpisSkSelectInfo) => {
    return x.idTLpisSkSelect;
  },
};
