import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, Observable, of, defer } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth-service/auth.service';
import {
  AuthActionTypes,
  Authenticated,
  AuthError,
  GetUser,
  LoginByEmail,
  Logout,
  NotAuthenticated,
  SignUpByEmail,
  AccountUpdated,
  // UpdateAccount,
  LoginByGoogle,
  LoginByFacebook,
  LoginByMicrosoft,
  Authorized,
} from './auth.actions';
import { AuthUser, User } from '@varistar-apps/shared/data';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {}

  @Effect({ dispatch: true })
  getUser$: Observable<Action> = this.actions$.pipe(
    ofType<GetUser>(AuthActionTypes.GetUser),
    map((action) => action.payload),
    switchMap((payload) => this.authService.getAuthState()),
    // delay(2000), // delay to show loading spinner, delete me!

    map((authData) => {
      if (authData) {
        /// User logged in
        const user = new AuthUser(authData.uid, authData.email, authData.displayName);
        return new Authenticated(user);
      } else {
        /// User not logged in
        return new NotAuthenticated();
      }
    }),
    catchError((error) => of(new AuthError(error))),
  );

  // @Effect({ dispatch: true })
  // getVaristarUser$: Observable<Action> = this.actions$.pipe(
  //   ofType<GetUser>(AuthActionTypes.GetVaristarUser),
  //   map(action => action.payload),
  //   switchMap(payload => {
  //     const fakeVaristarUser: User = {
  //       idtUser: 0,
  //       idtOsoby: 0,
  //       uid: null,
  //       enabled: true,
  //     };
  //     return of(new Authorized(fakeVaristarUser));
  //   }),
  //   catchError(error => of(new AuthError(error)))
  //   // TODO
  //   // switchMap(payload => this.authService.getAuthState()),
  //   // // delay(2000), // delay to show loading spinner, delete me!

  //   // map(authData => {
  //   //   if (authData) {
  //   //     /// User logged in
  //   //     const user = new AuthUser(authData.uid, authData.email, authData.displayName);
  //   //     return new Authorized(user);
  //   //   } else {
  //   //     /// User not logged in
  //   //     return new NotAuthenticated();
  //   //   }
  //   // }),
  //   // catchError(error => of(new AuthError(error)))
  // );

  @Effect({ dispatch: true })
  signUpByEmail$: Observable<Action> = this.actions$.pipe(
    ofType<SignUpByEmail>(AuthActionTypes.SignUpByEmail),
    map((action) => action.payload),
    switchMap((credentials) =>
      from(this.authService.signUpByEmail(credentials)).pipe(
        concatMap((userCredential) => [
          // FIXIT: signup by mel chtit potvrzeni emailu, cili prihlaseni by melo byt az potom, ale pro zjednoduseni je to docasne vypnuto
          new LoginByEmail(credentials),
        ]),
        catchError((error) => of(new AuthError(error))),
      ),
    ),
  );

  @Effect({ dispatch: true })
  loginByEmail$: Observable<Action> = this.actions$.pipe(
    ofType<LoginByEmail>(AuthActionTypes.LoginByEmail),
    map((action) => action.payload),
    switchMap((credentials) =>
      from(this.authService.loginByEmail(credentials)).pipe(
        concatMap((userCredential) => [new GetUser()]),
        catchError((error) => of(new AuthError(error))),
      ),
    ),
  );

  @Effect({ dispatch: true })
  loginByGoogle$: Observable<Action> = this.actions$.pipe(
    ofType<LoginByGoogle>(AuthActionTypes.LoginByGoogle),
    map((action) => action.payload),
    switchMap((credentials) =>
      from(this.authService.loginByGoogle()).pipe(
        concatMap((userCredential) => [new GetUser()]),
        catchError((error) => of(new AuthError(error))),
      ),
    ),
  );

  @Effect({ dispatch: true })
  loginByFacebook$: Observable<Action> = this.actions$.pipe(
    ofType<LoginByGoogle>(AuthActionTypes.LoginByFacebook),
    map((action) => action.payload),
    switchMap((credentials) =>
      from(this.authService.loginByFacebook()).pipe(
        concatMap((userCredential) => [new GetUser()]),
        catchError((error) => of(new AuthError(error))),
      ),
    ),
  );

  @Effect({ dispatch: true })
  loginByMicrosoft$: Observable<Action> = this.actions$.pipe(
    ofType<LoginByGoogle>(AuthActionTypes.LoginByMicrosoft),
    map((action) => action.payload),
    switchMap((credentials) =>
      from(this.authService.loginByMicrosoft()).pipe(
        concatMap((userCredential) => [new GetUser()]),
        catchError((error) => of(new AuthError(error))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  logout$: Observable<void | AuthError> = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    map((action) => action.payload),
    switchMap((payload) =>
      from(this.authService.logout()).pipe(catchError((error) => of(new AuthError(error)))),
    ),
  );

  @Effect({ dispatch: true })
  // updateAccount$: Observable<Action> = this.actions$.pipe(
  autheticated$ = this.actions$.pipe(
    ofType<Authenticated>(AuthActionTypes.Authenticated),
    map((action) => action.payload),
    switchMap((user) =>
      from(this.authService.updateAccount(user)).pipe(
        map((account: Account) => new AccountUpdated(account)),
      ),
    ),
    //   .pipe(

    // ),
    // {
    // // this.authService.getAuthState()

    // return from(null);
    // }),
    // // delay(2000), // delay to show loading spinner, delete me!

    // map(authData => {
    //   if (authData) {
    //     /// User logged in
    //     const user = new User(authData.uid, authData.email, authData.displayName);
    //     return of(user).pipe(
    //       concatMap(user => [
    //         new Authenticated(user),
    //         new UpdateAccount(user)
    //       ])
    //     )
    //   } else {
    //     /// User not logged in
    //     return new NotAuthenticated();
    //   }
    // }),
    // catchError(error => of(new AuthError(error)))
  );

  // @Effect({ dispatch: true })
  // updateUser$: Observable<Action> = this.actions$.pipe(
  //   ofType<UpdateUser>(AuthActionTypes.UpdateUser),
  //   map(action => action.payload),

  //   map(userCredential => ({})),

  //   concatMap(authUser => [
  //     new AuthStateChanged(authUser),
  //     // new UpdateSuccess(authUser)
  //   ]),

  //   // map(account => this.authService.updateProfile(account)),
  //   // // ziskam seznam vsech accountu a udelam z toho objekt podle userId
  //   // switchMap(profile =>
  //   //   from(this.accountApi.find()).pipe(
  //   //     map((accounts: IAuthUser[]) =>
  //   //       accounts.reduce((acc, account) => {
  //   //         acc[account.id] = {
  //   //           username: account.username,
  //   //           email: account.email
  //   //         };
  //   //         return acc;
  //   //       }, {})
  //   //     ),
  //   //     map(accounts => ({ ...profile, accounts: accounts } as IAuthProfile)),
  //   //     concatMap(profileWithAccounts => [
  //   //       new AuthStateChangedAction(profileWithAccounts),
  //   //       new UpdateSuccessAction(profileWithAccounts)
  //   //     ]),
  //   //     catchError(error => of(new UpdateFailureAction(error)))
  //   //   )
  //   // )
  // );

  // // @Effect({ dispatch: true })
  // @Effect({ dispatch: false })
  // authFailureAction$: Observable<Action> = this.actions$.pipe(
  //   ofType<AuthFailureAction>(AuthActionTypes.AUTH_FAILURE),
  //   tap(action => console.error(action))
  //   // map(() => (new RedirectToLoginAction()))
  // );

  // @Effect({ dispatch: false })
  // updateFailureAction$: Observable<void> = this.actions$.pipe(
  //   ofType(AuthActionTypes.UPDATE_FAILURE),
  //   map((action: any) => console.error(action.type, action.payload))
  // );

  // @Effect({ dispatch: true })
  // updateAccountAction$: Observable<Action> = this.actions$.pipe(
  //   ofType<UpdateAccountAction>(AuthActionTypes.UPDATE_ACCOUNT),
  //   map(action => action.payload),
  //   filter(account => !!account.userId),
  //   switchMap(account =>
  //     from(this.accountApi.updateAttributes(account.userId, account.user)).pipe(
  //       // switchMap(payload => (from(this.accountApi.updateAttributes().login(payload.credentials, 'user', payload.rememberMe))).pipe(

  //       map(user => new UpdateUserAction({ user: user })),
  //       //   concatMap(account => [
  //       //     new UpdateUserAction(account),
  //       //     new AuthSuccessAction()
  //       // ]),
  //       catchError(error => of(new AuthFailureAction(error)))
  //     )
  //   )
  // );

  // @Effect({ dispatch: true })
  // resetPasswordAction$: Observable<Action> = this.actions$.pipe(
  //   ofType<ResetPasswordAction>(AuthActionTypes.RESET_PASSWORD),
  //   map(action => action.payload),
  //   switchMap(email =>
  //     from(this.accountApi.resetPassword({ email })).pipe(
  //       map(user => new LogoutAction()),
  //       catchError(error => of(new AuthFailureAction(error)))
  //     )
  //   )
  // );

  // @Effect({ dispatch: true })
  // changePasswordAction$: Observable<Action> = this.actions$.pipe(
  //   ofType<ChangePasswordAction>(AuthActionTypes.CHANGE_PASSWORD),
  //   map(action => action.payload),
  //   switchMap(passwords =>
  //     from(
  //       this.accountApi.changePassword(
  //         passwords.currentPassword,
  //         passwords.newPassword
  //       )
  //     ).pipe(
  //       map(user => new AuthSuccessAction()),
  //       catchError(error => of(new AuthFailureAction(error)))
  //     )
  //   )
  // );

  // Zachytne Init Action ?
  // Dle MIGRATION.md / @ngrx/effects / Init Action : https://github.com/ngrx/platform/blob/81afd0d71c0aca6d051a88954e2fa4edbf9a9cf2/MIGRATION.md
  @Effect()
  init$: Observable<Action> = defer(() => {
    return of(new GetUser());

    // // NOTE: musim nastavit globalni LoopBackConfig path, nejde to ve forRoot v nadrazeneme modulu protoze se musi volat statiscka metoda a to je tam zakazano a kontroluje se to pri buildu --prod
    // if (this.config.baseUrl) LoopBackConfig.setBaseURL(this.config.baseUrl);

    // // overi za nahodou neni zapamatovane prihlaseni
    // if (this.accountApi.isAuthenticated()) {
    //   return this.accountApi.getCurrent().pipe(

    //     concatMap((user: IAuthUser) => [
    //       new UpdateUserAction({ user }),
    //       new AuthSuccessAction()
    //     ]),
    //     catchError(error => of(new AuthFailureAction(error)))
    //   );
    // }
    // return of(new RedirectToLoginAction());
  });
}
