import { THoldingOsoby } from './entities/THoldingOsoby';

// tslint:disable-next-line: no-empty-interface
export interface HoldingPerson extends THoldingOsoby {}

export const HoldingPerson = {
  selectId: (x: THoldingOsoby) => {
    return x.idtHoldingOsoby;
  },
};
