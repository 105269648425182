export interface AirflowDagRun {
  dag_run_id: string;
  dag_id: string;
  execution_date: string; // "2021-04-12T17:14:47Z",
  start_date: string; // "2021-04-12T17:14:47Z",
  end_date: string; // "2021-04-12T17:14:47Z",
  state: string; // "success",
  external_trigger: boolean; // true,
  conf: any; // { }
}

export class AirflowDagRun implements AirflowDagRun {
  constructor(data: AirflowDagRun) {
    if (data) Object.assign(this, data);
  }

  isRunning() {
    return this.state === 'running';
  }

  isFinished() {
    return !!this.end_date;
  }
}

// export const Email = {
// selectId: (a: Person) => {
//   return a.idOsoby;
// },
// }
