import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TVerze } from './TVerze';
import { TZakaznik } from './TZakaznik';

// import { TZakaznik } from "./TZakaznik";
// import { TVerze } from "./TVerze";
// import { TZaznamJednani } from "./TZaznamJednani";

@Index('id_nabidky_UNIQUE', ['idNabidky'], { unique: true })
@Index('id_zakaznik_idx', ['idZakaznik'], {})
@Entity('t_nabidky', { schema: 'VaristarIS' })
export class TNabidky {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_nabidky' })
  idNabidky: number;

  @Column('int', {
    name: 'cislo_nabidky',
    nullable: true,
    comment: 'Číselné označení nabídky. ',
  })
  cisloNabidky: number | null;

  @Column('int', {
    name: 'id_zakaznik',
    nullable: true,
    comment: 'ID zákazníka pro připárování zákazníka k nabídce z t_zakaznik. ',
  })
  idZakaznik: number | null;

  @Column('date', {
    name: 'nabidka_zalozeno',
    nullable: true,
    comment: 'Datum vytvoření nabídky.',
  })
  nabidkaZalozeno: string | null;

  @Column('int', {
    name: 'id_smlouvy',
    nullable: true,
    comment:
      'ID smlouvy je pouze pro nabídky zákazníkům, jenž s námi již mají podepsanou smlouvu. ',
  })
  idSmlouvy: number | null;

  @Column('tinyint', {
    name: 'neaktualni',
    nullable: true,
    comment: 'Údaj o tom, zda je nabídka aktivní či nikoliv.',
    width: 1,
    default: () => "'0'",
  })
  neaktualni: boolean | null;

  @Column('bigint', {
    name: 'id_deal_hs',
    nullable: true,
    comment: 'Slouží k propojení nabídky a CRM HubSpot. ',
  })
  idDealHs: string | null;

  @Column('varchar', { name: 'zeme', nullable: true, length: 45 })
  zeme: string | null;

  @Column('varchar', { name: 'mena', nullable: true, length: 15 })
  mena: string | null;

  @Column('decimal', {
    name: 'zaloha',
    nullable: true,
    comment: 'Výše zálohové faktury v % celkové ceny. ',
    precision: 3,
    scale: 3,
  })
  zaloha: string | null;

  @Column('int', {
    name: 'splatnost',
    nullable: true,
    comment: 'Splatnost zálohové faktury. ',
  })
  splatnost: number | null;

  @Column('varchar', {
    name: 'j_nabidky',
    nullable: true,
    comment: 'Jazyk nabídky.',
    length: 6,
  })
  jNabidky: string | null;

  @Column('varchar', {
    name: 'j_smlouvy',
    nullable: true,
    comment: 'Jazyk smlouvy',
    length: 6,
  })
  jSmlouvy: string | null;

  @Column('varchar', {
    name: 'j_faktury',
    nullable: true,
    comment: 'Jazyk faktury',
    length: 6,
  })
  jFaktury: string | null;

  @Column('varchar', {
    name: 'id_LPIS_select',
    nullable: true,
    comment:
      'Unikátní ID, které je generované systémem VaristarIS - toto ID je součástí hyperlinku, který dostane klient Varistar a jednoznačně identifikuje pole, z kterých má vybírat hony pro smlouvu.',
    length: 45,
  })
  idLpisSelect: string | null;

  @Column('date', {
    name: 'date_LPIS_select',
    nullable: true,
    comment: 'Datum vytvoření LPIS select',
  })
  dateLpisSelect: string | null;

  // @ManyToOne(() => TZakaznik, (tZakaznik) => tZakaznik.tNabidkies, {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  @ManyToOne('TZakaznik', 'tNabidkies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_zakaznik', referencedColumnName: 'idZakaznik' }])
  idZakaznik2: TZakaznik;

  // @OneToMany(() => TVerze, (tVerze) => tVerze.idNabidky2)
  @OneToMany('TVerze', 'idNabidky2')
  tVerzes: TVerze[];

  // @OneToMany(
  //   () => TZaznamJednani,
  //   (tZaznamJednani) => tZaznamJednani.idNabidky2
  // )
  // tZaznamJednanis: TZaznamJednani[];
}
