import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TNewFeatureCategory } from './TNewFeatureCategory';

@Entity('t_new_feature', { schema: 'VaristarIS' })
export class TNewFeature {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_new_feature' })
  idNewFeature: number;

  @Column('varchar', { name: 'title_cz', nullable: true, length: 128 })
  titleCz: string | null;

  @Column('text', { name: 'text_cz', nullable: true })
  textCz: string | null;

  @Column('varchar', { name: 'video_id_cz', nullable: true, length: 16 })
  videoIdCz: string | null;

  @Column('date', {
    name: 'create_date',
    nullable: true,
    comment: 'Datum vytvoreni',
  })
  createDate: string | null;

  @Column('text', { name: 'text_en', nullable: true })
  textEn: string | null;

  @Column('text', { name: 'text_sk', nullable: true })
  textSk: string | null;

  @Column('varchar', { name: 'video_id_sk', nullable: true, length: 16 })
  videoIdSk: string | null;

  @Column('varchar', { name: 'video_id_en', nullable: true, length: 16 })
  videoIdEn: string | null;

  @Column('varchar', { name: 'title_sk', nullable: true, length: 128 })
  titleSk: string | null;

  @Column('varchar', { name: 'title_en', nullable: true, length: 128 })
  titleEn: string | null;

  @Column('int', { name: 'id_new_feature_category', default: () => "'1'" })
  idNewFeatureCategory: number;

  @ManyToOne('TNewFeatureCategory', 'tNewFeatures', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([
    {
      name: 'id_new_feature_category',
      referencedColumnName: 'idNewFeatureCategory',
    },
  ])
  idNewFeatureCategory2: TNewFeatureCategory;
}
