import { TYieldPrice } from './entities/TYieldPrice';

// tslint:disable-next-line: no-empty-interface
export interface YieldPrice extends TYieldPrice {}

export const YieldPrice = {
  selectId: (x: TYieldPrice) => {
    return x.idYieldPrice;
  },
};
