import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'ui-popup-info-hint',
  templateUrl: './popup-info-hint.component.html',
  styleUrls: ['./popup-info-hint.component.scss'],
})
export class PopupInfoHintComponent {
  @ViewChild('customTooltip') tooltip: MatTooltip;
  @Input() message: string;
  @Input() hideDelay = 9999999;
  tooltipDisabled = true;

  showTooltip() {
    this.tooltipDisabled = false;
    setTimeout(() => this.tooltip.show(), 0);
  }

  hideTooltip() {
    this.tooltipDisabled = true;
    this.tooltip.hide();
  }
}
