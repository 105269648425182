import { Action } from '@ngrx/store';

import { AuthCredentials } from './auth.reducer';
import { Account, User, VaristarProfile } from '@varistar-apps/shared/data';

export enum AuthActionTypes {
  GetUser = '[Auth] Get user',
  Authenticated = '[Auth] Authenticated',
  NotAuthenticated = '[Auth] Not Authenticated',
  AuthError = '[Auth] Error',
  GetVaristarProfile = '[Auth] Get Varistar Profile',
  Authorized = '[Auth] Authorized',
  NotAuthorized = '[Auth] Not Authorized',

  SignUpByEmail = '[Auth] SignUp By Email',
  LoginByEmail = '[Auth] Login By Email',
  LoginByGoogle = '[Auth] Login By Google',
  LoginByFacebook = '[Auth] Login By Facebook',
  LoginByMicrosoft = '[Auth] Login By Microsoft',
  Logout = '[Auth] Logout',

  // UpdateAccount = '[Auth] Update Account',
  AccountUpdated = '[Auth] Account Updated',
  VaristarProfileUpdated = '[Auth] Varistar User Profile Updated',

  // AuthSuccess = '[Auth] Success',
  // AuthFailure = '[Auth] Failure',
  // AuthPermissionDenied = '[Auth] Permission denied',
  // AuthStateChanged = '[Auth] State changed',
  // RedirectToLogin = '[Auth] Redirect to Login',
  // UpdateUser = '[Auth] Update user',
  // UpdateSuccess = '[Auth] Update Success',
  // UpdateFailure = '[Auth] Update Failure',
}

// export class LoadAuth implements Action {
//   readonly type = AuthActionTypes.LoadAuth;
// }

// export class AuthLoadError implements Action {
//   readonly type = AuthActionTypes.AuthLoadError;
//   constructor(public payload: any) {}
// }

// export class AuthLoaded implements Action {
//   readonly type = AuthActionTypes.AuthLoaded;
//   constructor(public payload: Entity[]) {}
// }

// Get User AuthState

export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;
  constructor(public payload?: any) {}
}

export class Authenticated implements Action {
  readonly type = AuthActionTypes.Authenticated;
  constructor(public payload?: any) {}
}

export class NotAuthenticated implements Action {
  readonly type = AuthActionTypes.NotAuthenticated;
  constructor(public payload?: any) {}
}

export class AuthError implements Action {
  readonly type = AuthActionTypes.AuthError;
  constructor(public payload?: any) {}
}

export class GetVaristarProfile implements Action {
  readonly type = AuthActionTypes.GetVaristarProfile;
  constructor(public payload?: any) {}
}

export class Authorized implements Action {
  readonly type = AuthActionTypes.Authorized;
  constructor(public payload?: Partial<User>) {}
}

export class NotAuthorized implements Action {
  readonly type = AuthActionTypes.NotAuthorized;
  constructor(public payload?: any) {}
}

// SignUp acctions

export class SignUpByEmail implements Action {
  readonly type = AuthActionTypes.SignUpByEmail;
  constructor(readonly payload: AuthCredentials) {}
}

// Login acctions

export class LoginByEmail implements Action {
  readonly type = AuthActionTypes.LoginByEmail;
  constructor(readonly payload: AuthCredentials) {}
}

export class LoginByGoogle implements Action {
  readonly type = AuthActionTypes.LoginByGoogle;
  constructor(readonly payload?: any) {}
}

export class LoginByFacebook implements Action {
  readonly type = AuthActionTypes.LoginByFacebook;
  constructor(readonly payload?: any) {}
}

export class LoginByMicrosoft implements Action {
  readonly type = AuthActionTypes.LoginByMicrosoft;
  constructor(readonly payload?: any) {}
}

// Logout Actions

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
  constructor(readonly payload?: any) {}
}

// Account

// export class UpdateAccount implements Action {
//   type = AuthActionTypes.UpdateAccount;
//   constructor(public payload?: User) { }
//   // constructor(public payload?: IAuthAccount) { }
// }
export class AccountUpdated implements Action {
  type = AuthActionTypes.AccountUpdated;
  constructor(public payload?: Account) {}
}

//  Varistar Profile

export class VaristarProfileUpdated implements Action {
  type = AuthActionTypes.VaristarProfileUpdated;
  // constructor(public payload?: Partial<VaristarProfile>) { }
  constructor(public payload?: any) {}
}

// //

// export class AuthSuccess implements Action {
//   readonly type = AuthActionTypes.AuthSuccess;
//   constructor(readonly payload?: auth.UserCredential) { }
// }

// export class AuthFailure implements Action {
//   readonly type = AuthActionTypes.AuthFailure;
//   constructor(readonly payload: any) { } // error
// }

// export class AuthPermissionDenied implements Action {
//   readonly type = AuthActionTypes.AuthPermissionDenied;
//   constructor(readonly payload: any) { } // error
// }

// // User information / profile

// export class UpdateUser implements Action {
//   readonly type = AuthActionTypes.UpdateUser;
//   constructor(readonly payload?: auth.UserCredential) { }
// }

// export class UpdateSuccess implements Action {
//   readonly type = AuthActionTypes.UpdateSuccess;
//   constructor(readonly payload: AuthData) { }
// }

// export class UpdateFailure implements Action {
//   readonly type = AuthActionTypes.UpdateFailure;
//   constructor(readonly payload: any) { } // error
// }

// //

// export class AuthStateChanged implements Action {
//   readonly type = AuthActionTypes.AuthStateChanged;
//   constructor(readonly payload?: any) { }
// }

// //

// export class RedirectToLogin implements Action {
//   readonly type = AuthActionTypes.RedirectToLogin;
//   constructor(readonly payload?: any) { }
// }

export type AuthAction =
  | GetUser
  | Authenticated
  | NotAuthenticated
  | AuthError
  | GetVaristarProfile
  | Authorized
  | NotAuthorized
  | SignUpByEmail
  | LoginByEmail
  | LoginByGoogle
  | LoginByFacebook
  | LoginByMicrosoft
  | Logout
  // | UpdateAccount
  | AccountUpdated
  | VaristarProfileUpdated;

// | AuthSuccess | AuthFailure | AuthPermissionDenied | AuthStateChanged | UpdateUser | UpdateSuccess | UpdateFailure | RedirectToLogin
//LoadAuth | AuthLoaded | AuthLoadError;

export const fromAuthActions = {
  // LoadAuth,
  // AuthLoaded,
  // AuthLoadError
  GetUser,
  Authenticated,
  NotAuthenticated,
  AuthError,
  GetVaristarProfile,
  Authorized,
  NotAuthorized,

  SignUpByEmail,

  LoginByEmail,
  LoginByGoogle,
  LoginByFacebook,
  LoginByMicrosoft,

  Logout,

  // UpdateAccount,
  AccountUpdated,
  VaristarProfileUpdated,

  // AuthSuccess,
  // AuthFailure,
  // AuthPermissionDenied,
  // UpdateUser,
  // UpdateSuccess,
  // UpdateFailure,
  // AuthStateChanged,
  // RedirectToLogin,
};
