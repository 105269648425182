<mat-form-field (keyup.tab)="$event.stopPropagation()">
  <mat-label>{{ name | translate }}</mat-label>
  <input
    matInput
    #harvestDateInput
    [matDatepicker]="datePicker"
    (blur)="handleInputBlur($event)"
    (dateInput)="handleEdit($event)"
    (input)="handleDirectInput($event)"
    [defaultValue]="_date.format(inputFormat)"
    [max]="(max$ | async)?.toDate()"
    [min]="(min$ | async)?.toDate()"
  />
  <mat-datepicker-toggle tabindex="-1" matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker
    (opened)="isDatePickerOpen = true"
    (closed)="isDatePickerOpen = false"
    #datePicker
    startView="month"
    [startAt]="_date?.toDate()"
    [calendarHeaderComponent]="useCustomHeader ? customMonthHeader : undefined"
  >
  </mat-datepicker>
</mat-form-field>
