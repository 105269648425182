import { Column, Entity, Index, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TPlodinyEagri } from './TPlodinyEagri';

@Index('t_odberove_normativy_UN', ['idPlodiny'], { unique: true })
@Entity('t_odberove_normativy', { schema: 'VaristarIS' })
export class TOdberoveNormativy {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_odberove_normativy' })
  idOdberoveNormativy: number;

  @Column('int', { name: 'id_plodiny', unique: true })
  idPlodiny: number;

  @Column('decimal', {
    name: 'hp_n',
    nullable: true,
    comment: 'hlavni produkt',
    precision: 6,
    scale: 2,
  })
  hpN: string | null;

  @Column('decimal', { name: 'hp_p', nullable: true, precision: 6, scale: 2 })
  hpP: string | null;

  @Column('decimal', { name: 'hp_k', nullable: true, precision: 6, scale: 2 })
  hpK: string | null;

  @Column('decimal', {
    name: 'vp_n',
    nullable: true,
    comment: 'vedlejsi produkt',
    precision: 6,
    scale: 2,
  })
  vpN: string | null;

  @Column('decimal', { name: 'vp_p', nullable: true, precision: 6, scale: 2 })
  vpP: string | null;

  @Column('decimal', { name: 'vp_k', nullable: true, precision: 6, scale: 2 })
  vpK: string | null;

  @OneToOne('TPlodinyEagri', 'tPlodinyEagri.tOdberoveNormativy', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_plodiny', referencedColumnName: 'idtPlodinyEagri' }])
  idPlodiny2: TPlodinyEagri;
}
