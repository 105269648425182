import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';

import { TTabletMachinesType } from './TTabletMachinesType';
import { TTabletMachinesUnits } from './TTabletMachinesUnits';
import { TTabletProtocolSpeed } from './TTabletProtocolSpeed';
import { TTerminaly } from './TTerminaly';
import { TZakazkyTerminal } from './TZakazkyTerminal';

@Index('m_protocol_speed', ['mProtocolSpeed'], {})
@Index('t_machine_type_idx', ['mType'], {})
@Index('t_terminal_idx', ['idTerminal'], {})
@Index('t_unit_machines_idx', ['mUnits'], {})
@Entity('t_tablet_machines', { schema: 'VaristarIS' })
export class TTabletMachines {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_tablet_machines' })
  idtTabletMachines: number;

  @Column('int', {
    name: 'id_terminal',
    nullable: true,
    comment: 'Cizí klíč do tabulky t_terminaly',
  })
  idTerminal: number | null;

  @Column('int', {
    name: 'm_type',
    nullable: true,
    comment:
      'Typ stroje: 1. Rozemtadlo, 2. Postřikovač,  3. Secí stroj,  4.Jiný',
  })
  mType: number | null;

  @Column('varchar', {
    name: 'm_description',
    nullable: true,
    comment: 'Výrobce a označení stroje např. Kuhn Axis 30.1',
    length: 255,
  })
  mDescription: string | null;

  @Column('varchar', {
    name: 'm_terminal_brand_type',
    nullable: true,
    comment: 'Typové označení terminálu stroje na Rauch Quantron M.',
    length: 155,
  })
  mTerminalBrandType: string | null;

  @Column('int', {
    name: 'm_protocol_speed',
    nullable: true,
    comment:
      'Cizí klíč z tabulky t_tablet_protokol_baud - udává rychlost protokolu zvoleného v poli m_protokol',
  })
  mProtocolSpeed: number | null;

  @Column('int', {
    name: 'm_units',
    nullable: true,
    comment:
      'Jednotky v jakých stroj dávkuje:.  1. kg/ha.  2. l/ha.  3. t/ha.  4. jednici/ha.  5. tis. jedinců/ha.  6. m3/ha.  7. jedniců/bm.  8. jedniců/m2.  ',
  })
  mUnits: number | null;

  @Column('int', {
    name: 'm_frequency',
    nullable: true,
    comment:
      "Frekvence jak často odesílá terminál data do aplikační techniky - default 1 (rozsah je 1-10sec)",
    default: () => "'1'",
  })
  mFrequency: number | null;

  @Column('varchar', {
    name: 'm_isoyl_kabel_cislo',
    nullable: true,
    comment:
      'Číslo kabelu iSoyl u iSoyl nebo MAC adresa Elfin EW10 u Varistar Tablet',
    length: 45,
  })
  mIsoylKabelCislo: string | null;

  @Column('varchar', {
    name: 'm_adaptor',
    nullable: true,
    comment: 'Typ RS232 - rozhraní pro terminál. (A, L, P atd.)',
    length: 60,
  })
  mAdaptor: string | null;

  @Column('varchar', {
    name: 'm_obsluha_jmeno',
    nullable: true,
    comment: 'Jméno obsluhy stroje',
    length: 250,
  })
  mObsluhaJmeno: string | null;

  @Column('varchar', {
    name: 'm_obsluha_telefon',
    nullable: true,
    comment: 'Telefon na obsluhu stroje',
    length: 90,
  })
  mObsluhaTelefon: string | null;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    width: 1,
    default: () => "'0'",
  })
  deactivated: boolean | null;

  @Column('varchar', {
    name: 'vuc_ssid',
    nullable: true,
    comment:
      'SSID pro připojení k secím strojů Väderstad přes Vaderstad Universal Control Interface',
    length: 32,
  })
  vucSsid: string | null;

  @Column('varchar', {
    name: 'm_vt_name',
    nullable: true,
    comment:
      'Virtual Terminal Name -> CAN meno virtuálneho terminálu /string – je to reťazec presne 16-tich znakov 0-9, A-F/a-f',
    length: 16,
  })
  mVtName: string | null;

  @Column('varchar', {
    name: 'm_impl_name',
    nullable: true,
    comment:
      'Implement Name -> CAN meno stroja /string - je to reťazec presne 16-tich znakov 0-9, A-F/a-f',
    length: 16,
  })
  mImplName: string | null;

  @Column('smallint', {
    name: 'm_obj_id',
    nullable: true,
    comment: 'Object ID -> identifikácia objektu pre zmenu dávky /0-65535',
    unsigned: true,
  })
  mObjId: number | null;

  @Column('tinyint', {
    name: 'm_send_resp',
    nullable: true,
    comment:
      'Send Response -> pošli message do VT ak neposlal stroj  /0-255   0 znamená ignoruj a neposielaj nič, iné číslo znamená hlavička správy',
    unsigned: true,
  })
  mSendResp: number | null;

  @Column('smallint', {
    name: 'm_multiplier',
    nullable: true,
    comment:
      'násobiteľ dávky:  výsledná dávka = (rate * multiplier)    /1-1000',
    unsigned: true,
  })
  mMultiplier: number | null;

  @Column('tinyint', {
    name: 'm_input_ver',
    nullable: true,
    comment:
      'Input Version  >-určuje či message „Input“ pôjde do stroja ako 255 (rate should change) alebo ako 0 (rate has changed) /0-255',
    unsigned: true,
  })
  mInputVer: number | null;

  @Column('varchar', {
    name: 'm_firmware',
    nullable: true,
    comment: 'nahraty firmware u Wifi SCUL, Wifi VT',
    length: 20,
  })
  mFirmware: string | null;

  @Column('int', {
    name: 'm_working_width',
    nullable: true,
    comment: 'pracovni sirka (m) pro Wifi SCUL, Wifi VT',
  })
  mWorkingWidth: number | null;

  @Column('int', {
    name: 'm_reaction_time',
    nullable: true,
    comment: 'reakcni doba (s) pro Wifi SCUL, Wifi VT',
  })
  mReactionTime: number | null;

  @Column('int', {
    name: 'm_distance_from_terminal',
    nullable: true,
    comment: 'vzdalenost od terminalu (m) pro Wifi SCUL, Wifi VT',
  })
  mDistanceFromTerminal: number | null;

  @Column('int', {
    name: 'm_resp_obj_id',
    nullable: true,
    comment:
      'identifikace objektu pro zobrazeni davky na obrazovce terminalu (0-65535) pro Wifi SCUL',
  })
  mRespObjId: number | null;

  // @ManyToOne(
  //   () => TTabletProtocolSpeed,
  //   (tTabletProtocolSpeed) => tTabletProtocolSpeed.tTabletMachines,
  //   { onDelete: 'CASCADE', onUpdate: 'CASCADE' }
  // )
  @ManyToOne('TTabletProtocolSpeed', 'tTabletMachines')
  @JoinColumn([{ name: 'm_protocol_speed', referencedColumnName: 'idTabletProtocolSpeed' }])
  mProtocolSpeed2: TTabletProtocolSpeed;

  @ManyToOne('TTerminaly', 'tTabletMachines')
  @JoinColumn([{ name: 'id_terminal', referencedColumnName: 'idTerminaly' }])
  idTerminal2: TTerminaly;

  @ManyToOne('TTabletMachinesType', 'tTabletMachines', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'm_type', referencedColumnName: 'idtTabletMachinesType' }])
  mType2: TTabletMachinesType;

  @ManyToOne('TTabletMachinesUnits', 'tTabletMachines')
  @JoinColumn([{ name: 'm_units', referencedColumnName: 'idtTabletMachinesUnits' }])
  mUnits2: TTabletMachinesUnits;

  @OneToMany('TZakazkyTerminal', 'idTabletMachine2')
  tZakazkyTerminals: TZakazkyTerminal[];
}
