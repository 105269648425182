<dynamic-form
  layout="card"
  header=""
  description=""
  [controls]="controls"
  [data]="data"
  (save)="onSave($event)"
  (delete)="onDelete($event)"
  [enable]="enable"
  [resetWhenChanged]="resetWhenChanged"
></dynamic-form>
