import { Column, Entity } from 'typeorm';

// import { TTerminaly } from "./TTerminaly";

@Entity('t_tablet_settings', { schema: 'VaristarIS' })
export class TTabletSettings {
  @Column('int', {
    primary: true,
    name: 'id_terminaly',
    comment: 'Primární klíč a zároveň cizí klíč do tabulky t_terminaly',
  })
  idTerminaly: number;

  @Column('int', {
    name: 'language',
    nullable: true,
    comment: 'Jazyk menu přístroje: 0. Čeština, 1. Angličtina, 2. Slovenština',
    default: () => "'0'",
  })
  language: number | null;

  @Column('int', {
    name: 'map_deactivation',
    nullable: true,
    comment: 'Počet dnů nekativity, kdy se mapa deaktivuje.',
    default: () => "'30'",
  })
  mapDeactivation: number | null;

  @Column('int', {
    name: 'map_delete',
    nullable: true,
    comment: 'Počet dnů nekativity, kdy se mapa smaže počítáno od okamžiku kdy se mapa deaktivuje.',
    default: () => "'30'",
  })
  mapDelete: number | null;

  @Column('varchar', {
    name: 'Elfin_IP',
    nullable: true,
    comment: 'IP adresa Elfin EW10 používaného s daným tabletem.',
    length: 45,
    default: () => "'192.168.043.020'",
  })
  elfinIp: string | null;

  // @OneToOne(() => TTerminaly, (tTerminaly) => tTerminaly.tTabletSettings, {
  // onDelete: "CASCADE",
  // onUpdate: "CASCADE",
  // })
  // @JoinColumn([{ name: "id_terminaly", referencedColumnName: "idTerminaly" }])
  // idTerminaly2: TTerminaly;
}
