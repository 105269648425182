import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { roundSeeding } from '@varistar-apps/shared/utilities';

@Pipe({
  name: 'roundSeed',
})
export class RoundSeedPipe implements PipeTransform {
  transform = (value: string | number) => {
    return roundSeeding(value).toString().replace('.', ',');
  };

  constructor() {}
}
