import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SETTINGS_FEATURE_KEY, SettingsState } from './settings.reducer';

const getSettingsState = createFeatureSelector<SettingsState>(SETTINGS_FEATURE_KEY);

export const getSelectedSeasonId = createSelector(
  getSettingsState,
  (state: SettingsState) => state.selectedSeasonId,
);

export const getSeasonOptionList = createSelector(
  getSettingsState,
  (state: SettingsState) => state.seasonOptionList,
);

export const settingsQuery = {
  getSelectedSeasonId,
  getSeasonOptionList,
};
