import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

// const getLoaded = createSelector(
//   getAuthState,
//   (state: AuthState) => state.loaded
// );
// const getError = createSelector(
//   getAuthState,
//   (state: AuthState) => state.error
// );

// const getAllAuth = createSelector(
//   getAuthState,
//   getLoaded,
//   (state: AuthState, isLoaded) => {
//     return isLoaded ? state.list : [];
//   }
// );
// const getSelectedId = createSelector(
//   getAuthState,
//   (state: AuthState) => state.selectedId
// );
// const getSelectedAuth = createSelector(
//   getAllAuth,
//   getSelectedId,
//   (auth, id) => {
//     const result = auth.find(it => it['id'] === id);
//     return result ? Object.assign({}, result) : undefined;
//   }
// );

export const getLoading = createSelector(getAuthState, (state: AuthState) => state.loading);
export const getAuthenticated = createSelector(
  getAuthState,
  (state: AuthState) => state.authenticated,
);
export const getAuthorized = createSelector(getAuthState, (state: AuthState) => state.authorized);
export const getUser = createSelector(
  getAuthState,
  // (state: AuthState) => state.user
  (state: AuthState) => (state.account && state.account?.user ? state.account?.user : null),
);
export const getAccount = createSelector(getAuthState, (state: AuthState) => state.account);
export const getRole = createSelector(
  getAuthState,
  (state: AuthState) => null, // state.account.role // !!! FIXIT
);
export const getVaristarProfile = createSelector(
  getAuthState,
  (state: AuthState) => state.varistar,
);
// export const getVaristarProfileUser = createSelector(
//   getAuthState,
//   (state: AuthState) => (state.varistar && state.varistar.user) ? state.varistar.user : null
// );
// export const getVaristar = createSelector(
//   getAuthState,
//   (state: AuthState) => state.varistar
// );
// export const isAuthenticating = createSelector(
//   getAuthState,
//   (state: AuthState) => state.isAuthenticating
// );
// export const isAuthenticated = createSelector(
//   getAuthState,
//   (state: AuthState) => state.isAuthenticated
// );
// export const isLoggedIn = createSelector(
//   getAuthState,
//   (state: AuthState) => state.isLoggedIn
// );
// export const getAuthData = createSelector(
//   getAuthState,
//   (state: AuthState) => state.authData
// );
export const getDisplayName = createSelector(
  getAuthState,
  // (state: AuthState) => state.user.displayName || state.user.email
  (state: AuthState) =>
    state.account && state.account?.user
      ? state.account?.user.displayName || state.account?.user.email
      : null,
);
export const getError = createSelector(getAuthState, (state: AuthState) => state.error);
// export const getUserId = createSelector(
//   getAuthState,
//   (state: AuthState) => (state.userData ? state.userData.userId : null)
// );
// export const getAccounts = createSelector(
//   getAuthState,
//   (state: AuthState) => (state.userData ? state.userData.accounts : {})
// );

export const authQuery = {
  // getLoaded,
  // getError,
  // getAllAuth,
  // getSelectedAuth
  getLoading,
  getAuthenticated,
  getAuthorized,
  getUser,
  getRole,
  getVaristarProfile,
  // isAuthenticating,
  // isAuthenticated,
  // isLoggedIn,
  // getAuthData,
  getDisplayName,
  getError,
};
