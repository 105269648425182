import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { NavigationService } from '../../../../../libs/frontend/ui/src/lib/navigation-service/navigation.service';

@Component({
  selector: 'varistar-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass;

  constructor(
    public overlayContainer: OverlayContainer,
    // changeDetectorRef: ChangeDetectorRef,
    // media: MediaMatcher,
    private http: HttpClient,
    translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    private navigationService: NavigationService,
  ) {
    // private accountApi: AccountApi
    // this.mobileQuery = media.matchMedia('(max-width: 600px)');
    // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // this.mobileQuery.addListener(this._mobileQueryListener);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('cs'); // cs to hlasi browser pri cs-CZ
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('cs');
    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();

    // // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // // // FIXIT: neumim prenest baseUrl do ApiOnsiteServerClientSdkModule.forRoot aby se dala pouzit
    // // LoopBackConfig.setBaseURL(environment.varistarServerUrl); // /api/model si to doplni samo

    // Lokalizace kalendare v datePicker
    this.dateAdapter.setLocale('cs-CZ');
  }

  ngOnInit() {
    // https://github.com/tomastrajan/angular-ngrx-material-starter/blob/b0324cbd51f873ebf2f696d965f14b1a8559be4d/src/app/app.component.ts
    const effectiveTheme = 'varistar-light-theme';
    // const effectiveTheme = 'varistar-dark-theme';
    // // const effectiveTheme = 'varistar-light-theme';
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) => item.includes('-theme'));
    classList.remove(...toRemove);
    classList.add(effectiveTheme);

    this.http
      .get('/api/public/maintenance-page')
      .toPromise()
      .then((response: any) => {
        if (response.maintenancePage) {
          const iframe = document.createElement('iframe');
          var iframe_style = 'display: block; width: 100%; height: 100%; overflow: hidden;';
          var ifattr = {
            style: iframe_style,
            scrolling: 'no',
            frameBorder: 0,
            // id: 'my_iframe', width: '520', height: '300', 'scrolling': 'no', 'marginWidth': 0,
            // 'marginHeight': 0, 'noResize': 0, 'border': 0, 'frameBorder': 0, 'frameSpacing': 0,
            // 'background': 'transparent', 'allowTransparency': 'allowTransparency',
            // 'name': 'my_iframe', 'style': iframe_style
          };
          for (var i in ifattr) {
            iframe.setAttribute(i, ifattr[i]);
          }

          document.querySelector('html').replaceWith(iframe);
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(response.maintenancePage);
          iframe.contentWindow.document.close();
        }
      });

    // // Moment locale
    // moment.locale('cs');

    // // TEST
  }

  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
