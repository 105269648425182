import { TZakaznikOsoby } from './entities/TZakaznikOsoby';

export const CustomerPersonRoleOld = {
  1: 'Deny',
  2: 'Read',
  4: 'Write',
  8: 'Zobrazit faktury',
  16: 'Admin',
};

// tslint:disable-next-line: no-empty-interface
export interface CustomerPerson extends TZakaznikOsoby {}

export const CustomerPerson = {
  selectId: (a: TZakaznikOsoby) => {
    return a.idtZakaznikUser;
  },
  getRole: (a: TZakaznikOsoby) => {
    return a.role;
  },
};
