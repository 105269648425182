import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { ProductPriceUnitEnum, ProductTypeEnum } from '../product-price.model';
import { TZakaznik } from './TZakaznik';

@Index('t_product_price_FK', ['idZakaznik'], {})
@Entity('t_product_price', { schema: 'VaristarIS' })
export class TProductPrice {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_product_price' })
  idProductPrice: number;

  @Column('int', { name: 'id_zakaznik' })
  idZakaznik: number;

  @Column('date', { name: 'valid_from' })
  validFrom: string;

  @Column('date', { name: 'valid_to' })
  validTo: string;

  @Column('decimal', { name: 'price', precision: 7, scale: 2 })
  price: number;

  @Column('enum', {
    name: 'unit',
    comment: 'Jednotka, za kterou se plati uvedena cena.',
    enum: ['L', 'T', 'M3'],
  })
  unit: ProductPriceUnitEnum;

  @Column('int', { name: 'id_product' })
  idProduct: number;

  @Column('int', { name: 'season_id' })
  seasonId: number;

  @Column('enum', {
    name: 'product_type',
    comment:
      'typ produktu - id_product neni unikatni, je potreba rozlisit custom seed (CS), custom fertilizer (CF), custom pesticid (CP), bought seed (BS), bought fertilizer (BF), bought pesticid (BP)',
    enum: ['CS', 'CF', 'CP', 'BS', 'BF', 'BP'],
  })
  productType: ProductTypeEnum;

  // @ManyToOne('TZakaznik', 'tZakaznik.tProductPrices', {
  //   onDelete: "NO ACTION",
  //   onUpdate: "NO ACTION",
  // })
  // @JoinColumn([{ name: "id_zakaznik", referencedColumnName: "idZakaznik" }])
  // idZakaznik2: TZakaznik;
}
