import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { FrontendUiModuleConfig } from '../ui.interfaces';

export interface Breadcrumb {
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class TitleBarService {
  private breadcrumbs = [];
  readonly breadcrumbs$ = new BehaviorSubject<Array<Breadcrumb>>([]);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Optional() private config: FrontendUiModuleConfig,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((x) => {
      this.breadcrumbs = []; //[this.config];
      this.getBreadcrumbs(this.route.snapshot);
      this.breadcrumbs$.next(this.breadcrumbs);
    });
  }

  private getBreadcrumbs(snapshot) {
    if (snapshot.data && snapshot.data.title) {
      this.breadcrumbs.push(snapshot.data); // co je v datech se ulozi, breadcrumbs ale hlavne potrebuje title, bez toho se neulozi nic
    }
    if (snapshot.children) {
      _.forEach(snapshot.children, (child) => this.getBreadcrumbs(child));
    }
  }
}
