import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontendUiMaterialModule } from '@varistar-apps/frontend/ui-material';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler,
} from 'ngx-translate-messageformat-compiler';
import { FrontendUiModule } from '@varistar-apps/frontend/ui';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { GoogleMapsModule } from '@angular/google-maps';

import { AngularFireModule } from '@angular/fire';
import { FrontendApiVaristarModule } from '@varistar-apps/frontend/api-varistar';
import { AppRedirectComponent } from './app-redirect.component';
import { FrontendUiFeatureModule } from '@varistar-apps/frontend/ui-feature';
import '@angular/common/locales/global/cs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { metaReducers, reducers } from './reducers';
import { GlobalErrorHandler } from './global-error-handler.service';
import { NavigationService } from '../../../../../libs/frontend/ui/src/lib/navigation-service/navigation.service';

const routes = [
  { path: '', pathMatch: 'full', redirectTo: 'layout' }, // routovani na layout a z nej nasledne na portal
  { path: 'portal', pathMatch: 'full', redirectTo: 'layout/portal' }, // shortcut na portal
  { path: 'onboarding', pathMatch: 'full', redirectTo: 'layout/onboarding' }, // shortcut na onboarding
  { path: 'management', pathMatch: 'full', redirectTo: '/layout/management' }, // shortcut na management
  { path: 'viewer', pathMatch: 'full', redirectTo: '/layout/viewer' }, // shortcut na viewer
  {
    path: 'layout',
    loadChildren: () =>
      import('@varistar-apps/frontend/ui-layout').then((m) => m.FrontendUiLayoutModule),
  },
  {
    path: 'redirect',
    component: AppRedirectComponent,
  },
  // { path: '**', redirectTo: '/redirect' },
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, AppRedirectComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      // enableTracing: true, // <-- debugging purposes only
      // preloadingStrategy: PreloadAllModules
      scrollPositionRestoration: 'disabled',
    }),
    BrowserAnimationsModule,
    FrontendUiMaterialModule,
    HttpClientModule,
    GoogleMapsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      // https://github.com/lephyrus/ngx-translate-messageformat-compiler
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    // Moduly predelane na centralni inicializaci
    FrontendUiModule.forRoot({ title: 'TITLE.APP_NAME' }),
    // FrontendUiModule.forRoot({}),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {},
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule, // Titul aplikace v OnsiteTitleBarService, breadcrumbs jsou za nim, definovane jako route
    StoreRouterConnectingModule.forRoot(),
    // EntityDataModule.forRoot(entityConfig),
    FrontendUiFeatureModule.forRoot(environment.feature),

    AngularFireModule.initializeApp(environment.firebase),
    FrontendApiVaristarModule.forRoot(environment.varistar),
    // ApiOcsModule.forRoot(environment.ocs),
    NgxChartsModule,
  ],
  providers: [
    NavigationService,
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['en', 'cs', 'sk'] },
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig }, // NOTE: nefungovalo v FeatureConfigurationModule, presunuto do app.module
    // { provide: MatPaginatorIntl, useClass: TranslatedPaginator },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
