import { EntityMetadataMap, EntityDataModuleConfig, DefaultDataServiceConfig } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {};

const pluralNames = {};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames,
};

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: 'api', // NOTE: pokud je api/[entityname] tak je zbytecne, generuje se automaticky, ale mozno upravit
  // timeout: 3000, // request timeout
};
