import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

@Index('OGR_FID', ['ogrFid'], { unique: true })
@Entity('t_LPIS_pole', { schema: 'VaristarIS' })
export class TLpisPole {
  @PrimaryGeneratedColumn({ type: 'int', name: 'OGR_FID' })
  ogrFid: number;

  @Column('decimal', { name: 'FID', nullable: true, precision: 10, scale: 0 })
  fid: string | null;

  @Column('decimal', {
    name: 'ID_DPB',
    nullable: true,
    precision: 10,
    scale: 0,
  })
  idDpb: string | null;

  @Column('varchar', { name: 'NKOD_DPB', nullable: true, length: 13 })
  nkodDpb: string | null;

  @Column('varchar', { name: 'CTVEREC', nullable: true, length: 8 })
  ctverec: string | null;

  @Column('varchar', { name: 'ZKOD_DPB', nullable: true, length: 8 })
  zkodDpb: string | null;

  @Column('decimal', { name: 'ID_UZ', nullable: true, precision: 10, scale: 0 })
  idUz: string | null;

  @Column('date', { name: 'PLATNYOD', nullable: true })
  platnyod: string | null;

  @Column('date', { name: 'UCOD_AKTUA', nullable: true })
  ucodAktua: string | null;

  @Column('double', { name: 'VYMERAM', nullable: true, precision: 7, scale: 2 })
  vymeram: number | null;

  @Column('varchar', { name: 'KULTURANAZ', nullable: true, length: 254 })
  kulturanaz: string | null;

  @Column('varchar', { name: 'KULTURAKOD', nullable: true, length: 16 })
  kulturakod: string | null;

  @Column('decimal', {
    name: 'KULTURA',
    nullable: true,
    precision: 4,
    scale: 0,
  })
  kultura: string | null;

  @Column('date', { name: 'KULTURAOD', nullable: true })
  kulturaod: string | null;

  @Column('decimal', { name: 'EKO', nullable: true, precision: 10, scale: 0 })
  eko: string | null;

  @Column('double', {
    name: 'ANCLFA_H1',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaH1: number | null;

  @Column('double', {
    name: 'ANCLFAH1_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfah1_4: number | null;

  @Column('double', {
    name: 'ANCLFA_H2',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaH2: number | null;

  @Column('double', {
    name: 'ANCLFAH2_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfah2_4: number | null;

  @Column('double', {
    name: 'ANCLFA_H3',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaH3: number | null;

  @Column('double', {
    name: 'ANCLFAH3_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfah3_4: number | null;

  @Column('double', {
    name: 'ANCLFA_H4',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaH4: number | null;

  @Column('double', {
    name: 'ANCLFAH4_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfah4_4: number | null;

  @Column('double', {
    name: 'ANCLFA_H5',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaH5: number | null;

  @Column('double', {
    name: 'ANCLFAH5_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfah5_4: number | null;

  @Column('double', {
    name: 'ANCLFA_OA',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaOa: number | null;

  @Column('double', {
    name: 'ANCLFA_OB',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaOb: number | null;

  @Column('double', {
    name: 'ANCLFA_O1',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaO1: number | null;

  @Column('double', {
    name: 'ANCLFAO1_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfao1_4: number | null;

  @Column('double', {
    name: 'ANCLFA_O2',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaO2: number | null;

  @Column('double', {
    name: 'ANCLFAO2_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfao2_4: number | null;

  @Column('double', {
    name: 'ANCLFA_O3',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaO3: number | null;

  @Column('double', {
    name: 'ANCLFAO3_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfao3_4: number | null;

  @Column('double', {
    name: 'ANCLFA_S',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaS: number | null;

  @Column('double', {
    name: 'ANCLFAS_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfas_4: number | null;

  @Column('double', {
    name: 'ANCLFA_XOA',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaXoa: number | null;

  @Column('double', {
    name: 'ANCLFAXOA_',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfaxoa: number | null;

  @Column('double', {
    name: 'ANCLFA_XOB',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  anclfaXob: number | null;

  @Column('double', {
    name: 'ANCLFAXOB_',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  anclfaxob: number | null;

  @Column('double', {
    name: 'VYMERA_ECP',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  vymeraEcp: number | null;

  @Column('double', {
    name: 'VY_ECP_ZME',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vyEcpZme: number | null;

  @Column('double', { name: 'VYSKA', nullable: true, precision: 7, scale: 2 })
  vyska: number | null;

  @Column('double', {
    name: 'SVAZITOST',
    nullable: true,
    precision: 5,
    scale: 2,
  })
  svazitost: number | null;

  @Column('varchar', { name: 'MAPOVYLIST', nullable: true, length: 8 })
  mapovylist: string | null;

  @Column('varchar', { name: 'MAPVLST5', nullable: true, length: 48 })
  mapvlst5: string | null;

  @Column('decimal', { name: 'KU_KOD', nullable: true, precision: 6, scale: 0 })
  kuKod: string | null;

  @Column('varchar', { name: 'PRISL_OPZL', nullable: true, length: 254 })
  prislOpzl: string | null;

  @Column('decimal', {
    name: 'OKRES_KOD',
    nullable: true,
    precision: 12,
    scale: 0,
  })
  okresKod: string | null;

  @Column('double', {
    name: 'VYMNAT_PTA',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vymnatPta: number | null;

  @Column('double', {
    name: 'VNAT_PTA_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  vnatPta_4: number | null;

  @Column('double', {
    name: 'VYMNAT_EVL',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vymnatEvl: number | null;

  @Column('double', {
    name: 'VNAT_EVL_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  vnatEvl_4: number | null;

  @Column('double', {
    name: 'VYMVZCHU',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vymvzchu: number | null;

  @Column('double', {
    name: 'VVZCHU_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  vvzchu_4: number | null;

  @Column('double', {
    name: 'VYMVZCHU1',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vymvzchu1: number | null;

  @Column('double', {
    name: 'VVZCHU1_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  vvzchu1_4: number | null;

  @Column('double', {
    name: 'VYMMZCHU',
    nullable: true,
    precision: 9,
    scale: 2,
  })
  vymmzchu: number | null;

  @Column('double', {
    name: 'VMZCHU_4',
    nullable: true,
    precision: 9,
    scale: 4,
  })
  vmzchu_4: number | null;

  @Column('decimal', {
    name: 'FB_STAV',
    nullable: true,
    precision: 2,
    scale: 0,
  })
  fbStav: string | null;

  @Column('varchar', { name: 'ZTRV_DSO', nullable: true, length: 15 })
  ztrvDso: string | null;

  @Column('double', {
    name: 'DELKA_DSO',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  delkaDso: number | null;

  @Column('varchar', { name: 'HLADINA1', nullable: true, length: 11 })
  hladina1: string | null;

  @Column('varchar', { name: 'HLADINA2', nullable: true, length: 11 })
  hladina2: string | null;

  @Column('varchar', { name: 'HLADINA3', nullable: true, length: 11 })
  hladina3: string | null;

  @Column('varchar', { name: 'VYM_III_AP', nullable: true, length: 11 })
  vymIiiAp: string | null;

  @Column('varchar', { name: 'EROZOHRO', nullable: true, length: 8 })
  erozohro: string | null;

  @Column('double', { name: 'ZPUSOB', nullable: true, precision: 7, scale: 2 })
  zpusob: number | null;

  @Column('double', {
    name: 'NEZPUSOB',
    nullable: true,
    precision: 7,
    scale: 2,
  })
  nezpusob: number | null;

  @Column('varchar', { name: 'UPO', nullable: true, length: 15 })
  upo: string | null;
}
