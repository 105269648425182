<div class="c-content-table">
  <table
    class="fields-edit-table"
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="ctverec"
    matSortDisableClear
    matSortDirection="asc"
    (matSortChange)="onMatSortChange($event)"
  >
    <ng-container matColumnDef="vyber">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'PLACEHOLDER.FIELDS.VYBER' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let field; let i = index"
        [routerLink]="[]"
        [queryParams]="{ editId: field.idtLpisSelect }"
        queryParamsHandling="merge"
        routerLinkActive="active"
      >
        <custom-checkbox
          [checked]="field.vyber"
          (change)="onSelectField($event, field)"
        ></custom-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="jmeno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'PLACEHOLDER.FIELDS.JMENO' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let field; let i = index"
        [routerLink]="[]"
        [queryParams]="{ editId: field.idtLpisSelect }"
        routerLinkActive="active"
        queryParamsHandling="merge"
        [attr.colspan]="editId === field.idtLpisSelect && !field.selectEnd ? 3 : 1"
        [ngClass]="{ finished: !!field.selectEnd }"
      >
        <div *ngIf="editId !== field.idtLpisSelect || !!field.selectEnd">
          {{ field.jmeno }}
        </div>
        <div *ngIf="editId === field.idtLpisSelect && !field.selectEnd">
          <dynamic-form
            layout="simple"
            [controls]="dataSource.data.length === 1 ? controlsInit : controlsNormal"
            [data$]="data$"
            (save)="onSave($event)"
            (presskey)="onSave($event.data)"
            [enable]="enable"
            resetWhenChanged="zkodDpb"
            [autosave]="true"
          >
          </dynamic-form>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="plodina">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'PLACEHOLDER.FIELDS.PLODINA' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let field; let i = index"
        [routerLink]="[]"
        [queryParams]="{ editId: field.idtLpisSelect }"
        queryParamsHandling="merge"
        routerLinkActive="active"
        [style.display]="editId === field.idtLpisSelect && !field.selectEnd ? 'none' : ''"
        [ngClass]="{ finished: !!field.selectEnd }"
      >
        <div *ngIf="editId !== field.idtLpisSelect || !!field.selectEnd">
          {{ field.plodina }}
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="vymeramVyber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'PLACEHOLDER.FIELDS.VYMERAM_VYBER' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let field; let i = index"
        [routerLink]="[]"
        [queryParams]="{ editId: field.idtLpisSelect }"
        queryParamsHandling="merge"
        routerLinkActive="active"
        [style.display]="editId === field.idtLpisSelect && !field.selectEnd ? 'none' : ''"
        [ngClass]="{ finished: !!field.selectEnd }"
      >
        <div *ngIf="editId !== field.idtLpisSelect || !!field.selectEnd">
          {{ field.vymeramVyber }}
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span *ngIf="fieldsTotal">
          {{ 'PLACEHOLDER.FIELDS.SELECTED' | translate }}
          <b>
            {{ fieldsTotal.selected | numberCustom: '0.0-2' : 'cs' }}
          </b>
          {{ 'PLACEHOLDER.FIELDS.FROM' | translate }}
          <b>
            {{ fieldsTotal.summary | numberCustom: '0.0-2' : 'cs' }}
          </b>
          ha
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>
        <button
          mat-icon-button
          [disableRipple]="true"
          color="primary"
          [matTooltip]="'BUTTON.ADD_FIELD' | translate"
          matTooltipPosition="above"
          (click)="onAddField()"
          [disabled]="isSplittingField"
        >
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td
        mat-cell
        *matCellDef="let field; let i = index"
        [routerLink]="[]"
        [queryParams]="{ editId: field.idtLpisSelect }"
        queryParamsHandling="merge"
        routerLinkActive="active"
      >
        <div fxLayout="row">
          <button
            mat-icon-button
            [disableRipple]="true"
            color="primary"
            [matTooltip]="'BUTTON.DELETE' | translate"
            matTooltipPosition="above"
            (click)="$event.stopPropagation(); onDeleteField(field.idtLpisSelect)"
            *ngIf="
              this.dataSource.data.length > 1 && activeField?.idtLpisSelect === field?.idtLpisSelect
            "
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tbody>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (mouseover)="setActiveRow(row)"
        (mouseout)="setActiveRow(null)"
        class="selectable"
      >
        {{
          row
        }}
      </tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </tbody>
  </table>
</div>
