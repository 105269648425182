import { TZakazky } from './entities/TZakazky';

// tslint:disable-next-line: no-empty-interface
export interface Application extends TZakazky {}

export const Application = {
  selectId: (a: Application) => {
    //In this case this would be optional since primary key is id
    return a.idZakazky; // TBD: refId ?
  },
  sortByDescription: (a: Application, b: Application): number => {
    return a.popis && a.popis.localeCompare(b.popis);
  },
};

/**
 "Typ aplikace - bude řešeno separátní tabulkou s popisem
*/
export enum AppType {
  /** Variabilní hnojení dle mapy RVP, */
  YP_MAP_FERTILIZATION = 1,
  /* Variabilní setí dle mapy RVP */
  YP_MAP_SEEDING = 2,
  /**  Pesticidy dle mapy RVP */
  YP_MAP_PESTICIDES = 3,
  /** Základní hnojení (P,K) dle mapy RVP a rozborů půdy */
  PRE_PLANTING_FERTILIZATION = 4,
  /** Variabilní hnojení dle mapy RVP + aktuální stav */
  NDVI_YP_FERTILIZATION = 5,
  /** Pesticidy – dle aktuálního stavu */
  NDVI_PESTICIDES = 6,
  /** Hnojení - dle aktuálního stavu */
  NDVI_FERTILIZATION = 7,
  /** Uniformní hnojení (na každém místě pole stejně) */
  UNIFORM_FERTILIZATION = 8,
  LIMING = 9,
  /** Uniformní Pesticidy */
  UNIFORM_PESTICIDES = 10,
  /** Uniformní setí */
  UNIFORM_SEEDING = 11,
  /** Tankmix - ředění malého přípravku ve vodě a pokropení (jako sirup) */
  TANKMIX = 12,
}
