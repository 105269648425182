import { TOdberoveNormativy } from './entities/TOdberoveNormativy';

// tslint:disable-next-line: no-empty-interface
export interface ConsumptionStandard extends TOdberoveNormativy {}

export const ConsumptionStandard = {
  selectId: (a: TOdberoveNormativy) => {
    return a.idOdberoveNormativy;
  },
};
