import { Action } from '@ngrx/store';
import {
  Account,
  Access,
  Organization,
  Company,
  Person,
  Resource,
  Profile,
  Lpis,
  OldMapLayer,
  // Deal,
  // TradingPlatform,
  // TradePoint,
  // Trader,
  // Customer,
  // Player,
  // Season,
} from '@varistar-apps/shared/data';

export enum ConfigurationFeature {
  Access = 'Access',
  Account = 'Account',
  Company = 'Company',
  Organization = 'Organization',
  Person = 'Person',
  Resource = 'Resource',
  Profile = 'Profile',
  Lpis = 'Lpis',
  MapLayer = 'MapLayer',
  // Trader = 'Trader',
  // Customer = 'Customer',
  // Deal = 'Deal',
  // TradingPlatform = 'TradingPlatform',
  // TradePoint = 'TradePoint',
  // Player = 'Player',
  // Season = 'Season',
  // Role = 'Role',
}

export type ConfigurationPayload =
  | Access
  | Account
  | Company
  | Organization
  | Person
  | Resource
  | Profile
  | Lpis
  | OldMapLayer;
// | Trader
// | Customer
// | Deal
// | TradingPlatform
// | TradePoint
// | Player
// | Season

export enum ConfigurationActionTypes {
  Load = '[Configuration] Load',
  Query = '[Configuration] Query',
  Clear = '[Configuration] Clear',

  Select = '[Configuration] Select',

  Loaded = '[Configuration] Loaded',
  Added = '[Configuration] Added',
  Modified = '[Configuration] Modified',
  Set = '[Configuration] Set',
  Removed = '[Configuration] Removed',

  Update = '[Configuration] Update',
  UpdateSuccess = '[Configuration] Update Success',

  FirebaseError = '[Configuration] Firebase Error',
}

// Initial query

export class LoadConfiguration implements Action {
  readonly type = ConfigurationActionTypes.Load;
  constructor(
    public feature: ConfigurationFeature,
    public payload: any, // ids parametry
  ) {}
}

export class ConfigurationLoaded implements Action {
  readonly type = ConfigurationActionTypes.Loaded;
  constructor(
    public feature: ConfigurationFeature,
    public payload: any[],
  ) {}
}

// Initial query

export class QueryConfiguration implements Action {
  readonly type = ConfigurationActionTypes.Query;
  constructor(
    public feature: ConfigurationFeature,
    public params: any, // parametry nahradu collection parth v Firestore.getParh
    public query: any[], // query oblect
  ) {}
}

// Clear all data after logout

export class ClearConfiguration implements Action {
  readonly type = ConfigurationActionTypes.Clear;
  constructor(public feature: ConfigurationFeature) {}
}

// Select one Configuration

export class SelectConfiguration implements Action {
  readonly type = ConfigurationActionTypes.Select;
  constructor(
    public feature: ConfigurationFeature,
    public id: string, // public payload: ConfigurationPayload
  ) {}
}

// AngularFire2 StateChanges

export class ConfigurationAdded implements Action {
  readonly type = ConfigurationActionTypes.Added;
  constructor(
    public feature: ConfigurationFeature,
    public payload: ConfigurationPayload,
  ) {}
}

export class ConfigurationModified implements Action {
  readonly type = ConfigurationActionTypes.Modified;
  constructor(
    public feature: ConfigurationFeature,
    public payload: ConfigurationPayload,
  ) {}
}

export class ConfigurationSet implements Action {
  readonly type = ConfigurationActionTypes.Set;
  constructor(
    public feature: ConfigurationFeature,
    public payload: ConfigurationPayload,
  ) {}
}

export class ConfigurationRemoved implements Action {
  readonly type = ConfigurationActionTypes.Removed;
  constructor(
    public feature: ConfigurationFeature,
    public id: string, // public payload: ConfigurationPayload
  ) {}
}

// Firestore update

export class UpdateConfiguration implements Action {
  readonly type = ConfigurationActionTypes.Update;
  constructor(
    public feature: ConfigurationFeature,
    public id: string,
    public changes: Partial<ConfigurationPayload>,
  ) {}
}

export class UpdateConfigurationSuccess implements Action {
  readonly type = ConfigurationActionTypes.UpdateSuccess;
}

//

export class ConfigurationFirebaseError implements Action {
  readonly type = ConfigurationActionTypes.FirebaseError;
  constructor(public payload?: any) {}
}

export type ConfigurationAction = // LoadConfiguration | ConfigurationLoaded | ConfigurationLoadError;

    | LoadConfiguration
    | QueryConfiguration
    | ClearConfiguration
    | SelectConfiguration
    | ConfigurationAdded
    | ConfigurationModified
    | ConfigurationSet
    | ConfigurationRemoved
    | UpdateConfiguration
    | UpdateConfigurationSuccess
    | ConfigurationFirebaseError;

export const fromConfigurationActions = {
  LoadConfiguration,
  QueryConfiguration,
  ClearConfiguration,

  SelectConfiguration,

  ConfigurationAdded,
  ConfigurationModified,
  ConfigurationSet,
  ConfigurationRemoved,

  UpdateConfiguration,
  UpdateConfigurationSuccess,

  ConfigurationFirebaseError,
};
