import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent implements OnInit {
  @Input() tooltip = '';
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
