import { TSeedCustom } from './entities/TSeedCustom';

// tslint:disable-next-line: no-empty-interface
export interface SeedCustom extends TSeedCustom {
  type: string; //"Nazev" from t_ciselnik_druh
  // isGrain: boolean, // from t_ciselnik_druh
}

export const SeedCustom = {
  selectId: (x: TSeedCustom) => {
    return x.idSeedCustom;
  },
  filterFn: (seedList: TSeedCustom[]) => {
    return seedList.filter((seed) => {
      return !seed.deactivated;
    });
  },
};
