// helper function, find min & max values of the GeoJSON -> feature collection, by property name:
// use: const minmax: [] = getMinMax(featureCollection, "YP"); // will find min max values of property
import { MapPalette } from '@varistar-apps/shared/data';

export const getMinMax = (featureCollection, propertyName: string, schema: string = '') => {
  if (featureCollection && featureCollection.features) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    featureCollection.features.forEach((feature) => {
      if (feature.properties) {
        const property = feature.properties[propertyName];
        if (property < min) min = property;
        if (property > max) max = property;
      }
    });

    return [Math.floor(min), Math.ceil(max) + (schema === 'P' ? 0 : 1)];
  }
  return null;
};
export const getMinMaxS = (features, propertyName: string, schema: string = '') => {
  if (features) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;
    features.forEach((feature) => {
      if (feature.properties) {
        const property = feature.properties[propertyName];
        if (property < min) min = property;
        if (property > max) max = property;
      }
    });

    return [Math.floor(min), Math.ceil(max) + (schema === 'P' ? 0 : 1)];
  }
  return null;
};

export const defaultMapPalette = [
  '9e0142',
  'd53e4f',
  'f46d43',
  'fdae61',
  'fee08b',
  'ffffbf',
  'e6f598',
  'abdda4',
  '66c2a5',
  '3288bd',
  '5e4fa2',
];

export const addIdToLayerProperties = (data, layerId) => {
  if (data) {
    const { features } = data;
    const featuresLength = features?.length || 0;

    for (let index = 0; index < featuresLength; index++) {
      features[index].properties.layerId = layerId;
    }
  }
};

export const initializeRvpMap = async (map, featureCollection, mainProperty) => {
  map.setAutoZoomOnMapLoad(true);
  map.clearMap();
  map.cleanFeatures();
  map.cleanupPalets();
  map.closePopup();

  await map.redrawFeatures();

  map.setLegendMode('rvp');
  map.setLegendFilteredColor(null, '#FF0000');
  map.setLegendInterval(true);
  map.setLegendLabels(null);

  const minmax = getMinMax(featureCollection, mainProperty);
  map.setMinMax(minmax[0], minmax[1], 0);

  map.setAutoZoomOnMapLoad(true);

  map.setLegendPalette(defaultMapPalette);

  map.setPropertiesFormattingFunction((mapProperties: any, mainProperty: string) => {
    return mapProperties[mainProperty];
  });

  await map.addGeoJsonObjectLayered(featureCollection, 0);
  map.setAutoZoomOnMapLoad(false);

  await map.redrawFeatures();
  map.zoomAll();
};

export const initializePesticideRvpMap = async (
  map,
  featureCollection,
  translations,
  pallette,
  zoneCount,
) => {
  map.setAutoZoomOnMapLoad(true);
  map.clearMap();
  map.cleanFeatures();
  map.cleanupPalets();
  map.closePopup();
  await map.redrawFeatures();

  const labels = {};
  labels[1] = translations[0];
  const count = +zoneCount;
  labels[count] = translations[1];
  map.setLegendMode('rvp' + count);
  map.setLegendPalette(pallette);
  map.setLegendItemsCount(count + 1);
  map.setLegendFilteredColor(null, '#FF0000');
  map.setLegendInterval(false);
  map.setLegendValueLabelSeparator(' - ');
  map.setLegendLabels(labels);

  map.setPropertiesFormattingFunction((mapProperties: any, mainProperty: string) => {
    return mapProperties[mainProperty];
  });

  map.setMinMax(1, count + 1, 0);

  await map.addGeoJsonObjectLayered(featureCollection, 0);
  map.setAutoZoomOnMapLoad(false);

  await map.redrawFeatures();
  map.zoomAll();
};

export const initializeRvpMapNoZoom = (map, featureCollection, mainProperty): Promise<void> => {
  map.setAutoZoomOnMapLoad(false);
  map.zoomSettings(false, false);

  map.clearMap();
  map.cleanFeatures();
  map.cleanupPalets();
  map.closePopup();

  map.redrawFeatures();

  map.setLegendMode('rvp');
  map.setLegendPalette(defaultMapPalette);

  map.setLegendFilteredColor(null, '#FF0000');
  map.setLegendInterval(true);
  map.setLegendLabels(null);

  const minmax = getMinMax(featureCollection, mainProperty);
  map.setMinMax(minmax[0], minmax[1], 0);

  map.setMainProperty(mainProperty);
  map.setPropertiesFormattingFunction((mapProperties: any, mainProperty: string) => {
    return mapProperties[mainProperty];
  });

  map.setFeatures(featureCollection.features, 0);
  map.refresh();

  return null;
};

export const initializeRvpMapNoZoomForEdit = (
  map,
  featureCollection,
  data,
  mainProperty,
): Promise<any> => {
  return new Promise<void>((resolve, reject) => {
    map.setAutoZoomOnMapLoad(false);
    map.zoomSettings(false, false);

    map.interactiveActivateFeature(null);

    map.clearMap();

    const colors = map.getColors();
    for (let i = 0; i < colors.length; ++i) {
      colors[i] = 'rgba(250, 3, 4, .9)';
    }

    map.setLegendMode(null);
    map.setLegendVisible(false);
    // map.setLegendPaletteLayered(['00FF00'], 0);

    map.setBorder('rgba(250, 3, 4, .9)');
    map.setForceFeatureColor('rgba(200, 0, 0, .5)');
    // map.setForceEmptyColor(true, 1);
    // map.setForceEmptyColor(false, 0);
    // map.setLegendFilteredColor(null, "#00FF00");

    map.setLegendItemsCount(10); // kvůli vnitřní paletě barev
    map.setLegendMode('any');

    // main property from where the legend based palette is generated
    map.setMainProperty(mainProperty);
    map.setPropertiesFormattingFunction((mapProperties: any, property: string) => {
      return ''; // no titles - mapProperties[property];
    });

    // skipp initialisation - set data
    featureCollection.forceNoFirst = true;

    featureCollection.features.forEach((feature) => {
      feature.color = '#ffffff';
      feature.line = '#FF0000';
    });

    // GeoJSON object at layer 0
    map.addGeoJsonObjectLayered(featureCollection, 0).then((res) => {
      console.log(res);

      map.setMinMax(0, 255, 0);
      map.setMinMax(0, 255, 1);
      map.setLegendItemsCount(12);
      map.setLegendMode('geotiff');
      map.setGeoTiffC(2.0);

      map.setLegendPaletteLayered(MapPalette['colorbrewer.RYP'], 1);

      map.setLegendLabels(null);

      // geotiff at layer 1
      if (data && true) {
        map.addGeoTiffTilesLayered(data, 1, 0, 1, featureCollection).then(async (a) => {
          await map.redrawFeatures(0, true);

          map.setLegendVisible(false);

          resolve(featureCollection);
        });
      } else {
        map.setLegendVisible(false);

        resolve(featureCollection);
      }
    }); // render GeoJSON
  });
};

export const initializeRvpMapNoZoomForEditFoo = (
  map,
  featureCollection,
  data,
  mainProperty,
  schema,
  clean = true,
  editenabled = true,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    map.setAutoZoomOnMapLoad(false);
    map.zoomSettings(false, false);
    map.setAnimated(false);

    map.interactiveActivateFeature(null);

    if (clean) {
      map.clearMap();
      map.redrawFeatures();
      map.setLegendVisible(true);
    } else {
      map.closePopup();
    }

    if (editenabled) {
      map.setBorder('rgba(250, 3, 4, .9)');
      map.setForceEmptyColor(false, 0);
      map.setLegendFilteredColor('#FF0000', '#FF0000');
    }

    map.clearSelection();

    switch (schema) {
      case 'RVP':
        {
          map.setLegendMode('rvpp');
          map.setLegendInterval(true);
          map.setLegendPaletteLayered(MapPalette['colorbrewer.Spectral'], 0);
          // map.setMinMax(50, 150, 0);

          const minmax = getMinMax(featureCollection, mainProperty);
          const stepCount = (minmax[1] - minmax[0]) / ((150 - 50) / 20);
          map.setLegendItemsCount(stepCount);
          map.setMinMax(minmax[0], minmax[1] - 1, 0);
        }
        break;
      case 'RVP3':
        {
          map.setLegendItemsCount(3);
          map.setLegendMode('rvp3');
          map.setLegendInterval(false);
          map.setLegendFiltering(false);
          map.setLegendPaletteLayered(
            MapPalette['colorbrewer.Spectral' /*'colorbrewer.Spectral'*/],
            0,
          );
          map.setMinMax(1, 4, 0);
        }
        break;
      case 'RVP5':
        {
          map.setLegendItemsCount(5);
          map.setLegendMode('rvp5');
          map.setLegendInterval(false);
          map.setLegendFiltering(false);
          map.setLegendPaletteLayered(
            MapPalette['colorbrewer.Spectral' /*'colorbrewer.Spectral'*/],
            0,
          );
          map.setMinMax(1, 6, 0);
        }
        break;
    }

    // main property from where the legend based palette is generated
    map.setMainProperty(mainProperty);
    map.setPropertiesFormattingFunction((mapProperties: any, property: string) => {
      return mapProperties[property]; // no titles - mapProperties[property];
    });

    // skipp initialisation - set data
    featureCollection.forceNoFirst = true;

    if (editenabled) {
      featureCollection.features.forEach((feature) => {
        // feature.color = "#00FF00";
        feature.line = '#FF0000';
      });
    } else {
      if (map.isEditing()) {
        map.setEdit(false);
      }
    }

    // GeoJSON object at layer 0
    map.addGeoJsonObjectLayered(featureCollection, 0).then((res) => {
      console.log(res);

      map.redrawFeatures();

      if (editenabled) {
        try {
          // map.setEdit(true);
          // map.setEdit(false);
        } catch (e) {}
      }
      // map.setMinMax(0, 200, 0);
      // map.setLegendItemsCount(10);
      // map.setLegendMode("geotiff");
      // map.setGeoTiffC(2.0);
      // map.setLegendPaletteLayered(MapPalette['default'/*'colorbrewer.Spectral'*/], 1);

      // map.setLegendLabels(null);
      // map.setLegendVisible(false);

      resolve();
    }); // render GeoJSON
  });
};

export const getMainPropertyName = (layer) => {
  // GeoJSON only, get all property types in the GeoJSON for the current detail:
  const properties: Set<string> = new Set<string>();

  const { detail } = layer.geojson;

  getProperties(detail.data, properties); // properties now contains all the property names found in all instances

  // ..so you check for the property names if they really exists in data:
  // lets say by default we are interested in "vymera_zon" but some maps have it different
  // so we can construct algorithm to find the right one in a similar fashion:
  let propertyName = 'vymera_zon';
  if (properties.has('yp')) {
    propertyName = 'yp'; // there is probably no "vymera_zon" in this data so try to use "yp"
  } else if (properties.has('vymera_zon')) {
    propertyName = 'vymera_zon';
  }

  if (layer.schema === 'SS') {
    propertyName = 'zone';
  }

  return propertyName;
};

// helper function, copy unique property names from all features
// in the GeoJSON featureCollection to the propertiesList set:
export const getProperties = (featureCollection, propertiesList: Set<string> | null) => {
  if (featureCollection && featureCollection.features) {
    featureCollection.features.forEach((feature) => {
      if (feature.properties) {
        Object.keys(feature.properties).forEach((key) => {
          propertiesList.add(key);
        });
      }
    });
  }
};

// find stdDev
function standardDeviation(array, usePopulation = false) {
  const mean = array.reduce((acc, val) => acc + val, 0) / array.length;
  return Math.sqrt(
    array
      .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
      (array.length - (usePopulation ? 0 : 1)),
  );
}

// find median
function calculateMedian(array) {
  return array.slice().sort((a, b) => a - b)[Math.floor(array.length / 2)];
}

//!! NOT WORKING - TO BE REMOVED !!
// helper function, find best vegetation of the GeoJSON -> feature collection, by property name:
// use: const minmax: [] = getMinMax(featureCollection, "YP"); // will find min max values of property
export function getBestVegetation(featureCollection, propertyName: string, schema: string) {
  if (featureCollection && featureCollection.features) {
    const minmax = getMinMax(featureCollection, propertyName, schema);

    if (!minmax) return null;

    const values = featureCollection.features.map((feature) => {
      if (feature.properties) {
        return feature.properties[propertyName];
      }
    });

    const median = calculateMedian(values);
    const stdDev = standardDeviation(values);

    const bestVegetationMin = median - stdDev;

    minmax[0] = minmax[0] < bestVegetationMin ? bestVegetationMin : minmax[0];

    return minmax;
  }
  return null;
}
