<h1 mat-dialog-title translate>
  <span translate [translate]="title"></span>
</h1>
<mat-dialog-content class="mat-typography">
  <p>
    <span translate [translate]="question"></span>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    [mat-dialog-close]="false"
    cdkFocusInitial
    class="c-rounded-button c-rounded-button-outlined c-rounded-button--small"
  >
    <span translate>BUTTON.NO</span>
  </button>
  <ng-container *ngFor="let button of data.buttons">
    <button
      (click)="onConfirm(button.returnValue)"
      class="c-rounded-button c-rounded-button-primary c-rounded-button--small"
      [class.c-rounded-button-primary]="button.primary"
      [class.c-rounded-button-outlined]="!button.primary"
    >
      <span translate>{{ button.text }}</span>
    </button>
  </ng-container>
</mat-dialog-actions>
