export interface IAuthUser {
  uid?: string; // User UID z Firebase Authentication
  email?: string; // UserCredential.user.email
  displayName?: string; // UserCredential.user.displayName nebo UserCredential.user.email
}

export class AuthUser implements IAuthUser {
  constructor(
    public uid?: string,
    public email?: string,
    public displayName?: string,
  ) {}
}
