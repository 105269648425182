<dynamic-form
  layout="card"
  [header]="header"
  [description]="description"
  [controls]="controls"
  [data$]="data$"
  (save)="onSave($event)"
  (delete)="onDelete($event)"
  [enable]="enable"
  [resetWhenChanged]="resetWhenChanged"
>
</dynamic-form>
