import { TLpisSkPole } from './entities/TLpisSkPole';

export interface LpisSkPole extends TLpisSkPole {}

export const LpisSkPole = {
  selectId: (a: LpisSkPole): string => {
    return a.idLpisSkPole;
  },

  filterFn: (fieldList: LpisSkPole[]) => {
    return fieldList.filter((field) => {
      return field.idLpisSkPole !== 'placeholder';
    });
  },
};
