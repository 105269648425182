import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Address, AddressCountry } from '@varistar-apps/shared/data';
import * as _ from 'lodash';
import { DfcInput } from '../dfc-input';
import { DfcSelect } from '../dfc-select';
import { of, Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-control-address',
  templateUrl: './dynamic-form-control-address.component.html',
  styleUrls: ['./dynamic-form-control-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicFormControlAddressComponent,
      multi: true,
    },
  ],
})
export class DynamicFormControlAddressComponent implements ControlValueAccessor {
  @Input() form?: FormGroup;
  @Input() enable = {
    save: false,
    cancel: false,
    delete: false,
  };

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter();
  @Output() event = new EventEmitter();

  value: Address; // objekt adresy dle RUIAN
  controls = [];
  data: { address: Address };

  countries = Address.getCountries(); // = Address.countries// Object.keys(AddressCountry).map(key => ({ name: `COUNTRY.${key.toUpperCase()}`, value: AddressCountry[key] }));

  onChangeValue = (value: any): void => {};
  onValidatorChange = (value?: any): void => {};
  // isInEdit = []; // je dany index contant v editacnim rezimu

  // isDisabled = (i) => { return !this.isInEdit[i]; };

  constructor(private cdr: ChangeDetectorRef) {}

  // conscriptionnumber?: string,  // Číslo popisné
  // provisionalnumber?: string,   // Číslo evidenční
  // streetnumber?: string,        // Číslo orientační
  // housenumber?: string,         // 1367, 1367/67, 2238/1a, ev.21, ev.21/1
  // street?: string,
  // place?: string;               // Lhotka, Libív, Vysočany.
  // suburb?: string;              // Praha 9 or Plzeň 2-Slovany
  // city?: string;                // Brno, Jince, Brdy, Lhota.
  // postcode?: string;            // 19000
  // country?: string;             // CZ
  // ruian?: string;

  writeValue(value: Address) {
    this.value = value;
    this.data = { address: value }; // NOTE: data musí být zanořeny jinak se po updatu nezobrazí
    this.controls = [
      new DfcInput<string>({
        key: 'address.street',
        placeholder: 'PLACEHOLDER.ADDRESS.STREET',
        order: 1.1,
        flex: '50',
      }),
      new DfcInput<string>({
        key: 'address.conscriptionnumber',
        placeholder: 'PLACEHOLDER.ADDRESS.CONSCRIPTIONNUMBER',
        order: 1.2,
        flex: '25',
      }),
      new DfcInput<string>({
        key: 'address.streetnumber',
        placeholder: 'PLACEHOLDER.ADDRESS.STREETNUMBER',
        order: 1.3,
        flex: '25',
      }),
      new DfcInput<string>({
        key: 'address.postcode',
        placeholder: 'PLACEHOLDER.ADDRESS.POSTCODE',
        order: 2.1,
        flex: '25',
      }),
      new DfcInput<string>({
        key: 'address.city',
        placeholder: 'PLACEHOLDER.ADDRESS.CITY',
        // value: false,
        order: 2.2,
        flex: '50',
        // hint: 'HINT.ADDRESS.CITY',
        // required: false,
        // validator: Validators.compose([Validators.required]),
        validatorMessages: {
          // required: 'VALIDATION.ADDRESS.CITY.REQUIRED'
        },
      }),
      new DfcSelect<string>({
        key: 'address.country',
        placeholder: 'PLACEHOLDER.ADDRESS.COUNTRY',
        order: 2.3,
        items: this.countries,
        value: null,
        flex: '25',
      }),
    ];
    this.change.emit(this.value);
    this.cdr.markForCheck();
  }

  onChange() {
    this.onChangeValue(this.value);
    this.onValidatorChange(this.value);
  }

  registerOnChange(fn) {
    this.onChangeValue = fn;
  }

  registerOnTouched(fn) {}

  setDisabledState(isDisabled: boolean) {
    isDisabled = isDisabled;
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   // const isValid = _.reduce(control.value, (isValid, value, id) => {
  //   //   return _.reduce(value.data, (isValid, value, deviceType) => {
  //   //     return deviceType !== '' && _.reduce(value, (isValid, value, deviceId) => {
  //   //       return deviceId !== '' && _.reduce(value, (isValid, value, indexOrName) => {
  //   //         return indexOrName !== '' && _.reduce(value, (isValid, value, key) => {
  //   //           return key !== '' && !isNaN(value);
  //   //         }, isValid);
  //   //       }, isValid);
  //   //     }, isValid);
  //   //   }, isValid);
  //   // }, true);

  //   // // this.onValidatorChange();
  //   // return isValid ? null : { 'custom': true };
  // }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onSave(data) {
    const value = data.address;
    // let x = _.cloneDeep(this.value);
    //   x[i].ip = event.ip;
    // this.writeValue({ ...this.value, ...value});
    this.writeValue(value);
    return this.onChange();
  }

  onModify(data) {
    const value = data.address;
    // let x = _.cloneDeep(this.value);
    // x[i] = { ...x[i], ...event };
    // this.onChangeValue(value);
    if (this.value) {
      // NOTE: po pridani selectionChange v dynamic-form-control u select to bez kontroly na null nastavilo zmenu pri zobrazeni prazdneho form kde je select
      this.onChangeValue(value);
      // this.onChangeValue({ ...this.value, ...value });
    }
  }

  onDelete(event) {
    // let x = _.cloneDeep(this.value);
    // x.splice(i, 1)
    // this.writeValue(x);
    // this.onChange();
  }
}
