import * as moment from 'moment';
import { TDodatky } from './entities/TDodatky';
export interface Annex extends TDodatky {
  // aoi?: number,
}

export const Annex = {
  selectId: (a: Annex) => {
    return a.idDodatek;
  },
  getItems: (data: Annex[] = []) => {
    return data.map((annex) => ({
      name: `${moment(annex.platnyOd).format('D.M.YYYY')} - ${moment(annex.platnyOd)
        .add(annex.delkaTrvani, 'year')
        .format('D.M.YYYY')} #${annex.cisloDodatku} (${annex.idDodatek})`,
      value: annex.idDodatek,
    }));
    // return data.map(annex => ({ name: `#${annex.cisloDodatku} : ${moment(annex.platnyOd).format('D.M.YYYY')} - ${moment(annex.platnyOd).add(annex.delkaTrvani, 'year').format('D.M.YYYY')} (${annex.idDodatek} : ${annex.idSmlouvy})`, value: annex.idDodatek }));
  },
};
