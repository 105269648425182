import { Injectable } from '@angular/core';
import {
  EntityCollectionReducerMethodMap,
  EntityDefinitionService,
  EntityAction,
  EntityChangeTracker,
  EntityCollection,
  EntityCollectionReducerMethods,
  EntityDefinition,
} from '@ngrx/data';

@Injectable()
export class FeatureConfigurationEntityCollectionReducerMethodsFactory {
  constructor(private entityDefinitionService: EntityDefinitionService) {}

  /** Create the  {EntityCollectionReducerMethods} for the named entity type */
  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new FeatureConfigurationEntityCollectionReducerMethods(
      entityName,
      definition,
    );

    return methodsClass.methods;
  }
}

export class FeatureConfigurationEntityCollectionReducerMethods<
  T,
> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>,
  ) {
    super(entityName, definition);
  }

  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>,
  ): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);
    // if ((action.payload as any).page) {
    //   (ec as any).page = (action.payload as any).page;
    // }
    // (ec as any).selectId = 9;
    return ec;
  }

  protected removeAll(
    collection: EntityCollection<T>,
    action: EntityAction<T>,
  ): EntityCollection<T> {
    const ec = super.removeAll(collection, action);
    // (ec as any).page = undefined;
    // (ec as any).selectedId = undefined;
    (ec as any).filter = undefined;
    return ec;
  }

  // protected (
  //   collection: EntityCollection<T>,
  //   action: EntityAction<T[]>
  // ): EntityCollection<T> {
  //   const ec = super..removeAll(collection, action);

  //   if ((action.payload as any).selectId) {
  //     (ec as any).selectId = (action.payload as any).selectId;
  //   }
  //   (ec as any).selectId = 9;
  //   return ec;
  // }
}
