import { Injectable } from '@angular/core';

@Injectable()
export class FlexLayoutBreakpointsService {
  constructor() {}
}
import { BREAKPOINT } from '@angular/flex-layout';

export const ONSITE_BREAKPOINTS = [
  // 1920 x 1080 - FHD (Full HD) / 1080p.
  {
    alias: 'fhd',
    suffix: 'Ffhd',
    mediaQuery: 'screen and (min-width: 1920px) and (max-width: 2559px)',
    overlapping: false,
    priority: 640, // NOTE: musi byt > xl (600) a < lt-xl (650)
  },
  {
    alias: 'gt-fhd',
    suffix: 'gt-fhd',
    mediaQuery: 'screen and (min-width: 2560px)',
    overlapping: false,
    priority: -640, // NOTE: musi byt > gt-lg (-650)
  },
  // 2560 x 1440 - QHD/WQHD (Quad HD) / 1440p.
  {
    alias: 'qhd',
    suffix: 'qhd',
    mediaQuery: 'screen and (min-width: 2560px) and (max-width: 3839px)',
    overlapping: false,
    priority: 630, // NOTE: musi byt > xl (600)
  },
  {
    alias: 'gt-qhd',
    suffix: 'gt-qhd',
    mediaQuery: 'screen and (min-width: 3840px)',
    overlapping: false,
    priority: -630, // NOTE: musi byt > gt-fhd (-640)
  },
  // 3840 x 2160 - UHD (Ultra HD) / 4K 2160p
  {
    alias: 'uhd',
    suffix: 'uhd',
    mediaQuery: 'screen and (min-width:3840px) and (max-width: 7679px)',
    overlapping: false,
    priority: 620, // NOTE: musi byt > xl (600)
  },
  {
    alias: 'gt-uhd',
    suffix: 'gt-uhd',
    mediaQuery: 'screen and (min-width: 7680px)',
    overlapping: false,
    priority: -620, // NOTE: musi byt > gt-qhd (-630)
  },
  // 7680×4320 - FUHD (Full Ultra HD) / 8K 4320p.
  {
    alias: 'fuhd',
    suffix: 'fuhd',
    mediaQuery: 'screen and (min-width:7680px)',
    overlapping: false,
    priority: 610, // NOTE: musi byt > xl (600)
  },
];

export const FlexLayoutBreakpointsProvider = {
  provide: BREAKPOINT,
  useValue: ONSITE_BREAKPOINTS,
  multi: true,
};
