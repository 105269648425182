import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiVaristarService } from '@varistar-apps/frontend/api-varistar';
import { Account, Organization, VaristarProfile } from '@varistar-apps/shared/data';
import { CountryCodeEnum, isAllowed } from '@varistar-apps/shared/utilities';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AccountUpdated, AuthError, LoginByEmail, LoginByFacebook, LoginByGoogle, LoginByMicrosoft, Logout, SignUpByEmail, VaristarProfileUpdated } from './auth.actions';
import { AuthCredentials, AuthPartialState } from './auth.reducer';
import { getAccount, getAuthenticated, getAuthorized, getDisplayName, getError, getLoading, getRole, getUser, getVaristarProfile } from './auth.selectors';
import { filter, map, shareReplay, distinctUntilChanged, switchMap, first } from 'rxjs/operators';


// import * as fb from 'firebase';

// import { LoadAuth } from './auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  // loaded$ = this.store.pipe(select(authQuery.getLoaded));
  // allAuth$ = this.store.pipe(select(authQuery.getAllAuth));
  // selectedAuth$ = this.store.pipe(select(authQuery.getSelectedAuth));
  loading$ = this.store.select(getLoading);
  authenticated$ = this.store.select(getAuthenticated);
  authorized$ = this.store.select(getAuthorized);
  user$ = this.store.select(getUser);
  account$ = this.store.select(getAccount);
  role$ = this.store.select(getRole);
  displayName$ = this.store.select(getDisplayName);
  error$ = this.store.select(getError);
  varistarProfile$ = this.store.select(getVaristarProfile);
  //TODO: dodelat typovani session a tab, aby bylo zajisteno, ze nemuze byt vlozen nevalidni parametr
  isAccessAllowed = ({ section, tab = null, requiredPermission }): Observable<boolean> => {
    return this.varistarProfile$.pipe(
      filter((vp) => !!vp.user && !!vp.customer),
      map(({ permissions, user, customer, department }) => {
        return isAllowed({
          permissionMap: permissions,
          isSuperAdmin: user?.superAdmin,
          customerId: customer?.idZakaznik,
          departmentId: department?.idStrediska,
          section,
          tab,
          requiredPermission,
        });
      }),
    );
  };
  currency$ = this.store.select(getVaristarProfile).pipe(
    map((vp) => {
      return (<any>vp.contract)?.mena;
    }),
    shareReplay(1),
  );
  contract$ = this.store.select(getVaristarProfile).pipe(
    map((vp) => {
      return vp.contract;
    }),
  );
  customerCountryCode$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return varistarProfile.customer?.zeme as CountryCodeEnum;
    }),
    filter((countryCode) => !!countryCode),
  );
  idZakaznik$ = this.store.select(getVaristarProfile).pipe(
    map((vp: VaristarProfile) => {
      return vp?.customer?.idZakaznik;
    }),
    filter((idZakaznik) => {
      return !!idZakaznik;
    }),
  );
  idUser$ = this.store.select(getVaristarProfile).pipe(
    map((vp: VaristarProfile) => {
      return vp?.user?.idtUser;
    }),
    filter((idUser) => {
      return !!idUser;
    }),
  );
  idOsoby$ = this.store.select(getVaristarProfile).pipe(
    map((vp: VaristarProfile) => {
      return vp?.user?.idtOsoby;
    }),
    filter((idOsoby) => {
      return !!idOsoby;
    }),
  );
  isFlatRate$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return !!varistarProfile.annex?.flatRate;
    }),
  );
  idDodatek$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return varistarProfile.annex?.idDodatek;
    }),
  );
  idSmlouvy$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return varistarProfile?.contract?.idSmlouvy;
    }),
    filter((x) => !!x),
    distinctUntilChanged(),
  );
  seasonStartMoment$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      const seasonStartDate = varistarProfile?.contract?.seasonStart;
      if (seasonStartDate) {
        return moment(seasonStartDate).set('year', moment().year());
      }

      return null;
    }),
    filter((x) => !!x),
  );
  currentSeasonId$ = this.seasonStartMoment$.pipe(
    map((seasonStartMoment) => {
      if (
        moment()
          .set('year', moment().year())
          .isBefore(seasonStartMoment.set('year', moment().year()), 'day')
      ) {
        return +(moment().year() - 1).toString().slice(-2);
      }

      return +moment().year().toString().slice(-2);
    }),
  );
  idStrediska$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return varistarProfile.department?.idStrediska;
    }),
    distinctUntilChanged(),
  );
  isSuperAdmin$ = this.store.select(getVaristarProfile).pipe(
    map((varistarProfile) => {
      return !!varistarProfile.user?.superAdmin;
    }),
    distinctUntilChanged(),
  );
  // varistarProfile$ = this.store.select(getVaristarProfile);
  // varistar$ = this.store.select(getVaristar);
  // isAuthenticated$ = this.store.select(isAuthenticated);
  // isLoggedIn$ = this.store.select(isLoggedIn);
  // authData$ = this.store.select(getAuthData);
  // userId$ = this.store.select(getUserId);
  // accounts$ = this.store.select(getAccounts);

  varistar: VaristarProfile = {
    // user: null,
    // holdingId: null,
    // customerId: null,
    // contractId: null,
    user: null,
    person: null,
    holding: null,
    customer: null,
    contract: null,
    annex: null,
  };
  account: Account = {};
  organization: Organization = {};
  isAdmin = false;
  organizationAccess;

  // roleSubsctiption = this.authFacade.role$.pipe().subscribe(role => {
  //     this.isAdmin = role === AccountRole.ADMIN;
  //     // FIXIT: nevim jak jinak, ale kvuli isAdmin ale musim az po nacteni role

  constructor(
    private store: Store<AuthPartialState>,
    // private registrationFacade: RegistrationFacade,
    // private afs: AngularFirestore,
    // public afAuth: AngularFireAuth,
    // public db: AngularFirestore,
    // public gplus: GooglePlus,
    // public facebook: Facebook
  ) {
    if ((<any>window).Cypress) {
      (<any>window).store = store;
    }
    this.account$.subscribe((account) => {
      this.account = account;
      // !!! Nova Varistar implementace dle t_user, t_osoby, t_zakaznik_osoby
      this.isAdmin = account?.varistar?.user?.superAdmin;

      // !!! Puvodni implementace pro Firestore autorizaci, prideleni k organizaci a role uzivatele
      // this.organization = account && account.organization ? account.organization : {};
      // // FIXIT: naprasaka stahnu z Firestore stav Access pro danou organizaci a daneho uzivatele
      // this.organizationAccess = account && account.access ? account.access : {};;
      // this.isAdmin = account && account.role === AccountRole.ADMIN;
    });
    this.varistarProfile$.subscribe((varistarProfile) => {
      this.varistar = varistarProfile;
    });
    // this.role$.subscribe(role => {
    //   this.isAdmin = role === AccountRole.ADMIN;
    // });
  }

  // loadAll() {
  //   this.store.dispatch(new LoadAuth());
  // }

  signUpByEmail(credentials: AuthCredentials) {
    this.store.dispatch(new SignUpByEmail(credentials));
  }

  loginByEmail(credentials: AuthCredentials) {
    this.store.dispatch(new LoginByEmail(credentials));
  }

  loginByGoogle() {
    this.store.dispatch(new LoginByGoogle());
  }

  loginByFacebook() {
    this.store.dispatch(new LoginByFacebook());
  }

  loginByMicrosoft() {
    this.store.dispatch(new LoginByMicrosoft());
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  // vola se z registration.facade aby se po updatu organization v account aktualizoval auth state
  updateAccount(account: Partial<Account>) {
    this.store.dispatch(new AccountUpdated(account));
  }

  // vola se z registration.facade aby se po updatu organization v account aktualizoval auth state
  updateVaristarProfile(varistarProfile: Partial<VaristarProfile>) {
    this.store.dispatch(new VaristarProfileUpdated(varistarProfile));
  }

  authError(message: string) {
    this.store.dispatch(
      new AuthError({
        code: message,
      }),
    );
  }

  // sendPasswordResetEmail(credentials: IAuthCredentials): Promise<void> {
  //   return this.afAuth.auth.sendPasswordResetEmail(credentials.email);
  // }

  // async updateUsername(user: IAuthUser, newUsername: string) {
  //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  //     ...user,
  //     username: newUsername
  //   });
  // }

  // async updateSex(user: IAuthUser, newSex: string) {
  //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({
  //     ...user,
  //     personalData: {
  //       ...user.personalData,
  //       sex: newSex
  //     }
  //   });
  // }

  // async updateLanguage(user: IAuthUser, newLanguage: string) {
  //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({
  //     ...user,
  //     personalData: {
  //       ...user.personalData,
  //       language: newLanguage
  //     }
  //   });
  // }

  // async updateAvatar(user: IAuthUser, path: string) {
  //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  //     ...user,
  //     profile: {
  //       ...user.profile,
  //       avatar: path
  //     }
  //   });
  // }

  // /* */
  // async updateProfile(user: IAuthUser): Promise<IProgress> {
  //   const fbUser: fb.User = await this.afAuth.authState.pipe(take(1)).toPromise();
  //   const defaultUserData: IAuthUser = {
  //     id: fbUser.uid,
  //     username: fbUser.displayName ? fbUser.displayName : fbUser.email,
  //     email: fbUser.email,
  //     preferences: {
  //       allowDownloadOnDataConnection: true
  //     },
  //     profile: {
  //       avatar: fbUser.photoURL
  //     },
  //     createdAt: fb.firestore.FieldValue.serverTimestamp(),
  //     updatedAt: fb.firestore.FieldValue.serverTimestamp()
  //   };
  //   const dbUserPayload: DocumentSnapshot<IAuthUser> = (await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).snapshotChanges().pipe(
  //     take(1)
  //   ).toPromise()).payload;
  //   if (!dbUserPayload.exists) {
  //     this.store$.dispatch(new ProgressUpdateAction({ id: fbUser.uid }));
  //     await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).set({ ...defaultUserData });
  //   }
  //   await this.db.doc<IAuthUser>(`profiles/${fbUser.uid}`).update({
  //     ...user,
  //     updatedAt: fb.firestore.FieldValue.serverTimestamp()
  //   });
  //   return this.goToLastPage();
  // }

  // async goToLastPage(): Promise<IProgress> {
  //   const progress: Promise<IProgress> = this.store$.select(selectActiveProgress).pipe(
  //     take(1)
  //   ).toPromise();

  //   this.store$.dispatch(
  //     new RouterGoAction({
  //       path: [_.get(await progress, 'lastUrl', '')]
  //     })
  //   );
  //   return progress;
  // }
}
