import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TOsoby } from './TOsoby';
import { TRole } from './TRole';

@Index('t_osoby_role_FK', ['idtOsoby'], {})
@Index('t_osoby_role_FK_1', ['idtRole'], {})
@Entity('t_osoby_role', { schema: 'VaristarIS' })
export class TOsobyRole {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_osoby_role' })
  idtOsobyRole: number;

  @Column('int', { name: 'idt_osoby' })
  idtOsoby: number;

  @Column('int', { name: 'idt_role', default: () => "'29'" })
  idtRole: number;

  @ManyToOne('TOsoby', 'tOsobyRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_osoby', referencedColumnName: 'idOsoby' }])
  idtOsoby2: TOsoby;

  @ManyToOne('TRole', 'tOsobyRoles', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_role', referencedColumnName: 'idRole' }])
  idtRole2: TRole;
}
