<h1 mat-dialog-title translate *ngIf="title">
  <span translate [translate]="title"></span>
</h1>
<mat-dialog-content class="mat-typography" *ngIf="question">
  <p>
    <span translate [translate]="question"></span>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    [mat-dialog-close]="false"
    cdkFocusInitial
    class="c-rounded-button c-rounded-button-outlined c-rounded-button--small"
  >
    <span translate>BUTTON.NO</span>
  </button>
  <button
    [mat-dialog-close]="true"
    cy-button-save
    [disabled]="false"
    class="c-rounded-button c-rounded-button-primary c-rounded-button--small"
    #saveButton
  >
    <span translate>BUTTON.YES</span>
  </button>
</mat-dialog-actions>
