import { TranslateService } from '@ngx-translate/core';
import { TZakazkyTyp } from './entities/TZakazkyTyp';

export function getOrderTypeIconName(orderType: string | number) {
  const orderTypeIconMap = {
    1: 'hnojeni_mapa',
    2: 'seti_mapa',
    3: 'ochrana_mapa',
    4: 'zasobni_mapa',
    5: 'hnojeni_mapa_satelit',
    6: 'ochrana_satelit',
    7: 'hnojeni_satelit',
    8: 'hnojeni_uniformni',
    9: 'vapneni_mapa',
    10: 'ochrana_uniformni',
    11: 'seti_uniformni',
    12: 'tankmix',
    YP_MAP_FERTILIZATION: 'hnojeni_mapa',
    YP_MAP_SEEDING: 'seti_mapa',
    YP_MAP_PESTICIDES: 'ochrana_mapa',
    PRE_PLANTING_FERTILIZATION: 'zasobni_mapa',
    NDVI_YP_FERTILIZATION: 'hnojeni_mapa_satelit',
    NDVI_PESTICIDES: 'ochrana_satelit',
    NDVI_FERTILIZATION: 'hnojeni_satelit',
    UNIFORM_FERTILIZATION: 'hnojeni_uniformni',
    LIMING: 'vapneni_mapa',
    UNIFORM_PESTICIDES: 'ochrana_uniformni',
    UNIFORM_SEEDING: 'seti_uniformni',
    TANKMIX: 'tankmix',
  };

  return orderTypeIconMap[`${orderType}`];
}

export function getOrderTypeIconDescription(orderType: string | number) {
  const orderTypeIconDescription = {
    1: 'ORDER_TYPE.FERTILIZATION',
    2: 'ORDER_TYPE.SEEDING',
    3: 'ORDER_TYPE.PESTICIDES',
    4: 'ORDER_TYPE.PRE_PLANTING_FERTILIZATION',
    5: 'ORDER_TYPE.FERTILIZATION',
    6: 'ORDER_TYPE.PESTICIDES',
    7: 'ORDER_TYPE.FERTILIZATION',
    8: 'ORDER_TYPE.UNIFORM_FERTILIZATION',
    9: 'ORDER_TYPE.LIMING',
    10: 'ORDER_TYPE.UNIFORM_PESTICIDES',
    11: 'ORDER_TYPE.UNIFORM_SEEDING',
    12: 'ORDER_TYPE.TANKMIX',
  };

  return orderTypeIconDescription[`${orderType}`];
}

export enum orderTypeEnum {
  'YP_MAP_FERTILIZATION' = 1,
  'YP_MAP_SEEDING' = 2,
  'YP_MAP_PESTICIDES' = 3,
  'PRE_PLANTING_FERTILIZATION' = 4,
  'NDVI_YP_FERTILIZATION' = 5,
  'NDVI_PESTICIDES' = 6,
  'NDVI_FERTILIZATION' = 7,
  'UNIFORM_FERTILIZATION' = 8,
  'LIMING' = 9,
  'UNIFORM_PESTICIDES' = 10,
  'UNIFORM_SEEDING' = 11,
  'TANKMIX' = 12,
}

export const orderTypeMap = {
  1: 'YP_MAP_FERTILIZATION',
  2: 'YP_MAP_SEEDING',
  3: 'YP_MAP_PESTICIDES',
  4: 'PRE_PLANTING_FERTILIZATION',
  5: 'NDVI_YP_FERTILIZATION',
  6: 'NDVI_PESTICIDES',
  7: 'NDVI_FERTILIZATION',
  8: 'UNIFORM_FERTILIZATION',
  9: 'LIMING',
  10: 'UNIFORM_PESTICIDES',
  11: 'UNIFORM_SEEDING',
  12: 'TANKMIX',
};

export const orderTypeAllMap = {
  1: 'FERTILIZATION',
  2: 'SEEDING',
  3: 'PESTICIDES',
  4: 'PRE_PLANTING_FERTILIZATION',
  5: 'FERTILIZATION',
  6: 'PESTICIDES',
  7: 'FERTILIZATION',
  8: 'UNIFORM_FERTILIZATION',
  9: 'LIMING',
  10: 'UNIFORM_PESTICIDES',
  11: 'UNIFORM_SEEDING',
  12: 'TANKMIX',
};

// tslint:disable-next-line: no-empty-interface
export interface OrderType extends TZakazkyTyp {}

export const OrderType = {
  selectId: (a: OrderType) => {
    //In this case this would be optional since primary key is id
    return a.idtZakazkyTyp;
  },
  getItems: (data: OrderType[] = []) => {
    return data.map((orderType) => ({
      name: `ORDER_TYPE.${orderType.description.toUpperCase()}`,
      value: orderType.idtZakazkyTyp,
    }));
  },
  getInitialItems: () => [
    { value: 1, name: 'ORDER_TYPE.FERTILIZATION' },
    { value: 2, name: 'ORDER_TYPE.SEEDING' },
    { value: 3, name: 'ORDER_TYPE.PESTICIDES' },
    { value: 4, name: 'ORDER_TYPE.PRE_PLANTING_FERTILIZATION' },
    { value: 9, name: 'ORDER_TYPE.LIMING' },
    { value: 8, name: 'ORDER_TYPE.UNIFORM_FERTILIZATION' },
    { value: 11, name: 'ORDER_TYPE.UNIFORM_SEEDING' },
    { value: 10, name: 'ORDER_TYPE.UNIFORM_PESTICIDES' },
    { value: 12, name: 'ORDER_TYPE.TANKMIX' },
  ],

  getInitialItemsTranslated: (translateService: TranslateService) => {
    return OrderType.getInitialItems().map((item) => ({
      ...item,
      name: translateService.instant(item.name),
    }));
  },

  getAllItems: () => {
    return Object.entries(orderTypeAllMap).map(([id, name]) => {
      return { name: `ORDER_TYPE.${name}`, value: id };
    });
  },
  getOrderTypeTextByKey(key: number) {
    return `ORDER_TYPE.${orderTypeMap[`${key}`]}`;
  },
};
