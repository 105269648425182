import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TSim } from './TSim';
import { TSmlouvy } from './TSmlouvy';

@Index('cislo_terminalu', ['cisloTerminalu'], {})
@Index('deactivated', ['deactivated'], {})
@Index('id_terminalu1', ['idTerminalySoil'], {})
@Index('idc_smlouvy_idx', ['idSmlouvy'], {})
@Index('popis', ['popis'], {})
@Entity('t_terminaly_soil', { schema: 'VaristarIS' })
export class TTerminalySoil {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_terminaly_soil' })
  idTerminalySoil: number;

  @Column('int', {
    name: 'id_smlouvy',
    nullable: true,
    comment: 'ID smlouvy ke které je terminál vázán. ',
  })
  idSmlouvy: number | null;

  @Column('varchar', {
    name: 'cislo_terminalu',
    nullable: true,
    comment: 'Číselné označení terminálu. ',
    length: 45,
  })
  cisloTerminalu: string | null;

  @Column('varchar', {
    name: 'SSAID',
    nullable: true,
    comment:
      'Autentizační API key pro přístup k úložišti - např. u Dropbox a Varistar Direct, zároveň se sem ukládá SSAID Varistar One terminálu',
    length: 255,
  })
  ssaid: string | null;

  @Column('bigint', {
    name: 'teamviewer_id',
    nullable: true,
    comment: 'ID pro připojení přes teamviewer - tzv. partnerské ID.',
  })
  teamviewerId: string | null;

  @Column('varchar', {
    name: 'imei',
    nullable: true,
    comment: 'IMEI použitého tabletu, pouze pokud připojení řešeno přes tablet/ terminál iSoyl. ',
    length: 45,
  })
  imei: string | null;

  @Column('varchar', {
    name: 'jmeno_obsluhy',
    nullable: true,
    comment: 'Jméno obsluhy, jenž pracuje s teminálem.',
    length: 300,
  })
  jmenoObsluhy: string | null;

  @Column('varchar', {
    name: 'tel_obsluhy',
    nullable: true,
    comment: 'Telefonní spojení na obluhu pracující s terminálem. ',
    length: 300,
  })
  telObsluhy: string | null;

  @Column('datetime', {
    name: 'datum_predani',
    nullable: true,
    comment: 'Datum předání našeho teminálu zákazníkovi.',
  })
  datumPredani: Date | null;

  @Column('varchar', {
    name: 'cislo_plomby',
    nullable: true,
    comment: 'Číslo plomby našeho terminálu.',
    length: 255,
  })
  cisloPlomby: string | null;

  @Column('varchar', { name: 'poznamka', nullable: true, length: 1600 })
  poznamka: string | null;

  @Column('varchar', {
    name: 'popis',
    nullable: true,
    comment: 'Popis terminálu pro zákazníka - může jej měnit, aby poznal o jaký terminál jde.',
    length: 255,
  })
  popis: string | null;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    comment: 'Příznak pro neaktivní terminály',
    width: 1,
    default: () => "'0'",
  })
  deactivated: boolean | null;

  @Column('int', {
    name: 'settings_language',
    nullable: true,
    comment: 'Předdefinovaný jazyk terminálu',
  })
  settingsLanguage: number | null;

  @Column('tinyint', {
    name: 'settings_autosave',
    nullable: true,
    comment: 'Automatické ukládání naměřených dat po ukončení měření',
  })
  settingsAutosave: number | null;

  @Column('tinyint', {
    name: 'settings_sensor_type',
    nullable: true,
    comment: 'Typ senzoru (1. JXBS-4001, 0. PR-3001)',
  })
  settingsSensorType: number;

  @Column('int', {
    name: 'settings_time_delay',
    nullable: true,
    comment: 'Dálka měření - timeout pro srovnání naměřených dat ze sondy.',
  })
  settingsTimeDelay: number | null;

  @OneToMany('TSim', 'actualTerminalSoil2')
  tSims: TSim[];

  @ManyToOne('TSmlouvy', 'tTerminalySoils', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idSmlouvy2: TSmlouvy;
}
