import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationChangeService {
  public event$ = new BehaviorSubject(null);

  constructor(public location: Location) {
    location.onUrlChange((url) => {
      this.event$.next(url);
    });
  }
}
