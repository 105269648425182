import { MapBounds } from './map.model';
import { VisualizationType, ApiVaristarGeeMapRequest, MapParams } from './api-varistar.model';

export enum MapLayerType {
  MAP_IMAGE_TILES_URL = 'MAP_IMAGE_TILES_URL', // map image tiles url
  MAP_IMAGE_DOWNLOAD_URL = 'MAP_IMAGE_DOWNLOAD_URL', // map image download url
  GEOJSON_CONTENT = 'GEOJSON_CONTENT', // feature collection content in GeoJson format
  GEOJSON_DOWNLOAD_URL = 'GEOJSON_DOWNLOAD_URL', // download url to feature collection in GeoJson format
  POINT = 'POINT', // map layer je prazdny, vse je v mapParams min, max, ...
}

// rezim mapy co se stane po kliknuti v mape
export enum MapClickMode {
  LEGEND = 'LEGEND',
  MIN = 'MIN',
  MAX = 'MAX',
  VALUE = 'VALUE',
}

export interface MapLayer {
  id?: string;
  name: string;
  visualizationType: VisualizationType; // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
  type: MapLayerType; // MAP_IMAGE_URL / GEO_JSON
  url?: string; // map tiles / download url
  content?: object; // GeoJson object
  bounds?: MapBounds;
  mapParams?: MapParams;
  // FIXIT: podpora pro vraceni hodnoty na point
  point: [number, number];
  value: number;
  // downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu
  // bounds: [number[], number[]],
  // layers: OldMapLayer[],
  // response: ApiVaristarGeeMapResponse;
  //   visualizationType: VisualizationType // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
  // type: MapLayerType, // MAP_IMAGE_URL / GEO_JSON
  //   url?: string, // map tiles / download url
  //   content?: object, // GeoJson object
  //   downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu
}

// class pro DTO
export class MapLayer implements MapLayer {
  static selectId(a: MapLayer): string {
    //In this case this would be optional since primary key is id
    return a.id;
  }

  static getId(request: ApiVaristarGeeMapRequest) {
    return `${name}`;
  }
}
