import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TBonitacniSkupiny } from './TBonitacniSkupiny';
// import { TDataroom } from "./TDataroom";
import { TDodatky } from './TDodatky';
import { TFieldGroup } from './TFieldGroup';
import { TRole } from './TRole';
import { TVerze } from './TVerze';
import { TSmlouvyTerminaly } from './TSmlouvyTerminaly';
import { TStrediska } from './TStrediska';
import { TTerminalySoil } from './TTerminalySoil';

@Index('cislo_smlouv', ['cisloSmlouvy'], {})
@Index('id_smlouvy_UNIQUE', ['idSmlouvy'], { unique: true })
@Index('ik_verze_smlouvy_idx', ['idVerze'], {})
@Index('neaktivni', ['neaktivni'], {})
@Entity('t_smlouvy', { schema: 'VaristarIS' })
export class TSmlouvy {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_smlouvy' })
  idSmlouvy: number;

  @Column('int', { name: 'id_verze', nullable: true })
  idVerze: number | null;

  @Column('int', {
    name: 'cislo_smlouvy',
    nullable: true,
    comment: 'Číselné označení smlouvy',
  })
  cisloSmlouvy: number | null;

  @Column('date', {
    name: 'datum_podpisu',
    nullable: true,
    comment: 'Datum podpisu smlouvy',
  })
  datumPodpisu: string | null;

  @Column('varchar', {
    name: 'id_99',
    nullable: true,
    comment: 'Uživatelské jméno do LPIS',
    length: 45,
  })
  id_99: string | null;

  @Column('varchar', {
    name: 'pass_99',
    nullable: true,
    comment: 'Heslo k id_99',
    length: 45,
  })
  pass_99: string | null;

  @Column('varchar', {
    name: 'id_98',
    nullable: true,
    comment: 'Uživatelské jméno do LPIS',
    length: 45,
  })
  id_98: string | null;

  @Column('varchar', {
    name: 'pass_98',
    nullable: true,
    comment: 'Heslo k id_98',
    length: 45,
  })
  pass_98: string | null;

  @Column('tinyint', {
    name: 'neaktivni',
    nullable: true,
    comment: 'Určuje jestli je smluvní vztah mezi subjektem a Varistarem aktivní. ',
    width: 1,
    default: () => "'0'",
  })
  neaktivni: boolean | null;

  @Column('varchar', { name: 't_smlouvycol', nullable: true, length: 45 })
  tSmlouvycol: string | null;

  @Column('varchar', {
    name: 'jd_org_id',
    nullable: true,
    comment: 'John Deere org_id',
    length: 45,
  })
  jdOrgId: string | null;

  @Column('varchar', {
    name: 'jd_refresh_token',
    nullable: true,
    comment:
      'John Deere refresh_token (platnost 365 dní). Pomocí refresh_tokenu lze získat access_token, který má platnost 12 hodin, access_token se pak používá pro veškeré dotazy vůči John Deere API.',
    length: 1000,
  })
  jdRefreshToken: string | null;

  @Column('varchar', {
    name: 'argoRiskCity',
    nullable: true,
    comment:
      'Město, podle kterého se vyhledává informace z Agrorisk, v případě, že neexistuje detekce podle počasí.',
    length: 255,
  })
  argoRiskCity: string | null;

  @Column('date', {
    name: 'datum_ukonceni',
    nullable: true,
    comment: 'Datum ukončení smlouvy.',
  })
  datumUkonceni: string | null;

  @Column('date', {
    name: 'season_start',
    comment:
      'Datum zacatku sezony; uzivatel ho muze zmenit v nastaveni poli. Rok je pouze placeholder, dulezity je mesic a den.',
    default: () => "'2000-08-01'",
  })
  seasonStart: string;

  @Column('int', {
    name: 'newest_season_id',
    comment:
      'Id nejnovejsi sezony, pro kterou ma dana smlouva v portalu vytvorena pole. Pouziva se pro zjisteni, zda jiz byla vytvorena pole pro pristi sezonu.',
  })
  newestSeasonId: number;

  @Column('bigint', {
    name: 'historical_yield_last_update',
    comment: 'cislo posledni aktualizace t_fields_yield_year - pouziva se pro cachovani',
    default: () => "'1'",
  })
  historicalYieldLastUpdate: string;

  @Column('decimal', {
    name: 'ryp_map_sensitivity',
    nullable: true,
    comment:
      'Citlivost kroku při tvorbě map výnosového potenciálu 0,05 = 5% (mapy budou vždy po 5-ti %, ale vnitřní reclassifikace může být jiná - např. 0,04 zvýší citlivost (variabilitu) mapy.',
    precision: 3,
    scale: 2,
    default: () => "'0.05'",
  })
  rypMapSensitivity: string | null;

  @Column('date', {
    name: 'winter_crop_start',
    nullable: true,
    comment: 'Počáteční datum okna pro stahování map pro tvorbu map RVP u ozimů.',
    default: () => "'2000-05-15'",
  })
  winterCropStart: string | null;

  @Column('date', {
    name: 'winter_crop_end',
    nullable: true,
    comment: 'Konečný datum okna pro stahování map pro tvorbu map RVP u ozimů.',
    default: () => "'2000-06-16'",
  })
  winterCropEnd: string | null;

  @Column('date', {
    name: 'spring_crop_start',
    nullable: true,
    comment: 'Počáteční datum okna pro stahování map pro tvorbu map RVP u jařin.',
    default: () => "'2000-07-15'",
  })
  springCropStart: string | null;

  @Column('date', {
    name: 'spring_crop_end',
    nullable: true,
    comment: 'Konečný datum okna pro stahování map pro tvorbu map RVP u jařin.',
    default: () => "'2000-08-15'",
  })
  springCropEnd: string | null;

  @OneToMany('TBonitacniSkupiny', 'idSmlouvy2')
  tBonitacniSkupinies: TBonitacniSkupiny[];

  // @OneToMany('TDataroom', 'idSmlouvy2')
  // tDatarooms: TDataroom[];

  @OneToMany('TDodatky', 'idSmlouvy2')
  tDodatkies: TDodatky[];

  @OneToMany('TFieldGroup', 'idSmlouvy2')
  tFieldGroups: TFieldGroup[];

  @OneToMany('TRole', 'idtSmlouvy2')
  tRoles: TRole[];

  @ManyToOne('TVerze', 'tSmlouvies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_verze', referencedColumnName: 'idVerze' }])
  idVerze2: TVerze;

  @OneToMany('TSmlouvyTerminaly', 'idSmlouvy2')
  tSmlouvyTerminalies: TSmlouvyTerminaly[];

  @OneToMany('TStrediska', 'idtSmlouvy2')
  tStrediskas: TStrediska[];

  @OneToMany('TTerminalySoil', 'idSmlouvy2')
  tTerminalySoils: TTerminalySoil[];
}
