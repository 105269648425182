import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';

const whiteImageBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAAAqCAIAAABuuNHMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAABFSURBVGhD7c8BDQAwEMSg+Td908GnOODtolaOVo5WjlaOVo5WjlaOVo5WjlaOVo5WjlaOVo5WjlaOVo5WjlaOVo6Lq+0Dus7RDUxsCQoAAAAASUVORK5CYII=';

@Directive({
  selector: '[appFallbackSrc]',
})
export class FallbackSrcDirective implements OnDestroy {
  @Input('fallback-src') imgSrc: string = whiteImageBase64;
  private el: HTMLElement;
  private isApplied = false;
  private EVENT_TYPE = 'error';

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
    this.el.addEventListener(this.EVENT_TYPE, this.onError.bind(this));
  }

  private onError() {
    this.removeEvents();

    if (!this.isApplied) {
      this.isApplied = true;
      this.el.setAttribute('src', this.imgSrc);
    }
  }

  private removeEvents() {
    this.el.removeEventListener(this.EVENT_TYPE, this.onError);
  }

  ngOnDestroy() {
    this.removeEvents();
  }
}
