import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

@Index('kod', ['kodkd'], {})
@Index('Lokalita', ['lokalita'], {})
@Entity('t_LPIS_SR_pole', { schema: 'VaristarIS' })
export class TLpisSrPole {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'id_LPIS_SR' })
  idLpisSr: string;

  @Column('int', { name: 'ROK', nullable: true })
  rok: number | null;

  @Column('int', {
    name: 'ID_KD',
    nullable: true,
    comment: 'ID katastrálního území.',
  })
  idKd: number | null;

  @Column('varchar', {
    name: 'ZKODKD',
    nullable: true,
    comment: 'Zkrácený kód v rámci katastrálního území.',
    length: 254,
  })
  zkodkd: string | null;

  @Column('decimal', {
    name: 'VYMERA',
    nullable: true,
    comment: 'Výměra půdního bloku v hektarech.',
    precision: 20,
    scale: 5,
  })
  vymera: string | null;

  @Column('varchar', {
    name: 'KODKD',
    nullable: true,
    comment: 'Celostátní unikátní kód půdního bloku.',
    length: 254,
  })
  kodkd: string | null;

  @Column('varchar', {
    name: 'LOKALITA',
    nullable: true,
    comment: 'Lokalita (katastrální)',
    length: 254,
  })
  lokalita: string | null;

  @Column('varchar', { name: 'SPOSOBILOS', nullable: true, length: 254 })
  sposobilos: string | null;

  @Column('varchar', {
    name: 'KULTURA',
    nullable: true,
    comment: 'Typ využívání půdního bloku Orná půda, Vinice, Trvalý travní porost atd.',
    length: 254,
  })
  kultura: string | null;
}
