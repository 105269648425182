import { Column, Entity, Index, PrimaryGeneratedColumn } from 'typeorm';

@Index('t_opravneni_UN', ['idOpravneni'], { unique: true })
@Index('t_opravneni_FK', ['idtZakaznikOsobyStrediska'], {})
@Index('t_opravneni_FK_1', ['idtZakaznikOsoby'], {})
@Index('t_opravneni_FK_2', ['idtRole'], {})
@Index('t_opravneni_FK_3', ['idtOsoby'], {})
@Index('t_opravneni_FK_4', ['idtHoldingOsoby'], {})
@Entity('t_opravneni', { schema: 'VaristarIS' })
export class TOpravneni {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_opravneni' })
  idOpravneni: number;

  @Column('json', { name: 'settings', nullable: true })
  settings: object | null;

  @Column('int', { name: 'idt_zakaznik_osoby', nullable: true })
  idtZakaznikOsoby: number | null;

  @Column('int', { name: 'idt_zakaznik_osoby_strediska', nullable: true })
  idtZakaznikOsobyStrediska: number | null;

  @Column('int', { name: 'idt_role', nullable: true })
  idtRole: number | null;

  @Column('int', { name: 'idt_osoby', nullable: true })
  idtOsoby: number | null;

  @Column('int', { name: 'idt_holding_osoby', nullable: true })
  idtHoldingOsoby: number | null;
}
