import { Column, Entity, OneToMany } from 'typeorm';
import { TCiselnikOdruda } from './TCiselnikOdruda';

@Entity('t_ciselnik_druh', { schema: 'VaristarIS' })
export class TCiselnikDruh {
  @Column('varchar', {
    primary: true,
    name: 'Kod',
    comment: 'Kód druhu',
  })
  kod: number;

  @Column('varchar', {
    name: 'Nazev',
    nullable: true,
    comment: 'Název druhu',
    length: 255,
  })
  nazev: string | null;

  @Column('varchar', {
    name: 'Nazev_SK',
    nullable: true,
    comment: 'Název druhu ve slovenštině ',
    length: 255,
  })
  nazevSk: string | null;

  @Column('tinyint', { name: 'isGrain', nullable: true, default: () => "'0'" })
  isGrain: number | null;

  // @OneToMany(
  //   () => TCiselnikOdruda,
  //   (tCiselnikOdruda) => tCiselnikOdruda.kodDruh2
  // )
  @OneToMany('TCiselnikOdruda', 'kodDruh2')
  tCiselnikOdrudas: TCiselnikOdruda[];
}
