import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { TPorRozhodnuti } from './TPorRozhodnuti';
import { TPorPouzitiDavkovani } from './TPorPouzitiDavkovani';
import { TPorPouzitiPlodiny } from './TPorPouzitiPlodiny';
import { TPorPouzitiSoPpp } from './TPorPouzitiSoPpp';

@Index('idt_por_rozhodnuti_pouziti_idx', ['rozhodnutiId'], {})
@Entity('t_por_pouziti', { schema: 'VaristarIS' })
export class TPorPouziti {
  @Column('bigint', { primary: true, name: 'idt_por_pouziti' })
  idtPorPouziti: string;

  @Column('bigint', {
    name: 'rozhodnuti_id',
    nullable: true,
    comment: 'Odkazuje na t_por_rozhodnuti',
  })
  rozhodnutiId: string | null;

  @Column('varchar', {
    name: 'OL',
    nullable: true,
    comment: 'Ochranná lhůta',
    length: 45,
  })
  ol: string | null;

  @Column('varchar', {
    name: 'PLODINA',
    nullable: true,
    comment: 'Plodina, oblast použití',
    length: 2000,
  })
  plodina: string | null;

  @Column('varchar', {
    name: 'SO',
    nullable: true,
    comment: 'Škodlivý organismus, jiný účel použití',
    length: 2000,
  })
  so: string | null;

  @Column('varchar', {
    name: 'DAVKA',
    nullable: true,
    comment: 'Dávkování prostředku',
    length: 900,
  })
  davka: string | null;

  @Column('varchar', {
    name: 'POZNAMKA',
    nullable: true,
    comment: 'Aplikační poznámky',
    length: 2000,
  })
  poznamka: string | null;

  @ManyToOne('TPorRozhodnuti', 'tPorPouzitis', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'rozhodnuti_id', referencedColumnName: 'idtPorRozhodnuti' }])
  rozhodnuti: TPorRozhodnuti;

  // @OneToMany(
  //   'TPorPouzitiDavkovani',
  //   'pouziti'
  // )
  // tPorPouzitiDavkovanis: TPorPouzitiDavkovani[];

  // @OneToMany(
  //   'TPorPouzitiPlodiny',
  //   'pouziti'
  // )
  // tPorPouzitiPlodinies: TPorPouzitiPlodiny[];

  // @OneToMany(
  //   'TPorPouzitiSoPpp',
  //   'pouziti'
  // )
  // tPorPouzitiSoPpps: TPorPouzitiSoPpp[];
}
