import { TLpisSelect } from './entities/TLpisSelect';

// tslint:disable-next-line: no-empty-interface
export interface FieldSelect extends TLpisSelect {}

export const FieldSelect = {
  selectId: (a: FieldSelect) => {
    //In this case this would be optional since primary key is id
    return a.idtLpisSelect; // TBD: refId ?
  },
  // sortByDescription: (a: Field, b: Field): number => {
  //   return a.popis && a.popis.localeCompare(b.popis);
  // },

  // // localityNameFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string) => {
  // //   return PropsFilterFnFactory(['name'])(localities, pattern);
  // // },

  // localityEnabledFilterFn: (
  //   localities: Locality[],
  //   pattern: string) => {
  //   return PropsFilterFnFactory(['enabled'])(localities, !!pattern);
  // },

  // // localityIdFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string | number) => {
  // //   return localities.find(a => {
  // //     return a.id === pattern
  // //   });
  // //   //   // return PropsFilterFnFactory(['id'])(localities, pattern);
  // // // },

  // fullName: (p: Application) => {
  //   return p.order && `${p.order.popis} - ${p.order.popis}`;
  // },
};
