import { DynamicFormControlBase } from './dynamic-form-control-base';

export interface KeyValue {
  key: string;
  value: any;
}

export class DfcKeys<T> extends DynamicFormControlBase<T> {
  controlType = 'keys';
  items: KeyValue[];

  constructor(options: {} = {}) {
    super(options);
    this.items = options['items'] || [];
  }
}
