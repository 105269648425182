import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TZakazkyTerminal } from './TZakazkyTerminal';

@Index('Zakazky_app_tablet_idx', ['idZakazkyTerminal'], {})
@Index('lan', ['lon'], {})
@Index('lot', ['lat'], {})
@Index('date_time', ['dateTime'], {})
@Entity('t_tablet_applied', { schema: 'VaristarIS' })
export class TTabletApplied {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_tablet_applied' })
  idtTabletApplied: string;

  @Column('bigint', {
    name: 'id_zakazky_terminal',
    nullable: true,
    comment: 'Cizí klíč do tabulky zakázky.',
  })
  idZakazkyTerminal: string | null;

  @Column('datetime', {
    name: 'date_time',
    nullable: true,
    comment: 'Datum a čas odeslání dat z tabletu v UTC',
  })
  dateTime: Date | null;

  @Column('datetime', {
    name: 'device_time',
    nullable: true,
    comment: 'Čas sběru dat v česové zóně tabletu.',
  })
  deviceTime: Date | null;

  @Column('decimal', {
    name: 'lon',
    nullable: true,
    comment: 'Zeměpisná délka',
    precision: 13,
    scale: 10,
  })
  lon: string | null;

  @Column('decimal', {
    name: 'lat',
    nullable: true,
    comment: 'Zeměpisná šířka',
    precision: 13,
    scale: 10,
  })
  lat: string | null;

  @Column('decimal', {
    name: 'speed',
    nullable: true,
    comment: 'Aktuální rychlost stroje',
    precision: 5,
    scale: 2,
  })
  speed: string | null;

  @Column('decimal', {
    name: 'altitude',
    nullable: true,
    comment: 'Aktuální nadmořská výška',
    precision: 6,
    scale: 2,
  })
  altitude: string | null;

  @Column('decimal', {
    name: 'gps_accuracy',
    nullable: true,
    comment: 'Přesnost GPS v tabletu',
    precision: 8,
    scale: 2,
  })
  gpsAccuracy: string | null;

  @Column('decimal', {
    name: 'actual_rate',
    nullable: true,
    comment: 'Aktuálně aplikovaná dávka upravená o korekci dávky (Úprava dávky)',
    precision: 10,
    scale: 2,
  })
  actualRate: string | null;

  @Column('decimal', {
    name: 'acceleration',
    nullable: true,
    comment: 'Aktuální zrychlení zrychlení stroje.',
    precision: 8,
    scale: 2,
  })
  acceleration: string | null;

  @Column('decimal', {
    name: 'rate_correction',
    nullable: true,
    comment: 'Korekce dávky (Úprava dávky)',
    precision: 8,
    scale: 2,
  })
  rateCorrection: string | null;

  @ManyToOne('TZakazkyTerminal', 'tTabletApplieds', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_zakazky_terminal', referencedColumnName: 'idtZakazkaTerminal' }])
  idZakazkyTerminal2: TZakazkyTerminal;
}
