import { PropsFilterFnFactory } from '@ngrx/data';
import { TZakazky } from './entities/TZakazky';

export interface Order extends TZakazky {
  uid?: string; // TODO: doplneny uid z auth
  isOrgFertilizerPrevSeason?: boolean;
  isApplied?: boolean;
}

export const Order = {
  selectId: (a: Order) => {
    //In this case this would be optional since primary key is id
    return String(a.idZakazky); // TBD: refId ?
  },
  sortByDescription: (a: Order, b: Order): number => {
    return a.popis && a.popis.localeCompare(b.popis);
  },
  filterFn: (orderList) => {
    return orderList.filter((order: Order) => {
      return order.orderStatus !== 4;
    });
  },

  // // localityNameFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string) => {
  // //   return PropsFilterFnFactory(['name'])(localities, pattern);
  // // },

  // localityEnabledFilterFn: (
  //   localities: Locality[],
  //   pattern: string) => {
  //   return PropsFilterFnFactory(['enabled'])(localities, !!pattern);
  // },

  // // localityIdFilterFn: (
  // //   localities: Locality[],
  // //   pattern: string | number) => {
  // //   return localities.find(a => {
  // //     return a.id === pattern
  // //   });
  // //   //   // return PropsFilterFnFactory(['id'])(localities, pattern);
  // // // },

  // fullName: (p: Application) => {
  //   return p.order && `${p.order.popis} - ${p.order.popis}`;
  // },
};
