export interface Resource {
  id?: string; // NOTE: doplni se pri nacteni kvuli reduceru ale je stejne najo webmap_id
  webmap_id?: string; // NOTE: primarni id
  webmapId?: string; // NOTE: primarni id
  client_id?: string;
  source_layer_id?: string;
  // asset?: string, // GEE Asset
  name?: string; // GROSPOL STAŘÍČ, s.r.o.
  contract?: string; // 2019010
  asset?: string; // CLIP GEE asset name
  rvp?: string; // RVP GEE asset name
  // nasledujici atributy s eulozili i kdyz nebyly v DTO definici
  readonly client_details?: any; // display_name 2019010 AGROSPOL STAŘÍČ, s.r.o.
  readonly client_resource_group?: any;
}

export const Resource = {
  selectId: (a: Resource): string => {
    //In this case this would be optional since primary key is id
    return a.webmap_id || a.id; // !!! NOTE: pro rucni zaznamy se bere dle id, protoze webmap_id se nevyplnuje
  },

  parseClientDetails: (resource: Resource) => {
    if (resource) {
      const display_name =
        resource && resource.client_resource_group && resource.client_resource_group.display_name;
      if (display_name) {
        const contract = display_name.substr(0, display_name.indexOf(' '));
        const name = display_name.substr(display_name.indexOf(' ') + 1);
        return { contract, name };
      } else {
        // neni to resource datova struktura nahrana externe pres API ale lokalne ulozeny zaznam, pouziji primo atributy name a contract
        return {
          name: resource.name,
          contract: resource.contract,
          // webmap_id: resource.id,
        };
      }
    }
  },

  parseAssetToRvp: (resource: Resource) => {
    if (!resource.rvp) {
      return { rvp: `${resource.asset}_RVP` };
    } else {
      return {};
    }
  },

  // sortByLastName: (a: Person, b: Person): number => {
  //   return a.lastName && a.lastName.localeCompare(b.lastName);
  // },

  // fullName: (p: Person) => {
  //   return `${p.lastName} ${p.firstName}`;
  // },
  // getSalutations: () => {
  //   return Object.keys(PersonSalutation).map(key => ({ name: `SALUTATION.${key.toUpperCase()}`, value: PersonSalutation[key] }));
  // }
};
