import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, switchMap, take } from 'rxjs/operators';
import { AuthError } from '../+state/auth.actions';
import { AuthFacade } from '../+state/auth.facade';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    public authFacade: AuthFacade,
  ) {
    if ((<any>window).Cypress) {
      afAuth.idToken.pipe(first()).subscribe((token) => {
        (<any>window).token = token;
        localStorage.setItem('token', token);
      });
    }
    // this.afAuth.authState.subscribe((authState) => {

    // })
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log(err);
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      this.router.navigateByUrl(`/layout/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      // console.log(err)
      this.authFacade.authError(err?.error?.message || err?.message); // {  "statusCode": 401,  "error": "Unauthorized",  "message": "user-not-enabled" }
      return of(err.message); // or EMPTY may be appropriate here
      // return of(new AuthError(err.message)); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // // Clone the request to add the new header.
    // const authReq = request.clone({ headers: request.headers.set(Cookie.tokenKey, Cookie.getToken()) });
    // // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    // return next.handle(authReq).pipe(catchError(x => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70

    return this.afAuth.idToken.pipe(
      take(1), // <-------------- only emit the first value!

      switchMap((token: any) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          });
        }
        // return next.handle(request);
        return next.handle(request).pipe(catchError((x) => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70;
      }),
    );
  }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return this.afAuth.idToken.pipe(
  //     first(),
  //     mergeMap((token: any) => {

  //       if (token) {
  //         request = request.clone({
  //           setHeaders: { Authorization: `Bearer ${token}` },
  //           withCredentials: true
  //         });
  //       }

  //       return next.handle(request);

  //     }));
  //   // // // const accessToken = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;

  //   // // return next.handle(request);
  //   // // Consider only adding the auth header to API requests as this will add it to all HTTP requests.
  //   // return this.addToken(request).pipe(
  //   //   first(),
  //   //   mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken))
  //   // );

  // }

  // /**
  //    * Adds the JWT token to the request's header.
  //    */
  // private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
  //   // NOTE: DO NOT try to immediately setup this selector in the constructor or as an assignment in a
  //   // class member variable as there's no stores available when this interceptor fires fires up and
  //   // as a result it'll throw a runtime error.
  //   return this.afAuth.authState.pipe(
  //     first(),
  //     // map(authState => _.get(authState.toJSON(), 'stsTokenManager.accessToken', null)),
  //     mergeMap((token: string) => {
  //       if (token) {
  //         request = request.clone({
  //           headers: request.headers.set("Authorization", `Bearer ${token}`),
  //           withCredentials: true
  //         });
  //       } else {
  //         console.warn(`Invalid token!!! Cannot use token "${token}".`);
  //       }
  //       return of(request);
  //     })
  //   );
  // }

  // private getOptions(feature: string, params: any = {}) {
  //   const authTokenHeader = {};
  //   let headers = {};
  //   if (this.afAuth.auth.currentUser && this.config.headers) {
  //     // const accessToken = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;
  //     const accessToken = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;
  //     // accessToken = this.afAuth.idToken;

  //     authTokenHeader[this.config.headers.authToken] = accessToken; //this.config.headers.request[this.config.headers.authToken];
  //     headers = {
  //       ...headers,
  //       // ...this.config.headers.request, ...authTokenHeader, ...{ 'Access-Control-Allow-Origin': '*' }
  //       ...this.config.headers.request, ...authTokenHeader
  //     };
  //   }
  //   return { headers, params };
  // }
}
