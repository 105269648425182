import { TBonitacniSkupiny } from './entities/TBonitacniSkupiny';

// tslint:disable-next-line: no-empty-interface
export interface BonityGroup extends TBonitacniSkupiny {}

export const BonityGroup = {
  selectId: (a: TBonitacniSkupiny) => {
    //In this case this would be optional since primary key is id
    return a.idBonitacniSkupiny;
  },
};
