import { TabletWifiSculFw } from '@varistar-apps/shared/data'; 
import { DropboxDirect, Kultura, NewFeatureCategory } from '@varistar-apps/shared/data';
import { LpisSkSelectInfo } from '@varistar-apps/shared/data';
import { HoldingPerson } from '@varistar-apps/shared/data';
import { HoldingPersonRole } from '@varistar-apps/shared/data';
import { PersonRole } from '@varistar-apps/shared/data';
import { Injectable } from '@angular/core';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import {
  EntityCollectionService,
  EntityCollectionServiceElementsFactory,
  EntityCollectionServiceFactory,
  MergeStrategy,
} from '@ngrx/data';
import { LoadingService } from '@varistar-apps/frontend/ui';
import {
  Annex,
  Application,
  BonityGroup,
  CiselnikDruh,
  Component,
  ConsumptionStandard,
  Contract,
  ContractTerminal,
  Customer,
  CustomerPerson,
  CustomerPersonDepartment,
  CustomerPersonDepartmentRole,
  CustomerPersonRole,
  Department,
  Fertilizer,
  FertilizerCustom,
  Field,
  FieldArchive,
  FieldGroup,
  FieldObservationNew,
  FieldSelect,
  FieldYieldYear,
  Holding,
  LpisPole,
  LpisSkPole,
  LpisSkSelect,
  MapDocument,
  NewFeature,
  OnboardingPerson,
  OpenBalenaFleet,
  Order,
  OrderField,
  OrderStatus,
  OrderTerminal,
  OrderType,
  Owner,
  Permission,
  Person,
  PesticidCustom,
  Pesticides,
  PesticidType,
  PlanetApiKey,
  Plant,
  PlantType,
  ProductPrice,
  Resource,
  Role,
  Seed,
  SeedCustom,
  Sim,
  TabletMachine,
  TabletMachineType,
  TabletMachineUnit,
  TabletProtocol,
  TabletProtocolSpeed,
  TabletSettings,
  Tankmix,
  Terminal,
  TerminalSoil,
  TerminalType,
  User,
  VaristarProfile,
  Vyfakturuj,
  YieldPrice,
} from '@varistar-apps/shared/data';
import { FieldEdit } from 'libs/frontend/ui-user-portal/src/lib/historicalYields/field-edit';
import { combineLatest, forkJoin, isObservable, Observable, of, Subject } from 'rxjs';
import { filter, first, map, mergeScan, scan, switchMap, withLatestFrom } from 'rxjs/operators';

export enum FeatureConfiguration {
  TabletWifiSculFw = 'TabletWifiSculFw',
  Kultura = 'Kultura',
  LpisSkSelectInfo = 'LpisSkSelectInfo',
  HoldingPerson = 'HoldingPerson',
  HoldingPersonRole = 'HoldingPersonRole',
  PersonRole = 'PersonRole',
  PlanetApiKey = 'PlanetApiKey',
  NewFeature = 'NewFeature',
  NewFeatureCategory = 'NewFeatureCategory',
  FieldGroup = 'FieldGroup',
  LpisPole = 'LpisPole',
  CiselnikDruh = 'CiselnikDruh',
  Seed = 'Seed',
  YieldPrice = 'YieldPrice',
  PesticidCustom = 'PesticidCustom',
  SeedCustom = 'SeedCustom',
  User = 'User',
  VaristarProfile = 'VaristarProfile',
  Application = 'Application',
  Order = 'Order',
  OrderType = 'OrderType',
  OrderStatus = 'OrderStatus',
  OrderTerminal = 'OrderTerminal',
  OrderField = 'OrderField',
  Field = 'Field',
  FieldArchive = 'FieldArchive',
  FieldObservationNew = 'FieldObservationNew',
  FieldYieldYear = 'FieldYieldYear',
  Terminal = 'Terminal',
  TerminalType = 'TerminalType',
  TerminalSoil = 'TerminalSoil',
  TabletMachine = 'TabletMachine',
  TabletMachineType = 'TabletMachineType',
  Owner = 'Owner',
  FieldSelect = 'FieldSelect',
  FieldEdit = 'FieldEdit',
  Plant = 'Plant',
  PlantType = 'PlantType',
  Holding = 'Holding',
  Customer = 'Customer',
  Contract = 'Contract',
  Annex = 'Annex',
  Fertilizer = 'Fertilizer',
  FertilizerCustom = 'FertilizerCustom',
  Vyfakturuj = 'Vyfakturuj',
  Person = 'Person',
  OnboardingPerson = 'OnboardingPerson',
  Pesticides = 'Pesticides',
  PesticidType = 'PesticidType',
  BonityGroup = 'BonityGroup',
  Resource = 'Resource',
  MapDocument = 'MapDocument',
  Weather = 'Weather',
  CustomerPerson = 'CustomerPerson',
  CustomerPersonRole = 'CustomerPersonRole',
  Component = 'Component',
  Department = 'Department',
  Role = 'Role',
  Permission = 'Permission',
  CustomerPersonDepartment = 'CustomerPersonDepartment',
  CustomerPersonDepartmentRole = 'CustomerPersonDepartmentRole',
  Sim = 'Sim',
  LpisSkSelect = 'LpisSkSelect',
  LpisSkPole = 'LpisSkPole',
  Tankmix = 'Tankmix',
  TabletProtocol = 'TabletProtocol',
  TabletProtocolSpeed = 'TabletProtocolSpeed',
  TabletMachineUnit = 'TabletMachineUnit',
  TabletSettings = 'TabletSettings',
  ContractTerminal = 'ContractTerminal',
  ConsumptionStandard = 'ConsumptionStandard',
  ProductPrice = 'ProductPrice',
  OpenBalenaFleet = 'OpenBalenaFleet',
  DropboxDirect = 'DropboxDirect',
}

export interface EnhancedEntityCollectionService<T> extends EntityCollectionService<T> {
  getByKeyFromCache: (
    id: string | number | Observable<string | number>,
    filterNull?: boolean,
  ) => Observable<T>;
  //need to find better name for this method, but don't want to overwrite default getByKey method
  getByKeyCacheFirst: (
    id:
      | string
      | number
      | Observable<string | number>
      | string[]
      | number[]
      | Observable<string | number>[],
  ) => Observable<T>;
  loadWithQuery: (query: any) => Observable<T[]>;
}

export interface EnhancedEntityCollectionServiceOrder<Order>
  extends EnhancedEntityCollectionService<Order> {
  loadOrderFields: (orderId: number | string) => Observable<OrderField[]>;
  queryOrderFields: (orderId: number | string) => Observable<OrderField[]>;
}

export interface EnhancedEntityCollectionServiceOrderField<OrderField>
  extends EnhancedEntityCollectionService<OrderField> {
  entitiesFilteredByOrderId$: (
    orderId$: Observable<number | string> | number | string,
  ) => Observable<OrderField[]>;
  entitiesFilteredByOrder$: (orderId$: Observable<Order> | Order) => Observable<OrderField[]>;
}

//component is only in cache; do not use methods of EntityCollectionService;
export interface EnhancedEntityCollectionServiceComponent<Component>
  extends EnhancedEntityCollectionService<Component> {
  selectComponent: (component: any, subscriptionHandler: Function) => void;
  updateComponent: (component: any, modificationMap: object) => void;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  TabletWifiSculFw: EnhancedEntityCollectionService<TabletWifiSculFw>;
  Kultura: EnhancedEntityCollectionService<Kultura>;
  LpisSkSelectInfo: EnhancedEntityCollectionService<LpisSkSelectInfo>;
  HoldingPerson: EnhancedEntityCollectionService<HoldingPerson>;
  HoldingPersonRole: EnhancedEntityCollectionService<HoldingPersonRole>;
  PersonRole: EnhancedEntityCollectionService<PersonRole>;
  Role: EntityCollectionService<Role>;
  Permission: EntityCollectionService<Permission>;
  CustomerPersonDepartment: EntityCollectionService<CustomerPersonDepartment>;
  CustomerPersonDepartmentRole: EntityCollectionService<CustomerPersonDepartmentRole>;
  PlanetApiKey: EnhancedEntityCollectionService<PlanetApiKey>;
  NewFeature: EnhancedEntityCollectionService<NewFeature>;
  NewFeatureCategory: EnhancedEntityCollectionService<NewFeatureCategory>;
  FieldGroup: EnhancedEntityCollectionService<FieldGroup>;
  LpisPole: EnhancedEntityCollectionService<LpisPole>;
  CiselnikDruh: EnhancedEntityCollectionService<CiselnikDruh>;
  Seed: EnhancedEntityCollectionService<Seed>;
  YieldPrice: EnhancedEntityCollectionService<YieldPrice>;
  PesticidCustom: EnhancedEntityCollectionService<PesticidCustom>;
  SeedCustom: EnhancedEntityCollectionService<SeedCustom>;
  User: EnhancedEntityCollectionService<User>;
  VaristarProfile: EnhancedEntityCollectionService<VaristarProfile>;
  Application: EnhancedEntityCollectionService<Application>;
  OrderType: EnhancedEntityCollectionService<OrderType>;
  OrderStatus: EnhancedEntityCollectionService<OrderStatus>;
  OrderTerminal: EnhancedEntityCollectionService<OrderTerminal>;
  Field: EnhancedEntityCollectionService<Field>;
  FieldArchive: EnhancedEntityCollectionService<FieldArchive>;
  DropboxDirect: EnhancedEntityCollectionServiceOrderField<DropboxDirect>;
  FieldEdit: EnhancedEntityCollectionService<FieldEdit>;
  FieldYieldYear: EnhancedEntityCollectionService<FieldYieldYear>;
  Terminal: EnhancedEntityCollectionService<Terminal>;
  TerminalType: EnhancedEntityCollectionService<TerminalType>;
  TerminalSoil: EnhancedEntityCollectionService<TerminalSoil>;
  TabletMachine: EnhancedEntityCollectionService<TabletMachine>;
  TabletMachineType: EnhancedEntityCollectionService<TabletMachineType>;
  Owner: EnhancedEntityCollectionService<Owner>;
  FieldSelect: EnhancedEntityCollectionService<FieldSelect>;
  Plant: EnhancedEntityCollectionService<Plant>;
  PlantType: EnhancedEntityCollectionService<PlantType>;
  Holding: EnhancedEntityCollectionService<Holding>;
  Customer: EnhancedEntityCollectionService<Customer>;
  Contract: EnhancedEntityCollectionService<Contract>;
  Annex: EnhancedEntityCollectionService<Annex>;
  Fertilizer: EnhancedEntityCollectionService<Fertilizer>;
  FertilizerCustom: EnhancedEntityCollectionService<FertilizerCustom>;
  Vyfakturuj: EnhancedEntityCollectionService<Vyfakturuj>;
  Person: EnhancedEntityCollectionService<Person>;
  OnboardingPerson: EnhancedEntityCollectionService<OnboardingPerson>;
  Pesticides: EnhancedEntityCollectionService<Pesticides>;
  PesticidType: EnhancedEntityCollectionService<PesticidType>;
  BonityGroup: EnhancedEntityCollectionService<BonityGroup>;
  Resource: EnhancedEntityCollectionService<Resource>;
  MapDocument: EnhancedEntityCollectionService<MapDocument>;
  CustomerPerson: EnhancedEntityCollectionService<CustomerPerson>;
  CustomerPersonRole: EnhancedEntityCollectionService<CustomerPersonRole>;
  Component: EnhancedEntityCollectionServiceComponent<Component>;
  Department: EnhancedEntityCollectionService<Department>;
  Sim: EnhancedEntityCollectionService<Sim>;
  LpisSkPole: EnhancedEntityCollectionService<LpisSkPole>;
  LpisSkSelect: EnhancedEntityCollectionService<LpisSkSelect>;
  TabletProtocol: EnhancedEntityCollectionService<TabletProtocol>;
  TabletProtocolSpeed: EnhancedEntityCollectionService<TabletProtocolSpeed>;
  TabletMachineUnit: EnhancedEntityCollectionService<TabletMachineUnit>;
  TabletSettings: EnhancedEntityCollectionService<TabletSettings>;
  ContractTerminal: EnhancedEntityCollectionService<ContractTerminal>;
  FieldObservationNew: EnhancedEntityCollectionService<FieldObservationNew>;
  ProductPrice: EnhancedEntityCollectionService<ProductPrice>;
  ConsumptionStandard: EnhancedEntityCollectionService<ConsumptionStandard>;
  Tankmix: EnhancedEntityCollectionService<Tankmix>;

  Order: EnhancedEntityCollectionServiceOrder<Order>;
  OrderField: EnhancedEntityCollectionServiceOrderField<OrderField>;

  OpenBalenaFleet: EnhancedEntityCollectionService<OpenBalenaFleet>;

  private loadingMap = {};

  constructor(
    private featureConfigurationService: FeatureConfigurationService<any>,
    private loadingService: LoadingService,
  ) {
    for (let collectionName in FeatureConfiguration) {
      try {
        this[collectionName] = Object.assign(
          //must be in this order, because Object assign do not copy functions!!
          this.featureConfigurationService.getFeatureService(FeatureConfiguration[collectionName]),
          {
            //filterNull = true; (implementation)
            getByKeyFromCache: (id, filterNull) =>
              this.getByKeyFromCache(id, collectionName, filterNull),
            getByKeyCacheFirst: (id) => this.getByKeyCacheFirst(id, collectionName),
            loadWithQuery: (query) => this.loadWithQuery(query, collectionName),
          },
        );

        this.loadingMap[collectionName] = new Subject();
        this.loadingMap[collectionName]
          .pipe(
            mergeScan((acc, request: Observable<any>, index) => {
              //start fetching data
              //but take results in correct order;
              // do not overwrite faster fetch, which was done later
              return request.pipe(withLatestFrom(of(index)), first());
            }, null),
            scan(
              ([prevResult, prevIndex], [result, index]) => {
                if (index > prevIndex) {
                  return [result, index];
                } else {
                  return [prevResult, prevIndex];
                }
              },
              [null, -1],
            ),
          )
          .subscribe(([resultList]) => {
            this[collectionName].clearCache();
            // merge stategy need IgnoreChanges: otherwise if statusChanges = 1, delete will not work
            this[collectionName].addManyToCache(resultList, {
              mergeStrategy: MergeStrategy.IgnoreChanges,
            });
          });
      } catch (err) {
        // console.log('data service error', err);
      }
    }

    //add extension methods to some Collections

    // ORDER
    this.Order['loadOrderFields'] = (orderId) => {
      const { queryObject: queryOrderFields } = RequestQueryBuilder.create({
        search: { $and: [{ idZakazky: orderId }] },
      });

      return this.OrderField.loadWithQuery(queryOrderFields);
    };

    this.Order['queryOrderFields'] = (orderId) => {
      const { queryObject: queryOrderFields } = RequestQueryBuilder.create({
        search: { $and: [{ idZakazky: orderId }] },
      });

      return this.OrderField.getWithQuery(queryOrderFields);
    };

    // ORDER FIELD
    this.OrderField['entitiesFilteredByOrderId$'] = (orderId$) => {
      return combineLatest([
        this.OrderField.filteredEntities$,
        isObservable(orderId$) ? orderId$ : of(orderId$),
      ]).pipe(
        map(([orderFieldList, orderId]) => {
          return orderFieldList.filter((field: OrderField) => {
            return field.idZakazky === orderId;
          });
        }),
      );
    };

    this.OrderField['entitiesFilteredByOrder$'] = (order$: any) => {
      return combineLatest([
        this.OrderField.filteredEntities$,
        (isObservable(order$) ? order$ : of(order$)).pipe(
          first((order) => !!order),
          map((order: any) => order.idZakazky),
        ),
      ]).pipe(
        map(([orderFieldList, orderId]) => {
          return orderFieldList.filter((field: OrderField) => {
            return field.idZakazky === orderId;
          });
        }),
      );
    };

    // COMPONENT
    this.Component['selectComponent'] = (component: any, subscriptionHandler: Function) => {
      return this.Component.entityMap$
        .pipe(map((x) => x[this.getComponentId(component)]))
        .subscribe((selectedComponent: any) => {
          if (!selectedComponent) {
            this.Component.addOneToCache(<any>{
              id: this.getComponentId(component),
            });
          } else {
            subscriptionHandler(selectedComponent);
          }
        });
    };

    this.Component['updateComponent'] = (component: any, modificationMap: Object) => {
      this.Component.updateOneInCache(<any>{
        id: this.getComponentId(component),
        ...modificationMap,
      });
    };
  }

  private getByKeyFromCache = (id, collectionName: string, filterNull = true) => {
    if (isObservable(id)) {
      return combineLatest([id, this[collectionName].entityMap$]).pipe(
        map(([key, entityMap]: any) => {
          return entityMap[key] || null;
        }),
        filter((entity) => {
          return !filterNull || !!entity;
        }),
      );
    }

    return this[collectionName].entityMap$.pipe(
      map((entityMap) => {
        return entityMap[id] || null;
      }),
      filter((entity) => !filterNull || !!entity),
    );
  };

  private getByKeyCacheFirst = (id, collectionName) => {
    if (Array.isArray(id)) {
      return forkJoin(
        id.map((element) => {
          return this.getByKeyCacheFirst(element, collectionName).pipe(first());
        }),
      );
    }

    this.getByKeyFromCache(id, collectionName, false)
      .pipe(first())
      .subscribe((entity) => {
        if (!entity) {
          this.loadingService.withLoading(
            (isObservable(id) ? id : of(id)).pipe(
              first(),
              switchMap((entityId) => {
                return this[collectionName].getByKey(entityId);
              }),
            ),
          );
        }
      });

    return this.getByKeyFromCache(id, collectionName);
  };

  //if second request is fired before first request ends, then clearing cache before request returns data would have no effect, and resulted into
  //merging results of all queries
  private loadWithQuery = (query, collectionName) => {
    query = query.queryObject || query;
    const loadingObservable = this[collectionName].getWithQuery(query);

    this.loadingMap[collectionName].next(loadingObservable);

    return loadingObservable;
  };

  private getComponentId = (component: any) => {
    return `${component.constructor.name}_${component.route.snapshot.routeConfig.path}`;
  };
}

//DEPRECATED!!! - use DataService
@Injectable({
  providedIn: 'root',
})
export class FeatureConfigurationService<T> {
  //<T> extends EntityCollectionServiceBase<T> {
  private featureEntityCollectionServices: {
    [feature: string]: EntityCollectionService<T>;
  } = {};

  constructor(
    private entityCollectionServiceFactory: EntityCollectionServiceFactory,
    private entityCollectionServiceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {}

  getFeatureService(feature: string) {
    if (!this.featureEntityCollectionServices[feature])
      this.featureEntityCollectionServices[feature] =
        this.entityCollectionServiceFactory.create(feature);
    return this.featureEntityCollectionServices[feature];
  }

  load(feature: FeatureConfiguration, ids = [], replace = false) {
    const featureService = this.getFeatureService(feature);
    // if (replace)
    //   featureService.clearCache();
    featureService.getAll();
    // TODO: implementovat jen pro vybrane ids
    return featureService.filteredEntities$; // musim vratit filtered, protoze nekdy chci jen ty co jsou enabled
  }

  query(feature: FeatureConfiguration, query: any, replace = false) {
    const featureService = this.getFeatureService(feature);
    if (replace) featureService.clearCache();
    featureService.getWithQuery(query);
    return featureService.filteredEntities$; // musim vratit filtered, protoze nekdy chci jen ty co jsou enabled
  }

  // query(feature: ConfigurationFeature, params = {}, query = null) {
  //   // this.store.dispatch(new QueryConfiguration(feature, params, query));
  // }

  select(feature: FeatureConfiguration, id: string | number): Observable<T> {
    const featureService = this.getFeatureService(feature);
    // return featureService.getByKey(id);
    return featureService.entityMap$.pipe(map((x) => x[id]));
    // // this.getFeatureService(feature).getByKey(id);
    // return this.getFeatureService(feature).entityMap$.pipe(map(x => x[id]))
    // // return this.getEntityCollectionService(feature).getByKey(id);
    // // return this.getEntityCollectionService(feature).filteredEntities$.pipe(
    // //   map(fe => fe[0]),
    // // )
    // // this.getEntityCollectionService(feature).setFilter(id);
    // // return this.getEntityCollectionService(feature).filteredEntities$.pipe(
    // //   map(fe => fe[0]),
    // // )
    // // this.store.dispatch(new SelectConfiguration(feature, id));
  }

  // add(feature: ConfigurationFeature, data: ConfigurationPayload, ids: any = {}) {
  add(feature: FeatureConfiguration, data: T, ids: any = {}) {
    return this.getFeatureService(feature).add(data).toPromise();
    // const collectionPath = Firestore.getPath(feature, { ...this.authFacade.account, ...data, ...ids })
    // return this.afs.collection(collectionPath).add(data).then(newData => {
    //   this.store.dispatch(new ConfigurationAdded(feature, { id: newData.id, ...data }));
    //   this.select(feature, newData.id);
    // });;
    // return Promise.reject();
  }

  upsert(feature: FeatureConfiguration, data: T, ids: any = {}) {
    return this.getFeatureService(feature).upsert(data).toPromise();
  }

  // modify(feature: ConfigurationFeature, data: Partial<ConfigurationPayload>, id: string, ids: any = {}) {
  modify(feature: FeatureConfiguration, data: Partial<T>, id: string | number, ids: any = {}) {
    return this.getFeatureService(feature).update(data).toPromise();
    // const collectionPath = Firestore.getPath(feature, { ...this.authFacade.account, ...data, ...ids })
    // // nechci ukladat id, ktere se pridalo pri nacteni dokumentu
    // const dataWithoutId = { ...data };
    // delete dataWithoutId.id;
    // return this.afs.collection(collectionPath).doc(id).update(dataWithoutId).then(() => {
    //   this.store.dispatch(new ConfigurationModified(feature, data as ConfigurationPayload));
    // });;
    // return Promise.reject();
  }

  // remove(feature: ConfigurationFeature, id: string, ids: any = {}) {
  remove(feature: FeatureConfiguration, id: string | number, ids: any = {}) {
    return this.getFeatureService(feature).delete(id).toPromise();
  }

  // getAll(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selectAll));
  // }
  // getTotal(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selectTotal));
  // }
  // getIds(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selectIds));
  // }
  // getEntities(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selectEntities));
  // }

  // getLoaded(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).loaded));
  // }

  // getSelectedId(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selectedId));
  // }
  // getSelected(feature: ConfigurationFeature) {
  //   return this.store.pipe(select(getConfigurationQuery(feature).selected));
  // }
}
