import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  DefaultDataServiceConfig,
  DefaultDataServiceFactory,
  EntityCollectionReducerMethodsFactory,
  EntityCollectionReducerRegistry,
  EntityDataModule,
  HttpUrlGenerator,
  PersistenceResultHandler,
} from '@ngrx/data';
import { ConfigurationModule } from '@varistar-apps/frontend/feature';

import { defaultDataServiceConfig } from './entity-metadata';
import { entityMetadata, pluralNames } from './feature-configuration/feature-configuration.module';
import { FeatureConfigurationService } from './feature-configuration/feature-configuration.service';
import { FrontendUiFeatureConfigurationPersistenceResultHandler } from './forntend-ui-feature-configuration.persistent';
import { FrontendUiFeatureConfig } from './frontend-ui-feature-config';
import { FrontendUiFeatureConfigurationEntityCollectionReducerMethodsFactory } from './frontend-ui-feature-configuration.reducer';
import { FrontendUiFeatureExtendedDataServiceFactory } from './frontend-ui-feature-extended-data-service-factory';
import { FrontendUiFeatureHttpUrlGenerator } from './frontend-ui-feature-http-url-generator';

@NgModule({
  imports: [
    CommonModule,
    ConfigurationModule, // TODO: Odstranit závislost na Firestore konfiguraci
    // FeatureConfigurationModule,
    // !!! puvodni co se preinicializovalo v lazy load  FrontendUiFeatureModule pomoci eds.registerMetadataMap(entityMetadata);
    // EntityDataModule.forRoot(entityConfig),
    // !!! nahrazeno inicializaci hned
    EntityDataModule.forRoot({
      entityMetadata: entityMetadata,
      pluralNames: pluralNames,
    }),
  ],
  exports: [
    // FeatureConfigurationModule
  ],
  providers: [],
})
export class FrontendUiFeatureModule {
  constructor(
    entityCollectionReducerMethodsFactory: EntityCollectionReducerMethodsFactory,
    entityCollectionReducerRegistry: EntityCollectionReducerRegistry,
    @Optional()
    @SkipSelf()
    parentModule: FrontendUiFeatureModule,
  ) {
    // if (parentModule) {
    //   throw new Error(
    //     'ApiOcsModule is already loaded. Import it in the AppModule only'
    //   );
    // }
    // entityCollectionReducerMethodsFactory.create('Fertilizer')
    // entityCollectionReducerRegistry.registerReducer('Fertilizer', entityCollectionReducerFactory )
    // entityCollectionReducerMethodsFactory.create('Fertilizer');
    // const reducer = entityCollectionReducerRegistry.getOrCreateReducer('Fertilizer');
    // entityCollectionReducerRegistry.registerReducer('Fertilizer', reducer);
  }

  static forRoot(config: FrontendUiFeatureConfig): ModuleWithProviders {
    return {
      ngModule: FrontendUiFeatureModule,
      providers: [
        // !!! z FeatureConfigurationModule
        FeatureConfigurationService,
        //  !!! nahrazeno inicializaci hned
        // { provide: PLURAL_NAMES_TOKEN, multi: true, useValue: pluralNames },

        //
        // { provide: INITIAL_REDUCERS, useValue: pluralNames },
        { provide: FrontendUiFeatureConfig, useValue: config },
        {
          provide: DefaultDataServiceConfig,
          useValue: { ...defaultDataServiceConfig, ...config },
        }, // NOTE: nefungovalo v FeatureConfigurationModul; pripadna konfigurace v environment prepise lokalni default konfiguraci
        {
          provide: HttpUrlGenerator,
          useClass: FrontendUiFeatureHttpUrlGenerator,
        },
        {
          provide: DefaultDataServiceFactory,
          useClass: FrontendUiFeatureExtendedDataServiceFactory,
        },
        {
          provide: PersistenceResultHandler,
          useClass: FrontendUiFeatureConfigurationPersistenceResultHandler,
        },
        {
          provide: EntityCollectionReducerMethodsFactory,
          useClass: FrontendUiFeatureConfigurationEntityCollectionReducerMethodsFactory,
        },
        // {
        //   provide: EntityCollectionReducerMethodsFactory,
        //   useClass: AdditionalEntityCollectionReducerMethodsFactory
        // }, ,

        // {
        //   provide: PersistenceResultHandler,
        //   useClass: AdditionalPropertyPersistenceResultHandler
        // },
        // {
        //   provide: EntityCollectionReducerMethodsFactory,
        //   useClass: AdditionalEntityCollectionReducerMethodsFactory
        // }
      ],
    };
  }
}
