import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SelectSeasonId, SetSeasonOptionList } from './settings.actions';
import { SettingsPartialState } from './settings.reducer';
import { getSeasonOptionList, getSelectedSeasonId } from './settings.selectors';


// import * as fb from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class SettingsFacade {
  seasonOptionList$ = this.store.select(getSeasonOptionList).pipe(filter((seasonOptionList) => !!seasonOptionList.length));
  selectedSeasonId$ = this.store.select(getSelectedSeasonId).pipe(filter((seasonId) => !!seasonId));

  selectSeasonId(seasonId: number) {
    //oznacuje zacatecni rok sezony; tedy '20' je sezona 2020/2021
    this.store.dispatch(new SelectSeasonId(seasonId));
  }

  setSeasonOptionList(seasonOptionList: { name: string, value: number }[]) {
    this.store.dispatch(new SetSeasonOptionList(seasonOptionList));
  }

  constructor(private store: Store<SettingsPartialState>) {}
}
