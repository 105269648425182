import { TNewFeature } from './entities/TNewFeature';

export const newFeatureTranslationList = ['cz', 'en', 'sk'];

// tslint:disable-next-line: no-empty-interface
export interface NewFeature extends TNewFeature {}

export const NewFeature = {
  selectId: (x: TNewFeature) => {
    return x.idNewFeature;
  },
};
