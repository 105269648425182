import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TTerminaly } from './TTerminaly';

@Index('Terminaly_idx', ['idTerminaly'], {})
@Index('date_time_possition', ['dateTime'], {})
@Index('lon1', ['lon'], {})
@Index('lat1', ['lat'], {})
@Entity('t_tablet_position', { schema: 'VaristarIS' })
export class TTabletPosition {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_tablet_position' })
  idtTabletPosition: string;

  @Column('int', {
    name: 'id_terminaly',
    nullable: true,
    comment: 'Cizí klíč do tabulky terminály.',
  })
  idTerminaly: number | null;

  @Column('datetime', {
    name: 'date_time',
    nullable: true,
    comment: 'Datum a čas odeslání dat z tabletu v UTC',
  })
  dateTime: Date | null;

  @Column('datetime', {
    name: 'device_time',
    nullable: true,
    comment: 'Čas sběru dat v česové zóně tabletu.',
  })
  deviceTime: Date | null;

  @Column('decimal', {
    name: 'lon',
    nullable: true,
    comment: 'Zeměpisná délka',
    precision: 13,
    scale: 10,
  })
  lon: string | null;

  @Column('decimal', {
    name: 'lat',
    nullable: true,
    comment: 'Zeměpisná šířka',
    precision: 13,
    scale: 10,
  })
  lat: string | null;

  @Column('decimal', {
    name: 'speed',
    nullable: true,
    comment: 'Aktuální rychlost stroje',
    precision: 5,
    scale: 2,
  })
  speed: string | null;

  @Column('decimal', {
    name: 'altitude',
    nullable: true,
    comment: 'Aktuální nadmořská výška',
    precision: 6,
    scale: 2,
  })
  altitude: string | null;

  @ManyToOne('TTerminaly', 'tTabletPositions', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_terminaly', referencedColumnName: 'idTerminaly' }])
  idTerminaly2: TTerminaly;
}
