<!-- <mat-form-field fxFlex>
    <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="'PLACEHOLDER.FILTER' | translate"
      autocomplete="off">
  </mat-form-field> -->

<!-- {{data | json}} -->
<div *ngFor="let item of data; let i = index">
  <dynamic-form
    header="SUBTITLE.CONFIGURATION.SETTINGS.VALUE"
    description="DESCRIPTION.CONFIGURATION.SETTINGS.VALUE"
    [controls]="controls"
    [data]="data[i]"
    layout="simple"
    [enable]="enable"
    (save)="onSave($event, i)"
    (delete)="onDelete($event, i, value)"
    [autosave]="true"
    (modify)="onModify($event)"
  >
    <!-- Prida se pred tlacitka Save a Cancel -->
    <!-- <button mat-icon-button
[disableRipple]="true" color="" (click)="onDelete(i)" [matTooltip]="'BUTTON.ADD' | translate"
              matTooltipPosition="above">
              <mat-icon>minus_box</mat-icon>
            </button> -->
  </dynamic-form>
</div>

<!-- <dynamic-form header="SUBTITLE.CONFIGURATION.SETTINGS.VALUE" description="DESCRIPTION.CONFIGURATION.SETTINGS.VALUE"
    [controls]="controls" [data]="data" layout="simple" [enable]="enable" (save)="onSave($event)" (delete)="onDelete($event)" [autosave]="true"
    (modify)="onModify($event)"> -->
<!-- Prida se pred tlacitka Save a Cancel -->
<!-- <button mat-icon-button
[disableRipple]="true" color="" (click)="onAdd($event)" [matTooltip]="'BUTTON.ADD' | translate"
        matTooltipPosition="above">
        <mat-icon>add_box</mat-icon>
      </button> -->
<!-- </dynamic-form> -->

<!-- {{value | json}}

<div fxLayout="column" class="mat-typography" fxLayoutGap="16px">

  <div fxLayout="column" fxLayoutGap="16px">

    <div *ngFor="let conversion of value; let i = index;">

      <div fxLayout="row" fxLayoutAlign="space-between center">

        <mat-form-field>
          <input matInput [matDatepicker]="dp"
            [placeholder]="'PLACEHOLDER.LOCALITY.PARAMS.CONVERSION.VALID_FROM' | translate"
            [value]="conversion.validFrom" (dateChange)="changeValidFrom($event, i, conversion.validFrom)">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>

        <div>
          <button mat-icon-button
[disableRipple]="true" [matTooltip]="'BUTTON.CONVERSION.COPY_CONSTANTS' | translate"
            matTooltipPosition="above" (click)="onCopy(i)">
            <mat-icon>library_add</mat-icon>
          </button>
          <button mat-icon-button
[disableRipple]="true" [matTooltip]="'BUTTON.CONVERSION.DELETE_CONSTANT' | translate"
            matTooltipPosition="above" (click)="onDelete(i)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button
[disableRipple]="true" [matTooltip]="'BUTTON.CONVERSION.COPY_TO_LOCALITY' | translate"
            matTooltipPosition="above" (click)="onCopyToLocality(i)">
            <mat-icon>archive</mat-icon>
          </button>
          <ng-content></ng-content>
          <ng-content select="formButtons"></ng-content>
        </div>

      </div>

      <dynamic-form header="SUBTITLE.LOCALITY.PARAMS.CONVERSION" description="DESCRIPTION.LOCALITY.PARAMS.CONVERSION"
        [controls]="controls[i]" [data]="conversion" (save)="onSave($event, i)" (modify)="onModify($event, i)"
        resetWhenChanged="locality.id" layout="simple" [enable]="enable" [autosave]="true">
      </dynamic-form>

    </div>
  </div>



<mat-card fxLayout="column" fxFlexFill>
  <mat-card-header fxLayout="row">
    <mat-form-field fxFlex>
      <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="'PLACEHOLDER.FILTER' | translate"
        autocomplete="off">
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-icon-button
[disableRipple]="true" color="warn" [matTooltip]="'BUTTON.REMOVE_SELECTED' | translate"
        matTooltipPosition="above" [disabled]="!selection.selected.length" [matBadge]="selection.selected.length"
        matBadgePosition="after" matBadgeColor="warn" [matBadgeHidden]="!selection.selected.length"
        (click)="onDeleteSelected()" *ngIf="selection.selected.length && data?.enable?.delete && showSelection">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button
[disableRipple]="true" [matTooltip]="'BUTTON.NEW_LOCALITY' | translate" matTooltipPosition="above"
        (click)="onAdd()" *ngIf="data?.enable?.new">
        <mat-icon>playlist_add</mat-icon>
      </button>
      <button mat-icon-button
[disableRipple]="true"
        [matTooltip]="(showSelection ? 'BUTTON.CANCEL_SELECTION': 'BUTTON.REMOVE_SELECTED') | translate"
        matTooltipPosition="above" (click)="toggleSelection()">
        <mat-icon>playlist_add_check</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content fxFlex fxLayout="row">
    <div fxFlex="15" class="configuration-locality-container">
      <button mat-menu-item
        *ngFor="let locality of localities$ | async | onsiteLocalityFilter: localityFilter |  onsiteOrderBy: ['id']"
        [disabled]="locality.id === localityId" [routerLink]="getLocalityConfigLink(locality)">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>{{locality.id}} : {{locality.name}}</span>
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(locality) : null"
            [checked]="selection.isSelected(locality)" color="warn" *ngIf="showSelection && data?.enable?.select">
          </mat-checkbox>
        </div>
      </button>
    </div>
    <div fxFlex class="configuration-locality-container">
      <router-outlet></router-outlet>
    </div>

  </mat-card-content>
</mat-card> -->
