import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  Annex,
  ApiVaristarGeeAssetMapRequest,
  ApiVaristarGeeImagesResponse,
  ApiVaristarGeeMapRequest,
  ApiVaristarGeeMapResponse,
  ApiVaristarLpisCompaniesRequest,
  ApiVaristarLpisCompaniesResponse,
  ApiVaristarVaristarIsCompaniesResponse,
  ApiVaristarVaristarIsCrOwnersResponse,
  ApiVaristarVaristarIsLocalitiesResponse,
  ApplicationMapStats,
  Contract,
  ContractSetRypMapSensitivityRequest,
  Customer,
  Email,
  Field,
  OpenBalenaDeviceAction,
  OpenBalenaDeviceServiceAction,
  OsVectorSublayer,
  PostgisGatewayResponse,
  SetRypBufferByAnnexRequest,
  SetRypBufferToFidRequest,
  SetRypMapSensitivityToFidRequest,
  TerminalSoil,
  TLpisSelect,
  TZakazky,
} from '@varistar-apps/shared/data';
import { getSeasonBoundaries, getSeasonBoundaries$, getUrl } from '@varistar-apps/shared/utilities';
import { Observable, of, throwError } from 'rxjs';

import { FrontendApiVaristarConfig } from '../frontend-api-varistar-config';
// import { ConfigurationFacade, ConfigurationFeature } from '@varistar-apps/frontend/feature';
import { TranslateService } from '@ngx-translate/core';
import { CurrentService, LogMessage } from 'balena-sdk';
import * as _ from 'lodash';
import * as moment from 'moment';
import { catchError, delay, first, map, mergeMap, retryWhen, shareReplay } from 'rxjs/operators';
import { RypMapOrder } from '../../../../../shared/data/src/lib/ryp-map-order';

const DEFAULT_MAX_RETRIES = 3;
const geErrorMessage = (maxRetry: number) =>
  `Tried to load data over XHR for ${maxRetry} times without success. Giving up,`;

@Injectable({
  providedIn: 'root',
})
export class ApiVaristarService {
  cache: any = {};
  config: FrontendApiVaristarConfig;
  mapStats: ApplicationMapStats;

  constructor(
    @Optional() config: FrontendApiVaristarConfig,
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    private translateService: TranslateService,
    // private configurationFacade: ConfigurationFacade,
  ) {
    if (config) this.config = { ...this.config, ...config };
  }

  //

  /**
   * Připraví aplikační mapu k dané zakázce
   * a případně navýší RATE o zadaný koeficient, který před tím se musí uložit do map_koef v t_zakazky, to ale zpusobi zapomenuti customizace rate na fields
   * NOTE: nově se koeficient jen uloží, napočítání se provede až při načítání mapy
   * @param order zakazka
   */
  createApplicationMap(order: TZakazky, mapCoefficient: number = 1) {
    const feature = 'APPLICATION_CREATE_MAP';
    const url = this.getApiUrl(feature, { order }, {});
    return new Promise((resolve, reject) => {
      this.http
        .post(url, { mapCoefficient, lang: this.translateService.currentLang })
        .subscribe(resolve, reject);
    });
  }

  createAppMap(order: TZakazky) {
    const feature = 'APPLICATION_CREATE_APP_MAP';
    const url = this.getApiUrl(feature, { order }, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { lang: this.translateService.currentLang }).subscribe(resolve, reject);
    });
  }

  countRate(
    order: TZakazky,
    onlyStats: boolean = false,
    mainProperty: string = 'RATE',
    layerLabel: string = 'FERTILIZATION',
    layerPalette: string[] = null,
  ) {
    const feature = 'APPLICATION_COUNT_RATE';
    const url = this.getApiUrl(feature, { order }, {});
    return new Promise((resolve, reject) => {
      this.http
        .post(url, { lang: this.translateService.currentLang, onlyStats })
        .subscribe(resolve, reject);
    })
      .then((response: PostgisGatewayResponse) => {
        this.mapStats = response?.statistics;
        return response;
      })
      .then((response) => {
        return this.getApplicationMapResponse(response, mainProperty, layerLabel, layerPalette);
      });
  }

  private getApplicationMapResponse(
    response: PostgisGatewayResponse,
    mainProperty: string,
    layerLabel: string,
    layerPalette: string[],
  ) {
    const mapStats = response?.statistics;
    this.mapStats = mapStats; // zapamatuji si pro pozdejsi pouziti
    const layerMin = _.get(mapStats, [mainProperty, 'min'], 100);
    const layerMax = _.get(mapStats, [mainProperty, 'max'], 300);

    const vectorSublayer: OsVectorSublayer = {
      content: response?.geojson_data,
    };
    const layers = [
      {
        label: layerLabel,
        /*backgroundSublayer: {
      url: 'https://earthengine.googleapis.com/v1alpha/projects/earthengine-legacy/maps/fae596638e65469717009c6e976c4be7-73e853fb0ab93595f3a611d62ed18afe/tiles/{z}/{x}/{y}',
      name: 'test-overlay'
    },*/
        hasBackground: true,
        // vectorSublayer: MapJson['layer'],
        // vectorSublayer: { geoJsonUrl: 'http://localhost:3333/api/varistar-file-gateway-api/download/78/geojson' },
        // vectorSublayer: { shapeFileUrl: 'http://localhost:3333/api/varistar-file-gateway-api/download/78/shape' },
        // vectorSublayer: { shapeZipUrl: 'http://localhost:3333/api/varistar-file-gateway-api/download/78/shape.zip' },
        vectorSublayer: vectorSublayer,
        // bounds: {
        //   southWest: [
        //     49.75223720598075,
        //     13.212387792718259
        //   ],
        //   northEast: [
        //     49.80758802853016,
        //     13.299496280150569
        //   ]
        // },
        mapParams: {
          min: layerMin,
          max: layerMax,
          palette: layerPalette || [
            '9e0142',
            'd53e4f',
            'f46d43',
            'fdae61',
            'fee08b',
            'ffffbf',
            'e6f598',
            'abdda4',
            '66c2a5',
            '3288bd',
            '5e4fa2',
          ],
        },
        mainProperty,
      },
    ];
    return layers;
  }

  updateOrderMapCoefficient(order: TZakazky, mapCoefficient: number = 1) {
    const feature = 'APPLICATION_UPDATE_ORDER_MAP_COEFFICIENT';
    const url = this.getApiUrl(feature, { order }, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { mapCoefficient }).subscribe(resolve, reject);
    });
  }

  /**
   * Získá url na aplikační mapu k dané zakázce v shapefile zip formátu
   * @param order zakazka
   */
  getApplicationMapUrl(order: TZakazky) {
    return console.error('DEPRECATED');
  }

  // !!! NOTE: nahrazeno pouze pouzitim createApplicationMap
  // /**
  //  * Upraví aplikační mapu v dané zakázce přes zadaný RATE koeficient
  //  * před tím se musí nastavit map_koef v t_zakazky
  //  * @param order zakazka
  //  */
  // updateApplicationMapByCoefficient(order: TZakazky, mapCoefficient: number = 1) {
  //   const feature = 'APPLICATION_MAP_UPDATE_BY_COEFFICIENT';
  //   const url = this.getApiUrl(feature, { order, }, { mapCoefficient });
  //   // TODO: ne get ale post
  //   return new Promise((resolve, reject) => {
  //     this.http.get(url).subscribe(resolve, reject);
  //   });
  //   // return (this.http.get(url) as Observable<any>).pipe().toPromise();
  // }

  /**
   * Upraví aplikační mapu v dané zakázce a polygonu na novou hodnotu RATE
   * @param order zakazka
   * @param pid polygon id
   * @param rate rate
   */
  updateApplicationMapRateInPolygon(order: TZakazky, pid: number, rate: number) {
    const feature = 'APPLICATION_UPDATE_RATE_IN_POLYGON';
    // const url = this.getApiUrl(feature, { order, }, { pid, rate });
    const url = this.getApiUrl(feature, { order });
    // TODO: ne get ale post
    return new Promise((resolve, reject) => {
      // this.http.get(url).subscribe(resolve, reject);
      this.http.post(url, { pid, rate }).subscribe(resolve, reject);
    });
    // return (this.http.get(url) as Observable<any>).pipe();
  }

  /**
   * Získá statistiku aplikační mapy, kolik jakého produktu a kolik na jakých polích
   * @param order zakazka
   */
  async getApplicationMapStats(order: TZakazky) {
    return console.error('DEPRECATED');
  }

  /**
   * Získá layer s mapou aplikace a naplní i statistiku
   * @param order zakazka
   * @param mainProperty atribut v shapefile, který se použije pro zobrazení hodnoty v mapě
   */
  async getApplicationMapLayer(
    order: TZakazky,
    mainProperty: string = 'RATE',
    layerLabel: string = 'FERTILIZATION',
    layerPalette: string[] = null,
  ) {
    return console.error('DEPRECATED');
  }

  /**
   * Získá mapu a statistiku aplikační mapy, kolik jakého produktu a kolik na jakých polích
   * NOTE: nahrazuje getApplicationMapUrl a getApplicationMapStats použité v getApplicationMapLayer
   *
   * @param order zakazka
   */
  async getApplicationMapAndStats(
    order: TZakazky,
    onlyStats: boolean = false,
  ): Promise<PostgisGatewayResponse> {
    const feature = 'APPLICATION_READ_MAP';
    // const feature = 'APPLICATION_UPDATE_RATE'; // aktualizuje v mape rate a vrati data mapy a i statistiku
    const url = this.getApiUrl(feature, { order }, { onlyStats });
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(resolve, reject);
    }).then((response: PostgisGatewayResponse) => {
      this.mapStats = response?.statistics;
      return response;
    });
  }

  async applicationGetMap(config: {
    idZakazky?: string;
    schema?: string;
    idDodatek?: string | number;
    fid?: string;
    table?: string;
    idtStrediska?: string | number;
  }) {
    const feature = 'APPLICATION_GET_MAP';
    const url = this.getApiUrl(feature, {}, config);

    return this.http.get(url).toPromise();
  }

  // async getMaintenancePage(): Promise<any> {
  //   const feature = 'MAINTENANCE_PAGE';
  //   const url = this.getApiUrl(feature);

  //   return ;
  // }

  async getApplicationMapSimple(
    idZakazky: string,
    onlyStats: boolean = false,
  ): Promise<PostgisGatewayResponse> {
    const feature = 'APPLICATION_READ_MAP'; // APPLICATION_READ_MAP_SIMPLE
    // const feature = 'APPLICATION_UPDATE_RATE'; // aktualizuje v mape rate a vrati data mapy a i statistiku
    const zakazky: TZakazky = new TZakazky();
    zakazky.idZakazky = idZakazky;
    const url = this.getApiUrl(feature, { zakazky }, { onlyStats });
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe(resolve, reject);
    }).then((response: PostgisGatewayResponse) => {
      this.mapStats = response?.statistics;
      return response;
    });
    //   .then((response: PostgisGatewayResponse) => {
    //     this.mapStats = response.statistics || null;
    // });
  }

  getAppliedMap = (orderId): Promise<any> => {
    const feature = 'GET_APPLIED_MAP';

    const url = this.getApiUrl(feature, {}, { orderId });
    return this.http.get(url).toPromise();
  };

  /**
   * Získá layer s mapou aplikace a naplní i statistiku přes volání Postgis Gateway API
   * @param order zakazka
   * @param mainProperty atribut v shapefile, který se použije pro zobrazení hodnoty v mapě
   */
  async getApplicationMapPostgisLayer(
    order: TZakazky,
    mainProperty: string = 'RATE',
    layerLabel: string = 'FERTILIZATION',
    layerPalette: string[] = null,
  ) {
    if (mainProperty !== 'RATE' && mainProperty !== 'NET')
      throw `mainProperty ${mainProperty} is not supported`;
    return this.getApplicationMapAndStats(order, false).then((response) => {
      return this.getApplicationMapResponse(response, mainProperty, layerLabel, layerPalette);
    });
  }

  /**
   *
   * @param order zakazka
   * @param mainProperty atribut v shapefile, který se použije pro zobrazení hodnoty v mapě
   */
  getApplicationMap(order: TZakazky, mainProperty: string) {
    return console.error('DEPRECATED');
  }

  /**
   * Upraví aplikační mapu v dané zakázce a polygonu na novou hodnotu RATE
   * @param order zakazka
   * @param pid polygon id
   * @param rate rate
   */
  startApplicationMapSendToTerminals(order: TZakazky) {
    const feature = 'APPLICATION_SEND_MAP_TO_TERMINALS';
    const url = this.getApiUrl(feature, { order });
    return new Promise((resolve, reject) => {
      this.http.post(url, { orderStatus: order.orderStatus }).subscribe(resolve, reject);
    });
  }

  /**
   * Aktualizuje email v HubSpot
   * @param hsOsobaId
   * @param emaile
   */
  updateHubspotEmail(hsOsobaId: number, email: string) {
    const feature = 'ONBOARDING_UPDATE_HUBSPOT_EMAIL';
    const url = this.getApiUrl(feature, {}, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { hsOsobaId, email }).subscribe(resolve, reject);
    });
  }

  /**
   * Připraví záznam v t%user pro danou osobu,
   * @param personId id osoby
   */
  preparePersonLogin(personId: string | number, location: string) {
    const feature = 'ONBOARDING_PREPARE_PERSON_LOGIN';
    const url = this.getApiUrl(feature, {}, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { personId, location }).subscribe(resolve, reject);
    });
  }
  /**
   * Spojí vytvořený login uživatele s osobou na portále
   * ověří že předaný emailToken je validní a napojí osobu na uid v t_user
   * @param emailToken emailem předaný token s vazbou na osobu
   */
  activatePersonLogin(emailToken: string) {
    const feature = 'ONBOARDING_ACTIVATE_PERSON_LOGIN';
    const url = this.getApiUrl(feature, {}, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { emailToken }).subscribe(resolve, reject);
    });
  }

  /**
   * Spojí vytvořený login uživatele s osobou na portále
   * ověří že předaný emailToken je validní a napojí osobu na uid v t_user
   * @param emailToken emailem předaný token s vazbou na osobu
   */
  resetPassword(email: string, location: string) {
    const feature = 'ONBOARDING_RESET_PASSWORD';
    const url = this.getApiUrl(feature, {}, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, { email, location }).subscribe(resolve, reject);
    });
  }

  /**
   * Získá základní informace do profilu uživatele z dle t_user z t_osoby a t_zakaznik_osoby
   * @returns
   */
  getVaristarProfile() {
    const feature = 'AUTHORIZATION_GET_VARISTAR_PROFILE';
    const url = this.getApiUrl(feature, {});
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  /**
   * Získá dokument s mapami dane zakazky ve formatu .zip
   * @returns
   */
  getOrderMapDocument(cisloZakazky: string, cisloTerminaluList: string[]) {
    const feature = 'MAP_DOCUMENT_GET_MAP_DOCUMENT';
    const url = this.getApiUrl(feature, {}, { cisloZakazky, cisloTerminaluList });
    return (
      this.http.get(url, {
        responseType: 'blob',
        observe: 'response',
      }) as Observable<any>
    ).pipe(
      // this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
      shareReplay(),
    );
  }

  /**
   * Posle dokument s mapami dane zakazky ve formatu .zip na email uzivatele
   * @returns
   */
  sendMapDocumentToEmail(
    cisloZakazky: string,
    cisloTerminaluList: string[],
    email: string,
    emailSubject: string,
    fileName: string,
  ) {
    const feature = 'UTILITY_SEND_MAP_DOCUMENT_TO_EMAIL';
    const url = this.getApiUrl(feature);
    const headers = new HttpHeaders('Content-Type: application/json');

    const body = JSON.stringify({
      cisloZakazky,
      email,
      cisloTerminaluList,
      emailSubject,
      fileName,
    });

    return new Promise((resolve, reject) => {
      this.http.post(url, body, { headers }).subscribe(resolve, reject);
    });
  }

  /**
   * Získá základní informace do profilu uživatele z dle t_user z t_osoby a t_zakaznik_osoby
   * @returns
   */
  sendEmail(email: Email) {
    const feature = 'UTILITY_SEND_EMAIL';
    const url = this.getApiUrl(feature, {});
    return new Promise((resolve, reject) => {
      this.http.post(url, email).subscribe(resolve, reject);
    });
  }

  /**
   * Spustí autorizaci přístupu na John Deere portálu a následně aktualizuje seznam terminálů
   * @param contractId
   * @param contractNumber
   * @param redirectUrl url frontend aplikace  kam se to vrátí  z John Deere portalu zpět po dokončení loginu a případné autorrizaci přístupu k terminálům
   * @returns
   */
  async authorizeToJohnDeerePortalAndGetTerminals(
    contractId: number,
    contractNumber: number,
    redirectUrl: string,
  ) {
    // try {
    //   const logoutResponse = await this.http.get(this.config.varistarJohnDeereLogoutUrl, {}).toPromise();
    //   if (logoutResponse['status'] !== 200)
    //     console.warn(`John Deere logout unsuccessful`);
    // } catch (ex) {
    //   console.warn(`John Deere logout exception : ${ex.message}`);
    // }
    const feature = 'AUTHORIZATION_TO_JOHN_DEERE_PORTAL_AND_GET_TERMINALS';
    const url = this.getApiUrl(feature, {}, { contractId, contractNumber, redirectUrl });
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  /**
   * Získá stav spuštěného Airflow DAG tasku
   * @returns {"conf":{"cislo_smlouvy":"2018001","id_smlouvy":"3"},"dag_id":"TEST_call_jd_login_task","dag_run_id":"manual__2021-04-14T13:25:46.431936+00:00","end_date":null,"execution_date":"2021-04-14T13:25:46.431936+00:00","external_trigger":true,"start_date":"2021-04-14T13:25:46.433718+00:00","state":"running"}
   */
  getDagRunStatus(dagId: string, dagRunId: string) {
    const feature = 'AIRFLOW_GET_DAG_RUN_STATUS';
    const url = this.getApiUrl(
      feature,
      {},
      {
        dagId,
        dagRunId,
      },
    );
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  awaitDagRun(dagId: string, dagRunId: string) {
    const feature = 'AIRFLOW_AWAIT_DAG_RUN';
    const url = this.getApiUrl(
      feature,
      {},
      {
        dagId,
        dagRunId,
      },
    );
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  /**
   * Aktivuje mapu, spustí simplifikaci a pošle ji do terminálu
   * @param idOrderTerminal idt_zakazka_terminal
   */
  activateMap(idOrderTerminal: number) {
    const feature = 'AIRFLOW_ACTIVATE_MAP';
    const url = this.getApiUrl(feature);
    // TODO: ne get ale post
    return new Promise((resolve, reject) => {
      this.http.post(url, { idOrderTerminal }).subscribe(resolve, reject);
    });
  }

  /**
   * Deaktivuje mapu
   * @param idOrderTerminal idt_zakazka_terminal
   */
  deactivateMap(idOrderTerminal: number) {
    const feature = 'AIRFLOW_DEACTIVATE_MAP';
    const url = this.getApiUrl(feature);

    return new Promise((resolve, reject) => {
      this.http.post(url, { idOrderTerminal }).subscribe(resolve, reject);
    });
  }

  deactivateMapByOrder(idZakazky: string, deleteFromServer = false) {
    const feature = 'AIRFLOW_DEACTIVATE_MAP_BY_ORDER';
    const url = this.getApiUrl(feature);

    return new Promise((resolve, reject) => {
      this.http.post(url, { idZakazky, deleteFromServer }).subscribe(resolve, reject);
    });
  }

  createNewOrder(order: TZakazky) {
    const feature = 'ORDER_CREATE';
    const url = this.getApiUrl(feature);
    const options = this.getOptions(feature, order);
    return this.http.post(url, order, options) as Observable<TZakazky>;
  }

  getTerminalOwnerName(terminalId: number): Observable<any> {
    const feature = 'GET_TERMINAL_OWNER_NAME';
    const url = this.getApiUrl(feature, {}, { terminalId });
    return this.http.get(url);
  }

  getOrder(idOrder: number) {
    const feature = 'ORDER_GET';
    const url = this.getApiUrl(feature, { idOrder });
    return (this.http.get(url) as Observable<TZakazky>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of(null);
      }),
      shareReplay(),
    );
  }

  getOpenOrders(idAppendix: number) {
    const feature = 'ORDERS_GET';
    const url = this.getApiUrl(feature, { idAppendix });
    return (this.http.get(url) as Observable<TZakazky>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of(null);
      }),
      shareReplay(),
    );
  }

  //

  getLpisCompany(name: string) {
    const feature = 'LPIS_COMPANY';
    const url = this.getApiUrl(feature, { name });
    return (this.http.get(url) as Observable<ApiVaristarVaristarIsCompaniesResponse>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
      shareReplay(),
    );
  }

  getLpisCrOwners(idLpis: string) {
    const feature = 'LPIS_CR_OWNERS';
    const url = this.getApiUrl(feature, { idLpis });
    return (this.http.get(url) as Observable<ApiVaristarVaristarIsCrOwnersResponse>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
      shareReplay(),
    );
  }

  getLpisCrFields(idLpis: string, idOwner: string) {
    const feature = 'LPIS_CR_FIELDS';
    const url = this.getApiUrl(feature, { idLpis, idOwner });
    // return (this.http.get(url) as Observable<ApiVaristarVaristarIsCrFileldsResponse>).pipe(
    return (this.http.get(url) as Observable<TLpisSelect>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
      shareReplay(),
    );
  }

  getLpisSkLocality(name: string) {
    const feature = 'LPIS_SK_LOCALITY';
    const url = this.getApiUrl(feature, { name });
    return (this.http.get(url) as Observable<ApiVaristarVaristarIsLocalitiesResponse>).pipe(
      this.delayedRetry(1000, 3),
      catchError((error) => {
        console.error(error);
        return of([]);
      }),
      shareReplay(),
    );
  }

  postLpisCompanies(
    lpisParams: ApiVaristarLpisCompaniesRequest,
  ): Observable<ApiVaristarLpisCompaniesResponse> {
    const feature = 'LPIS_COMPANIES';
    const url = this.getApiUrl(feature, lpisParams);
    const options = this.getOptions(feature, lpisParams);

    return this.http.post(url, lpisParams, options) as Observable<ApiVaristarLpisCompaniesResponse>;
  }

  postSatelitteImages(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeImagesResponse> {
    // nemuzu pouzit postGeeApi, protoze vraci jiny ty a kombinace nesla
    const feature = 'SATELLITE_IMAGES';
    const url = this.getApiUrl(feature);
    const options = this.getOptions(feature, compositeParams);
    return this.http
      .post(url, compositeParams, options)
      .pipe(first()) as Observable<ApiVaristarGeeImagesResponse>;
  }

  postCompositeImageMap(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('COMPOSITE_MAP', compositeParams);
  }

  postCompositeImagePoint(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('COMPOSITE_POINT', compositeParams);
  }

  postCompositeImageDownloadUrl(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('COMPOSITE_DOWNLOAD', compositeParams);
  }

  postSmartScoutingMap(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('SMART_SCOUTING_MAP', compositeParams);
  }

  postSmartScoutingDownloadUrl(
    compositeParams: ApiVaristarGeeMapRequest,
  ): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('SMART_SCOUTING_DOWNLOAD', compositeParams);
  }

  postAssetMap(assetParams: ApiVaristarGeeAssetMapRequest): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('ASSET_MAP', assetParams);
  }

  postAssetRvp(assetParams: ApiVaristarGeeAssetMapRequest): Observable<ApiVaristarGeeMapResponse> {
    return this.postGeeApi('ASSET_RVP', assetParams);
  }

  private postGeeApi(
    feature: string,
    compositeParams: ApiVaristarGeeMapRequest | ApiVaristarGeeAssetMapRequest,
  ) {
    const url = this.getApiUrl(feature);
    const options = this.getOptions(feature, compositeParams);
    return (
      this.http.post(url, compositeParams, options) as Observable<ApiVaristarGeeMapResponse>
    ).pipe(
      this.delayedRetry(1000, 3),
      first(),
      // catchError(error => {
      //   console.error(error);
      //   return [];
      // }),
      // shareReplay()
    );
  }

  private getApiUrl(feature: string, ids?: any, query?: any): string {
    return getUrl([this.config.apiUrl, ...this.config.apiPath[feature]], ids, query);
  }

  private getOptions(feature: string, params: any = {}) {
    const authTokenHeader = {};
    let headers = {};
    if (this.afAuth.auth.currentUser && this.config.headers) {
      // const accessToken = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;
      const accessToken = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager
        .accessToken;
      // accessToken = this.afAuth.idToken;

      authTokenHeader[this.config.headers.authToken] = accessToken; //this.config.headers.request[this.config.headers.authToken];
      headers = {
        ...headers,
        // ...this.config.headers.request, ...authTokenHeader, ...{ 'Access-Control-Allow-Origin': '*' }
        ...this.config.headers.request,
        ...authTokenHeader,
      };
    }
    return { headers, params };
  }

  private delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
    let retries = maxRetry;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((errors: Observable<any>) =>
          errors.pipe(
            mergeMap((error) =>
              error.error && error.error.code === 400 ? throwError(error) : of(error),
            ),

            delay(delayMs),
            mergeMap((error) => (retries-- > 0 ? of(error) : throwError(geErrorMessage(maxRetry)))),
          ),
        ),
      );
  }

  getUserDashboardConfig(): Promise<object> {
    const feature = 'USERS_DASHBOARD';
    const url = this.getApiUrl(feature);
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getHoldingUsers(holdingId: string): Promise<object> {
    const feature = 'HOLDING_USERS';
    const url = this.getApiUrl(feature, { holdingId });

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getUserCustomers(idOsoby: string): Promise<object> {
    const feature = 'USER_CUSTOMERS';
    const url = this.getApiUrl(feature, { idOsoby });

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getAllCustomers(): Promise<Customer[]> {
    const feature = 'ALL_CUSTOMERS';
    const url = this.getApiUrl(feature, {});

    return this.http.get(url).toPromise() as Promise<Customer[]>;
  }

  // getOnboardingCustomerPersons(idOsoby: number, filterAllUsers: boolean): Promise<object> {
  //   const feature = 'ONBOARDING_PERSONS';
  //   const url = this.getApiUrl(feature, { idOsoby }, { filterAllUsers } );

  //   return new Promise((resolve, reject) => {
  //     this.http.get(url, {}).subscribe(resolve, reject);
  //   });
  // }

  getCustomerUsers(idZakaznik: string): Promise<object> {
    const feature = 'CUSTOMER_USERS';
    const url = this.getApiUrl(feature, { idZakaznik });

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getCustomerUsersForProtocol(idZakaznik: string): Promise<object> {
    const feature = 'CUSTOMER_USERS_FOR_PROTOCOL';
    const url = this.getApiUrl(feature, { idZakaznik });

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getTerminalSoilList(idZakaznik: string): Promise<TerminalSoil[]> {
    const feature = 'TERMINAL_SOIL_LIST';
    const url = this.getApiUrl(feature, { idZakaznik });

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  getActiveCustomers(): Promise<object> {
    const feature = 'ACTIVE_CUSTOMERS';
    const url = this.getApiUrl(feature);

    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(resolve, reject);
    });
  }

  createTerminalSoil(idZakaznik: string, newTerminal: any): Promise<TerminalSoil> {
    const feature = 'CREATE_TERMINAL_SOIL';
    const url = this.getApiUrl(feature, { idZakaznik });

    return new Promise((resolve, reject) => {
      this.http.post(url, newTerminal).subscribe(resolve, reject);
    });
  }

  patchUserDashboardConfig(data: object): Promise<object> {
    const feature = 'USERS_DASHBOARD';
    const url = this.getApiUrl(feature);
    return new Promise((resolve, reject) => {
      this.http.patch(url, data).subscribe(resolve, reject);
    });
  }

  getDashboardData(annexId: number, departmentId: number) {
    const feature = 'DASHBOARD_DATA';
    const queryObject = { annexId };
    if (departmentId) {
      queryObject['departmentId'] = departmentId;
    }

    return this.http.get(this.getApiUrl(feature, {}, queryObject));
  }

  getAppliedMapGeoJSON(idZakazkyTerminal: string) {
    const feature = 'APPLIED_MAP';
    const url = this.getApiUrl(feature, {}, { idZakazkyTerminal });
    return this.http.get(url);
  }

  getIsAppliedMap(idZakazkyList: string[]) {
    const feature = 'IS_APPLIED_MAP';
    const url = this.getApiUrl(feature) + `?idZakazkyList=${idZakazkyList.join(',')}`;
    return this.http.get(url);
  }

  getOrdersByCustomerId(customerId: string) {
    const feature = 'ORDERS_BY_CUSTOMER_ID';
    const url = this.getApiUrl(feature, {}, { customerId });

    return this.http.get(url);
  }

  getPlantId(plant: string) {
    const feature = 'PLANT_ID';
    const url = this.getApiUrl(feature, {}, { plant });

    return this.http
      .get(url)
      .toPromise()
      .then((result: any) => {
        return result.plantId || result[0]?.plantId;
      });
  }

  fetchMapLayers({
    selectedFidList = [],
    outputType = null,
    observationDate = null,
    schema,
    table = null,
  }) {
    const feature = 'MAP_LAYERS';
    if (!table) {
      // varianta pres POST pro nacitani vetsiny layers, ale nepodporuje primo dle table name z libovolneho schematu
      let url = this.getApiUrl(feature);
      const convertedDate = observationDate
        ? observationDate.getFullYear() +
          '-' +
          _.padStart(observationDate.getMonth() + 1, 2, '0') +
          '-' +
          _.padStart(observationDate.getDate(), 2, '0') /*+
      'T00:00:00.000Z' */
        : null;

      //selectedFidList can be very long, so I must make post request
      return this.http.post(url, {
        selectedFidList: selectedFidList.join(','),
        observationDate: convertedDate,
        outputType: outputType,
        schema,
      });
    } else {
      // varianta pres GET pro nacteni dle table name z libovolneho schematu
      let url = this.getApiUrl(
        feature,
        {},
        {
          schema,
          table,
          outputType: outputType,
        },
      );
      return this.http.get(url);
    }
  }

  fetchDemLayers(selectedFidList: string[] = []) {
    const feature = 'DEM_LAYERS';

    let url = this.getApiUrl(feature);

    return this.http.post(url, {
      selectedFidList: selectedFidList.join(','),
    });
  }

  fetchMapLayersGet({ outputType = null, observationDate = null, schema, table = null }) {
    const feature = 'APPLICATION_GET_MAP';
    let url = this.getApiUrl(
      feature,
      {},
      {
        schema,
        table,
        outputType: outputType,
      },
    );
    return this.http.get(url, {});
  }

  //Get Map portal soil analysis (pudni rozbory) and yield meters (vynosomery)
  //Setup to get all data from year 2000 when set seasonId
  getMapPortalSchemaOptionMap = async (fidList, seasonId?, authFacade?) => {
    const feature = 'MAP_PORTAL_SCHEMA_OPTION_MAP';
    const url = this.getApiUrl(feature);

    if (seasonId) {
      return getSeasonBoundaries(authFacade, seasonId).then(
        ({ seasonStart: startDate, seasonEnd: endDate }) => {
          return this.http
            .post(url, {
              fidList,
              startDate,
              endDate,
            })
            .toPromise();
        },
      );
    }

    return this.http
      .post(url, {
        fidList,
        startDate: '2000-01-01',
        endDate: '3000-01-01',
      })
      .toPromise();
  };

  getMapPortalSchemaOptionMap$ = (fidList, seasonId?, authFacade?) => {
    const feature = 'MAP_PORTAL_SCHEMA_OPTION_MAP';
    const url = this.getApiUrl(feature);

    if (seasonId) {
      return getSeasonBoundaries$(authFacade, seasonId).pipe(
        map(({ startDate, endDate }: any) => {
          return this.http.post(url, {
            fidList,
            startDate: '2000-01-01',
            endDate,
          });
        }),
      );
    }

    return this.http.post(url, {
      fidList,
      startDate: '2000-01-01',
      endDate: '3000-01-01',
    });
  };

  getMapPortalAppMapsOptionMap(seasonId, idDodatekList, departmentId, fidList) {
    const feature = 'MAP_PORTAL_APP_MAPS_OPTION_MAP';
    const url = this.getApiUrl(feature);
    return this.http.post(url, {
      seasonId,
      idDodatekList,
      departmentId,
      fidList,
    });
  }

  getBulkExistsAppliedMap(seasonId, idDodatekList, departmentId) {
    const feature = 'BULK_EXISTS_APPLIED_MAP';
    const url = this.getApiUrl(
      feature,
      {},
      { seasonId, idDodatekList: idDodatekList.join(','), departmentId },
    );
    return this.http.get(url);
  }

  getExistsAppliedMap(orderId) {
    const feature = 'EXISTS_APPLIED_MAP';
    const url = this.getApiUrl(feature, {}, { orderId });
    return this.http.get(url) as Observable<boolean>;
  }

  getMapPortalSoilCollectedPoints({
    dateFrom,
    dateTo,
    fidList,
    terminalList,
    customerId,
    departmentId,
  }: any) {
    const feature = 'MAP_PORTAL_SOIL_COLLECTED_POINTS';

    const queryParams = [];

    if (dateFrom) {
      queryParams.push(`dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}`);
    }

    if (dateTo) {
      queryParams.push(`dateTo=${moment(dateTo).format('YYYY-MM-DD')}`);
    }

    if (fidList?.length) {
      queryParams.push(`fidList=${fidList.join(',')}`);
    }

    if (terminalList?.length) {
      queryParams.push(`terminalList=${terminalList.join(',')}`);
    }

    if (customerId) {
      queryParams.push(`customerId=${customerId}`);
    }

    if (departmentId) {
      queryParams.push(`departmentId=${departmentId}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    const url = this.getApiUrl(feature) + queryString;
    return this.http.get(url);
  }

  soilPortalGetPoints(customerId, departmentId) {
    const feature = 'SOIL_PORTAL_GET_POINTS';

    const url = this.getApiUrl(feature, {}, { customerId, departmentId });
    return this.http.get(url);
  }

  soilPortalCreateMap(fidList, dateFrom, dateTo) {
    const feature = 'SOIL_PORTAL_CREATE_MAP';

    const url = this.getApiUrl(feature);
    return this.http.post(url, { fidList, dateFrom, dateTo });
  }

  soilPortalDeleteMap(mapName) {
    const feature = 'SOIL_PORTAL_DELETE_MAP';

    const url = this.getApiUrl(feature, { mapName });
    return this.http.delete(url);
  }

  soilPortalUpdateMap(mapName) {
    const feature = 'SOIL_PORTAL_UPDATE_MAP';

    const url = this.getApiUrl(feature, { mapName }, {});
    return this.http.put(url, {});
  }

  getMapPortalSoilPlannedPoints(filter: { fidList?: Array<string>; terminalList?: Array<number> }) {
    const feature = 'MAP_PORTAL_SOIL_PLANNED_POINTS';

    const query_params = [];

    if (filter.fidList?.length) {
      query_params.push(`fidList=${filter.fidList.join(',')}`);
    }

    if (filter.terminalList?.length) {
      query_params.push(`terminalList=${filter.terminalList.join(',')}`);
    }

    const query_string = query_params.length ? `?${query_params.join('&')}` : '';
    const url = this.getApiUrl(feature) + query_string;
    return this.http.get(url);
  }

  postMapPortalSoilPlannedPoints({
    longitude,
    latitude,
    idTerminal,
  }: {
    longitude: number;
    latitude: number;
    idTerminal: number;
  }): Promise<object> {
    const feature = 'MAP_PORTAL_SOIL_PLANNED_POINTS';
    const url = this.getApiUrl(feature);
    return this.http
      .post(url, {
        longitude,
        latitude,
        id_terminal: idTerminal,
      })
      .toPromise();
  }

  deleteMapPortalSoilPlannedPoints(id: number): Promise<object> {
    const feature = 'MAP_PORTAL_SOIL_PLANNED_POINTS';
    const url = `${this.getApiUrl(feature)}/${id}`;
    return new Promise((resolve, reject) => {
      this.http.delete(url).subscribe(resolve, reject);
    });
  }

  getPudniRozboryOptionMap(dodatekId) {
    const feature = 'PUDNI_ROZBORY_OPTION_LIST';
    const url = this.getApiUrl(feature, {}, { dodatekId });

    return this.http.get(url);
  }

  getFieldsWithMap(config): Observable<Field[]> {
    const feature = 'FIELDS_WITH_MAP';
    const url = this.getApiUrl(feature, {}, config);

    return <any>this.http.get(url);
  }

  getFieldsForRVP(config): Observable<Field[]> {
    const feature = 'FIELDS_FOR_RVP';
    const url = this.getApiUrl(feature, {}, config);

    return <any>this.http.get(url);
  }

  canHaveRightsForAdminSection(idtOsoby: number): Promise<boolean> {
    const feature = 'CAN_HAVE_RIGHTS_TO_ADMIN_SECTION';
    const url = this.getApiUrl(
      feature,
      {},
      {
        idtOsoby,
      },
    );

    return this.http.get(url).toPromise() as Promise<boolean>;
  }

  getIsGrain(kod: number, isCustom = false) {
    const feature = 'IS_GRAIN';
    const url = this.getApiUrl(feature, {}, { id: kod, isCustom });

    return this.http
      .get(url)
      .toPromise()
      .then((result: any) => {
        return result.isGrain || result[0]?.isGrain;
      });
  }

  getNewCisloTerminalu(idSmlouvy): Observable<any> {
    const feature = 'NEW_CISLO_TERMINALU';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url);
  }

  getPesticideType() {
    const feature = 'PESTICIDE_TYPE';
    const url = this.getApiUrl(feature);

    return this.http.get(url);
  }

  getLastAnnex(customerId: string): Promise<Annex> {
    const feature = 'LAST_ANNEX';
    const url = this.getApiUrl(feature, {}, { customerId });

    return this.http.get(url).toPromise() as Promise<Annex>;
  }

  getAnnexesForCustomerId(customerId: number): Promise<Annex[]> {
    const feature = 'ANNEXES_FOR_CUSTOMER';
    const url = this.getApiUrl(feature, {}, { customerId });

    return this.http.get(url).toPromise() as Promise<Annex[]>;
  }

  replaceDepartment(previousDepartmentId, nextDepartmentId): Promise<void> {
    const feature = 'REPLACE_DEPARTMENT';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        previousId: previousDepartmentId,
        nextId: nextDepartmentId,
      })
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  getContractByCustomerId(customerId: string): Promise<Contract> {
    const feature = 'CONTRACT_BY_CUSTOMER_ID';
    const url = this.getApiUrl(feature, { customerId }, {});

    return this.http.get(url).toPromise() as Promise<Contract>;
  }

  postSetRypMapSensitivityToContract(
    contractId: number,
    fids: string[],
    rypMapSensitivity: number,
  ): Promise<void> {
    const feature = 'CONTRACT_SET_RYP_MAP_SENSITIVITY';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        contractId,
        fids,
        rypMapSensitivity,
      } as ContractSetRypMapSensitivityRequest)
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  postSetRypBufferToFidsByAnnex(annexId: number, rypBuffer: number): Promise<void> {
    const feature = 'SET_RYP_BUFFER_BY_ANNEX';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        annexId,
        rypBuffer,
      } as SetRypBufferByAnnexRequest)
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  postSetRypMapSensitivityToFid(fid: string, rypMapSensitivity: number): Promise<void> {
    const feature = 'SET_RYP_MAP_SENSITIVITY_TO_FID';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fid,
        rypMapSensitivity,
      } as SetRypMapSensitivityToFidRequest)
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  postSetRypBufferToFid(fid: string, rypBuffer: number): Promise<void> {
    const feature = 'SET_RYP_BUFFER_TO_FID';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fid,
        rypBuffer,
      } as SetRypBufferToFidRequest)
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  getProtocolData(idSmlouvy) {
    const feature = 'SOIL_PROTOCOL_DATA';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url);
  }

  getAnnexesWithFields(idSmlouvy) {
    const feature = 'ANNEXES_WITH_FIELDS';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url);
  }


  getAnnexesWithFieldsForSeason(idSmlouvy, seasonId, isAnnexActive) {
    const feature = 'ANNEXES_WITH_FIELDS_FOR_SEASON';
    const url = this.getApiUrl(feature, {}, { idSmlouvy, seasonId, isAnnexActive });

    return this.http.get(url) as Observable<Annex[]>;
  }

  getSeasonOptionList(idSmlouvy) {
    const feature = 'SEASON_OPTION_LIST';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url);
  }

  getAnnexesWithFieldsArchive(idSmlouvy) {
    const feature = 'ANNEXES_WITH_FIELDS_ARCHIVE';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url);
  }

  getLpisSkNameOptionList() {
    const feature = 'LPIS_SK_NAME_OPTION_LIST';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.get(url);
  }

  getLpisSkKulturaOptionList() {
    const feature = 'LPIS_SK_KULTURA_OPTION_LIST';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.get(url);
  }

  getLpisSkCustomerName(key) {
    const feature = 'LPIS_SK_CUSTOMER_NAME';
    const url = this.getApiUrl(feature, {}, { key });

    return this.http.get(url);
  }

  saveLpisSk(fieldIdList, key) {
    const feature = 'LPIS_SK_SAVE';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.post(url, { fieldIdList, key });
  }

  uploadPostgisMap(params) {
    const feature = 'UPLOAD_POSTGIS_MAP';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.post(url, params);
  }

  lpisEndSendEmailToDealer(customerName, key) {
    const feature = 'LPIS_END_SEND_EMAIL_TO_DEALER';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.post(url, { customerName, key }).toPromise();
  }

  lpisSkEndSendEmailToDealer(customerName, key) {
    const feature = 'LPIS_SK_END_SEND_EMAIL_TO_DEALER';
    const url = this.getApiUrl(feature, {}, {});

    return this.http.post(url, { customerName, key }).toPromise();
  }

  modifyLpisFieldsEnd = (idLpisSelect, idUz, isEnd) => {
    const feature = 'MODIFY_LPIS_FIELDS_END';
    const url = this.getApiUrl(feature);
    return this.http.post(url, {
      idLpisSelect,
      idUz,
      isEnd,
    });
  };

  modifyLpisFieldsEndSk = (idLpisSelect, idUz, isEnd) => {
    const feature = 'MODIFY_LPIS_FIELDS_END_SK';
    const url = this.getApiUrl(feature);
    return this.http.post(url, {
      idLpisSelect,
      idUz,
      isEnd,
    });
  };

  getCustomerTerminals() {
    const feature = 'CUSTOMER_TERMINALS';
    const url = this.getApiUrl(feature);

    return this.http.get(url);
  }

  // TODO: add filter open balena devices by tags (direct terminal resolution)
  getOpenBalenaFleetDevices(fleetName: string) {
    const feature = 'OPENBALENA_FLEET_DEVICES';
    const url = this.getApiUrl(feature, { fleetName });
    return this.http.get(url);
  }

  getOpenBalenaFleetDevicesDetails(fleetName: string) {
    const feature = 'OPENBALENA_FLEET_DEVICES_DETAILS';
    const url = this.getApiUrl(feature, { fleetName });
    return this.http.get(url);
  }

  getOpenBalenaDeviceDetails(uuid: string) {
    const feature = 'OPENBALENA_DEVICE_DETAILS';
    const url = this.getApiUrl(feature, { uuid });
    return this.http.get(url);
  }

  getOpenBalenaDeviceLogs(uuid: string, count?: number, serviceId?: number) {
    const feature = 'OPENBALENA_DEVICE_LOGS';
    const url = this.getApiUrl(feature, { uuid }, { count, serviceId });
    return this.http.get(url) as Observable<LogMessage[]>;
  }

  getOpenBalenaDeviceSystemLog(uuid: string, count?: number) {
    const feature = 'OPENBALENA_DEVICE_SYSTEM_LOG';
    const url = this.getApiUrl(feature, { uuid }, { count });
    return this.http.get(url) as Observable<LogMessage[]>;
  }

  async setOpenBalenaDeviceName(uuid: string, name: string) {
    const feature = 'OPENBALENA_DEVICE_RENAME';
    const url = this.getApiUrl(feature, { uuid });
    return await this.http
      .put(url, {
        name,
      })
      .toPromise();
  }

  getOpenBalenaDeviceVars(uuid: string) {
    const feature = 'OPENBALENA_DEVICE_VARS';
    const url = this.getApiUrl(feature, { uuid });
    return this.http.get(url);
  }

  async setOpenBalenaDeviceEnvVar(uuid: string, key: string, value: string) {
    const feature = 'OPENBALENA_DEVICE_ENV_VAR';
    const url = this.getApiUrl(feature, { uuid, key });
    return await this.http
      .put(url, {
        value,
      })
      .toPromise();
  }

  async removeOpenBalenaDeviceEnvVar(uuid: string, key: string) {
    const feature = 'OPENBALENA_DEVICE_ENV_VAR';
    const url = this.getApiUrl(feature, { uuid, key });
    return await this.http.delete(url).toPromise();
  }

  getOpenBalenaDeviceTags(uuid: string) {
    const feature = 'OPENBALENA_DEVICE_TAGS';
    const url = this.getApiUrl(feature, { uuid });
    return this.http.get(url);
  }

  async putOpenBalenaDeviceLocalMode(uuid: string, enable: boolean = false) {
    const feature = 'OPENBALENA_DEVICE_LOCAL_MODE';
    const url = this.getApiUrl(feature, { uuid });
    return await this.http
      .put(url, {
        enable,
      })
      .toPromise();
  }

  async putOpenBalenaDeviceManageService(
    uuid: string,
    serviceName: string,
    service: CurrentService,
    action: OpenBalenaDeviceServiceAction,
  ) {
    const feature = 'OPENBALENA_DEVICE_MANAGE_SERVICE';
    const url = this.getApiUrl(feature, { uuid, serviceName });
    return await this.http
      .put(url, {
        service,
        action,
      })
      .toPromise();
  }

  async putOpenBalenaDeviceAction(uuid: string, action: OpenBalenaDeviceAction, force: boolean) {
    const feature = 'OPENBALENA_DEVICE_ACTION';
    const url = this.getApiUrl(feature, { uuid });
    return await this.http
      .put(url, {
        action,
        force,
      })
      .toPromise();
  }

  sendTestMapToTerminal(terminal) {
    const feature = 'SEND_TEST_MAP_TO_TERMINAL';
    const url = this.getApiUrl(feature);

    return this.http.post(url, terminal);
  }

  sendTestMapToTablet(terminalId, tabletId = null) {
    const feature = 'SEND_TEST_MAP_TO_TABLET';
    const url = this.getApiUrl(feature);

    return this.http.post(url, { terminalId, tabletId });
  }

  updateTerminalsInDatabase(idSmlouvy, cisloSmlouvy) {
    const feature = 'UPDATE_TERMINALS_IN_DATABASE';
    const url = this.getApiUrl(feature, {}, { idSmlouvy, cisloSmlouvy });

    return this.http.get(url);
  }

  sendZipMapToTerminal(orderData, zipMap, terminalId, terminalNumber, tabletId = null) {
    const feature = 'SEND_ZIP_MAP_TO_TERMINAL';
    const url = this.getApiUrl(feature);

    return this.http.post(url, {
      terminalId,
      tabletId,
      terminalNumber,
      orderData,
      zipMap,
    });
  }

  updateFertilizerUsabilityGlobally(
    fertilizerId,
    fertilizerCustomId,
    orgKoefCurrentSeason,
    orgKoefNextSeason,
    idSmlouvy,
  ) {
    const feature = 'UPDATE_FERTILIZER_USABILITY_GLOBALLY';
    const url = this.getApiUrl(feature);

    return this.http.put(url, {
      fertilizerId,
      fertilizerCustomId,
      orgKoefCurrentSeason,
      orgKoefNextSeason,
      idSmlouvy,
    });
  }

  sendErrorToSupport = (message, subject) => {
    const feature = 'SEND_ERROR_TO_SUPPORT';
    const url = this.getApiUrl(feature);
    return this.http.post(url, { message, subject }).toPromise();
  };

  tileServerGetPoint = (lon, lat, tiffname): Observable<any> => {
    const feature = 'TILE_SERVER_GET_POINT';
    const url = this.getApiUrl(feature, {}, { lon, lat, tiffname });

    return this.http.get(url);
  };

  tileServerGetTileURL = (tiffname, resize): Observable<any> => {
    const feature = 'TILE_SERVER_GET_URL';
    const url = this.getApiUrl(feature, {}, { tiffname, resize });

    return this.http.get(url);
  };

  updateAverageYield = (idtFields, averageYield): Observable<any> => {
    const feature = 'UPDATE_AVERAGE_YIELD';
    const url = this.getApiUrl(feature);

    return this.http.post(url, { idtFields, averageYield });
  };

  setOrderNumber = (orderId): Promise<any> => {
    const feature = 'SET_ORDER_NUMBER';
    const url = this.getApiUrl(feature, { orderId });

    return this.http.post(url, {}).toPromise();
  };

  updateFieldsInOrder = (orderId): Promise<any> => {
    const feature = 'UPDATE_FIELDS_IN_ORDER';
    const url = this.getApiUrl(feature, { orderId });

    return this.http.post(url, {}).toPromise();
  };

  registerUniformFields = (countryCode, fidList): Promise<any> => {
    const feature = 'REGISTER_UNIFORM_FIELDS';
    const url = this.getApiUrl(feature);

    return this.http.post(url, { countryCode, fidList }).toPromise();
  };

  enablePlanetSubscription = (idDodatek, isEnabled, isUniform): Promise<any> => {
    const feature = 'ENABLE_PLANET_SUBSCRIPTION';
    const url = this.getApiUrl(feature, {}, { idDodatek, isEnabled, isUniform });

    return this.http.post(url, {}).toPromise();
  };

  getLpisFieldMap = (schema: string, fid: string, country: string) => {
    const feature = 'LPIS_FIELD_MAP';
    const url = this.getApiUrl(feature, {}, { schema, fid, country });

    return this.http.get(url);
  };

  getFieldSeasonOptionList = (idSmlouvy) => {
    const feature = 'FIELD_SEASON_OPTION_LIST';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url, {}).toPromise();
  };

  setHistoricalYieldLastUpdate = (idSmlouvy): Promise<string> => {
    const feature = 'HISTORICAL_YIELD_LAST_UPDATE';
    const url = this.getApiUrl(feature, {}, { idSmlouvy });

    return this.http.get(url, {}).toPromise() as Promise<string>;
  };

  updatePlanetFieldSubscription = (updateMap) => {
    const feature = 'UPDATE_PLANET_FIELD_SUBSCRIPTION';

    const url = this.getApiUrl(feature);

    return this.http.post(url, { updateMap });
  };

  /**
   * Endpoint responsible for downloading metadata and geometries of a specific customer from GSAA defined by unique
   * combination of API key and ppa_id. It's also saving them in DB and returning selected metadata of those fields.
   * It can be used for either new customer or existing one. When getting data for current customer which wants to
   * add uniform fields, we will only download GSAA data which are not in our DB (they are not under contract).
   * @returns
   */
  createFieldsLpisSk = (key, idUz, gsaaRokKampane, selectionOfUniformFields: boolean = false) => {
    const feature = 'CREATE_FIELDS_LPIS_SK';

    const url = this.getApiUrl(feature);

    return this.http.post(url, {
      key,
      idUz,
      gsaaRokKampane,
      selectionOfUniformFields,
    });
  };

  deleteFieldsLpisSk = (key, idUz) => {
    const feature = 'DELETE_FIELDS_LPIS_SK';

    const url = this.getApiUrl(feature);

    return this.http.post(url, { key, idUz });
  };

  bulkCalculateAoi = (idDodatekList) => {
    const feature = 'BULK_CALCULATE_AOI';
    const url = this.getApiUrl(feature, {}, { idDodatekList });

    return this.http.get(url, {});
  };

  generateOrderGrantReport = (grantReportDataSource) => {
    const feature = 'GENERATE_ORDER_GRANT_REPORT';

    const url = this.getApiUrl(feature);

    return this.http.post(
      url,
      { grantReportDataSource },
      { responseType: 'blob', observe: 'response' },
    );
  };

  findRypEvi = (fid: string) => {
    const feature = 'FIND_RYP_EVI';
    const url = this.getApiUrl(feature, {}, { fid });
    return this.http.get(url, {}).toPromise();
  };

  findRypClip = (fid: string) => {
    const feature = 'FIND_RYP_CLIP';
    const url = this.getApiUrl(feature, {}, { fid });
    return this.http.get(url, {}).toPromise();
  };

  rypMapsProcessedState = (fid: string) => {
    const feature = 'RYP_MAPS_PROCESSED_STATE';
    const url = this.getApiUrl(feature, {}, { fid });
    return this.http.get(url, {}).toPromise();
  };

  combineDemRypMaps = (fids: string[], priorityProcessing = false) => {
    const feature = 'COMBINE_DEM_RYP_MAPS';
    const url = this.getApiUrl(feature);
    return this.http.post(url, { fids, priorityProcessing }).toPromise();
  };

  findTables = (fids: string[], schema: string) => {
    const feature = 'FIND_TABLES';
    const url = this.getApiUrl(feature, {}, { schema });
    const body = {
      fids,
    };

    return this.http.post(url, body, { responseType: 'json' }).toPromise();
  };

  readRypClip = (fid: string, years: number[]) => {
    const feature = 'READ_RYP_CLIP';
    const url = this.getApiUrl(feature, {}, { fid });
    return this.http.post(url, { fid: fid, years: years }, { responseType: 'json' }).toPromise();
  };

  saveEditedMap = (fid: string, schema: string, year: number, geoJson: any) => {
    const feature = `SAVE_EDITED_MAP`;
    const url = this.getApiUrl(feature, {}, { fid });
    const body = {
      fid: fid,
      target_schema: schema, // "RYP_CLIP",
      targetSchema: schema, // "RYP_CLIP",
      year: Number(year),
      geojson: geoJson,
    };

    return this.http.post(url, body, { responseType: 'json' }).toPromise();
  };

  readAsPng = (tableName: string, responseType: string = 'json', mapPalette: string = null) => {
    const feature = 'READ_AS_PNG';
    const url = this.getApiUrl(feature, {}, { tableName, responseType, mapPalette });
    return this.http
      .get(url, {
        responseType: 'blob' as 'json',
      })
      .toPromise();
  };

  getAnnexesWithoutPlanetApiKey = () => {
    const feature = 'ANNEXES_WITHOUT_PLANET_API_KEY';
    const url = this.getApiUrl(feature);

    return this.http.get(url) as Observable<Annex[]>;
  };

  getPlanetApiKeyAnnexesIdList = (idPlanetApiKey) => {
    const feature = 'PLANET_API_KEY_ANNEXES_ID_LIST';
    const url = this.getApiUrl(feature, {}, { idPlanetApiKey });

    return this.http.get(url);
  };

  bulkGetPlanetApiKeyAoi = (planetApiKeyList) => {
    const feature = 'BULK_GET_PLANET_API_KEY_AOI';
    const url = this.getApiUrl(feature, {}, { planetApiKeyList });

    return this.http.get(url);
  };

  bulkGetPlanetApiKeyCumulativeQuoteArea = (planetApiKeyList) => {
    const feature = 'BULK_GET_PLANET_API_KEY_CUMULATIVE_QUOTE_AREA';
    const url = this.getApiUrl(feature, {}, { planetApiKeyList });

    return this.http.get(url);
  };

  initializeRypMapsAndGetOrders(
    fids: string[],
    epsilon = 0.05,
    winterCropStart: string,
    winterCropEnd: string,
    springCropStart: string | null,
    springCropEnd: string | null,
  ): Promise<RypMapOrder[]> {
    const feature = 'PLANET_GATEWAY_INIT_RYP_MAPS';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fids,
        epsilon,
        winterCropStart,
        winterCropEnd,
        springCropStart: springCropStart ? springCropStart : undefined,
        springCropEnd: springCropEnd ? springCropEnd : undefined,
      })
      .toPromise() as Promise<RypMapOrder[]>;
  }

  regenerateRypMapsFromPostgis(
    fid: string,
    schema = 'RYP_EVI',
    epsilon = 0.5,
    priorityProcessing = false,
    buffer: number = 15,
  ): Promise<RypMapOrder[]> {
    const feature = 'REGEN_RYP_MAPS_FROM_POSTGIS';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fid,
        schema,
        epsilon,
        priorityProcessing,
        buffer,
      })
      .toPromise() as Promise<RypMapOrder[]>;
  }

  copyRipClipTableFromPostgis(
    fid: string,
    sourceYear: number,
    targetYears: number[],
  ): Promise<RypMapOrder[]> {
    const feature = 'COPY_RIP_CLIP_TABLE_FROM_POSTGIS';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fid,
        sourceYear,
        targetYears,
      })
      .toPromise() as Promise<RypMapOrder[]>;
  }

  postgisDem(annexId: number): Promise<void> {
    const feature = 'POSTGIS_DEM';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        annexId,
      })
      .toPromise() as Promise<unknown> as Promise<void>;
  }

  rypEviDropTables(tableNames: string[]): Promise<any> {
    const feature = 'RYP_EVI_DROP_TABLE';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        tableNames,
      })
      .toPromise() as Promise<any>;
  }

  deleteRypClip(fid: string, years: string[]): Promise<{ success: boolean }> {
    const feature = 'DELETE_RIP_CLIP';
    const url = this.getApiUrl(feature);

    return this.http
      .post(url, {
        fid,
        years,
      })
      .toPromise() as Promise<any>;
  }
}
