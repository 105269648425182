import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TSmlouvy } from './TSmlouvy';
import { TTerminaly } from './TTerminaly';

@Index('t_smlouvy_terminaly_UN', ['idSmlouvy', 'idTerminaly'], { unique: true })
@Index('t_smlouvy_terminaly_FK_1', ['idTerminaly'], {})
@Entity('t_smlouvy_terminaly', { schema: 'VaristarIS' })
export class TSmlouvyTerminaly {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_smlouvy_terminaly' })
  idSmlouvyTerminaly: number;

  @Column('int', { name: 'id_smlouvy' })
  idSmlouvy: number;

  @Column('int', { name: 'id_terminaly' })
  idTerminaly: number;

  @Column('tinyint', { name: 'is_owner', nullable: true, width: 1 })
  isOwner: boolean | null;

  @ManyToOne('TSmlouvy', 'tSmlouvyTerminalies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idSmlouvy2: TSmlouvy;

  @ManyToOne('TTerminaly', 'tSmlouvyTerminalies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_terminaly', referencedColumnName: 'idTerminaly' }])
  idTerminaly2: TTerminaly;
}
