import { THnojivaCustom } from './entities/THnojivaCustom';

// tslint:disable-next-line: no-empty-interface
export interface FertilizerCustom extends THnojivaCustom {
  settings: { fertilizers: object };
}

export const FertilizerCustom = {
  selectId: (a: THnojivaCustom) => {
    //In this case this would be optional since primary key is id
    return String(a.idtHnojiva);
  },
  filterFn: (fertilizerCustomList) => {
    return fertilizerCustomList.filter((fertilizerCustom) => {
      return !+fertilizerCustom.deactivated;
    });
  },
};
