<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div [formGroup]="form" class="formContainer">
  <ng-container [ngSwitch]="control.controlType">
    <ng-container *ngSwitchCase="'input'">
      <mat-form-field
        fxFlex
        [style.width]="control.width"
        [floatLabel]="control.floatLabel"
        [matTooltip]="control.placeholder | translate"
        matTooltipPosition="above"
      >
        <input
          cy-input
          *ngIf="control.type === 'number'"
          spacedinput
          matInput
          [type]="control.type"
          [min]="control.min"
          [max]="control.max"
          [formControlName]="control.key"
          [id]="control.key"
          [step]="control.step"
          [required]="control.required"
          [disableControl]="control.disabled"
          [placeholder]="control.overflowLabel ? null : (control.placeholder | translate)"
          [autocomplete]="control.key"
          [cdkFocusInitial]="control.focus"
          tabindex="0"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (keyup)="onPressKey($event)"
          (click)="$event.stopPropagation(); onPressKey($event)"
          (keyup.enter)="onEnter($event)"
          (keyup.esc)="onEsc($event)"
          [style.text-align]="control.text_align"
        />
        <input
          *ngIf="control.type !== 'number'"
          (click)="$event.stopPropagation()"
          matInput
          [type]="control.type"
          [formControlName]="control.key"
          [id]="control.key"
          [required]="control.required"
          [disableControl]="control.disabled"
          [placeholder]="control.overflowLabel ? null : (control.placeholder | translate)"
          [autocomplete]="control.key"
          [cdkFocusInitial]="control.focus"
          tabindex="0"
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          (keyup)="onPressKey($event)"
          (keyup.enter)="onEnter($event)"
          (keyup.esc)="onEsc($event)"
          [style.text-align]="control.text_align"
        />
        <span matPrefix [class.disabledColor]="control.disabled">
          {{ control.prefix }}
        </span>
        <span matSuffix [class.disabledColor]="control.disabled">
          {{ control.suffix }}
        </span>
        <mat-icon
          cy-suffix-icon
          matSuffix
          *ngIf="control.suffixIcon"
          (click)="onAction('SUFFIX_ACTION', control)"
        >
          {{ control.suffixIcon }}
        </mat-icon>
        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <ng-container *ngIf="control.hintPopup">
        <ui-popup-info-hint [message]="control.hintPopup"></ui-popup-info-hint>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'input-buttons'">
      <div fxFlexLayout="column" fxFlex>
        <dynamic-form-control-input-buttons
          [control]="control"
          [formControlName]="control.key"
          [form]="form"
          (event)="onEvent($event)"
          (delete)="onDelete($event)"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </dynamic-form-control-input-buttons>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </div>
    </ng-container>

    <!-- <ng-container *ngSwitchCase="'input-button'">
      <div fxLayout="row">
        <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
          <input matInput [type]="control.type" [formControlName]="control.key" [id]="control.key" [required]="control.required" [disableControl]="control.disabled" (change)="onChange(form)" [placeholder]="control.placeholder | translate" [autocomplete]="control.key" cdkFocusInitial>
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{control.hint | translate}}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>BUTTON.MORE</a>
          </mat-hint>
          <mat-error *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
            <mat-error class="error-message" *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)">
              {{validatorMessage.value | translate}}
            </mat-error>
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button *ngFor="let button of control.buttons" mat-icon-button
[disableRipple]="true" [matTooltip]="button.tooltip | translate" matTooltipPosition="above" (click)="button.click(control)">
            <mat-icon>{{button.icon}}</mat-icon>
          </button>
        </div>
      </div>
    </ng-container> -->
    <ng-container *ngSwitchCase="'textarea'">
      <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
        <!-- <textarea matInput [type]="control.type" [formControlName]="control.key" [id]="control.key" [required]="control.required" [disableControl]="control.disabled" (change)="onChange(form)" [placeholder]="control.placeholder | translate" cdkTextareaAutosize #autosize="cdkTextareaAutosize" [cdkAutosizeMinRows]="control.minRows" [cdkAutosizeMaxRows]="control.maxRows"></textarea> -->
        <textarea
          matInput
          [type]="control.type"
          [formControlName]="control.key"
          [id]="control.key"
          [required]="control.required"
          [disableControl]="control.disabled"
          [placeholder]="control.placeholder | translate"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          [cdkAutosizeMinRows]="control.minRows"
          [cdkAutosizeMaxRows]="control.maxRows"
        ></textarea>
        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
        <mat-select
          [formControlName]="control.key"
          [id]="control.key"
          [required]="control.required"
          [disableControl]="control.disabled"
          (selectionChange)="onChange(form)"
          [placeholder]="control.placeholder | translate"
          tabindex="0"
        >
          <mat-option
            *ngFor="let item of control.items$ | async"
            [id]="item.name"
            [value]="item.value"
          >
            {{ item.name | translate }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'palette-select'">
      <mat-form-field fxFlex>
        <mat-select
          [formControlName]="control.key"
          [id]="control.key"
          [required]="control.required"
          [disableControl]="control.disabled"
          (selectionChange)="onChange(form)"
          [placeholder]="control.placeholder | translate"
        >
          <mat-option
            [cy-name]="item.name"
            *ngFor="let item of control.items$ | async"
            [value]="item.value"
          >
            {{ item.name | translate }}
            <div>
              <div
                *ngFor="let color of item.value"
                fxFlex
                [style.background]="'#' + color"
                style="height: 8px"
              ></div>
            </div>
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
        <div style="margin-top: 6px">
          <div
            *ngFor="let color of form.value[control.key]"
            fxFlex
            [style.background]="'#' + color"
            style="height: 6px"
          ></div>
        </div>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <div fxLayout="column">
        <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
          <input
            matInput
            #matInput
            [type]="control.type"
            [formControlName]="control.key"
            [id]="control.key"
            [required]="control.required"
            [disableControl]="control.disabled"
            [placeholder]="control.placeholder | translate"
            [matAutocomplete]="auto"
            tabindex="0"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            (change)="onChangeVoid($event)"
          />
          <button
            mat-button
            *ngIf="control.value$ | async"
            matSuffix
            mat-icon-button
            [disableRipple]="true"
            aria-label="Clear"
            (click)="$event.stopPropagation(); onClear($event, matInput); matInput.focus()"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="control.display"
            (optionSelected)="onChangeOptions($event)"
            autoActiveFirstOption
          >
            <mat-option *ngIf="control.isLoading$ | async" class="is-loading">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </mat-option>
            <ng-container>
              <mat-option *ngFor="let item of control.filteredItems$ | async" [value]="item">
                {{ item.name | translate }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
          <mat-error *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
            <mat-error
              class="error-message"
              *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
            >
              {{ validatorMessage.value | translate }}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'filtered-list'">
      <!-- FIXIT: zatim nefunguje -->
      <mat-form-field fxFlex fxLayout="column">
        <div fxFlex fxLayout="column" class="filtered-list">
          <div class="filtered-list-header" fxLayout="row">
            <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
              <input
                matInput
                [(ngModel)]="control.filterValue"
                (keyup)="control.applyFilter($event.target.value)"
                [placeholder]="control.placeholderfilter | translate"
                autocomplete="off"
              />
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center">
              <button
                mat-icon-button
                [disableRipple]="true"
                [matTooltip]="'BUTTON.CLEAR_FILTER' | translate"
                matTooltipPosition="above"
                *ngIf="control.filter?.length"
                (click)="control.clearFilter()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="configuration-list-table" fxFlex fxLayout="column">
            <table
              mat-table
              [dataSource]="control.dataSource"
              matSort
              matSortActive="created"
              matSortDisableClear
              matSortDirection="desc"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ control.placeholder | translate }}
                </th>
                <td mat-cell *matCellDef="let data; let i = index" (click)="onChange(form)">
                  {{ data.name }}
                </td>
              </ng-container>

              <tbody>
                <tr mat-header-row *matHeaderRowDef="control.displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: control.displayedColumns"></tr>
              </tbody>
            </table>
          </div>
        </div>

        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <div fxFlexLayout="column">
        <div>
          <mat-checkbox
            labelPosition="after"
            [formControlName]="control.key"
            color="accent"
            (change)="onChange(form)"
            [disableControl]="control.disabled"
          >
            {{ control.placeholder | translate }}
          </mat-checkbox>
        </div>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <mat-form-field fxFlex [style.width]="control.width" [floatLabel]="control.floatLabel">
        <input
          matInput
          [matDatepicker]="dp"
          [formControlName]="control.key"
          [id]="control.key"
          [required]="control.required"
          [disableControl]="control.disabled"
          [placeholder]="control.placeholder | translate"
          (dateChange)="onChange(form)"
          tabindex="0"
        />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
        <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
          {{ control.hint | translate }}
          <a *ngIf="control.link" [href]="control.link" target="_blank" translate> BUTTON.MORE </a>
        </mat-hint>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'address'">
      <div fxFlexLayout="column" fxFlex>
        <dynamic-form-control-address
          [formControlName]="control.key"
          [form]="form"
          (event)="onEvent($event)"
          (delete)="onDelete($event)"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </dynamic-form-control-address>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'contact'">
      <div fxFlexLayout="column" fxFlex>
        <dynamic-form-control-contact
          [formControlName]="control.key"
          [form]="form"
          (event)="onEvent($event)"
          (delete)="onDelete($event)"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </dynamic-form-control-contact>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'divider'">
      <div fxFlexLayout="column" fxFlex>
        <mat-card-title *ngIf="control.header" translate>
          {{ control.header }}
        </mat-card-title>
        <mat-card-subtitle *ngIf="control.description" translate>
          {{ control.description }}
        </mat-card-subtitle>
        <mat-divider *ngIf="control.divider" translate>
          {{ control.description }}
        </mat-divider>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'link'">
      <!-- <button [routerLink]="control.url" mat-button fxLayout="column"> -->
      <button
        mat-icon-button
        [disableRipple]="true"
        color="control.color"
        [matTooltip]="control.tooltip | translate"
        matTooltipPosition="above"
        [routerLink]="control.url"
      >
        <mat-icon>{{ control.icon }}</mat-icon>
      </button>
      <!-- <button [routerLink]="control.url" mat-icon-button
[disableRipple]="true">
        <mat-icon>{{control.icon}}</mat-icon> -->
      <!-- {{control.icon}} -->
      <!-- fxLayoutAlign="center" *ngFor="let route of routes" #routeButton [id]="'go-to-' + route.url"
        [disabled]="route.disabled"> -->
      <!-- <ng-container *ngIf="control.icon">
          <mat-icon class="route-icon" [id]="control.icon" [matTooltip]="control.tooltip | translate" matTooltipPosition="above">
            {{control.icon}}
          </mat-icon>x
        </ng-container> -->
      <!-- <ng-container *ngIf="control.faicon">
          <fa-icon [icon]="['fas', control.faicon]" size="lg" [matTooltip]="control.tooltip | translate" matTooltipPosition="above">
          </fa-icon>
        </ng-container> -->
      <!-- </button> -->

      <!-- <button mat-icon-button
[disableRipple]="true" [matTooltip]="control.tooltip | translate" matTooltipPosition="above" [routerLink]="control.url">
        <mat-icon>control.icon</mat-icon>
      </button> -->
    </ng-container>

    <ng-container *ngSwitchCase="'keys'">
      <div fxFlexLayout="column" fxFlex>
        <dynamic-form-control-keys
          [formControlName]="control.key"
          [form]="form"
          (event)="onEvent($event)"
          (delete)="onDelete($event)"
          [enable]="control.enable"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </dynamic-form-control-keys>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'value'">
      <div fxFlexLayout="column" fxFlex>
        <dynamic-form-control-value
          [formControlName]="control.key"
          [form]="form"
          (event)="onEvent($event)"
          (delete)="onDelete($event)"
          [enable]="control.enable"
        >
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </dynamic-form-control-value>
        <div style="font-size: 75%">
          <mat-hint *ngIf="control.hint && !form.controls[control.key].errors" align="start">
            {{ control.hint | translate }}
            <a *ngIf="control.link" [href]="control.link" target="_blank" translate>
              BUTTON.MORE
            </a>
          </mat-hint>
        </div>
        <mat-hint *ngFor="let validatorMessage of control.validatorMessages | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="form.controls[control.key]?.hasError(validatorMessage.key)"
          >
            {{ validatorMessage.value | translate }}
          </mat-error>
        </mat-hint>
      </div>
    </ng-container>
  </ng-container>
</div>
