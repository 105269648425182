import { Application, Device, DeviceVariable } from 'balena-sdk';

export enum OpenBalenaDeviceAction {
  Reboot = 'restart_alt',
}

// tslint:disable-next-line: no-empty-interface
export interface OpenBalenaDevice extends Device {
  // env?: DeviceVariable[], // pridaji se jen ciste envVars ne cele OpenBalenaVariable (hlavne kvuli TERMINAL_NUMBER)
}

export const OpenBalenaDevice = {
  selectId: (a: OpenBalenaDevice) => {
    return a.id;
  },
};
