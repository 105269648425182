<mat-form-field class="container">
  <mat-select
    [value]="selectedSeasonId$ | async"
    (valueChange)="change.emit($event)"
    [placeholder]="'TITLE.ORDERS.SEASON' | translate"
  >
    <mat-option *ngFor="let option of seasonOptionList$ | async" [value]="option.value">
      {{ option.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
