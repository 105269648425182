import { Column, Entity, Index } from 'typeorm';

@Index('IC', ['ic'], {})
@Index('ID_UZ', ['idLpis'], {})
@Index('JMENO', ['obchodniJmeno'], {})
@Index('MESTO', ['obec'], {})
@Entity('t_LPIS_firmy', { schema: 'VaristarIS' })
export class TLpisFirmy {
  @Column('int', {
    primary: true,
    name: 'ID_LPIS',
    comment: 'ID tabulky, nikoliv ID v databázi LPIS.',
  })
  idLpis: number;

  @Column('varchar', { name: 'Obchodni_jmeno', nullable: true, length: 255 })
  obchodniJmeno: string | null;

  @Column('varchar', { name: 'Titul_pred', nullable: true, length: 45 })
  titulPred: string | null;

  @Column('varchar', { name: 'Jmeno', nullable: true, length: 255 })
  jmeno: string | null;

  @Column('varchar', { name: 'Prijmeni', nullable: true, length: 255 })
  prijmeni: string | null;

  @Column('varchar', { name: 'Titul_za', nullable: true, length: 255 })
  titulZa: string | null;

  @Column('varchar', { name: 'Obec', nullable: true, length: 255 })
  obec: string | null;

  @Column('varchar', { name: 'Cast_obce', nullable: true, length: 255 })
  castObce: string | null;

  @Column('varchar', { name: 'Cislo_popisne', nullable: true, length: 45 })
  cisloPopisne: string | null;

  @Column('varchar', { name: 'Mestska_cast', nullable: true, length: 255 })
  mestskaCast: string | null;

  @Column('varchar', { name: 'Ulice', nullable: true, length: 255 })
  ulice: string | null;

  @Column('varchar', { name: 'Cislo_orientacni', nullable: true, length: 45 })
  cisloOrientacni: string | null;

  @Column('int', { name: 'psc', nullable: true })
  psc: number | null;

  @Column('int', { name: 'Ic', nullable: true })
  ic: number | null;

  @Column('int', {
    name: 'Ji',
    nullable: true,
    comment: 'Jednotný identifikátor Státního zemědělského intervenčního fondu. ',
  })
  ji: number | null;

  @Column('int', {
    name: 'Id_szr',
    nullable: true,
    comment: 'ID Správy základních registrů. ',
  })
  idSzr: number | null;
}
