import { TNewFeatureCategory } from '@varistar-apps/shared/data';

export const newFeatureCategoryTranslationList = ['cz', 'en', 'sk'];

// tslint:disable-next-line: no-empty-interface
export interface NewFeatureCategory extends TNewFeatureCategory {}

export const NewFeatureCategory = {
  selectId: (x: TNewFeatureCategory) => {
    return x.idNewFeatureCategory;
  },
};
