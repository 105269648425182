import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[select-autofocus]',
})
export class SelectAutofocusDirective implements OnInit {
  @Input() focusDelay: number = 0;
  @Input('focus') isFocus = true;

  constructor(
    private matInput: MatInput,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    if (this.isFocus) {
      setTimeout(() => {
        this.matInput.focus();
      }, this.focusDelay);
    }
  }
}
