<mat-form-field class="container" [style.width]="width || '300px'">
  <mat-label *ngIf="label">{{ label | translate }}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let value of selectedValueList$ | async"
      (removed)="removeValue(value)"
      matChipRemove
      class="chip"
    >
      {{ optionMap[value] | translate }}
      <button class="chip_removeButton" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #select
      [disabled]="isDisabled"
      (input)="handleFilterChange($event)"
      (keydown.enter)="handleOptionSelect()"
      (keydown.space)="handleOptionSelect()"
      [value]="filterValue$ | async"
      [placeholder]="placeholder | translate"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
    />
  </mat-chip-list>
  <mat-autocomplete
    (optionActivated)="activeOption = $event?.option?.value"
    class="autocomplete"
    autoActiveFirstOption
    #auto="matAutocomplete"
  >
    <mat-option *ngFor="let option of optionListFiltered$ | async" [value]="option.value">
      <div (click)="$event.stopPropagation(); handleOptionSelect(option.value)">
        {{ option.name | translate }}
      </div>
    </mat-option>
  </mat-autocomplete>
  <button
    *ngIf="!isDisabled && !!(filterValue$ | async)"
    mat-button
    matSuffix
    mat-icon-button
    [disableRipple]="true"
    aria-label="Clear"
    (click)="handleClearFilter($event); select.focus()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div
    class="filterActionsContanier"
    matSuffix
    *ngIf="actions && !isDisabled && !(filterValue$ | async)"
  >
    <button
      *ngIf="(selectedValueList$ | async).length !== (optionList$ | async).length"
      mat-button
      mat-icon-button
      [disableRipple]="true"
      [matTooltip]="'PLACEHOLDER.SELECT_ALL' | translate"
      matTooltipPosition="above"
      color="primary"
      (click)="handleAddAll()"
    >
      <mat-icon>playlist_add</mat-icon>
    </button>
    <button
      *ngIf="(selectedValueList$ | async).length"
      mat-button
      [matTooltip]="'PLACEHOLDER.REMOVE_ALL' | translate"
      matTooltipPosition="above"
      mat-icon-button
      [disableRipple]="true"
      color="primary"
      (click)="$event.stopPropagation(); handleRemoveAll(); select.focus()"
    >
      <mat-icon>playlist_remove</mat-icon>
    </button>
  </div>
</mat-form-field>
