import { TTabletMachinesType } from './entities/TTabletMachinesType';

export enum TabletMachineTypes {
  SPREADER = 1,
  SPRAYER,
  SEEDER,
  OTHER,
  DRILL_FERT,
  HARVESTER,
}

export function getMachineTypeIconName(machineType: string | number) {
  const machineTypeIconMap = {
    1: 'rozmetadlo_granule',
    2: 'postrikovac_kapky',
    3: 'secka_semena',
    4: 'tractor',
    SPREADER: 'rozmetadlo_granule',
    SPRAYER: 'postrikovac_kapky',
    DRILL: 'secka_semena',
    OTHER: 'tractor',
  };

  return machineTypeIconMap[`${machineType}`] || 'tractor';
}

export function getMachineTypeIconDesc(machineType: string | number) {
  const machineTypeIconMap = {
    1: 'TABLET_MACHINE_TYPE.SPREADER',
    2: 'TABLET_MACHINE_TYPE.SPRAYER',
    3: 'TABLET_MACHINE_TYPE.DRILL',
    4: 'TABLET_MACHINE_TYPE.OTHER',
  };

  return machineTypeIconMap[`${machineType}`] || 'TABLET_MACHINE_TYPE.OTHER';
}

// tslint:disable-next-line: no-empty-interface
export interface TabletMachineType extends TTabletMachinesType {}

export const TabletMachineType = {
  selectId: (a: TTabletMachinesType) => {
    //In this case this would be optional since primary key is id
    return String(a.idtTabletMachinesType);
  },
  // getItems: () => {
  //   return Object.keys(TabletMachineTypes).map(key => ({ name: `TABLET_MACHINE_TYPE.${key.toUpperCase()}`, value: TabletMachineType[key] }));
  // },
  getItems: (data: TabletMachineType[] = []) => {
    return data.map((tabletMachineType) => ({
      name: `TABLET_MACHINE_TYPE.${tabletMachineType.description}`,
      value: tabletMachineType.idtTabletMachinesType,
    }));
  },
};
