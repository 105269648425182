import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ConfigurationModule } from '@varistar-apps/frontend/feature';

import { ApiVaristarService } from './api-varistar/api-varistar.service';
import { FrontendApiVaristarConfig } from './frontend-api-varistar-config';

@NgModule({
  imports: [
    CommonModule,
    ConfigurationModule, // TODO: Odstranit závislost na Firestore konfiguraci
  ],
  providers: [ApiVaristarService],
})
export class FrontendApiVaristarModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: FrontendApiVaristarModule,
  ) {
    // if (parentModule) {
    //   throw new Error(
    //     'ApiOcsModule is already loaded. Import it in the AppModule only'
    //   );
    // }
  }

  static forRoot(config: FrontendApiVaristarConfig): ModuleWithProviders {
    return {
      ngModule: FrontendApiVaristarModule,
      providers: [
        { provide: FrontendApiVaristarConfig, useValue: config },
        // ApiVaristarService
      ],
    };
  }
}
