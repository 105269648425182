import { TTerminaly } from './entities/TTerminaly';

// tslint:disable-next-line: no-empty-interface
export interface Terminal extends TTerminaly {}

export const Terminal = {
  selectId: (a: TTerminaly) => {
    //In this case this would be optional since primary key is id
    return String(a.idTerminaly);
  },
  filterFn: (terminalList) => {
    return terminalList.filter((terminal) => {
      return !+terminal.deactivated;
    });
  },
  // getItems: () => {
  //   return Object.keys(TabletMachineType).map(key => ({ name: `TABLET_MACHINE_TYPE.${key.toUpperCase()}`, value: TabletMachineType[key] }));
  // },
  // getItems: (data: OrderType[] = []) => {
  //   return data.map(orderType => ({ name: `ORDER_TYPE.${orderType.description.toUpperCase()}`, value: orderType.idtZakazkyTyp }));
  // },
};

export const SAVE_WITHOUT_SIM_FLAG = 'save-without-sim';
