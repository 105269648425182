<dynamic-form
  header="SUBTITLE.CONTACT"
  description="DESCRIPTION.CONTACT"
  [controls]="controls"
  [data]="data"
  (save)="onSave($event)"
  (delete)="onDelete($event)"
  (modify)="onModify($event)"
  resetWhenChanged="id"
  layout="simple"
  fxFlex
  [enable]="enable"
  [autosave]="true"
>
</dynamic-form>
