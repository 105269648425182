import { Column, Entity, Index, JoinColumn, ManyToOne, OneToMany } from 'typeorm';
import { TPorPouziti } from './TPorPouziti';
import { TPorPrilohy } from './TPorPrilohy';
import { TPor } from './TPor';
import { TPorUcinnaLatka } from './TPorUcinnaLatka';
import { TPorUdaje } from './TPorUdaje';

@Index('idt_por_idx', ['porId'], {})
@Index('idt_por_rozhodnuti', ['idtPorRozhodnuti'], {})
@Entity('t_por_rozhodnuti', { schema: 'VaristarIS' })
export class TPorRozhodnuti {
  @Column('bigint', { primary: true, name: 'idt_por_rozhodnuti' })
  idtPorRozhodnuti: string;

  @Column('bigint', { name: 'por_id', nullable: true })
  porId: string | null;

  @Column('varchar', {
    name: 'DRZ_POV',
    nullable: true,
    comment: 'Držitel povolení dovozu SP - je vyplněno v případě souběžného dovozu.',
    length: 256,
  })
  drzPov: string | null;

  @Column('varchar', {
    name: 'DRZ_ROZH',
    nullable: true,
    comment: 'Držitel rozhodnutí',
    length: 256,
  })
  drzRozh: string | null;

  @Column('datetime', {
    name: 'D_ZASD_SKUT',
    nullable: true,
    comment: 'Ukončení uvádění na trh',
  })
  dZasdSkut: Date | null;

  @Column('datetime', {
    name: 'D_ZAS_SKUT',
    nullable: true,
    comment: 'Používání povoleno max. do',
  })
  dZasSkut: Date | null;

  @Column('varchar', {
    name: 'B_FCE',
    nullable: true,
    comment: 'Biologická funkce',
    length: 2000,
  })
  bFce: string | null;

  @OneToMany('TPorPouziti', 'rozhodnuti')
  tPorPouzitis: TPorPouziti[];

  @OneToMany('TPorPrilohy', 'rozhodnuti')
  tPorPrilohies: TPorPrilohy[];

  @ManyToOne('TPor', 'tPorRozhodnutis', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'por_id', referencedColumnName: 'idtPor' }])
  por: TPor;

  @OneToMany('TPorUcinnaLatka', 'rozhodnuti')
  tPorUcinnaLatkas: TPorUcinnaLatka[];

  @OneToMany('TPorUdaje', 'rozhodnuti')
  tPorUdajes: TPorUdaje[];
}
