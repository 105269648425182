import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcLink<T> extends DynamicFormControlBase<T> {
  controlType = 'link';
  url: string;
  icon: string;
  tooltip: string;

  constructor(options: {} = {}) {
    super(options);
    this.url = options['url'];
    this.icon = options['icon'];
    this.tooltip = options['tooltip'];
  }
}
