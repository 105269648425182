import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { TPorRozhodnuti } from './TPorRozhodnuti';

@Index('idt_por_rozhodnuti_id', ['rozhodnutiId'], {})
@Entity('t_por_prilohy', { schema: 'VaristarIS' })
export class TPorPrilohy {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_prilohy' })
  idtPorPrilohy: string;

  @Column('bigint', { name: 'rozhodnuti_id', nullable: true })
  rozhodnutiId: string | null;

  @Column('varchar', {
    name: 'TYP',
    nullable: true,
    comment:
      'Typ dokumentu:013 - Etiketa; 997 - dodatečné informace; 010, 011, 012 - MRO na šarži; 014 - MRO na plodinu; 008 - MRO na celý přípravek',
    length: 45,
  })
  typ: string | null;

  @Column('varchar', {
    name: 'typ_popis',
    nullable: true,
    comment: 'Textový popis typu dokumentu.',
    length: 1000,
  })
  typPopis: string | null;

  @Column('varchar', {
    name: 'NAZEV',
    nullable: true,
    comment: 'Název přílohy',
    length: 255,
  })
  nazev: string | null;

  @Column('varchar', {
    name: 'URL',
    nullable: true,
    comment: 'URL kde lze soubor stáhnout.',
    length: 255,
  })
  url: string | null;

  @Column('varchar', {
    name: 'MIMETYP',
    nullable: true,
    comment: 'mimetyp souboru (např. PDF, RTF, DOC)',
    length: 55,
  })
  mimetyp: string | null;

  @Column('varchar', { name: 't_por_prilohycol', nullable: true, length: 45 })
  tPorPrilohycol: string | null;

  @ManyToOne('TPorRozhodnuti', 'tPorPrilohies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'rozhodnuti_id', referencedColumnName: 'idtPorRozhodnuti' }])
  rozhodnuti: TPorRozhodnuti;
}
