import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_kultura', { schema: 'VaristarIS' })
export class TKultura {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_druhpudy' })
  idDruhpudy: number;

  @Column('varchar', {
    name: 'kultura',
    nullable: true,
    comment: 'Textové vyjádření druhu půdní kultury. ',
    length: 100,
  })
  kultura: string | null;

  @Column('varchar', {
    name: 'kultura_sk',
    nullable: true,
    comment: 'Textové vyjádření druhu půdní kultury dle slovenského LPIS',
    length: 100,
  })
  kulturaSk: string | null;

  @Column('varchar', {
    name: 'kultura_kod_sk',
    nullable: true,
    comment: 'kód půdní kultury dle slovenského LPIS',
    length: 5,
  })
  kulturaKodSk: string | null;
}
