import { TTabletSettings } from './entities/TTabletSettings';

// tslint:disable-next-line: no-empty-interface
export interface TabletSettings extends TTabletSettings {}

export const TabletSettings = {
  selectId: (a: TTabletSettings) => {
    //In this case this would be optional since primary key is id
    return a.idTerminaly;
  },
};
