import { Column, Entity, Index, OneToMany, PrimaryGeneratedColumn } from 'typeorm';

import { THoldingOsoby } from './THoldingOsoby';
import { TZakaznik } from './TZakaznik';

// import { TZakaznik } from "./TZakaznik";

@Index('city', ['city'], {})
@Index('country', ['country'], {})
@Index('ic', ['idNumber'], {})
@Index('id', ['idtHolding'], {})
@Index('mail_city', ['city'], {})
@Index('mail_country', ['mailCountry'], {})
@Index('mail_zip', ['mailZip'], {})
@Index('name', ['name'], {})
@Index('vat', ['vatNumber'], {})
@Index('zip', ['zip'], {})
@Entity('t_holding', { schema: 'VaristarIS' })
export class THolding {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_holding' })
  idtHolding: number;

  @Column('varchar', { name: 'name', nullable: true, length: 254 })
  name: string | null;

  @Column('varchar', {
    name: 'id_number',
    nullable: true,
    comment: 'Obchodní označení subjektu (např. IČO). ',
    length: 25,
  })
  idNumber: string | null;

  @Column('varchar', {
    name: 'vat_number',
    nullable: true,
    comment: 'Daň z přidané hodnoty u uvedeného holdingu (v dané zemi).',
    length: 30,
  })
  vatNumber: string | null;

  @Column('varchar', {
    name: 'trade_register',
    nullable: true,
    comment:
      'Označení zápisu firmy v obchodní rejstříku např. vedený u Městského soudu v Praze vložka D3254',
    length: 254,
  })
  tradeRegister: string | null;

  @Column('varchar', { name: 'street', nullable: true, length: 254 })
  street: string | null;

  @Column('varchar', { name: 'city', nullable: true, length: 254 })
  city: string | null;

  @Column('varchar', {
    name: 'zip',
    nullable: true,
    comment: 'PSČ',
    length: 20,
  })
  zip: string | null;

  @Column('varchar', { name: 'country', nullable: true, length: 6 })
  country: string | null;

  @Column('varchar', {
    name: 'mail_street',
    nullable: true,
    comment: 'Poštovní adresa - ulice. ',
    length: 254,
  })
  mailStreet: string | null;

  @Column('varchar', {
    name: 'mail_city',
    nullable: true,
    comment: 'Poštovní adresa - město. ',
    length: 254,
  })
  mailCity: string | null;

  @Column('varchar', {
    name: 'mail_zip',
    nullable: true,
    comment: 'Poštovní adresa - PSČ.',
    length: 20,
  })
  mailZip: string | null;

  @Column('varchar', {
    name: 'mail_country',
    nullable: true,
    comment: 'Poštovní adresa - země.',
    length: 6,
  })
  mailCountry: string | null;

  // @OneToMany(() => THoldingOsoby, (tHoldingOsoby) => tHoldingOsoby.idtHolding2)
  @OneToMany('THoldingOsoby', 'idtHolding2')
  tHoldingOsobies: THoldingOsoby[];

  // @OneToMany(() => TZakaznik, (tZakaznik) => tZakaznik.idTHolding2)
  @OneToMany('TZakaznik', 'idTHolding2')
  tZakazniks: TZakaznik[];
}
