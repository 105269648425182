export interface Component {
  id: string;
  filter?: string;
}

export const Component = {
  selectId: (component: Component): string => {
    return component.id;
  },
};
