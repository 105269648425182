import { DOCUMENT } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isArray, isObject } from 'util';

import { DynamicFormBuilderService } from '../dynamic-form-builder/dynamic-form-builder.service';
import { DynamicFormControlBase } from '../dynamic-form-control-base';

// import { set, get } from 'lodash';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  //   providers: [DynamicFormBuilderService]
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() header = '';
  @Input() description = '';
  @Input() controls: DynamicFormControlBase<any>[] = [];
  @Input() data = {};
  // @Input() data$: Observable<any> = of(this.data);
  @Input() data$ = new BehaviorSubject<any>(this.data || {});
  @Input() resetWhenChanged = 'id';
  @Input() enable: any = {};
  @Input() layout = 'card';
  @Input() autosave = false;
  @Input() focusOnDataChange = false;

  @Output() save = new EventEmitter();
  @Output() delete = new EventEmitter();
  // @Output() add = new EventEmitter();
  @Output() modify = new EventEmitter(); // volano pokud se zmenily vstupni data
  @Output() change = new EventEmitter(); // volano pokud se zmenily data editaci formulare
  @Output() event = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() action = new EventEmitter();
  @Output() presskey = new EventEmitter();
  @Output() enter = new EventEmitter();
  @Output() esc = new EventEmitter();

  form: FormGroup;
  payLoad = '';
  controlRows = [];

  private default: any = {};
  private defaultEnable = {
    header: true,
    // add: false,
    // edit: false,
    save: true,
    cancel: true,
    delete: false,
  };
  private dataReady = false;

  private formValueChanges$: Observable<any>;
  private formValueChanges: Subscription;
  private dataSubscription: Subscription;
  private changeTimeout;
  private blurTimeout;
  private pendingSave; // pokud je autosave a doslo k modifikaci komponenty ale zatim jsem neopustil form fokusem jinam
  private defaultData; // poprve nactene data, nezmeni se pri jejich aktualizaci

  constructor(
    private dfb: DynamicFormBuilderService,
    @Inject(DOCUMENT) private document,
  ) {}

  ngOnInit() {
    this.buildForm();
    // this.form = this.dfb.toFormGroup(this.controls);
    // this.default = this.form.value;
    // this.enable = { ...this.defaultEnable, ...this.enable };

    // // podle order usporada komponenty do radku a sloupcu
    // this.controlRows = this.controls.reduce((rows, control) => {
    //   const row = Math.trunc(control.order);
    //   const col = Math.round((control.order - row) * 10);
    //   if (!rows[row])
    //     rows[row] = [];
    //   rows[row][col] = control;
    //   return rows;
    // }, []);

    this.formValueChanges$ = this.form.valueChanges.pipe(filter((changes) => this.autosave));

    // !!! NOTE: zustavaly subscription na data$, opraveno 2020-9-1, opravit vsude jinde
    this.dataSubscription = this.data$.pipe().subscribe((data) => {
      // pro autosave musim pred rizenymi zmenami dat vypnout notifikaci zmeny, reaguje se jen na zmeny uzivatele
      if (this.formValueChanges) this.formValueChanges.unsubscribe();

      if (
        _.get(this.data, this.resetWhenChanged) &&
        _.get(data, this.resetWhenChanged) !== _.get(this.data, this.resetWhenChanged)
      ) {
        this.form.reset(this.default);
        this.dataReady = true; // !!! NOTE: Doplneno aby se volalo onModify jen jednou
      }
      this.data = data; // zapamatuji si posledni hodnotu, pouzije se pri resetu zmen formulare
      if (!this.defaultData) {
        // defaultni data se zapamatuji pouze jednou pri prvotni inicializaci dat
        this.defaultData = _.cloneDeep(this.data);
      }

      //jestlize ma nektery input focus, zapamatuji

      this.setControlValues(data);

      // po rizene zmene dat znovu sleduji zmeny formulare od uzivatele
      this.formValueChanges = this.formValueChanges$.subscribe((data) => {
        if (this.dataReady) this.onModify(); // volano onMogify se provede az po pocatecni inicializaci dat, ktera musi probehnout nejdrive
        this.dataReady = false; // !!! NOTE: otoceno aby se volalo onModify jen jednou
      });
      if (this.focusOnDataChange) {
        this.focusToFirstControlWithFocusAttribute();
      }
    });

    // this.form.valueChanges.pipe(
    //   untilComponentDestroyed(this),
    //   filter(changes => this.autosave && this.dataReady)
    // ).subscribe(data => {
    //   this.onSave();
    // });
  }

  ngAfterViewInit(): void {
    let focusControl;

    // this.focusToFirstControlWithFocusAttribute();
    // this.controls?.forEach(control => {
    //   if (control.focus) {
    //     focusControl = this.document.getElementById(control.key);
    //   }
    // })
    // if (focusControl) {
    //   setTimeout(() => {
    //     focusControl.focus();
    //     focusControl.select();
    //   }, 1000);
    // }
  }

  ngOnDestroy() {
    if (this.pendingSave) {
      // nekdy destroy predbehne
      const data = this.buildDataObject();
      this.save.emit(data);
    }
    if (this.formValueChanges) this.formValueChanges.unsubscribe(); // NOTE: 2020-9-1
    if (this.dataSubscription) this.dataSubscription.unsubscribe(); // NOTE: 2020-9-1
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controls) {
      this.buildForm();
      if (changes.controls.firstChange) {
        this.focusToFirstControlWithFocusAttribute();
      }
    }
    if (changes.data) {
      this.data$.next(changes.data.currentValue);
      //   this.setControlValues(changes.data);
    }
  }

  private buildForm() {
    this.form = this.dfb.toFormGroup(this.controls);
    this.default = this.form.value;
    this.enable = { ...this.defaultEnable, ...this.enable };

    // podle order usporada komponenty do radku a sloupcu
    this.controlRows = (this.controls || []).reduce((rows, control) => {
      const row = Math.trunc(control.order);
      const col = Math.round((control.order - row) * 10);
      if (!rows[row]) rows[row] = [];
      rows[row][col] = control;
      return rows;
    }, []);
  }
  // onSubmit(e) {
  //   // e.target.submit();
  // }

  // onAdd() {
  //   // this.save.emit(this.form.value);
  //   this.add.emit(this.buildDataObject());
  //   // this.form.markAsPristine();
  //   // this.form.markAsUntouched();
  // }

  onSave(isInvalidOrDirty) {
    //form.invalid || !form.dirty

    // this.save.emit(this.form.value);
    this.save.emit(this.buildDataObject());
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  onDelete() {
    // this.save.emit(this.form.value);
    this.delete.emit(this.buildDataObject());
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  onCancel() {
    this.form.reset(this.default);
    this.setControlValues(this.data);
  }

  onModify() {
    this.modify.emit(this.buildDataObject());
  }

  onChange(x?) {
    this.change.emit(this.buildDataObject());
    if (this.autosave) {
      this.pendingSave = true;
      this.changeTimeout = setTimeout(() => {
        const data = this.buildDataObject();
        this.save.emit(data);
        this.pendingSave = false;
        // NOTE: !!! vyrazeno, nove se pouziva focus attribut pri definici dynamic-form control
        // const { activeElement } = this.document;
        // if (activeElement.hasAttribute('uiFormInput')) {
        //   activeElement.select();
        // }
      }, 0);
    }
  }

  onEvent(data) {
    this.event.emit(data);
  }

  onMouseOut(control?) {}

  onFocus(x?) {
    if (this.changeTimeout) clearTimeout(this.changeTimeout);
    if (this.blurTimeout) clearTimeout(this.blurTimeout);
    this.changeTimeout = null;
    this.blurTimeout = null;
    this.focus.emit();
  }
  onBlur(x?) {
    if (this.autosave) {
      if (this.pendingSave && !this.changeTimeout && !this.blurTimeout) {
        const data = this.buildDataObject();
        this.blurTimeout = setTimeout(() => {
          this.save.emit(data);
          this.pendingSave = false;
        }, 1000);
      }
    }
  }

  onAction(event) {
    if (event?.control?.action) {
      event.control.action({ ...event, form: this.form });
    }
    this.action.emit({ ...event, form: this.form });
  }

  onPressKey(event) {
    // console.log('onPressKey', event)
    // // NOTE: pri stisknu libovolne klavesy to ulozi vzdy ne jen pri autosave
    //   this.pendingSave = true;
    //   this.changeTimeout = setTimeout(() => {
    //     const data = this.buildDataObject();
    //     this.save.emit(data);
    //     this.pendingSave = false;
    //   }, 0)
    // // }
    if (event?.control?.presskey) {
      event.control.presskey({ ...event, form: this.form });
    }
    this.presskey.emit({
      ...event,
      form: this.form,
      data: this.buildDataObject(),
    });
  }

  onEnter(event) {
    // NOTE: pri ENTER to ulozi vzdy ne jen pri autosave
    // if (this.autosave) {
    this.pendingSave = true;
    this.changeTimeout = setTimeout(() => {
      const data = this.buildDataObject();
      this.save.emit(data);
      this.pendingSave = false;
    }, 0);
    // }
    if (event?.control?.enter) {
      event.control.enter({ ...event, form: this.form });
    }
    this.enter.emit({ ...event, form: this.form });
  }

  onEsc(event) {
    if (event?.control?.esc) {
      event.control.esc({ ...event, form: this.form });
    }
    this.esc.emit({ ...event, form: this.form });
  }

  /**
   * Nastaví hodnotu všech form control s názvem odpovídajícím cestě k hodnotě objektu/pole ale jen pokud byly beze změny a netknuté
   * @param value objekt, pole, hodnota
   * @param path pole s klici definujicimi cestu k prvku v objektu ci poli
   */
  private setControlValues(value: any, path: string[] = []) {
    if (isArray(value)) {
      if (!this.setControlValue(path.join('.'), value))
        // lze predat do controlu i cele pole
        Array.from(value).forEach((item, index) =>
          this.setControlValues(item, [...path, String(index)]),
        );
    } else {
      if (isObject(value)) {
        if (!this.setControlValue(path.join('.'), value))
          // lze predat do controlu i cely ci cast objektu
          Object.keys(value).forEach((key) => this.setControlValues(value[key], [...path, key]));
      } else {
        const controlName = path.join('.');
        this.setControlValue(controlName, value);
      }
    }
  }
  /**
   * Nastaví hodnotu form control, ale jen pouze pokud je form control beze změny a netknutý
   * @param controlName
   * @param value
   */
  private setControlValue(controlName: string, value: any): boolean {
    // if (this.form.contains(controlName)) { // nevratilo
    if (controlName && controlName.length && this.form.controls[controlName]) {
      const formControl = this.form.controls[controlName];
      if (formControl && formControl.pristine && formControl.untouched) {
        // TODO: use set from definition in controls
        const control = this.controls.find((c) => c.key === controlName);
        if (control && control.set) {
          formControl.setValue(control.set(value, this.form, control.key));
        } else {
          formControl.setValue(value);
        }
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * Vytvoří z vrácených hodnor formulare zase objekt se zanorenymi klici podleform control id
   */
  private buildDataObject() {
    const form = this.form;
    // let data = _.cloneDeep(this.data);
    const y = Object.keys(this.form.value).reduce((data, key) => {
      const control = this.controls.find((c) => c.key === key);
      const apiKey = _.isEmpty(control.apiKey) ? key : control.apiKey;
      if (control && control.get) {
        _.set(data, apiKey, control.get(this.form.value[key], this.form, key, _.get(data, apiKey))); // !!! nekdy potrebuji vedet co bylo v datech drive, treba u autocomplete pokud nechci ulozit
      } else {
        _.set(data, apiKey, this.form.value[key]);
      }
      return data;
    }, this.defaultData); // this.data je deep freeze
    y.isValidForm = !form.invalid; // !!! pridam jeste atribut indikujici validitu formulare

    if (form.invalid) {
      const cotrolStatuses = Object.keys(form.controls).map((key) => {
        return { control: key, status: form.controls[key].status };
      });
    }

    return y;
  }

  private focusToFirstControlWithFocusAttribute() {
    this.controls &&
      setTimeout(() => {
        this.controls?.find((control) => {
          const controlElement = this.document.getElementById(control?.key);
          if (controlElement) {
            control?.focus && controlElement?.focus && controlElement.focus();
            // control?.select && controlElement.select && controlElement.select();
          }
          return !!control?.focus;
        });
      }, 1000);
  }
}
