export interface LpisCompany {
  readonly id?: string; // NOTE: naplni se dotazem z LPIS assetu jako unikatni hodnota (možná se bude muset přidat PRISL_OPZL )
  readonly name?: string; // NOTE: naplni se dotazem z LPIS assetu jako unikatni hodnota (možná se bude muset přidat PRISL_OPZL )
  // NOTE: uložení ostatních atributů není efektivní, LPIS obsahuje pres 600k záznamů, vyhledávat podle CTVEREC nebo ZKOD_DPB se budě dělat rovnou v GEE assetu
}

export const LpisCompany = {
  selectId: (a: LpisCompany): string => {
    //In this case this would be optional since primary key is id
    return a.name;
  },

  sortByName: (a: LpisCompany, b: LpisCompany): number => {
    return a.name && a.name.localeCompare(b.name);
  },

  // fullName: (p: Person) => {
  //   return `${p.lastName} ${p.firstName}`;
  // },
  // getSalutations: () => {
  //   return Object.keys(PersonSalutation).map(key => ({ name: `SALUTATION.${key.toUpperCase()}`, value: PersonSalutation[key] }));
  // }
};
