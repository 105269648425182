import { Access } from './access.model';

// import * as _ from 'lodash';

export interface Organization {
  id?: string;
  name?: string;
  // internalCode?: string,
  // access? : Access[] // kdo z uzivatelu ma pristup k organizaci
}

export const Organization = {
  selectId: (a: Organization): string => {
    return a.id;
  },
  sortByName: (a: Organization, b: Organization): number => {
    return a.name && a.name.localeCompare(b.name);
    // },
    // internalCode: (name: string) => {
    //   return _.kebabCase(name);
  },
};
