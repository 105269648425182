import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { AuthFacade } from '@varistar-apps/frontend/auth';
// import { DataPersistence } from '@nrwl/nx';

import {
  ConfigurationAdded,
  ConfigurationFeature,
  ConfigurationModified,
  ConfigurationPayload,
  ConfigurationRemoved,
  SelectConfiguration,
  LoadConfiguration,
  QueryConfiguration,
  ConfigurationFirebaseError,
  ConfigurationSet,
} from './configuration.actions';
import { ConfigurationState } from './configuration.reducer';
import { getConfigurationQuery } from './configuration.selectors';
import { Firestore } from '@varistar-apps/shared/data';
import { from, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class ConfigurationFacade {
  constructor(
    private store: Store<ConfigurationState>,
    private afs: AngularFirestore,
    public authFacade: AuthFacade,
    // private dataPersistence: DataPersistence<ConfigurationState>,
    // private apiOcsConfig: ApiOcsService,
  ) {}

  load(feature: ConfigurationFeature, ids = {}) {
    this.store.dispatch(new LoadConfiguration(feature, ids));
  }

  query(feature: ConfigurationFeature, params = {}, query = null) {
    this.store.dispatch(new QueryConfiguration(feature, params, query));
  }

  query1(feature: ConfigurationFeature, params = {}, query = (ref) => ref) {
    const collectionPath = Firestore.getPath(feature, {
      account: { ...this.authFacade.account },
      ...params,
    });
    const collection = this.afs.collection(
      collectionPath, //, query
      // ref => {
      //   // return ref.where('tenantId', '==', '1')
      //   return action.query ? ref.where(action.query[0], action.query[1], action.query[2]) : ref;
      // }
    );
    return from(collection.stateChanges()).pipe(
      // takeUntil(this.afAuth.authState.pipe(filter(u => !u))), // NOTE: docasne vypnuto aby se overilole ze neprihlaseny uzivatel nema prava
      mergeMap((actions) => actions),
      map((documentChange) => {
        const id = documentChange.payload.doc.id;
        const data = {
          ...(documentChange.payload.doc.data() as object),
          id: documentChange.payload.doc.id,
        };
        // const data = {
        //   ...documentChange.payload.doc.data(),
        //   id,
        // }
        switch (documentChange.type as DocumentChangeType) {
          case 'added':
            return new ConfigurationAdded(feature, data);
          case 'modified':
            return new ConfigurationModified(feature, data);
          case 'removed':
            return new ConfigurationRemoved(feature, id);
        }
      }),
      catchError((error) => of(new ConfigurationFirebaseError({ ...error, collectionPath }))),
    );
  }

  select(feature: ConfigurationFeature, id: string) {
    this.store.dispatch(new SelectConfiguration(feature, id));
  }

  add(feature: ConfigurationFeature, data: ConfigurationPayload, ids: any = {}) {
    const collectionPath = Firestore.getPath(feature, {
      account: { ...this.authFacade.account },
      ...data,
      ...ids,
    });
    return this.afs
      .collection(collectionPath)
      .add(data)
      .then((newData) => {
        this.store.dispatch(new ConfigurationAdded(feature, { id: newData.id, ...data }));
        this.select(feature, newData.id);
      });
  }

  modify(
    feature: ConfigurationFeature,
    data: Partial<ConfigurationPayload>,
    id: string,
    ids: any = {},
  ) {
    const collectionPath = Firestore.getPath(feature, {
      account: { ...this.authFacade.account },
      ...data,
      ...ids,
    });
    // nechci ukladat id, ktere se pridalo pri nacteni dokumentu
    const dataWithoutId = { ...data };
    delete dataWithoutId.id;
    return this.afs
      .collection(collectionPath)
      .doc(id)
      .update(dataWithoutId)
      .then(() => {
        this.store.dispatch(new ConfigurationModified(feature, data as ConfigurationPayload));
      });
  }

  set(
    feature: ConfigurationFeature,
    data: Partial<ConfigurationPayload>,
    id: string,
    ids: any = {},
  ) {
    const collectionPath = Firestore.getPath(feature, {
      account: { ...this.authFacade.account },
      ...data,
      ...ids,
    });
    // nechci ukladat id, ktere se pridalo pri nacteni dokumentu
    const dataWithoutId = { ...data };
    delete dataWithoutId.id;
    return this.afs
      .collection(collectionPath)
      .doc(id)
      .set(dataWithoutId)
      .then(() => {
        this.store.dispatch(new ConfigurationModified(feature, data as ConfigurationPayload));
      });
  }

  remove(feature: ConfigurationFeature, id: string, ids: any = {}) {
    const collectionPath = Firestore.getPath(feature, {
      account: { ...this.authFacade.account },
      ...ids,
    });
    return this.afs
      .collection(collectionPath)
      .doc(id)
      .delete()
      .then(() => {
        this.store.dispatch(new ConfigurationRemoved(feature, id));
        this.select(feature, null);
      });
  }

  //
  // Pouze lokalni práce se state, bez synchronizace s Firestore
  //

  localAdd(feature: ConfigurationFeature, data: ConfigurationPayload, ids: any = {}) {
    this.store.dispatch(new ConfigurationAdded(feature, { id: data.id, ...data }));
    this.select(feature, data.id);
  }

  localModify(
    feature: ConfigurationFeature,
    data: Partial<ConfigurationPayload>,
    id: string,
    ids: any = {},
  ) {
    this.store.dispatch(new ConfigurationModified(feature, data as ConfigurationPayload));
  }

  localSet(
    feature: ConfigurationFeature,
    data: Partial<ConfigurationPayload>,
    id: string,
    ids: any = {},
  ) {
    this.store.dispatch(new ConfigurationSet(feature, data as ConfigurationPayload));
  }

  localRemove(feature: ConfigurationFeature, id: string, ids: any = {}) {
    this.store.dispatch(new ConfigurationRemoved(feature, id));
    this.select(feature, null);
  }

  getAll(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selectAll));
  }
  getTotal(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selectTotal));
  }
  getIds(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selectIds));
  }
  getEntities(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selectEntities));
  }

  getLoaded(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).loaded));
  }

  getSelectedId(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selectedId));
  }
  getSelected(feature: ConfigurationFeature) {
    return this.store.pipe(select(getConfigurationQuery(feature).selected));
  }
}
