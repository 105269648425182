import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'lodash';

@Pipe({ name: 'floor' })
export class PipeFloorPipe implements PipeTransform {
  /**
   *
   * @returns {number}
   * @param val
   */
  transform(val: number | string): number {
    if (val) {
      if (typeof val === 'string') {
        val = parseFloat(val);
      }

      return Math.floor(val);
    } else {
      return 0;
    }
  }
}
