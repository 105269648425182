import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'ui-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  private configSuccess: MatSnackBarConfig = {
    panelClass: 'success-panel',
  };

  private configError: MatSnackBarConfig = {
    panelClass: 'error-panel',
  };

  private configWarning: MatSnackBarConfig = {
    panelClass: 'warning-panel',
  };

  constructor(
    private snackBar: MatSnackBar,
    private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  /** Performs the action on the snack bar. */
  action(): void {
    this.snackBarRef.dismissWithAction();
  }

  /** If the action button should be shown. */
  get hasAction(): boolean {
    return !!this.data.action;
  }

  public snackbarSuccess(message, icon = null, action = '', duration = 2000, params?: any) {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.configSuccess,
      duration,
      data: { ...params, message, icon, action }, // params nejdrive aby neprepsali explicitne zadanou message
    });
  }

  public snackbarWarning(message, icon = null, action = '', duration = 2000, params?: any) {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.configWarning,
      duration,
      data: { ...params, message, icon, action }, // params nejdrive aby neprepsali explicitne zadanou message
    });
  }

  public snackbarError(message, icon = null, action = '', duration = 2000, params?: any) {
    return this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.configError,
      duration,
      data: { ...params, message, icon, action }, // params nejdrive aby neprepsali explicitne zadanou message
    });
  }

  public snackbarWithActionButtons(
    message: string,
    actionButtonList: { icon: string; tooltip: string; cb: Function }[],
    icon = null,
    action = '',
    duration = 2000,
    params?: any,
  ) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      ...this.configSuccess,
      duration,
      data: { ...params, message, icon, action, actionButtonList }, // params nejdrive aby neprepsali explicitne zadanou message
    });
  }
}
