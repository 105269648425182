import { PropsFilterFnFactory } from '@ngrx/data';
import { THolding } from './entities/THolding';

// tslint:disable-next-line: no-empty-interface
export interface Holding extends THolding {}

export const Holding = {
  selectId: (a: Holding) => {
    return a.idtHolding;
  },
  sortByName: (a: Holding, b: Holding): number => {
    return a.name && a.name.localeCompare(b.name);
  },
  // NOTE: moznost filtrovat pres vice atributu najednou nebo zvlast
  filterFn: (entities: Holding[], { idtHolding, country }: Partial<Holding>) => {
    return entities
      .filter((holding) => (idtHolding ? holding.idtHolding === +idtHolding : true))
      .filter((holding) =>
        country
          ? -1 < holding.country.toLocaleLowerCase().indexOf(country.toLocaleLowerCase())
          : true,
      );
  },
  // filterFn: PropsFilterFnFactory(['idtHolding', 'country'])
};
