import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TPorPouziti } from './TPorPouziti';

@Index('idt_por_pouziti_plodiny', ['pouzitiId'], {})
@Entity('t_por_pouziti_plodiny', { schema: 'VaristarIS' })
export class TPorPouzitiPlodiny {
  @Column('bigint', { name: 'pouziti_id' })
  pouzitiId: string;

  @Column('int', {
    name: 'KOD',
    nullable: true,
    comment: 'Kód plodiny nebo skupiny z EPH',
  })
  kod: number | null;

  @Column('varchar', {
    name: 'TYP',
    nullable: true,
    comment: 'Typ kódu: PL - plodina z EPH; SK - skupina plodin z EPH',
    length: 45,
  })
  typ: string | null;

  @Column('tinyint', {
    name: 'SHODA',
    nullable: true,
    comment:
      'Kód shody spárování plodiny EPH a PPP, true - shoda je 100%, false - spárováno na základě skupiny',
  })
  shoda: number | null;

  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_pouziti_plodiny' })
  idtPorPouzitiPlodiny: string;

  // @ManyToOne(
  //   () => TPorPouziti,
  //   (tPorPouziti) => tPorPouziti.tPorPouzitiPlodinies,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  @ManyToOne('TPorPouziti', 'tPorPouzitiPlodinies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'pouziti_id', referencedColumnName: 'idtPorPouziti' }])
  pouziti: TPorPouziti;
}
