import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcDivider<T> extends DynamicFormControlBase<T> {
  controlType = 'divider';
  header: string;
  description: string;
  divider: boolean;

  constructor(options: {} = {}) {
    super(options);
    this.header = options['header'];
    this.description = options['description'];
    this.divider = options['divider'];
  }
}
