import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FrontendUiFlexLayoutModule } from '@varistar-apps/frontend/ui-flex-layout';
import { FrontendUiMaterialModule } from '@varistar-apps/frontend/ui-material';
import { TranslateModule } from '@ngx-translate/core';

import { DynamicDisableControlDirective } from './dynamic-disable-control.directive';
import { DynamicFormControlComponent } from './dynamic-form-control/dynamic-form-control.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormControlAddressComponent } from './dynamic-form-control-address/dynamic-form-control-address.component';
import { DynamicFormControlContactComponent } from './dynamic-form-control-contact/dynamic-form-control-contact.component';
import { DynamicFormControlContactsComponent } from './dynamic-form-control-contacts/dynamic-form-control-contacts.component';
import { RouterModule } from '@angular/router';
import { DynamicFormTranslateService } from './dynamic-form-translate/dynamic-form-translate.service';
import { DynamicFormControlInputButtonsComponent } from './dynamic-form-control-input-buttons/dynamic-form-control-input-buttons.component';
import { DynamicFormControlKeysComponent } from './dynamic-form-control-keys/dynamic-form-control-keys.component';
import { DynamicFormControlValueComponent } from './dynamic-form-control-value/dynamic-form-control-value.component';
import { A11yModule } from '@angular/cdk/a11y';
import { PopupInfoHintComponent } from './popup-info-hint/popup-info-hint.component';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    ReactiveFormsModule,
    FrontendUiMaterialModule,
    FrontendUiFlexLayoutModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    // UiPipesModule,
  ],
  declarations: [
    DynamicFormComponent,
    DynamicFormControlComponent,
    DynamicDisableControlDirective,
    DynamicFormControlAddressComponent,
    DynamicFormControlContactComponent,
    DynamicFormControlContactsComponent,
    DynamicFormControlInputButtonsComponent,
    DynamicFormControlKeysComponent,
    DynamicFormControlValueComponent,
    PopupInfoHintComponent,
  ],
  exports: [
    A11yModule,
    DynamicFormComponent,
    DynamicFormControlComponent,
    DynamicDisableControlDirective,
    DynamicFormControlAddressComponent,
    DynamicFormControlContactComponent,
    DynamicFormControlContactsComponent,
    DynamicFormControlInputButtonsComponent,
    // DynamicFormTranslateService,
    DynamicFormControlKeysComponent,
    DynamicFormControlValueComponent,
    PopupInfoHintComponent,
  ],
  providers: [DynamicFormTranslateService],
})
export class FrontendUiFormModule {}
