import { Column, Entity } from 'typeorm';

// import { TZakazky } from "./TZakazky";

@Entity('t_zakazky_typ', { schema: 'VaristarIS' })
export class TZakazkyTyp {
  @Column('int', {
    primary: true,
    name: 'idt_zakazky_typ',
    comment: 'ID typu aplikace',
  })
  idtZakazkyTyp: number;

  @Column('varchar', {
    name: 'description',
    comment: 'Popis typu zakázky ve formě textového kódu',
    length: 45,
  })
  description: string;

  // @OneToMany(() => TZakazky, (tZakazky) => tZakazky.appType2)
  // tZakazkies: TZakazky[];
}
