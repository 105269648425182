import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { first, map, takeUntil } from 'rxjs/operators';
import { MatCalendar } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';

@Component({
  selector: 'ui-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
  ],
})
export class DatePickerComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  _date: moment.Moment;
  _inputValue = '';
  @Input() inputFormat = 'D. M. YYYY';
  @Input() selectedDate$: Observable<moment.Moment>;
  @Input() name: string;
  @Input() max$ = of(moment().add('100', 'year'));
  @Input() min$ = of(moment().subtract('100', 'year'));
  @Output() dateChange = new EventEmitter();

  @Input() useCustomHeader = false;
  customMonthHeader = DatePickerMonthSelectHeader;

  isDatePickerOpen = false;

  constructor() {}

  ngOnInit() {
    this.subscription.add(
      this.selectedDate$.subscribe((selectedDate) => {
        this._date = selectedDate;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleEdit = (event) => {
    if (this.isDatePickerOpen) {
      const { value } = event.target;
      this.dateChange.emit(moment(value));
    }
  };

  formatDateForInput = (date) => {
    return date.format('');
  };

  handleDirectInput = ($event) => {
    this._inputValue = $event.target.value;
  };

  handleInputBlur = (event) => {
    combineLatest([this.min$, this.max$])
      .pipe(first())
      .subscribe(([min, max]) => {
        if (!this.isDatePickerOpen && this._inputValue) {
          const date = this._inputValue.match(
            /(?<day>\d{1,2})[^\d]*(?<month>\d{1,2})[^\d]*(?<year>\d{4})/,
          );
          const { day, month, year } = date?.groups || {};

          if (day && month && year) {
            const parsedDate = moment(`${day}/${month}/${year}`, 'DD/MM/YYYY');

            if (
              parsedDate.isValid() &&
              parsedDate.isSameOrAfter(min) &&
              parsedDate.isSameOrBefore(max)
            ) {
              if (!this.useCustomHeader) {
                event.target.value = parsedDate.format(this.inputFormat);
              }

              this.dateChange.emit(parsedDate);
              return;
            }
          }
        }
        if (!this.useCustomHeader) {
          event.target.value = this._date.format(this.inputFormat);
        }
      });
  };
}

/** Custom header component for datepicker. */
@Component({
  selector: 'date-picker-month-select-header',
  styles: [
    `
      .monthly-datepicker-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .monthly-datepicker-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }

      .example-double-arrow .mat-icon {
        margin: -22%;
      }
    `,
  ],
  template: `
    <div class="monthly-datepicker-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="monthly-datepicker-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerMonthSelectHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
  }

  nextClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
  }
}
