import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TOpravneni } from './TOpravneni';
import { TUser } from './TUser';
import { TSmlouvy } from './TSmlouvy';
import { TZakaznikOsobyRole } from './TZakaznikOsobyRole';
import { TZakaznikOsobyStrediskaRole } from './TZakaznikOsobyStrediskaRole';
import { THoldingOsobyRole } from './THoldingOsobyRole';

@Index('t_role_FK', ['createdUser'], {})
@Index('t_role_FK_1', ['idtSmlouvy'], {})
@Index('t_role_UN', ['idRole'], { unique: true })
@Entity('t_role', { schema: 'VaristarIS' })
export class TRole {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_role' })
  idRole: number;

  @Column('int', { name: 'created_user', nullable: true })
  createdUser: number | null;

  @Column('varchar', {
    name: 'name',
    nullable: true,
    comment: 'Jmeno role',
    length: 100,
  })
  name: string | null;

  @Column('varchar', {
    name: 'description',
    nullable: true,
    comment: 'Popis role',
    length: 1000,
  })
  description: string | null;

  @Column('int', { name: 'idt_smlouvy', nullable: true })
  idtSmlouvy: number | null;

  @OneToMany('TOpravneni', 'idtRole2')
  @JoinColumn([{ name: 'id_opravneni', referencedColumnName: 'idOpravneni' }])
  tOpravnenis: TOpravneni[];

  @OneToMany('THoldingOsobyRole', 'idtRole2')
  tHoldingOsobyRoles: THoldingOsobyRole[];

  @ManyToOne('TUser', 'tRoles', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'created_user', referencedColumnName: 'idtUser' }])
  createdUser2: TUser;

  @ManyToOne('TSmlouvy', 'tRoles', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idtSmlouvy2: TSmlouvy;

  @OneToMany('TZakaznikOsobyRole', 'idtRole2')
  tZakaznikOsobyRoles: TZakaznikOsobyRole[];

  @OneToMany('TZakaznikOsobyStrediskaRole', 'idtRole2')
  tZakaznikOsobyStrediskaRoles: TZakaznikOsobyStrediskaRole[];
}
