import { TProductPrice } from './entities/TProductPrice';

export enum ProductPriceUnitEnum {
  L = 'L',
  T = 'T',
  M3 = 'M3',
}

export enum ProductTypeEnum {
  CS = 'CS', // custom seed
  CF = 'CF', // custom fertilizer
  CP = 'CP', // custom pesticid
  BS = 'BS', // bought seed
  BF = 'BF', // bought fertilizer
  BP = 'BP', // bought pesticid
}

export interface ProductPrice extends TProductPrice {
  koefprep?: number;
  productUid: string;
}

export const ProductPrice = {
  selectId: (a: TProductPrice): number => {
    return a.idProductPrice;
  },
};
