import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  SelectSeasonId = '[Settings] Select Season Id',
  SetSeasonOptionList = '[Settings] Season Option List',
}

export class SelectSeasonId implements Action {
  readonly type = SettingsActionTypes.SelectSeasonId;
  constructor(public payload?: number) {}
}

export class SetSeasonOptionList implements Action {
  readonly type = SettingsActionTypes.SetSeasonOptionList;
  constructor(public payload: { name: string, value: number}[]) {}
}

export type SettingsAction = SelectSeasonId | SetSeasonOptionList;

export const fromSettingsActions = {
  SelectSeasonId,
  SetSeasonOptionList,
};
