import { WeatherForecast } from './weather';
import { District, Cataster, AlertRisk } from './agrorisk';

export type GraphType = 'RAIN' | 'WIND' | 'HUMIDITY' | 'SOIL_HUMIDITY';
export type AgroriskData = {
  district: District;
  cataster: Cataster;
  alerts: Array<AlertRisk>;
  graph_urls: { [key in GraphType]: string };
};

export type GraphURLs = { [key in GraphType]: string };
export const GRAPH_TYPE_URL_PARTS: { [key in GraphType]: string } = {
  ['RAIN']: 'rr',
  ['WIND']: 'ws',
  ['HUMIDITY']: 'xh',
  ['SOIL_HUMIDITY']: '',
};

export type DashboardData = {
  weather: WeatherForecast;
  agrorisk: AgroriskData;
};

export * from './weather';
export * from './agrorisk';
