import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TSmlouvy } from './TSmlouvy';
import { TFields } from './TFields';

@Index('group_index', ['bonGroup'], {})
@Index('id_dodatek_idx1', ['idSmlouvy'], {})
@Entity('t_bonitacni_skupiny', { schema: 'VaristarIS' })
export class TBonitacniSkupiny {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_bonitacni_skupiny' })
  idBonitacniSkupiny: number;

  @Column('int', { name: 'id_smlouvy', nullable: true })
  idSmlouvy: number | null;

  @Column('int', {
    name: 'bon_group',
    nullable: true,
    comment: 'Označení bonitační skupiny. ',
  })
  bonGroup: number | null;

  @Column('decimal', {
    name: 'koeficient',
    nullable: true,
    comment: 'Hodnota koeficientu bonitačný skupiny. ',
    precision: 3,
    scale: 2,
  })
  koeficient: string | null;

  @Column('decimal', {
    name: 'coefficient_seeding',
    nullable: true,
    comment: 'Hodnota koeficientu bonitačný skupiny pro setí.',
    precision: 3,
    scale: 2,
    default: () => "'1.00'",
  })
  coefficientSeeding: string | null;

  @ManyToOne('TSmlouvy', 'tBonitacniSkupinies', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'id_smlouvy', referencedColumnName: 'idSmlouvy' }])
  idSmlouvy2: TSmlouvy;

  @OneToMany('TFields', 'bonGroup2')
  tFields: TFields[];
}
