import { PropsFilterFnFactory } from '@ngrx/data';
import { TZakaznik } from './entities/TZakaznik';

// tslint:disable-next-line: no-empty-interface
export interface Customer extends TZakaznik {
  cisloSmlouvy: number;
  idSmlouvy: number;
  country: string;
  notActive?: boolean;
}

export const Customer = {
  selectId: (a: Customer) => {
    return a.idZakaznik;
  },
  sortByName: (a: Customer, b: Customer): number => {
    return a.nazev && a.nazev.localeCompare(b.nazev);
  },
  // localityEnabledFilterFn: (
  //   localities: Locality[],
  //   pattern: string) => {
  //   return PropsFilterFnFactory(['enabled'])(localities, !!pattern);
  // },

  // NOTE: moznost filtrovat pres vice atributu najednou nebo zvlast
  filterFn: (entities: Customer[], { idZakaznik, idTHolding }: Partial<Customer>) => {
    return entities
      .filter((customer) => (idZakaznik ? customer.idZakaznik === idZakaznik : true))
      .filter((customer) => (idTHolding ? customer.idTHolding === idTHolding : true));
  },
  // filterFn: PropsFilterFnFactory(['idZakaznik', 'idtHolding'])
};
