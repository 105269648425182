<form class="container" [formGroup]="selectForm" [style.width]="(width || 200) + 'px'">
  <mat-form-field class="select" [style.width]="(width || 200) + 'px'">
    <mat-label>{{ label | translate }}</mat-label>
    <input
      #select
      [attr.cy-select]="'user'"
      class="select_input"
      type="text"
      matInput
      (input)="handleFilterChange($event)"
      (keydown.enter)="$event.preventDefault()"
      [matAutocomplete]="auto"
      formControlName="selectFormControl"
    />
    <mat-autocomplete
      (optionSelected)="handleOptionSelect($event)"
      class="autocomplete"
      autoActiveFirstOption
      #auto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of optionListFiltered$ | async"
        [value]="option.value"
        [style.background-color]="option.color || '#FFFFFF'"
        [attr.cy-select-option]="'user'"
      >
        {{ option.name | translate }}
        <span *ngIf="option.notActive">({{ 'PLACEHOLDER.NOT_ACTIVE' | translate }})</span>
      </mat-option>
    </mat-autocomplete>
    <button
      *ngIf="!select.disabled && !!(filterValue$ | async)"
      mat-button
      matSuffix
      mat-icon-button
      [disableRipple]="true"
      aria-label="Clear"
      (click)="handleClearFilter($event); select.focus()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="selectForm.get('selectFormControl').hasError('required')">{{
      'VALIDATION.REQUIRED' | translate
    }}</mat-error>
  </mat-form-field>
</form>
