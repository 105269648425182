import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { EntityCollectionService } from '@ngrx/data';
import {
  ConfirmDialogComponent,
  DeleteDialogComponent,
  ProgressBarService,
  SnackBarComponent,
  WizardStep,
} from '@varistar-apps/frontend/ui';
import {
  FeatureConfiguration,
  FeatureConfigurationService,
} from '@varistar-apps/frontend/ui-feature';
import { DfcAutocomplete, DfcInput } from '@varistar-apps/frontend/ui-form';
import { Field, FieldSelect, Plant } from '@varistar-apps/shared/data';
import { compare } from '@varistar-apps/shared/utilities';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-fields-edit',
  templateUrl: './fields-edit.component.html',
  styleUrls: ['./fields-edit.component.scss'],
})
export class FieldsEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isSplittingField = false;
  plants$ = new BehaviorSubject<Plant[]>([]);
  items$ = new BehaviorSubject<any>([]);

  fields$: Observable<FieldSelect[]>;
  field$: Observable<FieldSelect>;
  field: FieldSelect = null;
  data$: Observable<any>;
  fieldByZKOD$ = new BehaviorSubject(null);
  fieldsTotal = { summary: 0, assigned: 0, selected: 0, count: 0 };

  key: string = this.route.snapshot.queryParams.key;
  ownerId: string = this.route.snapshot.params.ownerId;
  editId: string = this.route.snapshot.params.editId;
  editId$ = new BehaviorSubject<string>(null);
  fieldId: string = this.route.snapshot.params.fieldId;

  filterValue = ''; // zobrazena hodnota
  filter = ''; // normalizovana hodnota
  selection = new SelectionModel<FieldSelect>(true, []);
  showSelection = false;

  displayedColumns = ['vyber', 'jmeno', 'plodina', 'vymeramVyber', 'action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {}) sort: MatSort;

  activeField = null; //
  lastEditId = null;

  feature = FeatureConfiguration.FieldEdit;
  resetWhenChanged = `${this.feature}.idtLpisSelect`;

  enable = {
    save: false,
    cancel: false,
    delete: false,
  };

  maxVymeramVyber = 0;

  controlsInit = [
    new DfcInput<string>({
      key: `${this.feature}.jmeno`,
      placeholder: 'PLACEHOLDER.FIELD.JMENO',
      order: 1.1,
      type: 'string',
      flex: 90,
    }),
    new DfcAutocomplete<string>({
      key: `${this.feature}.plodina`,
      placeholder: 'PLACEHOLDER.FIELD.PLODINA',
      order: 1.2,
      flex: 90,
      items$: this.items$,
      validator: Validators.compose([Validators.required]),
      asyncValidator: (control: AbstractControl) => {
        return this.items$.pipe(
          first(),
          map((itemList) => {
            const value = control.value?.value || control.value;

            if (itemList.some((item) => item.name === value)) {
              return null;
            }

            return { itemNotExist: true };
          }),
        );
      },
      validatorMessages: {
        required: 'VALIDATION.FIELD.REQUIRED',
        itemNotExist: 'VALIDATION.SELECT_FROM_LIST',
      },
      display: (value) => {
        return _.get(value, 'name', value);
      },
      get: (value, form, key, previousValue) => {
        return _.get(value, 'value', value === null ? value : previousValue); // pokud je null tak je to validni hodnota a nenahradi se predchozi hodnotou
      },
      autocompleteListOnEmpty: true,
    }),
    new DfcInput<number>({
      key: `${this.feature}.vymeramVyber`,
      placeholder: 'PLACEHOLDER.FIELD.VYMERAM_VYBER',
      order: 1.3,
      required: true,
      focus: true,
      type: 'number',
      validator: Validators.compose([
        Validators.min(0),
        (control: AbstractControl) => {
          return Validators.max(this.maxVymeramVyber)(control);
        },
      ]),
      validatorMessages: {
        min: 'VALIDATION.AREA.MIN',
        max: 'VALIDATION.AREA.MAX',
      },
      flex: 90,
      get: (value, form) => {
        if (+value > this.maxVymeramVyber) {
          return this.maxVymeramVyber;
        }

        if (+value < 0) {
          return 0;
        }

        return +value;
      },
    }),
  ];

  controlsNormal = [
    new DfcInput<string>({
      key: `${this.feature}.jmeno`,
      placeholder: 'PLACEHOLDER.FIELD.JMENO',
      order: 1.1,
      type: 'string',
      flex: 90,
    }),
    new DfcAutocomplete<string>({
      key: `${this.feature}.plodina`,
      placeholder: 'PLACEHOLDER.FIELD.PLODINA',
      order: 1.2,
      flex: 90,
      focus: true,
      items$: this.items$,
      validator: Validators.compose([Validators.required]),
      asyncValidator: (control: AbstractControl) => {
        return this.items$.pipe(
          first(),
          map((itemList) => {
            const value = control.value?.value || control.value;

            if (itemList.some((item) => item.name === value)) {
              return null;
            }

            return { itemNotExist: true };
          }),
        );
      },
      validatorMessages: {
        required: 'VALIDATION.FIELD.REQUIRED',
        itemNotExist: 'VALIDATION.SELECT_FROM_LIST',
      },
      display: (value) => {
        return _.get(value, 'name', value);
      },
      get: (value, form, key, previousValue) => {
        return _.get(value, 'value', value === null ? value : previousValue); // pokud je null tak je to validni hodnota a nenahradi se predchozi hodnotou
      },
      autocompleteListOnEmpty: true,
    }),
    new DfcInput<number>({
      key: `${this.feature}.vymeramVyber`,
      placeholder: 'PLACEHOLDER.FIELD.VYMERAM_VYBER',
      order: 1.3,
      required: true,
      type: 'number',
      validator: Validators.compose([
        Validators.min(0),
        (control: AbstractControl) => {
          return Validators.max(this.maxVymeramVyber)(control);
        },
      ]),
      validatorMessages: {
        min: 'VALIDATION.AREA.MIN',
        max: 'VALIDATION.AREA.MAX',
      },
      flex: 90,
      get: (value, form) => {
        if (+value > this.maxVymeramVyber) {
          return this.maxVymeramVyber;
        }

        if (+value < 0) {
          return 0;
        }

        return +value;
      },
    }),
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBarComponent: SnackBarComponent,
    private featureConfigurationService: FeatureConfigurationService<FieldSelect | Plant>,
    readonly progressBarService: ProgressBarService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe((query) => {
        this.editId = query.editId;
        this.editId$.next(query.editId);
        this.fieldId = query.fieldId;
      }),
    );

    const queryObject = RequestQueryBuilder.create({
      search: {
        $and: [{ idLpisSelect: this.key, idUz: this.ownerId, fid: this.fieldId }],
      },
    }).queryObject;

    this.fields$ = this.fieldId
      ? this.featureConfigurationService.query(this.feature, queryObject, true).pipe(
          map((fields) => {
            return fields.filter((field: any) => field.fid === this.fieldId);
          }),
        )
      : of(null);

    this.field$ = this.editId$.pipe(
      switchMap((editId) => {
        this.editId = editId;
        return editId ? this.featureConfigurationService.select(this.feature, editId) : of(null);
      }),
    );

    this.subscription.add(
      combineLatest([
        this.field$.pipe(filter((f) => !!f)),
        this.fieldByZKOD$.pipe(filter((f) => !!f)),
      ]).subscribe(([field, fieldByZkod]) => {
        this.field = field;
        this.activeField = field;
        this.maxVymeramVyber =
          Math.round((fieldByZkod.summary - fieldByZkod.assigned + +field.vymeramVyber) * 100) /
          100;
      }),
    );

    this.data$ = this.field$.pipe(
      // map(field => {
      //   // pokud je tam jen jedna polozka tak vynuluje velikost vyberu pole ale jen pokd neni jmeno a plodina
      //   return { ...field, vymeramVyber: (this.fieldsTotal?.count === 1 && field.vymeramVyber === field.vymeram && !field.jmeno && !field.plodina) ? 0 : field.vymeramVyber };
      // }),
      map((field) => ({ [this.feature]: field })), // pro ui-configuration-form se data zanoruji o jednu uroven
    );

    this.subscription.add(
      this.fields$
        .pipe(
          distinctUntilChanged((a, b) => {
            return a?.length === b?.length;
          }),
          filter((data) => !!data),
        )
        .subscribe((fieldsData: FieldSelect[]) => {
          const data = fieldsData.map((fieldData) => ({ ...fieldData }));

          // zapamatuji si posledni id v datech, tam prepnu pri smazani, pokud je ale jen jeden nebo je vybrany posledni tak se vybere predposledni
          if (data?.length) {
            const lastEditId = FieldSelect.selectId(data[data.length - 1]);
            this.lastEditId =
              data.length > 1 && lastEditId === this.editId
                ? FieldSelect.selectId(data[data.length - 2])
                : lastEditId;
          }

          this.dataSource.data = data;

          this.calculateStatistics();
        }),
    );

    // rostliny nactu jen jednou, nedelam dotaz na kazde hledani
    this.subscription.add(
      combineLatest([
        this.featureConfigurationService
          .getFeatureService(FeatureConfiguration.Plant)
          .filteredEntities$.pipe(
            first((c: any[]) => !!c?.length),
            map((plantList) => {
              return plantList
                .sort((a, b) => {
                  return compare(a.nazev, b.nazev, true);
                })
                .map((plant) => ({
                  name: plant.nazev,
                  value: plant.nazev,
                }));
            }),
          ),
        this.featureConfigurationService
          .query(
            this.feature,
            RequestQueryBuilder.create({
              search: {
                $and: [{ idLpisSelect: this.key, idUz: this.ownerId }],
              },
            }).queryObject,
          )
          .pipe(filter((c: any[]) => !!c?.length)),
      ]).subscribe(([plants, fields]) => {
        const usedPlantMap = {};
        const fieldsLength = fields.length;
        for (let index = 0; index < fieldsLength; index++) {
          const { plodina } = fields[index];
          if (plodina) {
            if (!usedPlantMap[plodina]) {
              usedPlantMap[plodina] = 1;
            } else {
              usedPlantMap[plodina] += 1;
            }
          }
        }

        const usedPlantList = Object.entries(usedPlantMap)
          .sort(([nazevA, a], [nazevB, b]) => {
            const difference = <number>b - <number>a;

            if (difference !== 0) {
              return difference;
            }

            return compare(nazevA, nazevB, true);
          })
          .map(([plant]) => plant);

        this.items$.next(
          usedPlantList
            .map((plant) => ({ name: plant, value: plant }))
            .concat(plants.filter((plant) => !usedPlantList.includes(plant.name))),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  calculateStatistics = () => {
    // spocitat agregace podle zkod aby se overilo kolik zbyva do celkove vymery

    const fieldByZKOD = _.reduce(
      this.dataSource.data,
      (byZKOD, row: any) => {
        if (!byZKOD[row.fid]) {
          byZKOD[row.fid] = {
            fields: [],
            // fields: {},
            summary: +row.vymeram, // celkova vymera se priradi pouze napoprve, predpokladam ze je vsude v poradku
            selected: 0, // vyymera zaskrtnutych
            assigned: 0,
          };
        }
        byZKOD[row.fid].fields.push(row);

        byZKOD[row.fid].assigned += +row.vymeramVyber; // NOTE: decimal je z TypeORM vracen jako string, protoze JS nema presny ekvivalent
        if (row.vyber) byZKOD[row.fid].selected += +row.vymeramVyber;
        return byZKOD;
      },
      {},
    );

    this.fieldByZKOD$.next(fieldByZKOD[this.fieldId]);

    this.fieldsTotal = _.reduce(
      fieldByZKOD,
      (total: any, row: any) => {
        total.summary += row.summary;
        total.assigned += row.assigned;
        total.selected += row.selected;
        total.count++;
        return total;
      },
      { summary: 0, assigned: 0, selected: 0, count: 0 },
    );
  };

  applyFilter(filterValue: string) {
    this.dataSource.filter = this.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.applyFilter((this.filterValue = ''));
  }

  onAdd() {
    this.router.navigate(['../'], {
      relativeTo: this.route,
    });
    this.selection.clear();
    this.showSelection = false;
  }

  openLpisDeleteDialog = (count) => {
    return new Promise((resolve) => {
      this.dialog
        .open(DeleteDialogComponent, {
          data: {
            question: 'QUESTION.REMOVE_SPLITTED_FIELD',
          },
        })
        .afterClosed()
        .subscribe(resolve);
    });
  };

  getTotalArea() {
    if (this.fieldByZKOD$.value) {
      return `Vybráno ${_.round(this.fieldByZKOD$.value.selected, 2)} z ${_.round(
        this.fieldByZKOD$.value.summary,
        2,
      )} ha`;
    }
  }

  isOwnerId() {
    return !!this.ownerId && this.ownerId.length;
  }

  updateFieldLocally = (fieldId, modificationMap) => {
    const currentField = this.dataSource.data.find((field: any) => {
      return FieldSelect.selectId(field) === fieldId;
    });

    Object.entries(modificationMap).forEach(([key, value]) => {
      currentField[key] = value;
    });
  };

  onSave = async (event: any) => {
    const editedField = event[this.feature];

    const duplicateField: any = editedField.plodina
      ? this.dataSource.data.find((field: any) => {
          return (
            field.plodina === editedField.plodina &&
            FieldSelect.selectId(field) !== FieldSelect.selectId(editedField)
          );
        })
      : false;

    if (duplicateField) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'QUESTION.MERGE_SPLITTED_FIELDS',
          },
        })
        .afterClosed()
        .subscribe((isConfirmed: boolean) => {
          if (isConfirmed) {
            this.isSplittingField = true;
            const newVymeramVyber = +duplicateField.vymeramVyber + +editedField.vymeramVyber;

            this.featureConfigurationService
              .modify(
                this.feature,
                {
                  ...duplicateField,
                  vymeramVyber: newVymeramVyber,
                },
                FieldSelect.selectId(duplicateField),
              )
              .then(() => {
                this.updateFieldLocally(FieldSelect.selectId(duplicateField), {
                  vymeramVyber: newVymeramVyber,
                });

                this.progressBarService.start('PROGRESS.SAVE');
                this.featureConfigurationService
                  .remove(this.feature, FieldSelect.selectId(editedField))
                  .finally(() => {
                    this.isSplittingField = false;

                    this.progressBarService.stop();
                    this.selectFieldById(FieldSelect.selectId(duplicateField));
                  });
              });
          } else {
            this.dataSource.data = JSON.parse(JSON.stringify(this.dataSource.data));
          }
        });
    } else {
      if (!this.field.plodina && editedField.plodina) {
        editedField.vyber = true;
      }

      // pokud uzivatel zada jmeno automaticky se mu vybere pole
      if (!this.field.jmeno && editedField.jmeno) {
        editedField.vyber = true;
      }

      const modifiedData = {
        ...editedField,
        vymeramVyber: editedField.vymeramVyber || 0,
      };
      const editId = FieldSelect.selectId(modifiedData);
      this.updateFieldLocally(editId, {
        vymeramVyber: editedField.vymeramVyber || 0,
        jmeno: editedField.jmeno,
        plodina: editedField.plodina,
      });

      this.featureConfigurationService.modify(this.feature, modifiedData, editId);

      this.calculateStatistics();
    }
  };

  getData(index: number) {
    return { field: this.dataSource.data[index] };
  }

  onSelectField = (event: any, field: FieldSelect) => {
    const { checked: isSelected } = event;

    const modifiedData = {
      ...field,
      vyber: isSelected,
    };

    this.featureConfigurationService.modify(this.feature, modifiedData, field.idtLpisSelect);

    if (isSelected) {
      this.selection.select(field);
    } else {
      this.selection.deselect(field);
    }

    this.updateFieldLocally(FieldSelect.selectId(field), { vyber: isSelected });
    this.calculateStatistics();
  };

  setActiveRow(field: FieldSelect) {
    if (field) {
      this.activeField = field;
      // this.editId$.next(Field.selectId(field));
    }
  }

  onDeleteField(editId) {
    this.openLpisDeleteDialog(this.selection.selected.length).then((isYes: boolean) => {
      if (isYes) {
        this.progressBarService.start('PROGRESS.SAVE');
        this.featureConfigurationService
          .remove(this.feature, editId)
          .then(() => {
            this.selection.deselect(editId);

            const nextSelectedFieldId =
              editId === this.lastEditId ? (<FieldSelect>this.dataSource.data
                    .slice()
                    .reverse()
                    .find((field: FieldSelect) => {
                      field.idLpisSelect !== editId;
                    })).idLpisSelect : this.lastEditId;

            this.selectFieldById(nextSelectedFieldId);
          })
          .finally(() => {
            this.progressBarService.stop();
          });
      }
    });
  }

  selectFieldById = (fieldId) => {
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: {
        editId: fieldId,
      },
      queryParamsHandling: 'merge',
    });
  };

  onAddField = () => {
    const unasigned = _.round(
      this.fieldByZKOD$.value.summary - this.fieldByZKOD$.value.assigned,
      2,
    );
    if (unasigned) {
      // jeste tu neni pradne pole vytvorim ho
      this.isSplittingField = true;
      const newField = {
        ...(<FieldSelect>this.dataSource.data[0]),
        vyber: 0,
        vymeramVyber: unasigned,
        plodina: undefined,
      };

      delete newField.idtLpisSelect;

      this.featureConfigurationService
        .add(this.feature, newField)
        .then((createdField: FieldSelect) => {
          this.onSelectField({ checked: true }, createdField);
          this.selectFieldById(FieldSelect.selectId(createdField));
          this.isSplittingField = false;
        });
    } else {
      this.snackBarComponent.snackbarError('VALIDATION.FIELD_FULLY_USED', 'warning', 'close', 2000);
    }
  };

  onPrev(link: string[]) {
    this.router.navigate(link, {
      relativeTo: this.route,
      queryParams: { key: this.key },
      queryParamsHandling: 'merge',
    });
  }

  onMatSortChange(sort: Sort) {
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      return compare(a[sort.active], b[sort.active], sort.direction === 'asc');
    });
  }
}
