export interface Lpis {
  // readonly companies?: string[]; // NOTE: naplni se dotazem z LPIS assetu jako unikatni hodnota (možná se bude muset rozšířit a přidat PRISL_OPZL)
  // // NOTE: uložení ostatních atributů není efektivní, LPIS obsahuje přes 600k záznamů, vyhledávat podle CTVEREC nebo ZKOD_DPB se budě dělat rovnou v GEE assetu
  id?: string;
  name?: string;
  asset?: string; // LPIS GEE asset name
  keys?: object; // konfigurace filtracnich parametru
}

export const Lpis = {
  selectId: (a: Lpis): string => {
    //In this case this would be optional since primary key is id
    return a.id;
  },

  // sortByName: (a: LpisCompany, b: LpisCompany): number => {
  //   return a.name && a.name.localeCompare(b.name);
  // },

  // fullName: (p: Person) => {
  //   return `${p.lastName} ${p.firstName}`;
  // },
  // getSalutations: () => {
  //   return Object.keys(PersonSalutation).map(key => ({ name: `SALUTATION.${key.toUpperCase()}`, value: PersonSalutation[key] }));
  // }
};
