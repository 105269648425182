<div class="container">
  <img [src]="selected_url" class="image" [mat-dialog-close]="true" />

  <button
    tabindex="-1"
    mat-icon-button
    [disableRipple]="true"
    class="button-close"
    [mat-dialog-close]="true"
  >
    <mat-icon class="icon-edit">close</mat-icon>
  </button>
  <button
    tabindex="-1"
    mat-icon-button
    [disableRipple]="true"
    class="button-prev"
    [disabled]="!movePrev()"
  >
    <mat-icon (click)="movePrev(true)">navigate_before</mat-icon>
  </button>
  <button
    tabindex="-1"
    mat-icon-button
    [disableRipple]="true"
    class="button-next"
    [disabled]="!moveNext()"
  >
    <mat-icon (click)="moveNext(true)">navigate_next</mat-icon>
  </button>
</div>
