import { Column, Entity, Index, JoinColumn, ManyToOne } from 'typeorm';
import { TCiselnikDruh } from './TCiselnikDruh';

@Index('idt_ciselnik_druh_odrudy', ['kodDruh'], {})
@Index('idt_ciselni_odruda_name', ['nazev'], {})
@Entity('t_ciselnik_odruda', { schema: 'VaristarIS' })
export class TCiselnikOdruda {
  @Column('varchar', {
    primary: true,
    name: 'Kod',
    comment: 'Kód odrůdy',
  })
  kod: number;

  @Column('varchar', {
    name: 'KodDruh',
    nullable: true,
    comment: 'Kód druhu',
    length: 255,
  })
  kodDruh: number | null;

  @Column('varchar', {
    name: 'Nazev',
    nullable: true,
    comment: 'Název odrůdy',
    length: 255,
  })
  nazev: string | null;

  // @ManyToOne(
  //   () => TCiselnikDruh,
  //   (tCiselnikDruh) => tCiselnikDruh.tCiselnikOdrudas,
  //   { onDelete: "NO ACTION", onUpdate: "NO ACTION" }
  // )
  @ManyToOne('TCiselnikDruh', 'tCiselnikOdrudas', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'KodDruh', referencedColumnName: 'kod' }])
  kodDruh2: TCiselnikDruh;
}
