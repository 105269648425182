import { Pipe, PipeTransform } from '@angular/core';

/**
 * If pesticide units value is (1) => ml/l then return compatible number format for correct value show
 * Supports backward compatibility for (0) => l/ha when decimal places is 1 then return no decimal, otherwise return one decimal number format
 */
enum PesticideUnitsEnum {
  L_HA,
  ML_L,
}

@Pipe({
  name: 'numberFormatByPesticidesUnits',
})
export class NumberFormatByPesticicesUnitsPipe implements PipeTransform {
  transform(pesticideUnits: number = 0, decimalPlaces: number = 1) {
    if (pesticideUnits === PesticideUnitsEnum.ML_L) {
      return `0.0-${decimalPlaces}`;
      // l/ha
    } else {
      return `0.0-${decimalPlaces - 1}`;
    }
  }
}
