import { Injectable } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

const ITEMS_PER_PAGE = 'PLACEHOLDER.PAGINATOR.ITEMS_PER_PAGE';
const NEXT_PAGE = 'PLACEHOLDER.PAGINATOR.NEXT_PAGE';
const PREV_PAGE = 'PLACEHOLDER.PAGINATOR.PREV_PAGE';
const FIRST_PAGE = 'PLACEHOLDER.PAGINATOR.FIRST_PAGE';
const LAST_PAGE = 'PLACEHOLDER.PAGINATOR.LAST_PAGE';

@Injectable()
export class TranslatedPaginator extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    // super();

    // this.nextPageLabel = ' My new label for next page';
    // this.previousPageLabel = ' My new label for previous page';
    // this.itemsPerPageLabel = 'Task per screen';

    super();

    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  public getAndInitTranslations(): void {
    this.translate
      .get([ITEMS_PER_PAGE, NEXT_PAGE, PREV_PAGE, FIRST_PAGE, LAST_PAGE])
      .subscribe((translation: any) => {
        this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
        this.nextPageLabel = translation[NEXT_PAGE];
        this.previousPageLabel = translation[PREV_PAGE];
        this.firstPageLabel = translation[FIRST_PAGE];
        this.lastPageLabel = translation[LAST_PAGE];

        this.changes.next();
      });
  }
}
