import { Column, Entity } from 'typeorm';

@Entity('t_vyfakturuj', { schema: 'VaristarIS' })
export class TVyfakturuj {
  @Column('int', { primary: true, name: 'id_vyfakturuj' })
  idVyfakturuj: number;

  @Column('int', {
    name: 'type',
    nullable: true,
    comment:
      'Určuje typ dokladu - 1 Faktura; 2 Zálohová faktura; 4 Proforma faktura; 8 Výzva k platbě;16 Daňový doklad;32 Opravný daňový doklad; 64 Příjmový doklad; 128 Opravný doklad;512 Objednávka',
  })
  type: number | null;

  @Column('int', {
    name: 'flags',
    nullable: true,
    comment:
      'Příznak označující k dokladu - 1 Dokument obsahuje DPH; 2 Uhrazeno; 4 Odesláno e-mailem zákazníkovi; 8 Doklad je stornován; 16 Odeslána e-mailem zákazníkovi upomínka; 32 Přeplatek; 64 Nedoplatek;256 Doklad byl stažen účetním',
  })
  flags: number | null;

  @Column('varchar', {
    name: 'number',
    nullable: true,
    comment: 'Číslo dokumentu.',
    length: 45,
  })
  number: string | null;

  @Column('date', {
    name: 'date_created',
    nullable: true,
    comment: 'Datum vytvoření dokumentu. ',
  })
  dateCreated: string | null;

  @Column('date', {
    name: 'date_due',
    nullable: true,
    comment: 'Datum splatnosti dokumentu.',
  })
  dateDue: string | null;

  @Column('date', {
    name: 'date_paid',
    nullable: true,
    comment: 'Uhrazeno dne.',
  })
  datePaid: string | null;

  @Column('varchar', {
    name: 'currency',
    nullable: true,
    comment: 'Měna',
    length: 5,
  })
  currency: string | null;

  @Column('decimal', {
    name: 'total',
    nullable: true,
    comment: 'Celková částka dokladu.',
    precision: 12,
    scale: 3,
  })
  total: string | null;

  @Column('decimal', {
    name: 'total_without_vat',
    nullable: true,
    comment: 'Celková částka dokladu bez daně.',
    precision: 12,
    scale: 3,
  })
  totalWithoutVat: string | null;

  @Column('varchar', {
    name: 'customer_name',
    nullable: true,
    comment: 'Jméno zákazníka.',
    length: 255,
  })
  customerName: string | null;

  @Column('varchar', {
    name: 'customer_IC',
    nullable: true,
    comment: 'Ičo zákazníka.',
    length: 20,
  })
  customerIc: string | null;

  @Column('varchar', {
    name: 'url_public_webpage',
    nullable: true,
    comment: 'URL s veřejnou stránkou dokumentu.',
    length: 255,
  })
  urlPublicWebpage: string | null;

  @Column('varchar', {
    name: 'order_number',
    nullable: true,
    comment: 'ID/číslo objednávky',
    length: 255,
  })
  orderNumber: string | null;
}

export type HoCuCo = {
  idHolding: number;
  idCustomer: number;
  idContract: number;
};
export type HoCuCoOr = HoCuCo & { idOrder: number };

export type InvoiceExtension = TVyfakturuj & {
  idSmlouvy?: number;
  shortNameCustomer?: string;
  ho_cu_co_or?: HoCuCoOr;
};
