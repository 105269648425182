import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';

import { TPorPouziti } from './TPorPouziti';

@Index('idt_por_pouziti_davkovani_idx', ['pouzitiId'], {})
@Entity('t_por_pouziti_davkovani', { schema: 'VaristarIS' })
export class TPorPouzitiDavkovani {
  @Column('bigint', { name: 'pouziti_id' })
  pouzitiId: string;

  @Column('decimal', {
    name: 'MAX_DAVKA',
    nullable: true,
    comment: 'Max. dávka',
    precision: 10,
    scale: 0,
  })
  maxDavka: string | null;

  @Column('decimal', {
    name: 'MIN_DAVKA',
    nullable: true,
    comment: 'Min. dávka',
    precision: 10,
    scale: 0,
  })
  minDavka: string | null;

  @Column('varchar', {
    name: 'MJ',
    nullable: true,
    comment: 'Měrná jednotka',
    length: 60,
  })
  mj: string | null;

  @Column('decimal', {
    name: 'MAX_DAVKA_VODA',
    nullable: true,
    comment: 'Max. dávka vody',
    precision: 10,
    scale: 0,
  })
  maxDavkaVoda: string | null;

  @Column('decimal', {
    name: 'MIN_DAVKA_VODA',
    nullable: true,
    comment: 'Min. dávka vody',
    precision: 10,
    scale: 0,
  })
  minDavkaVoda: string | null;

  @Column('varchar', {
    name: 'MJ_VODY',
    nullable: true,
    comment: 'Měrná jednotka vody',
    length: 10,
  })
  mjVody: string | null;

  @Column('varchar', {
    name: 'POZNAMKA',
    nullable: true,
    comment: 'Textový doplněk u dávkování',
    length: 2000,
  })
  poznamka: string | null;

  @Column('varchar', {
    name: 'DAVKA_TEXT',
    nullable: true,
    comment: 'Celá dávka textem, pomocné pole pro kontrolu dávky',
    length: 255,
  })
  davkaText: string | null;

  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_por_pouziti_davkovani' })
  idtPorPouzitiDavkovani: string;

  // @ManyToOne(
  //   () => TPorPouziti,
  //   (tPorPouziti) => tPorPouziti.tPorPouzitiDavkovanis,
  //   { onDelete: "CASCADE", onUpdate: "CASCADE" }
  // )
  @ManyToOne('TPorPouziti', 'tPorPouzitiDavkovanis', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'pouziti_id', referencedColumnName: 'idtPorPouziti' }])
  pouziti: TPorPouziti;
}
