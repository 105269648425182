import { TZakazkyFields } from './entities/TZakazkyFields';

// tslint:disable-next-line: no-empty-interface
export interface OrderFieldApplication extends TZakazkyFields {
  // TODO: naplnit podle vazby pole na data z oblasti v poli v mape dle shapefile
  // consumption: number, // Spotreba hnojiva (na jeden hektar)
  // avgRate: number, // Prumerna spotreba hnojiva ()
  volume: number; // Spotreba hnojiva (na jeden hektar)
  avgVolumePerHa: number; // Prumerna spotreba hnojiva ()
}

export const OrderFieldApplication = {
  selectId: (a: TZakazkyFields) => {
    //In this case this would be optional since primary key is id
    return String(a.idtZakazkyFields);
  },
};
