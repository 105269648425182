import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TDodatky } from './TDodatky';
import { TPlodinyEagri } from './TPlodinyEagri';
import { TStrediska } from './TStrediska';
import { TBonitacniSkupiny } from './TBonitacniSkupiny';
import { TFieldGroup } from './TFieldGroup';
import { TPlanetApiKey } from './TPlanetApiKey';
import { TFieldsObservations } from './TFieldsObservations';
import { TZakazkyFields } from './TZakazkyFields';

@Index('bon_group1', ['bonGroup'], {})
@Index('ctverec', ['ctverec'], {})
@Index('deactivated', ['deactivated'], {})
@Index('group_bonitace_idx', ['bonGroup'], {})
@Index('id', ['idtFields'], {})
@Index('id_dodatek', ['idtDodatek'], {})
@Index('id_uz', ['idUz'], {})
@Index('jmeno', ['jmeno'], {})
@Index('list', ['mapvlst5'], {})
@Index('plodina', ['plodina'], {})
@Index('t_fields_FK', ['idPlodiny'], {})
@Index('t_fields_FK_1', ['idtStrediska'], {})
@Index('t_fields_FK_3', ['groupId'], {})
@Index('t_fields_FK_4', ['idPlanetApiKey'], {})
@Index('t_fields_nkod_IDX', ['nkod'], {})
@Index('vymera', ['vymeram'], {})
@Index('zkod', ['zkod'], {})
@Entity('t_fields', { schema: 'VaristarIS' })
export class TFields {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'idt_fields' })
  idtFields: string;

  @Column('int', {
    name: 'idt_dodatek',
    nullable: true,
    comment: 'ID dodatku pro provázání honů s dodatkem smlouvy. ',
  })
  idtDodatek: number | null;

  @Column('varchar', {
    name: 'fid',
    nullable: true,
    comment: 'FID - označení honu v databázi LPIS.',
    length: 45,
  })
  fid: string | null;

  @Column('varchar', {
    name: 'zkod',
    nullable: true,
    comment: 'Zkrácený kód - označní z databáze LPIS.',
    length: 45,
  })
  zkod: string | null;

  @Column('varchar', {
    name: 'ctverec',
    nullable: true,
    comment: 'Čtverec - označní z databáze LPIS.',
    length: 45,
  })
  ctverec: string | null;

  @Column('varchar', { name: 'nkod', nullable: true, length: 45 })
  nkod: string | null;

  @Column('decimal', {
    name: 'vymeram',
    nullable: true,
    comment: 'Výměra honu.',
    precision: 6,
    scale: 2,
  })
  vymeram: string | null;

  @Column('varchar', {
    name: 'prisl_opzpl',
    nullable: true,
    comment: 'Územní příslušnost honu. ',
    length: 100,
  })
  prislOpzpl: string | null;

  @Column('varchar', {
    name: 'mapvlst5',
    nullable: true,
    comment: 'Mapový list?',
    length: 100,
  })
  mapvlst5: string | null;

  @Column('varchar', {
    name: 'plodina',
    nullable: true,
    comment: 'Pěstovaná plodina na honu v období platnosti daného dodatku. ',
    length: 100,
  })
  plodina: string | null;

  @Column('varchar', {
    name: 'jmeno',
    nullable: true,
    comment: 'Jmého honu dle uživatele. ',
    length: 100,
  })
  jmeno: string | null;

  @Column('int', {
    name: 'bon_group',
    nullable: true,
    comment: 'Bonitační skupina honu. ',
  })
  bonGroup: number | null;

  @Column('int', {
    name: 'id_uz',
    nullable: true,
    comment: 'ID uživatele v databázi LPIS. ',
  })
  idUz: number | null;

  @Column('tinyint', {
    name: 'deactivated',
    nullable: true,
    comment: 'Indikuje, informace o poli byla změněna (výměra nebo plodina)',
    width: 1,
    default: () => "'0'",
  })
  deactivated: boolean | null;

  @Column('bigint', {
    name: 'dc_id',
    nullable: true,
    comment: 'Id polygonu v systému dynacrop',
  })
  dcId: string | null;

  @Column('tinyint', {
    name: 'splitted',
    nullable: true,
    comment:
      'Ukazuje jestli jde o geometricky oddělení půdní subblok (FID takového půdního bloku má hodnotu YYYYYYYYXXX Y - původní FID, X pořadové číslo odděleného bloku na daném FID např. 12199611003)',
    width: 1,
    default: () => "'0'",
  })
  splitted: boolean | null;

  @Column('decimal', {
    name: 'vymeram_total',
    nullable: true,
    comment: 'Celková výměra DPB před dělením',
    precision: 6,
    scale: 2,
  })
  vymeramTotal: string | null;

  @Column('datetime', {
    name: 'created',
    nullable: true,
    comment: 'Datum a čas vytvoření záznamu',
    default: () => 'CURRENT_TIMESTAMP',
  })
  created: Date | null;

  @Column('int', {
    name: 'id_plodiny',
    nullable: true,
    comment: 'id plodiny - klíč do tabulky t_plodiny_EAGRI',
    default: () => "'0'",
  })
  idPlodiny: number | null;

  @Column('int', { name: 'idt_strediska', nullable: true })
  idtStrediska: number | null;

  @Column('json', {
    name: 'info',
    nullable: true,
    comment: 'dalsi informace k poli - vlastni prumerny historicky vynos',
  })
  info: object | null;

  @Column('int', {
    name: 'season_id',
    nullable: true,
    comment: 'Id sezony, pro kterou bylo pole vytvoreno. id 21 = sezona 2021/22',
  })
  seasonId: number | null;

  @Column('tinyint', {
    name: 'is_uniform',
    nullable: true,
    comment: 'Označuje jestli je pole uniformní (nezpracovávají se pro něj mapy RVP apod.)',
    width: 1,
    default: () => "'0'",
  })
  isUniform: boolean | null;

  @Column('int', { name: 'group_id', nullable: true })
  groupId: number | null;

  @Column('int', { name: 'id_planet_api_key', nullable: true })
  idPlanetApiKey: number | null;

  @Column('int', {
    name: 'pcuv',
    nullable: true,
    comment: 'ID zemědělské parcely v rámci ZKOD - pro slovenský LPIS',
  })
  pcuv: number | null;

  @Column('decimal', {
    name: 'ryp_map_sensitivity',
    nullable: true,
    comment:
      'Citlivost kroku při tvorbě map výnosového potenciálu 0,05 = 5% (mapy budou vždy po 5-ti %, ale vnitřní reclassifikace může být jiná - např. 0,04 zvýší citlivost (variabilitu) mapy. (při prní tvorbě se přenese z t_smlouvy.ryp_map_sensitivity)',
    precision: 3,
    scale: 2,
    default: () => "'0.05'",
  })
  rypMapSensitivity: string | null;

  @Column('int', {
    name: 'ryp_buffer',
    nullable: true,
    comment:
      'Výchozí hodnoty ořezového bufferu použitého při tvorbě map RVP a zónací prom potlačení krajového efektu.',
    default: () => "'10'",
  })
  rypBuffer: number | null;

  @Column('varchar', {
    name: 'anc_kategoria',
    nullable: true,
    comment: 'GSAA identifikator ANC_KATEGORIA',
    length: 10,
  })
  ancKategoria: string | null;

  @Column('varchar', {
    name: 'parcela',
    nullable: true,
    comment: 'GSAA identifikator PARCELA',
    length: 3,
  })
  parcela: string | null;

  @Column('tinyint', {
    name: 'last_in_season',
    nullable: false,
    comment: 'Identifikator, zda byl field poslednim aktivnim v sezone',
    default: () => "'0'",
  })
  lastInSeason: boolean;

  @ManyToOne('TDodatky', 'tFields', {
    onDelete: 'SET NULL',
    onUpdate: 'SET NULL',
  })
  @JoinColumn([{ name: 'idt_dodatek', referencedColumnName: 'idDodatek' }])
  idtDodatek2: TDodatky;

  @ManyToOne('TPlodinyEagri', 'tFields', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'id_plodiny', referencedColumnName: 'idtPlodinyEagri' }])
  idPlodiny2: TPlodinyEagri;

  @ManyToOne('TStrediska', 'tFields', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_strediska', referencedColumnName: 'idStrediska' }])
  idtStrediska2: TStrediska;

  @ManyToOne('TBonitacniSkupiny', 'tFields', {
    onDelete: 'SET NULL',
    onUpdate: 'SET NULL',
  })
  @JoinColumn([{ name: 'bon_group', referencedColumnName: 'idBonitacniSkupiny' }])
  bonGroup2: TBonitacniSkupiny;

  @ManyToOne('TFieldGroup', 'tFields', {
    onDelete: 'SET NULL',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'group_id', referencedColumnName: 'idFieldGroup' }])
  group: TFieldGroup;

  @ManyToOne('TPlanetApiKey', 'tFields', {
    onDelete: 'RESTRICT',
    onUpdate: 'RESTRICT',
  })
  @JoinColumn([{ name: 'id_planet_api_key', referencedColumnName: 'idPlanetApiKey' }])
  idPlanetApiKey2: TPlanetApiKey;

  @OneToMany('TFieldsObservations', 'idtFields2')
  tFieldsObservations: TFieldsObservations[];

  @OneToMany('TZakazkyFields', 'idtFields2')
  tZakazkyFields: TZakazkyFields[];
}
