import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentChangeType } from '@angular/fire/firestore';
import { AuthActionTypes, Authenticated } from '@varistar-apps/frontend/auth';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// import { DataPersistence } from '@nrwl/nx';
import { defer, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, filter, takeUntil } from 'rxjs/operators';

import {
  ConfigurationActionTypes,
  ConfigurationAdded,
  ConfigurationFirebaseError,
  ConfigurationModified,
  ConfigurationRemoved,
  LoadConfiguration,
  QueryConfiguration,
  ClearConfiguration,
  ConfigurationFeature,
} from './configuration.actions';
import { ConfigurationPartialState } from './configuration.reducer';
import { AuthFacade } from '@varistar-apps/frontend/auth';
import { Firestore } from '@varistar-apps/shared/data';

// import { Configuration } from '@varistar-apps/data';
@Injectable()
export class ConfigurationEffects {
  constructor(
    private actions$: Actions,
    private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public authFacade: AuthFacade,
    // private dataPersistence: DataPersistence<ConfigurationPartialState>
  ) {}

  @Effect({ dispatch: true })
  load$: Observable<Action> = this.actions$.pipe(
    ofType<LoadConfiguration>(ConfigurationActionTypes.Load),
    // switchMap(action => {
    mergeMap((action) => {
      // NOTE: Load akce se posila paralelne na vice zdroju, tak nelze cekat ze predchozi jiz skoncila proto melze pouzit switchap na zruseni predchoziho nedokonceneho requestu
      const feature = action.feature;
      const collectionPath = Firestore.getPath(feature, {
        account: { ...this.authFacade.account },
        ...action.payload,
      });

      const collection = this.afs.collection(
        collectionPath,
        // ref => {
        //   return ref.where('tenantId', '==', '1')
        // }
      );
      return from(collection.get()).pipe(
        mergeMap((value) => value.docChanges()),

        map((documentChange) => {
          const id = documentChange.doc.id;
          const data = {
            ...documentChange.doc.data(),
            id,
          };
          switch (documentChange.type as DocumentChangeType) {
            case 'added':
              return new ConfigurationAdded(feature, data);
            case 'modified':
              return new ConfigurationModified(feature, data);
            case 'removed':
              return new ConfigurationRemoved(feature, id);
          }
        }),
        catchError((error) => of(new ConfigurationFirebaseError({ ...error, collectionPath }))),
      );
    }),
  );

  @Effect({ dispatch: true })
  query$: Observable<Action> = this.actions$.pipe(
    ofType<QueryConfiguration>(ConfigurationActionTypes.Query),
    // switchMap(action => {
    mergeMap((action) => {
      // NOTE: Load akce se posila paralelne na vice zdroju, tak nelze cekat ze predchozi jiz skoncila proto melze pouzit switchap na zruseni predchoziho nedokonceneho requestu
      const feature = action.feature;
      const collectionPath = Firestore.getPath(feature, {
        account: { ...this.authFacade.account },
        ...action.params,
      });

      const collection = this.afs.collection(
        collectionPath,
        // ref => {
        //   // return ref.where('tenantId', '==', '1')
        //   return action.query ? ref.where(action.query[0], action.query[1], action.query[2]) : ref;
        // }
      );
      return from(collection.stateChanges()).pipe(
        // takeUntil(this.afAuth.authState.pipe(filter(u => !u))), // NOTE: docasne vypnuto aby se overilole ze neprihlaseny uzivatel nema prava
        mergeMap((actions) => actions),
        map((documentChange) => {
          const id = documentChange.payload.doc.id;
          const data = {
            ...(documentChange.payload.doc.data() as object),
            id: documentChange.payload.doc.id,
          };
          switch (documentChange.type as DocumentChangeType) {
            case 'added':
              return new ConfigurationAdded(feature, data);
            case 'modified':
              return new ConfigurationModified(feature, data);
            case 'removed':
              return new ConfigurationRemoved(feature, id);
          }
        }),
        catchError((error) => of(new ConfigurationFirebaseError({ ...error, collectionPath }))),
      );
    }),
  );

  // @Effect({ dispatch: true })
  // query$: Observable<Action> = this.actions$.pipe(
  //   ofType<QueryConfiguration>(ConfigurationActionTypes.Query),
  //   switchMap(action => {
  //     const dataUrl = 'ORGANIZATION/1/Configuration';
  //     const collection = this.afs.collection<Configuration>(
  //       dataUrl,
  //       ref => {
  //         return ref.where('tenantId', '==', '1')
  //       }
  //     );
  //     return from(collection.stateChanges())
  //       .pipe(
  //         takeUntil(this.afAuth.authState.pipe(filter(u => !u))), // NOTE: docasne vypnuto aby se overilole ze neprihlaseny uzivatel nema prava
  //         mergeMap(actions => actions),
  //         map(documentChange => {
  //           const data = {
  //             ...documentChange.payload.doc.data(),
  //             id: documentChange.payload.doc.id,
  //           }
  //           switch (documentChange.type as DocumentChangeType) {
  //             case 'added':
  //               return new ConfigurationAdded(data);
  //             case 'modified':
  //               return new ConfigurationModified(data);
  //             case 'removed':
  //               return new ConfigurationRemoved(data);
  //           }
  //         }),
  //         catchError(error => of(new ConfigurationFirebaseError({ ...error, dataUrl }))),
  //       )
  //   }),
  // );

  // @Effect({ dispatch: true })
  // update$: Observable<Action> = this.actions$.pipe(
  //   ofType<UpdateConfiguration>(ConfigurationActionTypes.Update),
  //   // map((action: ConfigurationAction.Update) => action),
  //   switchMap(data => {
  //     const dataUrl = `ORGANIZATION/1/Configuration${data.id}`;
  //     const ref = this.afs.doc<Configuration>(dataUrl);
  //     return from(ref.update(data.changes));
  //   }),
  //   // TODO: dodelat kontrolu uspesnosti
  //   map(() => new UpdateConfigurationSuccess())
  // );

  // Odchyceni Login na aktivaci Query
  @Effect({ dispatch: false })
  // login$: Observable<Action[]> = this.actions$.pipe(
  login$ = this.actions$.pipe(
    ofType<Authenticated>(AuthActionTypes.Authenticated),
    map((action) => {
      // return from([
      //   new ClearConfiguration(ConfigurationFeature.Account),
      //   new ClearConfiguration(ConfigurationFeature.Company),
      // ]);
      // const x = Object.keys(ConfigurationFeature).map(feature => (new ClearConfiguration(ConfigurationFeature[feature])));
      // return x;
    }),
  );
  // NOTE: Odchyceni Logout na odmazanani nactenych dat je jen v reduceru

  // Zachytne Init Action ?
  // Dle MIGRATION.md / @ngrx/effects / Init Action : https://github.com/ngrx/platform/blob/81afd0d71c0aca6d051a88954e2fa4edbf9a9cf2/MIGRATION.md
  @Effect()
  init$: Observable<Action> = defer(() => {
    // TODO: Doplnit nahrání dat pro všechny tabulky co jsou číselníky pro ostatní
    // return of(new QueryConfiguration());
    // return of(new LoadConfiguration(ConfigurationFeature.Person));
    // return of(new LoadConfiguration(ConfigurationFeature.TradingPlatform));
  });
}
