import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { TOpravneni } from './TOpravneni';
import { TZakaznikOsoby } from './TZakaznikOsoby';
import { TStrediska } from './TStrediska';
import { TZakaznikOsobyStrediskaRole } from './TZakaznikOsobyStrediskaRole';

@Index('t_zakaznik_osoby_UN', ['idZakaznikOsobyStrediska'], { unique: true })
@Index('id_user', ['idtStrediska'], {})
@Index('id_zakaznik', ['idtZakaznikOsoby'], {})
@Entity('t_zakaznik_osoby_strediska', { schema: 'VaristarIS' })
export class TZakaznikOsobyStrediska {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_zakaznik_osoby_strediska' })
  idZakaznikOsobyStrediska: number;

  @Column('int', {
    name: 'idt_zakaznik_osoby',
    nullable: true,
    comment: 'Párovací ID z tabulky zákazník - id farmy',
  })
  idtZakaznikOsoby: number | null;

  @Column('int', {
    name: 'idt_strediska',
    nullable: true,
    comment: 'Párovací ID uživatele z tabulky t_osoby',
  })
  idtStrediska: number | null;

  @OneToMany('TOpravneni', 'idtZakaznikOsobyStrediska2')
  tOpravnenis: TOpravneni[];

  @ManyToOne('TZakaznikOsoby', 'tZakaznikOsobyStrediskas', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_zakaznik_osoby', referencedColumnName: 'idtZakaznikUser' }])
  idtZakaznikOsoby2: TZakaznikOsoby;

  @ManyToOne('TStrediska', 'tZakaznikOsobyStrediskas', {
    onDelete: 'CASCADE',
    onUpdate: 'CASCADE',
  })
  @JoinColumn([{ name: 'idt_strediska', referencedColumnName: 'idStrediska' }])
  idtStrediska2: TStrediska;

  @OneToMany('TZakaznikOsobyStrediskaRole', 'idtZakaznikOsobyStrediska2')
  tZakaznikOsobyStrediskaRoles: TZakaznikOsobyStrediskaRole[];
}
