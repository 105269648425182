import { TTabletProtocolSpeed } from './entities/TTabletProtocolSpeed';

// tslint:disable-next-line: no-empty-interface
export interface TabletProtocolSpeed extends TTabletProtocolSpeed {}

export const TabletProtocolSpeed = {
  selectId: (a: TTabletProtocolSpeed) => {
    //In this case this would be optional since primary key is id
    return a.idTabletProtocolSpeed;
  },
};
