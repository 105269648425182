import { DynamicFormControlBase } from './dynamic-form-control-base';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, switchMap, startWith, tap, debounceTime, catchError, finalize } from 'rxjs/operators';
import { removeDiacritics } from '@varistar-apps/shared/utilities';

export class DfcAutocomplete<T> extends DynamicFormControlBase<T> {
  controlType = 'autocomplete';
  items: { name: string; value: any }[];
  items$: Observable<{ name: string; value: any }[]>;
  filteredItems$: Observable<{ name: string; value: any }[]>;
  // name$ = new BehaviorSubject('');
  value$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(false);
  isLoading = false;
  autocompleteListOnEmpty = false;
  // optionSelected = new EventEmitter();

  display = (item: { name: string; value: any }): string | undefined => {
    return item ? item.name : undefined;
  };

  change = (value, control, form) => {
    this.value$.next(value);
    return value;
  };

  // local filter or remote API request and response with mapping to name/value objects
  remote = (name) => {
    return this.filterName(name);
  };

  clear = () => {
    this.value$.next(null);
    return this.filterName('');
  };

  constructor(options: {} = {}) {
    super(options);

    this.clear = options['clear'] || this.clear;
    this.display = options['display'] || this.display;
    this.change = options['change'] || this.change;
    this.remote = options['remote'] || this.remote;
    this.get = options['get'] || this.get;
    this.items = options['items'] || [];
    this.items$ = options['items$'] || of(this.items);
    this.autocompleteListOnEmpty = !!options['autocompleteListOnEmpty'];

    this.filteredItems$ = this.value$.pipe(
      map((value) => (value && value.name) || value), // musit tu by, protoze na zacatku je to null, pak string, po ulozeni to je objekt
      debounceTime(500),
      tap((name) => {
        this.isLoading = !!name;
        this.isLoading$.next(!!name);
      }),
      switchMap((name) => this.remote(name)),
      catchError(() => []),
      tap((name) => {
        this.isLoading = false;
        this.isLoading$.next(false);
      }),
    );
  }

  private filterName(name: string): Observable<{ name: string; value: any }[]> {
    if (!name && this.autocompleteListOnEmpty) return this.items$; // pokud je autocompleteListOnEmpty tak se i pri prazdne hodnote zobrazi nabidka
    let filterValue = name.toLowerCase();
    // pridano odmazani diakritiky
    filterValue = removeDiacritics(filterValue);
    return this.items$.pipe(
      map((items) =>
        items.filter((item) => removeDiacritics(item.name.toLowerCase()).indexOf(filterValue) > -1),
      ),
    );
    //  return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
