import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_tankmix', { schema: 'VaristarIS' })
export class TTankmix {
  @PrimaryGeneratedColumn({ type: 'bigint', name: 'id_tankmix' })
  idTankmix: string;

  @Column('json', {
    name: 'pesticides',
    nullable: true,
    comment: '{ [idPesticid]: davka }',
  })
  pesticides: object | null;

  @Column('json', {
    name: 'fertilizers',
    nullable: true,
    comment: '{ [idFertilizer]: davka }',
  })
  fertilizers: object | null;
}
