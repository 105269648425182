import { TTabletMachinesUnits } from './entities/TTabletMachinesUnits';

// tslint:disable-next-line: no-empty-interface
export interface TabletMachineUnit extends TTabletMachinesUnits {}

export interface TabletMachineUnitOption {
  name: string;
  seederCompatible: boolean;
  value: number;
}

export const TabletMachineUnit = {
  selectId: (a: TTabletMachinesUnits) => {
    //In this case this would be optional since primary key is id
    return a.idtTabletMachinesUnits;
  },

  getSeederUnitItems: (data: TabletMachineUnitOption[] = []) => {
    return data
      .filter((tabletMachineUnit) => tabletMachineUnit.seederCompatible)
      .map((tabletMachineUnit: TabletMachineUnitOption) => ({
        name: `PLACEHOLDER.UNITS.${tabletMachineUnit.name}`,
        value: tabletMachineUnit.value,
      }));
  },
};
