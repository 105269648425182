import { isString } from 'lodash';
import { TFields } from './entities/TFields';

// tslint:disable-next-line: no-empty-interface
export interface Field extends TFields {
  hasMap?: boolean;
}

export const Field = {
  selectId: (a: TFields) => {
    //In this case this would be optional since primary key is id
    return String(a.idtFields);
  },

  /**
   * Převede FID s prefixem země jen na název atributu FIDu ve statistice
   * @param fid Field.fid, např. CZ12109873
   * @returns string, např. 12109873.0
   */
  getStatisticsFidNumber(fid: string): string {
    // let fid = field.fid;
    if (isString(fid)) {
      return `${+fid.substring(2)}`;
    } else {
      return `${+fid}`;
    }
  },

  // WARNING! more detailed filter is set in layout-portal, based on selected annex id and department id
  filterFn: (fieldList, pattern) => {
    if (!Object.values(pattern || {}).length) {
      return fieldList;
    }

    const filterList = Object.entries(pattern);
    return fieldList.filter((field) =>
      filterList.every(([key, value]) => {
        return field[key] === value;
      }),
    );
  },
};
