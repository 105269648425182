<form [formGroup]="signupForm">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <img alt="Field Hockey" height="40" src="/assets/logo.png" title="Field Hockey" />
    </div>
    <div>
      <span translate>TITLE.SIGNUP</span>
    </div>
    <button mat-icon-button [disableRipple]="true" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="form-container">
      <!-- <mat-form-field>
        <input type="text" matInput [placeholder]="'PLACEHOLDER.AUTH.USERNAME' | translate" formControlName="username"
          autocomplete="new-username" cdkFocusInitial>
        <mat-error *ngFor="let validation of validationMessages?.auth?.username | keyvalue">
          <mat-error class="error-message" *ngIf="signupForm.get('username').hasError(validation.key)">
            {{validation.value}}
          </mat-error>
        </mat-error>
      </mat-form-field> -->
      <mat-form-field>
        <input
          type="email"
          matInput
          [placeholder]="'PLACEHOLDER.AUTH.LOGIN_EMAIL' | translate"
          formControlName="email"
          required
          autocomplete="email"
        />
        <mat-error *ngFor="let validation of validationMessages?.auth?.email | keyvalue">
          <mat-error class="error-message" *ngIf="signupForm.get('email').hasError(validation.key)">
            {{ validation.value }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          matInput
          [placeholder]="'PLACEHOLDER.AUTH.LOGIN_PASSWORD' | translate"
          formControlName="password"
          required
          autocomplete="new-password"
        />
        <mat-error *ngFor="let validation of validationMessages?.auth?.password | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="signupForm.get('password').hasError(validation.key)"
          >
            {{ validation.value }}
          </mat-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          type="password"
          matInput
          [placeholder]="'PLACEHOLDER.AUTH.CONFIRM_PASSWORD' | translate"
          formControlName="confirmPassword"
          required
          autocomplete="confirm-password"
        />
        <mat-error *ngFor="let validation of validationMessages?.auth?.confirmPassword | keyvalue">
          <mat-error
            class="error-message"
            *ngIf="signupForm.get('confirmPassword').hasError(validation.key)"
          >
            {{ validation.value }}
          </mat-error>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" fxLayout="column">
    <button
      mat-raised-button
      mat-dialog-close
      color="accent"
      [disabled]="signupForm.invalid || !signupForm.dirty"
      (click)="onSignup()"
    >
      <span translate>BUTTON.AUTH.CREATE_ACCOUNT</span>
    </button>
    <button
      mat-button
      mat-dialog-close="AUTH_ALREADY_SIGNED"
      *ngIf="data.enable?.AUTH_ALREADY_SIGNED"
    >
      <span translate>BUTTON.AUTH.ALREADY_SIGNED</span>
    </button>
  </mat-dialog-actions>
</form>
