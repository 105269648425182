import { Component, OnInit, Inject, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { DynamicFormTranslateService } from '@varistar-apps/frontend/ui-form';
import { AuthFacade } from '@varistar-apps/frontend/auth';

@Component({
  selector: 'ui-auth-dialog-signup',
  templateUrl: './auth-dialog-signup.component.html',
  styleUrls: ['./auth-dialog-signup.component.scss'],
})
export class AuthDialogSignupComponent implements OnInit {
  minPasswordLength = 1;

  signupForm = this.fb.group(
    {
      // username: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
      confirmPassword: [''],
    },
    { validator: this.checkPasswords },
  );

  // NOTE: inspirace https://angular-templates.io/tutorials/about/angular-forms-and-validations
  // Ale upraveno z array na object a keyvalue pipe aby se daly prelozit
  // v prekladu assets/i18n/cs.json jsou vsechny klice v objektu prevedeny na velke pismena
  // obsah se nahradi prelozenym textem pokud exisuje, jako parametr se prilozi public atributy tridy, zde napr. maxContentLength
  validationMessages = {
    auth: {
      // username: {},
      email: {
        email: '',
        required: '',
      },
      password: {
        required: '',
        // 'minlength': ''
        // 'maxlength': `Heslo nemůže mít více jak ${this.maxContentLength} znaků`
      },
      confirmPassword: {
        notSame: '',
        // 'minlength': ''
        // 'maxlength': `Heslo nemůže mít více jak ${this.maxContentLength} znaků`
      },
    },
  };

  constructor(
    public dialogRef: MatDialogRef<AuthDialogSignupComponent>,
    public dialog: MatDialog,
    private translateFormService: DynamicFormTranslateService,
    private fb: FormBuilder,
    public authFacade: AuthFacade,
    library: FaIconLibrary,
    // public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    library.addIconPacks(fas, far);
  }

  ngOnInit() {
    // inicializace form
    this.signupForm.setValue({
      // username: this.data.username || '',
      email: this.data.email || '',
      password: this.data.password || '',
      confirmPassword: this.data.confirmPassword || '',
    });
    // this.translateFormService.translateValidationMessages(this.validationMessages);
  }

  onSignup() {
    this.authFacade.signUpByEmail({
      // username: this.signupForm.value.username,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  // custom validator
  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const password = group.controls.password.value;
    const confirmPassword = group.controls.confirmPassword.value;
    const errors = password === confirmPassword ? null : { notSame: true };
    group.controls.confirmPassword.setErrors(errors);
  }
}
