import { TZakazkyTerminal } from './entities/TZakazkyTerminal';

// tslint:disable-next-line: no-empty-interface
export interface OrderTerminal extends TZakazkyTerminal {}

export const OrderTerminal = {
  selectId: (a: TZakazkyTerminal) => {
    //In this case this would be optional since primary key is id
    return String(a.idtZakazkaTerminal);
  },
  // getItems: () => {
  //   return Object.keys(TabletMachineType).map(key => ({ name: `TABLET_MACHINE_TYPE.${key.toUpperCase()}`, value: TabletMachineType[key] }));
  // },
  // getItems: (data: OrderType[] = []) => {
  //   return data.map(orderType => ({ name: `ORDER_TYPE.${orderType.description.toUpperCase()}`, value: orderType.idtZakazkyTyp }));
  // },
};

export interface OrderTerminalDataSource {
  machineType: string;
  cisloTerminalu: string;
  nazevTerminalu: string;
  popisTerminalu: string;
  dateSent: Date;
  machineBrandType: string;
  machineDescription: string;
}
