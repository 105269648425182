import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeleteDialogComponent } from '@varistar-apps/frontend/ui';
import { Observable, Subscription } from 'rxjs';

import { RegistrationState } from '../..';
import { RegistrationFeature } from '../registration/+state/registration.actions';
import { RegistrationFacade } from '../registration/+state/registration.facade';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ui-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, OnDestroy {
  @Input() feature: RegistrationFeature;
  @Input() data$: Observable<any>;
  @Input() controls = [];
  @Input() resetWhenChanged: string;
  @Input() enable = {
    delete: true,
  };
  @Input() header = '';
  @Input() description = '';

  private data: any;
  private dataSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private registrationFacade: RegistrationFacade,
    private store: Store<RegistrationState>,
  ) {}

  ngOnInit() {
    if (!this.resetWhenChanged) this.resetWhenChanged = `${this.feature}.id`;
    this.dataSubscription = this.data$.pipe().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnDestroy() {
    if (this.dataSubscription && !this.dataSubscription.closed) this.dataSubscription.unsubscribe();
  }

  private getDataId() {
    const id = this.data && this.data[this.feature] && this.data[this.feature].id;
    return id;
  }

  async onSave(values: any) {
    const id = this.getDataId();
    if (id) {
      const modifiedData = {
        ...this.data[this.feature],
        ...values[this.feature],
      };
      this.registrationFacade.modify(this.feature, modifiedData, id);
      // this.apiOcsService.put(PERSONS_FEATURE_KEY, modifiedPerson, modifiedPerson.refId).subscribe((person: Person) => {

      // });
    } else {
      this.registrationFacade.add(this.feature, values[this.feature]);
      // this.apiOcsService.post(PERSONS_FEATURE_KEY, values.person).subscribe((person: Person) => {

      //   this.personsFacade.select(person.refId);
      //   // this.router.
      // });
    }
  }

  onDelete(values: any) {
    const id = this.getDataId();
    if (id) {
      this.openPersonDeleteDialog();
    }
  }

  openPersonDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        // title: String(this.feature).toLocaleUpperCase(),
        count: 1,
      },
    });

    dialogRef.afterClosed().subscribe((isYes) => {
      if (isYes) {
        const id = this.getDataId();
        this.registrationFacade.remove(this.feature, id);
        // this.apiOcsService.delete(PERSONS_FEATURE_KEY, this.person.refId).subscribe((person: Person) => {
        //   this.personsFacade.select(null);
        // });
      }
    });
  }
}
