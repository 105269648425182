import { Column, Entity, OneToMany, PrimaryGeneratedColumn } from 'typeorm';
import { TFields } from './TFields';

@Entity('t_planet_api_key', { schema: 'VaristarIS' })
export class TPlanetApiKey {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_planet_api_key' })
  idPlanetApiKey: number;

  @Column('varchar', {
    name: 'api_key',
    nullable: true,
    comment: 'API klíč',
    length: 100,
  })
  apiKey: string | null;

  @Column('decimal', {
    name: 'aoi',
    nullable: true,
    comment: 'Výměra pro tento order v hektarech.',
    precision: 10,
    scale: 2,
  })
  aoi: string | null;

  @Column('date', {
    name: 'valid_from',
    nullable: true,
    comment: 'Začátek platnosti order',
  })
  validFrom: string | null;

  @Column('varchar', {
    name: 'account',
    nullable: true,
    comment: 'Note',
    length: 100,
  })
  account: string | null;

  @Column('varchar', {
    name: 'note',
    nullable: true,
    comment: 'Poznámka k API klíči.',
    length: 1000,
  })
  note: string | null;

  @OneToMany('TFields', 'idPlanetApiKey2')
  tFields: TFields[];
}
