import { Application } from 'balena-sdk';

// tslint:disable-next-line: no-empty-interface
export interface OpenBalenaFleet extends Application {}

export const OpenBalenaFleet = {
  selectId: (a: OpenBalenaFleet) => {
    return a.app_name; // misto uuid se vsude na API odkazuji na app_name
  },
};
