import { TOsoby } from './entities/TOsoby';

export interface OnboardingPerson extends TOsoby {
  id?: string; // TODO: odstranit, nyni docasne ponechano kvuli neodstranenemu configuration
}

export const OnboardingPerson = {
  selectId: (a: OnboardingPerson) => {
    return a.idOsoby;
  },
  fullName: (p: Partial<OnboardingPerson>) => {
    return p ? `${p.jmeno} ${p.prijmeni}` : 'N/A';
  },
};
