import { THnojiva } from './entities/THnojiva';

export enum FertilizerSource {
  CUSTOM = 'CUSTOM',
  LIST = 'LIST',
}

// Typ obvyklé měrné jednotky použití.
export enum FertilizerType {
  MASS = 'H', // hmostnostní - ulozeno v db jako liquid = 0
  VOLUME = 'O', // objemová - ulozenao v db jako liquid = 1
}

export enum FertilizerSourceType {
  ORGANIC = 'ORGANIC',
  ANORGANIC = 'ANORGANIC',
  ALL = 'ALL',
}

// export enum FertilizerType {
export enum FertilizerElement {
  N = 'N',
  P = 'P',
  K = 'K',
}

// tslint:disable-next-line: no-empty-interface
export interface Fertilizer extends THnojiva {}

export const Fertilizer = {
  selectId: (a: THnojiva) => {
    //In this case this would be optional since primary key is id
    return String(a.idtHnojiva);
  },
  getFertilizerTypes: () => {
    return Object.keys(FertilizerType).map((key) => ({
      name: `FERTILIZER_TYPE.${key.toUpperCase()}`,
      value: FertilizerType[key],
    }));
  },
  getFertilizerTypeByValue: (type: string) => {
    const i = Object.values(FertilizerType).findIndex((t) => t === type);
    return i < 0 ? null : `FERTILIZER_TYPE.${Object.keys(FertilizerType)[i]}`;
  },
  getFertilizerElementByValue: (element: string) => {
    const i = Object.values(FertilizerElement).findIndex((e) => e === element);
    return i < 0 ? null : `FERTILIZER_ELEMENT.${Object.keys(FertilizerElement)[i]}`;
  },
};
