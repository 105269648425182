import { TOsobyRole } from './entities/TOsobyRole';

// tslint:disable-next-line: no-empty-interface
export interface PersonRole extends TOsobyRole {}

export const PersonRole = {
  selectId: (x: TOsobyRole) => {
    return x.idtOsobyRole;
  },
};
