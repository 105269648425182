import { DynamicFormControlBase } from './dynamic-form-control-base';

export class DfcValue<T> extends DynamicFormControlBase<T> {
  controlType = 'value';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
