import {
  Column,
  Entity,
  Index,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { THolding } from './THolding';
import { TOsoby } from './TOsoby';
import { THoldingOsobyRole } from './THoldingOsobyRole';
import { TOpravneni } from './TOpravneni';

@Index('id_user', ['idtOsoby'], {})
@Index('id_holding', ['idtHolding'], {})
@Entity('t_holding_osoby', { schema: 'VaristarIS' })
export class THoldingOsoby {
  @PrimaryGeneratedColumn({ type: 'int', name: 'idt_holding_osoby' })
  idtHoldingOsoby: number;

  @Column('int', {
    name: 'idt_holding',
    nullable: true,
    comment: 'Párovací klíč do tabulky t_holding.',
  })
  idtHolding: number | null;

  @Column('int', {
    name: 'idt_osoby',
    nullable: true,
    comment: 'Párovací klíč do tabulky t_osoba',
  })
  idtOsoby: number | null;

  @ManyToOne('THolding', 'tHoldingOsobies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_holding', referencedColumnName: 'idtHolding' }])
  idtHolding2: THolding;

  @ManyToOne('TOsoby', 'tHoldingOsobies', {
    onDelete: 'NO ACTION',
    onUpdate: 'NO ACTION',
  })
  @JoinColumn([{ name: 'idt_osoby', referencedColumnName: 'idOsoby' }])
  idtOsoby2: TOsoby;

  @OneToMany('THoldingOsobyRole', 'idtHoldingOsoby2')
  tHoldingOsobyRoles: THoldingOsobyRole[];

  @OneToMany('TOpravneni', 'idtHoldingOsoby2')
  tOpravnenis: TOpravneni[];
}
