import { THoldingOsobyRole } from './entities/THoldingOsobyRole';

// tslint:disable-next-line: no-empty-interface
export interface HoldingPersonRole extends THoldingOsobyRole {}

export const HoldingPersonRole = {
  selectId: (x: THoldingOsobyRole) => {
    return x.idtHoldingOsobyRole;
  },
};
