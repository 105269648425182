import { PropsFilterFnFactory } from '@ngrx/data';
import { TTabletMachines } from './entities/TTabletMachines';

// tslint:disable-next-line: no-empty-interface
export interface TabletMachine extends TTabletMachines {}

export const TabletMachine = {
  selectId: (a: TabletMachine) => {
    //In this case this would be optional since primary key is id
    return String(a.idtTabletMachines);
  },
  // getItems: () => {
  //   return Object.keys(TabletMachineType).map(key => ({ name: `TABLET_MACHINE_TYPE.${key.toUpperCase()}`, value: TabletMachineType[key] }));
  // },
  getItems: (data: TabletMachine[] = []) => {
    return data.map((tabletMachine) => ({
      name: `${tabletMachine.mDescription} (${tabletMachine.mTerminalBrandType})`,
      value: tabletMachine.idtTabletMachines,
    }));
  },
  filterFn: (tabletMachineList) => {
    return tabletMachineList.filter((tabletMachine: TabletMachine) => {
      return !tabletMachine.deactivated;
    });
  },
};
