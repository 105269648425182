// export enum PersonSalutation {
//   MR = 'MR',
//   MS = 'MS',
//   MRS = 'MRS',
//   MISS = 'MISS',
// };

// export interface Person {
//   id?: string,
//   refId?: string,
//   firstName?: string;
//   // middleName?: string;
//   lastName?: string;
//   salutationRefId?: string;
//   contact?: {
//     email?: string;
//     mobile?: string;
//     fix?: string;
//   }
// }

// export const Person = {
//   selectId: (a: Person): string => {
//     //In this case this would be optional since primary key is id
//     return a.id; // TBD: refId ?
//   },
//   sortByLastName: (a: Person, b: Person): number => {
//     return a.lastName && a.lastName.localeCompare(b.lastName);
//   },

//   fullName: (p: Person) => {
//     return `${p.lastName} ${p.firstName}`;
//   },
//   getSalutations: () => {
//     return Object.keys(PersonSalutation).map(key => ({ name: `SALUTATION.${key.toUpperCase()}`, value: PersonSalutation[key] }));
//   }
// }
import { PropsFilterFnFactory } from '@ngrx/data';
import * as moment from 'moment';
import { TOsoby } from './entities/TOsoby';

export interface Person extends TOsoby {
  id?: string; // TODO: odstranit, nyni docasne ponechano kvuli neodstranenemu configuration
}

export const Person = {
  selectId: (a: Person) => {
    return a.idOsoby;
  },
  fullName: (p: Partial<Person>) => {
    return p ? `${p.jmeno} ${p.prijmeni}` : 'N/A';
  },
  nameWithTitle: (p: Partial<Person>) => {
    return p ? `${p.titulPred} ${p.jmeno} ${p.prijmeni} ${p.titulZa}`.trim() : '';
  },
};
