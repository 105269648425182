import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FrontendUiFormModule } from '@varistar-apps/frontend/ui-form';

import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { RegistrationEffects } from './+state/registration.effects';
import { RegistrationFacade } from './+state/registration.facade';
import {
  REGISTRATION_FEATURE_KEY,
  registrationInitialState,
  registrationReducer,
} from './+state/registration.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(REGISTRATION_FEATURE_KEY, registrationReducer, {
      initialState: registrationInitialState,
    }),
    EffectsModule.forFeature([RegistrationEffects]),
    AngularFirestoreModule, // Firebase Firestore
    AngularFireAuthModule, // Firebase Authentication,
    FrontendUiFormModule,
    // ApiOcsModule,
  ],
  providers: [RegistrationFacade],
  declarations: [RegistrationFormComponent],
  exports: [RegistrationFormComponent],
})
export class RegistrationModule {}
