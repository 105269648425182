import { ApiVaristarGeeMapRequest } from './api-varistar.model';
import { MapLayer } from './map-layer.model';
import { GeoJSON } from 'geojson';
import { LatLngBounds } from 'leaflet';
import { FeatureCollection, Polygon } from '@turf/helpers';

export enum MapType {
  MAP_IMAGE_TILES_URL = 'MAP_IMAGE_TILES_URL', // map image tiles url
  MAP_IMAGE_DOWNLOAD_URL = 'MAP_IMAGE_DOWNLOAD_URL', // map image download url
  GEOJSON_CONTENT = 'GEOJSON_CONTENT', // feature collection content in GeoJson format
  GEOJSON_DOWNLOAD_URL = 'GEOJSON_DOWNLOAD_URL', // download url to feature collection in GeoJson format
}

export interface MapBounds {
  //google.maps.LatLng
  sw: { lat: number; lng: number }; // South West - lat, lng
  ne: { lat: number; lng: number }; // North East - lat, lng
}
// class pro DTO
export class MapBounds implements MapBounds {
  static fromArray(array) {
    return {
      sw: {
        lng: array[0][0],
        lat: array[0][1],
      },
      ne: {
        lng: array[1][0],
        lat: array[1][1],
      },
    };
  }
}

// external map editor interface:
export interface MapEditInterface {
  onCreate(features, feature);
  onEdit(features, affectedFeatures);
  onDelete(features, feature);
}
// external map interface:
export interface MapInterface {
  // visual selection of the field by property name and its value:
  selectFieldByProperty?(
    mapInterface: MapInterface,
    propertyName: string,
    propertyValue: any,
    layer?: number,
    splitIndex?: number,
  ): Promise<any>;
  // visual selection of the field by property name and its value:
  getFieldByProperty?(
    mapInterface: MapInterface,
    propertyName: string,
    propertyValue: any,
    layer?: number,
    splitIndex?: number,
  ): Promise<any>;
  // closes all map popups:
  closePopup?(): Promise<any>;
  // filter fields on the map by property name and its value:
  maskFieldsButByProperty?(
    mapInterface: MapInterface,
    propertyName: string,
    propertyValue: any,
    layer?: number,
    splitIndex?: number,
  ): Promise<any>;
  // set map field from the GeoJson object:
  addGeoJsonObject?(content: GeoJSON, splitIndex?: number): Promise<any>;
  addGeoJsonObjectLayered?(
    content: GeoJSON,
    layer: number,
    splitIndex?: number,
    reInitMap?: boolean,
    setActiveMapLayer?: boolean,
  ): Promise<any>;
  // set map filed from the SHP file ArrayBuffer object:
  addSHPArrayBuffer?(content: ArrayBuffer, splitIndex?: number): Promise<any>;
  addSHPArrayBufferLayered?(content: ArrayBuffer, layer: number, splitIndex?: number): Promise<any>;
  // set map filed from the SHP file ArrayBuffer object:
  addSHPString?(content: string, splitIndex?: number): Promise<any>;
  addSHPStringLayered?(content: string, layer: number, splitIndex?: number): Promise<any>;
  // displays GeoTiff:
  // todo deprecated
  setGeoTiffC?(c: number, splitIndex?: number): void;
  // redraws GeoTiff:
  // todo deprecated
  redrawGeoTiff?(): void;
  // add GeoTIFF tiles:
  addGeoTiffTiles?(link: string, splitIndex?: number): Promise<any>;
  addGeoTiffTilesLayered?(
    link: string,
    layer: number,
    splitIndex: number,
    resize: number,
    data: any | null,
  ): Promise<any>;
  // get GeoTiff infomation for current position:
  getTiffPointInfo?(link: string, lon: number, lat: number, splitIndex?: number): Promise<any>;
  // merge SHP ArrayBuffer:
  mergeSHPArrayBuffer?(content: ArrayBuffer, layer?: number, splitIndex?: number): Promise<any>;
  // test SHP ArrayBuffer, returns tested values:
  testSHPArrayBuffer?(content: ArrayBuffer, layer?: number, splitIndex?: number): Promise<any>;
  // force detect change:
  changeDetect?(): void;
  // enable editing options:
  setEdit?(enabled: boolean, splitIndex?: number): void;
  // editing options enabled:
  isEditing?(splitIndex?: number): boolean;
  // edit listener, onCreate, onEdit, onDelete:
  addEditFeaturesListener?(mapEdit: MapEditInterface, splitIndex?: number): void;

  setEditLayer?(layer: number, splitIndex?: number): void;

  // export selected map feature as SHP file:
  exportSHP?(
    filename: string,
    propertyName: string,
    propertyValues: any[],
    splitIndex?: number,
  ): Promise<any>;
  // export all currently used map features as SHP file:
  exportSHPAll?(filename: string, layer?: number, splitIndex?: number): Promise<any>;
  // enable animations:
  setAnimated?(enabled: boolean): void;

  setHoldRendering?(enabled: boolean): void;
  // zoom to borders:
  // zoomTo?(): Promise<any>;
  // control legend visibility:
  setLegendVisible?(visible: boolean, splitIndex?: number): void;
  // set count of items in legend:
  setLegendItemsCount?(count: number, splitIndex?: number): void;
  // set legend & map color palette:
  setLegendPalette?(palette: string[], splitIndex?: number): void;
  setLegendPaletteLayered?(palette: string[], layer: number, splitIndex?: number): void;
  cleanupPalets?(): void;
  // set legend labels:
  setLegendLabels?(labelsMap: any, splitIndex?: number): void;
  // set legend intervals:
  setLegendInterval?(enabled: boolean, splitIndex?: number): void;
  // set legend reverse order:
  setLegendReverseOrder?(reversed: boolean, splitIndex?: number): void;
  // set legend value label separator:
  setLegendValueLabelSeparator?(separator: string, splitIndex?: number): void;
  // set legend value label postfix:
  setLegendValuePostfix?(postfix: string, splitIndex?: number): void;
  // set legend filtering - hide map non visible elements in legend
  setLegendFiltering?(enabled: boolean, splitIndex?: number): void;
  // set feature color and feature border color when filtered out
  // passing null will make it transparent!
  setLegendFilteredColor?(fillHexColor: string, borderHexColor: string, splitIndex?: number): void;
  // set legend float values precision:
  setLegendFloatPrecision?(fixes: number, splitIndex?: number, forceFloatValues?: boolean): void;
  // set legend mode
  setLegendMode?(mode: string, splitIndex?: number): void;

  // set the template for edition:
  setEditTemplate?(geoJson: any): void;

  setLayerVisibility?(layer: number, visible: boolean, splitIndex?: number): void;

  // set map bounds:
  setBounds?(bounds: LatLngBounds, splitIndex?: number): void;
  // get map bounds:
  getBounds?(splitIndex?: number): LatLngBounds;

  // call to force changes:
  onChange?(): void;
  // set country:
  focusCountry?(countryCode: string, splitIndex?: number): void;
  // returns intenral features:
  getFeatures?(splitIndex?: number, layer?: number): any[];
  // returns cut result from edit:
  setCutResultListener?(listener, splitIndex?: number): void;
  // set borders style:
  setBorder?(borderColor: string, splitIndex?: number): void;
  setBorderLayered?(borderColor: string, layer: number, splitIndex?: number): void;
  // set color style:
  setForceFeatureColor?(color: string, splitIndex?: number): void;
  // set empty feature:
  setForceEmptyColor?(empty: boolean, layer: number, splitIndex?: number): void;
  // returns map colors
  getColors?(): any[];
  // force redraw features:
  redrawFeatures?(splitIndex?: number, immidiate?: boolean): void;
  redrawOrInitFeatures?(splitIndex?: number): Promise<void>;
  // draw features list:
  drawFeatures?(features: any[], splitIndex?: number): void;
  // feature map action:
  interactiveActivateFeature?(feature, layer?: number, splitIndex?: number): void;
  // custom add layer:
  addLayer?(layer: any): Promise<any>;
  // custom remove layer:
  removeLayer?(layer: any): Promise<any>;
  // custom remove layer:
  removeLayers?(layers: any[]): Promise<any[]>;
  // clear layers:
  clearMap?(splitIndex?: number): Promise<any>;
  // clear layer:
  clearMapLayer?(layer: number, splitIndex?: number): Promise<any>;
  // select popup key:
  selectPopup?(key: any);
  // sets properties of selected feature (please use getFeatures to searchfeature first)
  setPropertiesOfFeature?(feature, properties, splitIndex?: number): void;
  // find feature by "selectByPropertyName" property name and its "selectByPropertyValue" and set its "setPropertyName" property value as "setValue"
  setPropertyOfFeature?(
    selectByPropertyName: string,
    selectByPropertyValue: any,
    setPropertyName: string,
    setValue: any,
    splitIndex?: number,
  ): void;
  // reorder map features, order myde by "orderByPropertyName" property of each feature
  orderFeaturesByProperty?(
    orderByPropertyName: string,
    asc: boolean,
    layer?: number,
    splitIndex?: number,
  ): void;
  // custom formating method for properties to map labels conversion
  setPropertiesFormattingFunction?(
    formatingFunction: (properties: any, mainProperty: string, splitIndex?: number) => string,
    splitIndex?: number,
    layerIndex?: number,
  );
  // limit min/max values of the mapped maps
  setMinMax?(min: any, max: any, layer: number, splitIndex?: number): void;
  // returns min/max values
  getMinMax?(layer: number, splitIndex?: number): number[];
  // copy of the internal feature list - returns features in any GeoJSON format - experimental!
  copyFeatures?(splitIndex?: number): any[];
  // set internal feature list - experimental!
  setFeatures?(
    features: any[],
    layer: number,
    splitIndex?: number,
    disableRedraw?: boolean,
    redrawImmediately?: boolean,
  ): void;
  // cleanup map component features
  cleanFeatures?(splitIndex?: number): void;
  // cleanup map features layer
  cleanFeaturesLayer?(layer: number, splitIndex?: number): void;
  // set internal property used for style & legend generation
  setMainProperty?(propertyName: string, layer?: number, splitIndex?: number): void;

  // draw points
  addPoints?(geojson: any, layer?: number, splitIndex?: number): void;
  // remove points
  removeAllPoints?(layer?: number, splitIndex?: number): void;
  // remove specific points
  removePoints?(features: any[], splitIndex?: number): void;

  // enable/disable auto zoom:
  setAutoZoomOnMapLoad?(enabled: boolean): void;

  splitMap?(enabled: boolean): Promise<any>;
  isSplitMap?(): boolean;

  // todo zoom all content:
  zoomAll?(duration?: number, splitIndex?: number);
  zoomTopLayer?(splitIndex?: number);

  zoomSettings?(enabled: boolean, autoZoomEnabled?: boolean): void;
  scrollSettings?(enabled: boolean): void;

  getMapApi?(splitIndex?: number): any;

  getWorker?(): any;
  getInternalPalette?(): [];

  refresh?(zoom?: boolean, immediage?: boolean, reinit?: boolean, layerIndex?: number): void;
  reinitialiseLegend?(layer?: number, forceReinit?: boolean, splitIndex?: number): void;

  selectFeatures?(layer?: number, splitIndex?: number): void;
  setSelectionListener?(onSelected?: any): void;
  clearSelection?(): void;

  updateScene?(scene: any, index?: number): void;
  getScene?(index?: number): any;

  setCustomLegend?(legendSteps: any[], layer: number, splitIndex: number): Promise<void>;

  zoomFeatures?(features, duration: number, splitIndex: number): void;

  dissolveFeatureCollection?(
    featureCollection: any,
    propertyName: string,
  ): FeatureCollection<Polygon>;

  toggleGlobalMergeMode?(): void;
  toggleControls?(): void;

  isGeoJsonOverlapsCurrentMapBounds?(geoJson: GeoJSON): boolean;
  isMapBoundsBiggerThanGeoJsonBounds?(geoJson: GeoJSON, diffTollerance?: number): boolean;

  // set number format for map legend
  setLegendNumberFormat?(numberFormat: string): void;
  getLegendNumberFormat?(): string;
  getActiveMap?(splitIndex?: number): any;
}

// export interface ResponseGeeMapLayer {
//   visualizationType: VisualizationType // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
//   type: LayerType, // MAP_IMAGE_URL / GEO_JSON
//   url?: string, // map tiles / download url
//   content?: object, // GeoJson object
//   downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu

// }

export interface Map extends MapInterface {
  request: ApiVaristarGeeMapRequest; // zapamatuje si parametry podle kterych byla mapa vytvorena
  id?: string;
  name: string;
  bounds: MapBounds;
  zoom?: number;
  center?: any;
  layers: MapLayer[];
  enableFit?: boolean;
  // requests: (ApiVaristarGeeMapRequest | ApiVaristarGeeAssetMapRequest)[]

  // response: ApiVaristarGeeMapResponse;
  //   visualizationType: VisualizationType // NDVI, LAI, EVI / OUTLINE / SMART_SCOUTING
  // type: MapLayerType, // MAP_IMAGE_URL / GEO_JSON
  //   url?: string, // map tiles / download url
  //   content?: object, // GeoJson object
  //   downloadFormat?: DownloadFormat // TODO: jak budou formatovany data pri downloadu
}

// class pro DTO
export class Map implements Map {
  static selectId(a: Map): string {
    //In this case this would be optional since primary key is id
    return a.id;
  }

  static getId(map: Map) {
    return `${name}`;
  }
}
