import { Directive } from '@angular/core';
import { FlexDirective } from '@angular/flex-layout';

const selector = `
  [fxFlex.fhd], [fxFlex.gt-fhd],
  [fxFlex.qhd], [fxFlex.gt-qhd],
  [fxFlex.uhd], [fxFlex.gt-uhd],
  [fxFlex.fuhd],
`;
const inputs = [
  'fxFlex.fhd',
  'fxFlex.gt-fhd',
  'fxFlex.qhd',
  'fxFlex.gt-qhd',
  'fxFlex.uhd',
  'fxFlex.gt-uhd',
  'fxFlex.fuhd',
];

@Directive({ selector, inputs })
export class FlexLayoutFxFlexDirective extends FlexDirective {
  protected inputs = inputs;
}
