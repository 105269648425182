import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Contact } from '@varistar-apps/shared/data';
import * as _ from 'lodash';
import { DfcInput } from '../dfc-input';
import { DfcSelect } from '../dfc-select';
import { of, Observable } from 'rxjs';
import { DynamicFormControlBase } from '../dynamic-form-control-base';
import { DfcDivider } from '../dfc-divider';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dynamic-form-control-input-buttons',
  templateUrl: './dynamic-form-control-input-buttons.component.html',
  styleUrls: ['./dynamic-form-control-input-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicFormControlInputButtonsComponent,
      multi: true,
    },
  ],
})
export class DynamicFormControlInputButtonsComponent implements ControlValueAccessor {
  @Input() control: DynamicFormControlBase<any>;
  @Input() form?: FormGroup;
  @Input() enable = {
    save: false,
    cancel: false,
    delete: false,
    header: false,
  };

  // tslint:disable-next-line: no-output-native
  @Output() change = new EventEmitter();
  @Output() event = new EventEmitter();

  value: number; //
  controls = [];
  data: { values: any[] };

  isDisabled = false;

  constructor(private cdr: ChangeDetectorRef) {
    this.isDisabled = this.control && this.control.disabled;
  }

  onChangeValue = (value: any): void => {};
  onValidatorChange = (value?: any): void => {};
  writeValue(value: any) {
    this.value = value;

    this.data = {
      values:
        this.control.delimiter && value ? String(value).split(this.control.delimiter) : [value],
    }; // NOTE: data musí být zanořeny jinak se po updatu nezobrazí
    // this.controls = this.data.values.reduce((controls, value, i) => {
    let order = 1.0;
    // this.controls = _.range(+this.control.inputs).reduce((controls, value, i) => {
    this.controls = this.control.inputs.reduce((controls, input, i) => {
      if (i > 0) {
        controls.push(
          new DfcDivider<any>({
            // header: '',
            header: this.control.delimiter,
            // description: this.control.delimiter,
            order: order,
            flex: 1, // aby nebyl defaultnich 100 ale jen minimum co se vejde
          }),
        );
        order = order + 0.1;
      }
      // !!! TODO: nevyjmenovavat parametry ale pouzit vsechny pres merge
      controls.push(
        new DfcInput<any>({
          key: `values.${i}`,
          placeholder: input.placeholder, //this.control.placeholder,
          order: input.order || order,
          flex: input.flex, //this.control.flex,
          width: input.width, //this.control.width,
          required: input.required,
          validator: input.validator,
          validatorMessages: input.validatorMessages,
          disabled: this.isDisabled,
          type: input.type || this.control.type,
          floatLabel: input.floatLabel || this.control.floatLabel,
        }),
      );
      order = order + 0.1;
      return controls;
    }, []);
    this.change.emit(this.value);
    this.cdr.markForCheck();
  }

  onChange() {
    this.onChangeValue(this.value);
    this.onValidatorChange(this.value);
  }

  registerOnChange(fn) {
    this.onChangeValue = fn;
  }

  registerOnTouched(fn) {}

  // registerOnDisabledChange(fn: (isDisabled: boolean) => void) : {}: void;

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.writeValue(this.value);
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   // const isValid = _.reduce(control.value, (isValid, value, id) => {
  //   //   return _.reduce(value.data, (isValid, value, deviceType) => {
  //   //     return deviceType !== '' && _.reduce(value, (isValid, value, deviceId) => {
  //   //       return deviceId !== '' && _.reduce(value, (isValid, value, indexOrName) => {
  //   //         return indexOrName !== '' && _.reduce(value, (isValid, value, key) => {
  //   //           return key !== '' && !isNaN(value);
  //   //         }, isValid);
  //   //       }, isValid);
  //   //     }, isValid);
  //   //   }, isValid);
  //   // }, true);

  //   // // this.onValidatorChange();
  //   // return isValid ? null : { 'custom': true };
  // }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  onSave(data) {
    const value = data.values.reduce((value, v, i) => {
      // !!! FIXIT: proc je prazdna hodnota jako string 'null'?
      if (v && v !== 'null') {
        if (value.length && i > 0) value = String(value) + this.control.delimiter;
        value = value + v;
      }
      return value;
    }, '');

    // let value = this.control.delimiter ? data.values.join(this.control.delimiter) : data.values[0];
    // if (String(value)[String(value).length - 1] === this.control.delimiter)
    //   value = String(value).slice(0, -1); // nebyl zadan cely kod ale jen prvni cast tak oriznu delimiter na konci
    // if (String(value)[0] === this.control.delimiter)
    //   value = String(value).substr(1); // nebyl zadan cely kod ale jen druha cast tak oriznu delimiter na zacatku
    this.writeValue(value);
    return this.onChange();
  }

  onModify(data) {
    const value = this.control.delimiter
      ? data.values.join(this.control.delimiter)
      : data.values[0];
    if (this.value) {
      // NOTE: po pridani selectionChange v dynamic-form-control u select to bez kontroly na null nastavilo zmenu pri zobrazeni prazdneho form kde je select
      this.onChangeValue(value);
    }
  }

  onDelete(event) {}
}
