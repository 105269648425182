import { Action } from '@ngrx/store';
import { Access, Organization } from '@varistar-apps/shared/data';

export enum RegistrationFeature {
  Organization = 'Organization',
  Account = 'Account',
  // Access = 'Access',
  OrganizationAccess = 'OrganizationAccess',
  // Company = 'Company',
  // Person = 'Person',
  // // Trader = 'Trader',
  // // Customer = 'Customer',
  // Deal = 'Deal',
  // // TradingPlatform = 'TradingPlatform',
  // // TradePoint = 'TradePoint',
}

export type RegistrationPayload = Organization | Account | Access; //| Company | Person | Trader | Customer | Deal | TradingPlatform | TradePoint;

export enum RegistrationActionTypes {
  Load = '[Registration] Load',
  Query = '[Registration] Query',
  Clear = '[Registration] Clear',

  Select = '[Registration] Select',

  Loaded = '[Registration] Loaded',
  Added = '[Registration] Added',
  Modified = '[Registration] Modified',
  Removed = '[Registration] Removed',

  Update = '[Registration] Update',
  UpdateSuccess = '[Registration] Update Success',

  FirebaseError = '[Registration] Firebase Error',
}

// Initial query

export class LoadRegistration implements Action {
  readonly type = RegistrationActionTypes.Load;
  constructor(
    public feature: RegistrationFeature,
    public path: string = '',
  ) {}
}

export class RegistrationLoaded implements Action {
  readonly type = RegistrationActionTypes.Loaded;
  constructor(
    public feature: RegistrationFeature,
    public payload: any[],
  ) {}
}

// Initial query

export class QueryRegistration implements Action {
  readonly type = RegistrationActionTypes.Query;
}

// Clear all data after logout

export class ClearRegistration implements Action {
  readonly type = RegistrationActionTypes.Clear;
}

// Select one Registration

export class SelectRegistration implements Action {
  readonly type = RegistrationActionTypes.Select;
  constructor(
    public feature: RegistrationFeature,
    public id: string,
    // public payload: RegistrationPayload
  ) {}
}

// AngularFire2 StateChanges

export class RegistrationAdded implements Action {
  readonly type = RegistrationActionTypes.Added;
  constructor(
    public feature: RegistrationFeature,
    public payload: RegistrationPayload,
  ) {}
}

export class RegistrationModified implements Action {
  readonly type = RegistrationActionTypes.Modified;
  constructor(
    public feature: RegistrationFeature,
    public payload: RegistrationPayload,
  ) {}
}

export class RegistrationRemoved implements Action {
  readonly type = RegistrationActionTypes.Removed;
  constructor(
    public feature: RegistrationFeature,
    public id: string,
    // public payload: RegistrationPayload
  ) {}
}

// Firestore update

export class UpdateRegistration implements Action {
  readonly type = RegistrationActionTypes.Update;
  constructor(
    public feature: RegistrationFeature,
    public id: string,
    public changes: Partial<RegistrationPayload>,
  ) {}
}

export class UpdateRegistrationSuccess implements Action {
  readonly type = RegistrationActionTypes.UpdateSuccess;
}

//

export class RegistrationFirebaseError implements Action {
  readonly type = RegistrationActionTypes.FirebaseError;
  constructor(public payload?: any) {}
}

export type RegistrationAction = // LoadRegistration | RegistrationLoaded | RegistrationLoadError;

    | LoadRegistration
    | QueryRegistration
    | ClearRegistration
    | SelectRegistration
    | RegistrationAdded
    | RegistrationModified
    | RegistrationRemoved
    | UpdateRegistration
    | UpdateRegistrationSuccess
    | RegistrationFirebaseError;

export const fromRegistrationActions = {
  LoadRegistration,
  QueryRegistration,
  ClearRegistration,

  SelectRegistration,

  RegistrationAdded,
  RegistrationModified,
  RegistrationRemoved,

  UpdateRegistration,
  UpdateRegistrationSuccess,

  RegistrationFirebaseError,
};
