import { Injectable } from '@angular/core';
import {
  EntityCollectionReducerMethodMap,
  EntityDefinitionService,
  EntityAction,
  EntityChangeTracker,
  EntityCollection,
  EntityCollectionReducerMethods,
  EntityDefinition,
} from '@ngrx/data';
import { Update } from '@ngrx/entity';

@Injectable()
export class FrontendUiFeatureConfigurationEntityCollectionReducerMethodsFactory {
  constructor(private entityDefinitionService: EntityDefinitionService) {}

  // TODO: create se nezavola
  /** Create the  {EntityCollectionReducerMethods} for the named entity type */
  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    const methodsClass = new FrontendUiFeatureConfigurationEntityCollectionReducerMethods(
      entityName,
      definition,
    );
    // const methodsClass = new ExtendedEntityCollectionReducerMethods(entityName, definition);

    return methodsClass.methods;
  }
}

export class FrontendUiFeatureConfigurationEntityCollectionReducerMethods<
  T,
> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>,
  ) {
    super(entityName, definition);
  }

  // TODO: sem se to nedostane
  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>,
  ): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);

    if ((action.payload as any).page) {
      (ec as any).page = (action.payload as any).page;
    }
    if ((action.payload as any).pageCount) {
      (ec as any).pageCount = (action.payload as any).pageCount;
    }
    if ((action.payload as any).count) {
      (ec as any).count = (action.payload as any).count;
    }
    if ((action.payload as any).total) {
      (ec as any).total = (action.payload as any).total;
    }
    // (ec as any).selectId = 9;
    return ec;
  }

  //   // protected removeAll(
  //   //   collection: EntityCollection<T>,
  //   //   action: EntityAction<T>
  //   // ): EntityCollection<T> {
  //   //   const ec = super.removeAll(collection, action);
  //   //   // (ec as any).page = undefined;
  //   //   // (ec as any).selectedId = undefined;
  //   //   (ec as any).filter = undefined;
  //   //   return ec;
  //   // }

  //   // protected (
  //   //   collection: EntityCollection<T>,
  //   //   action: EntityAction<T[]>
  //   // ): EntityCollection<T> {
  //   //   const ec = super..removeAll(collection, action);

  //   //   if ((action.payload as any).selectId) {
  //   //     (ec as any).selectId = (action.payload as any).selectId;
  //   //   }
  //   //   (ec as any).selectId = 9;
  //   //   return ec;
  //   // }
}

// export class ExtendedEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
//   constructor(
//     public entityName: string,
//     public definition: EntityDefinition<T>) {
//     super(entityName, definition);
//   }

//   protected queryManySuccess(
//     collection: EntityCollection<T>,
//     action: EntityAction<T[]>
//   ): EntityCollection<T> {

//     const ec = super.queryManySuccess(collection, action);

//     if ((action.payload as any).page) {
//       (ec as any).page = (action.payload as any).page;
//     }
//     if ((action.payload as any).pageCount) {
//       (ec as any).pageCount = (action.payload as any).pageCount;
//     }
//     if ((action.payload as any).count) {
//       (ec as any).count = (action.payload as any).count;
//     }
//     if ((action.payload as any).total) {
//       (ec as any).total = (action.payload as any).total;
//     }
//     // (ec as any).selectId = 9;
//     return ec;
//   }

//   protected updateOne(collection: EntityCollection<T>, action: EntityAction<Update<T>>): EntityCollection<T> {
//     const superMethod = { ...super.updateOne(collection, action) };

//     // if (action.payload.entityName === 'Project' &&
//     //   (action.payload.data.changes as any).id === 0) {
//     //   (superMethod as any).lastProjectId = (action.payload.data.changes as any).lastProjectId;
//     // }

//     return superMethod;
//   }
// }

// @Injectable()
// export class AdditionalEntityCollectionReducerMethodsFactory {
//   constructor(private entityDefinitionService: EntityDefinitionService) { }
//   /** Create the  {EntityCollectionReducerMethods} for the named entity type */
//   create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {

//     const definition = this.entityDefinitionService.getDefinition<T>(entityName);
//     const methodsClass = new AdditionalEntityCollectionReducerMethods(entityName, definition);
//     return methodsClass.methods;
//   }
// }

// export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
//   constructor(public entityName: string, public definition: EntityDefinition<T>) {
//     super(entityName, definition);
//   }
//   protected queryManySuccess(
//     collection: EntityCollection<T>,
//     action: EntityAction<T[]>
//   ): EntityCollection<T> {

//     const ec = super.queryManySuccess(collection, action);
//     if ((action.payload as any).foo) {
//       // save the foo property from action.payload to entityCollection instance
//       (ec as any).foo = (action.payload as any).foo;
//     }
//     return ec;
//   }
// }

// @Injectable()
// export class AdditionalEntityCollectionReducerMethods<
//   T
//   > extends EntityCollectionReducerMethods<T> {
//   constructor(
//     public entityName: string,
//     public definition: EntityDefinition<T>
//   ) {
//     super(entityName, definition);

//   }
//   protected queryManySuccess(
//     collection: EntityCollection<T>,
//     action: EntityAction<T[]>
//   ): EntityCollection<T> {
//     const ec = super.queryManySuccess(collection, action);

//       `AdditionalEntityCollectionReducerMethods::${JSON.stringify(action)}`
//     );
//     if ((action.payload as any).totalNumberOfEntities) {
//       // save the foo totalNumberOfEntities from action.payload to entityCollection instance
//       (ec as any).totalNumberOfEntities = (action.payload as any).totalNumberOfEntities;
//     }
//     return ec;
//   }
// }

// @Injectable()
// export class AdditionalEntityCollectionReducerMethodsFactory {
//   constructor(private entityDefinitionService: EntityDefinitionService) { }
//   /** Create the  {EntityCollectionReducerMethods} for the named entity type */
//   create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
//     const definition = this.entityDefinitionService.getDefinition<T>(
//       entityName
//     );
//     const methodsClass = new AdditionalEntityCollectionReducerMethods(
//       entityName,
//       definition
//     );
//     return methodsClass.methods;
//   }
// }
