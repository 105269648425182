import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('t_yield_price', { schema: 'VaristarIS' })
export class TYieldPrice {
  @PrimaryGeneratedColumn({ type: 'int', name: 'id_yield_price' })
  idYieldPrice: number;

  @Column('int', { name: 'id_plodiny' })
  idPlodiny: number;

  @Column('decimal', {
    name: 'amount',
    comment: 'prodane mnozstvi (v tunach)',
    precision: 12,
    scale: 2,
  })
  amount: string;

  @Column('decimal', {
    name: 'price',
    comment: 'cena za 1 tunu',
    precision: 12,
    scale: 2,
  })
  price: string;

  @Column('date', { name: 'sell_date', comment: 'datum prodeje' })
  sellDate: string;

  @Column('int', {
    name: 'season_id',
    comment: 'id sezony; napr 21 = sezona 2021/22',
  })
  seasonId: number;

  @Column('int', { name: 'id_zakaznik' })
  idZakaznik: number;
}
